/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { Dispatch, useEffect } from "react";
import { useQuery } from "urql";
import { ObjectForSelectOptions } from "../../../../core/types/global"; 
import { Board, useListGroupsQuery, useCreateBoardMutation } from "../../../../core/graphql/types";
import { useNavigate } from "react-router-dom";
import toast from 'react-hot-toast';
import { useForm, Controller } from "react-hook-form";

import Select from "react-select";
import { customStyles } from "../../../../utils";
import { TbSquaresFilled, TbX } from "react-icons/tb";
import { useSpace } from "../../../../core/providers/space";

export type IBoardFormCreateProps = {
  onCreate: (board: Board) => void;
  closeModal: () => void;
}

interface Option {
  label: string;
  value: string;
}

interface GroupForSelectOptions {
  groupsForSelect: Option[]
}


export const BoardFormCreate: React.FC<IBoardFormCreateProps> = ({closeModal, onCreate}) => {
  const {space} = useSpace()
  const { handleSubmit, register, reset, setValue, control, formState: { errors } } = useForm();
  const navigate = useNavigate();
  const [{fetching, data: groups}] = useListGroupsQuery();
  const [board, createBoard] = useCreateBoardMutation();

  const onSubmit = handleSubmit(data => {
    toast.promise(
      createBoard({name: data.name, groupId: data.group.id}).then(result => {
        onCreate(result.data?.createBoard!)
        closeModal();
        return result
      }),
      {
        loading: 'Создаем новую доску...',
        success: (data) => {
          if (data.error) throw new Error();
          navigate(`boards/${data.data?.createBoard?.id}`)
          return 'Доска создана';
        },
        error: 'Ошибка. Не удалось создать доску',
      }
    );
  });

  useEffect(() => {
    if(!groups?.listGroups) {
      return
    }
    setValue("group", groups?.listGroups?.find(g => g?.isRoot === true))
  },[groups?.listGroups])

  return (
    <>
      <div className="flex items-center justify-between">
        <div className="flex items-center space-x-2">
        <div className="py-1 px-2 bg-slate-100 flex space-x-2 rounded">
            <TbSquaresFilled size={20} className="text-indigo-600"/>
            <div className="flex font-medium text-sm">{space?.name}</div>
        </div>
        <div className="flex font-normal text-sm">Создание таблицы</div>
        </div>
        <button onClick={() => closeModal()}><TbX size={18}/></button>
      </div>
      <form onSubmit={onSubmit} className="flex flex-col relative w-full space-y-4">
        <div className="relative mt-4">
          <input 
            {...register("name", {required: true})} 
            className="text-xl font-medium text-slate-800 placeholder:text-slate-300 focus:outline-none mb-4" 
            placeholder="Название таблицы"
          />
          {errors.name && <span className="text-red-600 text-xs font-normal">"Название" обязательное поле.</span>}
        </div>
        <div className="relative">
          <label className="font-medium text-gray-600 text-sm">Группа:</label>
          <Controller
            name="group"
            control={control}
            render={({ field: { onChange, value }}) => (
              <div className="mt-1.5">
                <Select
                  styles={customStyles}
                  classNamePrefix="matr_select_form"
                  getOptionLabel={(option) => option?.name}
                  getOptionValue ={(option)=> option?.id}
                  value={value}
                  onChange={onChange}
                  options={groups?.listGroups ? groups.listGroups : []}
                />
              </div>
            )}
          />
        </div>
        <div className="flex justify-between w-full pt-6">
          <button
            type="button"
            className="inline-flex justify-end px-3 py-1.5 text-sm font-medium text-indigo-700 bg-indigo-100 border border-transparent rounded-md hover:bg-indigo-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-indigo-500"
            onClick={closeModal}
          >Отменить</button>
          <button
            type="submit"
            className="inline-flex justify-center px-3 py-1.5 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md hover:bg-indigo-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-indigo-500"
          >Создать</button>
        </div>
      </form>
    </>
  )
};