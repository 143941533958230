import { useEffect, useState } from "react"
import { useFlowContext } from "../../../../../core/providers/flow"
import { Edge, Node as ReactFlowNode } from "reactflow"
import { Node, NodeField, NodeOutputTypesEnum } from "../../../../../core/graphql/types"

export default function NodeInputData() {
  const {node, nodes, edges} = useFlowContext()
  const [sourceNodes, setSourceNodes] = useState<ReactFlowNode[]>([])
  const [nodeInputEdges, setNodeInputEdges] = useState<Edge[]>([])

  useEffect(() => {
    let nodeInputs: Edge[] = edges.filter(edge => edge.target === node.id).map(edge => edge)
    setNodeInputEdges(nodeInputs)

    let parentNodes: any[] = edges.filter(edge => edge.target === node.id).map(edge => ({id: edge.source, status: edge.sourceHandle}))
    let preNodes = parentNodes.map(pn => { 
      let nod = nodes.find(n => pn.id === n.id)!
      let fields = nod.data.fields.filter((f: NodeField) => f.status === pn.status)
      return {...nod, data: {...nod.data, fields: fields}}
    }).flat()
    setSourceNodes(preNodes)
  }, [edges, node])

  function getNodeName(type: string) {
    switch (type) {
      case "flow_record": return "Запись в процессе"
      case "board_record": return "Запись в таблице"
      case "response_record": return "Запись в запросе"
      default: return "Не определено"
    }
  }

  return (
    <div className="h-full overflow-hidden">
      <div className="relative h-full flex-none overflow-y-scroll">
      {sourceNodes.map((sourceNode: ReactFlowNode) => (
        <div key={sourceNode.id} className="p-2">
          <div className="text-base text-slate-400 font-medium w-full px-2 cursor-default mb-2">{getNodeName(sourceNode.data.outputType)}</div>
          {sourceNode.data.fields.filter((field: NodeField) => field.external === true && field.status === nodeInputEdges.find(nie => nie.source === sourceNode.id)?.sourceHandle).map((field: NodeField) => (
            <div key={field.id} className="text-sm text-slate-600 w-full px-2 hover:bg-slate-100 h-8 cursor-default flex items-center rounded-sm">{field.name}</div>
          ))}
        </div>
      ))}
      </div>
    </div>
  )
}