import { Popover, Tab, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import { TbPlugConnected } from 'react-icons/tb'
import { useFlowContext } from '../../../../core/providers/flow'
import { NodeOutputTypesEnum, NodeTypesEnum } from '../../../../core/graphql/types'

const blocks = [
  {
    name: 'Источник',
    group: "base",
    description: 'Блок позволяет использовать данные из таблицы в процессе',
    type: NodeTypesEnum.Source,
    icon: IconOne,
  },
  {
    name: 'Сервис',
    group: "base",
    description: 'Блок позволяет использовать сервисы в процессе',
    type: NodeTypesEnum.Request,
    icon: IconTwo,
  },
  {
    name: 'Запись',
    group: "base",
    description: 'Блок для хранения не изменяемых данных в процессе',
    type: NodeTypesEnum.Record,
    icon: IconThree,
  },
  {
    name: 'Повторитель',
    group: "loop",
    description: 'Блок позволяет повторить действие n раз',
    type: NodeTypesEnum.LoopRepeat,
    icon: IconThree,
  },
  {
    name: 'Цикл',
    group: "loop",
    description: 'Блок позволяет работать с коллекциями данных',
    type: NodeTypesEnum.LoopFor,
    icon: IconThree,
  },
  {
    name: 'Сборщик',
    group: "loop",
    description: 'Собирает записи в коллекцию',
    type: NodeTypesEnum.LoopCollect,
    icon: IconThree,
  },
  {
    name: 'Создать запись',
    group: "action",
    description: 'Выполняет действие над входными данными',
    type: NodeTypesEnum.Output,
    outputType: NodeOutputTypesEnum.CreateCard,
    icon: IconThree,
  },
  {
    name: 'Обновить запись',
    group: "action",
    description: 'Выполняет действие над входными данными',
    type: NodeTypesEnum.Output,
    outputType: NodeOutputTypesEnum.UpdateCard,
    icon: IconThree,
  },
  {
    name: 'Создать уведомление',
    group: "action",
    description: 'Выполняет действие над входными данными',
    type: NodeTypesEnum.Output,
    outputType: NodeOutputTypesEnum.CreateNotification,
    icon: IconThree,
  },
  {
    name: 'Сообщение в Telegram',
    group: "action",
    description: 'Выполняет действие над входными данными',
    type: NodeTypesEnum.Output,
    outputType: NodeOutputTypesEnum.SendTelegramMessage,
    icon: IconThree,
  },
  {
    name: 'Отправить письмо',
    group: "action",
    description: 'Выполняет действие над входными данными',
    type: NodeTypesEnum.Output,
    outputType: NodeOutputTypesEnum.SendEmail,
    icon: IconThree,
  },
]

export default function Blocks() {
  const {createNode, createOutputNode} = useFlowContext()

  return (
    <div className="">
      <Popover className="relative">
        {({ open }) => (
          <>
            <Popover.Button
              className={`
                ${open ? '' : 'text-opacity-90'}
                group flex w-full items-center rounded-md bg-indigo-700 px-3 py-2 text-sm font-medium text-white hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
            >
              <TbPlugConnected
                className={`${open ? '' : 'text-opacity-70'}
                  mr-3 h-5 w-5 text-indigo-300 transition duration-150 ease-in-out group-hover:text-opacity-80`}
                aria-hidden="true"
              />
              <span>Блоки</span>
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute bottom-12 left-1/2 z-10 mt-3 w-screen max-w-sm -translate-x-1/2 transform px-4 sm:px-0 lg:max-w-3xl">
                <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white">
                  <Tab.Group>
                    <Tab.List className="border-b px-4 h-full flex">
                      <Tab key="base"
                        className={({ selected }) => `px-2 pt-4 pb-2 text-base font-medium h-full flex border-b-2 ${selected ? "border-blue-600 text-blue-600" : "border-white text-slate-600"}`}
                      >
                        Источники
                      </Tab>
                      <Tab key="loop"
                        className={({ selected }) => `px-2 pt-4 pb-2 text-base font-medium h-full flex border-b-2 ${selected ? "border-blue-600 text-blue-600" : "border-white text-slate-600"}`}
                      >
                        Циклы
                      </Tab>
                      <Tab key="actions"
                        className={({ selected }) => `px-2 pt-4 pb-2 text-base font-medium h-full flex border-b-2 ${selected ? "border-blue-600 text-blue-600" : "border-white text-slate-600"}`}
                      >
                        Действия
                      </Tab>
                    </Tab.List>
                    <Tab.Panels className="h-[16rem] overflow-scroll">
                      <Tab.Panel>
                      <div className="relative grid gap-8 bg-white p-6 lg:grid-cols-2">
                        {blocks.filter(b => b.group === "base").map((item) => (
                          <button
                            key={item.name}
                            onClick={() => createNode(item.type)}
                            className="-m-3 flex text-left items-center rounded-lg p-2 transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                          >
                            <div className="flex h-10 w-10 shrink-0 items-center justify-center text-white sm:h-12 sm:w-12">
                              <item.icon aria-hidden="true" />
                            </div>
                            <div className="ml-4">
                              <p className="text-sm font-medium text-gray-900">
                                {item.name}
                              </p>
                              <p className="text-sm text-gray-500">
                                {item.description}
                              </p>
                            </div>
                          </button>
                        ))}
                      </div>
                      </Tab.Panel>
                      <Tab.Panel>
                      <div className="relative grid gap-8 bg-white p-6 lg:grid-cols-2">
                        {blocks.filter(b => b.group === "loop").map((item) => (
                          <button
                            key={item.name}
                            onClick={() => createNode(item.type)}
                            className="-m-3 flex text-left items-center rounded-lg p-2 transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                          >
                            <div className="flex h-10 w-10 shrink-0 items-center justify-center text-white sm:h-12 sm:w-12">
                              <item.icon aria-hidden="true" />
                            </div>
                            <div className="ml-4">
                              <p className="text-sm font-medium text-gray-900">
                                {item.name}
                              </p>
                              <p className="text-sm text-gray-500">
                                {item.description}
                              </p>
                            </div>
                          </button>
                        ))}
                      </div>
                      </Tab.Panel>
                      <Tab.Panel>
                      <div className="relative grid gap-8 bg-white p-6 lg:grid-cols-2">
                        {blocks.filter(b => b.group === "action").map((item) => (
                          <button
                            key={item.name}
                            onClick={() => createOutputNode(item.type, item.outputType!)}
                            className="-m-3 flex text-left items-center rounded-lg p-2 transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                          >
                            <div className="flex h-10 w-10 shrink-0 items-center justify-center text-white sm:h-12 sm:w-12">
                              <item.icon aria-hidden="true" />
                            </div>
                            <div className="ml-4">
                              <p className="text-sm font-medium text-gray-900">
                                {item.name}
                              </p>
                              <p className="text-sm text-gray-500">
                                {item.description}
                              </p>
                            </div>
                          </button>
                        ))}
                      </div>
                      </Tab.Panel>
                    </Tab.Panels>
                  </Tab.Group>
                  <div className="bg-gray-50 p-4">
                    <a
                      href="##"
                      className="flow-root rounded-md px-2 py-2 transition duration-150 ease-in-out hover:bg-gray-100 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                    >
                      <span className="flex items-center">
                        <span className="text-sm font-medium text-gray-900">
                          Документация
                        </span>
                      </span>
                      <span className="block text-sm text-gray-500">
                        Start integrating products and tools
                      </span>
                    </a>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  )
}

function IconOne() {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="48" height="48" rx="8" fill="#FFEDD5" />
      <path
        d="M24 11L35.2583 17.5V30.5L24 37L12.7417 30.5V17.5L24 11Z"
        stroke="#FB923C"
        strokeWidth="2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.7417 19.8094V28.1906L24 32.3812L31.2584 28.1906V19.8094L24 15.6188L16.7417 19.8094Z"
        stroke="#FDBA74"
        strokeWidth="2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.7417 22.1196V25.882L24 27.7632L27.2584 25.882V22.1196L24 20.2384L20.7417 22.1196Z"
        stroke="#FDBA74"
        strokeWidth="2"
      />
    </svg>
  )
}

function IconTwo() {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="48" height="48" rx="8" fill="#FFEDD5" />
      <path
        d="M28.0413 20L23.9998 13L19.9585 20M32.0828 27.0001L36.1242 34H28.0415M19.9585 34H11.8755L15.9171 27"
        stroke="#FB923C"
        strokeWidth="2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.804 30H29.1963L24.0001 21L18.804 30Z"
        stroke="#FDBA74"
        strokeWidth="2"
      />
    </svg>
  )
}

function IconThree() {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="48" height="48" rx="8" fill="#FFEDD5" />
      <rect x="13" y="32" width="2" height="4" fill="#FDBA74" />
      <rect x="17" y="28" width="2" height="8" fill="#FDBA74" />
      <rect x="21" y="24" width="2" height="12" fill="#FDBA74" />
      <rect x="25" y="20" width="2" height="16" fill="#FDBA74" />
      <rect x="29" y="16" width="2" height="20" fill="#FB923C" />
      <rect x="33" y="12" width="2" height="24" fill="#FB923C" />
    </svg>
  )
}
