import { memo, FC } from 'react';
import { Handle, Position, NodeProps } from 'reactflow';
import { useFlowContext } from '../../../../core/providers/flow';
import { TbSettings, TbTrash } from 'react-icons/tb';
import { NodeOutputTypesEnum } from '../../../../core/graphql/types';


const NodeOutput: FC<NodeProps> = (props) => {
  const {id, data, selected} = props
  const {chooseNode, deleteNode} = useFlowContext()

  const actionName = (type: NodeOutputTypesEnum) => {
    switch (type) {
      case NodeOutputTypesEnum.CreateCard: return "Создание."
      case NodeOutputTypesEnum.UpdateCard: return "Обновление."
      case NodeOutputTypesEnum.SendEmail: return "Письмо."
      case NodeOutputTypesEnum.SendTelegramMessage: return "Telegram."
      case NodeOutputTypesEnum.CreateNotification: return "Уведомление."    
      default:
        break;
    } 
  }

  return (
    <div className='w-64'>
      <div className={`shadow-sm rounded bg-white ${selected ? "ring-2 ring-blue-400" : "ring-1 ring-slate-200"}`}>
        <div className='flex h-8 w-full px-2 border-b rounded-t items-center justify-between bg-blue-800'>
          <div className='text-base font-medium text-white truncate'>{data.name} {actionName(data.options.outputNodeType)}</div>
          <div className='flex items-center gap-2'>
            {/* <button type='button' className='text-indigo-400 hover:text-indigo-200' onClick={() => deleteNode(id)}><TbTrash /></button> */}
            <button type='button' className='text-indigo-400 hover:text-indigo-200' onClick={() => chooseNode(id)}><TbSettings /></button>
          </div>
        </div>
        <div className='h-6 flex border-b px-2 justify-between items-center text-xs text-slate-600'>
          <span>Данные</span>
          <Handle type="target" position={Position.Left} style={{top: 43}} className="h-4 !bg-blue-500" />
          {[NodeOutputTypesEnum.CreateCard, NodeOutputTypesEnum.UpdateCard].includes(data.options.outputNodeType) &&
            <>
              <span>Выполнено</span>
              <Handle type="source" position={Position.Right} id='SUCCESS' style={{top: 43}} className="h-4 !bg-green-500" />
            </>
          }

        </div>
        <div className='min-h-[40px] px-2 py-2 text-slate-800 text-sm'>
          {/* {description(data.options.triggerType)} */}
        </div>
        {[NodeOutputTypesEnum.CreateCard, NodeOutputTypesEnum.UpdateCard].includes(data.options.outputNodeType) &&
          <div className='h-6 border-t items-center flex justify-end'>
            <div className='px-1 text-xs text-slate-600'>Ошибка</div>
            <Handle type="source" position={Position.Right} id='error' style={{position: "relative", top: 8}} className="h-4 !bg-red-500" />
          </div>  
        }        
      </div>
    </div>

  );
};

export default memo(NodeOutput);