import { useEffect, useState } from "react";
import { Block, BlockSummaryOptions, Field, ValuePayload } from "../../../../../core/graphql/types";
import { useSpaceStore } from "../../../../../core/store/store";
import { getSummaryValue } from "../../../../../core/Controllers/Summary";



export default function SummaryValue({block}:{block: Block}) {
  const store = useSpaceStore().data;
  const init: string = `block:${block.id}`;
  const {cards, fields, currentView, currentCardId, values} = store[init]
  const [valuesForSummary, setValuesForSummary] = useState<ValuePayload[]>()
  const [stackedField, setStackedField] = useState<Field>(fields.find((f: Field) => f.id === (block.options as BlockSummaryOptions).stackedFieldId))

  useEffect(() => {
    if(!(block.options as BlockSummaryOptions).stackedFieldId) {
      return
    }
    setValuesForSummary(values.filter((v: ValuePayload) => v.fieldId === (block.options as BlockSummaryOptions).stackedFieldId))
  },[cards, values])


  return (
    <div className="flex w-full text-base justify-between">
      {(block.options as BlockSummaryOptions).showTitle && <span>{(block.options as BlockSummaryOptions).title}</span> }
      <span className="font-semibold">{(block.options as BlockSummaryOptions).summaryType! && stackedField && getSummaryValue(valuesForSummary!, (block.options as BlockSummaryOptions).summaryType!, stackedField)}</span>
    </div>
  )
}