import { Tab } from '@headlessui/react'
import BodyPanel from './body';
import AuthPanel from './auth';
import HeadersPanel from './headers';
import ParametersPanel from './parameters';


export default function RequestTabs() {
  return (
    <div className='w-full h-full bg-white pb-10'>
      <Tab.Group>
        <Tab.List className="flex h-8 items-center px-4 overflow-hidden text-sm border-b">
          <Tab
            key="parameters"
            className={({ selected }) => {
              return `h-full font-medium text-center focus:outline-none hover:text-slate-800 hover:bg-slate-300
              ${selected ? 'text-white bg-indigo-600' : 'text-slate-700 hover:bg-white/[0.12] '}`
            }}
          >
            <div className='px-3'>Параметры</div>
          </Tab>
          <Tab
            key="authorization"
            className={({ selected }) => {
              return `h-full font-medium text-center focus:outline-none hover:text-slate-800 hover:bg-slate-300
              ${selected ? 'text-white bg-indigo-600' : 'text-slate-700 hover:bg-white/[0.12] '}`
            }}
          >
            <div className='px-3'>Авторизация</div>
          </Tab>
          <Tab
            key="headers"
            className={({ selected }) => {
              return `h-full font-medium text-center focus:outline-none hover:text-slate-800 hover:bg-slate-300
              ${selected ? 'text-white bg-indigo-600' : 'text-slate-700 hover:bg-white/[0.12] '}`
            }}
          >
            <div className='px-3'>Заголовки</div>
          </Tab>
          <Tab
            key="body"
            className={({ selected }) => {
              return `h-full font-medium text-center focus:outline-none hover:text-slate-800 hover:bg-slate-300
              ${selected ? 'text-white bg-indigo-600' : 'text-slate-700 hover:bg-white/[0.12] '}`
            }}
          >
            <div className='px-3'>Тело запроса</div>
          </Tab>
        </Tab.List>
        <Tab.Panels className="min-h-min h-full w-full overflow-y-scroll px-4">
          <Tab.Panel><ParametersPanel /></Tab.Panel>
          <Tab.Panel><AuthPanel /></Tab.Panel>
          <Tab.Panel><HeadersPanel /></Tab.Panel>
          <Tab.Panel><BodyPanel /></Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </div>
  )
}
