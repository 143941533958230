import { useForm, Controller } from "react-hook-form";
import { SingleValue } from 'react-select';
import { useFetcherContext } from "../../../../../core/providers/fetcher";
import MatrNarrowSelect from "../../../../../components/ui/select/narrow";
import { useEffect } from "react";
import { FetcherRequestOutputType } from "../../../../../core/graphql/types";

const outputOptions = [
  {label: "Одиночная запись", value: FetcherRequestOutputType.Single},
  {label: "Первая запись", value: FetcherRequestOutputType.FirstRecord},
  {label: "Все записи", value: FetcherRequestOutputType.AllRecords},
]


export default function ResponseSettings():JSX.Element {
  const {request, onChangeRequestDepth, onChangeRequestOutput} = useFetcherContext()
  const methods = useForm({defaultValues: {depth: request?.depth, output: request?.output}});
  const {control, reset} = methods;

  useEffect(() => {
    reset({depth: request?.depth, output: request?.output})
  },[request])
  

  function handleChangeDepth(val: any, onChange: { (...event: any[]): void; (arg0: any): void; }) {
    onChangeRequestDepth(val?.id!)
    onChange(val?.id!)
  }

  function handleChangeOutput(val: {value: FetcherRequestOutputType}, onChange: { (...event: any[]): void; (arg0: any): void; }) {
    onChangeRequestOutput(val?.value!)
    onChange(val?.value!)
  }

  return (
    <>
        <div className='flex flex-col w-full px-4'>
          <Controller
            name={`depth`}
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value, ref }}) => (
              <div className="flex flex-col w-full mt-2 relative">
                <label className="font-medium text-gray-600 text-sm">Выберите глубину получения данных:</label>
                <div className="mt-2">
                  <MatrNarrowSelect
                    isSearchable={false}
                    isClearable={false}
                    styles={{control: () => ({border: 0})}}
                    classNamePrefix="matr_select_custom"
                    placeholder="Выберите глубину"
                    getOptionLabel = {(option: any) => option.title}
                    getOptionValue = {(option: any) => option.id}
                    defaultValue={request?.depths![0]}
                    value={request?.depths!.find((d: any) => d?.id! === value)}
                    onChange={(val) => handleChangeDepth(val, onChange)}
                    options={request?.depths! as any}
                  />
                </div>
              </div>
            )}
          />
          <Controller
            name={`output`}
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value, ref }}) => (
              <div className="flex flex-col w-full mt-2 relative">
                <label className="font-medium text-gray-600 text-sm">Выберите что возвращает ответ:</label>
                <div className="mt-2">
                  <MatrNarrowSelect
                    isSearchable={false}
                    isClearable={false}
                    styles={{control: () => ({border: 0})}}
                    classNamePrefix="matr_select_custom"
                    placeholder="Выберите глубину"
                    value={outputOptions.find((o: any) => o?.value! === value)}
                    onChange={(val: any) => handleChangeOutput(val, onChange)}
                    options={outputOptions}
                  />
                </div>
              </div>
            )}
          />
        </div>
    </>
  )
}