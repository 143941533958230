import { InsertedAtIcon } from "../../icons";
import { Inserted_At } from "../../../core/graphql/types";
import "moment/locale/ru";
import {dateFormats, timeFormats} from "../../../core/enums";
import moment from "moment";

function datetimeFormat(value: string, attr: Inserted_At) {
  let format = "";

  if(!attr.includeTime) {
    format = dateFormats.find(format => format.name === attr.dateFormat)?.format!;
  } else {
    format = dateFormats.find(format => format.name === attr.dateFormat)?.format! + ' ' + timeFormats.find(format => format.name === attr.timeFormat)?.format!;
  }
  return (
    moment(new Date(value)).format(format)
  )
}

export function FieldInsertedAt({ value, field }: {value: string, field: any}) {
  return (
    <div className="relative flex flex-row justify-between items-center pb-1.5 border-b">
      <div className="flex items-center h-full">
        <div className="text-slate-400 pr-2"><InsertedAtIcon /></div>
        <label className="text-slate-500 text-sm">{field.title}</label>
      </div>
      <span className="px-2 text-sm bg-yellow-200 text-slate-800 rounded-sm">{value ? datetimeFormat(value, field.attributes) : ""}</span> 
      {/* <input
        disabled={true}
        className="matr-form-input"
        value={value ? datetimeFormat(value, field.attributes) : ""}
      /> */}
    </div>
  );
}