/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from "react";
import { useFormContext, Controller } from "react-hook-form";
import { BiToggleLeft, BiToggleRight } from "react-icons/bi";
import Select, { SingleValue } from "react-select";
import { Board, BoardView, Relation } from "../../../../../core/graphql/types";
import { customStyles } from "../../../../../utils";
import { useBoard } from "../../../../../core/providers/board";
import { useSpaceStore } from "../../../../../core/store/store";

function FieldRelation({attributes}: {attributes: Relation}) {
  const {initId, initType} = useBoard();
  const init: string = `${initType}:${initId}`;
  const store = useSpaceStore()
  const {sources} = store.data[init];

  const methods = useFormContext();
  const {register, watch, control, unregister, setValue, resetField, formState: {errors}} = methods;
  const [boardsList, setBoardsList] = useState<Board[]>([]);
  const [viewsList, setViewsList] = useState<BoardView[]>([]);

  console.log(attributes)

  useEffect(() => {
    unregister('attributes');
  },[])

  useEffect(() => {
    register('attributes.type');
    setValue('attributes.type', "RELATION");

    setValue('attributes.boardId', attributes.boardId ? attributes.boardId : "");
    setValue('attributes.multiple', attributes.multiple ? attributes.multiple : false);
    setValue('attributes.searchable', attributes.searchable ? attributes.searchable: true);
    setValue('attributes.boardViewId', attributes.boardViewId ? attributes.boardViewId : "");
  },[attributes])

  useEffect(() => {
    if(!sources || !sources.length) {
      return
    }

    setBoardsList(sources)
    setViewsList(sources.find((board: Board) => board.id === watch('attributes.boardId'))?.views as BoardView[])
  },[sources])

  useEffect(() => {
    if(!watch('attributes.boardId') || !sources || !sources.length) {
      return
    }
    setViewsList(sources.find((board: Board) => board.id === watch('attributes.boardId'))?.views as BoardView[])
  },[watch('attributes.boardId')])


  const handelChangeBoard = (val: SingleValue<Board>, onChange: { (...event: any[]): void; (arg0: any): void; }) => {
    onChange(val?.id)
    resetField('attributes.boardViewId')
  }

  const handelChangeView = (val: SingleValue<BoardView>, onChange: { (...event: any[]): void; (arg0: any): void; }) => {
    onChange(val?.id)
    setValue('attributes.boardViewId', val?.id)
  }

  if(!sources || !sources.length) { 
    return (<div className="flex py-4 text-sm justify-center">Подключите источники, чтобы создавать связи с ними.</div>
  )}

  return (

    <> 
      {errors.attributes && <span className="text-red-600 text-xs font-normal">Oбязательное поле.</span>}
      <div className="relative">
        <label className="font-medium text-gray-600 text-sm">Выберите источник:</label>
        <Controller
          name="attributes.boardId"
          control={control}
          rules={{ required: true }}
          render={({ field: { onChange, value, ref }}) => (
            <div className="mt-2">
              <Select
                styles={customStyles}
                classNamePrefix="matr_select_form"
                placeholder="Выберите доску источник"
                isLoading = {!sources}
                getOptionLabel = {(option) => option.name }
                getOptionValue = {(option) => option.id }
                defaultValue={boardsList.find(per => per?.id === value)}
                value={boardsList.find(per => per?.id === value)}
                onChange={val => handelChangeBoard(val, onChange)}
                options={boardsList}
              />
            </div>
          )}
        />
      </div>
      <div className="relative">
        <label className="font-medium text-gray-600 text-sm">Выберите вид:</label>
        <Controller
          name="attributes.boardViewId"
          control={control}
          rules={{ required: true }}
          render={({ field: { onChange, value, ref }}) => (
            <div className="mt-2">
              <Select
                styles={customStyles}
                classNamePrefix="matr_select_form"
                placeholder="Выберите поле"
                getOptionLabel = {(option) => option.name }
                getOptionValue = {(option) => option.id }
                defaultValue={viewsList && viewsList.find(v => v.primary === true)}
                value={viewsList && viewsList.find(per => per.id === value)}
                onChange={val => handelChangeView(val, onChange)}
                options={viewsList}
              />
            </div>
          )}
        />
      </div>
      <div className="relative inline-flex">
        <label className="font-medium text-slate-800 text-sm flex items-center gap-2">
          <Controller
            name="attributes.multiple"
            control={control}
            render={({ field: { onChange, value, ref }}) => (
              <div 
                className={`flex items-center  text-sm rounded-md font-normal justify-center cursor-default`} 
                onClick={() => onChange(!value)}
              >
                {value ? <BiToggleLeft className='h-6 w-6 text-green-600'/> : <BiToggleRight className='h-6 w-6 text-slate-400'/>}
              </div>
            )}
          />
          Разрешить множественные связи
        </label>
      </div>
      <div className="relative inline-flex">
        <label className="font-medium text-slate-800 text-sm flex items-center gap-2">
          <Controller
            name="attributes.searchable"
            control={control}
            render={({ field: { onChange, value, ref }}) => (
              <div 
                className={`flex items-center  text-sm rounded-md font-normal justify-center cursor-default`} 
                onClick={() => onChange(!value)}
              >
                {value ? <BiToggleLeft className='h-6 w-6 text-green-600'/> : <BiToggleRight className='h-6 w-6 text-slate-400'/>}
              </div>
            )}
          />
          Разрешить поиск по связанной доске
        </label>
      </div>
    </>
  );
}
export default FieldRelation;