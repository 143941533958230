import { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useConfirmEmailMutation } from "../../core/graphql/types";



const EmailConfiramtionPage = () => {
  let params = useParams()
  let navigate = useNavigate()

  const [, confirm] = useConfirmEmailMutation()

  useEffect(() => {
    if(!params.token) {
      navigate('/')
      return
    }
    confirm({token: params.token})

    navigate('/')
  },[])

  return <></>
}

export default EmailConfiramtionPage;