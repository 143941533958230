import { FieldForm } from "../../Fields/form";
import { MatrPortal } from "../../ui/portal";
import { FieldModalProps } from "./FieldModalProps";

const ModalField = ({open, close, field, column, fields, boardId}: FieldModalProps) => {
  if (!open) {
    return null;
  }

  return (
    <MatrPortal>
      <div className="fixed inset-0 overflow-y-auto" role="dialog" style={{zIndex: 21}}>
        <div className='relative h-full flex items-start justify-center py-14'>
          <div className="fixed inset-0 bg-black opacity-30 cursor-default" role="button" tabIndex={0} onClick={close}/>
          <div className="max-w-2xl inline-block w-full text-left align-middle transition-all transform bg-white shadow-lg rounded-lg px-6 py-5">
            <FieldForm field={field} fields={fields} boardId={boardId} close={close} column={column}/>
          </div>
        </div>
      </div>
    </MatrPortal>
  )
  
};

export default ModalField;