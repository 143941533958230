import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";

import * as Yup from 'yup';

import { TbSquaresFilled, TbX } from "react-icons/tb";
import { Block } from "../../../../../core/graphql/types";
import { usePanel } from "../../../../../core/providers/panel";
import BlockSettings from "./settings";

// Написать валидацию для каждого блока
const validationSchema = Yup.object().shape({

});

export const BlockEdit = ({block, onClose}:{block: Block, onClose: () => void}) => {
  const {page, updateBlock} = usePanel()
  const methods = useForm({defaultValues: {...block}});
  const { getValues, formState: { errors } } = methods;
  const [ error, setError ] = useState(null);

  const onSubmit = () => { 
    validationSchema.isValid(getValues()).then(res => {
      if(res) {
        let data = getValues()
        setError(null)
        updateBlock(data as Block)
        onClose()
      } else {
        validationSchema.validate(getValues()).catch(res => setError(res.message))
      }
    })
  };


  return (
    <>
      <div className="flex items-center justify-between">
        <div className="flex items-center space-x-2">
          <div className="py-1 px-2 bg-slate-100 flex space-x-2 rounded">
            <TbSquaresFilled size={20} className="text-indigo-600"/>
            <div className="flex font-medium text-sm">{page.name}</div>
          </div>
          <div className="flex font-normal text-sm">Настройки блока</div>
        </div>
        <button onClick={onClose}><TbX size={18}/></button>
      </div>
      <FormProvider {...methods}>
        <div className="flex flex-col relative w-full space-y-4">
          <BlockSettings type={block.type!}/>
          <div className="flex w-full h-full justify-end pt-4 space-x-4">
            <button
              type="button"
              className="rounded text-sm py-2 px-4 bg-slate-200 text-slate-400 hover:bg-slate-300 hover:text-slate-500"
              onClick={onClose}
            >Отменить</button>
            <button type="button" onClick={() => onSubmit()} className="rounded text-sm py-2 px-4 bg-indigo-600 text-white hover:bg-indigo-700">Сохранить</button>
          </div>
        </div>
      </FormProvider>
    </>

  )
};