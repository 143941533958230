import React, {Fragment, memo} from "react";
import { Transition, Dialog } from "@headlessui/react";
import { ISimpleModal } from "../../../core/types/global";

export const SimpleModal: React.FC<ISimpleModal> = memo(({open, close, children, isWide}) => {
  return (
    <Transition show={open} as={Fragment}>
      <Dialog 
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        static
        open={open}
        onClose={close}
      >
      <div className="px-4 text-center mt-14">
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-30"
          leave="ease-in duration-200"
          leaveFrom="opacity-30"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
        </Transition.Child>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <div className={`${isWide ? "max-w-5xl" : "max-w-lg" } inline-block w-full text-left align-middle transition-all transform bg-white shadow rounded-lg p-8`}>
            {React.cloneElement(children as React.ReactElement<any>, { closeModal: close })}
          </div>
        </Transition.Child>
      </div>
    </Dialog>
  </Transition>
  )
});