import { useEffect, useState } from 'react';

import { AttachmentValue, useAddAttachmentsMutation, useRemoveAttachmentMutation} from '../../../core/graphql/types';
import type { FieldAttachmentEditorProps } from './types';

import AttachmentUploader from '../../Uploaders/AttachmentUploader';
import { HiDocument } from 'react-icons/hi';
import Viewer from '../../Viewer/viewer';
import { AttachmentIcon } from '../../icons';

const storage_url = process.env.REACT_APP_STORAGE_URL ? process.env.REACT_APP_STORAGE_URL : ""

export default function FieldAttachment({field, card, addAttachment, deleteAttachment}:FieldAttachmentEditorProps) {
  const [value, setValue] = useState((card[field.id] as unknown as AttachmentValue)?.attachments! ? (card[field.id] as unknown as AttachmentValue)?.attachments! : [])
  const attr = field.attributes;
  
  const [attachments, setAttachments] = useState<any>([])
  
  let [isOpen, setIsOpen] = useState(false)
  let [currentFileNo, setCurrentFileNo] = useState(0)


  function closeViewer() {
    setCurrentFileNo(0)
    setIsOpen(false)
  }

  function openViewer(index: number) {
    setCurrentFileNo(index)
    setIsOpen(true)
  }

  useEffect(() => {
    console.log("UPDAED")
    setValue((card[field.id] as unknown as AttachmentValue)?.attachments! ? (card[field.id] as unknown as AttachmentValue)?.attachments! : [])
  }, [card[field.id].attachments])

  useEffect(() => {
    setAttachments(value?.filter(i => i?.id).map((item, index) => {
      if(item?.thumbnail) {
        if(item.contentType?.startsWith("image/")) {
          return (
            <div className="flex col-span-1 h-full w-full items-center justify-center ring-1 rounded-sm hover:ring-2 ring-slate-200 hover:ring-sky-400" key={item?.id} onClick={() => openViewer(index)}>
              <img className="w-fit mx-auto h-full object-cover object-center rounded-sm" alt={`${item?.filename!}`} src={`${storage_url}${item?.path!}/thumb/${item.filename}`}></img>
            </div>
          )
        } else {
          return (
            <div className="flex col-span-1 h-full w-full items-center justify-center ring-1 rounded-sm hover:ring-2 ring-slate-200 hover:ring-sky-400" key={item?.id} onClick={() => openViewer(index)}>
              <img className="w-full mx-auto h-full object-cover object-center rounded-sm" alt={`${item?.filename!}`} src={`${storage_url}${item?.path!}/thumb/${item?.filename!.replace(/\.[^/.]+$/, "")}.png`}></img>
            </div>
          )
        }
      } else {
        return (
          <div className="flex col-span-1 h-full w-full items-center justify-center ring-1 rounded-sm hover:ring-2 ring-slate-200 hover:ring-sky-400" key={item?.id} onClick={() => openViewer(index)}>
            <HiDocument className="h-full"/>
          </div>
        )
      }
    }))
  },[value])

  function handleChange(files: any) {
    let id = (card[field.id] as unknown as AttachmentValue).id
    let attachments = files.map((f: { file_id: string; }) => f.file_id)

    addAttachment(id, attachments)
    // setValue((row[column.key as keyof Row] as unknown as AttachmentValue)?.attachments!)

    // addAttachment({id: id, files: attachments}).then(resp => {
    //   if(resp.error) {
    //     return
    //   }
    //   setValue((row[column.key as keyof Row] as unknown as AttachmentValue)?.attachments!)
    // })
  }

  function onRemove(index: number) {
    let value = (card[field.id] as unknown as AttachmentValue)!
    let attachment_id = value.attachments![index]?.id!

    deleteAttachment(attachment_id, value)
    closeViewer()
  }

  return (
    <div>
      <div className="relative flex flex-row justify-between items-center pb-1.5">
        <div className="flex items-center h-full">
          <div className="text-slate-400 pr-2"><AttachmentIcon /></div>
          <label className="text-slate-500 text-sm">{field.title}</label>
        </div>
        <div className='p-1.5'>
          <AttachmentUploader buttonType="iconText" onChange={handleChange}/>
        </div>
      </div>
      <div className="w-full z-auto h-full focus:outline-none">
        <div className="flex w-full bg-white items-center">
          
          <div className="truncate flex flex-col bg-white z-10 w-full rounded-sm" >
            {attachments.length > 0 && <div className="grid grid-cols-3 flex-nowrap h-full overflow-hidden w-full gap-2 p-2">{attachments}</div>}
          </div>
        </div>
      </div>
      {isOpen && <Viewer open={isOpen} close={closeViewer} attachments={value} currentFileNo={currentFileNo} onRemove={onRemove}/>}
    </div>
  )
}