import React, {Fragment} from 'react'
import PropTypes from 'prop-types'
import { Navigate as navigate } from 'react-big-calendar';
import { Menu, Transition } from '@headlessui/react'
import { HiChevronLeft, HiChevronRight } from 'react-icons/hi';

function ViewNamesGroup({ views: viewNames, view, messages, onView }) {
  return (
    <div className='col-span-1 justify-start flex flex-row space-x-1 h-full items-center text-sm'>
      {viewNames.map((name) => (
        <button
          type="button"
          key={name}
          className={`${view === name ? "bg-indigo-600 text-white" : "bg-slate-200 text-slate-600"} px-3 py-1.5 font-medium rounded-md`}
          onClick={() => onView(name)}
        >
          {messages[name]}
        </button>
      ))}
    </div>
  )
}


ViewNamesGroup.propTypes = {
  messages: PropTypes.object,
  onView: PropTypes.func,
  view: PropTypes.string,
  views: PropTypes.array,
}

export default function CustomToolbar({
  // date, // available, but not used here
  label,
  localizer: localData,
  onNavigate,
  onView,
  view,
  views,
}) {
  return (
    <div className="grid grid-cols-3 justify-between items-center h-10 px-4">
      <ViewNamesGroup
        view={view}
        views={views}
        messages={localData.messages}
        onView={onView}
      />
      <span className='col-span-1 flex justify-center capitalize text-slate-600'>{label}</span>
      <div className='flex col-span-1 justify-end space-x-4'>
        <span className='flex space-x-2'>
          <button
            type="button"
            className='px-3 py-1 text-sm bg-slate-200 text-slate-600 rounded-md font-medium'
            onClick={() => onNavigate(navigate.TODAY)}
            aria-label={localData.messages.today}
          >
            Сегодня
          </button>
          <button
            type="button"
            className='p-1 bg-slate-200 text-slate-600 rounded-md'
            onClick={() => onNavigate(navigate.PREVIOUS)}
            aria-label={localData.messages.previous}
          >
            <HiChevronLeft size={20}/>
          </button>
          <button
            type="button"
            className='p-1 bg-slate-200 text-slate-600 rounded-md'
            onClick={() => onNavigate(navigate.NEXT)}
            aria-label={localData.messages.next}
          >
            <HiChevronRight size={20}/>
          </button>
        </span>
      </div>

    </div>
  )
}
CustomToolbar.propTypes = {
  date: PropTypes.instanceOf(Date),
  label: PropTypes.string,
  localizer: PropTypes.object,
  messages: PropTypes.object,
  onNavigate: PropTypes.func,
  onView: PropTypes.func,
  view: PropTypes.string,
  views: PropTypes.array,
}


