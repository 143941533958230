/* eslint-disable @typescript-eslint/no-unused-vars */
import { NavLink } from 'react-router-dom';
import _ from "lodash";
import { useAuth } from '../../../core/providers/auth';
import { useSpace } from '../../../core/providers/space';
import { SimpleModal } from '../../modals/simple';
import { HiPlus } from 'react-icons/hi';
import { BoardFormCreate } from '../../../containers/board/form/create';
import { BoardFormImport } from '../../../containers/board/form/import';
import { Board } from '../../../core/graphql/types';
import { useState } from 'react';
import { TbArrowsSort, TbChevronRight, TbFolderPlus, TbSquareRoundedPlusFilled, TbTableImport, TbTablePlus } from 'react-icons/tb';
import { BoardPreview } from './preview';
import { Disclosure } from '@headlessui/react';
import { MatrMediumDialog } from '../../ui/portal';

const BoardsMenu = () => {
  const {boards, groups} = useSpace()

  const [ open, setOpen ] = useState(false);
  const [ modalType, setModalType ] = useState("");

  function openModal(type: string) { 
    setModalType(type)
    setOpen(true)
  };
  
  function closeModal() { 
    setModalType("")
    setOpen(false)
  };

  function onCreate(board: Board) {
    // setListBoards([...listBoards, board])
  }

  function getModal() {
    switch (modalType) {
      case "create":
        return (
          <MatrMediumDialog open={open} onClose={closeModal}>
            <BoardFormCreate closeModal={closeModal} onCreate={onCreate}/>
          </MatrMediumDialog>
        )
      // case "template":
      //   return (
      //     <SimpleModal open={open} close={closeModal}>
      //       <BoardFormCreateFromTemplate closeModal={closeModal} onCreate={onCreate}/>
      //     </SimpleModal>
      //     )
      case "import":
        return (
          <MatrMediumDialog open={open} onClose={closeModal}>
            <BoardFormImport closeModal={closeModal} onCreate={onCreate}/>
          </MatrMediumDialog>
          )
      default:
        break;
    }
  }

  return (
    <>  
        <div className='px-2 pt-2'>
          <div className="flex items-center text-base font-semibold text-slate-500 py-2 px-2 max-h-9 mb-1">
            <span>Таблицы</span>
          </div>
          <div className='px-1 flex items-center max-h-9 py-1 justify-between text-slate-600 rounded-sm mb-2'>
            <div className='flex space-x-2'>
              <TbTablePlus className='px-1 w-6 h-6 hover:bg-slate-200 rounded-sm' onClick={() => openModal("create")}/>
              <TbTableImport className='px-1 w-6 h-6 hover:bg-slate-200 rounded-sm' onClick={() => openModal("import")}/>
            </div>
            <div className='flex space-x-2'>
              <TbArrowsSort className='px-1 w-6 h-6 hover:bg-slate-200 rounded-sm'/>
              <TbFolderPlus className='px-1 w-6 h-6 hover:bg-slate-200 rounded-sm'/>
            </div>
          </div>
          {
            groups.map(group => (
              <Disclosure key={group.id} defaultOpen={true}>
                {({ open, close }) => (
                  <>
                    <Disclosure.Button className="flex items-center justify-center py-1 px-2 space-x-2">
                      <TbChevronRight
                        size={16}
                        className={`${
                          open ? 'rotate-90 transform' : ''
                        } text-slate-500 flex-none`}
                      />
                      <div className="text-base font-medium text-slate-800">{group.name}</div>
                    </Disclosure.Button>
                    <Disclosure.Panel className="pb-2">
                      {_.orderBy(boards.filter(b => b.group?.id === group.id), ['updatedAt'], 'desc').map((board: Board, idx: number) => (
                        <BoardPreview key={idx} board={board}/>
                      ))}
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))
          }
        </div>


        {modalType && open ? getModal() : <></>}
    </>
  );
};

export default BoardsMenu;