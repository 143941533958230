//@ts-nocheck
import React from 'react'
import Document from '@tiptap/extension-document'
import { EditorContent, useEditor, ReactRenderer } from '@tiptap/react'
import Mention from '../components/Mention'
import Paragraph from '@tiptap/extension-paragraph'
import Text from '@tiptap/extension-text'
import { Color } from '@tiptap/extension-color'
import ListItem from '@tiptap/extension-list-item'
import TextStyle from '@tiptap/extension-text-style'
import StarterKit from '@tiptap/starter-kit'


import tippy from 'tippy.js'
import { MentionList } from '../components/MentionList'
import EditorToolbar from '../components/Toolbar'


export const RichTextEditor = ({onChange, value, tokens}) => {

  const tokenOptions = {
    items: ({ query }) => {
      return tokens && tokens.length > 0 ? tokens.filter(item => item.value.toLowerCase().startsWith(query.toLowerCase())) : []
    },
    render: () => {
      let reactRenderer
      let popup
  
      return {
        onStart: props => {
          reactRenderer = new ReactRenderer(MentionList, {
            props,
            editor: props.editor,
          })
  
          if (!props.clientRect) {
            return
          }
  
          popup = tippy('body', {
            getReferenceClientRect: props.clientRect,
            appendTo: () => document.body,
            content: reactRenderer.element,
            showOnCreate: true,
            interactive: true,
            trigger: 'manual',
            placement: 'bottom-start',
          })
        },
  
        onUpdate(props) {
          reactRenderer.updateProps(props)
  
          if (!props.clientRect) {
            return
          }
  
          popup[0].setProps({
            getReferenceClientRect: props.clientRect,
          })
        },
  
        onKeyDown(props) {
          if (props.event.key === 'Escape') {
            popup[0].hide()
  
            return true
          }
  
          return reactRenderer.ref?.onKeyDown(props)
        },
  
        onExit() {
          popup[0].destroy()
          reactRenderer.destroy()
        },
      }
    },
  } 

  const editor = useEditor({
    extensions: [
      Color.configure({ types: [TextStyle.name, ListItem.name] }),
      TextStyle.configure({ types: [ListItem.name] }),
      StarterKit.configure({
        bulletList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
        orderedList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
      }),
      Mention.configure({
        HTMLAttributes: {
          class: 'matr-token-input',
        },
        renderLabel: ({options, node}) => (node && node.attrs && tokens ? tokens.find(t => t.id === node.attrs.id).value : "..."),
        suggestion: tokenOptions,
      }),
    ],
    content: value,
    onUpdate: ({ editor }) => {
      const json = editor.getJSON()
      onChange(json)
    },
  })


  return (
    <>
      <EditorToolbar editor={editor} />
      <EditorContent editor={editor} className="form"/>
    </>
  )
}

export default RichTextEditor;