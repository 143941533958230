import { useEffect, useState } from "react";
import { TbFile } from "react-icons/tb";
import { useBoard } from "../../../core/providers/board";
import { useSpaceStore } from "../../../core/store/store";
import { Row } from "../MattrGrid/types";
import { BlockRecordsListOptions, Card, CardWithValues, ValuePayload } from "../../../core/graphql/types";

interface DataRow {
  id: string;
  image: string | null | undefined;
  title: string | null | undefined;
  second: string | null | undefined;
  third: string | null | undefined;
  actions: {canEdit: boolean};
}

export default function ViewList() {
  const {initId, initType, board, cards, currentView, values, setEditMode, setCurrentCard, setCurrentCardId, setCardModalIsOpened} = useBoard();
  const init: string = `${initType}:${initId}`;
  const [data, setData] = useState<DataRow[]>([])

  useEffect(() => {
    let titleField = (currentView?.options! as BlockRecordsListOptions).titleFieldId!
    let secondField = (currentView?.options! as BlockRecordsListOptions).secondFieldId!
    let thirdField = (currentView?.options! as BlockRecordsListOptions).thirdFieldId!
    let imageField = (currentView?.options! as BlockRecordsListOptions).imageFieldId!


    setData(cards.map((card: Card) => {
      return {
        id: card.id,
        image: values?.find(v => v.cardId === card.id && v.fieldId === imageField)?.value,
        title: values?.find(v => v.cardId === card.id && v.fieldId === titleField)?.value,
        second: values?.find(v => v.cardId === card.id && v.fieldId === secondField)?.value,
        third: values?.find(v => v.cardId === card.id && v.fieldId === thirdField)?.value,
        actions: {
          canAdd: currentView?.permissions?.canAdd!,
          canEdit: currentView?.permissions?.canEdit!,
        }
      }
    }))
  },[cards, values])

  function onRowClick(id: string) {
    if(!currentView?.permissions?.canEdit) {return}
    setCardModalIsOpened(true, init)
    setCurrentCardId(id, init)
    setCurrentCard({...(cards.find(c => c.id === id) as CardWithValues), values: values.filter(v => v.cardId === id)}, init)
  }

  return (
    <div className="flex flex-col space-y-1">
      {data.map((dataRow, index) => {
        return (
          <div 
            key={dataRow.id} 
            className="text-base bg-slate-50 min-h-[40px] h-[40px] rounded items-center flex w-full hover:bg-slate-100 "
            onClick={() => onRowClick(dataRow.id)}
          >
            <div className="w-1/2 min-w-1/2 flex items-center h-full space-x-2">
              <span className="flex-none w-[40px] h-full flex items-center justify-center bg-slate-200 rounded-l">{dataRow.image ? <img src={`${dataRow.image}`} alt={`${dataRow.title}`}/> : <TbFile/>}</span>
              <span className="w-full truncate">{dataRow.title}</span>
            </div>
            <div className="w-1/2 flex space-x-2">
              {dataRow.second ? <div className="w-1/2 truncate">{dataRow.second}</div> : <div className="w-1/2"></div>}
              {dataRow.third ? <div className="w-1/2 truncate">{dataRow.third}</div> : <div className="w-1/2"></div>}
            </div>
          </div>
        )
      })}
    </div>
  )
  
}