import { useEffect, useState } from "react";
import { Block, BlockRecordsListOptions, Card, CardWithValues, ValuePayload } from "../../../../../core/graphql/types";
import { useMattrDataProvider } from "../../../../../core/providers/data";
import { TbFile } from "react-icons/tb";
import { usePanel } from "../../../../../core/providers/panel";
import { useSpaceStore } from "../../../../../core/store/store";

import ValueShow from "../../../../../components/Forms/Fields/Values";
import { useBoard } from "../../../../../core/providers/board";


interface DataRow {
  id: string;
  image: any;
  title: any;
  second: any;
  third: any;
  actions: {canEdit: boolean, canAdd: boolean};
}

export default function RecordsList({block, onCardChange}:{block: Block, onCardChange: (card: CardWithValues) => void}) {
  const store = useBoard()
  const init: string = `block:${block.id}`;
  const {cards, fields, currentView, currentCardId, values} = store
  const [data, setData] = useState<DataRow[]>([])

  useEffect(() => {
    let titleField = (currentView?.options! as BlockRecordsListOptions).titleFieldId!
    let secondField = (currentView?.options! as BlockRecordsListOptions).secondFieldId!
    let thirdField = (currentView?.options! as BlockRecordsListOptions).thirdFieldId!
    let imageField = (currentView?.options! as BlockRecordsListOptions).imageFieldId!

    let arr: DataRow[] = cards.map((card: Card) => {
      return {
        id: card.id,
        image: imageField ? values?.find((v: ValuePayload) => v.cardId === card.id && v.fieldId === imageField)?.value : null,
        title: titleField ? <ValueShow type={values?.find((v: ValuePayload) => v.cardId === card?.id! && v.fieldId === titleField)!.type!} value={values?.find((v: ValuePayload) => v.cardId === card.id && v.fieldId === titleField)} fieldAttributes={fields.find(f => f.id == titleField)?.attributes!}/> : <></>,
        second: secondField ? <ValueShow type={values?.find((v: ValuePayload) => v.cardId === card.id && v.fieldId === secondField)!.type!} value={values?.find((v: ValuePayload) => v.cardId === card.id && v.fieldId === secondField)} fieldAttributes={fields.find(f => f.id == secondField)?.attributes!}/> : <></>,
        third: thirdField ? <ValueShow type={values?.find((v: ValuePayload) => v.cardId === card.id && v.fieldId === thirdField)!.type!} value={values?.find((v: ValuePayload) => v.cardId === card.id && v.fieldId === thirdField)} fieldAttributes={fields.find(f => f.id == thirdField)?.attributes!}/> : <></>,
        actions: {
          canAdd: currentView?.permissions?.canAdd!,
          canEdit: currentView?.permissions?.canEdit!
        }
      }
    })

    setData(arr)
  },[cards, values])

  function onRowClick(id: string) {
    if(!currentView?.permissions?.canEdit) {return}
    onCardChange({...(cards.find((c: Card | CardWithValues) => c.id === id) as CardWithValues), values: values.filter((v: ValuePayload) => v.cardId === id)})
  }

  return (
    <div className="flex flex-col space-y-2">
      {data.map((dataRow, index) => {
        return (
          <div 
            key={dataRow.id} 
            className={`text-base bg-white min-h-[60px] pl-4 pr-2 items-center flex w-full hover:bg-slate-100 ${currentCardId === dataRow?.id ? "bg-slate-100" : ""}`}
            onClick={() => onRowClick(dataRow.id)}
          >
            <div className="flex items-center h-full w-full space-x-2 cursor-pointer">
              {dataRow.image ? <span className="flex-none w-[40px] h-full flex items-center justify-center bg-slate-200"><img src={`${dataRow.image}`} alt={`${dataRow.title}`}/></span> : <></>}
              <div className="w-full">
                <div className="w-full text-base font-medium truncate mb-1">{dataRow.title}</div>
                <div className="w-full flex space-x-2 text-sm truncate items-center">
                  {dataRow.second ? <div className="w-1/2 ">{dataRow.second}</div> : <></>}
                  {dataRow.third ? <div className="w-1/2 truncate">{dataRow.third}</div> : <></>}
                </div>
              </div>
            </div>

          </div>
        )
      })}
    </div>
  )
  
}