import type { RouteObject } from "react-router-dom";
import { useRoutes } from "react-router-dom";
import { useAuth } from "../core/providers/auth";
import { SidebarProvider } from "../core/providers/sidebar";
import { SpaceProvider } from "../core/providers/space";

import { Layout } from "../components/layouts/base";
import { PrivateLayout } from "../components/layouts/private";
import { TelegramLayout } from "../components/layouts/telegram";
import { BigSpinner } from "../components/loaders";
import Flow from "../containers/Flows/flow";
import { PanelContainer } from "../containers/Panel";
import { Account } from "../containers/account";
import { AccountBase } from "../containers/account/base";
import { AccountSpaces } from "../containers/account/spaces";
import { AccountTelegram } from "../containers/account/telegram";
import { BoardContainer } from "../containers/board";
import FetcherContainer from "../containers/fetcher";
import { AccountNotifications } from "../containers/notifications";
import NewSpace from "../containers/spaces/create";
import { FetcherProvider } from "../core/providers/fetcher";
import { FlowProvider } from "../core/providers/flow";
import { PanelProvider } from "../core/providers/panel";
import { LoginPage } from '../pages/auth/Login';
import SignUp from "../pages/auth/SignUp";
import BoardsPage from "../pages/boards";
import EmailConfiramtionPage from "../pages/confirmation";
import Feed from "../pages/notifications";
import { PrivatePage } from "../pages/page";
import { SpaceSettings } from "../pages/settings";
import { SpaceSettingsBase } from "../pages/settings/base";
import { SpaceSettingsConstants } from "../pages/settings/constants";
import { SpaceSettingsGroups } from "../pages/settings/groups";
import { SpaceSettingsRoles } from "../pages/settings/roles";
import { SpaceSettingsTelegram } from "../pages/settings/telegram";
import { SpaceUsers } from "../pages/settings/users";
import TelegramPage from "../pages/telegram";
// import TestDocViewer from "../components/Viewer/page";

export function Routes() {
  let auth = useAuth();

  let public_routes = [
    { path: "/", element: <LoginPage /> },
    { path: "/signup", element: <SignUp />  },
    { path: "/confirmation/:token", element: <EmailConfiramtionPage />},
    { path: "/telegram/:key", element: <TelegramLayout><TelegramPage/></TelegramLayout> },
  ]

  let protected_routes = [
    { path: "/",
      element: 
      <SpaceProvider>
        <SidebarProvider>
          <PrivateLayout />
        </SidebarProvider>
      </SpaceProvider>, 
      children: [
        { index: true, element: <PrivatePage/> },
        { path: "/:page_id", element:  <PanelProvider><PanelContainer /></PanelProvider>},
        { path: "boards", children: [
          { index: true, element: <BoardsPage /> },
          { path: ":board_id", element:  <BoardContainer />},
        ]},
        { path: "feed", element: <Feed />},
        { path: "services/:fetcher_id", element: <FetcherProvider><FetcherContainer /></FetcherProvider>},
        { path: "flows/:flow_id", element: <FlowProvider><Flow /></FlowProvider>},
        { path: "space", element: <SpaceSettings />, 
          children: [
            { path: "settings", element: <SpaceSettingsBase /> },
            { path: "groups", element: <SpaceSettingsGroups />},
            { path: "users", element: <SpaceUsers />},
            { path: "roles", element: <SpaceSettingsRoles />},
            { path: "telegram", element: <SpaceSettingsTelegram />},
          ]
        },
        { path: "notifications", element: <AccountNotifications /> },
        { path: "account", element: <Account />, 
          children: [
            { index: true, element: <AccountBase /> },
            { path: "telegram", element: <AccountTelegram />},
            { path: "spaces", element: <AccountSpaces />}
          ]
        },
        { path: "spaces/add", element: <NewSpace />},
        { path: "confirmation/:token", element: <EmailConfiramtionPage />},
        { path: "telegram/:key", element: <TelegramLayout><TelegramPage/></TelegramLayout>},
      ],
    },
    { path: "*", element: <PrivateLayout /> },
  ]

  let routes: RouteObject[] = [
    {
      path: "/",
      element: <Layout />,
      children: auth.isLogged == null ? [{path: "*", element: <BigSpinner />}] : auth.isLogged ? protected_routes : public_routes
    },
  ];

  let element = useRoutes(routes);

  return (element);
}

// function NoMatchProtected() {
//   return (
//     <div>
//       <h2>NoMatchProtected</h2>
//     </div>
//   );
// }

function NoMatchPublic() {
  return (
    <div>
      <h2>NoMatchPublic</h2>
    </div>
  );
}