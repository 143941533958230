import { useState } from "react";
import { LinkItem } from "../../../../core/graphql/types";
import { HiXCircle } from 'react-icons/hi';
import { PopoverRelations} from "../../../modals/relations";
import CardModal from "../../../Cards";

interface RelationInputProps {
  relations: LinkItem[];
  onChange: any;
  foreginFieldId?: string; 
  fieldId: string;
  boardId: string;
  narrow?: boolean;
}

function RelationInput({fieldId, boardId, foreginFieldId, relations, onChange, narrow}: RelationInputProps) {
  const [relationCardId, setRelationCardId] = useState<string | null>(null)
  const [ openEdit, setOpenEdit ] = useState(false);


  function openModalCardRelation(element: LinkItem) { 
    setRelationCardId(element?.value?.cardId!)
    setOpenEdit(true)
  };

  function closeModalCardRelation() { 
    setRelationCardId(null)
    setOpenEdit(false)
  };

  function handleChange(e: any) {
    let el = {
      // foreginCardId: e.id,
      id: e.fields.find((f:any) => f.primary === true).id,
      valueId: e.fields.find((f:any) => f.primary === true).id,
      name: e.fields.find((f:any) => f.primary === true).value,
      // foreginType: e.fields.find((f:any) => f.primary === true).type
    }
    onChange(el)
  }

  function transformRelationValue(value: any) {
    if(typeof value === 'object') {
      return value.map((v: any) => v.foreginName).join(", ")
    } else {
      return value
    }
  }

  return (
    <>
      <div className="w-full h-full focus:outline-none">
        <div className="flex w-full bg-white items-center">
          <div className={`flex flex-row items-center w-full ${!narrow ? "matr-form-input" : "matr-form-narrow-input"}`} >
            <div className="flex">
              <div className="flex-none w-8">
                <PopoverRelations boardId={boardId} fieldId={fieldId} foreginFieldId={foreginFieldId} relations={relations} onChange={handleChange} />
              </div>
              { 
                relations ? 
                  <div className="grow overflow-hidden">
                    <div className="flex flex-row space-x-2">
                      {relations.map((element: any) => { 
                        return element.id ? 
                          <div className="flex max-w-min bg-blue-200 text-blue-800 h-5 py-0.5 rounded-sm pl-2 pr-1 items-center truncate" key={element.id}>
                            <span className='truncate' onClick={()=>openModalCardRelation(element)}>{element.name ? element.name as string : "Без названия"}</span>
                            <HiXCircle className="ml-1 h-4 w-4 z-10" onClick={() => onChange(element)}></HiXCircle>
                          </div> : <></>
                      })}
                    </div>
                  </div>
                : 
                <></>
              }
            </div>
          </div>
        </div>
      </div>
      {openEdit ? 
        <CardModal
          open={openEdit} 
          close={closeModalCardRelation} 
          boardId={boardId} 
          fromFieldId={fieldId} 
          cardId={relationCardId!}
          initiator="INPUT_VALUE"
        /> : <></>}
    </>
  )
} 

export default RelationInput;