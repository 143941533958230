//@ts-nocheck
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'

export const MentionList = forwardRef((props, ref) => {
  const [selectedIndex, setSelectedIndex] = useState(0)

  const selectItem = index => {
    const item = props.items[index]

    if (item) {
      props.command({ id:  item.id ? item.id : `${item.value}_${index}`, label: item.value, data: item.data})
    }
  }

  const upHandler = () => {
    setSelectedIndex(((selectedIndex + props.items.length) - 1) % props.items.length)
  }

  const downHandler = () => {
    setSelectedIndex((selectedIndex + 1) % props.items.length)
  }

  const enterHandler = () => {
    selectItem(selectedIndex)
  }

  useEffect(() => setSelectedIndex(0), [props.items])

  useImperativeHandle(ref, () => ({
    onKeyDown: ({ event }) => {
      if (event.key === 'ArrowUp') {
        upHandler()
        return true
      }

      if (event.key === 'ArrowDown') {
        downHandler()
        return true
      }

      if (event.key === 'Enter') {
        enterHandler()
        return true
      }

      return false
    },
  }))

  return (
    <div className="p-3 relative rounded-md bg-white text-slate-800 text-sm overflow-x-hidden overflow-y-scroll shadow-lg max-h-[24rem] border mt-1 space-y-1">
      {props.items.length
        ? props.items.map((item, index) => (
          <button
            className={`block m-0 w-full text-left rounded-md px-2 py-1.5 hover:bg-blue-200 hover:text-blue-600  ${index === selectedIndex ? 'bg-blue-200 text-blue-600' : 'bg-white text-slate-800'}`}
            key={index}
            onClick={() => selectItem(index)}
          >
            {item.value}
          </button>
        ))
        : <div className="item">Нет данных</div>
      }
    </div>
  )
})