import React, {useEffect} from "react";
import { useFormContext } from "react-hook-form";

interface FieldCreatedByProps {
  type: string;
}

function FieldCreatedBy({attributes}: FieldCreatedByProps | any) {
  const methods = useFormContext();
  const {register, unregister, setValue} = methods;

  useEffect(() => {
    unregister('attributes');
  },[])

  useEffect(() => {
    register('attributes.type');
    setValue('attributes.type', "CREATED_BY");
  },[attributes])

  return (
    <>
    </>
  );
}
export default FieldCreatedBy;