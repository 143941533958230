import { useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { SingleValue } from "react-select";
import useDeepCompareEffect from "use-deep-compare-effect";
import MatrNarrowSelect from "../../../../components/ui/select/narrow";
import { FetcherInListFragment, FetcherRequest, NodeField } from "../../../../core/graphql/types";
import { useFlowContext } from "../../../../core/providers/flow";
import { useSpace } from "../../../../core/providers/space";
import NodeFields from "./nodeField/NodeFields";
import { SmallSpinner } from "../../../../components/loaders";



export default function NodeRequestEdit() {
  const {services} = useSpace()
  const [loading, setLoading] = useState(true)
  const {node, updateNode} = useFlowContext()
  const {control, getValues, formState, watch, setValue} = useFormContext()
  const [nodeFields, setNodeFields] = useState(node.data?.fields?.filter((f: NodeField) => f.external === false))
  const [requests, setRequests] = useState<FetcherRequest[]>([])

  useEffect(() => {
    if(!getValues()?.fetcherId) {return}
    setRequests(services.find(b => b.id === getValues()?.fetcherId)?.requests! as FetcherRequest[])
    setLoading(false)
  }, [])

  useDeepCompareEffect(() => {
    setNodeFields(node.data?.fields?.filter((f: NodeField) => f.external === false))
    setLoading(false)
  },[node.data?.fields])

  function handleChangeService(e: SingleValue<FetcherInListFragment>, onChange: { (...event: any[]): void; (arg0: any): void; }) {
    setRequests(services.find(b => b.id === e?.id)?.requests! as FetcherRequest[])
    setValue('requestId', null)
    onChange(e?.id)
    setLoading(true)
  }

  function handleChangeRequest(e: SingleValue<FetcherRequest>, onChange: { (...event: any[]): void; (arg0: any): void; }) {
    onChange(e?.id)
    setLoading(true)
  }

  return (
    <div className="flex flex-col h-full">
        <div className="py-4 space-y-2">
          <div className="relative">
            <label className="font-medium text-gray-600 text-sm">Выберите сервис:</label>
            <Controller
              name="fetcherId"
              control={control}
              rules={{required: true}}
              render={({ field: { onChange, value }}) => (
                <div className="mt-1.5">
                  <MatrNarrowSelect
                    getOptionLabel={(option) => option?.title!}
                    getOptionValue ={(option)=> option?.id!}
                    value={services.find(s => s.id === value)}
                    onChange={(e) => handleChangeService(e, onChange)}
                    options={services}
                  />
                </div>
              )}
            />
            {formState.errors.fetcherId && <span className="text-red-600 text-xs font-normal">"Сервис" обязательное поле.</span>}
          </div>
          {watch('fetcherId') &&     
            <div className="relative">
              <label className="font-medium text-gray-600 text-sm">Выберите запрос:</label>
              <Controller
                name="requestId"
                control={control}
                rules={{required: true}}
                render={({ field: { onChange, value }}) => (
                  <div className="mt-1.5">
                    <MatrNarrowSelect
                      getOptionLabel={(option) => option?.name!}
                      getOptionValue ={(option)=> option?.id!}
                      value={requests?.find(r => value === r.id)}
                      onChange={(e) => handleChangeRequest(e, onChange)}
                      options={requests}
                    />
                  </div>
                )}
              />
              {formState.errors.requestId && <span className="text-red-600 text-xs font-normal">"Запрос" обязательное поле.</span>}
            </div>
          }
          {!loading ?
            <div className="relative">
              <label className="matr-label w-full">Параметры для запроса</label>
              {nodeFields?.length > 0 ? <NodeFields nodeFields={nodeFields} /> : <></>}
            </div>
            :
            <div className="min-h-[4rem] flex items-center">
              <SmallSpinner />
            </div>
          }
        </div>
    </div>
  )
}