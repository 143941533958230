import _ from 'lodash';
import React, { useEffect } from "react";
import useDeepCompareEffect from "use-deep-compare-effect";
import { BigSpinner, SmallSpinner } from "../../../components/loaders/index";
import {
  BlockSourceTypeEnum,
  BlockTypeEnum,
  Card, Field,
  ValuePayload,
  useGetBlockDataQuery,
  useGetFieldsQuery,
  useGetValuesQuery,
  useListCardsQuery,
  useStoreValueMutation
} from "../../graphql/types";
import { BoardActions, DataStore, useSpaceStore } from "../../store/store";

export interface MattrDataContextType extends BoardActions, DataStore {
  initId: string,
  initType: string,
  savePrimitiveValue: (value: any) => void;
}

let MattrDataContext = React.createContext<MattrDataContextType>(null!);

export function MattrDataProvider({ initiator, children }: { initiator: {type: string, id: string}, children: React.ReactNode }) {
  const { id: initId, type: initType } = initiator
  const init: string = `${initType}:${initId}`
  const store = useSpaceStore()
  const {setFields, setCards, setValues, setValueForUpdate, setBoardIsLoading} = store.boardActions
  const {inBlock, block, fields, currentView, relationCardId, currentCardId, userCurrentView, valueForUpdate} = store.data[init]
  const [,storeValue] = useStoreValueMutation();
  
  const [{data: fieldsData, fetching: fieldsFetching, error: fieldsError}, getFields] = useGetFieldsQuery({variables: {boardId: initId!}, pause: true});
  const [{data: cardsData, fetching: cardsFetching, error: cardsError}, getCards] = useListCardsQuery({ variables: { viewId: currentView?.id!}, pause: true});
  const [{data: valuesData, fetching: valuesFetching}, getValues] = useGetValuesQuery({ variables: { viewId: currentView?.id!}, pause: true});
  const [{fetching: fetchingBlockData, data: blockData, error: blockError}, getData] = useGetBlockDataQuery({variables: {blockId: initId!, cardId: block?.source?.type === BlockSourceTypeEnum.Field || block?.type === BlockTypeEnum.BlockForm ? relationCardId : currentCardId}, pause: true})
  

  useDeepCompareEffect(() => {
    if(initType === "block") { 
      setBoardIsLoading(true, init)
      getData()
    } else {
      if(!currentView?.id) {return}
      setBoardIsLoading(true, init)
      getFields()
      getCards()
      getValues()
    }
  },[currentView, block, relationCardId])

  useDeepCompareEffect(() => {
    if(inBlock) { 
      setBoardIsLoading(true, init)
      getData()
    } else {
      setBoardIsLoading(true, init)
      getCards()
      getValues()
    }
  }, [userCurrentView.filters])

  useEffect(() => {
    if(!blockData) { return }
    let data = blockData?.getBlockData
    
    let preCards: Card[] = []
    let preValues: any[] = []

    data?.cards?.map(cwv => {
      preCards.push((cwv as Card))
      preValues.push(cwv?.values!)
    })

    setFields((_.orderBy(data?.fields!, 'position', 'asc') as Field[]), init)
    setCards(preCards, init)
    setValues(preValues.flat(), init)
    setBoardIsLoading(false, init)
        
  },[blockData])

  useEffect(() => {
    if(!fieldsData) { return }
    setFields(fieldsData?.getFields! as Field[], init)
  }, [fieldsData])

  useEffect(() => {
    if(valuesFetching || cardsFetching) { return }
    if(!cardsData || !cardsData?.listCards || !valuesData || !valuesData?.getValues) { return }

    setCards(cardsData?.listCards as Card[], init)
    setValues(valuesData?.getValues as ValuePayload[], init)
    setBoardIsLoading(false, init)
  },[cardsData, cardsData?.listCards, valuesData, valuesData?.getValues, cardsFetching, valuesFetching])

  useEffect(() => {
    if(!valueForUpdate) { return }
    savePrimitiveValue(valueForUpdate)
    setValueForUpdate(null, init)
  },[valueForUpdate])

  function savePrimitiveValue(value: any) {
    storeValue({
      id: value.id,
      cardId: value.cardId,
      fieldId: value.fieldId,
      boardId: value.boardId,
      type: value.type,
      valueAsString: value.valueAsString,
      valueAsBool: value.valueAsBool,
      valueAsNumber: value.valueAsNumber,
      valueAsDecimal: value.valueAsDecimal,
      valueAsJson: value.valueAsJson,
      valueAsDatetime: value.valueAsDatetime,
      valueAsJsonArray: value.valueAsJsonArray
    });
  }

  let value = { 
    ...store.data[init],
    ...store.boardActions,
    savePrimitiveValue
  };
  
  if(blockData?.getBlockData?.fields?.length === 0) {return <div>Нет данных. Или источника не существует.</div>}


  return fields.length > 0 ? <MattrDataContext.Provider value={value}>{children}</MattrDataContext.Provider> : <SmallSpinner />

}

export function useMattrDataProvider() {return React.useContext(MattrDataContext);}