import { useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { BiToggleLeft, BiToggleRight } from "react-icons/bi";
import { SingleValue } from "react-select";
import MatrNarrowSelect from "../../../../components/ui/select/narrow";
import { Board, Field, Filter, NodeSourceReturnAsEnum } from "../../../../core/graphql/types";
import { useSpace } from "../../../../core/providers/space";
import BlockSettingsFiltersDropdown from "./filters";


export default function NodeSourceEdit() {
  const {boards} = useSpace()
  const {control, getValues, formState, watch, setValue} = useFormContext()
  const [fields, setFields] = useState<Field[]>([])


  useEffect(() => {
    if(!getValues()?.boardId) {return}
    setFields(boards.find(b => b.id === getValues()?.boardId)?.fields! as Field[])
  }, [])


  function handleChangeBoard(e: SingleValue<Board>, onChange: { (...event: any[]): void; (arg0: any): void; }) {
    setFields(boards.find(b => b.id === e?.id)?.fields! as Field[])
    setValue('filters', [])
    onChange(e?.id)
  }

  function handleChangeFilters(filters: Filter[], onChange: { (...event: any[]): void; (arg0: any): void; }) {
    onChange(filters)
  }

  function handleChangeCheckbox(value: any, onChange: { (...event: any[]): void; (arg0: any): void; }) {
    onChange(value === NodeSourceReturnAsEnum.One ? NodeSourceReturnAsEnum.All : NodeSourceReturnAsEnum.One)
  }


  return (
    <div className="flex flex-col h-full">
        <div className="py-4">
          <div className="relative">
            <label className="font-medium text-gray-600 text-sm">Выберите таблицу:</label>
            <Controller
              name="boardId"
              control={control}
              rules={{required: true}}
              render={({ field: { onChange, value }}) => (
                <div className="mt-1.5">
                  <MatrNarrowSelect
                    getOptionLabel={(option) => option?.name!}
                    getOptionValue ={(option)=> option?.id!}
                    value={boards.find(s => s.id === value)}
                    onChange={(e) => handleChangeBoard(e, onChange)}
                    options={boards}
                  />
                </div>
              )}
            />
            {formState.errors.boardId && <span className="text-red-600 text-xs font-normal">"Таблица" обязательное поле.</span>}
          </div>
          <div className="relaitve mt-2">
            <div className="flex justify-between items-center">
              <label className="matr-label w-full">Возвращать одну строку:</label>
              <Controller
                name="returnAs"
                control={control}
                render={({ field: { onChange, value, ref }}) => (
                  <div 
                    className={`flex items-center  rounded-md font-normal  cursor-default`} 
                    onClick={() => handleChangeCheckbox(value, onChange)}
                  >
                    {value === NodeSourceReturnAsEnum.One ? <BiToggleRight className='h-6 w-6 text-blue-600'/> : <BiToggleLeft className='h-6 w-6 text-slate-400'/>}
                  </div>
                )}
              />
            </div>
          </div> 
          {watch('boardId') && 
            <div className="relative mt-2">
              <Controller
                name="filters"
                control={control}
                render={({ field: { onChange, value, ref }}) => (
                  <BlockSettingsFiltersDropdown fields={fields!} filters={value ? value : [] } onChangeFilters={(filters: Filter[]) => handleChangeFilters(filters, onChange)}/>
                )}
              />
            </div>
          }
        </div>
    </div>
  )
}