import { Tab } from "@headlessui/react";
import { Controller, useFormContext } from "react-hook-form";
import { SingleValue } from "react-select";
import { Field, FieldType, Filter, Role, ViewType } from "../../../../../core/graphql/types";
import { useBoard } from "../../../../../core/providers/board";
import FiltersList from "../../../../Filters/filter";
import HiddenFields from "../../../../Filters/hidden";
import MatrSelect from "../../../../ui/select/base";
import ViewPermissions from "../../permissions";
import { useSpaceStore } from "../../../../../core/store/store";

interface ViewFormProps {
  types: any[]
}

function ViewForm({types}: ViewFormProps) {
  const {initId, initType, fields, users} = useBoard()
  const init: string = `${initType}:${initId}`


  
  const methods = useFormContext();
  const {register, control, watch, setValue} = methods;

  function getViewSettings(type: ViewType) {
    switch (type) {
      case ViewType.Grid:
        return <></>
      case ViewType.Calendar:
        return (
          <>
            <div className="relative space-y-1.5">
              <label className="matr-label">Базовое поле "Дата и время":</label>
              <Controller
                name="stackedFieldId"
                control={control}
                rules={{required: true}}
                render={({ field: { onChange, value, ref }}) => (
                  <div className="relative mt-2">
                    <MatrSelect 
                      classNamePrefix="matr_select_form"
                      placeholder="Выберите поле на базе которого будет построен вид"
                      isSearchable={false}
                      getOptionLabel ={(option)=>option?.title!}
                      getOptionValue ={(option)=>option?.id!}
                      value={fields.find((field: Field) => field.id === value)}
                      onChange={(e) => handleSelectStackedField(e, onChange)}
                      options={fields.filter((f: Field) => f.type === FieldType.Datetime)}
                    />
                  </div>
                )}
              />
              <p className="text-xs text-slate-400 pt-1">Это поле используется для построения вида. Оно также является временем начала события.</p>
            </div>
            { watch('stackedFieldId') && <>
              <div className="relative space-y-1.5">
                <label className="matr-label">Выберите поле "Дата и время" начала события:</label>
                <p className="text-xs text-slate-400 pt-1">Это поле нужно чтобы отображать, когда событие началось. Если оно не указано то будет браться значение времени из базового поля.</p>
                <Controller
                  name="options.startEventFieldId"
                  control={control}
                  render={({ field: { onChange, value, ref }}) => (
                    <div className="mt-2">
                      <MatrSelect 
                        classNamePrefix="matr_select_form"
                        placeholder="Выберите поле начала события"
                        isSearchable={false}
                        isClearable={true}
                        getOptionLabel ={(option)=>option?.title!}
                        getOptionValue ={(option)=>option?.id!}
                        defaultValue = {value ? fields.find((f: Field) => f.id === value) : []}
                        value={fields.find((f: Field) => f.id === value)}
                        onChange={(e) => handleSelectStartEventField(e, onChange)}
                        options={fields.filter((f: Field) => f.type === FieldType.Datetime)}
                      />
                    </div>
                  )}
                />
              </div>
              <div className="relative space-y-1.5">
                <label className="matr-label">Выберите поле "Дата и время" окончания события:</label>
                <p className="text-xs text-slate-400 pt-1">Это поле нужно чтобы отображать, когда событие закончится. Если оно не указано то будет братъся значение времени из базового поля.</p>
                <Controller
                  name="options.endEventFieldId"
                  control={control}
                  render={({ field: { onChange, value, ref }}) => (
                    <div className="mt-2">
                      <MatrSelect 
                        classNamePrefix="matr_select_form"
                        placeholder="Выберите поле окончания события"
                        isSearchable={false}
                        isClearable={true}
                        getOptionLabel ={(option)=>option?.title!}
                        getOptionValue ={(option)=>option?.id!}
                        value={fields.find((f: Field) => f.id === value)}
                        onChange={(e) => handleSelectEndEventField(e, onChange)}
                        options={watch('options.startEventFieldId') ? fields.filter((f: Field) => f.type === FieldType.Datetime && f.id !== watch('options.startEventFieldId')) : []}
                      />
                    </div>
                  )}
                />
              </div>
              <div className="relative space-y-1.5">
                <label className="matr-label">Выберите поле заголовка для события:</label>
                <p className="text-xs text-slate-400 pt-1">Занчение из этого поля будет отображаться в календаре.</p>
                <Controller
                  name="options.titleEventFieldId"
                  control={control}
                  render={({ field: { onChange, value, ref }}) => (
                    <div className="mt-2">
                      <MatrSelect 
                        classNamePrefix="matr_select_form"
                        placeholder="Выберите поле окончания события"
                        isSearchable={false}
                        getOptionLabel ={(option)=>option?.title!}
                        getOptionValue ={(option)=>option?.id!}
                        value={fields.find((f: Field) => f.id === value)}
                        onChange={(e) => handleSelectTitleEventField(e, onChange)}
                        options={fields}
                      />
                    </div>
                  )}
                />
              </div> 
            </>}
          </>
        )
      case ViewType.Kanban:
        <div className="relative space-y-1.5">
          <label className="matr-label">Базовое поле "Дата и время":</label>
          <Controller
            name="stackedFieldId"
            control={control}
            rules={{required: true}}
            render={({ field: { onChange, value, ref }}) => (
              <div className="mt-2">
                <MatrSelect 
                  classNamePrefix="matr_select_form"
                  placeholder="Выберите поле на базе которого будет построен вид"
                  isSearchable={false}
                  getOptionLabel ={(option)=>option?.title!}
                  getOptionValue ={(option)=>option?.id!}
                  value={fields.find((field: Field) => field.id === value)}
                  onChange={(e) => handleSelectStackedField(e, onChange)}
                  options={fields.filter((f: Field) => f.type === FieldType.Datetime)}
                />
              </div>
            )}
          />
          <p className="text-xs text-slate-400 pt-1">Это поле используется для построения вида. Оно также является временем начала события.</p>
        </div>
        break;
    
      default: return <></>
    }
  }

  function handleSelectStackedField(e: SingleValue<Field>, onChange: { (...event: any[]): void; (arg0: any): void; }) {
    setValue('options.startEventFieldId', e?.id)
    onChange(e?.id)
  }

  function handleSelectStartEventField(e: SingleValue<Field>, onChange: { (...event: any[]): void; (arg0: any): void; }) {
    onChange(e?.id)
  }

  function handleSelectEndEventField(e: SingleValue<Field>, onChange: { (...event: any[]): void; (arg0: any): void; }) {
    onChange(e?.id)
  }

  function handleSelectTitleEventField(e: SingleValue<Field>, onChange: { (...event: any[]): void; (arg0: any): void; }) {
    onChange(e?.id)
  }

  function handleSelectType(e: { value: any; }, onChange: { (...event: any[]): void; (arg0: any): void; }) {
    onChange(e.value)
  }

  function onChangeHiddenFields(state: any, onChange: { (...event: any[]): void; (arg0: any): void; }) {
    onChange(state)
  }

  function onChangeFilters(state: any, onChange: { (...event: any[]): void; (arg0: any): void; }) {
    onChange(state)
  }

  return (
    <> 
      <div className="relative flex flex-col space-y-1.5 pt-4">
        <input 
          {...register("name", {required: true})} 
          className="text-xl font-medium text-slate-800 placeholder:text-slate-300 focus:outline-none mb-4" 
          placeholder="Название вида"
        />
      </div>
      <Tab.Group>
        <Tab.List className="text-base border-b">
          <Tab >{({ selected }) => (<div className={`px-3 py-1.5  font-medium ${selected ? "text-indigo-600 border-b-2 border-indigo-600" : "text-slate-600"} `}>Тип</div>)}</Tab>
          <Tab >{({ selected }) => (<div className={`px-3 py-1.5  font-medium ${selected ? "text-indigo-600 border-b-2 border-indigo-600" : "text-slate-600"} `}>Колонки</div>)}</Tab>
          <Tab >{({ selected }) => (<div className={`px-3 py-1.5  font-medium ${selected ? "text-indigo-600 border-b-2 border-indigo-600" : "text-slate-600"} `}>Фильтры</div>)}</Tab>
          {/* <Tab >{({ selected }) => (<div className={`px-3 py-1.5  font-medium ${selected ? "text-indigo-600 border-b-2 border-indigo-600" : "text-slate-600"} `}>Сортировка</div>)}</Tab>
          <Tab >{({ selected }) => (<div className={`px-3 py-1.5  font-medium ${selected ? "text-indigo-600 border-b-2 border-indigo-600" : "text-slate-600"} `}>Группировка</div>)}</Tab> */}
          {!watch("primary") ? <Tab >{({ selected }) => (<div className={`px-3 py-1.5  font-medium ${selected ? "text-indigo-600 border-b-2 border-indigo-600" : "text-slate-600"} `}>Разрешения</div>)}</Tab> : <></>}
        </Tab.List>
        <Tab.Panels>
          <Tab.Panel className="py-2 space-y-2">
            <div className="relative">
              <Controller
                name="type"
                control={control}
                render={({ field: { onChange, value, ref }}) => (
                  <div className="rounded text-sm flex justify-around bg-slate-50 overflow-hidden">
                    {types.map(type => (
                      <button key={type.value} onClick={(e) => handleSelectType(type, onChange)} className={`p-1.5 font-medium w-full text-center ${value === type.value ? "bg-indigo-600 text-white" : "text-slate-800"}`}>{type.label}</button>
                    ))}
                  </div>
                )}
              />
            </div>
            {getViewSettings(watch('type'))}
          </Tab.Panel>
          <Tab.Panel className="py-2 space-y-2">
            <Controller
              name="hiddenFields"
              control={control}
              render={({ field: { onChange, value, ref }}) => (
                <HiddenFields fields={fields} hiddenFields={value ? value : []} onChangeFields={(state: string[] | []) => onChangeHiddenFields(state, onChange)}/> 
              )}
            />
          </Tab.Panel>
          <Tab.Panel className="py-2 space-y-2">
            <Controller
              name="filters"
              control={control}
              render={({ field: { onChange, value, ref }}) => (
                <FiltersList boardFields={fields} currentFilters={value ? value : []} users={users} saveFilters={(state: Filter[] | []) => onChangeFilters(state, onChange)}/>
              )}
            />
          </Tab.Panel>
          {!watch("primary") ? 
            <Tab.Panel className="py-2 space-y-2">
              <ViewPermissions />
            </Tab.Panel> 
          : <></>}
        </Tab.Panels>
      </Tab.Group>
    </>
  );
}
export default ViewForm;