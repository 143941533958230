import { Tab } from "@headlessui/react";
import { useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { TbTrash } from "react-icons/tb";
import { SingleValue } from "react-select";
import { iconByType } from "../../../../../../components/icons";
import MatrNarrowSelect from "../../../../../../components/ui/select/narrow";
import { getBlockWHByType } from "../../../../../../core/Controllers/Panel/blocks_functions";
import { Block, BlockFieldOptions, BlockTypeEnum, Board, Field, LayoutEnum } from "../../../../../../core/graphql/types";
import { usePanel } from "../../../../../../core/providers/panel";
import { useSpace } from "../../../../../../core/providers/space";
import { BiToggleLeft, BiToggleRight } from "react-icons/bi";

const contentWidth = [
  {label: "На всю ширину", value: 0},
  {label: "Десктоп", value: 1},
  {label: "Планшет", value: 2},
  {label: "Телефон", value: 3},
]

const contentColumns = [
  {label: "Одна колонка", value: 1},
  {label: "Две колонки", value: 2},
]

const BlockFormEditSettings = () => {
  const {boards} = useSpace()
  const {page} = usePanel()
  const {control, watch, getValues, formState} = useFormContext()

  function handleChangeBoard(e: SingleValue<Board>, onChange: (...event: any[]) => void) {
    onChange(e?.id)
  }

  function handleChangeCheckbox(value: any, onChange: { (...event: any[]): void; (arg0: any): void; }) {
    onChange(!value)
  }

  function handleChangeContentWidthAndColumns(value: {label: string, value: number} | null, onChange: { (...event: any[]): void; (arg0: any): void; }) {
    onChange(value?.value)
  }

  return (
    <>
      {page.layout === LayoutEnum.Form ? 
        <div className="relative space-y-1.5">
          <label className="matr-label w-full">Источник:</label>
          <Controller
            name="source.boardId"
            control={control}
            rules={{required: true}}
            render={({ field: { onChange, value }}) => (
                <MatrNarrowSelect
                  getOptionLabel={(option) => option?.name!}
                  getOptionValue ={(option)=> option?.id!}
                  value={value && value.length > 0 ? boards.find(r => value.includes(r.id)) : []}
                  onChange={(e) => handleChangeBoard(e, onChange)}
                  options={boards}
                />
            )}
          />
          {formState.errors.boardId && <span className="text-red-600 text-xs font-normal">"Источник" обязательное поле.</span>}
        </div>
      :
        <div className="relative space-y-1.5 flex flex-col mt-4">
          <label className="matr-label w-full">Источник:</label>
          <span className="text-base font-medium">{boards.find(b => b.id === getValues('source.boardId'))?.name}
            {(page.blocks?.find(b => b?.id === getValues('source.blockId'))?.options as any).title}</span>
        </div> 
      }
      <Tab.Group>
        <Tab.List className="text-base border-b">
          <Tab >{({ selected }) => (<div className={`px-3 py-1.5  font-medium ${selected ? "text-indigo-600 border-b-2 border-indigo-600" : "text-slate-600"} `}>Общие настройки</div>)}</Tab>
        </Tab.List>
        <Tab.Panels>
          <Tab.Panel className="py-2 space-y-2">
            <div className="relative space-y-1.5">
              <div className="flex justify-between items-center">
                <label className="matr-label">Заголовок формы:</label>
                <Controller
                  name="options.showTitle"
                  control={control}
                  render={({ field: { onChange, value, ref }}) => (
                    <div 
                      className={`flex items-center  rounded-md font-normal  cursor-default`} 
                      onClick={() => handleChangeCheckbox(value, onChange)}
                    >
                      {value === false ? <BiToggleLeft className='h-6 w-6 text-slate-400'/> : <BiToggleRight className='h-6 w-6 text-blue-600'/>}
                    </div>
                  )}
                />
              </div>
              <Controller
                name="options.title"
                control={control}
                render={({ field: { onChange, value, ref }}) => (
                  <div className="mt-2">
                    <input className="matr-form-narrow-input" value={value} onChange={onChange} />
                  </div>
                )}
              />
            </div>
            <div className="relative space-y-1.5">
              <label className="matr-label">Текст кнопки:</label>
              <Controller
                name="options.submitText"
                control={control}
                render={({ field: { onChange, value, ref }}) => (
                  <div className="mt-2">
                    <input className="matr-form-narrow-input" value={value} onChange={onChange} />
                  </div>
                )}
              />
            </div>
            <div className="relative">
              <label className="font-medium text-gray-600 text-sm">Ширина формы:</label>
              <Controller
                name="options.contentWidth"
                control={control}
                rules={{required: true}}
                render={({ field: { onChange, value }}) => (
                  <div className="mt-1.5">
                    <MatrNarrowSelect
                      value={contentWidth.find(cw => cw.value === value)}
                      onChange={(e) => handleChangeContentWidthAndColumns(e, onChange)}
                      options={contentWidth}
                    />
                  </div>
                )}
              />
            </div>
            <div className="relative">
              <label className="font-medium text-gray-600 text-sm">Количество колонок:</label>
              <Controller
                name="options.contentColumns"
                control={control}
                rules={{required: true}}
                render={({ field: { onChange, value }}) => (
                  <div className="mt-1.5">
                    <MatrNarrowSelect
                      value={value ? contentColumns.find(cw => cw.value === value) : []}
                      onChange={(e) => handleChangeContentWidthAndColumns(e, onChange)}
                      options={contentColumns}
                    />
                  </div>
                )}
              />
            </div>
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </>
  )
}

export default BlockFormEditSettings;