import { useEffect, useRef, useState } from 'react';
import { Field } from '../../../../core/graphql/types';
import { useBoard } from '../../../../core/providers/board';
import { useSpaceStore } from '../../../../core/store/store';
import HiddenFields from '../../../Filters/hidden';
import { MatrPopover } from '../../../ui/portal';
import { TbColumns } from 'react-icons/tb';

interface HiddenFieldsDropdownProps {
  viewFields: Field[];
  onChange: any;
  custom: any;
}

function HiddenFieldsDropdown({viewFields, onChange}: HiddenFieldsDropdownProps): JSX.Element {
  const {initId, initType} = useBoard()
  const init: string = `${initType}:${initId}`
  const store = useSpaceStore()
  const {userCurrentView} = store.data[init];

  const buttonRef = useRef<any>(null);
  const [ open, setOpen ] = useState(false);
  const [ count, setCount ] = useState<number | null>(null)

  useEffect(() => {
    if(!viewFields) { return }
    setCount(userCurrentView?.hiddenFields!.length)
  }, [viewFields, userCurrentView.hiddenFields])

  function closePopover() { 
    setOpen(false)
  };

  return (
    <>
      <button 
        ref={buttonRef}
        className={`
        ${open ? 'bg-indigo-600 text-white hover:bg-indigo-700 hover:text-white ring-2 ring-indigo-300' : 'bg-slate-200 text-slate-700 hover:bg-slate-300 hover:text-slate-800'}
        ${count ? "bg-orange-200 text-orange-800" : ""}
        h-7 text-sm font-medium flex items-center px-1.5 space-x-2 rounded-sm`}
        onClick={() => setOpen(true)}
      >
        <TbColumns size={18}/>
        {count ? <span className='flex h-4 w-4 rounded-sm bg-indigo-600 text-xxs ml-2 items-center justify-center text-white'>{count}</span> : <></>}
      </button>
      {open && <MatrPopover onClose={closePopover} reference={buttonRef.current} placement="bottom-start">
        <div className='bg-white h-fit max-h-[32rem] min-w-[24rem] rounded-sm p-4 border shadow-lg mt-2'>
          <HiddenFields fields={viewFields} hiddenFields={userCurrentView?.hiddenFields && userCurrentView?.hiddenFields.length > 0 ? userCurrentView?.hiddenFields! as string[] : []} onChangeFields={onChange}/>
        </div>
      </MatrPopover>}
    </>
  )
}

export default HiddenFieldsDropdown;