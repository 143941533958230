import { BiRedo, BiUndo } from "react-icons/bi";
import { TbBold, TbCode, TbH1, TbH2, TbH3, TbH4, TbH5, TbH6, TbItalic, TbListCheck, TbSticker } from "react-icons/tb";

const EditorToolbar = ({ editor }: {editor: any}) => {
  if (!editor) {
    return null
  }

  return (
    <div className="flex space-x-2 text-slate-600 items-center">
      <button
        type="button"
        onClick={() => editor.chain().focus().toggleBold().run()}
        disabled={
          !editor.can()
            .chain()
            .focus()
            .toggleBold()
            .run()
        }
        className={`text-sm font-medium px-2 py-0.5 rounded-md ${editor.isActive('bold') ? 'is-active bg-blue-200 text-blue-600 ring-2 ring-blue-200' : 'ring-2 ring-slate-200'}`}
      >
        Жирный
      </button>
      <button
        type="button"
        onClick={() => editor.chain().focus().toggleItalic().run()}
        disabled={
          !editor.can()
            .chain()
            .focus()
            .toggleItalic()
            .run()
        }
        className={`text-sm font-medium px-2 py-0.5 rounded-md ${editor.isActive('italic') ? 'is-active bg-blue-200 text-blue-600 ring-2 ring-blue-200' : 'ring-2 ring-slate-200'}`}
      >
        Курсив
      </button>
      <button
        type="button"
        onClick={() => editor.chain().focus().toggleStrike().run()}
        disabled={
          !editor.can()
            .chain()
            .focus()
            .toggleStrike()
            .run()
        }
        className={`text-sm font-medium px-2 py-0.5 rounded-md ${editor.isActive('strike') ? 'is-active bg-blue-200 text-blue-600 ring-2 ring-blue-200' : 'ring-2 ring-slate-200'}`}
      >
        Зачеркнутый
      </button>

      <button
        type="button"
        onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
        className={`text-sm font-medium flex items-center justify-center h-5 w-5 rounded-md ${editor.isActive('heading', { level: 1 }) ? 'is-active bg-blue-200 text-blue-600 ring-2 ring-blue-200' : 'ring-2 ring-slate-200'}`}
      >
        <TbH1 size={16}/>
      </button>
      <button
        type="button"
        onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
        className={`text-sm font-medium flex items-center justify-center h-5 w-5 rounded-md ${editor.isActive('heading', { level: 2 }) ? 'is-active bg-blue-200 text-blue-600 ring-2 ring-blue-200' : 'ring-2 ring-slate-200'}`}
      >
        <TbH2 size={16}/>
      </button>
      <button
        type="button"
        onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
        className={`text-sm font-medium flex items-center justify-center h-5 w-5 rounded-md ${editor.isActive('heading', { level: 3 }) ? 'is-active bg-blue-200 text-blue-600 ring-2 ring-blue-200' : 'ring-2 ring-slate-200'}`}
      >
        <TbH3 size={16}/>
      </button>
      <button
        type="button"
        onClick={() => editor.chain().focus().toggleHeading({ level: 4 }).run()}
        className={`text-sm font-medium flex items-center justify-center h-5 w-5 rounded-md ${editor.isActive('heading', { level: 4 }) ? 'is-active bg-blue-200 text-blue-600 ring-2 ring-blue-200' : 'ring-2 ring-slate-200'}`}
      >
        <TbH4 size={16}/>
      </button>
      <button
        type="button"
        onClick={() => editor.chain().focus().toggleHeading({ level: 5 }).run()}
        className={`text-sm font-medium flex items-center justify-center h-5 w-5 rounded-md ${editor.isActive('heading', { level: 5 }) ? 'is-active bg-blue-200 text-blue-600 ring-2 ring-blue-200' : 'ring-2 ring-slate-200'}`}
      >
        <TbH5 size={16}/>
      </button>
      <button
        type="button"
        onClick={() => editor.chain().focus().toggleHeading({ level: 6 }).run()}
        className={`text-sm font-medium flex items-center justify-center h-5 w-5 rounded-md ${editor.isActive('heading', { level: 6 }) ? 'is-active bg-blue-200 text-blue-600 ring-2 ring-blue-200' : 'ring-2 ring-slate-200'}`}
      >
        <TbH6 size={16}/>
      </button>
      <button
        type="button"
        onClick={() => editor.chain().focus().toggleCode().run()}
        disabled={
          !editor.can()
            .chain()
            .focus()
            .toggleCode()
            .run()
        }
        className={`text-sm font-medium px-2 py-0.5 rounded-md ${editor.isActive('code') ? 'is-active bg-blue-200 text-blue-600 ring-2 ring-blue-200' : 'ring-2 ring-slate-200'}`}
      >
        Код
      </button>
      {/* <button type="button" onClick={() => editor.chain().focus().unsetAllMarks().run()}>
        clear marks
      </button>
      <button type="button" onClick={() => editor.chain().focus().clearNodes().run()}>
        clear nodes
      </button> */}
      {/* <button
        type="button"
        onClick={() => editor.chain().focus().setParagraph().run()}
        className={editor.isActive('paragraph') ? 'is-active' : ''}
      >
        paragraph
      </button> */}
      {/* <button
        type="button"
        onClick={() => editor.chain().focus().toggleBulletList().run()}
        className={editor.isActive('bulletList') ? 'is-active' : ''}
      >
        <TbListCheck />
      </button>
      <button
        type="button"
        onClick={() => editor.chain().focus().toggleOrderedList().run()}
        className={editor.isActive('orderedList') ? 'is-active' : ''}
      >
        ordered list
      </button>
      <button
        type="button"
        onClick={() => editor.chain().focus().toggleCodeBlock().run()}
        className={editor.isActive('codeBlock') ? 'is-active' : ''}
      >
        code block
      </button>
      <button
        type="button"
        onClick={() => editor.chain().focus().toggleBlockquote().run()}
        className={editor.isActive('blockquote') ? 'is-active' : ''}
      >
        blockquote
      </button> */}
      {/* <button type="button" onClick={() => editor.chain().focus().setHorizontalRule().run()}>
        horizontal rule
      </button>
      <button type="button" onClick={() => editor.chain().focus().setHardBreak().run()}>
        hard break
      </button> */}
      <button
        type="button"
        onClick={() => editor.chain().focus().undo().run()}
        disabled={
          !editor.can()
            .chain()
            .focus()
            .undo()
            .run()
        }
      >
        <BiUndo />
      </button>
      <button
        type="button"
        onClick={() => editor.chain().focus().redo().run()}
        disabled={
          !editor.can()
            .chain()
            .focus()
            .redo()
            .run()
        }
      >
        <BiRedo />
      </button>
      {/* <button
        type="button"
        onClick={() => editor.chain().focus().setColor('#958DF1').run()}
        className={editor.isActive('textStyle', { color: '#958DF1' }) ? 'is-active' : ''}
      >
        purple
      </button> */}
    </div>
  )
}

export default EditorToolbar;