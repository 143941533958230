export default function ActionSendEmail() {
  return (
    <>
    <div className="h-12 flex flex-row px-4 items-center justify-between border-b">
      <span className="text-lg font-medium text-slate-600">Конфигурация</span>
    </div>
    <div className="h-full flex-1 overflow-scroll">
      <div className="pb-3">
        EEEE
      </div>
    </div>
    </>
  )
}