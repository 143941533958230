import React from "react";
import { useDeleteBoardMutation } from "../../../../core/graphql/types";
import { useForm } from "react-hook-form";
import toast from 'react-hot-toast';
import { useNavigate } from "react-router-dom";

export type IBoardFormDeleteProps = {
  id: string;
  name: string;
  closeModal: () => void;
}

export const BoardFormDelete: React.FC<IBoardFormDeleteProps> = ({id, name, closeModal}) => {
  const { register, handleSubmit, formState: { errors } } =  useForm();
  const navigate = useNavigate();
  const [ , deleteBoard] = useDeleteBoardMutation();

  const onSubmit = handleSubmit(data => {
    if(data.name === name) {
      toast.promise(
        deleteBoard({id: id, name: data.name}).then(result => {
          closeModal();
          return result
        }),
        {
          loading: 'Удаляю доску...',
          success: (data) => {
            if (data.error) throw new Error();
            if (data.data?.deleteBoard?.id === id) {navigate('/')}
            return 'Доска удалена';
          },
          error: 'Ошибка. Не удалось удалить доску',
        }
      );
    }
  });

  return (
    <form onSubmit={onSubmit} className="flex flex-col relative w-full space-y-4">
      <h2 className="text-lg font-semibold">Удаление доски</h2>
      <div className="text-gray-600 text-xs">
        Вы хотите удалить доску: <span className="font-medium text-gray-800">{name}</span>, чтобы удалить введите название доски.
      </div>
      <div className="flex flex-col space-y-1.5">
        <label className="font-medium text-gray-600 text-sm">Название доски:</label>
        <input {...register("name", { required: true })}
          className="matr-form-input"
        />
        {errors.name && <span className="text-red-600 text-xs font-normal">Вы не ввели название доски.</span>}
      </div>
      <div className="flex justify-between w-full pt-6">
        <button
          type="button"
          className="inline-flex justify-end px-3 py-1.5 text-sm font-medium text-indigo-700 bg-indigo-100 border border-transparent rounded-md hover:bg-indigo-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-indigo-500"
          onClick={closeModal}
        >
          Отменить
        </button>
        <button
          type="submit"
          className="inline-flex justify-center px-3 py-1.5 text-sm font-medium text-white bg-red-600 border border-transparent rounded-md hover:bg-red-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-indigo-500"
        >
          Удалить
        </button>
      </div>
    </form>
  )
}