import {useEffect} from "react";
import { useFormContext } from "react-hook-form";
import { String } from "../../../../../core/graphql/types";

function FieldString({attributes}: {attributes: String} | any) {
  const methods = useFormContext();
  const {register, unregister, setValue} = methods;


  useEffect(() => {
    unregister('attributes');
  },[])

  useEffect(() => {
    register('attributes.type');
    setValue('attributes.type', "STRING");
    setValue('attributes.stringDefaultValue', attributes.stringDefaultValue ? attributes.stringDefaultValue : "");
    setValue('attributes.placeholder', attributes.placeholder ? attributes.placeholder : "");
    setValue('attributes.helpText', attributes.helpText ? attributes.helpText : "");
  },[attributes])

  return (
    <>
      {/* <div className="relative">
        <label className="font-medium text-gray-600 text-sm">Значение по умолчанию:</label>
        <input {...register("attributes.stringDefaultValue")}
          className="text-sm form-input mt-2 py-2.5 block w-full rounded-sm bg-gray-100 border-0 focus:bg-white focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          placeholder="Введите значение по умолчанию для этого поля"
        />
      </div>
      <div className="relative">
        <label className="font-medium text-gray-600 text-sm">Подсказка в поле:</label>
        <input {...register("attributes.placeholder")}
          className="text-sm form-input mt-2 py-2.5 block w-full rounded-sm bg-gray-100 border-0 focus:bg-white focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          placeholder="Введите подсказку для этого поля"
        />
      </div>
      <div className="relative">
        <label className="font-medium text-gray-600 text-sm">Вспомогательный текст к полю:</label>
        <input {...register("attributes.helpText")}
          className="text-sm form-input mt-2 py-2.5 block w-full rounded-sm bg-gray-100 border-0 focus:bg-white focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          placeholder="Введите дополнительный текст для этого поля"
        />
      </div> */}
    </>
  );
}
export default FieldString;