import { useEffect, useState } from "react";
import useDeepCompareEffect from "use-deep-compare-effect";
import ViewList from "../../../../../components/Views/List";
import { SmallSpinner } from "../../../../../components/loaders";
import { MatrMediumDialog } from "../../../../../components/ui/portal";
import { Block, BlockGridOptions, BlockRecordsListOptions, BlockSourceBoard, Board, ViewType } from "../../../../../core/graphql/types";
import useDebounce from "../../../../../core/hooks/debounce/useDebounce";
import { BoardProvider } from '../../../../../core/providers/board';
import { MattrDataProvider } from '../../../../../core/providers/data';
import { usePanel } from "../../../../../core/providers/panel";
import { BlockSubscriptionProvider } from '../../../../../core/providers/subscriptions/block';
import { DataStore, dataInitialState, useSpaceStore } from "../../../../../core/store/store";
import { BlockEdit } from "../Forms/editBlock";
import { BlockListActions } from './actions';


export default function ListBlock({block}:{block: Block}) { 
  const {page, editorMode, updatePage} = usePanel()
  const init = `${"block"}:${block.id}`
  const {addBoardData, resetDataItem} = useSpaceStore().boardActions
  const storeData = useSpaceStore().data
  const [ pageName, setPageName ] = useState<string>("")
  const [ name ] = useDebounce(pageName, 500)
  const [ openSettings, setOpenSettings ] = useState(false);

  function onOpenSettings(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) { setOpenSettings(true)}
  function closeSettings() { setOpenSettings(false) };

  useDeepCompareEffect(() => {
    resetDataItem(init)
    let newObject: Record<string, DataStore> = {}
    newObject[init] = {
      ...dataInitialState,
      block: block,
      board: {id: (block.source as BlockSourceBoard).boardId!} as Board,
      currentView: {
        type: ViewType.List, 
        filters: (block.options as any)?.filters, 
        hiddenFields: [],
        permissions: block.permissions!, 
        options: (block.options as BlockRecordsListOptions)
      },
      inBlock: true,
      showModalAs: "modal",
      initId: block.id!, 
      initType: "block", 
      boardIsLoading: false
    }

    addBoardData(newObject)
    setPageName(page.name!)
  },[block])

  useEffect(() => {
    if (name && name != page.name) {
      updatePage({...page, name: pageName})
    }
  }, [name]);


  return (
    <>
      {block.isPage ?
      <>
        <div className="flex flex-row w-full justify-end px-4 py-2">
          <button 
            className="bg-slate-200 rounded text-slate-400 flex px-2 py-1 text-sm font-medium hover:bg-indigo-600 hover:text-white" 
            onClick={(e) => onOpenSettings(e)}
            >
              Настройка
          </button>
        </div>
        <div className="w-full px-4">
          {editorMode ?
            <div className="flex-none w-full py-4">
              <input className="w-full outline-none text-xl text-slate-800 font-semibold" value={pageName} onChange={(e) => setPageName(e.target.value)}/>
            </div> 
          :
            <div className="flex-none h-20 w-full text-xl text-slate-800 font-semibold px-6 pt-8 pb-4">{page.name}</div>
          }
        </div> 
      </>
      :
      <></>
      }
      {(init in storeData) ? 
        <>
          <MattrDataProvider initiator={{id: block.id!, type: "block"}}>
            <BlockSubscriptionProvider blockId={block.id!} boardId={(block.source as BlockSourceBoard).boardId!}>
              <BoardProvider>
                <div className="flex flex-col w-full mx-auto h-full overflow-hidden">
                  <BlockListActions initiator={{type: "block", id: block.id!}}/>
                  <div className="flex-1 overflow-scroll w-full h-full relative">
                    <ViewList/>
                  </div>
                </div>
              </BoardProvider>
            </BlockSubscriptionProvider>
          </MattrDataProvider>
          {openSettings && 
            <MatrMediumDialog open={openSettings} onClose={closeSettings}>
                <BlockEdit block={block} onClose={closeSettings}/>
            </MatrMediumDialog>
          }
        </>
      :
        <SmallSpinner />}
    </>
  )
}
