import DocViewer, { DocViewerRenderers } from "./component";
import { MatrDocumentDialog } from "../ui/portal";
import { useEffect, useState } from "react";

const storage_url = process.env.REACT_APP_STORAGE_URL ? process.env.REACT_APP_STORAGE_URL : ""

export default function Viewer({open, close, attachments, onRemove, currentFileNo}:{open: boolean, close: any, onRemove: (index: number) => void, attachments: any[], currentFileNo: number}) {
  const [docs, setDocs] = useState<any>([])
  useEffect(() => {
    setDocs(attachments.map(item => ({uri: `${storage_url}${item?.path!}/${item.filename}`})))
  },[attachments])
  
  return (
    <MatrDocumentDialog open={open} onClose={close}>
      <DocViewer pluginRenderers={DocViewerRenderers} documents={docs} currentFileNo={currentFileNo} onRemove={onRemove}/>
    </MatrDocumentDialog>
  )
}