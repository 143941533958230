//@ts-nocheck
import React, { useState, useImperativeHandle, useEffect } from 'react'
import { EditorContent, useEditor, FloatingMenu, BubbleMenu } from '@tiptap/react'
import Document from '@tiptap/extension-document'
import Paragraph from '@tiptap/extension-paragraph'
import Heading from '@tiptap/extension-heading'
import Placeholder from '@tiptap/extension-placeholder'
import Text from '@tiptap/extension-text'
import TextStyle from '@tiptap/extension-text-style'
import ListItem from '@tiptap/extension-list-item'
import OrderedList from '@tiptap/extension-ordered-list'
import BulletList from '@tiptap/extension-bullet-list'
import TaskItem from '@tiptap/extension-task-item'
import TaskList from '@tiptap/extension-task-list'

import Mention from '../components/Mention'

import { Color } from '@tiptap/extension-color'

import Bold from '@tiptap/extension-bold'
import Strike from '@tiptap/extension-strike'
import Italic from '@tiptap/extension-italic'
import Underline from '@tiptap/extension-underline'


import tippy from 'tippy.js'
import { MentionList } from '../components/MentionList'
import BlockToolbar from '../components/BlockToolbar'
import { usePanel } from '../../../../core/providers/panel'
import EditorToolbar from '../components/Toolbar'
import { TbH1, TbH2, TbH3, TbList, TbListCheck, TbListNumbers } from 'react-icons/tb'




const BlockEditor = ({onChange, value, tokens, editable}:{onChange: (value: any) => void, value: any, tokens: any[], editable: boolean}) => {
  // const tokenOptions = {
  //   items: ({ query }) => {
  //     return tokens && tokens.length > 0 ? tokens.filter(item => item.value.toLowerCase().startsWith(query.toLowerCase())) : []
  //   },
  //   render: () => {
  //     let reactRenderer
  //     let popup
  
  //     return {
  //       onStart: props => {
  //         reactRenderer = new ReactRenderer(MentionList, {
  //           props,
  //           editor: props.editor,
  //         })
  
  //         if (!props.clientRect) {
  //           return
  //         }
  
  //         popup = tippy('body', {
  //           getReferenceClientRect: props.clientRect,
  //           appendTo: () => document.body,
  //           content: reactRenderer.element,
  //           showOnCreate: true,
  //           interactive: true,
  //           trigger: 'manual',
  //           placement: 'bottom-start',
  //         })
  //       },
  
  //       onUpdate(props) {
  //         reactRenderer.updateProps(props)
  
  //         if (!props.clientRect) {
  //           return
  //         }
  
  //         popup[0].setProps({
  //           getReferenceClientRect: props.clientRect,
  //         })
  //       },
  
  //       onKeyDown(props) {
  //         if (props.event.key === 'Escape') {
  //           popup[0].hide()
  
  //           return true
  //         }
  
  //         return reactRenderer.ref?.onKeyDown(props)
  //       },
  
  //       onExit() {
  //         popup[0].destroy()
  //         reactRenderer.destroy()
  //       },
  //     }
  //   },
  // } 

  const editor = useEditor({
    extensions: [
      Document,
      Paragraph,
      Text,
      Placeholder.configure({
        // Use a placeholder:
        // placeholder: 'Начните писать …',
        // Use different placeholders depending on the node type:
        placeholder: ({ node }) => {
          if (node.type.name === 'heading') {
            return 'Новый заголовок'
          }

          return 'Начните писать …'
        },
      }),
      Heading.configure({
        levels: [1, 2],
      }),
      Bold, Strike, Italic, Underline,
      Color.configure({ types: [TextStyle.name, ListItem.name, TaskItem.name] }),
      TextStyle.configure({ types: [ListItem.name, TaskItem.name] }),
      ListItem,
      OrderedList.configure({
        keepMarks: true,
        keepAttributes: false
      }),
      BulletList.configure({
        keepMarks: true,
        keepAttributes: false
      }),
      TaskList,
      TaskItem.configure({
        nested: true
      }),


      // Mention.configure({
      //   HTMLAttributes: {
      //     class: 'matr-token-input',
      //   },
      //   renderLabel: ({options, node}) => (node && node.attrs && tokens ? tokens.find(t => t.id === node.attrs.id).value : "..."),
      //   suggestion: tokenOptions,
      // }),
    ],
    editable: editable,
    content: value ? JSON.parse(value) : null,
    onUpdate: ({ editor }) => {
      const json = editor.getJSON()
      onChange(json)
    },
  })

  useEffect(() => {
    editor?.setOptions({editable: editable})
  },[editable])


  return (
    <>
      {editor && <BubbleMenu className="flex bg-white shadow rounded text-sm" tippyOptions={{placement: "top-start", duration: 100 }} editor={editor}>
        <button
          type="button"
          onClick={() => editor.chain().focus().toggleBold().run()}
          className={editor.isActive('bold') ? 'is-active' : ''}
        >
          Жиирный
        </button>
        <button
          type="button"
          onClick={() => editor.chain().focus().toggleItalic().run()}
          className={editor.isActive('italic') ? 'is-active' : ''}
        >
          Курсив
        </button>
        <button
          type="button"
          onClick={() => editor.chain().focus().toggleStrike().run()}
          className={editor.isActive('strike') ? 'is-active' : ''}
        >
          Зачеркнутый
        </button>
      </BubbleMenu>}
      {editor && <FloatingMenu className="flex flex-col items-start bg-white shadow rounded" tippyOptions={{placement: "bottom-start", duration: 100 }} editor={editor}>
        <button
          type="button"
          onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
          className={`flex w-64 px-2 py-1 h-10 items-center space-x-2 hover:bg-indigo-600 hover:text-white ${editor.isActive('heading', { level: 1 }) ? 'bg-indigo-600 text-white' : 'bg-white text-slate-800'}`}
        >
          <div className='flex-none flex items-center justify-center h-full w-8'>
            <TbH1 size={24} />
          </div>
          <div className='flex-1 flex flex-col items-start'>
            <span className='text-sm'>Большой заголовок</span>
            <span className='text-xxs opacity-40'>Большой заголовок</span>
          </div>
        </button>
        <button
          type="button"
          onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
          className={`flex w-64 px-2 py-1 h-10 items-center space-x-2 hover:bg-indigo-600 hover:text-white ${editor.isActive('heading', { level: 2 }) ? 'bg-indigo-600 text-white' : 'bg-white text-slate-800'}`}
        >
          <div className='flex-none flex items-center justify-center h-full w-8'>
            <TbH2 size={24} />
          </div>
          <div className='flex-1 flex flex-col items-start'>
            <span className='text-sm'>Средний заголовок</span>
            <span className='text-xxs opacity-40'>Средний заголовок</span>
          </div>
        </button>
        <button
          type="button"
          onClick={() => editor.chain().focus().toggleBulletList().run()}
          className={`flex w-64 px-2 py-1 h-10 items-center space-x-2 hover:bg-indigo-600 hover:text-white ${editor.isActive('bulletList') ? 'bg-indigo-600 text-white' : 'bg-white text-slate-800'}`}
        >
          <div className='flex-none flex items-center justify-center h-full w-8'>
            <TbList size={24} />
          </div>
          <div className='flex-1 flex flex-col items-start'>
            <span className='text-sm'>Не нумерованный список</span>
            <span className='text-xxs opacity-40'>Не нумерованный список</span>
          </div>
        </button>
        <button
          type="button"
          onClick={() => editor.chain().focus().toggleOrderedList().run()}
          className={`flex w-64 px-2 py-1 h-10 items-center space-x-2 hover:bg-indigo-600 hover:text-white ${editor.isActive('orderedList') ? 'bg-indigo-600 text-white' : 'bg-white text-slate-800'}`}
        >
          <div className='flex-none flex items-center justify-center h-full w-8'>
            <TbListNumbers size={24} />
          </div>
          <div className='flex-1 flex flex-col items-start'>
            <span className='text-sm'>Нумерованный список</span>
            <span className='text-xxs opacity-40'>Нумерованный список</span>
          </div>
        </button>
        <button
          type="button"
          onClick={() => editor.chain().focus().toggleTaskList().run()}
          className={`flex w-64 px-2 py-1 h-10 items-center space-x-2 hover:bg-indigo-600 hover:text-white ${editor.isActive('orderedList') ? 'bg-indigo-600 text-white' : 'bg-white text-slate-800'}`}
        >
          <div className='flex-none flex items-center justify-center h-full w-8'>
            <TbListCheck size={24} />
          </div>
          <div className='flex-1 flex flex-col items-start'>
            <span className='text-sm'>Список задач</span>
            <span className='text-xxs opacity-40'>Список задач</span>
          </div>
        </button>
      </FloatingMenu>}
      <EditorContent editor={editor} className='block-section-editor w-full'/>
    </>
  )
}

export default BlockEditor;