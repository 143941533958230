import _ from 'lodash';
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { BigSpinner } from "../../../components/loaders/index";
import { Fetcher, FetcherInListFragment, FetcherRequest, FetcherRequestField, FetcherRequestOutputType, FetcherRequestResponse, useChangeMappingFieldsActivityMutation, useChangeRequestDepthMutation, useChangeRequestOutputMutation, useCreateFetcherRequestMutation, useGetFetcherQuery, useGetFetcherRequestFieldsQuery, useGetFetcherRequestQuery, useGetFetcherRequestResponseQuery, useGetRecordsPreviewQuery, useRemoveFetcherRequestMutation, useSaveFetcherRequestMutation, useStartFetcherRequestMutation, useUpdateFetcherMutation, useUpdateMappingFieldMutation } from "../../graphql/types";
import { useSpaceStore } from '../../store/store';


interface FetcherContextType {
  fetcher: Fetcher | null;
  setFetcher: (fetcher: Fetcher) => void;
  updateFetcher: (fetcher: Fetcher) => void;
  request: FetcherRequest | null;
  setRequest: (request: FetcherRequest | null) => void;
  requests: FetcherRequest[];
  setRequests: (requests: FetcherRequest[]) => void;
  currentRequestId: string | null | undefined;
  setCurrentRequestId: (id: string | null) => void;
  response: FetcherRequestResponse;
  requestFields: FetcherRequestField[] | null;
  records: any;
  addRequest: (data: any) => void;
  saveRequest: (data: any) => void;
  saveRequestAndGetResponse: (data: any) => void;
  startRequest: (data: any) => void;
  deleteRequest: (data: any) => void;
  onChangeRequestDepth: (depth: number) => void;
  onChangeRequestOutput: (output: FetcherRequestOutputType) => void;
  turnOnAllRequestField: () => void;
  turnOffAllRequestField: () => void;
  updateRequestField: (field: FetcherRequestField | any) => void;
}

let FetcherContext = React.createContext<FetcherContextType>(null!);


export function FetcherProvider({ children }: { children: React.ReactNode }) {
  const { fetcher_id } = useParams();
  const {actionServicesUpdate} = useSpaceStore().baseActions
  const [loading, setLoading] = useState(true);
  const [fetcher, setFetcher] = useState<Fetcher | any>(null);
  const [requests, setRequests] = useState<FetcherRequest[] | any>();
  const [requestFields, setRequestFields] = useState<FetcherRequestField[] | any>(null)
  const [currentRequestId, setCurrentRequestId] = useState<string | null | undefined>(null)
  const [request, setRequest] = useState<FetcherRequest | any>(null);
  const [response, setResponse] = useState<FetcherRequestResponse | any>(null);
  const [records, setRecords] = useState<any>(null)

  const [{data: dataFetcher, fetching: fetchingFetcher, error: errorFetcher}, getFetcher] = useGetFetcherQuery({variables: {id: fetcher_id!}, pause: true})
  const [{data: dataRequest, fetching: fetchingRequest, error: errorRequest}, getRequest] = useGetFetcherRequestQuery({variables: {id: currentRequestId!}, pause: true})
  const [{data: dataRequestFields, fetching: fetchingRequestFields, error: errorRequestFields}, getRequestFields] =  useGetFetcherRequestFieldsQuery({variables: {id: currentRequestId!}, pause: true})
  const [{data: dataRequestResponse, fetching: fetchingRequestResponse, error: errorRequestResponse}, getRequestResponse] =  useGetFetcherRequestResponseQuery({variables: {id: currentRequestId!}, pause: true})
  const [{data: dataRecords, fetching: fetchingRecords, error: errorRecords}, getRecords] = useGetRecordsPreviewQuery({variables: {id: currentRequestId!}, pause: true})
  const [,createRequest] = useCreateFetcherRequestMutation()
  const [,removeRequest] = useRemoveFetcherRequestMutation()
  const [,changeRequestDepth] = useChangeRequestDepthMutation()
  const [,changeRequestOutput] = useChangeRequestOutputMutation()
  const [{fetching: fetchingSaveRequest}, saveRequestMutation] = useSaveFetcherRequestMutation()
  const [{fetching: fetchStart}, startRequestMutation] = useStartFetcherRequestMutation()
  const [{fetching: fetchChangeActivity}, changeActivity] = useChangeMappingFieldsActivityMutation()
  const [{fetching: fetchUpdate}, updateField] = useUpdateMappingFieldMutation()
  const [, updateFetcherMutation] = useUpdateFetcherMutation()


  // useEffect(() => {
  //   if(fetchingFetcher || fetchingRequest || fetchingRequestFields || fetchingRequestResponse || fetchingRecords || fetchingSaveRequest || fetchUpdate || fetchChangeActivity) {return setLoading(true)}

  // },[fetchingFetcher, fetchingRequest, fetchingRequestFields, fetchingRequestResponse, fetchingRecords, fetchingSaveRequest, fetchChangeActivity, fetchUpdate])

  useEffect(() => {
    setLoading(true)
    setFetcher(null)
    setRequests(null)
    setCurrentRequestId(null)
    setRequest(null)
    setRequestFields(null)
    setResponse(null)
    setRecords(null)
    getFetcher()
  }, [fetcher_id])


  useEffect(()=> {
    if(!dataFetcher?.getFetcher) {return}
    setFetcher(dataFetcher?.getFetcher)
    setRequests(dataFetcher?.getFetcher?.requests)
    setLoading(false)
  },[dataFetcher?.getFetcher])

  useEffect(() => {
    if(!currentRequestId) {
      return
    }
    getRequest();
    getRequestFields();
    getRequestResponse();
  },[currentRequestId])

  useEffect(() => {
    if(!dataRequest?.getFetcherRequest) {
      return
    }
    setRequest(dataRequest?.getFetcherRequest)
  },[dataRequest?.getFetcherRequest])

  useEffect(() => {
    if(!dataRequestFields?.getFetcherRequestFields) {
      return
    }
    setRequestFields(_.orderBy(dataRequestFields?.getFetcherRequestFields, ['active', 'pathAsQuery'], ['desc', 'asc']))
  },[dataRequestFields?.getFetcherRequestFields])

  useEffect(() => {
    if(!dataRequestResponse?.getFetcherRequestResponse) {
      return
    }
    setResponse(dataRequestResponse?.getFetcherRequestResponse)
    getRecords()
  },[dataRequestResponse?.getFetcherRequestResponse])

  useEffect(() => {
    if(!dataRecords?.getRecordsPreview || !dataRecords?.getRecordsPreview.records) {
      return
    }
    setRecords(dataRecords?.getRecordsPreview.records)
  },[dataRecords?.getRecordsPreview])

  function updateFetcher(data: Fetcher) {
    updateFetcherMutation(data).then(res => {
      actionServicesUpdate(res.data?.updateFetcher as FetcherInListFragment)
      setFetcher(res.data?.updateFetcher)
    })
  }

  function addRequest(data: any) {
    createRequest({...data, fetcherId: fetcher?.id}).then(res => {
      setFetcher({...fetcher, requests: [...fetcher?.requests, res.data?.createFetcherRequest]})
      setRequests([...fetcher?.requests, res.data?.createFetcherRequest])
      setCurrentRequestId(res.data?.createFetcherRequest?.id)
      getRequestFields({id: res.data?.createFetcherRequest?.id});
    })
  }

  function deleteRequest(data: any) {
    removeRequest({
      id: data.id
    }).then(res => {
      if(res.error) {return}
      let requests = fetcher.requests.filter((i: FetcherRequest) => i.id !== res.data?.removeFetcherRequest?.id)
      setFetcher({...fetcher, requests: requests})
      setRequests(requests)
      setRequest(null)
      setCurrentRequestId(null)
      setRequestFields(null)
    })
  }

  function onChangeRequestDepth(depth: number) {
    changeRequestDepth({
      requestId: currentRequestId!,
      depth: depth,
    }).then(res => {
      let objIndex = fetcher.requests.findIndex((obj: FetcherRequest) => obj.id === res.data?.changeRequestDepth?.id);
      setFetcher({...fetcher, requests: [...fetcher.requests.slice(0, objIndex), res.data?.changeRequestDepth, ...fetcher.requests.slice(objIndex + 1)]})
      setRequest(res.data?.changeRequestDepth);
      getRequestFields({id: res.data?.changeRequestDepth?.id});
      getRecords();
    })
  }

  function onChangeRequestOutput(output: FetcherRequestOutputType) {
    changeRequestOutput({
      requestId: currentRequestId!,
      output: output,
    }).then(res => {
      let objIndex = fetcher.requests.findIndex((obj: FetcherRequest) => obj.id === res.data?.changeRequestOutput?.id);
      setFetcher({...fetcher, requests: [...fetcher.requests.slice(0, objIndex), res.data?.changeRequestOutput, ...fetcher.requests.slice(objIndex + 1)]})
      setRequest(res.data?.changeRequestOutput);
      getRequestFields({id: res.data?.changeRequestOutput?.id});
      getRecords();
    })
  }



  function saveRequest(data: any) {
    saveRequestMutation({...data, ...{send: false}}).then(res => {
      if(res.error) {return}
      let objIndex = fetcher.requests.findIndex((obj: FetcherRequest) => obj.id === res.data?.saveFetcherRequest?.id);
      setFetcher({...fetcher, requests: [...fetcher.requests.slice(0, objIndex), res.data?.saveFetcherRequest, ...fetcher.requests.slice(objIndex + 1)]})
      // setRequest(res.data?.saveFetcherRequest)
    })
  }

  function saveRequestAndGetResponse(data: any) {
    saveRequestMutation({...data, ...{send: true}}).then(res => {
      if(res.error) {return}
      let objIndex = fetcher.requests.findIndex((obj: FetcherRequest) => obj.id === res.data?.saveFetcherRequest?.id);
      setFetcher({...fetcher, requests: [...fetcher.requests.slice(0, objIndex), res.data?.saveFetcherRequest, ...fetcher.requests.slice(objIndex + 1)]})
      setRequest(res.data?.saveFetcherRequest)
      getRequestFields()
      getRequestResponse()
      getRecords();
    })
  }

  function turnOnAllRequestField() {
    changeActivity({
      requestId: currentRequestId!,
      state: true
    }).then(response => {
      setRequest({...request, fields: response.data?.changeMappingFieldsActivity})
      setRequestFields(_.orderBy(response.data?.changeMappingFieldsActivity, ['active', 'pathAsQuery'], ['desc', 'asc']))
    })
  }

  function  turnOffAllRequestField() {
    changeActivity({
      requestId: currentRequestId!,
      state: false
    }).then(response => {
      setRequest({...request, fields: response.data?.changeMappingFieldsActivity})
      setRequestFields(_.orderBy(response.data?.changeMappingFieldsActivity, ['active', 'pathAsQuery'], ['desc', 'asc']))
    })
  }

  function startRequest(data: any) {
    startRequestMutation(data)
  }

  function updateRequestField(column: FetcherRequestField) {
    updateField({
      id: column?.id!,
      title: column?.title!,
      active: column.active,
      fieldId: column.fieldId,
      fieldMode: column.fieldMode,
      fieldType: column.fieldType,
      transformation: column.transformation
    }).then(res => {
      if(res.error) {return}
      setRequestFields(_.orderBy(res.data?.updateMappingField, ['active', 'pathAsQuery'], ['desc', 'asc']))
    })
  }


  let value = {
    fetcher, 
    setFetcher,
    updateFetcher,
    request,
    setRequest,
    requests,
    setRequests,
    currentRequestId,
    setCurrentRequestId,
    requestFields,
    response,
    records,
    addRequest,
    saveRequest,
    saveRequestAndGetResponse,
    startRequest,
    deleteRequest,
    onChangeRequestDepth,
    onChangeRequestOutput,
    turnOnAllRequestField,
    turnOffAllRequestField,
    updateRequestField
  };

  return (
    loading ? <BigSpinner /> : <FetcherContext.Provider value={value}>{children}</FetcherContext.Provider>
  )
}

export function useFetcherContext() {return React.useContext(FetcherContext);}