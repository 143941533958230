import { useState, useCallback, ChangeEvent, useEffect } from "react";
import { useForm, Controller, useWatch, useFieldArray, FormProvider } from "react-hook-form";
import { Disclosure } from "@headlessui/react";
import { TbChevronUp, TbEye, TbEyeOff, TbInfoSquare, TbLink, TbSquareCheck, TbX } from "react-icons/tb";
import { useFlowContext } from "../../../../core/providers/flow";
import { useSpace } from "../../../../core/providers/space";
import { Node, NodeTrigger, NodeTriggerTypesEnum, Board, Filter, Field, BoardView, NodeFieldTypeEnum, MattrTypes, NodeTypesEnum } from "../../../../core/graphql/types";
import { Node as ReactFlowNode } from "reactflow";
import * as Yup from 'yup';
import { debounce } from "debounce";
import useDeepCompareEffect from "use-deep-compare-effect";
import LinkNodeFieldDropDown from "./nodeField/LinkNodeFieldDropDown";
import NodeFieldTypeDropDown from "./nodeField/NodeFieldTypeDropDown";

import NumberFormat, {FormatInputValueFunction} from "react-number-format";
import MatrNarrowDatePicker from "../../../../components/ui/datepicker/narrow";



export default function NodeRecrodEdit() {
  const {node, updateNode, createNodeField, updateNodeField, deleteNodeField, nodes, edges} = useFlowContext()

  const methods = useForm({defaultValues: {record_fields: node?.data?.fields}});
  const {control, register, watch, getValues, setValue, formState} = methods;
  const { fields, append, remove } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "record_fields", // unique name for your Field Array
  });
  const [nodeName, setNodeName] = useState<string>(node?.data?.name)
  const [fieldIndex, setFieldIndex] = useState<number | null>(null)
  const [sourceNodes, setSourceNodes] = useState<ReactFlowNode[]>([])

  useEffect(() => {
    setValue('record_fields', node?.data?.fields)
  }, [node?.data?.fields])

  useEffect(() => {
    let parentNodeIds: string[] = edges.filter(edge => edge.target === node.id).map(edge => edge.source)
    setSourceNodes(nodes.filter(n => parentNodeIds.includes(n.id)))
  }, [edges, node])

  // Обновление

  const debouncedSaveName = useCallback(
    debounce((name: string) => {
      updateNode({type: NodeTypesEnum.Record, name: name})
    }, 1000),
    []
  );

  const handleChangeNodeName = useCallback((name: string) => {
    if(name) {
      setNodeName(name)
      debouncedSaveName(name)
    } else {
      debouncedSaveName.clear()
      setNodeName(name)
    }
  },[])

  const debouncedSave = useCallback(
    debounce(() => {
      updateNodeField(getValues(`record_fields.${fieldIndex}`))
      setFieldIndex(null)
    }, 1000),
    [fieldIndex]
  );

  const watchedData = useWatch({
    control: control,
    defaultValue: {record_fields: node?.data?.fields}
  });

  useDeepCompareEffect(() => {
    if (formState.isDirty) {
      debouncedSave()
    } else {
      debouncedSave.clear()
    }
  }, [watchedData]);


  function handleAppendField() {
    let newField = {name: "", type: NodeFieldTypeEnum.Static, valueType: MattrTypes.String, node_field_id: null, value: ""}
    createNodeField(node.id)
    append(newField)
  } 

  function handleRemoveField(index: number) {
    let field = getValues(`record_fields.${index}`) 
    deleteNodeField(field.id)
    remove(index)
  }

  function handleChangeName(e: ChangeEvent<HTMLInputElement>, onChange: (...event: any[]) => void, index: number) {
    onChange(e)
    setFieldIndex(index)
  } 

  function handleChangeValue(e: ChangeEvent<HTMLInputElement>, onChange: (...event: any[]) => void, index: number) {
    setValue(`record_fields.${index}.nodeFieldId`, null)
    onChange(e)
    setFieldIndex(index)
  } 

  function handleChangeValueType(e: any, onChange: (...event: any[]) => void, index: number) {
    if (e === getValues(`record_fields.${index}.valueType`)) {return}
    switch (e) {
      case MattrTypes.String:
        setValue(`record_fields.${index}.value`, getValues(`record_fields.${index}.value`))
        break;
      case MattrTypes.Decimal:
      case MattrTypes.Number:
        setValue(`record_fields.${index}.value`, "")
        break;
    
      case MattrTypes.Date:
      case MattrTypes.Datetime:
        setValue(`record_fields.${index}.value`, new Date().toLocaleString())
        break;
      case MattrTypes.Function:
        setValue(`record_fields.${index}.value`, "NOW()")
        setValue(`record_fields.${index}.nodeFieldId`, null)
        break;
      default:
        setValue(`record_fields.${index}.value`, "")
        break;
    }
    onChange(e)
    setFieldIndex(index)
  } 

  function handleChangeNodeFieldId(e: any, onChange: (...event: any[]) => void, index: number) {
    onChange(e)
    setFieldIndex(index)
  } 

  function valueInput(value: any, onChange: ((...event: any[]) => void) | ((arg0: Date | null) => void), index: number) {
    switch (watch(`record_fields.${index}.valueType`)) {
      case MattrTypes.String:
        return (
          <input 
            value={value ? value : ""}
            onChange={(e) => handleChangeValue(e, onChange, index)}
            disabled={watch(`record_fields.${index}.nodeFieldId`) ? true : false}
            placeholder="Значение поля"
            className="matr-form-narrow-input flex-1"
          />
        )
      case MattrTypes.Number:
      case MattrTypes.Decimal:
        return (
          <NumberFormat 
            value={value ? value : ""}
            onChange={(e: any) => handleChangeValue(e.target.value, onChange, index)}
            disabled={watch(`record_fields.${index}.nodeFieldId`) ? true : false}
            placeholder="Значение поля"
            className="matr-form-narrow-input flex-1"
          />
        )
      case MattrTypes.Date:
        return (
          <MatrNarrowDatePicker 
            selected = {(value instanceof Date) ? new Date(value) : new Date()}
            disabled={watch(`record_fields.${index}.nodeFieldId`) ? true : false}
            // placeholder="Значение поля"
            showTimeInput = {false}
            dateFormat = { "yyyy-MM-dd" }
            onChange = {(e) => onChange(e)}
          />
        )
      case MattrTypes.Datetime:
        return (
          <MatrNarrowDatePicker 
            selected = {(value instanceof Date) ? new Date(value) : new Date()}
            disabled={watch(`record_fields.${index}.nodeFieldId`) ? true : false}
            // placeholder="Значение поля"
            showTimeInput = {true}
            dateFormat = { "yyyy-MM-dd" }
            onChange = {(e) => onChange(e)}
          />
        )
      default: return <></>
    }
  }

  function handleResetNodeFieldId(value: any, onChange: { (...event: any[]): void; (arg0: any): void; }, index: number) {
    setValue(`record_fields.${index}.nodeFieldId`, null)
    onChange(value)
    setFieldIndex(index)
  }


  return (
    <div className="flex flex-col overflow-hidden h-full">
      <div className="h-12 flex flex-row px-4 items-center justify-between border-b">
        <span className="text-lg font-medium text-slate-600">Конфигурация</span>
      </div>
      <div className="h-full flex-1 overflow-scroll">
        <Disclosure defaultOpen={true}>
          {({ open }) => (
            <>
              <Disclosure.Button className="flex w-full justify-between items-center px-4 py-2 text-left font-medium text-indigo-800 focus:outline-none">
                <div className="flex flex-col justify-between w-full pr-2">
                  <span className="text-base">Название блока</span>
                  {open ? "" : <span className="text-slate-500 font-normal text-xs">{nodeName}</span>}
                </div>
                <TbChevronUp
                  className={`${
                    open ? 'rotate-180 transform' : ''
                  } h-5 w-5 text-indigo-500`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="px-4 pb-4">
                <div className="relative">
                  <input 
                    value={nodeName}
                    onChange={(e) => handleChangeNodeName(e.target.value)}
                    className="matr-form-narrow-input flex-1"
                  />
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>   
        <Disclosure defaultOpen={true}>
          {({ open }) => (
            <>
              <Disclosure.Button className="border-t flex w-full justify-between items-center p-4 text-left font-medium text-indigo-800 focus:outline-none">
                <div className="flex flex-col justify-between w-full pr-2">
                  <span className="text-base">Поля</span>
                </div>
                <TbChevronUp
                  className={`${
                    open ? 'rotate-180 transform' : ''
                  } h-5 w-5 text-indigo-500`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="px-4 pb-4 space-y-4">
                <FormProvider {...methods}>
                  {fields.map((field, index) => (
                    <div key={field.id} className="flex flex-col">
                      <div className="flex w-full justify-between h-8 text-xs text-slate-600 items-center">
                        <span>Поле номер: {index + 1}</span>
                        <button type="button" onClick={() => handleRemoveField(index)} className="flex  text-slate-400 items-center">Удалить</button>
                      </div>
                      <div className="flex flex-col space-y-2">
                        <div className="flex w-full space-x-2">
                          <Controller 
                            name={`record_fields.${index}.name`}
                            control={control}
                            render={({ field: {value, onChange} }) => 
                              <input 
                                value={value ? value : ""}
                                onChange={(e) => handleChangeName(e, onChange, index)}
                                placeholder="Название поля"
                                className="matr-form-narrow-input flex-1"
                              />
                            }
                          />
                          <div className="flex w-7 h-7 rounded-sm bg-slate-200 items-center justify-center">
                            <Controller 
                              name={`record_fields.${index}.valueType`}
                              control={control}
                              render={({ field: {value, onChange} }) => 
                                <NodeFieldTypeDropDown value={value} onChange={(e: any) => handleChangeValueType(e, onChange, index)}/>
                              }
                            />
                          </div>
                        </div>
                        {watch(`record_fields.${index}.valueType`) === "NOW" ? <></> : 
                          <div className="flex w-full space-x-2 items-center">
                            <Controller 
                              name={`record_fields.${index}.value`}
                              control={control}
                              render={({ field: {value, onChange} }) => (
                                getValues(`record_fields.${index}.nodeFieldId`) ? 
                                  <div  className="flex-1 bg-indigo-600 text-white text-sm px-2 h-7 rounded-sm flex justify-between items-center">{value} <TbX className="text-white" onClick={() => handleResetNodeFieldId(value, onChange, index)}/></div> 
                                  : valueInput(value, onChange, index)
                              )}
                            />
                            <div className="flex w-7 h-7 rounded-sm bg-slate-200 items-center justify-center">
                              <Controller 
                                name={`record_fields.${index}.nodeFieldId`}
                                control={control}
                                render={({ field: {value, onChange} }) => 
                                  <LinkNodeFieldDropDown value={value} contextType={watch(`record_fields.${index}.contextType`)} onChange={(e: any, valueType: MattrTypes) => handleChangeNodeFieldId(e, onChange, index)} sourceNodes={sourceNodes}/>
                                }
                              />
                            </div>
                          </div>
                        }
                      </div>
                    </div>
                  ))}
                  <button type="button" onClick={() => handleAppendField()} className="flex w-full bg-slate-200 text-slate-800 text-sm rounded py-2 justify-center">Создать поле</button>
                </FormProvider>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </div>
    </div>
  )
}