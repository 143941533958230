import { forwardRef, useEffect, useRef, useState } from "react";
import { Column } from "react-data-grid";
import { Row } from "../types";
import type { RenderCellProps, RenderEditCellProps } from 'react-data-grid';
import { Datetime as DatetimeType, DatetimeValue, Field, StringValue } from '../../../../core/graphql/types';
import SummaryRows from "../rows/summary";
import { GroupCell } from "../rows/group";
import { HeaderRenderer } from "../rows/header";
import { useBoard } from "../../../../core/providers/board";
import {dateFormats, timeFormats} from "../../../../core/enums";
import "moment/locale/ru";
import moment from "moment";
import {DateTime, Settings} from "luxon";
import DatePicker from "react-datepicker";
import { HiChevronLeft, HiChevronRight } from "react-icons/hi";
import { useSpaceStore } from "../../../../core/store/store";
import { MatrPopover } from "../../../ui/portal";
import MatrCellDatePicker from "../../../ui/datepicker/cell";


interface BaseRenderCellProps<TRow, TSummaryRow> extends RenderCellProps<TRow, TSummaryRow> {
  field: Field;
  canEdit: boolean;
}

interface RenderEditStringCellProps<TRow, TSummaryRow> extends RenderEditCellProps<TRow, TSummaryRow> {
  field: Field;
}

function datetimeFormat(value: Date, attr: DatetimeType) {
    let format = "";
    
    if(!attr.includeTime) {
      format = dateFormats.find(format => format.name === attr.dateFormat)?.format!;
    } else {
      format = dateFormats.find(format => format.name === attr.dateFormat)?.format! + ' ' + timeFormats.find(format => format.name === attr.timeFormat)?.format!;
    }
  
    return (
      moment(new Date(value)).format(format)
    )
  }
  

// Column

export function datetimeColumn({ field, canEditInline }: { field: Field; canEditInline: boolean }): Column<Row, any> {
  return {
    key: field.id,
    name: field.title,
    width: field.width,
    resizable: true,
    frozen: field.primary ? true : false,
    renderHeaderCell: (p) => { return <HeaderRenderer field={field} {...p}/> },
    renderCell: (p) => { return <DatetimeCell {...p} field={field} canEdit={canEditInline}/> },
    // renderEditCell: (p) => { return canEditInline ? <DatetimeCellEditor {...p} field={field}/> : <></> },
    renderGroupCell: (p) => { return <GroupCell {...p} field={field}/> },
    renderSummaryCell: (p) => { return <SummaryRows {...p} field={field}/> },
    editorOptions: {
      displayCellContent: false
    }
  }
};


export function DatetimeCell<Row, SummaryRow>(props: BaseRenderCellProps<Row, SummaryRow>) {
  const {initId, initType, setEditMode} = useBoard();
  const init: string = `${initType}:${initId}`;
  const {row, column, tabIndex, field, canEdit, onRowChange} = props;
  const attr = (field.attributes as DatetimeType);
  const [dateFormat, setDateFormat] = useState<any>();
  const [timeFormat, setTimeFormat] = useState<any>();
  const cellRef = useRef<any>(null);
  const [ open, setOpen ] = useState(false);
  const [value, setValue] = useState<Date | null>((row[column.key as keyof Row] as unknown as DatetimeValue)?.valueAsDatetime! ? new Date((row[column.key as keyof Row] as unknown as DatetimeValue)?.valueAsDatetime!) : null)

  function openOptions() { setOpen(true) };
  function closeOptions() { setOpen(false)};

  useEffect(() => {
    let curdf = dateFormats.find(format => format.name === attr.dateFormat)
    let curtf = timeFormats.find(format => format.name === attr.timeFormat)

    if (attr.includeTime) {
      setDateFormat(curdf?.spec_format + ' ' + curtf?.spec_format)
    } else {
      setDateFormat(curdf?.spec_format)
    }
  },[])

  const handleValue = (e: any) => {
    setEditMode(true, init)
    setValue(e)
    if(!attr.includeTime) {
      onRowChange({ ...row, [column.key]: {...row[column.key as keyof Row], valueAsDatetime: e ? new Date(e).toISOString() : e}})
    } else {
      onRowChange({ ...row, [column.key]: {...row[column.key as keyof Row], valueAsDatetime: e ? new Date(e).toISOString() : e}})
    }
  }




  return (
    <>
      <div className="flex w-full h-full">
        {
          tabIndex == 0 && canEdit ?
            <div className="flex relative w-full h-full" onClick={() => openOptions()}>
              {value ? 
                <div className="px-2 text-left flex items-start py-2 text-sm truncate" ref={cellRef}>{value ? datetimeFormat(value, attr) : ""}</div>
                : 
                <div className={`block w-full h-full pr-6`} ref={cellRef}>
                  <span className="truncate"></span>
                </div>
              }
            </div>
          :
          <>
            {value ? 
              <div className="px-2 text-left h-full flex items-start py-2 text-sm truncate">{value ? datetimeFormat(value, attr) : ""}</div>
              : 
              <div className={`block w-full h-full pr-6`}>
                <span className="truncate"></span>
              </div>
            }
          </>
        }
      </div>
      {tabIndex == 0 && canEdit && open &&
        <MatrPopover onClose={closeOptions} reference={cellRef.current} placement={"bottom-start"}>
          <div className="bg-white shadow rounded-sm min-w-min max-h-96 relative p-2 w-[240px]">
            <MatrCellDatePicker 
              selected={value}
              onChange={handleValue}
              dateFormat = { dateFormat }
              onFocus={() => setEditMode(true, init)}
              showTimeInput = {attr.includeTime ?true : false}
            />
          </div>
        </MatrPopover>
      }
    </>
  );
}


// Cell

// export function DatetimeCell<Row, SummaryRow>({field, row, column, onRowChange}: BaseRenderCellProps<Row, SummaryRow>) {
//   const value = (row[column.key as keyof Row] as unknown as DatetimeValue)?.valueAsDatetime!
//   const attr = (field.attributes as DatetimeType);
//   return (
//     <div className="px-2 text-left h-full flex items-start py-2 text-sm truncate">{value ? datetimeFormat(value, attr) : ""}</div>
//   );
// }

// CellEditor

// export function DatetimeCellEditor<Row, SummaryRow>({field, row, column, onRowChange, onClose}: RenderEditStringCellProps<Row, SummaryRow>) {
//     Settings.defaultLocale = "ru";
//     const [dateFormat, setDateFormat] = useState<any>();
//     const [timeFormat, setTimeFormat] = useState<any>();
//     const [value, setValue] = useState<Date>((row[column.key as keyof Row] as unknown as DatetimeValue)?.valueAsDatetime! ? new Date((row[column.key as keyof Row] as unknown as DatetimeValue)?.valueAsDatetime!) : DateTime.now().toJSDate())

//     const {initId, initType} = useBoard();
//     const init: string = `${initType}:${initId}`;
//     const store = useSpaceStore()
//     const {setEditMode} = store.boardActions

//     const attr = (field.attributes as DatetimeType);

//     useEffect(() => {
//         let curdf = dateFormats.find(format => format.name === attr.dateFormat)
//         let curtf = timeFormats.find(format => format.name === attr.timeFormat)
    
//         if (attr.includeTime) {
//           setDateFormat(curdf?.spec_format + ' ' + curtf?.spec_format)
//         } else {
//           setDateFormat(curdf?.spec_format)
//         }
//       },[])
    
//       const DatePickerInput = forwardRef(({ onClick, ...props }: {onClick: any}, ref: any) => (
//         <input 
//           {...props}
//           className="cell"
//           ref={ref}
//           // onClick={onClick}
//           // onFocus={onClick}
//         />
//       ));
    
    
    
//       const handleValue = (e: any) => {
//         setEditMode(true, init)
//         setValue(e)
//         if(!attr.includeTime) {
//           onRowChange({ ...row, [column.key]: {...row[column.key as keyof Row], valueAsDatetime: new Date(e).toISOString()}})
//           // setValueForUpdate({...row[column.key as keyof Row], valueAsDatetime: new Date(e).toISOString()}, init)
//         } else {
//           onRowChange({ ...row, [column.key]: {...row[column.key as keyof Row], valueAsDatetime: new Date(e).toISOString()}})
//           // setValueForUpdate({...row[column.key as keyof Row], valueAsDatetime: new Date(e).toISOString()}, init)
//         }
//       } 
    
    
//       return (
//         <DatePicker 
//           selected = {value}
//           closeOnScroll={true}
//           popperPlacement="bottom-end"
//           showTimeInput = {attr.includeTime ?true : false}
//           customInput={<DatePickerInput onClick={undefined}/>}
//           dateFormat = { dateFormat }
//           onFocus={() => setEditMode(true, init)}
//           onChange = {(e) => handleValue(e)}
//           renderCustomHeader={({
//             date,
//             decreaseMonth,
//             increaseMonth,
//             prevMonthButtonDisabled,
//             nextMonthButtonDisabled,
//           }) => (
//               <div className="flex items-center justify-between pl-1s py-2">
//                   <span className="text-sm text-gray-700 capitalize">
//                       {moment(date).format('MMMM yyyy')}
//                   </span>
    
//                   <div className="space-x-2">
//                       <button
//                           onClick={decreaseMonth}
//                           disabled={prevMonthButtonDisabled}
//                           type="button"
//                           className={`
//                               ${prevMonthButtonDisabled && 'cursor-not-allowed opacity-50'}
//                               inline-flex p-1 text-sm font-medium text-gray-700 bg-slate-100 rounded-sm hover:bg-slate-300 focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-blue-500
//                           `}
//                       >
//                           <HiChevronLeft className="w-4 h-4 text-gray-600" />
//                       </button>
    
//                       <button
//                           onClick={increaseMonth}
//                           disabled={nextMonthButtonDisabled}
//                           type="button"
//                           className={`
//                               ${nextMonthButtonDisabled && 'cursor-not-allowed opacity-50'}
//                               inline-flex p-1 text-sm font-medium items-center text-gray-700 bg-slate-100 rounded-sm hover:bg-slate-300 focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-blue-500
//                           `}
//                       >
//                           <HiChevronRight className="w-4 h-4 text-gray-600" />
//                       </button>
//                   </div>
//               </div>
//           )}
//         />
//       )
// }
