import _ from "lodash";
import { useState } from "react";
import { TbPlus } from "react-icons/tb";
import { BoardView, useSwitchBoardViewMutation } from "../../../core/graphql/types";
import { useBoard } from "../../../core/providers/board";
import { MatrMediumDialog } from "../../ui/portal";
import BoardViewSettings from "./ViewForm";
import BoardViewTab from "./ViewTab";
import UserView from "./UserViewMenu";
import { useSpaceStore } from "../../../core/store/store";



export function ViewMenu():JSX.Element {
  const {initId, initType} = useBoard()
  const init: string = `${initType}:${initId}`
  const store = useSpaceStore()
  const {switchView} = store.boardActions;
  const {board, views} = store.data[init];

  const [ open, setOpen] = useState(false);
  const [ modalContent, setModalContent] = useState<{type: string; id: string;}>({id: "", type: ""});

  const [, switchBoardView] = useSwitchBoardViewMutation()

  function openModal(content: any) {
    setModalContent(content)
    setOpen(true)
  };

  function closeModal() {
    setOpen(false)
    setTimeout(() =>
      setModalContent({id: "", type: ""}),
    300)
  };

  function onViewChange(view_id: string) {
    switchBoardView({boardId: board!.id!, viewId: view_id}).then(res => {
      if(res.data?.switchBoardView?.status === 'ok') {
        switchView(views.find((v: BoardView) => v.id === view_id) as BoardView, init)
      }
    })
  }

  return (
    <>
      <div className="flex px-4 bg-slate-100 space-x-4 border-t border-b items-center">
        <div className="flex space-x-2">
          <button 
            onClick={() => openModal({type: 'views', id: ""})}
            className='pt-2 flex items-center  space-x-2 text-slate-800 hover:text-indigo-600'
          >
            <div className="flex justify-between items-center cursor-pointer h-full w-full py-1 space-x-1.5">
              <TbPlus size={16}/>
              <span className='flex h-full items-center text-sm font-medium'>Новый вид</span>
            </div>
          </button>
          {views.length > 0 && _.orderBy(views, 'position').map((view: BoardView) => (
            <div key={view.id} className="pt-2">
              <BoardViewTab view={view} openModal={openModal} onViewChange={onViewChange}/>
            </div>
          ))}
        </div>
      </div>
      <UserView />
      { modalContent.id ? 
        <MatrMediumDialog open={open} onClose={closeModal}><BoardViewSettings close={closeModal} type={"edit"}/></MatrMediumDialog>
        :
        <MatrMediumDialog open={open} onClose={closeModal}><BoardViewSettings close={closeModal} type={"create"}/></MatrMediumDialog>
      }
    </>
  )
}