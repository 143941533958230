import { Fragment, useEffect, useRef, useState } from 'react';
import { Tab } from '@headlessui/react'
import { NodeField, MattrTypes } from '../../../../../core/graphql/types';
import { MatrPopover } from '../../../../../components/ui/portal';
import _ from 'lodash';
import { Node as ReactFlowNode } from "reactflow"
import { TbLink } from 'react-icons/tb';
import { useFlowContext } from '../../../../../core/providers/flow';

interface LinkNodeFieldDropDownProps {
  value: string | null,
  contextType: MattrTypes,
  onChange: any;
  sourceNodes: ReactFlowNode[] | [];
}

const dateTimeFunctions = [
  {label: "Сегодня", value: "TODAY"},
  {label: "Вчера", value: "YESTERDAY"},
  {label: "Завтра", value: "TOMORROW"}
]

function LinkNodeFieldDropDown({value, contextType, onChange, sourceNodes}: LinkNodeFieldDropDownProps) {
  const buttonRef = useRef<any>(null);
  const [ open, setOpen ] = useState(false);

  function closePopover() { 
    setOpen(false)
  };

  function handleChange(value: string, type: MattrTypes) {
    onChange(value, type)
    closePopover()
  }

  return (
    <>
      <button 
        ref={buttonRef}
        className={`
        ${open ? 'bg-indigo-600 text-white hover:bg-indigo-700 hover:text-white' : 'bg-slate-200 text-slate-700 hover:bg-slate-300 hover:text-slate-800'}
        flex w-7 h-7 rounded-sm bg-slate-200 items-center justify-center`}
        onClick={() => setOpen(true)}
        >
        <span><TbLink /></span>
      </button>
      {open && <MatrPopover onClose={closePopover} reference={buttonRef.current} placement="bottom-start">
        <div className='bg-white max-h-[320px] min-h-[320px] flex w-full rounded text-sm border shadow mt-2'>
          {sourceNodes.length > 0 ? 
            <Tab.Group vertical>
              <Tab.List className="h-fit max-h-[320px] min-h-[320px] w-56 p-2 overflow-auto  space-y-2 border-r">
                {sourceNodes.map((sourceNode: ReactFlowNode) => 
                  <Tab as={Fragment} key={sourceNode.data.id}>
                    {({ selected }) => (
                      <button
                        className={
                          selected ? 'bg-blue-600 text-white font-medium rounded-md p-2 leading-4 w-full flex flex-col' : 'bg-slate-50 text-slate-800 font-medium rounded-sm p-2 leading-4 w-full flex flex-col'
                        }
                      >
                        <div className='text-xxs'>БЛОК</div>
                        <div className='py-1'>
                          {sourceNode.data.name}
                        </div>
                        
                      </button>
                    )}

                  </Tab>
                )}
                {(contextType === MattrTypes.Datetime || contextType === MattrTypes.Date) && 
                  <Tab as={Fragment} key="date_time_function">
                    {({ selected }) => (
                      <button
                        className={
                          selected ? 'bg-blue-600 text-white font-medium rounded-md p-2 leading-4 w-full flex flex-col' : 'bg-slate-50 text-slate-800 font-medium rounded-sm p-2 leading-4 w-full flex flex-col'
                        }
                      >
                        <div className='text-xxs'>ФУНКЦИИ</div>
                        <div className='py-1'>
                          Дата и время
                        </div>
                        
                      </button>
                    )}
                  </Tab>
                }
              </Tab.List>
              <Tab.Panels className="w-96 overflow-auto h-fit max-h-[320px] min-h-[320px]"> 
                {sourceNodes.map((sourceNode: ReactFlowNode) => (
                  <Tab.Panel key={sourceNode.id} className="p-2">
                    <div className="text-base text-slate-400 font-medium w-full p-2 leading-4 cursor-default">{sourceNode.data.name}</div>
                    {sourceNode.data.fields.map((field: NodeField) => (
                      <button 
                        type='button' onClick={() => handleChange(field.id!, field.contextType!)} key={field.id} 
                        className={`${value === field.id ? "bg-indigo-600 text-white hover:bg-indigo-700 " : "bg-white text-slate-600 hover:bg-slate-100 "} text-sm  w-full px-2 h-8 cursor-default flex items-center rounded-sm`}
                        >{field.name}</button>
                    ))}
                  </Tab.Panel>
                ))}
                <Tab.Panel key="date_time_function" className="p-2">
                  <div className="text-base text-slate-400 font-medium w-full p-2 leading-4 cursor-default">Функции даты и времени</div>
                  {dateTimeFunctions.map((item: {label: string, value: string}) => (
                    <button 
                      type='button' onClick={() => handleChange(item.value, MattrTypes.Function)} key={item.value} 
                      className={`${value === item.value ? "bg-indigo-600 text-white hover:bg-indigo-700 " : "bg-white text-slate-600 hover:bg-slate-100 "} text-sm  w-full px-2 h-8 cursor-default flex items-center rounded-sm`}
                      >{item.label}</button>
                  ))}
                </Tab.Panel>
              </Tab.Panels>
            </Tab.Group>
          : <div>Нет входных данных</div>}
        </div>
      </MatrPopover>}
    </>
  )
}

export default LinkNodeFieldDropDown;