import { Fragment } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Listbox, Transition } from "@headlessui/react";
import { bodyTypes } from '../../../../../core/enums/index';
import { HiChevronDown } from 'react-icons/hi';
import Editor from "react-simple-code-editor";
import { highlight, languages } from "prismjs";
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-graphql';
import 'prismjs/components/prism-xml-doc';
import 'prismjs/components/prism-json';
import "prismjs/themes/prism.css";
import MultipartFormData from './multipart_form_data';


const hightlightWithLineNumbers = (input: string, language: any, gramma: string) =>
  highlight(input, language, gramma)
    .split("\n")
    .map((line: any, i: number) => `<span class='editorLineNumber'>${i + 1}</span>${line}`)
    .join("\n");

export default function BodyPanel():JSX.Element {
  const methods = useFormContext();
  const {watch, control, setValue} = methods;


  const handleChange = (value: { name: string; id: string; }, onChange: { (...event: any[]): void; (arg0: string): void; }) => {
    onChange(value.id)
    setValue('body', {type: value.id})
  }


  function showContent(item: string) {
    switch (item) {
      case 'BODY_MULTIPART': return <MultipartFormData />
      case 'BODY_FROM_URL': return <>"from_url_encoded"</>
      case 'BODY_JSON': return (
        <Controller
          name={`body.content`}
          control={control}
          render={({ field: { onChange, value, ref }}) => (
            <div className="text-sm py-4">
              <Editor
                placeholder='...'
                value={value ? value : ""}
                onValueChange={onChange}
                highlight={value => hightlightWithLineNumbers(value, languages.json, "json")}
                textareaId="code"
                className="editor"
              />
            </div>
          )}
        />
      )
      case 'BODY_XML': return (
        <Controller
          name={`body.content`}
          control={control}
          render={({ field: { onChange, value, ref }}) => (
            <div className="text-sm py-4">
              <Editor
                placeholder='...'
                value={value ? value : ""}
                onValueChange={onChange}
                highlight={value => hightlightWithLineNumbers(value, languages.xml, "xml")}
                textareaId="code"
                className="editor"
              />
            </div>
          )}
        />
      )
      case 'BODY_OTHER': return (
        <Controller
          name={`body.content`}
          control={control}
          render={({ field: { onChange, value, ref }}) => (
            <div className="text-sm py-4">
              <Editor
                value={value ? value : ""}
                placeholder='...'
                onValueChange={onChange}
                highlight={value => hightlightWithLineNumbers(value, languages.clike, "clike")}
                className="editor"
                textareaId="code"
              />
            </div>
          )}
        />
      )
      case 'BODY_PLAIN': return (
        <Controller
          name={`body.content`}
          control={control}
          render={({ field: { onChange, value, ref }}) => (
            <div className="text-sm py-4">
              <Editor
                value={value ? value : ""}
                placeholder='...'
                onValueChange={onChange}
                highlight={value => hightlightWithLineNumbers(value, languages.clike, "clike")}
                className="editor"
                textareaId="code"
              />
            </div>
          )}
        />
      )
      case 'BODY_GRAPHQL': return (
        <Controller
          name={`body.content`}
          control={control}
          render={({ field: { onChange, value, ref }}) => (
            <div className="text-sm py-4">
              <Editor
                value={value ? value : ""}
                placeholder='...'
                onValueChange={onChange}
                highlight={value => hightlightWithLineNumbers(value, languages.graphql, "graphql")}
                className="editor"
                textareaId="code"
              />
            </div>
          )}
        />
      )
      default: return  <div className='text-sm text-center flex w-full h-24'>Без содержимого</div>
    }
  }

  return (
    <Controller
      name="bodyType"
      control={control}
      render={({ field: { onChange, value, ref }}) => (
        <>
          <div className='relative flex text-sm'>
              {bodyTypes.map((op, opIdx: number) => (
                <div
                  key={opIdx}
                  className={
                    `relative cursor-default select-none py-1.5 px-2 hover:bg-indigo-100 ${
                      op.id === value ? 'bg-indigo-200 text-indigo-900' : 'text-slate-900'
                    }`
                  }
                  onClick={() => handleChange(op, onChange)}
                >
                  <span
                    className={`block truncate ${
                      op.id === value ? 'font-medium' : 'font-normal'
                    }`}
                  >
                    {op.name}
                  </span>
                </div>
              ))}
          </div>
          
          {showContent(watch('bodyType'))}
        </>
      )}
    />
  )
}