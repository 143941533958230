import _ from 'lodash';
import { useParams } from "react-router-dom";
import ViewsContainer from "../../components/Views";
import { BoardProvider } from "../../core/providers/board";
import { useSidebar } from "../../core/providers/sidebar";
import BoardHeader from "./header";

import { useEffect, useState } from "react";
import { BigSpinner } from "../../components/loaders";
import {
  Board,
  BoardView,
  SpaceUser,
  useBoardSourcesQuery,
  useBoardUsersQuery, useGetBoardQuery
} from "../../core/graphql/types";
import { MattrDataProvider } from "../../core/providers/data";
import { BoardSubscriptionProvider } from "../../core/providers/subscriptions/board";
import { DataStore, dataInitialState, useSpaceStore, userViewInitial } from "../../core/store/store";


export const BoardContainer = () => {
  const {board_id} = useParams()
  const key = `${"board"}:${board_id}`
  const [initialState, setInitialState] = useState<DataStore>(dataInitialState)
  const {isSidebarOpen, openSidebar} = useSidebar()
  const store = useSpaceStore()
  const data = useSpaceStore().data
  let [ready, setReady] = useState<any>({board: false, views: false, users: false, sources: false})

  const [{data: boardData, fetching: boardFetching, error: boardError}] = useGetBoardQuery({variables: {id: board_id!}});
  const [{data: boardUsersData, fetching: boardUsersFetching, error: boardUsersError}] = useBoardUsersQuery({variables: { id: board_id!}});
  const [{data: sourcesData, fetching: sourcesFetching, error: sourcesError}] = useBoardSourcesQuery({variables: {id: board_id!}});

  useEffect(() => {
    store.boardActions.resetAllBoardData()
  }, [])

  useEffect(() => {
    if(ready.board && ready.users && ready.views && ready.sources) {
      let newObject: Record<string, DataStore> = {}
      newObject[key] = {...initialState, ...{initId: board_id!, initType: "board", boardIsLoading: true}}
      store.boardActions.addBoardData(newObject)
    } else {
      return
    }
  },[ready])

  // Получение BOARD, USERS, VIEWS, SOURCES, FIELDS
  useEffect(() => {
    if(!boardData || !boardData?.getBoard || !boardData?.getBoard.views) { return }
    if(!sourcesData || !sourcesData?.boardSources || !sourcesData?.boardSources?.boards) { return }
    if(!boardUsersData || !boardUsersData?.boardUsers) { return }

    let cv = _.orderBy(boardData?.getBoard.views as BoardView[], [(obj) => new Date(obj?.lastTimeSeen)], ['desc'])[0]
    let ucv = store.currentSpaceUser?.views?.find(cuv => cuv?.viewId === cv.id) ? store.currentSpaceUser?.views?.find(cuv => cuv?.viewId === cv.id)! : userViewInitial

    setInitialState({
      ...initialState, 
      inBlock: false,
      board: boardData?.getBoard as Board,
      views: boardData?.getBoard.views as BoardView[],
      sources: sourcesData?.boardSources?.boards as Board[],
      users: boardUsersData?.boardUsers as SpaceUser[],
      currentView: cv,
      rowHeight: ucv.options?.rowHeight ? setRowHeight(ucv.options?.rowHeight) : setRowHeight(cv.options?.rowHeight!),
      showModalAs: "modal",
      userCurrentView: store.currentSpaceUser?.views?.find(cuv => cuv?.viewId === cv.id) ? store.currentSpaceUser?.views?.find(cuv => cuv?.viewId === cv.id)! : userViewInitial
    })

    setReady({...ready, ...{board: true, views: true, users: true, sources: true}})
  },[boardData, boardData?.getBoard, boardUsersData, boardUsersData?.boardUsers, sourcesData, sourcesData?.boardSources])

  function setRowHeight(rowHeight: string) {
    switch (rowHeight) {
      case "SHORT": return 36
      case "MEDIUM": return 76
      case "TALL": return 136
      case "EXTRA": return 216
      default: return 36
    }
  }


  if(key in data) { return (
    <MattrDataProvider initiator={{id: board_id!, type: "board"}}>
      <BoardSubscriptionProvider boardId={board_id!}>
        <BoardProvider>
          <div className='flex-none flex w-full h-14 items-center text-base px-4 justify-between'>
            <BoardHeader />
          </div>
          <ViewsContainer />
        </BoardProvider>
      </BoardSubscriptionProvider>
    </MattrDataProvider>
  )} else {
    return <BigSpinner />
  }


}