import React from "react";
import _ from 'lodash'

export function stopPropagation(event: React.ChangeEvent) {
  event.stopPropagation();
}


export function mergeRefs(refs: any[]) {
  return (value: any) => {
    refs.forEach(ref => {
      if (typeof ref === 'function') {
        ref(value)
      } else if (ref != null) {
        ref.current = value
      }
    })
  }
}


export function array_move(arr: [], old_index: number, new_index: number) {
  if (new_index >= arr.length) {
      var k = new_index - arr.length + 1;
      while (k--) {
          arr.push();
      }
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
  // return arr; // for testing
};


export const mergeArrays = (arr1: any, arr2: any) => 
   arr1 && arr1.map((obj: {id: string}) => arr2 && arr2.find((p: {id: string}) => p.id! === obj.id!) || obj);

export function getListOfIds(array: [{id: string}]) {
  return array.map(it => {
    return it.id 
  })
}

export const removeById = (arrayOfObjects: {id: string}[], listToDelete: any, action: string) => {
  if(action === "delete") {
    return arrayOfObjects.map(obj => {
      if(!listToDelete.includes[obj.id]) {
        return obj
      }
    })
  } else {
    return arrayOfObjects.map(obj => {
      if(listToDelete.includes[obj.id]) {
        return obj
      }
    })
  }
}

export const transformPositionsInc = (arr: any[]) => {
  const new_rest: any = []
  arr.map(i => {
    new_rest.push({...i, position: i.position + 1})
  })
  return new_rest
}

export const transformPositionsDec = (arr: any[]) => {
  const new_rest: any = []
  arr.map(i => {
    new_rest.push({...i, position: i.position - 1})
  })
  return new_rest
}

export const splitNames = (names: string[] | string) => {
  if (!names || names.length === 0) {
    return
  }

  if (typeof names  === "string") {
    return names
  } else {
    return (names.join(', '))
  }

}

export function withoutProperty(obj: { [x: string]: any; }, property: any) {  
  const { [property]: unused, ...rest } = obj
  return rest
}

export const isEmailValid = (email: string) => {
  // eslint-disable-next-line
  let re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(email)
}


export function hashCode(str: string) {
  var hash = 0;
  for (var i = 0; i < str.length; i++) {
     hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  return hash;
} 

export function intToRGB(i: any){
  var c = (i & 0x00FFFFFF)
      .toString(16)
      .toUpperCase();

  return "00000".substring(0, 6 - c.length) + c;
}

export function hexToRGB(hex: string, alpha: any) {

  let r = parseInt(hex.slice(0, 2), 16),
      g = parseInt(hex.slice(2, 4), 16),
      b = parseInt(hex.slice(4, 6), 16);

  if (alpha) {
      return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
  } else {
      return "rgb(" + r + ", " + g + ", " + b + ")";
  }
}


export function getKeyForType(type: string) {
  switch (type) {
    case 'STRING':
    case 'URL':
    case 'EMAIL':
    case 'FORMULA':
      return 'valueAsString'
    case 'CHECKBOX':
      return 'valueAsBool'
    case 'NUMBER':
    case 'PHONE':
    case 'DURATION':
    case 'RECORDID':
      return 'valueAsNumber'
    case 'DECIMAL':
    case 'PERCENT':
    case 'CURRENCY':
      return 'valueAsDecimal'
    case 'SELECT':
    case 'CREATED_BY':
    case 'UPDATED_BY':
      return 'valueAsJson'
    case 'MULTISELECT':
    case 'COLLABORATOR':
      return 'valueAsJsonArray'
    case 'DATETIME':
    case 'INSERTED_AT':
    case 'UPDATED_AT':
      return 'valueAsDatetime'
    case 'RELATION':
    case 'LOOKUP':
      return 'links'
    default: return 'valueAsString'
  }
}

export const customStyles = {
  container: () => ({
    width: '100%'
  }),
  control: (provided: any, state: { isFocused: any; }) => ({
    ...provided,
    // background: '#fff',
    // borderColor: '#9e9e9e',
    border: 0,
    minHeight: '34px',
    height: '34px',
    boxShadow: state.isFocused ? null : null,
  }),

  // Нужно ститлизовать тэги

  // multiValue: (base: any) => ({
  //   ...base,
  //   backgroundColor: 'red',
  //   borderRadius: '6px',
  //   color: 'white',
  // }),

  // multiValueLabel: (base: any) => ({
  //   ...base,
  //   backgroundColor: 'red',
  //   borderTopLeftRadius: '6px',
  //   borderBottomLeftRadius: '6px',
  //   color: 'white',
  // }),

  valueContainer: (provided: any, state: any) => ({
    ...provided,
    height: '34px',
    padding: '0 6px'
  }),

  input: (provided: any, state: any) => ({
    ...provided,
    margin: '0px',
  }),
  indicatorSeparator: (state: any) => ({
    display: 'none',
  }),
  indicatorsContainer: (provided: any, state: any) => ({
    ...provided,
    height: '34px',
  }),
};


export function uuidv4() {
  //@ts-ignore
  return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  );
}

export function isArrayEqual(x: any, y: any) {
  return _(x).xorWith(y, _.isEqual).isEmpty();
};