import { MdUpdate, MdTimer } from "react-icons/md";
import { FaUserEdit, FaUserClock } from "react-icons/fa";
import { HiOutlinePhone, HiLink, HiOutlineAtSymbol, HiOutlineDocument } from "react-icons/hi";
import { TbAbacus, TbNumbers } from "react-icons/tb";

const StringIcon = () => {
  return (
    <svg className='w-3 h-3' viewBox='0 0 512 512' fill="currentColor">
      <path d='M292.6 407.78l-120-320a22 22 0 00-41.2 0l-120 320a22 22 0 0041.2 15.44l36.16-96.42a2 2 0 011.87-1.3h122.74a2 2 0 011.87 1.3l36.16 96.42a22 22 0 0041.2-15.44zm-185.84-129l43.37-115.65a2 2 0 013.74 0l43.37 115.67a2 2 0 01-1.87 2.7h-86.74a2 2 0 01-1.87-2.7zM400.77 169.5c-41.72-.3-79.08 23.87-95 61.4a22 22 0 0040.5 17.2c8.88-20.89 29.77-34.44 53.32-34.6 32.32-.22 58.41 26.5 58.41 58.85a1.5 1.5 0 01-1.45 1.5c-21.92.61-47.92 2.07-71.12 4.8-54.75 6.44-87.43 36.29-87.43 79.85 0 23.19 8.76 44 24.67 58.68C337.6 430.93 358 438.5 380 438.5c31 0 57.69-8 77.94-23.22h.06a22 22 0 1044 .19v-143c0-56.18-45-102.56-101.23-102.97zM380 394.5c-17.53 0-38-9.43-38-36 0-10.67 3.83-18.14 12.43-24.23 8.37-5.93 21.2-10.16 36.14-11.92 21.12-2.49 44.82-3.86 65.14-4.47a2 2 0 012 2.1C455 370.1 429.46 394.5 380 394.5z'/>
    </svg>
  )
}

const AttachmentIcon = () => {
  return (
    <HiOutlineDocument size={12}/>
  )
}

const UrlIcon = () => {
  return (
    <HiLink size={12}/>
  )
}

const EmailIcon = () => {
  return (
    <HiOutlineAtSymbol size={12}/>
  )
}

const PhoneIcon = () => {
  return (
    <HiOutlinePhone size={12}/>
  )
}

const NumberIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3" viewBox="0 0 20 20" fill="currentColor">
      <path fillRule="evenodd" d="M9.243 3.03a1 1 0 01.727 1.213L9.53 6h2.94l.56-2.243a1 1 0 111.94.486L14.53 6H17a1 1 0 110 2h-2.97l-1 4H15a1 1 0 110 2h-2.47l-.56 2.242a1 1 0 11-1.94-.485L10.47 14H7.53l-.56 2.242a1 1 0 11-1.94-.485L5.47 14H3a1 1 0 110-2h2.97l1-4H5a1 1 0 110-2h2.47l.56-2.243a1 1 0 011.213-.727zM9.03 8l-1 4h2.938l1-4H9.031z" clipRule="evenodd" />
    </svg>
  )
}

const DurationIcon = () => {
  return (
    <MdTimer size={12}/>
  )
}

const TextareaIcon = () => {
  return (
    <svg className='w-3 h-3' viewBox='0 0 512 512' fill="currentColor">
      <rect width="48" height="48" fill="currentColor" />
      <path d='M292.6 407.78l-120-320a22 22 0 00-41.2 0l-120 320a22 22 0 0041.2 15.44l36.16-96.42a2 2 0 011.87-1.3h122.74a2 2 0 011.87 1.3l36.16 96.42a22 22 0 0041.2-15.44zm-185.84-129l43.37-115.65a2 2 0 013.74 0l43.37 115.67a2 2 0 01-1.87 2.7h-86.74a2 2 0 01-1.87-2.7zM400.77 169.5c-41.72-.3-79.08 23.87-95 61.4a22 22 0 0040.5 17.2c8.88-20.89 29.77-34.44 53.32-34.6 32.32-.22 58.41 26.5 58.41 58.85a1.5 1.5 0 01-1.45 1.5c-21.92.61-47.92 2.07-71.12 4.8-54.75 6.44-87.43 36.29-87.43 79.85 0 23.19 8.76 44 24.67 58.68C337.6 430.93 358 438.5 380 438.5c31 0 57.69-8 77.94-23.22h.06a22 22 0 1044 .19v-143c0-56.18-45-102.56-101.23-102.97zM380 394.5c-17.53 0-38-9.43-38-36 0-10.67 3.83-18.14 12.43-24.23 8.37-5.93 21.2-10.16 36.14-11.92 21.12-2.49 44.82-3.86 65.14-4.47a2 2 0 012 2.1C455 370.1 429.46 394.5 380 394.5z'/>
    </svg>
  )
}

const DecimalIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3" viewBox="0 0 48 48" fill="currentColor">
      <path d="M0,42.1v-8.5h5.9v8.5H0z"/>
      <path d="M26.9,14.3v17.9c0,5.6-1.7,10.4-9,10.4c-6.7,0-8.5-4.7-8.5-10.3V14.7c0-6.5,2.8-10.3,8.9-10.3C24.2,4.4,26.9,8.1,26.9,14.3
        z M15.2,14v18.9c0,2.8,0.8,4.3,2.9,4.3c2.1,0,2.9-1.6,2.9-4.5V13.9c0-2.6-0.8-4.1-2.9-4.1C16.2,9.7,15.2,11.1,15.2,14z"/>
      <path d="M48,14.3v17.9c0,5.6-1.7,10.4-9,10.4c-6.7,0-8.5-4.7-8.5-10.3V14.7c0-6.5,2.8-10.3,8.9-10.3C45.3,4.4,48,8.1,48,14.3z
        M36.3,14v18.9c0,2.8,0.8,4.3,2.9,4.3c2.1,0,2.9-1.6,2.9-4.5V13.9c0-2.6-0.8-4.1-2.9-4.1C37.3,9.7,36.3,11.1,36.3,14z"/>
    </svg>
  )
}

const PercentIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3" viewBox="0 0 20 20" fill="currentColor">
      <path d="M16,9.6c-2.2,0-4,0.9-4,3.6v3.7c0,2.6,1.7,3.6,4,3.6c2.2,0,4-0.9,4-3.6v-3.7C20,10.5,18.3,9.6,16,9.6z M17.4,16.9 L17.4,16.9c0,0.9-0.5,1.3-1.3,1.3c-0.8,0-1.3-0.4-1.3-1.3v-3.7c0-0.9,0.5-1.3,1.3-1.3c0.8,0,1.3,0.4,1.3,1.3V16.9z"/>
      <path d="M14.5,0c-0.5,0-0.9,0.2-1.1,0.7l-9.2,19C4.1,19.8,4,20,4,20.2c0,0.7,0.6,1.4,1.5,1.4c0.5,0,1-0.3,1.2-0.7l9.3-19 c0.1-0.2,0.1-0.3,0.1-0.5C16.1,0.6,15.3,0,14.5,0z"/>
      <path d="M4,1.1C1.7,1.1,0,2,0,4.6v3.7c0,2.6,1.7,3.6,4,3.6c2.2,0,4-0.9,4-3.6V4.6C7.9,2,6.2,1.1,4,1.1z M5.3,8.4 c0,0.9-0.5,1.3-1.3,1.3S2.7,9.2,2.7,8.4V4.6c0-0.9,0.5-1.3,1.3-1.3s1.3,0.4,1.3,1.3V8.4z"/>
    </svg>
  )
}

const CurrencyIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3" viewBox="0 0 20 20" fill="currentColor">
      <path d="M13.1,0C9.8,0,6.2,1.1,6.2,3.1v2.5c0,0.3,0.3,0.6,0.6,0.6S7.5,6,7.5,5.6V5c1.3,0.8,3.5,1.3,5.6,1.3s4.3-0.4,5.6-1.3v0.7
      c0,0.6-1.5,1.6-4.4,1.8c-0.3,0-0.6,0.3-0.6,0.7c0,0.3,0.3,0.6,0.6,0.6c0,0,0,0,0.1,0c1.6-0.1,3.2-0.6,4.3-1.2v0.7
      c0,0.5-1.1,1.3-3.2,1.7c-0.3,0.1-0.6,0.4-0.5,0.7c0.1,0.3,0.3,0.5,0.6,0.5c0,0,0.1,0,0.1,0c1.3-0.2,2.3-0.6,3-1.1v0.7
      c0,0.5-1.1,1.3-3.2,1.7C15.2,12.4,15,12.7,15,13c0.1,0.3,0.3,0.5,0.6,0.5c0,0,0.1,0,0.1,0c1.3-0.2,2.3-0.6,3-1.1v0.7
      c0,0.5-1.1,1.3-3.2,1.7c-0.3,0.1-0.6,0.4-0.5,0.7c0.1,0.3,0.3,0.5,0.6,0.5c0,0,0.1,0,0.1,0c1.3-0.2,2.3-0.6,3-1.1v0.7
      c0,0.6-1.5,1.6-4.4,1.8c-0.3,0-0.6,0.3-0.6,0.7c0,0.3,0.3,0.6,0.6,0.6c0,0,0,0,0.1,0c2.8-0.2,5.6-1.3,5.6-3.1V3.1
      C20,1.1,16.5,0,13.1,0z M13.1,5C9.7,5,7.5,3.9,7.5,3.1s2.2-1.9,5.6-1.9s5.6,1.1,5.6,1.9S16.6,5,13.1,5z"/>
      <path d="M6.9,7.5C3.5,7.5,0,8.6,0,10.6v5c0,2,3.5,3.1,6.9,3.1s6.9-1.1,6.9-3.1v-5C13.8,8.6,10.2,7.5,6.9,7.5z M12.5,15.6
        c0,0.8-2.2,1.9-5.6,1.9s-5.6-1.1-5.6-1.9V15c1.3,0.8,3.5,1.3,5.6,1.3s4.3-0.4,5.6-1.3V15.6z M12.5,13.1c0,0.8-2.2,1.9-5.6,1.9
        s-5.6-1.1-5.6-1.9v-0.7c1.3,0.8,3.5,1.3,5.6,1.3s4.3-0.4,5.6-1.3V13.1z M6.9,12.5c-3.4,0-5.6-1.1-5.6-1.9s2.2-1.9,5.6-1.9
        s5.6,1.1,5.6,1.9S10.3,12.5,6.9,12.5z"/>            
    </svg>
  )
}

const SelectIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2" />
    </svg>
  )
}

const MultiSelectIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01" />
    </svg>
  )
}

const CollaboratorIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z" />
    </svg>
  )
}

const DateTimeIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
    </svg>
  )
}

const InsertedAtIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
    </svg>
  )
}

const RecordidIcon = () => {
  return (
    <TbNumbers size={12}/>
  )
}

const RelationIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13.828 10.172a4 4 0 00-5.656 0l-4 4a4 4 0 105.656 5.656l1.102-1.101m-.758-4.899a4 4 0 005.656 0l4-4a4 4 0 00-5.656-5.656l-1.1 1.1" />
    </svg>
  )
}


const LookupIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
    </svg>
  )
}

const CheckboxIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z" />
    </svg>
  )
}

const FormulaIcon = () => {
  return (
    <TbAbacus size={12}/>
  )
}

const ErrorIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3" viewBox="0 0 20 20" fill="currentColor">
      <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
    </svg>
  )
}

const GridIcon = () => {
  return (
  <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 10h18M3 14h18m-9-4v8m-7 0h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z" />
  </svg>
  )
}

const UpdatedAtIcon = () => {
  return (
    <MdUpdate size={12}/>
  )
}

const CreatedByIcon = () => {
  return (
    <FaUserEdit size={12}/>
  )
}

const UpdatedByIcon = () => {
  return (
    <FaUserClock size={12}/>
  )
}

const iconByType = (type: string) => {
  switch (type) {
    case "STRING": return <StringIcon />
    case "URL": return <UrlIcon />
    case "EMAIL": return <EmailIcon />
    case "PHONE": return <PhoneIcon />
    case "NUMBER": return <NumberIcon />
    case "RECORID": return <RecordidIcon />
    case "TEXTAREA": return <TextareaIcon />
    case "DECIMAL": return <DecimalIcon />
    case "PERCENT": return <PercentIcon />
    case "CURRENCY": return <CurrencyIcon />
    case "SELECT": return <SelectIcon />
    case "MULTISELECT": return <MultiSelectIcon />
    case "COLLABRATOR": return <CollaboratorIcon />
    case "DATETIME": return <DateTimeIcon />
    case "INSERTED_AT": return <InsertedAtIcon />
    case "UPDATED_AT": return <UpdatedAtIcon />
    case "CREATED_BY": return <CreatedByIcon />
    case "UPDATED_BY": return <UpdatedByIcon />
    case "RELATION": return <RelationIcon />
    case "LOOKUP": return <LookupIcon />
    case "CHECKBOX": return <CheckboxIcon />
    case "FORMULA": return <FormulaIcon />
    case "ERROR": return <ErrorIcon />
    case "ATTACHMENT": return <AttachmentIcon />
    default: return <></>
  }
}

export {
  iconByType,
  StringIcon,
  UrlIcon,
  EmailIcon,
  PhoneIcon,
  NumberIcon,
  DurationIcon,
  TextareaIcon,
  DecimalIcon,
  PercentIcon,
  CurrencyIcon,
  SelectIcon,
  MultiSelectIcon,
  CollaboratorIcon,
  DateTimeIcon,
  InsertedAtIcon,
  UpdatedAtIcon,
  CreatedByIcon,
  UpdatedByIcon,
  RelationIcon,
  LookupIcon,
  CheckboxIcon,
  FormulaIcon,
  ErrorIcon,
  GridIcon,
  AttachmentIcon,
  RecordidIcon
}