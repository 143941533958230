import { useEffect, useState, useCallback } from "react";
import { useForm, Controller } from "react-hook-form";
import { BiToggleLeft, BiToggleRight } from "react-icons/bi";
import Select from "react-select";
import { BigSpinner } from "../../../components/loaders";
import MatrSelect from "../../../components/ui/select/base";
import CoverSpaceUploader from "../../../components/Uploaders/CoverSpaceUploader";
import { useCurrentSpaceQuery, useUpdateSpaceMutation, useTimezonesQuery, useUploadSpaceCoverMutation } from "../../../core/graphql/types"
import { useAuth } from "../../../core/providers/auth";

const storage_url = process.env.REACT_APP_STORAGE_URL ? process.env.REACT_APP_STORAGE_URL : ""

export function SpaceSettingsBase() {
  const {currentUser, setCurrentUser} = useAuth()
  const { register, handleSubmit, setValue, control, formState: { errors } } = useForm();
  const [timezones, setTimezones] = useState<{label: string | null, value: string | null}[]>([]);
  const [{fetching: fetchingTimezones, data: dataTimezones}] = useTimezonesQuery()
  const [{fetching, data, error}, getSpace] = useCurrentSpaceQuery();
  const [, updateSpace] = useUpdateSpaceMutation();
  const [cover, setCover] = useState("")

  useEffect(() => {
    if(!data?.currentSpace) {return}
    setValue('name', data?.currentSpace.name || "")
    setValue('private', data?.currentSpace.private || true)
    setValue('template', data?.currentSpace.template)
    setValue('timezone', data?.currentSpace.timezone || "")
    setCover(data?.currentSpace.cover ? `${data?.currentSpace.cover}?${Date.now()}` : "")
  },[data?.currentSpace, setValue])

  useEffect(() => {
    if(!dataTimezones?.timezones || !dataTimezones?.timezones.length) {return}
    const timezoneList = 
    dataTimezones?.timezones!.map(t => {
      return {label: t, value: t}
    })

    setTimezones(timezoneList)
  },[dataTimezones?.timezones])


  const onSubmit = handleSubmit(data => {
    updateSpace({
      name: data.name,
      private: data.private,
      template: data.template,
      timezone: data.timezone.value
    }).then(result => {
      setCurrentUser(
        {...currentUser, 
          space: {...currentUser.space, ...result.data?.updateSpace}, 
          spaces: currentUser.spaces.map((space: any) => {
            if(space.spaceId === result.data?.updateSpace?.spaceId) {
              return {...space, ...result.data?.updateSpace}
            } else {
              return space
            }
          })
        }
      )
    })
  });

  function onChangeCover() {
    getSpace()
  }

  if (fetching && fetchingTimezones) return <BigSpinner/>;
  if (error) return <>`Error! ${error.message}`</>;

  return (
    <div className="w-full">
      <div className="flex pb-8">
        <div className="bg-white w-full h-80 relative">
          <div className="absolute top-4 right-4">
            <CoverSpaceUploader onChange={onChangeCover}/>
          </div>
          <div className="mb-4 w-full">
            {cover && storage_url ? 
              <img className="w-full mx-auto h-80 object-cover object-center rounded-md" src={`${storage_url}${cover}`} alt="avatar" loading="lazy"></img>
              :
              <div className="w-full mx-auto h-80 object-cover object-center bg-cyan-200"></div>
            }
          </div>
        </div>
      </div>
      <form onSubmit={onSubmit} className="flex flex-col relative w-full space-y-4">
        <div className="relative space-y-1.5">
          <label className="matr-label">Название пространства:</label>
          <input {...register("name", 
            { required: true})}
            className="matr-form-input" 
            placeholder="Название пространства"
          />
          {errors.name && <span className="text-red-600 text-xs font-normal px-2">Не может быть пустым</span>}
        </div>
        <div className="relative space-y-1.5">
          <Controller
            name="template"
            control={control}
            render={({ field: { onChange, value, ref }}) => (
              <div 
                className={`flex items-center py-1.5 px-2 text-sm ${value ? "bg-green-300 text-green-800 border-green-500" : "bg-slate-50 text-slate-600"} rounded-sm font-medium border justify-between w-full cursor-default`} 
                onClick={() => onChange(!value)}
              >
                Использовать как шаблон
                {value ? <BiToggleRight className='h-6 w-6 text-green-800'/> : <BiToggleLeft className='h-6 w-6 text-slate-400'/>}
              </div>
            )}
          />
        </div>
        <div className="relative space-y-1.5">
          <label className="matr-label">Часовой пояс пространства:</label>
          <Controller
            name="timezone"
            control={control}
            render={({ field: { onChange, value, ref }}) => (
              <div className="mt-2">
                <MatrSelect
                  placeholder="Выберите часовой пояс"
                  isSearchable={true}
                  value={value ? timezones.find(t => t.value === value) : ""}
                  onChange={onChange}
                  options={timezones}
                />
              </div>
            )}
          />
        </div>
        <div className="flex justify-end">
          <button
            type="submit"
            className="flex items-center h-full text-sm justify-center px-4 py-2 bg-indigo-600 rounded-md text-white font-medium"
          >
            Сохранить
          </button>
        </div>
      </form>
    </div>
  )
}