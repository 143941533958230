import { Outlet } from "react-router-dom";
import Header from "../../components/Headers/Header";


export function SpaceSettings() {
  return (
    <>
      <Header location="space"/>
      <section className="w-full bg-white flex h-full">
        <div className="flex flex-1 w-full max-w-2xl mx-auto px-6 py-4">
          <Outlet />
        </div>
      </section>
    </>
  )
}