import { useCallback, useEffect, useState } from "react";
import { sortBy } from "lodash";
import { SmallSpinner } from "../../../loaders";
import { BoardForm, Field, FormField, User, useStoreExternalFormMutation, useStoreFormMutation } from "../../../../core/graphql/types";
import { useForm, Controller, FormProvider } from "react-hook-form";
import toast from 'react-hot-toast';
import { getKeyForType } from "../../../../utils";
import { useBoardUsersQuery } from '../../../../core/graphql/types';

import FormStringEditor from "../../Fields/FormStringEditor";
import FormUrlEditor from "../../Fields/FormUrlEditor";
import FormNumberEditor from "../../Fields/FormNumberEditor";
import FormCheckboxEditor from "../../Fields/FormCheckboxEditor";
import FormDecimalEditor from "../../Fields/FormDecimalEditor";
import FormPercentEditor from "../../Fields/FormPercentEditor";
import FormCurrencyEditor from "../../Fields/FormCurrencyEditor";
import FormEmailEditor from "../../Fields/FormEmailEditor";
import FormPhoneEditor from "../../Fields/FormPhoneEditor";
import FormSelectorEditor from "../../Fields/FormSelectorEditor";
import FormMultiSelectEditor from "../../Fields/FormMultiSelectEditor";
import FormDateTimeEditor from "../../Fields/FormDateTimeEditor";
import FormRelationEditor from "../../Fields/FormRelationEditor";
import FormDurationEditor from "../../Fields/FormDurationEditor";
import { useTelegram } from "../../../../core/hooks/useTelegram";

export default function TelegramExternalForm({form, close}: {form: BoardForm, close: any}) {
  const {tg, user, onToggleButton, onClose} = useTelegram()
  const methods = useForm();
  const {watch, getValues, formState: {isValid}} = methods;

  const [{fetching: fetchingStore}, storeForm] = useStoreExternalFormMutation()

  useEffect(() => {
    tg.MainButton.setParams({
      text: form.submitText
    })
  },[])

  useEffect(() => {
    if(isValid) {
      tg.MainButton.show()
    } else {
      tg.MainButton.hide()
    }
  },[isValid])

  const onSendData = useCallback(() => {
    // console.log(getValues())
    const data = getValues()
    onSubmit(data)
    onClose()
    // tg.sendData()
  }, [])

  useEffect(() => {
    tg.onEvent('mainButtonClicked', onSendData)
    return () => {
      tg.offEvent('mainButtonClicked', onSendData)
    }
  },[])



  function getField(form_field: FormField) {
    switch (form_field.field.type) {
      case 'STRING': return <FormStringEditor formField={form_field}/>
      case 'URL': return <FormUrlEditor formField={form_field}/>
      case 'EMAIL': return <FormEmailEditor formField={form_field}/>
      case 'NUMBER': return <FormNumberEditor formField={form_field}/>
      case 'PHONE': return <FormPhoneEditor formField={form_field}/>
      case 'DECIMAL': return <FormDecimalEditor formField={form_field}/>
      case 'PERCENT': return <FormPercentEditor formField={form_field}/>
      case 'CURRENCY': return <FormCurrencyEditor formField={form_field}/>
      case 'CHECKBOX': return <FormCheckboxEditor formField={form_field}/>
      case 'SELECT': return <FormSelectorEditor formField={form_field}/>
      case 'MULTISELECT': return <FormMultiSelectEditor formField={form_field}/>
      case 'DURATION': return <FormDurationEditor formField={form_field}/>
      case 'DATETIME': return <FormDateTimeEditor formField={form_field}/>
      case 'RELATION': return <FormRelationEditor formField={form_field} form={form}/>

      default: return <></>
    }
  }

  const onSubmit = (data: any) => {
    let values: any = []

    for (const [key, value] of Object.entries(data)) {
      let obj = {}
      let field = form?.fields?.find(f => f?.fieldId === key)
      let valueKey = getKeyForType(field?.field?.type!)
      
      if(valueKey === 'links') {
        //@ts-ignore
        obj["links"] = value.map(r => ({
          valueId: r.fields[0].id
        }))
      } else if(field?.field.type === 'DATETIME') {
        //@ts-ignore
        obj[valueKey] = value ? value.toISOString() : null
      } else {
        //@ts-ignore
        obj[valueKey] = value
      }
      
      values.push(
        { 
          ...obj,
          fieldId: key,
          type: field?.field?.type!
        }
      )
    }
    
    let storeData = {boardId: form.boardId, slug: form.slug, values: values}

    toast.promise(
      storeForm(storeData).then(res => {
        if(res.error) {
          return res
        }
        close()
        return res
      }),
      {
        loading: 'Отправляем форму...',
        success: (data) => {
          if (data.error) throw new Error();
          return 'Форм отправлена';
        },
        error: 'Ошибка. Не удалось отправить форму',
      }
    );
  };

  const onError = (errors: any, e: any) => console.log(errors, e, "ERROR");

  if(!form) return <SmallSpinner />

  return (
    <div 
      className="
        flex 
        flex-col 
        h-full w-full
        telegram_bg_color
        overflow-scroll"
      >
      <div className="flex flex-col w-full max-w-xl h-full mx-auto">
        <FormProvider {...methods}>
          <form>
            <div className="flex flex-col space-y-6 mt-10">
            {
              sortBy(form?.fields!, ['position']).map((form_field: FormField | any) => (
                <div key={form_field.id} className="px-2">{getField(form_field)}</div>
              ))
            }
            </div>
          </form>
        </FormProvider>
        {/* {isValid ? <button onClick={() => onSendData()}>TEEEEE</button> : <></>} */}
      </div>
    </div>
  )
}