import ReactJson from 'react-json-view';
import { useFetcherContext } from '../../../../../core/providers/fetcher';

export default function ResponsePanel():JSX.Element {
  const {response} = useFetcherContext()

  function getViewer() {
    switch (response?.contentType) {
      case 'json':
        return (
          <div className='p-4 flex w-full'>
            <ReactJson
              src={JSON.parse(response?.body!)}
              enableClipboard={false}
              collapsed={false}
              indentWidth={2}
              groupArraysAfterLength={2}
            />
          </div>
        )
    
      default:
        return (
          <div className='w-full h- full items-center justify-center text-sm text-slate-400 p-4'>Просмотр недоступен</div>
        )
    }
  }

  return getViewer()
  
}