import { useSignUpMutation } from "../../core/graphql/types";
import { useNavigate, redirect} from "react-router-dom";
import { useForm } from "react-hook-form";
import { isEmailValid } from "../../utils";

interface SignUpProps {
  firstName: string
  lastName: string
  email: string
  password: string
}

const SignUp = () => {
  const { register, handleSubmit, watch, setError, formState: { errors } } = useForm(); 
  let navigate = useNavigate();
  const [{fetching, error}, registration] = useSignUpMutation();

  const onSubmit = handleSubmit((data: SignUpProps | any) => registration(data).then(result => {
    if (!result.data?.signUp) {
      return;
    }
    navigate('/')
  }));


  return (
    <section className="w-full bg-white">
      <div className="mx-auto max-w-7xl">
        <div className="flex flex-col lg:flex-row">
          <div className="w-full bg-white lg:w-6/12 xl:w-5/12 mx-auto">
            <div className="flex flex-col w-full h-full p-10 lg:p-16 xl:p-24">
              <h4 className="w-full text-3xl font-bold">Добро пожаловать,</h4>
              <p className="text-m text-gray-500 leading-10">или, если уже с нами <a href="/" className="text-indigo-600 underline">войдите</a></p>
              <div className="relative w-full mt-6">
                <form onSubmit={onSubmit} className="space-y-4">
                  <div className="relative">
                    <label className="font-medium text-gray-900 text-sm">Имя</label>
                    <input {...register("firstName", { required: true })} 
                      className="block w-full px-3 mt-2 placeholder-gray-400 bg-gray-100 rounded-sm focus:outline-none focus:ring-4 focus:ring-blue-600 focus:ring-opacity-50 text py-2"
                    />
                  </div>
                  <div className="relative">
                    <label className="font-medium text-gray-900 text-sm">Фамилия</label>
                    <input {...register("lastName", { required: true })} 
                      className="block w-full px-3 mt-2 placeholder-gray-400 bg-gray-100 rounded-sm focus:outline-none focus:ring-4 focus:ring-blue-600 focus:ring-opacity-50 text py-2"
                    />
                  </div>
                  <div className="relative">
                    <label className="font-medium text-gray-900 text-sm">Электронная почта</label>
                    <input {...register("email", { required: true, validate: value => isEmailValid(value) })} 
                      autoComplete="email"
                      className="block w-full px-3 mt-2 placeholder-gray-400 bg-gray-100 rounded-sm focus:outline-none focus:ring-4 focus:ring-blue-600 focus:ring-opacity-50 text py-2"
                    />
                  </div>
                  <div className="relative">
                    <label className="font-medium text-gray-900 text-sm">Пароль</label>
                    <input {...register("password", { required: true })}
                      type="password"
                      autoComplete="new-password"
                      className="block w-full px-3 mt-2 placeholder-gray-400 bg-gray-100 rounded-sm focus:outline-none focus:ring-4 focus:ring-blue-600 focus:ring-opacity-50 text py-2"
                    />
                  </div>
                  {errors.firstName && <span className="text-xs py-1 text-red-900">Необходимое поле</span>}
                  {errors.llastName && <span className="text-xs py-1 text-red-900">Необходимое поле</span>}
                  {errors.email && <span className="text-xs py-1 text-red-900">Необходимое поле</span>}
                  {errors.password && <span className="text-xs py-1 text-red-900">Необходимое поле</span>}
                  <div className="flex pt-4">
                    <button type="submit"
                      className="inline-block w-full py-3 text-sm font-medium text-center text-white transition duration-200 bg-indigo-600 rounded-md hover:bg-indigo-700 ease"
                    >Регистрация</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
};

export default SignUp;
