import React, {useEffect} from "react";
import { useFormContext, Controller } from "react-hook-form";
import { dateFormatList, timeFormatList } from "../../../../../core/enums"
import Select from "react-select";
import { BiToggleLeft, BiToggleRight } from "react-icons/bi";
import { customStyles } from "../../../../../utils";

interface FieldRecordidProps {
  type: string;
}

function FieldRecordid({attributes}: FieldRecordidProps | any) {
  const methods = useFormContext();
  const {register, unregister, watch, control, setValue} = methods;

  useEffect(() => {
    unregister('attributes');
  },[])

  useEffect(() => {
    register('attributes.type');
    setValue('attributes.type', "RECORDID");
  },[attributes])


  return (
    <>

    </>
  );
}
export default FieldRecordid;