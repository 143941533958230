import { useState } from "react";
import Select, {SingleValue} from "react-select";
import type { FieldPrimitiveEditorProps } from './types';
import { JsonValue, Map, MapInput} from '../../../core/graphql/types';
import { SelectIcon } from "../../icons";
import MatrSelect from "../../ui/select/base";


export default function FieldSelector({field, card, updatePrimitive}: FieldPrimitiveEditorProps) {
  const options = field.attributes.options
  const [selection, setSelection] = useState((card[field.id] as unknown as JsonValue)?.valueAsJson! ? [(card[field.id] as unknown as JsonValue)?.valueAsJson!] : []);

  function handleOnClick(item: SingleValue<Map>) {
    if (!selection.some(current => current.id === item?.id)) {
      setSelection([item!]);
      handleChange(item);
    } else {
      setSelection([]);
      handleChange(null);
    }
  }

  function handleChange(item: Map | null) {
    updatePrimitive(
      { ...card, [field.id]: {...card[field.id], valueAsJson: item as MapInput}}, 
      ({...card[field.id], valueAsJson: item as MapInput} as unknown as JsonValue), 
      {valueAsJson: item as MapInput}
    )
  }



  return (
    <>
      <div className="relative flex flex-col space-y-1.5">
        <div className="flex items-center h-full">
          <div className="text-slate-400 pr-2"><SelectIcon /></div>
          <label className="text-slate-500 text-sm">{field.title}</label>
        </div>
        <MatrSelect 
          placeholder="Выберите..."
          isClearable={true}
          isSearchable={false}
          value={selection}
          getOptionLabel ={(option)=>option.name!}
          getOptionValue ={(option)=>option.id!}
          onChange={(e) => handleOnClick(e)}
          options={options}
        />
      </div>
    </>
  )
}