import { useEffect, useState } from "react";
import { Column } from "react-data-grid";
import { Row } from "../types";
import type { RenderCellProps, RenderEditCellProps } from 'react-data-grid';
import { Field,  Number, NumberValue } from '../../../../core/graphql/types';
import SummaryRows from "../rows/summary";
import { GroupCell } from "../rows/group";
import { HeaderRenderer } from "../rows/header";
import NumberFormat, { NumberFormatValues } from "react-number-format";
import { useBoard } from "../../../../core/providers/board";

interface BaseRenderCellProps<TRow, TSummaryRow> extends RenderCellProps<TRow, TSummaryRow> {
  field: Field;
}

interface BaseRenderEditCellProps<TRow, TSummaryRow> extends RenderEditCellProps<TRow, TSummaryRow> {
  field: Field;
}

// Column

export function recordidColumn({ field, canEditInline }: { field: Field; canEditInline: boolean }): Column<Row, any> {
  return {
    key: field.id,
    name: field.title,
    width: field.width,
    resizable: true,
    frozen: field.primary ? true : false,
    renderHeaderCell: (p) => { return <HeaderRenderer field={field} {...p}/> },
    renderCell: (p) => { return <RecordIdCell {...p} field={field}/> },
    renderGroupCell: (p) => { return <GroupCell {...p} field={field}/> },
    renderSummaryCell: (p) => { return <SummaryRows {...p} field={field}/> },
    editorOptions: {
      displayCellContent: !canEditInline
    }
  }
};

// Cell

export function RecordIdCell<Row, SummaryRow>({field, row, column}: BaseRenderCellProps<Row, SummaryRow>) {
const value = (row[column.key as keyof Row] as unknown as NumberValue)?.valueAsNumber!
  return (
    <div className="flex h-full p-2 text-sm items-start">{value ? value : ""}</div>
  );
}