import { memo, FC } from 'react';
import { Handle, Position, NodeProps } from 'reactflow';
import { useFlowContext } from '../../../../core/providers/flow';
import { TbSettings, TbTrash } from 'react-icons/tb';


const NodeLoopFor: FC<NodeProps> = ({ id, data, selected }) => {
  const {chooseNode, deleteNode} = useFlowContext()

  return (
    <div className='w-64'>
      <div className={`shadow-sm rounded bg-white ${selected ? "ring-2 ring-blue-400" : "ring-1 ring-slate-200"}`}>
        <div className='flex h-8 w-full px-2 border-b rounded-t items-center justify-between bg-indigo-800'>
          <div className='text-base font-medium  text-white truncate'>{data.name}</div>
          <div className='flex items-center gap-2'>
            {/* <button type='button' className='text-indigo-400 hover:text-indigo-200' onClick={() => deleteNode(id)}><TbTrash /></button> */}
            <button type='button' className='text-indigo-400 hover:text-indigo-200' onClick={() => chooseNode(id)}><TbSettings /></button>
          </div>
        </div>
        <div className='h-6 flex border-b px-2 justify-between items-center text-xs text-slate-600'>
          <span>Коллекция</span>
          <span>Запись</span>
          <Handle type="target" position={Position.Left} style={{top: 43}} className="h-4 !bg-blue-500" />
          <Handle type="source" position={Position.Right} id='SUCCESS' style={{top: 43}} className="h-4 !bg-blue-500" />
        </div>
        <div className='min-h-[100px] px-2 py-2 text-slate-800 text-sm'>
          {/* {description(data.options.triggerType)} */}
        </div>
      </div>
    </div>

  );
};

export default memo(NodeLoopFor);