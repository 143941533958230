/* eslint-disable @typescript-eslint/no-unused-vars */
import { useNavigate} from "react-router-dom";
import { useMutation } from "urql";
import { SignInDocument } from "../../core/graphql/types";
import { isEmailValid } from "../../utils";
import { saveToken, getToken, clearStorage } from "../../core/store/authStore";
import { useForm } from "react-hook-form";
import { useAuth } from "../../core/providers/auth";
import { useState } from "react";
import { buildError } from "../../errors";


export function LoginPage() {
  const { register, handleSubmit, watch, setError, formState: { errors } } = useForm(); 
  const [responsError, setResponseError] = useState<any>()
  const token = getToken();
  const auth = useAuth()
  let navigate = useNavigate();

  const [loginResult, login] = useMutation(SignInDocument)

  const onSubmit = handleSubmit(data => {
    clearStorage();
    login(data).then(result => {
      if(result.error) {
        setResponseError(buildError(result?.error?.graphQLErrors[0]?.originalError!))
        return
        // setError({name: result.error.toString()})
      }

      saveToken(result.data.signIn.token)
      auth.signin()
      navigate(0)
    })
  });

  return (
    token ? <></> :
    <section className="w-full bg-white">
      <div className="mx-auto max-w-7xl">
        <div className="flex flex-col lg:flex-row">
          <div className="w-full bg-white lg:w-6/12 xl:w-5/12 mx-auto">
            <div className="flex flex-col w-full h-full p-10 lg:p-16 xl:p-24">
              <h4 className="w-full text-3xl font-bold">С возвращением,</h4>
              <p className="text-m text-gray-500 leading-10">или, если вы впервые <a href="/signup" className="text-indigo-600 underline">зарегистрируйтесь</a></p>
              <div className="relative w-full mt-6">
                {responsError ? <div className="text-sm bg-red-100 text-red-600 px-2 py-1 rounded mb-4">{responsError}</div> : <></>}
                <form onSubmit={onSubmit} className="space-y-4">
                  <div className="relative">
                    <label className="font-medium text-gray-900 text-sm">Электронная почта</label>
                    <input {...register("email", { required: true, validate: value => isEmailValid(value) })} 
                      autoComplete="email"
                      className="block w-full px-3 mt-2 placeholder-gray-400 bg-gray-100 rounded-sm focus:outline-none focus:ring-4 focus:ring-blue-600 focus:ring-opacity-50 text py-2"
                    />
                  </div>
                  {errors.email && errors.email.type === "required" && <span className="text-xs py-1 text-red-900">Необходимое поле</span>}
                  {errors.email && errors.email.type === "validate" && <span className="text-xs py-1 text-red-900">Неправильно введен электронный адрес</span>}
                  <div className="relative">
                    <label className="font-medium text-gray-900 text-sm">Пароль</label>
                    <input {...register("password", { required: true })}
                      type="password"
                      autoComplete="current-password"
                      className="block w-full px-3 mt-2 placeholder-gray-400 bg-gray-100 rounded-sm focus:outline-none focus:ring-4 focus:ring-blue-600 focus:ring-opacity-50 text py-2"
                    />
                  </div>
                  {errors.password && <span className="text-xs py-1 text-red-900">Необходимое поле</span>}

                  <div className="flex pt-4">
                    <button type="submit"
                      className="inline-block w-full py-3 text-sm font-medium text-center text-white transition duration-200 bg-indigo-600 rounded-md hover:bg-indigo-700 ease"
                    >Войти</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}