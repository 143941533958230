import toast from "react-hot-toast"
import { useSendConfirmationEmailMutation } from "../../../../core/graphql/types"


export default function ConfirmationEmailNotification() {
  const [,sendEmail] = useSendConfirmationEmailMutation()

  function send() {
    sendEmail().then(res => {
      if(!res.data || res.error) {return}
      res.data.sendConfirmationEmail?.status === "ok" && toast("Письмо отправлено на почту.")
    })
  }


  return (
    <>
      <div className="flex w-full items-center justify-between h-10">
        <div className="flex flex-col flex-1 w-full truncate">
          <div className="flex font-medium pr-3">Подтвердите свой электронный адрес</div> 
          <span className="font-meduim">Не получили письмо? Отправьте повторно</span>
        </div>
        <div className="flex-none space-x-2">
          <button onClick={() => send()} className="px-3 py-1 rounded bg-indigo-600 text-white hover:bg-indigo-700 hover:text-white" type="button">Отправить</button>
        </div>
      </div>
    </>
  )
}