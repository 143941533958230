import { useState, useCallback, ChangeEvent, useEffect } from "react";
import { useForm, Controller, useWatch, useFieldArray, FormProvider } from "react-hook-form";
import { Disclosure } from "@headlessui/react";
import { TbChevronUp, TbX } from "react-icons/tb";
import { useFlowContext } from "../../../../core/providers/flow";
import { NodeField, MattrTypes, NodeTypesEnum } from "../../../../core/graphql/types";
import { Node as ReactFlowNode } from "reactflow";

import { debounce } from "debounce";
import useDeepCompareEffect from "use-deep-compare-effect";
import LinkNodeFieldDropDown from "./nodeField/LinkNodeFieldDropDown";


import NumberFormat, {FormatInputValueFunction} from "react-number-format";



export default function NodeLoopRepeatEdit() {
  const {node, nodes, edges, updateNodeFieldValue, updateNode} = useFlowContext()

  const methods = useForm({defaultValues: {repeat_fields: node.data?.fields?.filter((f: NodeField) => f.external === false)}});
  const {control, register, watch, getValues, setValue, formState} = methods;
  const { fields } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "repeat_fields", // unique name for your Field Array
  });
  const [fieldIndex, setFieldIndex] = useState<number | null>(null)
  const [sourceNodes, setSourceNodes] = useState<ReactFlowNode[]>([])

  useEffect(() => {
    setValue('repeat_fields', node.data?.fields?.filter((f: NodeField) => f.external === false))
  }, [node?.data?.fields])

  useEffect(() => {
    let parentNodeIds: string[] = edges.filter(edge => edge.target === node.id).map(edge => edge.source)
    setSourceNodes(nodes.filter(n => parentNodeIds.includes(n.id)))
  }, [edges, node])



  const debouncedSave = useCallback(
    debounce(() => {
      updateNodeFieldValue(getValues(`repeat_fields.${fieldIndex}`))
      // console.log(fieldIndex, getValues(`record_fields.${fieldIndex}`))
      setFieldIndex(null)
      // updateNodeField(getValues() as NodeTrigger)
    }, 1000),
    [fieldIndex]
  );

  const watchedData = useWatch({
    control: control,
    defaultValue: {repeat_fields: node.data?.fields?.filter((f: NodeField) => f.external === false)}
  });

  useDeepCompareEffect(() => {
    if (formState.isDirty) {
      debouncedSave()
    } else {
      debouncedSave.clear()
    }
  }, [watchedData]);


  function handleChangeValue(e: ChangeEvent<HTMLInputElement>, onChange: (...event: any[]) => void, index: number) {
    onChange(e)
    setFieldIndex(index)
  } 

  function handleChangeNodeFieldId(e: any, onChange: (...event: any[]) => void, index: number) {
    onChange(e)
    setFieldIndex(index)
  } 

  function handleResetNodeFieldId(value: any, onChange: { (...event: any[]): void; (arg0: any): void; }, index: number) {
    setValue(`repeat_fields.${index}.nodeFieldId`, null)
    onChange(value)
    setFieldIndex(index)
  }

  return (
    <div className="flex flex-col overflow-hidden h-full">
      <div className="h-full flex-1 overflow-scroll">
        <div className="py-4">
            <FormProvider {...methods}>
              {fields.map((field, index) => (
                <div key={field.id} className="flex flex-col">
                  <div className="flex flex-col space-y-2">
                    {/* <label className="matr-label">{getValues(`repeat_fields.${index}.name`)}</label> */}
                    <div className="flex w-full space-x-2 items-center">
                      <Controller 
                        name={`repeat_fields.${index}.value`}
                        control={control}
                        rules={{required: true}}
                        render={({ field: {value, onChange} }) => 
                          getValues(`repeat_fields.${index}.nodeFieldId`) ? 
                            <div  className="flex-1 bg-indigo-600 text-white text-sm px-2 h-7 rounded-sm flex justify-between items-center">{value} <TbX className="text-white" onClick={() => handleResetNodeFieldId(value, onChange, index)}/></div> 
                            : 
                          <NumberFormat 
                            value={value ? value : ""}
                            onChange={(e: any) => handleChangeValue(e.target.value, onChange, index)}
                            disabled={watch(`repeat_fields.${index}.nodeFieldId`) ? true : false}
                            placeholder="Количество повторений"
                            className="matr-form-narrow-input flex-1"
                          />
                        }
                      />
                      <div className="flex w-7 h-7 rounded-sm bg-slate-200 items-center justify-center">
                        <Controller 
                          name={`repeat_fields.${index}.nodeFieldId`}
                          control={control}
                          render={({ field: {value, onChange} }) => 
                            <LinkNodeFieldDropDown value={value} contextType={watch(`repeat_fields.${index}.contextType`)} onChange={(e: any, valueType: MattrTypes) => handleChangeNodeFieldId(e, onChange, index)} sourceNodes={sourceNodes}/>
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </FormProvider>
        </div>
      </div>
    </div>
)
}