import { Popover } from '@headlessui/react';
import { useAuth } from '../../../../core/providers/auth';
import { BsTable, BsCalendar3 } from 'react-icons/bs';
import { HiViewGrid, HiCog } from 'react-icons/hi';
import { useBoard } from '../../../../core/providers/board';
import { TbFolderCog, TbLock, TbSettings, TbSettings2, TbSettingsCog, TbSettingsFilled } from 'react-icons/tb';
import { MouseEvent } from 'react';
import { BoardView } from '../../../../core/graphql/types';
import { useSpaceStore } from '../../../../core/store/store';

interface IconProps {type: any}

const Icon = ({type}: IconProps) => {
  switch (type) {
    case 'GRID':
      return <BsTable className='text-blue-600' size={12}/>
    case 'CALENDAR':
      return <BsCalendar3 className='text-yellow-700' size={12}/>
    default:
      return <HiViewGrid size={16}/>
  }
}

interface BoardViewTabProps {
  openModal: (content: any) => void;
  view: any;
  onViewChange: (content: any) => void;
}

function BoardViewTab({openModal, view, onViewChange}: BoardViewTabProps) {
  const auth = useAuth();
  const {initId, initType} = useBoard()
  const init: string = `${initType}:${initId}`
  const store = useSpaceStore()
  const {currentView} = store.data[init];

  const handleClick = (id: string) => {
    if((currentView as BoardView)?.id === id) {return}
    onViewChange(id)
  }

  const handleEdit = (e: MouseEvent, id: string) => {
    e.stopPropagation()
    openModal({type: 'views', id: id})
  }

  return (
    <button className={`
      ${(currentView as BoardView)?.id === view.id ? 'bg-white text-indigo-600' : 'bg-slate-200 text-slate-800' } text-sm font-medium flex items-center px-2 space-x-1 rounded-sm focus:outline-none`}>
      {auth.currentUser.role.name === "Administrator" || view.editable === true ?
        <div className="flex justify-between items-center cursor-pointer h-full w-full space-x-1.5" onClick={() => handleClick(view.id)}>
          {view.primary ? <TbLock /> : <Icon type={view.type}/>}
          <span className='py-1'>{view.name}</span>
          {(currentView as BoardView).id === view.id ? 
            <div className='h-full px-1 border-l'>
              <TbSettingsFilled size={16} className='ml-2 text-slate-400 hover:text-indigo-600' onClick={(e) => handleEdit(e, view.id)}/>
            </div>
          : <></>}
        </div>
        :
        <div className="flex justify-between items-center cursor-pointer h-full w-full py-1 space-x-1.5">
          <Icon type={view.type}/>
          <span>{view.name}</span>
        </div>
      }
    </button>
  )
}

export default BoardViewTab;