import { DragDropContext } from 'react-beautiful-dnd';
import Column from "./Column";
import Task from "./Task";
// import EmptyBoard from "./EmptyBoard";
// import NoBoardsFound from './NoBoardsFound';

const Board = ({kanbanBoard}:any) => {
  const dragTask = (source: any, destination: any) => {
    // dropped outside a column
    if (!destination) {
      return;
    }

    // if the source and destination are the same, do nothing
    if (
      source.droppableId === destination.droppableId &&
      destination.index === source.index
    ) {
      return;
    }
    // If the card is moved within the same column and just needs an index change
    if (source.droppableId === destination.droppableId) {
      const column = kanbanBoard.columns.find(
        (column: any) => column.name === source.droppableId
      );
      const taskId = column.tasks[source.index];
      column.tasks.splice(source.index, 1);
      column.tasks.splice(destination.index, 0, taskId);
      // setBoards([...boards]);
    }
    //If the card has been moved to a different column
    else {
      const column = kanbanBoard.columns.find(
        (column: any) => column.name === source.droppableId
      );
      const taskId = column.tasks[source.index];
      const draggedTask = kanbanBoard.tasks.find((task: any) => task.id === taskId);
      draggedTask.status = destination.droppableId;
      column.tasks.splice(source.index, 1);
      const newColumn = kanbanBoard.columns.find(
        (column: any) => column.name === destination.droppableId
      );
      newColumn.tasks.splice(destination.index, 0, taskId);
      // setBoards([...boards]);
    }
  };

  function handleOnDragEnd(result: any) {
    const {source, destination} = result;
    dragTask(source, destination);
  }

  return (
    <main className='overflow-y-hidden scrollbar-thin scrollbar-thumb-slate-200 scrollbar-track-transparent flex-1 px-4 space-x-2 bg-white  flex'>
      <DragDropContext
          onDragEnd={handleOnDragEnd}
      >
      {
        kanbanBoard.columns.map((column: any, i: any) => (
          <Column data={column} key={i}>
            {
              column.tasks.map((taskId: any, j: any) => {
                const task = kanbanBoard.tasks.filter((task: any) => task.id === taskId)[0];
                return <Task data={task} index={j} key={taskId} />
              })
            }
          </Column>
        ))
      }
      </DragDropContext>
    </main>
  )
}
export default Board