import { TbPlayerPlay, TbPlayerStop, TbViewportWide, TbZoomInArea, TbZoomOutArea } from "react-icons/tb";
import Blocks from "./blocks";
import { 
  useReactFlow
} from "reactflow"
import { useFlowContext } from "../../../../core/providers/flow";

export default function FlowControl() {
  const {flow, startStopFlow} = useFlowContext()
  const {zoomIn, zoomOut, fitView} = useReactFlow()
  
  return (
    <div className="h-12 bg-slate-50 w-[360px] grid grid-cols-3 shadow rounded items-center divide-x divide-slate-200">
      <div className="flex col-span-1 w-full divide-x justify-center">
        <button className="h-8 w-10 flex items-center justify-center text-slate-500" onClick={() => zoomIn()}><TbZoomInArea size={20}/></button>
        <button className="h-8 w-10 flex items-center justify-center text-slate-500" onClick={() => zoomOut()}><TbZoomOutArea size={20}/></button>
        <button className="h-8 w-10 flex items-center justify-center text-slate-500" onClick={() => fitView()}><TbViewportWide size={20}/></button>
      </div>
      <div className="col-span-1 w-full justify-center px-2">
        <Blocks />
      </div>
      <div className="col-span-1 justify-center px-2">
        <button
          onClick={startStopFlow}
          className={`
            group flex w-full items-center rounded-md px-2 py-2 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75
            ${flow.active ? "bg-red-600 text-white hover:text-opacity-100" : "bg-green-600  text-white hover:text-opacity-100"}`}
        >
          {flow.active ? 
          <TbPlayerStop
            className={`h-5 w-5 mr-3 text-red-100 transition duration-150 ease-in-out group-hover:text-opacity-80`}
            aria-hidden="true"
          />
          :
          <TbPlayerPlay
            className={`h-5 w-5 mr-3 text-green-100 transition duration-150 ease-in-out group-hover:text-opacity-80`}
            aria-hidden="true"
          />
          }
          <span>{flow.active ? "Стоп" : "Старт"}</span>
        </button>
      </div>
    </div>
  )
} 