import { CreatedByIcon } from "../../icons";

export function FieldCreatedBy({ value, field }: {value: any, field: any}) {
  return (
    <div className="relative flex flex-row justify-between items-center pb-1.5 border-b">
      <div className="flex items-center h-full">
        <div className="text-slate-400 pr-2"><CreatedByIcon /></div>
        <label className="text-slate-500 text-sm">{field.title}</label>
      </div>
      <span className="px-2 text-sm bg-blue-200 text-blue-600 rounded-sm">{value ? value.name : <></>}</span> 
    </div>
  );
}