import { useEffect, useState } from 'react';
import { Controller, useFormContext, useFieldArray } from 'react-hook-form';
import { HiOutlineTrash, HiMenuAlt4 } from 'react-icons/hi';
import { TbAlertCircle, TbExchange, TbEye, TbEyeOff, TbSquare, TbSquareCheck } from 'react-icons/tb';
import { useFetcherContext } from '../../../../../../core/providers/fetcher';
import { isVariable, isVariableExist } from '../../../../../../core/Controllers/Service';

export default function MultipartFormData():JSX.Element {
  const {fetcher} = useFetcherContext()
  const methods = useFormContext();
  const {control, watch} = methods;
  const { fields, append, remove, move } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "body.values", // unique name for your Field Array
  });

  function handleChangeCheckbox(value: any, onChange: { (...event: any[]): void; (arg0: any): void; }) {
    onChange(!value)
  }

  function handleRemove(index: number) {
    remove(index)
  }
  
  return (
    <div className='flex flex-col w-full mt-2'>
      <div className='flex items-center justify-between pb-4'>
        <label className='text-sm text-slate-600 font-medium'>Заголовки запроса</label>
        <div
          className='flex py-1 px-2 text-sm bg-slate-200 text-slate-600 hover:bg-slate-300 cursor-pointer font-medium rounded-sm'
          onClick={() =>
            append({
              name: "",
              key: "",
              value: "",
              type: "STRING",
              disabled: false,
              dynamic: false
            })
          }
        >
          + Добавить
        </div>
      </div>
      {fields.map((field, index) => {
        return (
          <div key={field.id} className="flex items-center w-full mb-4">
            <section className={"flex gap-2 w-full"}>
              <Controller
                name={`body.values.${index}.disabled`}
                control={control}
                rules={{required: true}}
                render={({ field: { onChange, value, ref }}) => (
                  <div 
                    className={`flex-none w-7 justify-center items-center py-1.5 px-1.5 text-sm ${value ? "bg-slate-200 text-slate-600" : "bg-green-300 text-green-800" } rounded font-normal justify-between w-full cursor-default`} 
                    onClick={() => handleChangeCheckbox(value, onChange)}
                  >
                    {value ? <TbSquare className='h-4 w-4 text-slate-400'/> : <TbSquareCheck className='h-4 w-4 text-green-800'/>}
                  </div>
                )}
              />
              <Controller
                name={`body.values.${index}.key`}
                control={control}
                rules={{required: true, minLength: 1}}
                render={({ field: { onChange, value, ref }}) => (
                  <input 
                    value={value}
                    onChange={onChange}
                    placeholder="Ключ"
                    className={`matr-form-narrow-input`}
                  />
                )} 
              />
              <Controller
                name={`body.values.${index}.value`}
                control={control}
                rules={{required: true, minLength: 1}}
                render={({ field: { onChange, value, ref }}) => (
                  <input 
                    value={value}
                    onChange={onChange}
                    placeholder="Тестовое значение"
                    className={`matr-form-narrow-input ${isVariable(value) ? isVariableExist(value, fetcher?.variables!) ? "!text-blue-700" : "!text-red-400" : ""}`}
                  />
                )} 
              />
              <Controller
                name={`body.values.${index}.dynamic`}
                control={control}
                rules={{required: true}}
                render={({ field: { onChange, value, ref }}) => (
                  <div 
                    className={`flex-none w-7 items-center justify-center py-1.5 px-1.5 text-sm ${value ? "bg-green-300 text-green-800" : "bg-slate-200 text-slate-600"} rounded font-normal justify-between w-full cursor-default`} 
                    onClick={() => handleChangeCheckbox(value, onChange)}
                  >
                    {value ? <TbExchange className='h-4 w-4 text-green-800'/> : <TbExchange className='h-4 w-4 text-slate-400'/>}
                  </div>
                )}
              />
              <Controller
                name={`body.values.${index}.name`}
                control={control}
                rules={{required: true, minLength: 1}}
                render={({ field: { onChange, value, ref }}) => (
                  <input 
                    value={value}
                    onChange={onChange}
                    disabled={!watch(`body.values.${index}.dynamic`)}
                    placeholder="Название"
                    className={`matr-form-narrow-input`}
                  />
                )} 
              />
              <Controller
                name={`body.values.${index}.description`}
                control={control}
                rules={{required: true, minLength: 1}}
                render={({ field: { onChange, value, ref }}) => (
                  <input 
                    value={value}
                    disabled={!watch(`body.values.${index}.dynamic`)}
                    onChange={onChange}
                    placeholder="Описание"
                    className={`matr-form-narrow-input`}
                  />
                )} 
              />
            </section>
            <HiOutlineTrash size={16} className="ml-2 text-slate-600" onClick={() => handleRemove(index)}/>
          </div>
        );
      })}
    </div>
  )
}