import { useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { BiToggleLeft, BiToggleRight } from "react-icons/bi";
import Select from "react-select";
import { dateFormatList, timeFormatList } from '../../../../../core/enums';
import { Field } from "../../../../../core/graphql/types";
import { useBoard } from "../../../../../core/providers/board";
import { customStyles } from "../../../../../utils";
import { useSpaceStore } from "../../../../../core/store/store";

interface FieldUpdatedAtProps {
  attributes: any;
  field: Field;
}

function FieldUpdatedAt({attributes, field}: FieldUpdatedAtProps) {
  const {initId, initType} = useBoard();
  const init: string = `${initType}:${initId}`;
  const store = useSpaceStore()
  const {fields} = store.data[init];

  const methods = useFormContext();
  const {register, unregister, watch, control, setValue} = methods;
  const [options, setOptions] = useState<Field[]>([])

  useEffect(() => {
    let opt = fields.filter((f: Field) => f.type !== "LOOKUP" && f.id !== field.id)
    setOptions(opt as Field[])
  },[])

  useEffect(() => {
    unregister('attributes');
  },[])


  useEffect(() => {
    register('attributes.type');
    setValue('attributes.type', "UPDATED_AT");
    setValue('attributes.byField', attributes.byField  ? attributes.byField : false)
    setValue('attributes.updatedByFieldId', attributes.updatedByFieldId  ? attributes.updatedByFieldId : null)
    setValue('attributes.includeTime', attributes.includeTime  ? attributes.includeTime : false);
    setValue('attributes.useOnTimezone', attributes.useOnTimezone  ? attributes.useOnTimezone : true);
    setValue('attributes.dateFormat', attributes.dateFormat  ? attributes.dateFormat : "LOCAL");
    setValue('attributes.timeFormat', attributes.timeFormat  ? attributes.timeFormat : "FULL");
  },[attributes])

  return (
    <>
      <div className="relative inline-flex">
        {/* <label className="font-medium text-gray-600 text-sm flex items-center">
          <input 
            {...register("attributes.byField")}
            className="form-checkbox rounded-sm focus:outline-none focus:outline-offset-0 mr-2"
            type="checkbox" 
          />
          По конкртному полю
        </label> */}
        <label className="font-medium text-slate-800 text-sm flex items-center gap-2">
          <Controller
            name="attributes.byField"
            control={control}
            render={({ field: { onChange, value, ref }}) => (
              <div 
                className={`flex items-center  text-sm rounded-md font-normal justify-center cursor-default`} 
                onClick={() => onChange(!value)}
              >
                {value ? <BiToggleLeft className='h-6 w-6 text-green-600'/> : <BiToggleRight className='h-6 w-6 text-slate-400'/>}
              </div>
            )}
          />
          По конкртному полю
        </label>
      </div>
      {!watch('attributes.byField') ? "" : 
        <div className="relative">
          <label className="font-medium text-gray-600 text-sm">Выберите поле:</label>
          <Controller
            name="attributes.updatedByFieldId"
            control={control}
            render={({ field: { onChange, value, ref }}) => (
              <div className="mt-2">
                <Select
                  styles={customStyles}
                  classNamePrefix="matr_select_form"
                  placeholder="Выберите значение по умолчанию"
                  isSearchable={false}
                  getOptionLabel ={(option)=>option.title}
                  getOptionValue ={(option)=>option.id}
                  value={options.find((field: { id: string; }) => field.id === value)}
                  onChange={val => onChange(val?.id)}
                  options={options}
                />
              </div>
            )}
          />
        </div>
      }
      <div className="relative">
        <label className="font-medium text-gray-600 text-sm">Формат даты:</label>
        <Controller
          name="attributes.dateFormat"
          control={control}
          render={({ field: { onChange, value, ref }}) => (
            <div className="mt-2">
              <Select
                styles={customStyles}
                classNamePrefix="matr_select_form"
                placeholder="Выберите значение по умолчанию"
                isSearchable={false}
                defaultValue={dateFormatList.find(format => format.value === value)}
                value={dateFormatList.find(format => format.value === value)}
                onChange={val => onChange(val?.value)}
                options={dateFormatList}
              />
            </div>
          )}
        />
      </div>
      <div className="relative inline-flex">
        <label className="font-medium text-slate-800 text-sm flex items-center gap-2">
          <Controller
            name="attributes.includeTime"
            control={control}
            render={({ field: { onChange, value, ref }}) => (
              <div 
                className={`flex items-center  text-sm rounded-md font-normal justify-center cursor-default`} 
                onClick={() => onChange(!value)}
              >
                {value ? <BiToggleLeft className='h-6 w-6 text-green-600'/> : <BiToggleRight className='h-6 w-6 text-slate-400'/>}
              </div>
            )}
          />
          Включить показ времени
        </label>
        {/* <label className="font-medium text-gray-600 text-sm flex items-center">
          <input 
            {...register("attributes.includeTime")}
            className="form-checkbox rounded-sm focus:outline-none focus:outline-offset-0 mr-2"
            type="checkbox" 
          />
          Включить показ времени
        </label> */}
      </div>
      {!watch('attributes.includeTime') ? "" :
        <>
          <div className="relative">
            <label className="font-medium text-gray-600 text-sm">Формат времени:</label>
            <Controller
              name="attributes.timeFormat"
              control={control}
              render={({ field: { onChange, value, ref }}) => (
                <div className="mt-2">
                  <Select
                    styles={customStyles}
                    classNamePrefix="matr_select_form"
                    placeholder="Выберите значение по умолчанию"
                    isSearchable={false}
                    defaultValue={timeFormatList.find(format => format.value === value)}
                    value={timeFormatList.find(format => format.value === value)}
                    onChange={val => onChange(val?.value)}
                    options={timeFormatList}
                  />
                </div>
              )}
            />
          </div>
          {/* <div className="relative inline-flex">
            <label className="font-medium text-gray-600 text-sm flex items-center">
              <input 
                {...register("attributes.useOnTimezone")}
                className="form-checkbox rounded-sm focus:outline-none focus:outline-offset-0 mr-2"
                type="checkbox" 
              />
              Использовать единый часовой пояс для всех участников
            </label>
          </div> */}
        </>  
        }
    </>
  );
}
export default FieldUpdatedAt;