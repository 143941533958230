import React, { useEffect } from "react";
import _ from "lodash";
import { Panel, PanelInList, usePanelChangesSubscription, Block } from "../../graphql/types";
import { useSpaceStore } from "../../store/store";
import { usePanel } from "../panel";
import { useNavigate } from "react-router-dom";

interface PanelSubscriptionContextType {}

let PanelSubscriptionContext = React.createContext<PanelSubscriptionContextType>(null!);

export function PanelSubscriptionProvider({ panelId, children }: { panelId: string, children: React.ReactNode }) {
    const navigate = useNavigate();
    const {editorMode, pageBlock, setBlock, setPageBlock} = usePanel()
    const store = useSpaceStore()
    const {panel, currentPage} = store
    const {panelsSetPanel, panelsSetPage} = store.panelActions
    const [changes] = usePanelChangesSubscription({variables: {panelId: panelId}});


  useEffect(() => {
    if(!changes.data?.panelChanges) {return}
    const change = changes.data?.panelChanges

    let pages = panel?.pages
    let pageIndex = panel?.pages!.findIndex((p: any) => p.id === change.page?.id)

    switch (change.action) {
      case "createPage":
        panelsSetPanel({...panel, pages: _.orderBy([...pages!, change?.page!], ['position'], "desc")} as Panel)
        panelsSetPage(change.page!)
        break;
      case "updatePage":
        if(panel?.id === change.id) {
          panelsSetPanel({...panel, pages: _.orderBy([...pages!.slice(0, pageIndex!), change.page, ...pages!.slice(pageIndex! + 1)], ['position'], "desc")} as Panel)
          store.baseActions.actionPanelsUpdate({...panel, pages: _.orderBy([...pages!.slice(0, pageIndex!), change.page, ...pages!.slice(pageIndex! + 1)], ['position'], "desc")} as PanelInList)
        }
        if(currentPage?.id === change.page?.id) {
          panelsSetPage(change.page!)
        }
        break;
      case "deletePage":
        let newPageId = pages?.filter(p => p?.id !== change.actionId)[0]?.id!

        if(panel?.id === change.id) {
          panelsSetPanel({...panel, pages: pages?.filter(p => p?.id !== change.actionId)} as Panel)
          store.baseActions.actionPanelsUpdate({...panel, pages: pages?.filter(p => p?.id !== change.actionId)} as PanelInList)
        }
        if(currentPage?.id === change.actionId) {
          panelsSetPage(null)
          navigate(`/${newPageId!}`)
        }
        break;
      case "updateBlocksPosition":
        if(panel?.id === change.id) {
          panelsSetPanel({...panel, pages: _.orderBy([...pages!.slice(0, pageIndex!), change.page, ...pages!.slice(pageIndex! + 1)], ['position'], "desc")} as Panel)
          store.baseActions.actionPanelsUpdate({...panel, pages: _.orderBy([...pages!.slice(0, pageIndex!), change.page, ...pages!.slice(pageIndex! + 1)], ['position'], "desc")} as PanelInList)
        }
        if(currentPage?.id === change.page?.id) {
          panelsSetPage(change.page!)
        }
        break;
      case "createBlock":
        if(panel?.id === change.id) {
          panelsSetPanel({...panel, pages: _.orderBy([...pages!.slice(0, pageIndex!), change.page, ...pages!.slice(pageIndex! + 1)], ['position'], "desc")} as Panel)
          store.baseActions.actionPanelsUpdate({...panel, pages: _.orderBy([...pages!.slice(0, pageIndex!), change.page, ...pages!.slice(pageIndex! + 1)], ['position'], "desc")} as PanelInList)
        }
        if(currentPage?.id === change.page?.id) {
          panelsSetPage(change.page!)
        }
        break;
      case "updateBlock":
        if(panel?.id === change.id) {
          panelsSetPanel({...panel, pages: _.orderBy([...pages!.slice(0, pageIndex!), change.page, ...pages!.slice(pageIndex! + 1)], ['position'], "desc")} as Panel)
          store.baseActions.actionPanelsUpdate({...panel, pages: _.orderBy([...pages!.slice(0, pageIndex!), change.page, ...pages!.slice(pageIndex! + 1)], ['position'], "desc")} as PanelInList)
        }
        if(currentPage?.id === change.page?.id) {
          panelsSetPage(change.page!)
          if(editorMode) {
            setBlock(change.page?.blocks?.find(b => b?.id === change.actionId!))
            setPageBlock(change.page?.blocks?.find(b => b?.id === pageBlock?.id))
          }
        }
        break;
      case "deleteBlock":
        if(panel?.id === change.id) {
          panelsSetPanel({...panel, pages:_.orderBy([...pages!.slice(0, pageIndex), change.page, ...pages!.slice(pageIndex! + 1)], ['positions.x'], "asc")} as Panel)
          store.baseActions.actionPanelsUpdate({...panel, pages:_.orderBy([...pages!.slice(0, pageIndex), change.page, ...pages!.slice(pageIndex! + 1)], ['positions.x'], "asc")} as PanelInList)
        }
        if(currentPage?.id === change.page?.id) {
          panelsSetPage(change.page!)
          if(editorMode) {
            setBlock(change.page?.blocks?.find(b => b?.id === change.actionId!))
            setPageBlock(change.page?.blocks?.find(b => b?.id === pageBlock?.id))
          }
        }
        break;
      default:
        break;
    }
  }, [changes])

  let value = {};

  return <PanelSubscriptionContext.Provider value={value}>{children}</PanelSubscriptionContext.Provider>

}