import { useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { BiToggleLeft, BiToggleRight } from "react-icons/bi";
import { SingleValue } from "react-select";
import Cron from '../../../../components/cron_expression';
import MatrFormDatePicker from "../../../../components/ui/datepicker/form";
import MatrNarrowSelect from "../../../../components/ui/select/narrow";
import { triggers } from "../../../../core/enums";
import { Board, BoardView, Field, Filter, NodeTriggerTypesEnum, NodeTypesEnum } from "../../../../core/graphql/types";
import { useSpace } from "../../../../core/providers/space";
import BlockSettingsFiltersDropdown from "./filters";



export default function NodeTriggerEdit() {
  const {boards} = useSpace()
  const {control, getValues, formState,  watch, setValue} = useFormContext()
  const [fields, setFields] = useState<Field[]>([])
  const [views, setViews] = useState<BoardView[]>([])


  useEffect(() => {
    if(!getValues('boardId')) {return}
    setFields(boards.find(b => b.id === getValues('boardId'))?.fields! as Field[])
  },[boards, watch('boardId')])


  function handleChangeTriggerType(e: SingleValue<{ label: string; value: NodeTriggerTypesEnum; }>, onChange: (...event: any[]) => void) {
    setValue('type', NodeTypesEnum.Trigger)
    setValue('triggerType', e?.value)
    setValue('boardId', null)
    setValue('viewId', null)
    setValue('filters', [])
    setValue('schedule', null)
  }


  function handleChangeBoard(e: SingleValue<Board>, onChange: (...event: any[]) => void) {
    setFields(boards.find(b => b.id === e?.id)?.fields! as Field[])
    if(watch('triggerType')! === "CARD_IN_VIEW") {
      setViews(boards.find(b => b.id === e?.id)?.views! as BoardView[])
    } else {
      setViews([])
    }
    onChange(e?.id)
  }

  function handleChangeView(e: SingleValue<BoardView>, onChange: { (...event: any[]): void; (arg0: any): void; }) {
    onChange(e?.id)
  }

  function handleChangeFilters(filters: Filter[], onChange: { (...event: any[]): void; (arg0: any): void; }) {
    onChange(filters)
  }

  const handleChangeCron = (e: string, onChange: { (...event: any[]): void; (arg0: any): void; }) => {
    onChange(e)
  }

  const handleChangeStartAt = (e: Date | null, onChange: { (...event: any[]): void; (arg0: any): void; }) => {
    onChange(new Date(e!).toISOString())
  }

  function handleChangeCheckbox(value: any, onChange: { (...event: any[]): void; (arg0: any): void; }) {
    onChange(!value)
  }

  return (
  <div className="flex flex-col h-full">
      <div className="py-4 space-y-2">
        <div className="relative">
          <label className="font-medium text-gray-600 text-sm">Выберите событие:</label>
          <Controller
            name="triggerType"
            control={control}
            // rules={{required: true}}
            render={({ field: { onChange, value }}) => (
              <div className="mt-1.5">
                <MatrNarrowSelect
                  value={triggers.find(t => t.value === value)}
                  onChange={(e) => handleChangeTriggerType(e, onChange)}
                  options={triggers}
                />
              </div>
            )}
          />
          {formState.errors.triggerType && <span className="text-red-600 text-xs font-normal">"Тип события" обязательное поле.</span>}
        </div>
        {["CARD_CREATED", "CARD_CREATED_WITH_VALUES", "CARD_UPDATED", "CARD_IN_CONDITIONS", "CARD_IN_VIEW"].includes(watch('triggerType')!) && 
          <>       
            <div className="relative">
              <label className="font-medium text-gray-600 text-sm">Выберите таблицу:</label>
              <Controller
                name="boardId"
                control={control}
                // rules={{required: true}}
                render={({ field: { onChange, value }}) => (
                  <div className="mt-1.5">
                    <MatrNarrowSelect
                      getOptionLabel={(option) => option?.name!}
                      getOptionValue ={(option)=> option?.id!}
                      value={value && value.length > 0 ? boards?.find(r => value.includes(r.id)) : []}
                      onChange={(e) => handleChangeBoard(e, onChange)}
                      options={boards}
                    />
                  </div>
                )}
              />
              {formState.errors.boardId && <span className="text-red-600 text-xs font-normal">"Доска" обязательное поле.</span>}
            </div>
            {"CARD_IN_VIEW" === watch('triggerType')! && 
            <div className="relative">
              <label className="font-medium text-gray-600 text-sm">В виде:</label>
              <Controller
                name="viewId"
                control={control}
                // rules={{required: true}}
                render={({ field: { onChange, value }}) => (
                  <div className="mt-1.5">
                    <MatrNarrowSelect
                      getOptionLabel={(option) => option?.name!}
                      getOptionValue ={(option)=> option?.id!}
                      value={value && value.length > 0 ? views?.find(r => value.includes(r.id)) : []}
                      onChange={(e) => handleChangeView(e, onChange)}
                      options={views}
                    />
                  </div>
                )}
              />
              {formState.errors.boardId && <span className="text-red-600 text-xs font-normal">"Доска" обязательное поле.</span>}
            </div>}
          </>
        }
        {watch('boardId') && ["CARD_CREATED", "CARD_CREATED_WITH_VALUES", "CARD_UPDATED", "CARD_IN_CONDITIONS", "CARD_IN_VIEW"].includes(watch('triggerType')!) && 
          <div className="relative">
            <Controller
              name="filters"
              control={control}
              render={({ field: { onChange, value, ref }}) => (
                <BlockSettingsFiltersDropdown fields={fields!} filters={value ? value : [] } onChangeFilters={(filters: Filter[]) => handleChangeFilters(filters, onChange)}/>
              )}
            />
          </div>
        }
        {watch('triggerType')! === NodeTriggerTypesEnum.BySchedule && 
          <>
            <div className="relative">
              <Controller
                name="schedule.expression"
                control={control}
                render={({ field: { onChange, value, ref }}) => (
                  <Cron value={value!} setValue={(e: string) => handleChangeCron(e, onChange)} />
                )}
              />
            </div>
            <div className="relative">
              <label className="font-medium text-gray-600 text-sm">Начать в:</label>
              <Controller
                name="schedule.startAt"
                control={control}
                // rules={{required: false}}
                render={({ field: { onChange, value }}) => (
                  <div className="mt-1.5">
                    <MatrFormDatePicker 
                      selected = {value ? new Date(value) : new Date()}
                      closeOnScroll={true}
                      popperPlacement="bottom-start"
                      showTimeInput = {true}
                      onChange = {(e) => handleChangeStartAt(e, onChange)}
                    />
                  </div>
                )}
              />
            </div>
            <div className="relaitve">
              <div className="flex justify-between items-center">
                <label className="matr-label w-full">Запускать один раз:</label>
                <Controller
                  name="schedule.oneTime"
                  control={control}
                  render={({ field: { onChange, value, ref }}) => (
                    <div 
                      className={`flex items-center  rounded-md font-normal  cursor-default`} 
                      onClick={() => handleChangeCheckbox(value, onChange)}
                    >
                      {value! ? <BiToggleRight className='h-6 w-6 text-blue-600'/> : <BiToggleLeft className='h-6 w-6 text-slate-400'/>}
                    </div>
                  )}
                />
              </div>
            </div>
          </>
        }
      </div>
  </div>
  )
}