import React, {Fragment, useRef, useState} from "react";
import { Transition, Dialog, Listbox } from "@headlessui/react";
import { useForm, Controller } from "react-hook-form";
import { HiSelector } from 'react-icons/hi';
import { requestMethods } from "../../../../core/enums";
import { useFetcherContext } from "../../../../core/providers/fetcher";
import { TbSquaresFilled, TbX } from "react-icons/tb";
import { MatrMediumDialog } from "../../../../components/ui/portal";


const CreateModal = ({open, close}: {open: boolean; close: any;}) => {
  const { fetcher, addRequest } = useFetcherContext()
  const { register, handleSubmit, control, formState: { errors } } = useForm({defaultValues: {name: "", method: requestMethods.find(m => m.id === "GET")}})
  let refDiv = useRef(null)


  const onSubmit = handleSubmit(data => {
    addRequest({name: data.name, method: data.method?.id})
    close()
  })

  return (
      <MatrMediumDialog open={open} onClose={close}>
        <>
          <div className="flex items-center justify-between">
              <div className="flex items-center space-x-2">
              <div className="py-1 px-2 bg-slate-100 flex space-x-2 rounded">
                  <TbSquaresFilled size={20} className="text-indigo-600"/>
                  <div className="flex font-medium text-sm">{fetcher?.title}</div>
              </div>
              <div className="flex font-normal text-sm">Добавление запроса</div>
              </div>
              <button onClick={() => close()}><TbX size={18}/></button>
          </div>
          <form onSubmit={onSubmit}>
            <div className="flex flex-col mt-4">
              <input 
                  {...register("name", {required: true})} 
                  className="text-xl font-medium text-slate-800 placeholder:text-slate-300 focus:outline-none w-full" 
                  placeholder="Название запроса"
              />
              <Controller
                name="method"
                control={control}
                render={({ field: { onChange, value, ref }}) => (
                  <div className="flex space-x-2 mt-4">
                    {requestMethods.map((m, mIdx) => (
                      <div
                        key={mIdx}
                        className={
                          `relative cursor-default text-sm rounded select-none py-1 px-3 hover:bg-indigo-100 ${
                            value?.id === m.id ? 'bg-indigo-200 text-indigo-900' : 'text-slate-600 bg-slate-200'
                          }`
                        }
                      >
                        <span className={`block truncate`}>
                          {m.name}
                        </span>
                      </div>
                    ))}
                  </div>
                  // <Listbox value={value} onChange={onChange}>
                  //   <div className="relative w-max">
                  //     <Listbox.Button className="relative h-8 min-w-max max-w-max w-max cursor-default border-l pl-3 text-left focus:outline-none">
                  //       <span className="block truncate min-w-max w-20 text-sm">{value?.name!}</span>
                  //       <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  //         <HiSelector className="h-5 w-5 text-gray-400" aria-hidden="true"/>
                  //       </span>
                  //     </Listbox.Button>
                  //     <Transition
                  //       as={Fragment}
                  //       leave="transition ease-in duration-100"
                  //       leaveFrom="opacity-100"
                  //       leaveTo="opacity-0"
                  //     >
                  //       <Listbox.Options className="absolute z-10 max-h-60 w-full overflow-auto bg-white focus:outline-none text-sm border ">
                  //         {requestMethods.map((m, mIdx) => (
                  //           <Listbox.Option
                  //             key={mIdx}
                  //             className={({ active }) =>
                  //               `relative cursor-default select-none py-2 pl-3 pr-4 hover:bg-indigo-100 ${
                  //                 active ? 'bg-indigo-200 text-indigo-900' : 'text-slate-900'
                  //               }`
                  //             }
                  //             value={m}
                  //           >
                  //             {({ selected }) => (
                  //               <>
                  //                 <span
                  //                   className={`block truncate ${
                  //                     selected ? 'font-medium' : 'font-normal'
                  //                   }`}
                  //                 >
                  //                   {m.name}
                  //                 </span>
                  //               </>
                  //             )}
                  //           </Listbox.Option>
                  //         ))}
                  //       </Listbox.Options>
                  //     </Transition>
                  //   </div>
                  // </Listbox>
                )}
              />
            </div>
            <div className="flex justify-between w-full pt-6">
                <button
                type="button"
                className="inline-flex justify-end px-3 py-1.5 text-sm font-medium text-indigo-700 bg-indigo-100 border border-transparent rounded-md hover:bg-indigo-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-indigo-500"
                onClick={close}
                >Отменить</button>
                <button
                type="submit"
                className="inline-flex justify-center px-3 py-1.5 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md hover:bg-indigo-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-indigo-500"
                >Добавить</button>
            </div>
          </form>
        </>
      </MatrMediumDialog>
  )
  
};

export default CreateModal;