import { useEffect, useState } from "react";
import { Controller, UseFormReturn } from 'react-hook-form';
import { BiToggleLeft, BiToggleRight } from "react-icons/bi";
import { HiPlus, HiXCircle } from "react-icons/hi";
import NumberFormat, { NumberFormatValues } from "react-number-format";
import { MultiValue, SingleValue } from "react-select";
import { dateFormats, timeFormats } from "../../../../core/enums";
import { Checkbox, Collaborator, Currency, Datetime, Decimal, Duration, Email, FieldType, MattrTypes, Multiselect, Number, Percent, Phone, Relation, Select, SpaceUser, String, Textarea, Url, useSpaceUsersForSelectQuery } from '../../../../core/graphql/types';
import BaseFormRelationList from "../../../Cards/Lists/BaseFormRelationsList";
import PlainTextEditor from "../../../ui/TextEditors/PlainTextEditor";
import RichTextEditor from "../../../ui/TextEditors/RichTextEditor";
import MatrFormDatePicker from "../../../ui/datepicker/form";
import MatrNarrowDatePicker from "../../../ui/datepicker/narrow";
import { MatrPopover } from "../../../ui/portal";
import MatrSelect from "../../../ui/select/base";
import MatrNarrowSelect from "../../../ui/select/narrow";
import { checkJson, isObject } from "../../../../core/Controllers/Base";
import { isObjectLike } from "lodash";
import { useSpace } from "../../../../core/providers/space";
import BlockEditor from "../../../ui/TextEditors/BlockEditor";


interface FormFieldInputProps {
  methods: UseFormReturn<any>,
  autoFocus: boolean,
  name: string,
  index?: number | null | undefined, 
  className?: string,
  narrow: boolean,
  placeholder?: string | undefined,
  disabled: boolean,
  required: boolean,
  handleChange: (value: any, onChange: () => void, index?: number | null | undefined) => void;
}
  
// 1
interface FormFieldStringInputProps extends FormFieldInputProps {
  value: string | null
  attributes?: String,
}
// 2
interface FormFieldUrlInputProps extends FormFieldInputProps {
  value: string | null
  attributes?: Url,
}
// 3
interface FormFieldEmailInputProps extends FormFieldInputProps {
  value: string | null
  attributes?: Email,
}
// 4
interface FormFieldNumberInputProps extends FormFieldInputProps {
  value: string | null
  attributes?: Number,
}
// 5
interface FormFieldPhoneInputProps extends FormFieldInputProps {
  value: string | null
  attributes?: Phone,
}
// 6
interface FormFieldDurationInputProps extends FormFieldInputProps {
  value: string | null
  attributes?: Duration,
}
// 7
interface FormFieldDecimalInputProps extends FormFieldInputProps {
  value: string | null
  attributes?: Decimal,
}
// 8
interface FormFieldPercentInputProps extends FormFieldInputProps {
  value: string | null
  attributes?: Percent,
}
// 9
interface FormFieldCurrencyInputProps extends FormFieldInputProps {
  value: string | null
  attributes?: Currency,
}
// 10
interface FormFieldCheckboxInputProps extends FormFieldInputProps {
  value: string | null
  attributes?: Checkbox,
}
// 11
interface FormFieldSelectInputProps extends FormFieldInputProps {
  value: any,
  attributes?: Select,
}
// 12
interface FormFieldMultiselectInputProps extends FormFieldInputProps {
  value: any,
  attributes?: Multiselect,
}
// 13
interface FormFieldCollaboratorsInputProps extends FormFieldInputProps {
  value: any,
  attributes?: Collaborator
}
// 14
interface FormFieldDatetimeInputProps extends FormFieldInputProps {
  value: string | null
  attributes?: Datetime,
}
// 15
interface FormFieldRelationInputProps extends FormFieldInputProps {
  value: any,
  boardId: string,
  fieldId: string,
  attributes?: Relation,
}
// 15
interface FormFieldTextareaInputProps extends FormFieldInputProps {
  value: string | null,
  attributes?: Textarea,
}


export function FormStringFieldInput(props: FormFieldStringInputProps) {
  const {name, index, disabled, required, handleChange, narrow, className, placeholder} = props
  const {control} = props.methods;

  function inputChange(e: any, onChange: () => void) {
    handleChange(e?.target?.value ? e?.target?.value : null, onChange, index)
  }

  return (
    <Controller 
      name={name}
      control={control}
      rules={{required: required}}
      render={({ field: {value, onChange} }) => (
        <input 
          value={value ? value : ""}
          onChange={(e) => inputChange(e, onChange)}
          disabled={disabled}
          placeholder={placeholder}
          className={`${narrow ? "matr-form-narrow-input" : "matr-form-input"} ${className ? className : ""}`}
        />
      )}
    />

  )
}

export function FormUrlFieldInput(props: FormFieldUrlInputProps) {
  const {name, index, disabled, required, handleChange, narrow, className, placeholder} = props
  const {control} = props.methods;

  function inputChange(e: any, onChange: () => void) {
    handleChange(e?.target?.value ? e?.target?.value : null, onChange, index)
  }

  return (
    <Controller 
      name={name}
      control={control}
      rules={{required: required}}
      render={({ field: {value, onChange} }) => (
        <input 
          value={value ? value : ""}
          onChange={(e) => inputChange(e, onChange)}
          disabled={disabled}
          placeholder={placeholder}
          className={`${narrow ? "matr-form-narrow-input" : "matr-form-input"} ${className ? className : ""}`}
        />
      )}
    />

  )
}

export function FormEmailFieldInput(props: FormFieldEmailInputProps) {
  const {name, index, disabled, required, handleChange, narrow, className, placeholder} = props
  const {control} = props.methods;

  function inputChange(e: any, onChange: () => void) {
    handleChange(e?.target?.value ? e?.target?.value : null, onChange, index)
  }

  return (
    <Controller 
      name={name}
      control={control}
      rules={{required: required}}
      render={({ field: {value, onChange} }) => (
        <input 
          value={value ? value : ""}
          onChange={(e) => inputChange(e, onChange)}
          disabled={disabled}
          placeholder={placeholder}
          className={`${narrow ? "matr-form-narrow-input" : "matr-form-input"} ${className ? className : ""}`}
        />
      )}
    />

  )
}

export function FormNumberFieldInput(props: FormFieldNumberInputProps) {
  const {name, autoFocus, index, disabled, required, handleChange, narrow, className, attributes, placeholder} = props
  const {control} = props.methods;

  function inputChange(e: NumberFormatValues, onChange: () => void) {
    handleChange(e?.formattedValue ? e?.formattedValue : null, onChange, index)
  }

  return (
    <Controller 
      name={name}
      control={control}
      rules={{required: required}}
      render={({ field: {value, onChange} }) => (
        <NumberFormat
          autoFocus={autoFocus}
          allowNegative={attributes?.negative ? true : false || true}
          decimalScale={0}
          className={`${narrow ? "matr-form-narrow-input" : "matr-form-input"} ${className ? className : ""}`}
          disabled={disabled}
          displayType="input"
          placeholder={placeholder}
          value={value}
          onValueChange={(e) => inputChange(e, onChange)}
        />
      )}
    />

  )
}

export function FormPhoneFieldInput(props: FormFieldPhoneInputProps) {
  const {name, autoFocus, index, disabled, required, handleChange, narrow, className, attributes, placeholder} = props
  const {control} = props.methods;

  function inputChange(e: NumberFormatValues, onChange: () => void) {
    handleChange(e?.floatValue ? e?.floatValue : null, onChange, index)
  }

  return (
    <Controller 
      name={name}
      control={control}
      rules={{required: required}}
      render={({ field: {value, onChange} }) => (
        <NumberFormat
          autoFocus={autoFocus}
          type="tel"
          format="+# (###) ###-####"
          displayType="input"
          className={`${narrow ? "matr-form-narrow-input" : "matr-form-input"} ${className ? className : ""}`}
          disabled={disabled}
          placeholder={placeholder}
          value={value}
          onValueChange={(e) => inputChange(e, onChange)}
        />
      )}
    />

  )
}

export function FormDurationFieldInput(props: FormFieldDurationInputProps) {
  const {name, autoFocus, index, disabled, required, handleChange, narrow, className, attributes, placeholder} = props
  const {control} = props.methods;

  function inputChange(e: NumberFormatValues, onChange: () => void) {
    handleChange(e?.formattedValue ? e?.formattedValue : null, onChange, index)
  }

  return (
    <Controller 
      name={name}
      control={control}
      rules={{required: required}}
      render={({ field: {value, onChange} }) => (
        <NumberFormat
          autoFocus={autoFocus}
          allowNegative={false}
          decimalScale={0}
          className={`${narrow ? "matr-form-narrow-input" : "matr-form-input"} ${className ? className : ""}`}
          disabled={disabled}
          displayType="input"
          placeholder={placeholder}
          value={value}
          onValueChange={(e) => inputChange(e, onChange)}
        />
      )}
    />

  )
}

export function FormDecimalFieldInput(props: FormFieldDecimalInputProps) {
  const {name, autoFocus, index, disabled, required, handleChange, narrow, className, attributes, placeholder} = props
  const {control} = props.methods;

  function inputChange(e: NumberFormatValues, onChange: () => void) {
    handleChange(e?.formattedValue ? e?.formattedValue : null, onChange, index)
  }

  return (
    <Controller 
      name={name}
      control={control}
      rules={{required: required}}
      render={({ field: {value, onChange} }) => (
        <NumberFormat
          autoFocus={autoFocus}
          allowNegative={attributes?.negative ? true : false || true}
          decimalScale={attributes?.percision || 2}
          className={`${narrow ? "matr-form-narrow-input" : "matr-form-input"} ${className ? className : ""}`}
          disabled={disabled}
          displayType="input"
          placeholder={placeholder}
          value={value}
          onValueChange={(e) => inputChange(e, onChange)}
        />
      )}
    />

  )
}

export function FormPercentFieldInput(props: FormFieldPercentInputProps) {
  const {name, autoFocus, index, disabled, required, handleChange, narrow, className, attributes, placeholder} = props
  const {control} = props.methods;

  function inputChange(e: NumberFormatValues, onChange: { (...event: any[]): void; (arg0: number): void; }) {
    onChange(e?.floatValue ? e?.floatValue / 100 : null)
  }

  return (
    <Controller 
      name={name}
      control={control}
      rules={{required: required}}
      render={({ field: {value, onChange} }) => (
        <NumberFormat
          autoFocus={autoFocus}
          allowNegative={attributes?.negative ? true : false || true}
          decimalScale={attributes?.percision || 2}
          suffix={value ? "%" : ""}
          className={`${narrow ? "matr-form-narrow-input" : "matr-form-input"} ${className ? className : ""}`}
          disabled={disabled}
          displayType="input"
          placeholder={placeholder}
          value={value ? value * 100 : ""}
          onValueChange={(e) => inputChange(e, onChange)}
        />
      )}
    />

  )
}

export function FormCurrencyFieldInput(props: FormFieldCurrencyInputProps) {
  const {name, autoFocus, index, disabled, required, handleChange, narrow, className, attributes, placeholder} = props
  const {control} = props.methods;

  function inputChange(e: any, onChange: () => void) {
    handleChange(e?.formattedValue ? e?.formattedValue : null, onChange, index)
  }

  return (
    <Controller 
      name={name}
      control={control}
      rules={{required: required}}
      render={({ field: {value, onChange} }) => (
        <NumberFormat
          autoFocus={autoFocus}
          allowNegative={attributes?.negative ? true : false || true}
          decimalScale={attributes?.percision || 2}
          prefix={attributes?.currency ? attributes?.currency + " " : ""}
          className={`${narrow ? "matr-form-narrow-input" : "matr-form-input"} ${className ? className : ""}`}
          disabled={disabled}
          displayType="input"
          placeholder={placeholder}
          value={value}
          onValueChange={(e) => inputChange(e, onChange)}
        />
      )}
    />

  )
}

export function FormCheckboxFieldInput(props: FormFieldCheckboxInputProps) {
  const {name, autoFocus, index, disabled, required, handleChange, narrow, className, attributes, placeholder} = props
  const {control} = props.methods;

  function inputChange(e: string, onChange: () => void) {
    let value = e ? null : "true"
    handleChange(value, onChange, index)
  }

  return (
    <Controller 
      name={name}
      control={control}
      rules={{required: required}}
      render={({ field: {value, onChange} }) => (
        narrow ?
        <div 
          className={`flex items-center py-1 px-2 text-sm ${value ? "bg-green-300 text-green-800" : "bg-slate-200 text-slate-600"} rounded-sm font-normal justify-between w-full cursor-default`} 
          onClick={() => inputChange(value, onChange)}
        >
          {value ? <BiToggleRight size={22} className='text-green-800'/> : <BiToggleLeft size={22} className='text-slate-400'/>}
          {value ? "Выбрано" : "Не выбрано"}
        </div>
        :
        <div 
          className={`flex items-center py-1 px-4 text-sm ${value ? "bg-green-300 text-green-800" : "bg-slate-200 text-slate-600"} rounded-sm font-normal justify-between w-full cursor-default`} 
          onClick={() => inputChange(value, onChange)}
        >
          {value ? <BiToggleRight size={22} className='text-green-800'/> : <BiToggleLeft size={22} className='text-slate-400'/>}
          {value ? "Выбрано" : "Не выбрано"}
        </div>
      )}
    />

  )
}

export function FormSelectFieldInput(props: FormFieldSelectInputProps) {
  const {name, autoFocus, index, disabled, required, handleChange, narrow, className, attributes, placeholder} = props
  const {control} = props.methods;

  function inputChange(value: any, onChange: () => void) {
    handleChange(value ? value : null, onChange, index)
  }

  return (
    <Controller 
      name={name}
      control={control}
      rules={{required: required}}
      render={({ field: {value, onChange} }) => (
        narrow ?
          <MatrNarrowSelect 
            autoFocus={autoFocus}
            isDisabled={disabled}
            value={value ? value : null}
            defaultValue={null}
            onChange={(v) => inputChange(v, onChange)}
            placeholder={placeholder}
            isClearable={true}
            isSearchable={false}
            getOptionLabel ={(option)=>option.name!}
            getOptionValue ={(option)=>option.id!}
            options={attributes?.options ? attributes.options : []}
          />
          :
          <MatrSelect
            autoFocus={autoFocus}
            isDisabled={disabled}
            value={value ? value : null}
            defaultValue={null}
            placeholder="Выберите..."
            onChange={(v) => inputChange(v, onChange)}
            isClearable={true}
            isSearchable={false}
            getOptionLabel ={(option)=>option.name!}
            getOptionValue ={(option)=>option.id!}
            options={attributes?.options ? attributes?.options : []}
          />
      )}
    />

  )
}

export function FormMultiselectFieldInput(props: FormFieldMultiselectInputProps) {
  const {name, autoFocus, index, disabled, required, handleChange, narrow, className, attributes, placeholder} = props
  const {control} = props.methods;

  function inputChange(value: any, onChange: () => void) {
    if(!value || value.length === 0) { 
      handleChange([], onChange, index)
      return
    }
    handleChange(value, onChange, index)
  }

  return (
    <Controller 
      name={name}
      control={control}
      rules={{required: required}}
      render={({ field: {value, onChange} }) => (
        narrow ?
          <MatrNarrowSelect 
            autoFocus={autoFocus}
            isDisabled={disabled}
            value={value ? value : []}
            defaultValue={[]}
            onChange={(v) => inputChange(v, onChange)}
            placeholder={placeholder}
            isClearable={true}
            isMulti={true}
            isSearchable={false}
            getOptionLabel ={(option)=>option.name!}
            getOptionValue ={(option)=>option.id!}
            options={attributes?.options ? attributes.options : []}
          />
          :
          <MatrSelect
            autoFocus={autoFocus}
            isDisabled={disabled}
            value={value ? value : []}
            defaultValue={[]}
            placeholder="Выберите..."
            onChange={(v) => inputChange(v, onChange)}
            isClearable={true}
            isMulti={true}
            isSearchable={false}
            getOptionLabel ={(option)=>option.name!}
            getOptionValue ={(option)=>option.id!}
            options={attributes?.options ? attributes?.options : []}
          />
      )}
    />

  )
}

export function FormCollaboratorsFieldInput(props: FormFieldCollaboratorsInputProps) {
  const {spaceUsers} = useSpace()
  const {name, autoFocus, index, disabled, required, handleChange, narrow, attributes, placeholder} = props
  const {control} = props.methods;


  function inputChange(value: any, onChange: () => void) {
    if(!value || value.length < 1) { 
      handleChange([], onChange, index)
      return
    }
    handleChange(value, onChange, index)
  }

  return (
    <Controller 
      name={name}
      control={control}
      rules={{required: required}}
      render={({ field: {value, onChange} }) => (
        narrow ?
          <MatrNarrowSelect 
            autoFocus={autoFocus}
            isDisabled={disabled}
            value={value ? value : []}
            defaultValue={[]}
            onChange={(v) => inputChange(v, onChange)}
            placeholder={placeholder}
            isClearable={true}
            isMulti={attributes?.multiple}
            isSearchable={false}
            getOptionLabel ={(option)=>option.name!}
            getOptionValue ={(option)=>option.id!}
            options={spaceUsers ? spaceUsers : []}
          />
          :
          <MatrSelect
            autoFocus={autoFocus}
            isDisabled={disabled}
            value={value ? value : []}
            defaultValue={[]}
            placeholder="Выберите..."
            onChange={(v) => inputChange(v, onChange)}
            isClearable={true}
            isMulti={true}
            isSearchable={false}
            getOptionLabel ={(option)=>option.name!}
            getOptionValue ={(option)=>option.id!}
            options={spaceUsers ? spaceUsers : []}
          />
      )}
    />

  )
}

export function FormDatetimeFieldInput(props: FormFieldDatetimeInputProps) {
  const {name, autoFocus, index, disabled, required, handleChange, narrow, value, attributes, placeholder} = props
  const {control, watch} = props.methods;
  const [current, setCurrent] = useState<Date | null>(null)
  const [dateFormat, setDateFormat] = useState<any>(dateFormats[0] + ' ' + timeFormats[0]);

  useEffect(() =>{
    setCurrent(value ? new Date(value) : null)
  },[watch(name)])

  useEffect(() => {
    let curdf = dateFormats.find(format => format.name === attributes?.dateFormat)
    let curtf = timeFormats.find(format => format.name === attributes?.timeFormat)

    if (attributes?.includeTime) {
      setDateFormat(curdf?.spec_format + ' ' + curtf?.spec_format)
    } else {
      setDateFormat(curdf?.spec_format)
    }
  },[])

  function inputChange(e: any, onChange: () => void) {
    let value: string | null  = e ? e?.toISOString() : null
    handleChange(value, onChange, index)
  }

  return (
    <Controller 
      name={name}
      control={control}
      rules={{required: required}}
      render={({ field: {value, onChange} }) => (
        narrow ?
          <MatrNarrowDatePicker 
            selected = {current}
            showTimeInput = {attributes?.includeTime ? true : false}
            dateFormat = { dateFormat }
            onChange={(e) => inputChange(e, onChange)}
          />
          :
          <MatrFormDatePicker 
            selected = {current}
            showTimeInput = {attributes?.includeTime ? true : false}
            dateFormat = { dateFormat }
            onChange={(e) => inputChange(e, onChange)}
          />
      )}
    />

  )
}

export function FormRelationFieldInput(props: FormFieldRelationInputProps) {
  const {name, autoFocus, index, disabled, required, handleChange, narrow, className, attributes, boardId, fieldId, value} = props;
  let [buttonRef, setButtonRef] = useState<any>(null)
  const [ open, setOpen ] = useState(false);
  const { control, watch, resetField } = props.methods;


  function openRelations() { setOpen(true) };
  function closeRelations() { 
    setOpen(false)
  };

  const [current, setCurrent] = useState<{id: string, name: string}[] | []>([])

  useEffect(() => {
    setCurrent(value)
  }, [watch(name)])

  function onHandleChange(card: any, onChange: () => void) {
    let pv = card.fields.find((f:any) => f.primary === true)
    let item = {id: pv.id, name: pv.value}
    if(attributes?.multiple) {
      if(current?.some(c => c.id === item.id)) {
        handleChange(current.filter(c => c.id !== item.id), onChange, index)
      } else{
        handleChange([...current!, item], onChange, index)
      }
    } else {
      handleChange([item], onChange, index)
    }
  }

  function onHandleDelete(item: {id: string, name: string}, onChange: () => void) {
    if(attributes?.multiple) {
      handleChange(current.filter(c => c.id !== item.id), onChange, index)
    } else {
      handleChange([item], onChange, index)
    }
  }

  return (
    <Controller 
    name={name}
    control={control}
    rules={{required: required}}
    render={({ field: {value, onChange} }) => (
        <>
          <div className="relative flex flex-col">
            <div>
              <div className='h-7 flex items-center space-x-2 cursor-pointer bg-slate-200 text-slate-600 px-3 rounded-sm' ref={setButtonRef} onClick={() => openRelations()}>
                <HiPlus className="h-3 w-3"/>
                <span className='text-sm'>Добавить</span>
              </div>
            </div>
            <div className="flex flex-col mt-2">
              <div className="flex flex-col space-y-1">
                {current?.map((item: any, index: number) => (
                  <div key={index} className="flex w-full bg-blue-200 text-blue-800 border border-blue-300 py-1.5 px-2 justify-between rounded-sm items-center truncate text-sm cursor-pointer">
                    <div className="flex flex-row text-sm font-medium text-indigo-600">{item.name}</div>
                    <HiXCircle className="ml-1 h-4 w-4 z-auto text-blue-400 hover:text-blue-500" onClick={() => onHandleDelete(item, onChange)}></HiXCircle>
                  </div> 
                ))}
              </div>
            </div>
          </div>
          {open &&
            <MatrPopover onClose={closeRelations} reference={buttonRef} placement="bottom-end">
              <BaseFormRelationList 
                close={closeRelations} 
                boardId={boardId}
                fieldId={fieldId}
                links={current} 
                onChange={(item: any) => onHandleChange(item, onChange)}
                canAdd={false}
              />
            </MatrPopover>
          }  
        </>
      )}
    />

  )
}

export function FormTextareaFieldInput(props: FormFieldTextareaInputProps) {
  const {name, autoFocus, index, disabled, required, handleChange, narrow, className, attributes, placeholder} = props
  const {control} = props.methods;
  const [tokens, setTokens] = useState<any[]>([])

  // useEffect(() => {
  //   const tok = Object.values(Object.fromEntries(Object.entries(card as {}).filter(([key]) => !["id", "boardId", "position"].includes(key)))).filter((i: any) => !["TEXTAREA"].includes(i.type)).map((i:any) => ({
  //     id: i.id,
  //     value: transformValueToString(i),
  //     data: {
  //       type: "editor",
  //       source: "internal",
  //       sourceId: i.boardId, 
  //       valueType: i.type,
  //       boardId: i.boardId,
  //       fieldId: i.fieldId,
  //       cardId: i.cardId,
  //       function: null,
  //     }
  //   }));
  //   setTokens(tok)
  // },[card])

  function inputChange(value: any, onChange: () => void) {
    handleChange(value ? JSON.stringify(value) : null, onChange, index)
  }

  return (
    <Controller 
      name={name}
      control={control}
      rules={{required: required}}
      render={({ field: {value, onChange} }) => (
        !attributes?.editor ? 
            <PlainTextEditor value={value} onChange={(e: any) => inputChange(e, onChange)}/>
          : 
            <BlockEditor onChange={(e) => inputChange(e, onChange)} value={value} tokens={tokens} editable={!disabled}/>
            // <>{tokens && <RichTextEditor value={value} onChange={(e: any) => handleChange(e, onChange, index)} tokens={tokens}/>}</>
      )}
    />

  )
}


export default function FormFieldInput({type, fieldOptions}: {type: MattrTypes | FieldType, fieldOptions: FormFieldInputProps}) {
  switch (type) {
    case MattrTypes.String:
      return (
        <FormStringFieldInput 
          methods={fieldOptions.methods}
          value={(fieldOptions as FormFieldStringInputProps).value}
          autoFocus={fieldOptions.autoFocus || false}
          name={fieldOptions.name}
          index={fieldOptions.index}
          narrow={fieldOptions.narrow || false}
          placeholder={fieldOptions.placeholder || ""}
          disabled={fieldOptions.disabled}
          required={fieldOptions.required}
          handleChange={fieldOptions.handleChange}
          attributes={(fieldOptions as FormFieldStringInputProps).attributes}
        />
      )
    case MattrTypes.Url:
      return (
        <FormUrlFieldInput 
          methods={fieldOptions.methods}
          value={(fieldOptions as FormFieldUrlInputProps).value}
          autoFocus={fieldOptions.autoFocus || false}
          name={fieldOptions.name}
          index={fieldOptions.index}
          narrow={fieldOptions.narrow || false}
          placeholder={fieldOptions.placeholder || ""}
          disabled={fieldOptions.disabled}
          required={fieldOptions.required}
          handleChange={fieldOptions.handleChange}
          attributes={(fieldOptions as FormFieldUrlInputProps).attributes}
        />
      )
    case MattrTypes.Email:
      return (
        <FormEmailFieldInput 
          methods={fieldOptions.methods}
          value={(fieldOptions as FormFieldEmailInputProps).value}
          autoFocus={fieldOptions.autoFocus || false}
          name={fieldOptions.name}
          index={fieldOptions.index}
          narrow={fieldOptions.narrow || false}
          placeholder={fieldOptions.placeholder || ""}
          disabled={fieldOptions.disabled}
          required={fieldOptions.required}
          handleChange={fieldOptions.handleChange}
          attributes={(fieldOptions as FormFieldEmailInputProps).attributes}
        />
      )
    case MattrTypes.Number:
      return (
        <FormNumberFieldInput 
          methods={fieldOptions.methods}
          value={(fieldOptions as FormFieldNumberInputProps).value}
          autoFocus={fieldOptions.autoFocus || false}
          name={fieldOptions.name}
          index={fieldOptions.index}
          narrow={fieldOptions.narrow || false}
          placeholder={fieldOptions.placeholder || ""}
          disabled={fieldOptions.disabled}
          required={fieldOptions.required}
          handleChange={fieldOptions.handleChange}
          attributes={(fieldOptions as FormFieldNumberInputProps).attributes}
        />
      )
    case MattrTypes.Phone:
      return (
        <FormPhoneFieldInput 
          methods={fieldOptions.methods}
          value={(fieldOptions as FormFieldPhoneInputProps).value}
          autoFocus={fieldOptions.autoFocus || false}
          name={fieldOptions.name}
          index={fieldOptions.index}
          narrow={fieldOptions.narrow || false}
          placeholder={fieldOptions.placeholder || ""}
          disabled={fieldOptions.disabled}
          required={fieldOptions.required}
          handleChange={fieldOptions.handleChange}
        />
      )
    case MattrTypes.Duration:
      return (
        <FormDurationFieldInput 
          methods={fieldOptions.methods}
          value={(fieldOptions as FormFieldDurationInputProps).value}
          autoFocus={fieldOptions.autoFocus || false}
          name={fieldOptions.name}
          index={fieldOptions.index}
          narrow={fieldOptions.narrow || false}
          placeholder={fieldOptions.placeholder || ""}
          disabled={fieldOptions.disabled}
          required={fieldOptions.required}
          handleChange={fieldOptions.handleChange}
          attributes={(fieldOptions as FormFieldDurationInputProps).attributes}
        />
      )
    case MattrTypes.Decimal:
      return (
        <FormDecimalFieldInput 
          methods={fieldOptions.methods}
          value={(fieldOptions as FormFieldDecimalInputProps).value}
          autoFocus={fieldOptions.autoFocus || false}
          name={fieldOptions.name}
          index={fieldOptions.index}
          narrow={fieldOptions.narrow || false}
          placeholder={fieldOptions.placeholder || ""}
          disabled={fieldOptions.disabled}
          required={fieldOptions.required}
          handleChange={fieldOptions.handleChange}
          attributes={(fieldOptions as FormFieldDecimalInputProps).attributes}
        />
      )
    case MattrTypes.Percent:
      return (
        <FormPercentFieldInput 
          methods={fieldOptions.methods}
          value={(fieldOptions as FormFieldPercentInputProps).value}
          autoFocus={fieldOptions.autoFocus || false}
          name={fieldOptions.name}
          index={fieldOptions.index}
          narrow={fieldOptions.narrow || false}
          placeholder={fieldOptions.placeholder || ""}
          disabled={fieldOptions.disabled}
          required={fieldOptions.required}
          handleChange={fieldOptions.handleChange}
          attributes={(fieldOptions as FormFieldPercentInputProps).attributes}
        />
      )
    case MattrTypes.Currency:
      return (
        <FormCurrencyFieldInput 
          methods={fieldOptions.methods}
          value={(fieldOptions as FormFieldCurrencyInputProps).value}
          autoFocus={fieldOptions.autoFocus || false}
          name={fieldOptions.name}
          index={fieldOptions.index}
          narrow={fieldOptions.narrow || false}
          placeholder={fieldOptions.placeholder || ""}
          disabled={fieldOptions.disabled}
          required={fieldOptions.required}
          handleChange={fieldOptions.handleChange}
          attributes={(fieldOptions as FormFieldCurrencyInputProps).attributes}
        />
      )
    case MattrTypes.Checkbox:
    case MattrTypes.Boolean:
      return (
        <FormCheckboxFieldInput 
          methods={fieldOptions.methods}
          value={(fieldOptions as FormFieldCheckboxInputProps).value}
          autoFocus={fieldOptions.autoFocus || false}
          name={fieldOptions.name}
          index={fieldOptions.index}
          narrow={fieldOptions.narrow || false}
          placeholder={fieldOptions.placeholder || ""}
          disabled={fieldOptions.disabled}
          required={fieldOptions.required}
          handleChange={fieldOptions.handleChange}
        />
      )
    case MattrTypes.Select:
      return (
        <FormSelectFieldInput 
          methods={fieldOptions.methods}
          value={(fieldOptions as FormFieldSelectInputProps).value}
          autoFocus={fieldOptions.autoFocus || false}
          name={fieldOptions.name}
          index={fieldOptions.index}
          narrow={fieldOptions.narrow || false}
          placeholder={fieldOptions.placeholder || ""}
          disabled={fieldOptions.disabled}
          required={fieldOptions.required}
          handleChange={fieldOptions.handleChange}
          attributes={(fieldOptions as FormFieldSelectInputProps).attributes}
        />
      )
    case MattrTypes.Multiselect:
      return (
        <FormMultiselectFieldInput 
          methods={fieldOptions.methods}
          value={(fieldOptions as FormFieldMultiselectInputProps).value}
          autoFocus={fieldOptions.autoFocus || false}
          name={fieldOptions.name}
          index={fieldOptions.index}
          narrow={fieldOptions.narrow || false}
          placeholder={fieldOptions.placeholder || ""}
          disabled={fieldOptions.disabled}
          required={fieldOptions.required}
          handleChange={fieldOptions.handleChange}
          attributes={(fieldOptions as FormFieldMultiselectInputProps).attributes}
        />
      )
    case MattrTypes.Collaborator:
      return (
        <FormCollaboratorsFieldInput 
          methods={fieldOptions.methods}
          value={(fieldOptions as FormFieldCollaboratorsInputProps).value}
          autoFocus={fieldOptions.autoFocus || false}
          name={fieldOptions.name}
          index={fieldOptions.index}
          narrow={fieldOptions.narrow || false}
          placeholder={fieldOptions.placeholder || ""}
          disabled={fieldOptions.disabled}
          required={fieldOptions.required}
          handleChange={fieldOptions.handleChange}
          attributes={(fieldOptions as FormFieldCollaboratorsInputProps).attributes}
        />
      )
    case MattrTypes.Datetime:
      return (
        <FormDatetimeFieldInput 
          methods={fieldOptions.methods}
          value={(fieldOptions as FormFieldDatetimeInputProps).value}
          autoFocus={fieldOptions.autoFocus || false}
          name={fieldOptions.name}
          index={fieldOptions.index}
          narrow={fieldOptions.narrow || false}
          placeholder={fieldOptions.placeholder || ""}
          disabled={fieldOptions.disabled}
          required={fieldOptions.required}
          handleChange={fieldOptions.handleChange}
          attributes={(fieldOptions as FormFieldDatetimeInputProps).attributes}
        />
      )
    case MattrTypes.Relation:
      return (
        <FormRelationFieldInput 
          methods={fieldOptions.methods}
          value={(fieldOptions as FormFieldRelationInputProps).value}
          autoFocus={fieldOptions.autoFocus || false}
          name={fieldOptions.name}
          index={fieldOptions.index}
          narrow={fieldOptions.narrow || false}
          placeholder={fieldOptions.placeholder || ""}
          disabled={fieldOptions.disabled}
          required={fieldOptions.required}
          handleChange={fieldOptions.handleChange}
          attributes={(fieldOptions as FormFieldRelationInputProps).attributes!}
          boardId={(fieldOptions as FormFieldRelationInputProps).boardId!}
          fieldId={(fieldOptions as FormFieldRelationInputProps).fieldId!}
        />
      )
    case MattrTypes.Textarea:
      return (
        <FormTextareaFieldInput 
          methods={fieldOptions.methods}
          value={(fieldOptions as FormFieldTextareaInputProps).value}
          autoFocus={fieldOptions.autoFocus || false}
          name={fieldOptions.name}
          index={fieldOptions.index}
          narrow={fieldOptions.narrow || false}
          placeholder={fieldOptions.placeholder || ""}
          disabled={fieldOptions.disabled}
          required={fieldOptions.required}
          handleChange={fieldOptions.handleChange}
          attributes={(fieldOptions as FormFieldTextareaInputProps).attributes}
        />
      )
    default: return <></>
  }
}