import { getKeyForType } from "../../../../utils";
import {SummaryCell} from "./summary";

export function GroupCell(props: any) {
  const {row, childRows, groupKey, field, column} = props

  function getValue() {
    if(childRows.length > 0) {
      return childRows[0][column.id]?.value!
    } else {
      <></>
    }
  }

  return (
    <>
      {
        column.rowGroup ?
          <div className='flex w-full h-full justify-start items-center px-2.5 font-medium text-sm'>
            <span className="truncate">{getValue()}</span>
          </div>
        : 
          <SummaryCell field={field} column={column} values={childRows.filter((row: any) => row.id !== 'new').map((row: any) => row[column.key]!)} />
      }
    </>
  )
}
