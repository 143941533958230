import { TbCsv, TbSquaresFilled, TbX } from "react-icons/tb";
import { Row } from "../../../../components/Views/MattrGrid/types";
import { useBoard } from "../../../../core/providers/board";
import { useSpaceStore } from "../../../../core/store/store";
import { PiFileCsvBold, PiFileCsvFill, PiFileXlsFill } from "react-icons/pi";


function BoardExport({close}:{close: () => void}) {
  const {initId, initType} = useBoard()
  const init: string = `${initType}:${initId}`
  const store = useSpaceStore()
  const {board, columns, rows} = store.data[init];

  async function exportToCsv<R, SR>() {
    const {head, body} = getGridContent(columns, rows)
    const content = [head, ...body].join('\n');
    downloadFile(board!.name, new Blob([content], { type: 'text/csv;charset=utf-8;' }));
  }

  async function exportToXlsx<R, SR>() {
    const [{ utils, writeFile }, { head, body }] = await Promise.all([
      import('xlsx'),
      getGridContent(columns, rows)
    ]);
    const wb = utils.book_new();
    const ws = utils.aoa_to_sheet([head, ...body]);
    utils.book_append_sheet(wb, ws, 'Sheet 1');
    writeFile(wb, `${board!.name}.xlsx`);
  }

  // TODO решить проблему с русским
  // async function exportToPdf<R, SR>() {
  //   const [{ jsPDF }, autoTable, { head, body }] = await Promise.all([
  //     import('jspdf'),
  //     (await import('jspdf-autotable')).default,
  //     await getGridContent(columns, rows)
  //   ]);
  //   const doc = new jsPDF({
  //     orientation: 'l',
  //     unit: 'px'
  //   });

  //   autoTable(doc, {
  //     head,
  //     body,
  //     horizontalPageBreak: true,
  //     styles: { cellPadding: 1.5, fontSize: 8, cellWidth: 'wrap'},
  //     tableWidth: 'wrap'
  //   });
  //   doc.save(`${board.name}`);
  // }

  return (
    <>
      <div className="flex items-center justify-between mb-4">
        <div className="flex items-center space-x-2">
          <div className="py-1 px-2 bg-slate-100 flex space-x-2 rounded">
            <TbSquaresFilled size={20} className="text-indigo-600"/>
            <div className="flex font-medium text-sm">{board.name}</div>
          </div>
          <div className="flex font-normal text-sm">Экспорт таблицы</div>
        </div>
        <button onClick={() => close()}><TbX size={18}/></button>
      </div>
      <div className="flex flex-col space-y-4 pb-2 text-slate-800"> 
        <button onClick={exportToCsv} className="flex flex-row items-center w-full rounded focus:outline-none hover:ring-8 hover:ring-slate-100 hover:bg-slate-100">
          <div className="flex w-full items-center">
            <div className="mr-2">
              <PiFileCsvFill size={24}/>
            </div>
            <div className="">
              <div className="text-sm font-medium">
                Экспорт в csv формат
              </div>
            </div>
          </div>
        </button>
        <button onClick={exportToXlsx} className="flex flex-row items-center w-full rounded focus:outline-none hover:ring-8 hover:ring-slate-100 hover:bg-slate-100">
          <div className="flex w-full items-center">
            <div className="mr-2">
              <PiFileXlsFill size={24}/>
            </div>
            <div className="">
              <div className="text-sm font-medium">
                Экспорт в xlsx формат
              </div>
            </div>
          </div>
        </button>
        {/* <button onClick={exportToPdf}>EXPORT PDF</button> */}
      </div>
    </>

  );
}
export default BoardExport;

function getGridContent(columns: any[], rows: Row[] ) {
  let lines:any[] = []
  const cols = columns.filter(c => !["select-row", "create"].includes(c.key)).map(c => ({id: c.key, name: c.name}))
  const head = cols.map(c => c.name)
  const body = rows.map((row) => {
    //@ts-ignore
    let line = cols.map(h => row[h.id as keyof Row]?.value)
    
    return [...lines, ...line]
  })

  return {head, body}
}


function downloadFile(fileName: string, data: Blob) {
  const downloadLink = document.createElement('a');
  downloadLink.download = fileName;
  const url = URL.createObjectURL(data);
  downloadLink.href = url;
  downloadLink.click();
  URL.revokeObjectURL(url);
}