import { useEffect, useState } from "react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { operators } from "../../../core/enums";
import { Field, Formula } from "../../../core/graphql/types";
import { uuidv4 } from "../../../utils";
import FilterItem from "./item";
import useDebounce from "../../../core/hooks/debounce/useDebounce";
import useDebouncedCallback from "../../../core/hooks/debounce/useDebouncedCallback";

interface FiltersListProps {
  currentFilters: any;
  boardFields: any;
  users: any;
  saveFilters: (rest: any) => void; 
}

export default function FiltersList({currentFilters, boardFields, users, saveFilters}: FiltersListProps) {
  const methods = useForm();
  const { control, register, setValue, watch, getValues, handleSubmit, formState } = methods;
  const { fields, append, prepend, remove, replace, swap, move, insert } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "filters", // unique name for your Field Array
  });

  const debounced = useDebouncedCallback(
    (value) => {
      saveFilters(prepareForSave(value))
    },
    500,
    // The maximum time func is allowed to be delayed before it's invoked:
    { maxWait: 2000 }
  );

  useEffect(() => {
    setValue('filters', currentFilters)
  },[])

  const addFilterHandler = () => {
    const first_field: Field = boardFields?.find((it: any) => it.primary === true)
    append({ 
      id: uuidv4(),
      position: watch('filters').length,
      fieldId: first_field.id,
      fieldType: first_field.type,
      fieldValueType: ["FORMULA"].includes(first_field.type) ? (first_field.attributes as Formula).expressionType : first_field.type,
      conjunction: "AND",
      operator: ["FORMULA"].includes(first_field.type) ? operators.filter((o: any) => o.type === (first_field.attributes as Formula).expressionType)[0].value : operators.filter((o: any) => o.type === first_field.type)[0].value,
      value: ""
    });
    startSaving()
  }

  function onFilterChange() {
    startSaving()
  }

  function startSaving() {
    debounced(getValues('filters'))
  }

  function prepareForSave(items: any) {
    const filtersForSave: any = []
    items.map((filter: any, index: number) => {
      let filterForSave = {
        ...filter,
        position: index
      }

      filtersForSave.push(filterForSave)
    })
    return filtersForSave
  }

  const deleteFilterHandler = (index: number) => {
    remove(index)
    startSaving()
  };


  return (
    <div className="relative text-left">
      <FormProvider {...methods}>
          <div className="flex relative flex-col space-y-2 filters h-full">
          {!fields?.length ? <></> : fields.map((filter: any, index: number) => (
            <FilterItem key={filter.id} boardFields={boardFields} users={users} index={index} filters={fields} onFilterChange={onFilterChange} deleteFilter={deleteFilterHandler}/>
          ))}
          </div>
          <button className={`flex flex-row whitespace-nowrap max-w-min text-sm text-indigo-600 rounded-sm font-medium pt-2 pb-6  ${!watch('filters')?.length ? "" : "mt-2"}`} type="button" onClick={addFilterHandler}>Добавить условие</button>
      </FormProvider>
    </div>
  );
}