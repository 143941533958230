import { Popover, Transition } from "@headlessui/react";
import { HiDotsVertical } from "react-icons/hi";
import { Fragment, useState } from "react";
import { usePopper } from 'react-popper'

export type IPopperMenu = {
  menu: IPopperMenuItems;
};

export type IPopperMenuItem = {
  name: string;
  action: () => void;
  icon: React.ReactNode;
  disabled: boolean;
};

export type IPopperMenuItems = IPopperMenuItem[];

export const BoardPreviewMenu: React.FC<IPopperMenu> = ({menu}) => {  
  // TODO Настроить и везде сделать правильно

  function handleClick(action: () => void) {
    action()
  }

  return (
    <div className="overflow-hidden rounded-md border shadow-lg bg-white p-1 mt-1.5">
      <div className="relative bg-white text-slate-600">
        {menu.map((item, i: number) => {
          return (
            <button
              disabled={item.disabled}
              key={i}
              onClick={(e) => handleClick(item.action)}
              className="w-full py-1.5 px-2 space-x-1.5 hover:bg-indigo-600 hover:text-white disabled:bg-white disabled:text-slate-300 flex text-sm text-right rounded-md whitespace-nowrap items-center cursor-pointer"
            >
              {item.icon}
              <div className="text-sm font-medium pr-1.5">
                {item.name}
              </div>
            </button>
          )
        })}
      </div>
    </div>
  );
}
