import { Tab } from "@headlessui/react";
import { useCallback, useEffect, useState } from "react";
import { TbArrowsJoin, TbArrowsSplit, TbSettings, TbTrash, TbX } from "react-icons/tb";
import {
  Background,
  BackgroundVariant,
  Connection,
  Edge,
  Node,
  NodeTypes,
  Panel,
  ReactFlow
} from "reactflow";
import { EdgeStatusEnum, NodeTypesEnum } from "../../../core/graphql/types";
import { useFlowContext } from "../../../core/providers/flow";
import '../../../styles/reactflow/base.css';
import FlowControl from "./control";
import { DeleteableEdge } from "./edges";
import { NodeLoopCollect, NodeLoopFor, NodeLoopRepeat, NodeOutput, NodeRecord, NodeRequest, NodeSource, NodeTrigger } from "./nodes";
import NodeSettings from "./settings";
import NodeInputData from "./settings/data/NodeInputData";
import NodeOutputData from "./settings/data/NodeOutputData";
import { MatrMediumDialog } from "../../../components/ui/portal";
import NodeEdit from "./settings";
import Header from "../../../components/Headers/Header";

const nodeTypes: NodeTypes = {
  trigger: NodeTrigger,
  source: NodeSource,
  output: NodeOutput,
  record: NodeRecord,
  request: NodeRequest,
  loop_for: NodeLoopFor,
  loop_repeat: NodeLoopRepeat,
  loop_collect: NodeLoopCollect
};

const edgeTypes = {
  edgewithdelete: DeleteableEdge,
};

export default function Flow() {
  const defaultViewport = {x: 0, y: 0, zoom: 1}
  const {nodes, onNodesChange, edges, onEdgesChange, node, chooseNode, deleteNode, changeNodePosition, connectNodes} = useFlowContext()
  const [positions, setPositions] = useState<{ x: number; y: number; }>();

  const [openNodeSettings, setOpenNodeSettings] = useState(false)

  function onOpenNodeSettings() {setOpenNodeSettings(true)}
  function onCloseNodeSettings() {
    chooseNode(null)
    setOpenNodeSettings(false)
  }

  useEffect(() => {
    if(!node?.id) {return}
    onOpenNodeSettings()
  },[node?.id])

  const onConnect = useCallback((params: Edge<any> | Connection) => connectingNodes(params, nodes), [nodes]);

  function connectingNodes(params: Edge<any> | Connection, nodes: Node[]) {
    // Написать валидацию для нодов.

    connectNodes(params.source!, params.target!,  params.sourceHandle!.toUpperCase() as EdgeStatusEnum)
  }

  function onNodeDragStart(node: { position: { x: number; y: number; }; }) {
    setPositions({x: Math.round(node.position.x), y: Math.round(node.position.y)})
  };

  function onNodeDragStop(node: Node) {
    if(positions?.x ===  Math.round(node.position.x) && positions?.y ===  Math.round(node.position.y)) { return }
    changeNodePosition(node)
  }

  function onNodeDoubleClick(node: Node) {
    chooseNode(node.id)
  }


  // function showInputs(type: string) {
  //   switch (type.toUpperCase()) {
  //     case NodeTypesEnum.Trigger: return false    
  //     default: return true
  //   }
  // }

  // function showDelete(type: string) {
  //   switch (type.toUpperCase()) {
  //     case NodeTypesEnum.Trigger: return false    
  //     default: return true
  //   }
  // }

  return (
    <>
      <Header location={"flow"} />
      <div className="flex flex-1 w-full" style={{height: window.outerHeight - 164}}>
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          deleteKeyCode={""}
          nodeTypes={nodeTypes}
          edgeTypes={edgeTypes}
          onNodeDragStart={(e, node)=> onNodeDragStart(node)}
          onNodeDragStop={(e, node)=> onNodeDragStop(node)}
          onNodeDoubleClick={(e, node) => onNodeDoubleClick(node)}
          fitView
          defaultViewport={defaultViewport}
        > 
          <Panel position="bottom-center"><FlowControl /></Panel>
          <Background variant={BackgroundVariant.Dots} />
        </ReactFlow>

        {openNodeSettings ? 
            <MatrMediumDialog open={openNodeSettings} onClose={onCloseNodeSettings}>
              <NodeEdit onClose={onCloseNodeSettings}/>
            </MatrMediumDialog>
        : <></>}
      </div>
    </>
  )
}