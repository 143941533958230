import { BlockTypeEnum, FieldType } from "../../graphql/types";

export function getBlockWHByType(type: BlockTypeEnum, fieldType: FieldType | null) {
  switch (type) {
    case BlockTypeEnum.BlockField: 
      switch (fieldType) {
        case FieldType.Checkbox:
        case FieldType.Collaborator:
        case FieldType.Currency:
        case FieldType.CreatedBy:
        case FieldType.Datetime:
        case FieldType.Decimal:
        case FieldType.Duration:
        case FieldType.Email:
        case FieldType.Formula:
        case FieldType.InsertedAt:
        case FieldType.Lookup:
        case FieldType.Multiselect:
        case FieldType.Number:
        case FieldType.Percent:
        case FieldType.Phone:
        case FieldType.Rating:
        case FieldType.Recordid:
        case FieldType.Select:
        case FieldType.String:
        case FieldType.UpdatedAt:
        case FieldType.UpdatedBy:
        case FieldType.Url: return {w: 6, h: 2}
        case FieldType.Relation: return {w: 12, h: 8}
        case FieldType.Textarea: return {w: 6, h: 8}
        default: return {w: 12, h:2}
      }
    case BlockTypeEnum.BlockCalendar: return {w: 12, h: 12}
    case BlockTypeEnum.BlockButton: return {w: 2, h: 1}
    case BlockTypeEnum.BlockChart: return {w: 12, h: 8}
    case BlockTypeEnum.BlockFilter: return {w: 12, h: 2}
    case BlockTypeEnum.BlockForm: return {w: 12, h: 12}
    case BlockTypeEnum.BlockGrid: return {w: 12, h: 8}
    case BlockTypeEnum.BlockRecordsSelect: return {w: 12, h: 2}
    case BlockTypeEnum.BlockSummary: return {w: 4, h: 4}
    case BlockTypeEnum.BlockText: return {w: 12, h: 2}
    default: return {w: 12, h: 2}
  }
} 
