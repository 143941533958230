import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useGetTelegramContentQuery, BoardForm } from "../../core/graphql/types";
import { HiPlus } from "react-icons/hi"
import TelegramFormModal from "../../components/modals/telegram/form";
import { useTelegram } from "../../core/hooks/useTelegram";


const TelegramPage = () => {
  // @ts-ignore-next-line
  const {user} = useTelegram()
  const [tgData, setTgData] = useState<BoardForm[] | any>([])
  let params = useParams()

  const [open, setOpen] = useState(false);
  const [showAll, setShowAll] = useState(false)
  const [form, setForm] = useState<BoardForm | null>()

  const [{data, fetching, error}, getTgContent] = useGetTelegramContentQuery({variables: {key: params.key!, bot: "testwebappbigthing_bot"}})

  useEffect(() => {
    if(!data) {
      return
    }
    setTgData(data?.getTelegramContent!)
  }, [data])


  if(error) {return (
    <section className="flex flex-col items-center w-full bg-white h-screen">
      ОШИБКА
    </section>
  )}

  function openModal(form: BoardForm) {
    setForm(form)
    setOpen(true)
  }

  function closeModal() {
    setForm(null)
    setOpen(false)
  }

  return (
    tgData?.length > 0 ? 
    <div className="flex flex-col">
      <div className="w-full grid grid-cols-1 gap-8 md:grid-cols-2">
      {tgData?.map((form: BoardForm) => (
        <div key={form.id} 
          className="flex col-span-1 rounded-sm h-full p-2 text-sm gap-4 cursor-default items-start hover:shadow telegram_secondary_bg_color"
          onClick={() => openModal(form)}
        >
          <div className="flex shrink-0 w-10 h-10 bg-indigo-600 rounded-sm text-white items-center justify-center">
            <HiPlus />
          </div>
          <div className="flex flex-col w-full">
            <div className="text-slate-600 font-medium">{form.title}</div>
            <div className="text-slate-400">{form.description ? form.description : "Описание отсутсвует"}</div>
          </div>
        </div>
      ))}
      </div>
      {user?.username}
      {open && form && <TelegramFormModal open={open} close={closeModal} form={form!}/>}
    </div>
    : <></>
  )
}
export default TelegramPage;