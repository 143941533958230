import React, {useEffect} from "react";
import { useFormContext, Controller } from "react-hook-form";
import Select from "react-select";
import {durationInputDiscretList, durationTimeFormatList} from '../../../../../core/enums';

// Переписать на Intl.NumberFormat, используя локали пользователя

interface FieldDurationProps {
  type: string;
  durationInputDiscret: string;
  durationTimeFormat: string;
}

function FieldDuration({attributes}: FieldDurationProps | any) {
  const methods = useFormContext();
  const {register, watch, control, unregister, setValue} = methods;

  useEffect(() => {
    unregister('attributes');
  },[])

  useEffect(() => {
    register('attributes.type');
    setValue('attributes.type', "DURATION");
    setValue('attributes.durationInputDiscret', attributes.durationInputDiscret ? attributes.durationInputDiscret : "MINUTES");
    setValue('attributes.durationTimeFormat', attributes.durationTimeFormat ? attributes.durationTimeFormat : "HH_MM");
  },[attributes])

  return (
    <>
      <div className="relative">
        <label className="font-medium text-gray-600 text-sm">Формат входных данных:</label>
        <Controller
          name="attributes.durationInputDiscret"
          control={control}
          render={({ field: { onChange, value }}) => (
            <div className="mt-2">
              <Select
                styles={{control: () => ({border: 0})}}
                classNamePrefix="matr_select_custom"
                placeholder="Выберите значение по умолчанию"
                isSearchable={false}
                defaultValue={durationInputDiscretList.find(format => format.value === value)}
                value={durationInputDiscretList.find(format => format.value === value)}
                onChange={val => onChange(val?.value)}
                options={durationInputDiscretList}
              />
            </div>
          )}
        />
      </div>
      <div className="relative">
        <label className="font-medium text-gray-600 text-sm">Формат продолжительности:</label>
        <Controller
          name="attributes.durationTimeFormat"
          control={control}
          render={({ field: { onChange, value }}) => (
            <div className="mt-2">
              <Select
                styles={{control: () => ({border: 0})}}
                classNamePrefix="matr_select_custom"
                placeholder="Выберите значение по умолчанию"
                isSearchable={false}
                defaultValue={durationTimeFormatList.find(format => format.value === value)}
                value={durationTimeFormatList.find(format => format.value === value)}
                onChange={val => onChange(val?.value)}
                options={durationTimeFormatList}
              />
            </div>
          )}
        />
      </div>
    </>
  );
}
export default FieldDuration;