import { useFormContext, Controller } from "react-hook-form";
import MatrSelect from "../../../../../components/ui/select/base";
import { SingleValue } from "react-select";
import { useSpace } from "../../../../../core/providers/space";
import { Board, Field, FieldType } from "../../../../../core/graphql/types";
import { useState } from "react";

const PageLayoutKanbanOptions = () => {
  const {boards} = useSpace()
  const {control, watch, formState: {errors}} = useFormContext()
  const [fields, setFields] = useState<Field[]>()

  function handleChangeBoard(e: SingleValue<Board>, onChange: (...event: any[]) => void) {
    setFields(boards.find(b => b.id === e?.id)?.fields! as Field[])
    onChange(e?.id)
  }

  function handleSelectStackedField(e: SingleValue<Field>, onChange: (...event: any[]) => void) {
    onChange(e?.id)
  }

  function handleSelectTitleField(e: SingleValue<Field>, onChange: (...event: any[]) => void) {
    onChange(e?.id)
  }

  return (
    <>
      <div className="relative">
        <label className="font-medium text-gray-600 text-sm">Доска:</label>
        <Controller
          name="pageOptions.boardId"
          control={control}
          rules={{required: true}}
          render={({ field: { onChange, value }}) => (
            <div className="mt-1.5">
              <MatrSelect
                getOptionLabel={(option) => option?.name!}
                getOptionValue ={(option)=> option?.id!}
                value={value && value.length > 0 ? boards.find(r => value.includes(r.id)) : []}
                onChange={(e) => handleChangeBoard(e, onChange)}
                options={boards}
              />
            </div>
          )}
        />
        {errors.boardId && <span className="text-red-600 text-xs font-normal">"Доска" обязательное поле.</span>}
      </div>
      <div className="relative space-y-1.5">
        <label className="matr-label">Базовое поле "Одиночный выбор":</label>
        <p className="text-xs text-slate-400 pt-1">Это поле используется для построения канбан доски.</p>
        <Controller
          name="pageOptions.stackedFieldId"
          control={control}
          rules={{required: true}}
          render={({ field: { onChange, value, ref }}) => (
            <div className="mt-2">
              <MatrSelect 
                classNamePrefix="matr_select_form"
                placeholder="Выберите поле на базе которого будет построен вид"
                isSearchable={false}
                getOptionLabel ={(option)=>option?.title!}
                getOptionValue ={(option)=>option?.id!}
                value={fields?.find((f: any) => f.id === value)}
                onChange={(e) => handleSelectStackedField(e, onChange)}
                options={fields?.filter((f: any) => f.type === FieldType.Select)}
              />
            </div>
          )}
        />
      </div>
        { watch('pageOptions.stackedFieldId') && <>
          <div className="relative space-y-1.5">
            <label className="matr-label">Выберите поле заголовка для события:</label>
            <p className="text-xs text-slate-400 pt-1">Занчение из этого поля будет отображаться в календаре.</p>
            <Controller
              name="pageOptions.titleFieldId"
              control={control}
              render={({ field: { onChange, value, ref }}) => (
                <div className="mt-2">
                  <MatrSelect 
                    classNamePrefix="matr_select_form"
                    placeholder="Выберите поле заголовка"
                    isSearchable={false}
                    getOptionLabel ={(option)=>option?.title!}
                    getOptionValue ={(option)=>option?.id!}
                    value={fields?.find(f => f.id === value)}
                    onChange={(e) => handleSelectTitleField(e, onChange)}
                    options={fields}
                  />
                </div>
              )}
            />
          </div> 
        </>}
    </>
  )
}

export default PageLayoutKanbanOptions;