import { useState, useCallback, FocusEvent, useEffect } from 'react';
import { Controller, useFormContext, useFieldArray } from 'react-hook-form';
import { debounce } from "debounce";
import useDeepCompareEffect from "use-deep-compare-effect";

import { useFetcherContext } from '../../../../../../core/providers/fetcher';
import { BiToggleLeft, BiToggleRight } from 'react-icons/bi';

export default function MappingField({index, setIndex, rowNumber}:{index: number, setIndex: (index: number) => void; rowNumber: number}):JSX.Element {
  const {records, updateRequestField} = useFetcherContext()
  const methods = useFormContext();
  const {control, getValues, formState} = methods;
  const [colRecords, setColRecords] = useState<any[]>([])

  useEffect(() => {
    let slug = getValues(`fields.${index}.slug`)
    let recs: any[] = records!.map((rec:any) => rec[slug!])
    setColRecords(recs)
  },[records])

  // // Обновление

  // const debouncedSave = useCallback(
  //   debounce(() => {
  //     updateRequestField(getValues(`fields.[${index}]`))
  //   }, 1000),
  //   []
  // );

 
  function handleChangeActive(e: boolean | ((prevState: boolean) => boolean), onChange: { (...event: any[]): void; (arg0: any): void; }) {
    // debouncedSave()
    setIndex(index)
    onChange(!e)
  }

  function handleChangeTitle(e: any,  onChange: { (...event: any[]): void; (arg0: any): void; }) {
    // debouncedSave()
    setIndex(index)
    onChange(e.target.value)
  }


  function handleChangeType(e: any, onChange: { (...event: any[]): void; (arg0: any): void; }) {
    onChange(e)
  }



  return (
    <div className="flex w-full h-8 items-center border-b px-4">
      <Controller
        name={`fields.${index}.active`}
        control={control}
        render={({ field: { onChange, value, ref }}) => (
        <div 
          className={`flex items-center px-1 text-sm font-normal justify-between w-10 cursor-default`} 
          onClick={() => handleChangeActive(value, onChange)}
        >
          {value ? <BiToggleRight className='h-6 w-6 text-blue-800'/> : <BiToggleLeft className='h-6 w-6 text-slate-400'/>}
        </div>
      )}
      />
      <div className='grid grid-cols-6 w-full h-full items-center'>
        <Controller
          name={`fields.${index}.title`}
          rules={{required: true}}
          control={control}
          render={({ field: { onChange, value, ref }}) => (
            <input 
            value={value}
            onChange={(e) => handleChangeTitle(e, onChange)}
            onBlur={(e) => handleChangeTitle(e, onChange)}
            className="matr-form-input col-span-2 max-h-8"
          />
          )}
        />
        <div className='flex items-center text-sm px-2 col-span-1 truncate'>{getValues(`fields.${index}.pathAsQuery`)}</div>
        <div className='flex items-center text-sm px-2 col-span-1'>{getValues(`fields.${index}.fieldType`)}</div>
        <div className='flex items-center text-sm px-2 col-span-2 truncate'>{colRecords[rowNumber]}</div>
      </div>


        {/* { colRecords && colRecords.length ?
          colRecords.map((record: any, index: number) => {
            return <div className="w-full h-8 text-sm truncate py-2" key={`${column.id}_${index}`}>{record}</div>
          })
          :
          <></>
        } */}
    </div>
  )
}