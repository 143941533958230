import React, { useEffect, useMemo, useState } from "react";
import { MentionsInput, Mention, MentionItem } from "react-mentions";

export interface ExpressionOption {
  id: string;
  display: string;
  type: string;
  field_type?: string;
  annatation?: string; 
}

const ExpressionEditor = ({value, options, onChange}: {value: string, options: ExpressionOption[], onChange: (e: string) => void}) => {
  const [val, setVal] = useState(value ? value : "");

  useEffect(() => {
    setVal(value)
  },[value])

  const hanldeAreaInputValue = (event: { target: { value: string; }; }, newValue: string, newPlainTextValue: React.SetStateAction<string>, mentions: MentionItem[]) => {
    onChange(newPlainTextValue as string)
    setVal(newPlainTextValue);
  };

  const dataSet = useMemo(() => {return options}, [options]);

  function transformDisplay(id: string, display: string) {
    if(dataSet.filter(item => item.id === id)[0].type === "field") {
      return `{${display}}`
    } else {
      return `${display}()`
    }
  }

  const mention = (
    suggestion: ExpressionOption,
    search: string,
    highlightedDisplay: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | null | undefined,
    index: number,
    focused: boolean) => {
    return (
      <div className={`${focused ? 'focused' : ''} flex flex-col w-full`}>
        <div className="flex flex-row justify-between mb-0.5">
          <div className="">{highlightedDisplay}</div>
          <div className="text-gray-500">{suggestion.type} {suggestion.field_type}</div>
        </div>
        <div className="text-gray-500">{suggestion.annatation}</div>
      </div>
    )
  }

  return (
    <>
      <div className="wrapper-expression-input">
        <MentionsInput
          singleLine={true}
          className="formula single"
          id="logicTextArea"
          value={val}
          allowSpaceInQuery={true}
          allowSuggestionsAboveCursor={false}
          a11ySuggestionsListLabel="ПОЛЯ И ФУНКЦИИ"
          onChange={(event, newValue, newPlainTextValue, mentions) => {
            hanldeAreaInputValue(event, newValue, newPlainTextValue, mentions);
          }}
        >
          <Mention
            appendSpaceOnAdd={false}
            markup="@[__display__](field:__id__)"
            trigger={/(?:^|)(\@([^\s@]*))$/}
            data={dataSet}
            renderSuggestion={(suggestion, search, highlightedDisplay, index, focused) => mention(suggestion as ExpressionOption, search, highlightedDisplay, index, focused)}
            displayTransform={(id, display) => transformDisplay(id, display)}
            // onAdd={onAdd}
          />
        </MentionsInput>
      </div>
    </>
  );
};


export default ExpressionEditor;