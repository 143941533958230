import React, {Fragment} from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Listbox, Transition } from "@headlessui/react";
import { authTypes } from '../../../../../core/enums';
import AuthBasic from './basic'
import AuthBearer from './bearer';
import { HiChevronDown } from 'react-icons/hi';

export default function AuthPanel():JSX.Element {
  const methods = useFormContext();
  const {watch, control, setValue, reset} = methods;

  const handleChange = (value: { name: string; id: string; }, onChange: { (...event: any[]): void; (arg0: string): void; }) => {
    onChange(value.id)
    switch (value.id) {
      case 'AUTH_BASIC': 
        setValue('authorization', {type: value.id, dynamic: false, username: null, password: null})
        break;
      case 'AUTH_BEARER': 
        setValue('authorization', {type: value.id, dynamic: false, token: null, prefix: null})
        break;
      case 'AUTH_OAUTH': return <>"oauth"</>
      case 'AUTH_HEADER': return <>"header"</>
      case 'AUTH_PARAMETER': return <>"parameter"</>
      default: return setValue('authorization', {type: value.id})
    }
  }

  function showContent(item: string) {
    switch (item) {
      case 'AUTH_BASIC': return <AuthBasic />
      case 'AUTH_BEARER': return <AuthBearer />
      case 'AUTH_OAUTH': return <>"oauth"</>
      case 'AUTH_HEADER': return <>"header"</>
      case 'AUTH_PARAMETER': return <>"parameter"</>
      default: return  <div className='flex text-sm text-center h-24 justify-center items-center'>Без авторизации</div>
    }
  }


  return (
    <Controller
      name="authorizationType"
      control={control}
      render={({ field: { onChange, value, ref }}) => (
        <>
          <div className='relative flex flex-col text-sm mt-2'>
            <label className="font-medium text-slate-600 text-sm mb-2">Тип авторизации</label>
            <Listbox value={value} onChange={(value) => handleChange(value, onChange)}>
              <div className="w-full flex">
                <Listbox.Button as='div' className="flex w-full bg-slate-100 text-slate-800 px-2 justify-between h-8 rounded-sm focus:outline-none items-center">
                  <span className="block truncate font-medium">{authTypes.find((at: {name: string; id: string;}) => at?.id === value!)?.name}</span>
                  <HiChevronDown size={20}/>
                </Listbox.Button>
                <Transition
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options className="absolute z-10 max-h-60 w-full mt-8 overflow-auto bg-white focus:outline-none text-sm shadow-lg ">
                    {authTypes.map((op: { name: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | null | undefined; }, opIdx: React.Key | null | undefined) => (
                      <Listbox.Option
                        key={opIdx}
                        className={({ active }) =>
                          `relative cursor-default select-none py-2 px-2 hover:bg-indigo-100 ${
                            active ? 'bg-indigo-200 text-indigo-900' : 'text-slate-900'
                          }`
                        }
                        value={op}
                      >
                        {({ selected }) => (
                          <>
                            <span
                              className={`block truncate ${
                                selected ? 'font-medium uppercase' : 'font-normal'
                              }`}
                            >
                              {op.name}
                            </span>
                          </>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Transition>
              </div>
            </Listbox>
          </div>
          {showContent(watch('authorizationType'))}          
        </>
      )}
    />
  )

}