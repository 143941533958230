import DatePicker, { ReactDatePickerProps } from "react-datepicker";
import { HiChevronLeft, HiChevronRight } from "react-icons/hi";
import moment from "moment";
import { forwardRef } from "react";

export default function MatrCellDatePicker(props: JSX.IntrinsicAttributes & JSX.IntrinsicClassAttributes<DatePicker<never, undefined>> & Readonly<ReactDatePickerProps<never, undefined>>) {
  function handleInputValue(e: any) {
    console.log(Date.parse(e))
    props.onChange(e, undefined)
  }

  function handleClearValue() {
    props.onChange(null, undefined)
  }

  return (
    <div className="space-y-2">
      <DatePicker {...props} popperClassName="hidden"/>
      <DatePicker 
        {...props}
        inline
        renderCustomHeader={({
          date,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
            <div className="flex items-center justify-between pl-1 py-2">
              <span className="text-sm text-gray-700 capitalize">
                  {moment(date).format('MMMM yyyy')}
              </span>

              <div className="space-x-2">
                <button
                  onClick={decreaseMonth}
                  disabled={prevMonthButtonDisabled}
                  type="button"
                  className={`
                      ${prevMonthButtonDisabled && 'cursor-not-allowed opacity-50'}
                      inline-flex p-1 text-sm font-medium text-gray-700 bg-slate-100 rounded-sm hover:bg-slate-300 focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-blue-500
                  `}
                >
                  <HiChevronLeft className="w-4 h-4 text-gray-600" />
                </button>

                <button
                  onClick={increaseMonth}
                  disabled={nextMonthButtonDisabled}
                  type="button"
                  className={`
                      ${nextMonthButtonDisabled && 'cursor-not-allowed opacity-50'}
                      inline-flex p-1 text-sm font-medium items-center text-gray-700 bg-slate-100 rounded-sm hover:bg-slate-300 focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-blue-500
                  `}
                >
                  <HiChevronRight className="w-4 h-4 text-gray-600" />
                </button>
              </div>
            </div>
        )}
      />
      <div className="flex justify-end">
        <button type="button" className="text-sm px-2 py-1 bg-slate-200 rounded-sm" onClick={() => handleClearValue()}>Очистить</button>
      </div>
      
    </div>
  )
}