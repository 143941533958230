import React, { FC, useContext } from "react";
import { HiArrowDown, HiArrowLeft, HiArrowRight, HiArrowSmDown, HiArrowSmUp, HiArrowUp } from "react-icons/hi";
import styled from "styled-components";
import { DocViewerContext } from "../state";
import { nextDocument, previousDocument } from "../state/actions";
import { IStyledProps } from "../types";
import { ButtonSecondary } from "./common/Button";
import { NextDocIcon, PrevDocIcon } from "./icons";

export const DocumentNav: FC<{}> = () => {
  const {
    state: { currentDocument, currentFileNo, documents },
    dispatch,
  } = useContext(DocViewerContext);

  if (documents.length <= 1 || !currentDocument) return null;

  let fileName = currentDocument.uri;

  const splitURL = fileName.split("/");
  if (splitURL.length) {
    fileName = splitURL[splitURL.length - 1];
  }

  return (
    <div id="doc-nav" className="flex flex-row items-center justify-end gap-2">
      <div className="flex flex-row space-x-1">
        <button
          className="flex item-center justify-center w-full h-full p-1 rounded-md bg-slate-200 hover:bg-indigo-600 text-slate-800 hover:text-white disabled:bg-slate-100 disabled:text-slate-200 "
          onClick={() => dispatch(previousDocument())}
          disabled={currentFileNo === 0}
        >
          <HiArrowSmUp className="w-5 h-5"/>
        </button>
        <button
          className="flex item-center justify-center w-full h-full p-1 rounded-md bg-slate-200 hover:bg-indigo-600 text-slate-800 hover:text-white disabled:bg-slate-100 disabled:text-slate-200 "
          onClick={() => dispatch(nextDocument())}
          disabled={currentFileNo >= documents.length - 1}
        >
          <HiArrowSmDown className="w-5 h-5"/>
        </button>
      </div>
      <p id="doc-nav-info" className="text-xs font-semibold text-slate-400">
        {currentFileNo + 1} / {documents.length}
      </p>
    </div>
  );
};

const Container = styled.div`
  min-width: 150px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin: 0 10px;
  color: ${(props: IStyledProps) => props.theme.text_primary};
`;

const ButtonPrev = styled(ButtonSecondary)`
  width: 30px;
  height: 30px;
  margin: 0 5px 0 10px;

  @media (max-width: 768px) {
    width: 25px;
    height: 25px;
  }
`;
const ButtonNext = styled(ButtonPrev)`
  margin: 0 5px;
`;
