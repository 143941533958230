import React, {useEffect, useState} from "react";
import _ from "lodash";
import {BigSpinner} from "../../../components/loaders/index";
import { useAuth } from "../auth";
import {useNavigate, useParams} from "react-router-dom";
import { CardWithValues, Page, Panel, useCreateBlockMutation, useDeleteBlockMutation, useGetPageDataQuery, useGetPanelQuery, Block, useUpdateBlocksPositionMutation, Field, useUpdateBlockMutation, LayoutEnum, BlockTypeEnum, useGetPanelByPageQuery, useStoreFormMutation, useDeletePageMutation, PanelInList, usePublishingPanelMutation, useUpdatePageMutation, useUpdatePagePositionMutation, BlockOptionsInput, PanelChanges, usePanelChangesSubscription, CardValueInput, useBlockToTabMutation, BlockSourceTypeEnum, Card} from "../../graphql/types";
import toast from "react-hot-toast";
import { SimpleModal } from "../../../components/modals/simple";
import PanelSettings from "../../../containers/Panel/forms/editPanel";
import { useSpaceStore } from "../../store/store";
import { PanelSubscriptionProvider } from "../subscriptions/panel";
import { transformFormValueToFieldValue } from "../../Controllers/Base";

interface PanelContextType {
  editorMode: boolean,
  panel: Panel | null,
  page: Page,
  pageBlock: any,
  block: any,
  currentCard: CardWithValues | null;
  currentCardId: string | null;
  currentCardPrimaryKey: string | null;
  canEdit: boolean,
  changeEditorMode: () => void;
  setPanel: (panel: Panel) => void;
  setPage: (page: Page) => void;
  publishPanel: (id: string) => void;
  changePage: (pageId: string) => void;
  updatePage: (data: Page) => void;
  removePage: (pageId: string) => void;
  setPageBlock: (block: any) => void;
  setBlock: (block: any) => void;
  showCardModal: (props: any) => void;
  addBlock: (data: any) => void;
  updateBlock: (block: Block) => void;
  updateBlocksPosition: (blocks: {id: string, x: number, y: number, w: number, h: number}[]) => void;
  removeBlock: (id: string) => void;
  changeCurrentBlock: (event: any, block: Block | null) => void;
  selectCard: (card: CardWithValues | null) => void;
  storeFormBlock: (data: {boardId: string, cardId: string | null, values: any}) => void;
  getParentPageBlock: (block: Block) => void;
  switchTab: (block: Block) => void;
}

let PanelContext = React.createContext<PanelContextType>(null!);

export function PanelProvider({ children }: { children: React.ReactNode }) {
  const { page_id } = useParams();
  const { currentUser } = useAuth();
  const store = useSpaceStore()
  const {panel, currentPage} = store
  const {panelsSetPanel, panelsSetPage} = store.panelActions
  const {resetAllBoardData} = store.boardActions

  const [ editorMode, setEditorMode ] = useState(false)
  const [ canEdit, setCanEdit ] = useState(false)
  const [ block, setBlock ] = useState<any>(null);
  const [ pageBlock, setPageBlock ] = useState<any>(null);

  const [ currentCard, setCurrentCard ] = useState<CardWithValues | null>(null);
  const [ currentCardId, setCurrentCardId ] = useState<string | null>(null);
  const [ currentCardPrimaryKey, setCurrentCardPrimaryKey ] = useState<string | null>(null);
  const [ pageData, setPageData] = useState<any>(null);

  let [ loading, setLoading] = useState<boolean>(true)
  let [ openCardModal, setOpenCardModal ] = useState<boolean>(false);


  function showCardModal() {setOpenCardModal(true)}
  function closeCardModal() {setOpenCardModal(false)}

  const [{data: panelData, fetching: panelFetching, error: panelError}, getPanel] = useGetPanelByPageQuery({variables: {id: page_id!}, pause: true, requestPolicy: "network-only"});
  const [, createBlock] = useCreateBlockMutation()
  const [, updateBlockMutation] = useUpdateBlockMutation()
  const [, blockToTabMutation] = useBlockToTabMutation()
  const [, deleteBlock] = useDeleteBlockMutation()
  const [, updateBlocksPositionMutation] = useUpdateBlocksPositionMutation()
  const [, updatePageMutation] = useUpdatePageMutation()
  const [, updatePagePosition] = useUpdatePagePositionMutation()
  const [, deletePage] = useDeletePageMutation()
  const [, publishingPanel] = usePublishingPanelMutation()
  const [, storeForm] = useStoreFormMutation()


  useEffect(()=> {
    if(!currentPage || !panel) { 
      getPanel() 
      return 
    }

    if(panel?.pages?.some(p => p?.id === page_id)) {
      // console.log("IN PANEL")
      if(currentPage.id !== page_id) {
        setLoading(true)
        changePage(page_id!)
      }
    } else {
      // console.log("OUT PANEL")
      getPanel()
      setLoading(true)
      setBlock(null)
      setPageBlock(null)
      setPageData(null)
      setCurrentCard(null)
      setCurrentCardId(null)
    }
    
  },[page_id])

  useEffect(() => {
    if(!panelData?.getPanelByPage) {return}
    panelsSetPanel(panelData.getPanelByPage!)
    panelsSetPage(panelData.getPanelByPage?.pages!.find(p => p?.id === page_id)!)

    if(currentUser?.space?.spaceId === panelData?.getPanelByPage?.spaceId && panelData.getPanelByPage.canEdit) {
      setCanEdit(true)
      setEditorMode(panelData.getPanelByPage.published ? false : true)
    } else {
      setCanEdit(false)
      setEditorMode(false)
    }
  }, [panelData])

  // Mutations

  function publishPanel(id: string) {
    publishingPanel({id: id}).then(result => {
      if(result.error) {return}
      panelsSetPanel(result.data?.publishingPanel as Panel)
    })
  }

  function updatePage(data: any) {
    let newPage = {...currentPage, ...data}
    updatePageMutation(newPage).then(result => {
      return result
    })
  }

  function removePage(pageId: string) {
    deletePage({id: pageId}).then(result => {
      return result
    })
  }


  function addBlock(data: { pageId: any; parentId: any; type: any; position: any; options: any; sourceType: BlockSourceTypeEnum, source: any}) {
    toast.promise(
      createBlock({pageId: data.pageId, parentId: data.parentId, type: data.type, sourceType: data.sourceType, position: data.position, source: data.source, options: data.options}).then(result => {
        return result
      }),
      {
        loading: 'Добавляем блок',
        success: (data) => {
          if (data.error) throw new Error();
          return 'Блок добавлен';
        },
        error: 'Ошибка. Не удалось создать блок',
      }
    );
  }

  function updateBlock(blockData: any) {
    toast.promise(
      updateBlockMutation({
        id: blockData.id!,
        parentId: blockData.parentId,
        position: blockData.position,
        sourceType: blockData.sourceType,
        source: normalizeSource(blockData.source),
        options: blockData.options as BlockOptionsInput,
        permissions: blockData.permissions
      }).then(result => {
        return result
      }),
      {
        loading: 'Обновляем блок',
        success: (data) => {
          if (data.error) throw new Error();
          return 'Блок обновлен';
        },
        error: 'Ошибка. Не удалось обновить блок',
      }
    );
  }

  function normalizeSource(source: any) {
    if(Object.hasOwn(source, "field")) {
      delete source.field
    }
    return source
  }

  function updateBlocksPosition(blocks: any) {
    updateBlocksPositionMutation({blocks: blocks})
  }

  function removeBlock(id: string) {
    toast.promise(
      deleteBlock({pageId: currentPage?.id!, id: id}).then(result => {
        return result
      }),
      {
        loading: 'Удаляем блок',
        success: (data) => {
          if (data.error) throw new Error();
          return 'Блок удален';
        },
        error: 'Ошибка. Не удалось удалить блок',
      }
    );
  }

  function switchTab(block: Block) {
    toast.promise(
      blockToTabMutation({id: block.id}).then(result => {
        return result
      }),
      {
        loading: 'Трансформируем блок',
        success: (data) => {
          if (data.error) throw new Error();
          return block.isPage ? "Вкаладка удалена" : "Вкладка создана";
        },
        error: 'Ошибка. Не удалось трансформировать блок',
      }
    );
  }

  function storeFormBlock(data: {boardId: string, cardId: string | null, values: CardValueInput[]}) {
    storeForm({
      boardId: data.boardId,
      cardId: data.cardId,
      values: data.values
    }).then(result => {
      return result
    })
  }

  // FUNCTIONS

  function changeEditorMode() { canEdit ? setEditorMode(!editorMode) : setEditorMode(false)}

  function selectCard(card: CardWithValues | null) {
    if(!card) {
      setCurrentCardId(null)
      setCurrentCardPrimaryKey(null)
      setCurrentCard(null)
    } else {
      setCurrentCardId(card?.id!)
      setCurrentCardPrimaryKey(card?.values?.find(v => v?.primary === true)?.id!)
      setCurrentCard(card!)
    }
  }

  function changePage(pageId: string) {
    setBlock(null)
    setPageBlock(null)
    setCurrentCard(null)
    setCurrentCardId(null)
    resetAllBoardData()
    panelsSetPage(panel?.pages?.find(p => p?.id === pageId)!)
    setLoading(false)
  }



  function changeCurrentBlock(event: any, block: Block | null) {
    event?.stopPropagation()

    if(!block) {
      setBlock(null)
      return
    }

    setBlock(block)
    // if(block?.isPage) {
    //   setPageBlock(block)
    // } else if(!block?.isPage && !block?.parentId) {
    //   setPageBlock(null)
    // } else if(!block?.isPage && block?.parentId && block?.parentId !== pageBlock.id) {
    //   setPageBlock(getParentPageBlock(block))
    // } else {
    //   return
    // }
  }

  function getParentPageBlock(block: Block): Block | null {
    if(block && !block?.parentId) {return null}
    let parentPageBlock = currentPage!.blocks!.find((b: Block | null) => b?.id === block?.parentId)

    if(parentPageBlock && !parentPageBlock.isPage) {
      return getParentPageBlock(parentPageBlock)
    } else {
      return parentPageBlock || null
    }
  }  

  let value = {
    panel: store.panel!, 
    page: store.currentPage!,
    editorMode,
    changeEditorMode,
    setPanel: panelsSetPanel,
    publishPanel, 
    setPage: panelsSetPage,
    changePage,
    updatePage,
    removePage,
    pageBlock,
    setPageBlock,
    block, 
    setBlock, 
    showCardModal, 

    addBlock,
    updateBlock, 
    removeBlock, 
    changeCurrentBlock, 
    currentCard,
    currentCardId,
    currentCardPrimaryKey,
    selectCard,
    storeFormBlock,
    canEdit,
    updateBlocksPosition,
    getParentPageBlock,
    switchTab
  };

  return loading && !panel ? <BigSpinner /> : 
    <>
      <PanelContext.Provider value={value}>
        <PanelSubscriptionProvider panelId = {panel?.id!}>
          {children}
        </PanelSubscriptionProvider>
      </PanelContext.Provider>
      { openCardModal }
    </>
}

export function usePanel() {return React.useContext(PanelContext);}