import React, { useEffect } from "react";
import { BigSpinner } from "../../../components/loaders/index";
import { useSpaceStore } from "../../store/store";
import { BlockSourceTypeEnum, BlockTypeEnum, Card, ValuePayload, useBlockDataChangesSubscription } from "../../graphql/types";
import { mergeArrays } from "../../../utils";

interface BlockSubscriptionContextType {}

let BlockSubscriptionContext = React.createContext<BlockSubscriptionContextType>(null!);

export function BlockSubscriptionProvider({ blockId, boardId, children }: { blockId: string, boardId: string, children: React.ReactNode }) {
  const initType = "block"
  const init: string = `${initType}:${blockId}`
  const store = useSpaceStore()
  const {setCards, setValues} = store.boardActions
  const {block, fields, cards, values, relationCardId, currentCardId} = store.data[init]

  const [blockChanges] = useBlockDataChangesSubscription({variables: {blockId: blockId, boardId: boardId!, cardId: block.source.type === BlockSourceTypeEnum.Field || block.type === BlockTypeEnum.BlockForm ? relationCardId : currentCardId}});

  function reorderCards(card: Card) {
    let fromCardIndex = cards.findIndex((c: { id: string | undefined; }) => c.id === card?.id)
    let toCardIndex = card?.position!
    const newCards = [...cards];

    newCards.splice(toCardIndex, 0, newCards.splice(fromCardIndex, 1)[0]);
    newCards.map((row, idx) => row['position'] = idx)

    setCards(newCards, init)
  }

  console.log(blockChanges, blockId)

  // SUBSCRIPTIONS
  useEffect(() => {
    if(!blockChanges.data?.blockDataChanges) {
      return
    }

    switch (blockChanges.data?.blockDataChanges?.action!) {
      // case "createField":
      //   setFields([...fields, ...blockChanges.data?.blockDataChanges?.fields!] as Field[], init)
      //   setValues([...values, ...blockChanges.data?.blockDataChanges?.values!] as ValuePayload[], init)
      //   break;
      // case "updateField":
      //   updateViews(mergeArrays(views, blockChanges.data?.blockDataChanges?.views!) as BoardView[], init)
      //   setFields(mergeArrays(fields, blockChanges.data?.blockDataChanges?.fields!) as Field[], init)
      //   setValues(mergeArrays(values, blockChanges.data?.blockDataChanges?.values!) as ValuePayload[], init)
      //   break;
      // case "deleteField":
      //   updateViews(mergeArrays(views, blockChanges.data?.blockDataChanges?.views!) as BoardView[], init)
      //   let fields_ids = blockChanges.data?.blockDataChanges?.fields!.map((f: any) => f.id)
      //   setFields(fields.filter((f: any) => !fields_ids.includes(f.id)) as Field[], init)
      //   setValues(values.filter((v: any) => !fields_ids.includes(v.fieldId)) as ValuePayload[],init)
      //   break;
      // case "changeFieldPositions":
      //   setFields(blockChanges.data?.blockDataChanges?.fields! as Field[], init)
      //   break;
      // case "changeFieldWidth":
      //   setFields(mergeArrays(fields, blockChanges.data?.blockDataChanges?.fields!) as Field[], init)
      //   break;
      // case "changeFieldSummary":
      //   setFields(mergeArrays(fields, blockChanges.data?.blockDataChanges?.fields!) as Field[], init)
      //   break;
      case "createCard":
        setCards(blockChanges.data?.blockDataChanges?.cards! as Card[], init)
        setValues(blockChanges.data?.blockDataChanges?.values! as ValuePayload[], init)
        break;
      case "createCardWithValues":
        setCards(blockChanges.data?.blockDataChanges?.cards! as Card[], init)
        setValues(blockChanges.data?.blockDataChanges?.values! as ValuePayload[], init)
        break;
      case "updateCard":
        setValues(mergeArrays(values, blockChanges.data?.blockDataChanges?.values!) as ValuePayload[], init)
        break;
      case "deleteCard":
        setCards(blockChanges.data?.blockDataChanges?.cards! as Card[], init)
        setValues(blockChanges.data?.blockDataChanges?.values! as ValuePayload[], init)
        break;
      case "changeCardPosition":
        let card = blockChanges.data?.blockDataChanges?.cards![0]
        reorderCards(card!)
        break;
      case "addAttachments":
        setValues(mergeArrays(values, blockChanges.data?.blockDataChanges.values!) as ValuePayload[], init)
        break;
      case "removeAttachment":
        setValues(mergeArrays(values, blockChanges.data?.blockDataChanges.values!) as ValuePayload[], init)
        break;
      case "updateValues":
        setValues(mergeArrays(values, blockChanges.data?.blockDataChanges.values!) as ValuePayload[], init)
        break;

      case "boardDataFromServices":
        // setFields(blockChanges.data?.blockDataChanges?.fields! as Field[], init)
        setCards(blockChanges.data?.blockDataChanges?.cards! as Card[], init)
        setValues(blockChanges.data?.blockDataChanges?.values! as ValuePayload[], init)
        break;
      default:
        break;
    }
  },[blockChanges.data?.blockDataChanges])

  let value = {};

  return fields.length > 0 ? <BlockSubscriptionContext.Provider value={value}>{children}</BlockSubscriptionContext.Provider> : <BigSpinner />

}