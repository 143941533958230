import { useState } from 'react'
import { Tab } from '@headlessui/react';
import { range } from 'lodash';

import ResponsePanel from './response';
import MappingPanel from './mapping';
import { useFetcherContext } from '../../../../core/providers/fetcher';
import ResponseSettings from './response_settings';


export default function ResponseTabs() {
  const {response} = useFetcherContext()


  return (
    <div className='w-full h-full bg-white pb-10'>
      <Tab.Group>
        <Tab.List className="flex h-8 items-center px-4 overflow-hidden text-sm border-b">
          <Tab
            key="response_settings"
            className={({ selected }) => {
              return `h-full font-medium text-center focus:outline-none hover:text-slate-800 hover:bg-slate-300
              ${selected ? 'text-white bg-indigo-600' : 'text-slate-700 hover:bg-white/[0.12] '}`
            }}
          >
            <div className='px-3'>Настройки</div>
          </Tab>
          <Tab
            key="response_fields"
            className={({ selected }) => {
              return `flex-none h-full font-medium text-center focus:outline-none hover:text-slate-800 hover:bg-slate-300
              ${selected ? 'text-white bg-indigo-600' : 'text-slate-700 hover:bg-white/[0.12] '}`
            }}
          >
            <div className='px-3'>Доступные поля</div>
          </Tab>
          <Tab
            key="response_body"
            className={({ selected }) => {
              return `h-full font-medium text-center focus:outline-none hover:text-slate-800 hover:bg-slate-300
              ${selected ? 'text-white bg-indigo-600' : 'text-slate-700 hover:bg-white/[0.12] '}`
            }}
          >
            <div className='px-3'>Ответ</div>
          </Tab>
          <div className='flex w-full justify-end uppercase'>{response?.status}: {response?.code}  {response?.contentType}</div>
        </Tab.List>
        <Tab.Panels className="min-h-max h-full w-full overflow-y-scroll">
          <Tab.Panel><ResponseSettings /></Tab.Panel>
          <Tab.Panel><MappingPanel /></Tab.Panel>
          <Tab.Panel><ResponsePanel /></Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </div>
  )
}
