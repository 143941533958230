import { useRef, useState } from 'react';
import { RowHeightType, UserSpaceBoardView } from '../../../../core/graphql/types';
import { TbArrowAutofitHeight } from 'react-icons/tb';
import { MatrPopover } from '../../../ui/portal';
import { useBoard } from '../../../../core/providers/board';
import { useSpaceStore } from '../../../../core/store/store';


interface RoowHeightProps {
  onChange: any;
}

const rowHeights = [
  {label: "Узкая", value: RowHeightType.Short},
  {label: "Средняя", value: RowHeightType.Medium},
  {label: "Широкая", value: RowHeightType.Tall},
  {label: "Очень широкая", value: RowHeightType.Extra},
  {label: "По контенту", value: RowHeightType.Content}
]

function RowHeight({onChange}: RoowHeightProps) {
  const {initId, initType} = useBoard()
  const init: string = `${initType}:${initId}`
  const store = useSpaceStore()
  const {userCurrentView} = store.data[init];

  const [open, setOpen] = useState(false)
  const btnRef = useRef(null)

  function onClose() {setOpen(false)}

  function getIcon() {
    switch (userCurrentView?.options?.rowHeight) {
      case RowHeightType.Short: return <TbArrowAutofitHeight size={16}/>
      case RowHeightType.Medium: return <TbArrowAutofitHeight size={16}/>   
      case RowHeightType.Tall: return <TbArrowAutofitHeight size={16}/>   
      case RowHeightType.Extra: return <TbArrowAutofitHeight size={16}/>
      case RowHeightType.Content: return <TbArrowAutofitHeight size={16}/>  
      default: return <TbArrowAutofitHeight size={12}/>
    }
  }

  function handleChange(value: { label?: string; value: any; }) {
    onChange(value.value)
  }

  return (
    <>
      <button 
        ref={btnRef}
        className={`
        ${open ? 'bg-indigo-600 text-white hover:bg-indigo-700 hover:text-white ring-2 ring-indigo-300' : 'bg-slate-200 text-slate-700 hover:bg-slate-300 hover:text-slate-800'}
        h-7 text-sm font-medium flex items-center px-2 space-x-2 rounded-sm`}
        onClick={() => setOpen(true)}
        >
          {getIcon()}
      </button>
      {open && <MatrPopover onClose={onClose} reference={btnRef.current} placement="bottom-start">
        <div className='bg-white h-fit max-h-[32rem] min-w-[8rem] rounded-md border shadow-lg mt-2 py-1'>
          <ul className='flex flex-col w-full space-y-1 overflow-scroll'>
            {rowHeights.map((item, i) => (
              <li key={i} className="flex flex-col w-full rounded px-1" onClick={() => handleChange(item)}>
                <span className={`text-sm font-medium cursor-default px-2 rounded-sm
                 hover:bg-indigo-100 hover:text-indigo-600 py-1.5 ${item.value === userCurrentView?.options?.rowHeight ? "bg-indigo-600 text-white" : "text-slate-800"}`}
                >{item.label}</span>
              </li>
            ))}
          </ul>
        </div>
      </MatrPopover>}
    </>
  )
}

export default RowHeight;