import React, { useState } from "react";
import { TbDots, TbEdit, TbTrash } from "react-icons/tb";
import { useNavigate, useParams } from "react-router-dom";
import { Flow } from "../../../../core/graphql/types";
import { MatrBaseDialog, MatrMediumDialog, MatrPopover } from "../../../ui/portal";
import { hashCode, hexToRGB, intToRGB } from "../../../../utils";
import { FlowFormDelete } from "../../../../containers/Flows/form/delete";
import { ActionMenu } from "../../../Menus/ActionMenu";
import { FlowFormEdit } from "../../../../containers/Flows/form/edit";

export type ServicePreviewProps = {flow: Flow}

export const FlowPreview: React.FC<ServicePreviewProps> = ({flow}) => {
  let params = useParams()
  const active = params?.flow_id! === flow.id ? true : false
  const [ openMenu, setOpenMenu ] = useState(false);
  const [ buttonRef, setButtonRef ] = useState<any>()
  let navigate = useNavigate();
  const [ openDeleteModal, setOpenDeleteModal ] = useState(false);
  const [ openEditModal, setOpenEditModal ] = useState(false);

  const openService = () => {
    navigate(`/flows/${flow.id}`);
  }

  function onOpenMenu(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) { setOpenMenu(true)}
  function closeMenu() { setOpenMenu(false) };

  function openModalDelete() {setOpenDeleteModal(true)};
  function closeModalDelete() {setOpenDeleteModal(false)};

  function openModalEdit() {setOpenEditModal(true)};
  function closeModalEdit() {setOpenEditModal(false)};


  const menu = [
    {
      name: "Настройка",
      action: openModalEdit,
      icon: <TbEdit className="w-4 h-4"/>,
      disabled: false
    },
    {
      name: "Удалить",
      action: openModalDelete,
      icon: <TbTrash className="w-4 h-4"/>,
      disabled: false
    },
  ];


  return (
    <>
      <div className="flex w-full">
        <div className={`rounded-sm relative w-full cursor-pointer px-2 ${active ? "bg-indigo-600 text-white hover:bg-indigo-700 " : "text-slate-600 hover:bg-slate-200"}`}>
          <div className="relative flex items-center">
            <div className="w-full flex items-center" onClick={openService}>
              <div className="h-4 w-4 flex-none rounded-sm" style={{background: `${hexToRGB(intToRGB(hashCode(flow.name!)), 0.4)}`}}>
              </div>
              <span className="flex items-end px-2 py-1 text-base bg-opacity-0 hover:bg-opacity-100 text-left whitespace-pre-wrap w-full">{flow.name!}</span>
            </div>
            <button
              onClick={(e) => onOpenMenu(e)}
              ref={setButtonRef}
              className="h-5 w-5"
            >
              <TbDots />
            </button>
          </div>   
        </div>
      </div>
      {openMenu && 
        <MatrPopover onClose={closeMenu} reference={buttonRef} placement="bottom-end">
          <ActionMenu menu = {menu}/>
        </MatrPopover>
      }
      {openEditModal &&
        <MatrMediumDialog open = {openEditModal} onClose = {closeModalEdit}>
          <FlowFormEdit closeModal={closeModalEdit} flow={flow}/>
        </MatrMediumDialog>
      }
      {openDeleteModal &&
        <MatrBaseDialog open = {openDeleteModal} onClose = {closeModalDelete}>
          <FlowFormDelete closeModal={closeModalDelete} name={flow?.name!} id={flow?.id!}/>
        </MatrBaseDialog>
      }
    </>
  );
};
