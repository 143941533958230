import React, {useState} from "react";
import {SimpleModal} from "../../../../components/modals/simple";
import UpdateGroup from "../update";
import { HiTrash, HiFolder, HiFolderOpen, HiPencil } from "react-icons/hi";

const GroupPreview = ({group, onChange, onDelete, depth}:{group: any, onChange: any, onDelete: any, depth: number}) => {
  const [isExpanded, toggleExpanded] = useState(true);
  const [ openUpdateModal, setOpenUpdateModal ] = useState(false);

  function openModalUpdate() { setOpenUpdateModal(true)};
  function closeModalUpdate() { setOpenUpdateModal(false)};

  const handleEdit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    openModalUpdate()
  }

  const handleDelete = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>{
    e.stopPropagation();
    onDelete(group.id)
  } 

  if (group.children?.length > 0) {
    return (
      <div className="folder" key={`${depth}_${group.id}_f`} style={{paddingLeft: depth}}>
        {
          group.isRoot ? 
            <div className="py-3 w-full border-b rounded-sm flex justify-between items-center cursor-pointer focus:outline-none focus:ring-2 ring-offset-2 ring-offset-indigo-200 ring-white ring-opacity-60"  onClick={() => toggleExpanded(!isExpanded)}>
              <div>
                <div className="flex items-center"> {isExpanded ? <HiFolderOpen className="mr-2 text-indigo-800"/> : <HiFolder className="mr-2 text-indigo-600"/> } <span className="text-sm font-medium">{group.name}</span></div>
                <div className="text-xs text-slate-600">Количество досок в группе: {group.boardsCount}</div>
              </div>
            </div>
            :
            <>
              <div className="py-3 w-full border-b rounded-sm flex justify-between items-center cursor-pointer focus:outline-none focus:ring-2 ring-offset-2 ring-offset-indigo-200 ring-white ring-opacity-60" onClick={() => toggleExpanded(!isExpanded)}>
                <div>
                  <div className="flex items-center"> {isExpanded ? <HiFolderOpen className="mr-2 text-indigo-800"/> : <HiFolder className="mr-2 text-indigo-600"/> } <span className="text-sm font-medium">{group.name}</span></div>
                  <div className="text-xs text-slate-600">Количество досок в группе: {group.boardsCount}</div>
                </div>
                <div className="flex w-1/12 justify-end px-2 space-x-2">
                  <button className="flex w-6 h-6 bg-slate-200 rounded-md items-center justify-center" onClick={(e) => handleEdit(e)}>
                    <HiPencil className="w-3 h-3 text-slate-600"/>
                  </button>
                  <button className="flex w-6 h-6 bg-red-200 rounded-md items-center justify-center" onClick={(e) => handleDelete(e)}>
                    <HiTrash className="w-3 h-3 text-red-600"/>
                  </button>
                </div>
              </div>
              {openUpdateModal ? 
                <SimpleModal open = {openUpdateModal} close = {closeModalUpdate}>
                  <UpdateGroup closeModal={closeModalUpdate} onChange={onChange} group={group}/>
                </SimpleModal>
              : <></> }
            </> 
        }
        {
          isExpanded && group.children.map((item: any) => <GroupPreview key={`${depth}_${item.id}_fc`} group={item} onChange={onChange} onDelete={onDelete} depth={depth + 1}/>)
        }
      </div>
    )
  }

  return (
    <div key={`${depth}_${group.id}`} style={{paddingLeft: depth}}>
      {
      group.isRoot ? 
        <div className="py-3 w-full border-b rounded-sm flex justify-between items-center cursor-pointer focus:outline-none focus:ring-2 ring-offset-2 ring-offset-indigo-200 ring-white ring-opacity-60">
          <div>
            <div className="text-sm font-medium">{group.name}</div>
            <div className="text-xs text-slate-600">Количество досок в группе: {group.boardsCount}</div>
          </div>
        </div>
        :
        <>
          <div className="py-3 w-full border-b rounded-sm flex justify-between items-center cursor-pointer focus:outline-none focus:ring-2 ring-offset-2 ring-offset-indigo-200 ring-white ring-opacity-60">
            <div>
              <div className="text-sm font-medium">{group.name}</div>
              <div className="text-xs text-slate-600">Количество досок в группе: {group.boardsCount}</div>
            </div>
            <div className="flex w-1/12 justify-end px-2 space-x-2">
              <button className="flex w-6 h-6 bg-slate-200 rounded-md items-center justify-center" onClick={() => openModalUpdate()}>
                <HiPencil className="w-3 h-3 text-slate-600" />
              </button>
              <button className="flex w-6 h-6 bg-red-200 rounded-md items-center justify-center" onClick={(e) => handleDelete(e)}>
                <HiTrash className="w-3 h-3 text-red-600" />
              </button>
            </div>
          </div>
          {openUpdateModal ? 
            <SimpleModal open = {openUpdateModal} close = {closeModalUpdate}>
              <UpdateGroup closeModal={closeModalUpdate} onChange={onChange} group={group}/>
            </SimpleModal>
          : <></> }
        </>
      }
    </div>
  );
}

export default GroupPreview;