import create, { StateCreator } from 'zustand'
import { immer } from 'zustand/middleware/immer'
import { Block, Board, BoardView, Card, Constant, Field, Group, Role, Space, SpaceUser, ValuePayload, Fetcher, PanelInList, Account, UserSpaceBoardView, RowHeightType, Flow, Filter, ViewType, BoardViewPermissions, BoardViewOptions, BlockPermissions, BlockOptions, BlockGridOptions, BlockRecordsListOptions, BlockCalendarOptions, CardWithValues, Panel, Page, LinkItem, UserSpaceBlock, FetcherInListFragment, ForSelect, BlockKanbanOptions } from '../graphql/types'
import { Column } from 'react-data-grid';
import { Row } from '../../components/Views/MattrGrid/types';
import { transformValueToString } from '../Controllers/Base';
import { makeColumns, makeColumnsForBlock } from '../Controllers/Grid';
import { useAuth } from '../providers/auth';


export interface DataStore {
  initId: string | null,
  initType: string | null,
  editMode: boolean;
  boardIsLoading: boolean;
  board: Board | null;
  inBlock: boolean;
  block: Block | null;
  openCreateForm: boolean;
  currentCardId: string | null,
  currentCardPrimaryKey: string | null;
  relationCardId: string | null,
  relationPrimaryKey: string | null;
  canEditField: boolean;
  fieldModalIsOpened: boolean;
  cardModalIsOpened: boolean;
  externalModalIsOpened: boolean;
  views: BoardView[];
  currentView: BoardView | {type: ViewType, filters: Filter[], hiddenFields: string[], options: BlockGridOptions | BlockRecordsListOptions | BlockCalendarOptions | BlockKanbanOptions, permissions: BlockPermissions} | null;
  userCurrentView: UserSpaceBoardView | UserSpaceBlock;
  prevView?: BoardView | null;
  sources: Board[];
  users: SpaceUser[];
  fields: Field[];
  cards: Card[];
  values: ValuePayload[];
  columns: Column<any, any>[];
  rows: Row[];
  rowHeight: number;
  showModalAs: string,
  modal: JSX.Element | null;
  valueForUpdate: ValuePayload | null;
}

export const userViewInitial = {
  hiddenFields: [],
  filters: [],
  groups: [],
  sorts: [],
  appearance: [],
  options: {rowHeight: RowHeightType.Short}
}

export const dataInitialState = {
  initId: null,
  initType: null,
  editMode: false,
  boardIsLoading: true,
  board: null,
  inBlock: false,
  block: null,
  openCreateForm: false,
  currentCardId: null,
  currentCardPrimaryKey: null,
  relationCardId: null,
  relationPrimaryKey: null,
  canEditField: true,
  fieldModalIsOpened: false,
  cardModalIsOpened: false,
  externalModalIsOpened: false,
  constants: [],
  views: [],
  currentView: null,
  userCurrentView: userViewInitial,
  currentBlockView: null,
  prevView: null,
  sources: [],
  users: [],
  fields: [],
  cards: [],
  values: [],
  columns: [],
  rows: [],
  rowHeight: 34,
  showModalAs: "modal",
  modal: null,
  valueForUpdate: null
}

interface SpaceStore {
  space: Space;
  currentSpaceUser: Account | null;
  roles: Role[];
  boards: Board[] | [];
  services: FetcherInListFragment[];
  flows: Flow[];
  currentPage: Page | null;
  panel: Panel | null;
  panels: PanelInList[] | [];
  groups: Group[] | [];
  constants: Constant[] | [];
  data: Record<string, DataStore> | any;
  baseActions: BaseActions;
  boardActions: BoardActions;
  panelActions: PanelActions;
  spaceUsers: ForSelect[] | []; 
}

export type BaseActions = {
  actionSpaceCreate: (space: Space) => void;
  actionSpaceSet: (space: Space) => void;
  actionSpaceUpdate: (space: Space) => void;
  actionSpaceDelete: (space: Space) => void;
  actionRolesSet: (roles: Role[]) => void;
  actionRoleCreate: (role: Role) => void;
  actionRoleUpdate: (role: Role) => void;
  actionRoleDelete: (role: Role) => void;
  actionSpaceUsersSet: (spaceUsers: ForSelect[]) => void; 
  // actionInviteUserToSpace: (user: SpaceUser) => void;
  // actionRejectUserFromSpace: (user: SpaceUser) => void;
  actionConstantsSet: (constants: Constant[]) => void;
  // actionConstantCreate: (constant: Constant) => void;
  // actionConstantUpdate: (constant: Constant) => void;
  // actionConstantDelete: (constant: Constant) => void;
  actionGroupsSet: (groups: Group[]) => void;
  // actionGroupCreate: (group: Group) => void;
  // actionGroupUpdate: (group: Group) => void;
  // actionGroupDelete: (group: Group) => void;
  actionBoardsSet: (boards: Board[]) => void;
  // actionBoardCreate: (board: Board) => void;
  // actionBoardUpdate: (board: Board) => void;
  // actionBoardDelete: (board: Board) => void;
  // actionBoardImport: (board: Board) => void;
  actionPanelsSet: (panels: PanelInList[]) => void;
  actionPanelsUpdate: (panel: PanelInList) =>void;
  actionServicesSet: (services: FetcherInListFragment[]) => void;
  actionServicesUpdate: (service: FetcherInListFragment) => void;
  actionCurrentSpaceUserSet: (user: Account) => void;
  actionFlowsSet: (flows: Flow[]) => void;
  actionFlowCreate: (flow: Flow) => void;
  actionFlowUpdate: (flow: Flow) => void;
  actionFlowDelete: (id: string) => void;
  actionUserViewsSet: (user_view: UserSpaceBoardView) => void;
  actionUserBlocksSet: (user_block: UserSpaceBlock) => void;
  actionChangeSpaceArticle: (article: "board" | "panel" | "service" | "flow") => void;
}

export type BoardActions = {
  addBoardData: (obj: Record<string, DataStore>) => void; 
  addField: (field: Field, init: string) => void;
  addCard: (card: Card, init: string) => void;
  addUser: (user: SpaceUser, init: string) => void;
  addView: (view: BoardView, init: string) => void;
  updateView: (view: BoardView, init: string) => void;
  updateViews: (views: BoardView[], init: string) => void;
  deleteView: (view: BoardView, init: string) => void;
  switchView: (view: BoardView, init: string) => void;
  setEditMode: (state: boolean, init: string) => void;
  setBoard: (board: Board, init: string) => void;
  setBoardIsLoading: (state: boolean, init: string) => void;
  setViews: (views: BoardView[], init: string) => void;
  setCurrentView: (view: BoardView, init: string) => void;
  setUserCurrentView: (view: UserSpaceBoardView | UserSpaceBlock, init: string) => void;
  setConstants: (constants: Constant[], init: string) => void;
  setBoardUsers: (users: SpaceUser[], init: string) => void;
  setBoardSources: (sources: Board[], init: string) => void;
  setFields: (fields: Field[], init: string) => void;
  setCards: (cards: Card[], init: string) => void;
  setValues: (values: ValuePayload[], init: string) => void;
  setColumns: (columns: Column<Row>[], init: string) => void;
  setRows: (rows: Row[], init: string) => void;
  setUpdateContent: (state: boolean, init: string) => void;
  setValueForUpdate: (value: any, init: string) => void;
  updateSources: (boards: Board[], init: string) => void;
  updateValue: (value: ValuePayload, init: string) => void;
  updateValueInRow: (value: ValuePayload, rowIdx: number, init: string) => void;
  setCanEditField: (value: boolean, init: string) => void;
  setFieldModalIsOpened: (value: boolean, init: string) => void;
  setCardModalIsOpened: (value: boolean, init: string) => void;
  setExternalModalIsOpened: (value: boolean, init: string) => void;
  setRowHeight: (value: number, init: string) => void;
  setShowModalAs: (type: "modal" | "page", init: string) => void;
  setModal: (modal: JSX.Element, init: string) => void;
  setInBlock: (value: boolean, init: string) => void;
  setBlock: (block: Block, init: string) => void;
  // setRelationValue: (value:  LinkItem[] | null, init: string) => void;
  setOpenCreateForm: (value: boolean, init: string) => void;
  setCurrentCardId: (cardId: string | null, init: string) => void;
  setCurrentCardPrimaryKey: (key: string | null, init: string) => void;
  setCurrentCard: (card: Card | CardWithValues | null, init: string) => void;
  resetAllBoardData: () => void;
  resetDataItem: (init: string) => void;
}

export type PanelActions = {
  panelsSetPanel: (panel: Panel | null) => void;
  panelsUpdate: (panel: PanelInList) => void;
  panelsSetPage: (page: Page | null) => void;
  // panelsAddPanel: (panels: PanelInList) => void;
  // panelsUpdatePanel: (panel: Panel) => void;
  // panelsDeletePanel: (panel: Panel) => void;
  // panelsAddPage: (page: Page) => void;
  // panelsUpdatePage: (page: Page) => void;
  // panelsDeletePage: (page: Page) => void;
}

export const useSpaceStore = create<SpaceStore>()(
  immer<SpaceStore>((set, get) => ({
    space: {},
    currentSpaceUser: null,
    roles: [],
    spaceUsers: [],
    boards: [],
    services: [],
    flows: [],
    panels: [],
    panel: null,
    currentPage: null,
    groups: [],
    constants: [],
    data: {},
    baseActions: {
      actionSpaceCreate: (space) => set((state) => {state.space = space}),
      actionSpaceSet: (space) => set((state) => {state.space = space}),
      actionSpaceUpdate: (space) => set((state) => {state.space = space}),
      actionSpaceDelete: (space) => set((state) => {state.space = space}),
      actionRolesSet: (roles) => set((state) => {state.roles = roles}),
      actionRoleCreate: (role) => set((state) => {state.roles!.push(role)}),
      actionRoleUpdate: (role) => set((state) => {state.roles = [...state.roles.filter(r => r.id !== role.id), role]}),
      actionRoleDelete: (role) => set((state) => {state.roles.filter(r => r.id !== role.id)}),
      actionSpaceUsersSet: (spaceUsers) => set((state) => {state.spaceUsers = spaceUsers}),
      // actionInviteUserToSpace: (user: SpaceUser) => set((state) => {state.data[init].fields = {...state.data[init].fields, field}}),
      // actionRejectUserFromSpace: (user: SpaceUser) => set((state) => {state.data[init].fields = {...state.data[init].fields, field}}),
      actionConstantsSet: (constants: Constant[]) => set((state) => {state.constants = constants}),
      // actionConstantCreate: (constant: Constant) => set((state) => {state.data[init].fields = {...state.data[init].fields, field}}),
      // actionConstantUpdate: (constant: Constant) => set((state) => {state.data[init].fields = {...state.data[init].fields, field}}),
      // actionConstantDelete: (constant: Constant) => set((state) => {state.data[init].fields = {...state.data[init].fields, field}}),
      actionGroupsSet: (groups) => set((state) => {state.groups = groups}),
      // actionGroupCreate: (group: Group) => set((state) => {state.data[init].fields = {...state.data[init].fields, field}}),
      // actionGroupUpdate: (group: Group) => set((state) => {state.data[init].fields = {...state.data[init].fields, field}}),
      // actionGroupDelete: (group: Group) => set((state) => {state.data[init].fields = {...state.data[init].fields, field}}),
      actionBoardsSet: (boards: Board[]) => set((state) => {state.boards = boards}),
      // actionBoardCreate: (board: Board) => set((state) => {state.data[init].fields = {...state.data[init].fields, field}}),
      // actionBoardUpdate: (board: Board) => set((state) => {state.data[init].fields = {...state.data[init].fields, field}}),
      // actionBoardDelete: (board: Board) => set((state) => {state.data[init].fields = {...state.data[init].fields, field}}),
      // actionBoardImport: (board: Board) => set((state) => {state.data[init].fields = {...state.data[init].fields, field}}),
      actionPanelsSet: (panels: PanelInList[]) => set((state) => {state.panels = panels}),
      actionPanelsUpdate: (panel: PanelInList) => {
        let index = get().panels.findIndex(p => p.id === panel.id)
        set((state) => {state.panels[index] = panel})
      },
      actionCurrentSpaceUserSet: (user: Account) => set((state) => {state.currentSpaceUser = user}),
      actionServicesSet: (services: FetcherInListFragment[]) => set((state) => {state.services = services}),
      actionServicesUpdate: (service: FetcherInListFragment) => {
        let index = get().services.findIndex(s => s.id === service.id)
        set((state) => {state.services[index] = service})
      },
      actionFlowsSet: (flows) => set((state) => {state.flows = flows}),
      actionFlowCreate: (flow) => set((state) => {state.flows!.push(flow)}),
      actionFlowUpdate: (flow) => {
        let index = get().flows.findIndex(f => f.id === flow.id)
        set((state) => {state.flows[index] = flow})
      }, 
      actionFlowDelete: (id) => set((state) => {state.flows = state.flows!.filter(f => f.id != id)}),
      actionUserViewsSet: (user_view) => {
        let userViews = get().currentSpaceUser?.views
        if(userViews?.find(uv => uv?.viewId === user_view.viewId)) {
          let nuv = [...userViews.filter(uv => uv?.viewId !== user_view.viewId), user_view]
          set((state) => {state.currentSpaceUser!.views = nuv})
        } else {
          set((state) => {state.currentSpaceUser!.views = [...userViews!, user_view]})
        }
      },
      actionUserBlocksSet: (user_block) => {
        let userBlocks = get().currentSpaceUser?.blocks
        if(userBlocks?.find(uv => uv?.blockId === user_block.blockId)) {
          let nuv = [...userBlocks.filter(uv => uv?.blockId !== user_block.blockId), user_block]
          set((state) => {state.currentSpaceUser!.blocks = nuv})
        } else {
          set((state) => {state.currentSpaceUser!.blocks = [...userBlocks!, user_block]})
        }
      },
      actionChangeSpaceArticle: (article) => {

      }
    },
    boardActions: {
      addBoardData: (obj) => {
        set((state) => {state.data = {...state.data, ...obj}})
      },          
      addView: (view, init) => {
        set((state) => {state.data[init].views = [...state.data[init].views, view]})
        set((state) => {state.data[init].currentView = view})
      },
      updateView: (view, init) => {
        set((state) => {state.data[init].boardIsLoading = true})
        let views = get().data[init].views
        const newViews = views.map((obj: BoardView) => {
          if (obj.id === view.id) {
            return view;
          }
          return obj;
        });
        set((state) => {state.data[init].views = newViews})
        if(get().data[init].currentView.id === view.id) {
          set((state) => {state.data[init].currentView = view})
        }
        let userViews = get().currentSpaceUser?.views;
        if(userViews?.find(uv => uv?.viewId === view.id)) {
          let nuv = [...userViews.filter(uv => uv?.viewId !== view.id), {...userViewInitial, viewId: view.id}]
          set((state) => {state.currentSpaceUser!.views = nuv})
          set((state) => {state.data[init].userCurrentView =  {...userViewInitial, viewId: view.id}})
        } 
      },
      updateViews: (views, init) => {
        let cvi = get().data[init].currentView.id
        set((state) => {state.data[init].views = views})
        let ids = views.map(v => v.id)
        if(ids.includes(cvi)) {
          set((state) => {state.data[init].currentView = views.find(v => v.id === cvi)})
        }
      },
      deleteView: (view, init) => {
        let restViews = get().data[init].views.filter((v: BoardView) => v.id != view.id)
        let primaryView: BoardView = get().data[init].views.find((v: BoardView) => v.primary === true)
        set((state) => ({...state, views: restViews}))
        set((state) => ({...state, currentSpaceUser: {...state.currentSpaceUser, views: state.currentSpaceUser?.views?.filter(v => v?.viewId !== view.id)}}))
        set((state) => {state.data[init].views = restViews})
        set((state) => {state.data[init].currentView = primaryView})
        set((state) => {state.data[init].userCurrentView = state?.currentSpaceUser?.views?.find(v => v?.viewId === primaryView.id) ? state?.currentSpaceUser?.views?.find(v => v?.viewId === primaryView.id) : userViewInitial})
      },
      switchView: (view, init) => {
        let userView = get().currentSpaceUser?.views?.find(uv => uv?.viewId === view.id)
        set((state) => {state.data[init].currentView = view})
        if(userView) {
          set((state) => {state.data[init].userCurrentView = userView})
        } else {
          set((state) => {state.data[init].userCurrentView = userViewInitial})
        }
      },
      addField: (field, init) => set((state) => {state.data[init].fields = {...state.data[init].fields, field}}),
      addCard: (card, init) => set((state) => {state.data[init].cards = {...state.data[init].cards, card}}),
      addUser: (user, init) => set((state) => {state.data[init].users = {...state.data[init].users, user}}),
      setEditMode: (mode, init) => set((state) => {state.data[init].editMode = mode}),
      setBoard: (board, init) => set((state) => {state.data[init].board = board}),
      setBoardIsLoading: (isLoading, init) => set((state) => {state.data[init].boardIsLoading = isLoading}),
      setInBlock: (value, init) => set((state) => {state.data[init].inBlock = value}),
      // setRelationValue: (value, init) => set((state) => {state.data[init].relationValue = value}),
      setViews: (views, init) => set((state) => {state.data[init].views = views}),
      setCurrentView: (view, init) => set((state) => {state.data[init].currentView = view}),
      setUserCurrentView: (user_view, init) => set((state) => {state.data[init].userCurrentView = user_view}),
      setConstants: (constants, init) => set((state) => {state.data[init].constants = constants}),
      setBoardSources: (sources, init) => set((state) => {state.data[init].sources = sources}),
      setBoardUsers: (users, init) => set((state) => {state.data[init].users = users}),
      setFields: (fields, init) => { 
        set((state) => {state.data[init].fields = fields})
        // if(init.startsWith("board")) {
        //   let cv = get().data[init].currentView
        //   cv && set((state) => {state.data[init].columns = makeColumns(fields, cv!, get().currentSpaceUser!, get().data[init].board)}) 
        // } else {
        //   let cvb = get().data[init].currentBlockView
        //   cvb && set((state) => {state.data[init].columns = makeColumnsForBlock(fields, cvb!)})
        // }
      },
      setCards: (cards, init) => set((state) => {state.data[init].cards = cards}),
      setValues: (values, init) => { set((state) => {state.data[init].values = values}) },
      setColumns: (columns, init) => set((state) => {state.data[init].columns = columns}),
      setRows:  (rows, init) => { 
        set((state) => {state.data[init].rows = rows}) 
      },
      setUpdateContent: (content, init) => set((state) => {state.data[init].updateContent = content}),
      setValueForUpdate: (value, init) => set((state) => {state.data[init].valueForUpdate = value}),
      updateSources: (sources, init) => set((state) => {state.data[init].sources = sources}),
      updateValueInRow: (value, index, init) => {
        set((state) => {state.data[init].rows[index][value.fieldId] = value ? {...value, value: transformValueToString(value)} : value})
        // let rows = get().data[init].rows
        // for (const obj of rows) {
        //   if (obj.id === value.cardId) {
        //     // @ts-ignore
        //     obj[value.fieldId] = value ? {...value, value: transformValueToString(value)} : value;
        //     break;
        //   }
        // }

      },
      updateValue: (value, init) => {
        set((state) => 
          ({
            rows: state.data[init].rows.map((r: Row) => {
              if(r.id === value.cardId) {
                return { ...r, [value.fieldId]: value ? {...value, value: transformValueToString(value)} : value}
              } else {
                return r
              }
            })
          })
        )
      },
      setCanEditField: (value, init) => set((state) => {state.data[init].canEditField = value}),
      setFieldModalIsOpened: (value, init) => {
        set((state) => {state.data[init].modal = value === false ? null : state.data[init].modal})
        set((state) => {state.data[init].fieldModalIsOpened = value})
      },
      setCardModalIsOpened: (value, init) => {
        set((state) => {state.data[init].modal = value === false ? null : state.data[init].modal})
        set((state) => {state.data[init].editMode = value})
        set((state) => {state.data[init].cardModalIsOpened = value})
      },
      setExternalModalIsOpened: (value, init) => {
        set((state) => {state.data[init].modal = value === false ? null : state.data[init].modal})
        set((state) => {state.data[init].editMode = value})
        set((state) => {state.data[init].externalModalIsOpened = value})
      },
      setRowHeight: (value, init) => set((state) => {state.data[init].rowHeight = value}),
      setShowModalAs: (type, init) => set((state) => {state.data[init].showModalAs = type}),
      setModal: (value, init) => set((state) => {state.data[init].modal = value}),
      setBlock: (block, init) => set((state) => {state.data[init].block = block}),
      setOpenCreateForm: (value, init) => set((state) => {state.data[init].openCreateForm = value}),
      setCurrentCardId: (cardId, init) => set((state) => {state.data[init].currentCardId = cardId}),
      setCurrentCardPrimaryKey: (key, init) => set((state) => {state.data[init].currentCardPrimaryKey = key}),
      setCurrentCard: (card, init) => set((state) => {state.data[init].currentCard = card}),
      resetAllBoardData: () => set((state) => {state.data = {}}),
      resetDataItem: (init) => set((state) => {delete state.data[init]})
    },
    panelActions: {
      panelsSetPanel: (panel) => set((state) => {state.panel = panel}),
      panelsUpdate: (panel) => {
        let index = get().panels.findIndex(p => p.id === panel.id)
        set((state) => {state.panels[index] = panel})
      }, 
      panelsSetPage: (page) => set((state) => {state.currentPage = page}),
      // panelsAddPanel: (panels) => set((state) => {state.panels = panels}),
      // panelsUpdatePanel: (panel) => set((state) => {state.space = space}),
      // panelsDeletePanel: (panel) => set((state) => {state.space = space}),
      // panelsAddPage: (page) => set((state) => {state.roles = roles}),
      // panelsUpdatePage: (page) => set((state) => {state.roles = roles}),
      // panelsDeletePage: (page) => set((state) => {state.roles = roles}),
    }
  }
))
);
