import { useForm, Controller, NestedValue } from "react-hook-form";
import Avatar from 'react-avatar';
import Select from 'react-select';
import { ObjectForSelectOptions } from "../../../../core/types/global";
import { useQuery } from 'urql';
import { useSpaceUsersForSelectQuery, useDeleteUserFromBoardMutation, useInviteUserToBoardMutation, Board, SpaceUser} from "../../../../core/graphql/types"

import { SmallSpinner } from '../../../../components/loaders';
import { HiOutlineTrash } from 'react-icons/hi';
import { useEffect, useState } from "react";
import { useBoard } from "../../../../core/providers/board";
import { useSpaceStore } from "../../../../core/store/store";
import MatrSelect from "../../../../components/ui/select/base";
import { TbSquaresFilled, TbX } from "react-icons/tb";

interface BoardSettingsUsersProps {
  board: Board;
}

function BoardSettingsUsers({close}:{close: () => void}) {
  const {initId, initType} = useBoard()
  const init: string = `${initType}:${initId}`
  const store = useSpaceStore()
  const {board, users} = store.data[init];
  const {spaceUsers} = store;
  const {setBoardUsers} = store.boardActions;

  const { handleSubmit, control, formState: { errors } } = useForm();

  const [, inviteUserToBoard] = useInviteUserToBoardMutation();
  const [, deleteUserFromBoard] = useDeleteUserFromBoardMutation();

  const onSubmit = handleSubmit(data => {
    inviteUserToBoard({ userId: data?.user?.value!, boardId: board?.id! }).then(res => {
      setBoardUsers([...users, res.data?.inviteUserToBoard] as SpaceUser[], init)
    });
  });

  const handleDelete = (id: string | any) => {
    deleteUserFromBoard({ boardId: board?.id!, userId: id }).then(res => {
      setBoardUsers(users.filter((bu: SpaceUser) => bu.id !== res.data?.deleteUserFromBoard?.id!) as SpaceUser[], init)
    });
  };

  return (
    <>
      <div className="flex items-center justify-between mb-4">
        <div className="flex items-center space-x-2">
          <div className="py-1 px-2 bg-slate-100 flex space-x-2 rounded">
            <TbSquaresFilled size={20} className="text-indigo-600"/>
            <div className="flex font-medium text-sm">{board.name}</div>
          </div>
          <div className="flex font-normal text-sm">Управление участниками</div>
        </div>
        <button onClick={() => close()}><TbX size={18}/></button>
      </div>
      <form onSubmit={onSubmit} className="flex flex-row w-full">
        <div className="relative w-full text-left">
          <label className="font-medium text-gray-600 text-sm">Список участников пространства:</label>
          <div className="flex flex-row items-center w-full mt-2">
            <div className="w-full relative">
              <Controller
                name="user"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value, ref } }) => (
                  <MatrSelect
                    styles={{control: () => ({border: 0})}}
                    classNamePrefix="matr_select_custom"
                    placeholder="Выберите участника"
                    getOptionLabel ={(option)=>option?.name!}
                    getOptionValue ={(option)=>option?.id!}
                    value={value}
                    onChange={onChange}
                    options={spaceUsers} />
                )} />
            </div>
            <button type="submit" className="flex rounded-sm px-4 py-2 ml-4 font-medium text-base bg-indigo-600 text-white">Приглсить</button>
          </div>
          {errors.user && <span>This field is required</span>}
        </div>

      </form>
      <div className="flex mt-4 text-left text-sm text-gray-600 font-medium">Участники</div>
      <div className="flex mb-2 py-1 text-left text-xs text-gray-600">Участники могут редактировать содержимое таблиц</div>
      <div className="flex flex-col space-y-4 pb-2">
        {users.map((user: SpaceUser) => {
          return (
            <div key={user?.id} className="flex flex-row items-center w-full rounded focus:outline-none hover:ring-8 hover:ring-slate-100 hover:bg-slate-100">
              <div className="flex w-full items-center">
                <div className="mr-2">
                  <Avatar name={`${user?.firstName} ${user?.lastName}`} round size="30" className="font-medium" />
                </div>
                <div className="">
                  <div className="text-sm font-medium">
                    {`${user?.firstName} ${user?.lastName}`}
                  </div>
                </div>
              </div>
              <div className="flex justify-end">
                {user?.isOwner ? <div className="h-6"></div> :
                  <button className="flex w-6 h-6 bg-red-200 rounded-md items-center justify-center">
                    <HiOutlineTrash className="w-3 h-3 text-red-600" onClick={() => handleDelete(user?.id)} />
                  </button>}
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}

export default BoardSettingsUsers;