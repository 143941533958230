import { useState } from "react";
import type { FieldPrimitiveEditorProps } from './types';
import { StringValue} from "../../../core/graphql/types"
import { StringIcon } from "../../icons";


export default function FieldString({field, card, updatePrimitive}: FieldPrimitiveEditorProps) {
  const [value, setValue] = useState(card[field.id]?.valueAsString ? card[field.id].valueAsString : "")


  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    updatePrimitive(
      { ...card, [field.id]: {...card[field.id], valueAsString: e.target.value}}, 
      ({...card[field.id], valueAsString: e.target.value} as unknown as StringValue), 
      {valueAsString: e.target.value}
    )
    setValue(e.target.value)
  }

  return (
    <div className="relative flex flex-col space-y-1.5">
      <div className="flex items-center h-full">
        <div className="text-slate-400 pr-2"><StringIcon /></div>
        <label className="text-slate-500 text-sm">{field.title}</label>
      </div>
      <input className="matr-form-input" value={value} onChange={(e) => handleChange(e)} />
    </div>
  );
}