import { useState, useEffect } from "react";
import type { FormFieldEditorProps } from './types';
import {dateFormats, timeFormats} from "../../../core/enums";
import { DateTimeIcon } from "../../icons";
import { Controller, useFormContext } from 'react-hook-form';
import MatrFormDatePicker from "../../ui/datepicker/form";

export default function FormDateTimeEditor({formField}: FormFieldEditorProps) {
  const methods = useFormContext();
  const {control, formState: {errors}} = methods;
  const [dateFormat, setDateFormat] = useState<any>();
  const attr: any = formField.field.attributes;

  useEffect(() => {
    let curdf = dateFormats.find(format => format.name === attr.dateFormat)
    let curtf = timeFormats.find(format => format.name === attr.timeFormat)

    if (attr.includeTime) {
      setDateFormat(curdf?.spec_format + ' ' + curtf?.spec_format)
    } else {
      setDateFormat(curdf?.spec_format)
    }
  },[])

  return (
    <div className="relative flex flex-col">
      <div className="flex items-center h-full space-x-2">
        <div className='text-slate-400'><DateTimeIcon /></div>
        <label className="font-medium text-indigo-600 text-sm">{formField.name} {formField.required ? <span className="text-red-600">*</span> : ""}</label>
      </div>
      <div className="flex mt-2">
        <Controller
          key={formField.id}
          name={`${formField.fieldId}`}
          control={control}
          rules={{required: formField.required}}
          render={({ field: {value, onChange} }) => (
            <MatrFormDatePicker 
              selected = {value}
              showTimeInput = {attr.includeTime}
              dateFormat = { dateFormat }
              onChange = {(e) => onChange(e)}
            />
          )}
        />
      </div>
    </div>
  );
}