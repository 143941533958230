import {useState, useCallback, ChangeEvent, useEffect, useRef } from "react";
import { Controller, useFieldArray, useForm, useFormContext, useWatch } from "react-hook-form";
import { NodeField, MattrTypes, NodeFieldFieldTypeEnum, Field } from "../../../../../core/graphql/types";
import { Node as ReactFlowNode } from "reactflow";
import NumberFormat from "react-number-format";
import MatrNarrowDatePicker from "../../../../../components/ui/datepicker/narrow";
import LinkNodeFieldDropDown from "./LinkNodeFieldDropDown";
import { debounce } from "debounce";
import useDeepCompareEffect from "use-deep-compare-effect";
import { useFlowContext } from "../../../../../core/providers/flow";
import { TbX } from "react-icons/tb";
import { BiToggleLeft, BiToggleRight } from "react-icons/bi";
import { deepEqual, valueFromString, valueToString } from "../../../../../core/Controllers/Base";
import FormFieldInput, { FormStringFieldInput } from "../../../../../components/Forms/Fields/Inputs";
import { useSpace } from "../../../../../core/providers/space";
import { DateTime } from "luxon";


export default function NodeFields({nodeFields}:{nodeFields: any}) {
  const {nodes, edges, node, updateNodeFieldValue} = useFlowContext()
  const methods = useForm({defaultValues: {internal_fields: nodeFields}})
  const {control, watch, getValues, setValue, formState} = methods;
  const { fields } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "internal_fields", // unique name for your Field Array
  });
  const [fieldIndex, setFieldIndex] = useState<number | null>(null)
  const [sourceNodes, setSourceNodes] = useState<ReactFlowNode[]>([])

  useEffect(() => {
    setValue('internal_fields', nodeFields.map((nf: any) => valueFromString(nf, nf.valueType)))
  }, [nodeFields])

  useEffect(() => {
    let parentNodes: any[] = edges.filter(edge => edge.target === node.id).map(edge => ({id: edge.source, status: edge.sourceHandle}))
    let preNodes = parentNodes.map(pn => { 
      let nod = nodes.find(n => pn.id === n.id)!
      let fields = nod.data.fields.filter((f: NodeField) => f.status === pn.status)
      return {...nod, data: {...nod.data, fields: fields}}
    }).flat()
    setSourceNodes(preNodes)
  }, [edges, node])

  const debouncedSave = useCallback(
    debounce(() => {
      let updated_value = getValues(`internal_fields.${fieldIndex}`)
      updateNodeFieldValue(valueToString(updated_value, updated_value.valueType))
      setFieldIndex(null)
    }, 1000),
    [fieldIndex]
  );

  const watchedData = useWatch({
    control: control,
    defaultValue: {internal_fields: nodeFields.map((nf: any) => valueFromString(nf, nf.valueType))}
  });

  useDeepCompareEffect(() => {
    if (!deepEqual(watchedData, {internal_fields: nodeFields.map((nf: any) => valueFromString(nf, nf.valueType))})) {
      debouncedSave()
    } else {
      debouncedSave.clear()
    }
  }, [watchedData]);


  return (
    <>
      {fields.map((field, index) => (
        watch(`internal_fields.${index}.contextType`) === "EMPTY" ? <></> : 
        <div key={field.id} className="flex flex-col pb-2 space-y-1.5">
          <NodeFieldTitle index={index} methods={methods} updateFieldIndex={setFieldIndex}/>
          <NodeFieldValue index={index} methods={methods} sourceNodes={sourceNodes} updateFieldIndex={setFieldIndex}/>
          <NodeFieldDescription index={index} methods={methods} updateFieldIndex={setFieldIndex}/>
        </div>
      ))}
    </>
  )
}



interface NodeFieldProps {
  index: number, 
  methods: any, 
  updateFieldIndex: (index: number) => void
}

interface NodeFieldValueProps extends NodeFieldProps {
  sourceNodes: ReactFlowNode[],
}


const NodeFieldTitle = ({index, methods, updateFieldIndex}:NodeFieldProps) => {
  const {control, getValues} = methods;
  let internalField = getValues(`internal_fields.${index}`)

  function handleChangeCheckbox(value: any, onChange: { (...event: any[]): void; (arg0: any): void; }, index: number) {
    onChange(!value)
    updateFieldIndex(index)
  }

  return (
    <div className="relaitve mt-2">
      <div className="flex justify-between items-center">
        <label className="matr-label w-full">{internalField?.name}</label>
        {internalField?.required ? <></> :
        <Controller
          name={`internal_fields.${index}.active`}
          control={control}
          render={({ field: { onChange, value, ref }}) => (
            <div 
              className={`flex items-center  rounded-md font-normal  cursor-default`} 
              onClick={() => handleChangeCheckbox(value, onChange, index)}
            >
              
              {value! ? 
                <div className="flex items-center w-full">
                  <span className="text-xxs text-blue-600 mr-3">Изменять</span>
                  <BiToggleRight className='h-6 w-6 text-blue-600'/> 
                </div>
              : 
                <div className="flex items-center w-full">
                  <span className="text-xxs text-slate-400 mr-3 whitespace-nowrap">Не изменять</span>
                  <BiToggleLeft className='h-6 w-6 text-slate-400'/>
                </div>
              }
            </div>
          )}
        />}
      </div>
    </div>
  )
}

const NodeFieldDescription = ({index, methods, updateFieldIndex}:NodeFieldProps) => {
  const {getValues} = methods;
  let internalField = getValues(`internal_fields.${index}`)


  return (
    internalField.description ? 
    <div className="text-xxs text-slate-400">{internalField.description}</div>
    :
    <></>
  )
}

const NodeFieldValue = ({index, methods, updateFieldIndex, sourceNodes}:NodeFieldValueProps) => {
  const {boards} = useSpace()
  const {getValues: getNodeValues} = useFormContext()
  const {control, watch, getValues, setValue} = methods;
  let internalField = watch(`internal_fields.${index}`)

  function getSourceNodeFieldName() {
    let acc: any[] = []
    sourceNodes.map(n => acc.push(n.data.fields))
    let field = acc.flat().filter(f => f.id === internalField.nodeFieldId)
    if(field.length === 1) {
      return field[0].name
    } else {
      return ""
    }
  }

  function handleChangeValue(e: any, onChange: (...event: any[]) => void, index: number | null | undefined) {
    onChange(e)
    updateFieldIndex(index!)
  } 

  function handleChangeNodeField(value: string, valueType: MattrTypes, onChange: (...event: any[]) => void, index: number) {
    if(valueType === MattrTypes.Function) {
      setValue(`internal_fields.${index}.nodeFieldId`, null)
      setValue(`internal_fields.${index}.valueType`, valueType)
      setValue(`internal_fields.${index}.value`, value)
    } else {
      setValue(`internal_fields.${index}.nodeFieldId`, value)
      setValue(`internal_fields.${index}.valueType`, valueType)
    }
    updateFieldIndex(index)
  } 

  function handleResetNodeField(index: number) {
    setValue(`internal_fields.${index}.nodeFieldId`, null)
    setValue(`internal_fields.${index}.valueType`, getValues(`internal_fields.${index}.contextType`))
    setValue(`internal_fields.${index}.value`, null)
    updateFieldIndex(index)
  }

  function valueFormInput() {
    if(internalField.nodeFieldId) {
      return (
        <div  className="flex-1 bg-indigo-600 text-white text-sm px-2 h-7 rounded-sm flex justify-between items-center">
          {getSourceNodeFieldName()} 
          <TbX className="text-white" onClick={() => handleResetNodeField(index)}/>
        </div>
      ) 
    }

    if(internalField.valueType === MattrTypes.Function) {
      return (
        <div  className="flex-1 bg-indigo-600 text-white text-sm px-2 h-7 rounded-sm flex justify-between items-center">
          Функция: {internalField.value} 
          <TbX className="text-white" onClick={() => handleResetNodeField(index)}/>
        </div> 
      ) 
    }

    let fieldOptions = {
      methods: methods,
      attributes: boards.find(b => b.id == getNodeValues('boardId'))?.fields?.find(f => f?.id === internalField.fieldId)?.attributes,
      boardId: getNodeValues('boardId'),
      fieldId: internalField.fieldId,
      value: internalField.value, 
      autoFocus: false,
      name: `internal_fields.${index}.value`,
      index: index,
      narrow: true,
      placeholder: "",
      disabled: !internalField.active,
      required: false,
      handleChange: handleChangeValue
    }

    return ( 
      <div className="relative w-full">
        <FormFieldInput 
          type={internalField.valueType}
          fieldOptions={fieldOptions}
        />
      </div>

    )
  }

  return (
    <div className="flex w-full space-x-2 items-start">
      {watch(`internal_fields.${index}.active`) ? 
        <>
          {valueFormInput()}
      
          <div className="flex w-7 h-7 rounded-sm bg-slate-200 items-center justify-center">
            <Controller 
              name={`internal_fields.${index}.nodeFieldId`}
              control={control}
              render={({ field: {value, onChange} }) => 
                <LinkNodeFieldDropDown value={value} contextType={watch(`internal_fields.${index}.contextType`)} onChange={(e: any, valueType: MattrTypes) => handleChangeNodeField(e, valueType, onChange, index)} sourceNodes={sourceNodes}/>
              }
            />
          </div>
        </>
        :
        <div className="text-base h-7 w-full test-slate-600">
          Поле не будет изменено.
        </div>
      }

    </div>
  )
}