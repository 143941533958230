import { NodeOutputTypesEnum } from "../../../../core/graphql/types";
import { useFlowContext } from "../../../../core/providers/flow";
import ActionCreateCard from "./actions/actionCreateCard";
import ActionCreateNotification from "./actions/actionCreateNotification";
import ActionSendEmail from "./actions/actionSendEmail";
import ActionSendTelegram from "./actions/actionSendTelegram";
import ActionUpdateCard from "./actions/actionUpdateCard";

export default function NodeOutputEdit() {
  const {node} = useFlowContext()

  switch (node.data?.options?.outputNodeType! as NodeOutputTypesEnum) {
    case NodeOutputTypesEnum.CreateCard: return <ActionCreateCard />
    case NodeOutputTypesEnum.UpdateCard: return <ActionUpdateCard />
    case NodeOutputTypesEnum.CreateNotification: return <ActionCreateNotification />
    case NodeOutputTypesEnum.SendTelegramMessage: return <ActionSendTelegram />
    case NodeOutputTypesEnum.SendEmail: return <ActionSendEmail></ActionSendEmail>
    default: return <></>
  }
}