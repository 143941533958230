/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import { Controller, useForm } from "react-hook-form";
import toast from 'react-hot-toast';
import { Flow, useCreateFlowMutation } from "../../../../core/graphql/types";
import { useSpace } from "../../../../core/providers/space";
import { useNavigate } from "react-router-dom";
import { TbSquaresFilled, TbX } from "react-icons/tb";


interface FlowCreateProps {
    closeModal: () => void;
}


export const FlowFormCreate: React.FC<FlowCreateProps> = ({closeModal}) => {
    const {space, actionFlowCreate} = useSpace()
    const navigate = useNavigate()
    const { register, handleSubmit, control, formState: { errors } } = useForm({defaultValues: {name: "", description: ""}})
    const [,createFlow] = useCreateFlowMutation()


    const onSubmit = handleSubmit(data => {
        toast.promise(
                createFlow({name: data.name, description: data.description}).then(result => {
                    actionFlowCreate({
                        name: result.data?.createFlow?.name, 
                        id: result.data?.createFlow?.id, 
                        active: result.data?.createFlow?.active,
                        mode: result.data?.createFlow?.mode,
                        description: result.data?.createFlow?.description
                    } as Flow)
                    closeModal();
                    return result
            }),
        {
            loading: 'Создаем новый процесс...',
            success: (data) => {
                if (data.error) throw new Error();
                navigate(`/flows/${data.data?.createFlow?.id}`);
                return 'Процесс создан';
            },
            error: 'Ошибка. Не удалось создать процесс',
        }
        );
    });


    return (
        <>
            <div className="flex items-center justify-between">
                <div className="flex items-center space-x-2">
                <div className="py-1 px-2 bg-slate-100 flex space-x-2 rounded">
                    <TbSquaresFilled size={20} className="text-indigo-600"/>
                    <div className="flex font-medium text-sm">{space?.name}</div>
                </div>
                <div className="flex font-normal text-sm">Создание процесса</div>
                </div>
                <button onClick={() => closeModal()}><TbX size={18}/></button>
            </div>
            <form onSubmit={onSubmit}>
                <div className="relative flex flex-col space-y-1.5 pt-4">
                    <input 
                        {...register("name", {required: true})} 
                        className="text-xl font-medium text-slate-800 placeholder:text-slate-300 focus:outline-none mb-4" 
                        placeholder="Название процесса"
                    />
                    <Controller
                        name="description"
                        control={control}
                        render={({ field: { onChange, value, ref }}) => (
                        <textarea {...register("description", { required: false })} 
                        placeholder="Описание процесса" 
                        className={`matr-input h-[100px]`}
                        />
                        )}
                    />
                </div>
                <div className="flex justify-between w-full pt-6">
                    <button
                    type="button"
                    className="inline-flex justify-end px-3 py-1.5 text-sm font-medium text-indigo-700 bg-indigo-100 border border-transparent rounded-md hover:bg-indigo-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-indigo-500"
                    onClick={closeModal}
                    >Отменить</button>
                    <button
                    type="submit"
                    className="inline-flex justify-center px-3 py-1.5 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md hover:bg-indigo-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-indigo-500"
                    >Создать</button>
                </div>
            </form>
        </>
    )
};