import { useEffect, useState } from "react";
import { Column } from "react-data-grid";
import { Row } from "../types";
import type { RenderCellProps, RenderEditCellProps } from 'react-data-grid';
import { Field, StringValue } from '../../../../core/graphql/types';
import SummaryRows from "../rows/summary";
import { GroupCell } from "../rows/group";
import { HeaderRenderer } from "../rows/header";
import { useBoard } from "../../../../core/providers/board";
import { useSpaceStore } from "../../../../core/store/store";



interface RenderStringCellProps<TRow, TSummaryRow> extends RenderCellProps<TRow, TSummaryRow> {
  field: Field;
}

interface RenderEditStringCellProps<TRow, TSummaryRow> extends RenderEditCellProps<TRow, TSummaryRow> {
  field: Field;
}

function autoFocusAndSelect(input: HTMLInputElement | null) {
  input?.focus();
  input?.select();
}

// Column

export function stringColumn({ field, canEditInline }: { field: Field; canEditInline: boolean }): Column<Row, any> {
  return {
    key: field.id,
    name: field.title,
    width: field.width,
    resizable: true,
    frozen: field.primary ? true : false,
    renderHeaderCell: (p) => { return <HeaderRenderer field={field} {...p}/> },
    renderCell: (p) => { return <StringCell {...p} field={field}/> },
    renderEditCell: (p) => { return canEditInline ? <StringCellEditor {...p} field={field}/> : <></>},
    renderGroupCell: (p) => { return <GroupCell {...p} field={field}/> },
    renderSummaryCell: (p) => { return <SummaryRows {...p} field={field}/> },
    editorOptions: {
      displayCellContent: !canEditInline
    }
  }
};

// Cell

export function StringCell<Row, SummaryRow>({row, column}: RenderStringCellProps<Row, SummaryRow>) {
  const value = (row[column.key as keyof Row] as unknown as StringValue)?.valueAsString!
  return (
    <div className="flex h-full px-2 py-2 text-sm items-start">{value}</div>
  );
}

// CellEditor

export function StringCellEditor<Row, SummaryRow>({field, row, column, onRowChange, onClose}: RenderEditStringCellProps<Row, SummaryRow>) {
  const [value, setValue] = useState((row[column.key as keyof Row] as unknown as StringValue)?.valueAsString!)

  const {initId, initType} = useBoard();
  const init: string = `${initType}:${initId}`;
  const store = useSpaceStore()
  const {setEditMode, } = store.boardActions
  const {rowHeight} = store.data[init];


  useEffect(() => {
    setEditMode(true, init)
  },[])

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setValue(e.target.value)
    onRowChange({ ...row, [column.key]: {...row[column.key as keyof Row], valueAsString: e.target.value}})
  }

  function handleBlur() {
    onClose(true)
  }

  return (
    <input
      autoFocus
      className={`w-full leading-5 text-sm focus:outline-none p-2`}
      style={{paddingBottom: rowHeight - 28}}
      ref={autoFocusAndSelect}
      value={value ? value : ""}
      onFocus={() => setEditMode(true, init)}
      onChange={(e) => handleChange(e)}
      onBlur={() =>  handleBlur()}
    />
  );
}

