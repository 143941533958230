import { UserNotification, useAcceptSpaceInvitationMutation, useRejectSpaceInvitationMutation, useDeleteNotificationMutation, DeleteFromSpace } from "../../../../core/graphql/types"

interface DeleteFromSpaceNotificationProps {
  data: UserNotification
  onRemove: (id: string) => void
}

export default function DeleteFromSpaceNotification({data, onRemove}:DeleteFromSpaceNotificationProps) {
  const [,remove] = useDeleteNotificationMutation()

  function deleteNotification() {
    remove({id: data.id!}).then(res => {
      if(!res.data || res.error) {return}
      res.data.deleteNotification?.status === "ok" && onRemove(data.id!) 
    })
  }

  return (
    <>
      <div className="flex w-full font-medium h-6">Вы были отключены от простанства</div> 
      <div className="flex w-full items-center justify-between">
        <div className="flex w-full">
          <span className="font-meduim">{(data.resource as DeleteFromSpace).content}</span>
        </div>
        <div className="flex space-x-2">
          <button onClick={() => deleteNotification()} className="px-2 py-1.5 rounded-md bg-indigo-600 text-white hover:bg-indigo-700 hover:text-white" type="button">Закрыть</button>
        </div>
      </div>
    </>
  )
}