import React, { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import Select, { MultiValue } from "react-select";
import toast from 'react-hot-toast';

import { ForSelect, useCreateGroupMutation, useListGroupsQuery, useRolesForSelectQuery } from "../../../../core/graphql/types";

export default function CreateGroup({closeModal, onChange}:{closeModal: any, onChange: any}) {
  const { register, handleSubmit, watch, control, setValue, formState: { errors } } =  useForm();
  const [{data, fetching, error}] = useListGroupsQuery({requestPolicy: "network-only"});
  const [{data: dataRoles, fetching: fetchingRoles, error: errorRoles}] = useRolesForSelectQuery();

  const [,createGroup] = useCreateGroupMutation();

  useEffect(() => {
    setValue("addable", true)
  }, [])

  useEffect(() => {
    if(!dataRoles?.rolesForSelect) {
      return
    }
    setValue("roles", [dataRoles?.rolesForSelect?.find(r => r?.name === "Administrator")?.id])
  }, [dataRoles?.rolesForSelect])

  useEffect(() => {
    if(!data?.listGroups) {
      return
    }
    setValue("parent", data?.listGroups?.find(g => g?.isRoot === true))
  },[data?.listGroups])

  const onSubmit = handleSubmit(data => {
    createGroup({
      name: data.name, 
      parentId: data.parent.id, 
      addable: data.addable,
      roles: data.addable ? [] : data.roles
    }).then(res => {
      onChange(res.data?.createGroup)
      closeModal();
    })
  });

  const handleChangeRoles = (e: MultiValue<ForSelect | null>, onChange: (...event: any[]) => void) => {
    onChange(e.map(r => r?.id))
  }

  return (
    <form onSubmit={onSubmit} className="flex flex-col relative w-full space-y-4 p-6">
      <h2 className="text-lg font-semibold">Создание новой группы</h2>
      <div className="text-xs text-gray-600">Придумайте название для группы досок. Это позволит Вам структурировать ваше пространство.</div>
      <div>
        <label className="font-medium text-gray-600 text-sm">Название:</label>
        <input {...register("name", { required: true })}
          className="text-sm form-input mt-2 py-2.5 block w-full rounded-sm bg-gray-100 border-0 focus:bg-white focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
        />
        {errors.name && <span className="text-red-600 text-xs font-normal">"Название" обязательное поле.</span>}
      </div>
      <div className="relative">
        <Controller
          name="parent"
          control={control}
          rules={{ required: true }}
          render={({ field: { onChange, value, ref } }) => (
            <Select
              styles={{control: () => ({border: 0})}}
              classNamePrefix="matr_select_custom"
              placeholder="В группе"
              getOptionLabel={(option) => option?.name}
              getOptionValue ={(option)=>option.id}
              value={value}
              onChange={onChange}
              options={data?.listGroups ? data.listGroups : []} />
          )} />
      </div>
      <div className="relative inline-flex">
        <label className="font-medium text-gray-600 text-sm flex items-center">
          <input 
            {...register("addable")}
            className="form-checkbox rounded-sm focus:outline-none focus:outline-offset-0 mr-2"
            type="checkbox" 
            placeholder="Добавление в группу" 
          />
          Все роли могут добавлять в группу
        </label>
      </div>
      {watch("addable") === false ?
      <div className="relative">
        <Controller
          name="roles"
          control={control}
          render={({ field: { onChange, value, ref } }) => (
            <Select
              styles={{control: () => ({border: 0})}}
              isMulti={true}
              isClearable={false}
              classNamePrefix="matr_select_custom"
              placeholder="Роли"
              value={dataRoles?.rolesForSelect?.filter(r => value.includes(r?.id))}
              getOptionLabel ={(option)=>option?.name!}
              getOptionValue ={(option)=>option?.id!}
              onChange={(e) => handleChangeRoles(e, onChange)}
              options={dataRoles?.rolesForSelect ? dataRoles.rolesForSelect : []} />
          )} />
      </div> : <></>}
      <div className="flex justify-between w-full pt-6">
        <button
          type="button"
          className="inline-flex justify-end px-4 py-2 text-sm font-medium text-indigo-700 bg-indigo-100 border border-transparent rounded-md hover:bg-indigo-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-indigo-500"
          onClick={closeModal}
        >
          Отменить
        </button>
        <button
          type="submit"
          className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md hover:bg-indigo-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-indigo-500"
        >
          Создать
        </button>
      </div>
    </form>
  )
}