import { useState } from "react";
import useDeepCompareEffect from "use-deep-compare-effect";
import MattrGrid from '../../../../../components/Views/MattrGrid';
import { SmallSpinner } from "../../../../../components/loaders";
import { MatrMediumDialog } from "../../../../../components/ui/portal";
import { Block, BlockGridOptions, BlockSourceBlock, BlockSourceBoard, BlockSourceField, Board, ViewType } from "../../../../../core/graphql/types";
import { BoardProvider } from '../../../../../core/providers/board';
import { MattrDataProvider } from '../../../../../core/providers/data';
import { usePanel } from "../../../../../core/providers/panel";
import { BlockSubscriptionProvider } from '../../../../../core/providers/subscriptions/block';
import { DataStore, dataInitialState, useSpaceStore, userViewInitial } from "../../../../../core/store/store";
import { BlockEdit } from "../Forms/editBlock";
import { BlockGridActions } from './actions';


export function GridBlockWithField({block}:{block: Block}) { 
  const {editorMode} = usePanel()
  const init = `${"block"}:${block.id}`
  const formInit = `${"block"}:${block.parentId}`
  const store = useSpaceStore()
  const {addBoardData, resetDataItem} = store.boardActions
  const storeData = store.data

  const [ openSettings, setOpenSettings ] = useState(false);
  const { currentCardId, currentCardPrimaryKey } = storeData[formInit]

  function onOpenSettings(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) { setOpenSettings(true)}
  function closeSettings() { setOpenSettings(false) };

  useDeepCompareEffect(() => {
    resetDataItem(init)
    let newObject: Record<string, DataStore> = {}
    newObject[init] = {
      ...dataInitialState,
      block: block,
      currentCardId: null,
      currentCardPrimaryKey: null,
      relationCardId: currentCardId,
      relationPrimaryKey: currentCardPrimaryKey,
      board: {id: (block.source as BlockSourceBoard | BlockSourceBlock | BlockSourceField).boardId!} as Board,
      currentView: {
        type: ViewType.Grid, 
        filters: (block.source as any)?.filters, 
        hiddenFields: (block.source as any)?.hiddenFields,
        options: (block.options as BlockGridOptions),
        permissions: block.permissions!
      },
      rowHeight: 36,
      inBlock: true,
      initId: block.id!,
      showModalAs: "modal", 
      initType: "block", 
      boardIsLoading: false,
      userCurrentView: store.currentSpaceUser?.blocks?.find(cub => cub?.blockId === block.id) ? store.currentSpaceUser?.blocks?.find(cub => cub?.blockId === block.id)! : userViewInitial
    }

    addBoardData(newObject)
  },[block, currentCardId])



  return (
    <>
      {block.isPage && editorMode &&
        <div className="flex flex-row w-full justify-end px-4 py-2">
          <button 
            className="bg-slate-200 rounded text-slate-400 flex px-2 py-1 text-sm font-medium hover:bg-indigo-600 hover:text-white" 
            onClick={(e) => onOpenSettings(e)}
            >
              Настройка
          </button>
        </div>
      }
      {(init in storeData) ? 
        <>
          <MattrDataProvider initiator={{id: block.id!, type: "block"}}>
            <BlockSubscriptionProvider blockId={block.id!} boardId={(block.source as BlockSourceBoard).boardId!}>
              <BoardProvider>
                <div className="flex flex-col w-full mx-auto h-full overflow-hidden max-h-[40rem] min-h-[16rem] ">
                  <BlockGridActions initiator={{type: "block", id: block.id!}}/>
                  <div className="flex-1 overflow-scroll w-full h-full relative border rounded-sm">
                    <MattrGrid/>
                  </div>
                </div>
              </BoardProvider>
            </BlockSubscriptionProvider>
          </MattrDataProvider>
          {openSettings && 
            <MatrMediumDialog open={openSettings} onClose={closeSettings}>
              <BlockEdit block={block} onClose={closeSettings}/>
            </MatrMediumDialog>
          }
        </>
      :
        <SmallSpinner />}
    </>
  )
}


export function GridBlock({block}:{block: Block}) { 
  const {editorMode} = usePanel()
  const init = `${"block"}:${block.id}`
  const store =  useSpaceStore()
  const {addBoardData, resetDataItem} = store.boardActions
  const storeData = store.data
  const [ openSettings, setOpenSettings ] = useState(false);

  function onOpenSettings(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) { setOpenSettings(true)}
  function closeSettings() { setOpenSettings(false) };


  useDeepCompareEffect(() => {
    resetDataItem(init)
    let newObject: Record<string, DataStore> = {}
    newObject[init] = {
      ...dataInitialState,
      block: block,
      board: {id: (block.source as BlockSourceBoard | BlockSourceBlock | BlockSourceField).boardId!} as Board,
      currentView: {
        type: ViewType.Grid, 
        filters: (block.source as any)?.filters, 
        hiddenFields: (block.source as any)?.hiddenFields,
        options: (block.options as BlockGridOptions),
        permissions: block.permissions!
      },
      userCurrentView: store.currentSpaceUser?.blocks?.find(cub => cub?.blockId === block.id) ? store.currentSpaceUser?.blocks?.find(cub => cub?.blockId === block.id)! : userViewInitial,
      rowHeight: 36,
      inBlock: true,
      initId: block.id!,
      showModalAs: "modal", 
      initType: "block", 
      boardIsLoading: false
    }

    addBoardData(newObject)
  },[block])

  return (
    <>
      {block.isPage && editorMode &&
        <div className="flex flex-row w-full justify-end px-4 py-2">
          <button 
            className="bg-slate-200 rounded text-slate-400 flex px-2 py-1 text-sm font-medium hover:bg-indigo-600 hover:text-white" 
            onClick={(e) => onOpenSettings(e)}
            >
              Настройка
          </button>
        </div>
      }
      {(init in storeData) ? 
        <>
          <MattrDataProvider initiator={{id: block.id!, type: "block"}}>
            <BlockSubscriptionProvider blockId={block.id!} boardId={(block.source as BlockSourceBoard).boardId!}>
              <BoardProvider>
                <div className={`flex flex-col w-full mx-auto h-full overflow-hidden ${block.isPage ? "min-h-full max-h-full mt-4" : "min-h-[32rem] max-h-[32rem]"}`}>
                  <BlockGridActions initiator={{type: "block", id: block.id!}}/>
                  <div className="flex-1 overflow-scroll w-full h-full relative">
                    <MattrGrid/>
                  </div>
                </div>
              </BoardProvider>
            </BlockSubscriptionProvider>
          </MattrDataProvider>
          {openSettings && 
            <MatrMediumDialog open={openSettings} onClose={closeSettings}>
              <BlockEdit block={block} onClose={closeSettings}/>
            </MatrMediumDialog>
          }
        </>
      :
        <SmallSpinner />}
    </>
  )
}

