import { Node, NodeOutputTypesEnum, NodeTriggerTypesEnum, NodeTypesEnum } from "../../../../core/graphql/types"
import { useFlowContext } from "../../../../core/providers/flow"
import NodeTriggerEdit from "./NodeTriggerEdit"
import NodeRecordEdit from "./NodeRecordEdit"
import NodeRequestEdit from "./NodeRequestEdit"
import NodeSourceEdit from "./NodeSourceEdit"
import NodeLoopRepeatEdit from "./NodeLoopRepeatEdit"
import NodeLoopForEdit from "./NodeLoopForEdit"
import NodeOutputEdit from "./NodeOutputEdit"
import NodeLoopCollectEdit from "./NodeLoopCollectEdit"
import { Tab } from "@headlessui/react"
import { TbArrowsJoin, TbArrowsSplit, TbInfoSquare, TbSettings, TbSquareCheck, TbSquaresFilled, TbTrash, TbX } from "react-icons/tb"
import NodeInputData from "./data/NodeInputData"
import NodeOutputData from "./data/NodeOutputData"
import { useForm, Controller, FormProvider, useWatch } from "react-hook-form"
import * as Yup from 'yup';
import { ChangeEvent, useCallback, useState } from "react"
import { debounce } from "debounce";
import useDeepCompareEffect from "use-deep-compare-effect"
import { deepEqual } from "../../../../core/Controllers/Base"


const validationSchemaTrigger = Yup.object().shape({
  name: Yup.string().required(),
  type: Yup.mixed().required().default(NodeTypesEnum.Trigger),
  triggerType: Yup.mixed().oneOf([NodeTriggerTypesEnum.CardCreated, NodeTriggerTypesEnum.CardCreatedWithValues, NodeTriggerTypesEnum.CardUpdated, NodeTriggerTypesEnum.CardInConditions, NodeTriggerTypesEnum.CardInView, NodeTriggerTypesEnum.BySchedule]).defined().required("Событие обязательное поле"),
  boardId: Yup.string().when('triggerType', { is: NodeTriggerTypesEnum.CardCreated || NodeTriggerTypesEnum.CardCreatedWithValues || NodeTriggerTypesEnum.CardUpdated || NodeTriggerTypesEnum.CardInConditions || NodeTriggerTypesEnum.CardInView, 
    then: (schema) => schema.nullable(false).required(),
    otherwise: (schema) => schema.nullable(true),
  }),
  viewId: Yup.string().when('triggerType', { is: NodeTriggerTypesEnum.CardInView, 
    then: (schema) => schema.nullable(false),
    otherwise: (schema) => schema.nullable(true),
  }),
  filters: Yup.array().when('triggerType', { is: NodeTriggerTypesEnum.CardInConditions, 
    then: (schema) => schema.nullable(false).min(1),
    otherwise: (schema) => schema.nullable(true),
  }),
  schedule: Yup.object().when('triggerType', { is: NodeTriggerTypesEnum.BySchedule, 
    then: (schema) => schema.nullable(false),
    otherwise: (schema) => schema.nullable(true),
  })
});

const validationSchemaSource = Yup.object().shape({
  name: Yup.string().required(),
  type: Yup.mixed().required().default(NodeTypesEnum.Source),
  boardId: Yup.string().required()
});

const validationSchemaRequest = Yup.object().shape({
  name: Yup.string().required(),
  fetcherId: Yup.string().required(),
  requestId: Yup.string().required()
});

const validationSchema = Yup.object().shape({
  name: Yup.string().required()
});

const validationSchemaCreateCard = Yup.object().shape({
  name: Yup.string().required(),
  boardId: Yup.string().required()
});

const validationSchemaUpdateCard = Yup.object().shape({
  name: Yup.string().required(),
  boardId: Yup.string().required(),
  updateByCondition: Yup.boolean().required(),
  updateByFieldId: Yup.string().when('updateByCondition', { is: true, 
    then: (schema) => schema.nullable(false),
    otherwise: (schema) => schema.nullable(true),
  }),
  nodeFieldId: Yup.string().required()
});

const validationSchemaCreateNotification = Yup.object().shape({
  name: Yup.string().required(),
});

const validationSchemaSendTelegramMessage = Yup.object().shape({
  name: Yup.string().required(),
});

const validationSchemaSendEmail = Yup.object().shape({
  name: Yup.string().required(),
});

export default function NodeEdit({onClose}:{onClose: () => void}) {
  const {node, flow, deleteNode, updateNode} = useFlowContext()
  const defaultValues =  {name: node.data.name, ...node.data.options}
  const methods = useForm({defaultValues: defaultValues})
  const {control, getValues, watch, formState} = methods
  const [error, setError] = useState(null);

  const debouncedSave = useCallback(
    debounce(() => {
      updateNode(getValues())
    }, 1000),
    []
  );

  const watchedData = useWatch({
    control: control,
    defaultValue: defaultValues
  });

  useDeepCompareEffect(() => {
      switch ((node.data as Node).options?.type) {
        case NodeTypesEnum.Trigger:
          validationSchemaTrigger.isValid(getValues()).then(res => {
            if(res) {
              setError(null)
              !deepEqual(watchedData, defaultValues) && debouncedSave() 
            } else {
              validationSchemaTrigger.validate(getValues()).catch(res => setError(res.message))
              debouncedSave.clear()
            }
          })
          break;

        case NodeTypesEnum.Source:
          validationSchemaSource.isValid(getValues()).then(res => {
            if(res) {
              setError(null)
              !deepEqual(watchedData, defaultValues) && debouncedSave()
            } else {
              validationSchemaSource.validate(getValues()).catch(res => setError(res.message))
              debouncedSave.clear()
            }
          })
          break;

        case NodeTypesEnum.Request:
          validationSchemaRequest.isValid(getValues()).then(res => {
            if(res) {
              setError(null)
              !deepEqual(watchedData, defaultValues) && debouncedSave()
            } else {
              validationSchemaRequest.validate(getValues()).catch(res => setError(res.message))
              debouncedSave.clear()
            }
          })
          break;

        case NodeTypesEnum.Output:
          switch (node.data?.options?.outputNodeType) {
            case NodeOutputTypesEnum.CreateCard:
              validationSchemaCreateCard.isValid(getValues()).then(res => {
                if(res) {
                  setError(null)
                  !deepEqual(watchedData, defaultValues) && debouncedSave()
                } else {
                  validationSchemaCreateCard.validate(getValues()).catch(res => setError(res.message))
                  debouncedSave.clear()
                }
              })
              break;
            case NodeOutputTypesEnum.UpdateCard:
              validationSchemaUpdateCard.isValid(getValues()).then(res => {
                if(res) {
                  setError(null)
                  !deepEqual(watchedData, defaultValues) && debouncedSave()
                } else {
                  validationSchemaUpdateCard.validate(getValues()).catch(res => setError(res.message))
                  debouncedSave.clear()
                }
              })
              break;
            case NodeOutputTypesEnum.CreateNotification:
              validationSchemaCreateNotification.isValid(getValues()).then(res => {
                if(res) {
                  setError(null)
                  !deepEqual(watchedData, defaultValues) && debouncedSave()
                } else {
                  validationSchemaCreateNotification.validate(getValues()).catch(res => setError(res.message))
                  debouncedSave.clear()
                }
              })
              break;
            case NodeOutputTypesEnum.SendTelegramMessage:
              validationSchemaSendTelegramMessage.isValid(getValues()).then(res => {
                if(res) {
                  setError(null)
                  !deepEqual(watchedData, defaultValues) && debouncedSave()
                } else {
                  validationSchemaSendTelegramMessage.validate(getValues()).catch(res => setError(res.message))
                  debouncedSave.clear()
                }
              })
              break;
            case NodeOutputTypesEnum.SendEmail:
              validationSchemaSendEmail.isValid(getValues()).then(res => {
                if(res) {
                  setError(null)
                  !deepEqual(watchedData, defaultValues) && debouncedSave()
                } else {
                  validationSchemaSendEmail.validate(getValues()).catch(res => setError(res.message))
                  debouncedSave.clear()
                }
              })
              break;
          
            default:
              break;
          }
          break;
        default:
          validationSchema.isValid(getValues()).then(res => {
            if(res) {
              setError(null)
              !deepEqual(watchedData, defaultValues) && debouncedSave()
            } else {
              validationSchemaTrigger.validate(getValues()).catch(res => setError(res.message))
              debouncedSave.clear()
            }
          })
          break;
      }
  }, [watchedData]);


  function handleChangeNodeName(e: ChangeEvent<HTMLInputElement>, onChange: (...event: any[]) => void) {
    onChange(e)
  }

  function getNodeEditPage(type: NodeTypesEnum) {
    switch (type.toUpperCase()) {
      case NodeTypesEnum.Trigger: return <NodeTriggerEdit />
      case NodeTypesEnum.Record: return <NodeRecordEdit />
      case NodeTypesEnum.Request: return <NodeRequestEdit />
      case NodeTypesEnum.Source: return <NodeSourceEdit />
      case NodeTypesEnum.LoopRepeat: return <NodeLoopRepeatEdit />
      case NodeTypesEnum.LoopFor: return <NodeLoopForEdit />
      case NodeTypesEnum.LoopCollect: return <NodeLoopCollectEdit />
      case NodeTypesEnum.Output: return <NodeOutputEdit />
  
      default: return <></>
    }
  }


  function showInputs(type: string) {
    switch (type.toUpperCase()) {
      case NodeTypesEnum.Trigger: return false    
      default: return true
    }
  }

  function showDelete(type: string) {
    switch (type.toUpperCase()) {
      case NodeTypesEnum.Trigger: return false    
      default: return true
    }
  }

  function onDeleteNode(id: string) {
    deleteNode(id)
    onClose()
  }


  return (
    <>
      <div className="flex items-center justify-between">
        <div className="flex items-center space-x-2">
          <div className="py-1 px-2 bg-slate-100 flex space-x-2 rounded">
            <TbSquaresFilled size={20} className="text-indigo-600"/>
            <div className="flex font-medium text-sm">{flow.name}</div>
          </div>
          <div className="flex font-normal text-sm">Настройка процесса</div>
        </div>
        <button onClick={() => onClose()}><TbX size={18}/></button>
      </div>
      <div className="h-8 flex flex-row items-center justify-between">
        <span className="text-base font-medium text-slate-600">Блок:</span>
        <div className={`flex w-fit items-center text-xs rounded h-6 pl-1 pr-2 py-1 gap-1 ${error ? "bg-red-400" : "bg-green-400"}`}>
          {error ? <TbInfoSquare size={16} className="text-red-700"/> : <TbSquareCheck size={16} className="text-green-700"/>}
          {error ? <span className="text-red-700">Есть ошибки</span> : <span className="text-green-700">Без ошибок</span>}
        </div>
      </div>
      <div className="relative mb-4">
        <Controller
          name="name"
          control={control}
          rules={{required: true}}
          render={({ field: { onChange, value }}) => (
            <input 
              value={value ? value : ""}
              onChange={(e) => handleChangeNodeName(e, onChange)}
              className="text-xl w-full font-medium text-slate-800 placeholder:text-slate-300 focus:outline-none" 
            />
          )}
        />
        {formState.errors.name && <span className="text-red-600 text-xs font-normal">"Название блока" обязательное поле.</span>}
      </div>
      <Tab.Group>
        <Tab.List className="text-base border-b">
          <Tab >{({ selected }) => (<div className={`px-3 py-1.5  font-medium ${selected ? "text-indigo-600 border-b-2 border-indigo-600" : "text-slate-600"} `}>Настройки</div>)}</Tab>
          {showInputs(node?.type!) && <Tab >{({ selected }) => (<div className={`px-3 py-1.5  font-medium ${selected ? "text-indigo-600 border-b-2 border-indigo-600" : "text-slate-600"} `}>Входные данные</div>)}</Tab>}
          <Tab >{({ selected }) => (<div className={`px-3 py-1.5  font-medium ${selected ? "text-indigo-600 border-b-2 border-indigo-600" : "text-slate-600"} `}>Выходные данные</div>)}</Tab>
        </Tab.List>
        <Tab.Panels className="flex-1 w-full h-full flex flex-col">
          { node?.id && 
            <Tab.Panel className="h-full min-h-[4rem] w-full">
              <FormProvider {...methods}>
                {getNodeEditPage(node.data.type)}
              </FormProvider>
            </Tab.Panel>}
          {showInputs(node?.type!) && <Tab.Panel className="h-full w-full"><NodeInputData /></Tab.Panel>}
          <Tab.Panel className="h-full w-full"><NodeOutputData /></Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
      <div className="flex-none h-16 flex items-center">
        {showDelete(node?.type!) ? 
          <button type="button" onClick={() => onDeleteNode(node.id)} className="flex bg-red-600 text-white px-2 rounded space-x-2 py-2">
            <TbTrash size={18} />
            <div className="text-sm">Удалить блок</div>
          </button>
          :
          <div className="text-sm text-slate-400">Данный блок нельзя удалить, он является стартом процесса</div>
        }
      </div>
    </>
  )
} 