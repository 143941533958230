import type { FormFieldEditorProps } from './types';
import Select from "react-select";
import { SelectIcon } from "../../icons";
import { Controller, useFormContext } from 'react-hook-form';
import MatrSelect from '../../ui/select/base';


export default function FormSelectorEditor({formField, value}: FormFieldEditorProps) {
  const methods = useFormContext();
  const {control} = methods;
  const attrs: any = formField?.field?.attributes

  return (
    <div className="relative flex flex-col">
      <div className="flex items-center h-full space-x-2">
        <div className="text-slate-400"><SelectIcon /></div>
        <label className="font-medium text-indigo-600 text-sm">{formField.name} {formField.required ? <span className="text-red-600">*</span> : ""}</label>
      </div>
      <div className="flex w-full mt-2">
        <Controller
          key={formField.id}
          name={`${formField.fieldId}`}
          defaultValue={value ? attrs.options.find((o: any) => o.id === value.id): {}}
          control={control}
          rules={{required: formField.required}}
          render={({ field }) => (
            <MatrSelect 
              {...field}
              placeholder="Выберите..."
              isClearable={true}
              isSearchable={false}
              getOptionLabel ={(option)=>option.name!}
              getOptionValue ={(option)=>option.id!}
              options={attrs.options ? attrs.options : []}
            />
          )}
        />
      </div>
    </div>
  );
}