import { ChangeEvent, useState } from "react";
import { TbEye, TbEyeOff, TbSearch } from "react-icons/tb";
import { Field } from "../../../../core/graphql/types";

interface FieldsForCardListProps {
  fields: Field[];
  selection: string[] | [];
  handleChange: any;
  handleHideAll: any;
  handleShowAll: any;
}


function FieldsForCardList({fields, selection, handleChange, handleHideAll, handleShowAll}: FieldsForCardListProps) {
  const [search, setSearch] = useState("")
  const [filteredFields, setFilterdFields] = useState<Field[]>(fields)

  function searchField(e: ChangeEvent<HTMLInputElement>) {
    setSearch(e.target.value)
    let ff = fields.filter((field: Field) => 
      field.title.toLocaleLowerCase().search(e.target.value.toLocaleLowerCase()) >= 0
    )
    setFilterdFields(ff)
  }

  return (
    <>
      <div className="flex flex-row w-full items-center border-b pb-1">
          <div className="px-2 text-slate-400 ">
            <TbSearch size={16} />
          </div>
          <div className='text-sm w-full'>
            <input value={search} placeholder="Найти поле" onChange={(e) => searchField(e)} className="text-base focus:outline-none"/>
          </div>
          <div className="flex flex-row h-full px-1">
            <button className='flex h-full text-slate-400 px-1.5 py-1.5 hover:text-indigo-600 transition duration-200' onClick={() => handleShowAll()}><TbEye size={16}/></button>
            <button className='flex h-full text-slate-400 px-1.5 py-1.5 hover:text-indigo-600 transition duration-200' onClick={() => handleHideAll()}><TbEyeOff size={16}/></button>
          </div>
      </div>
      <div className="flex flex-col max-h-96 py-2 overflow-auto">
        {filteredFields.map((f: Field) => (
          <div 
            key={f.id}
            className={`flex items-center py-1.5 pl-2 pr-3 text-base ${selection.some(s => s === f.id) ? "text-slate-800" : "text-slate-600"} rounded hover:bg-slate-100 font-medium justify-between w-full cursor-default`} 
            onClick={() => handleChange(f.id)}
          >
            {f.title}
            {selection.some(s => s === f.id) ? <TbEyeOff size={16} className='text-green-800'/> : <TbEye size={16}/>}
          </div>
        ))}
      </div>
    </>
  )
}

export default FieldsForCardList;