/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import toast from 'react-hot-toast';
import { useForm, Controller, FormProvider, FieldValues } from "react-hook-form";

import Select, { MultiValue, SingleValue } from "react-select";
import { useSpace } from "../../../core/providers/space";
import MatrSelect from "../../../components/ui/select/base";
import { PanelInList, Role, useCreatePanelMutation } from "../../../core/graphql/types";
import { pageLayouts } from "../../../core/enums";
import PanelFormInfo from "./steps/panelFormInfo";
import PageFormLayout from "./steps/pageLayout";
import PageFormOptions from "./steps/pageOptions";
import { TbSquaresFilled, TbX } from "react-icons/tb";


export const PanelFormCreate = ({close}:{close: any}) => {
  const navigate = useNavigate()
  const {panels, actionPanelsSet, space} = useSpace()
  const methods = useForm();
  const { handleSubmit, formState: { errors } } = methods;
  const [, createPanel] = useCreatePanelMutation();
  const [step, setStep] = useState("panelInfo")


  const onSubmit = handleSubmit(data => {
    if(step === "panelInfo") {
      setStep("pageLayout")
      return
    }

    if(step === "pageLayout") {
      setStep("pageOptions")
      return
    }

    if(step === "pageOptions") {
      savePanel(data)
    }
  });

  function savePanel(data: FieldValues) {
    toast.promise(
      createPanel({name: data.name, roles: data.roles, pageLayout: data.pageLayout, pageName: data.pageName, pageOptions: data.pageOptions}).then(result => {
        if(result.error) {
          return result
        }
        actionPanelsSet([...panels,  result.data?.createPanel! as PanelInList])
        return result
      }),
      {
        loading: 'Создаем новую панель...',
        success: (data) => {
          if (data.error) throw new Error();
          close();
          navigate(`/${data.data?.createPanel?.pages![0]?.id!}`)
          return 'Панель создана';
        },
        error: 'Ошибка. Не удалось создать панель',
      }
    );
  }



  return (
    <>
      <div className="flex items-center justify-between">
        <div className="flex items-center space-x-2">
          <div className="py-1 px-2 bg-slate-100 flex space-x-2 rounded">
            <TbSquaresFilled size={20} className="text-indigo-600"/>
            <div className="flex font-medium text-sm">{space?.name}</div>
          </div>
          <div className="flex font-normal text-sm">Создание нового раздела</div>
        </div>
        <button onClick={() => close()}><TbX size={18}/></button>
      </div>

      <FormProvider {...methods}>
        <form onSubmit={onSubmit} className="flex flex-col relative w-full space-y-4">
          {step === "panelInfo" && <PanelFormInfo />}
          {step === "pageLayout" && <PageFormLayout newPanel={true}/>}
          {step === "pageOptions" && <PageFormOptions />}
          <div className="flex w-full h-full justify-end pt-4 space-x-4">
            <button
              type="button"
              className="rounded text-sm py-2 px-4 bg-slate-200 text-slate-400 hover:bg-slate-300 hover:text-slate-500"
              onClick={close}
            >Отменить</button>
            <button
              type="submit"
              className="rounded text-sm py-2 px-4 bg-indigo-600 text-white hover:bg-indigo-700"
            >{step === "pageOptions" ? "Сохранить" : "Продолжить"}</button>
          </div>
        </form>
      </FormProvider>
    </>
  )
};