import {useEffect, useState} from "react";
import { SmallSpinner } from "../../../components/loaders";
import { Group, useListGroupsQuery, useDeleteGroupMutation } from "../../../core/graphql/types";
import { SimpleModal } from "../../../components/modals/simple"
import CreateGroup from './create';
import GroupPreview from './preview';


export function SpaceSettingsGroups() {
  const [open, setOpen] = useState(false);
  const [groups, setGroups] = useState<any>([])

  const [{ fetching, error, data }, getGroups] = useListGroupsQuery();
  const [,deleteGroup] = useDeleteGroupMutation()

  function openModal() { setOpen(true)}
  function closeModal() { setOpen(false)}

  useEffect(() => {
    if(!data?.listGroups) {
      return
    }
    const idMapping = data?.listGroups.reduce((acc, el, i) => {
      //@ts-ignore
      acc[el?.id!] = i;
      return acc;
    }, {});

    let root;
    data?.listGroups.forEach(el => {
      // Handle the root element
      if (el?.parentId === null) {
        root = el;
        return;
      }

      // Use our mapping to locate the parent element in our data array
      //@ts-ignore
      const parentEl = data?.listGroups[idMapping[el.parentId]];
      // Add our current el to its parent's `children` array
      
      if(!parentEl) {
        //@ts-ignore
        let rootEl = data?.listGroups.find(re => re?.isRoot === true)
        //@ts-ignore
        rootEl.children = [...(rootEl.children || []), el];
      } else {
        //@ts-ignore
        parentEl.children = [...(parentEl.children || []), el];
      }
    });

    setGroups(root)
  },[data?.listGroups])

  function onCreate(group: Group) {
    getGroups()
    // setGroups([...groups, ...[group]])
  }

  function onUpdate(group: Group) {
    getGroups()
    // let objIndex = groups.findIndex(obj => obj.id === group.id);
    // setGroups([...groups.slice(0, objIndex), group, ...groups.slice(objIndex + 1)] as Group[])
  }

  function onDelete(id: string) {
    deleteGroup({id: id}).then(res => {
      getGroups()
      // setGroups(groups.filter(g => g.id !== res.data?.deleteGroup?.id))
    })
  }

  if (error) return <>`Error! ${error.message}`</>;

  return (
    <>
      <section className="w-full">
        <div className="w-full flex justify-between items-center">
          <h4 className="text-xl font-semibold">Группы</h4>
          <button
            type="button"
            onClick={openModal}
            className="px-4 py-2 text-sm font-medium text-white bg-indigo-600 rounded-md hover:bg-indigo-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
          >
            Создать
          </button>
        </div>
        <p className="text-sm text-gray-500 leading-8 mb-6 mt-4">Список доступных ролей для пространства. Создавайте свои роли и разграничиваете права доступа.</p>
        <div className="relative w-full py-4">
          { fetching ? <SmallSpinner/> : <GroupPreview group={groups} onChange={onUpdate} onDelete={onDelete} depth={0}/>}
        </div>
      </section>
      { open ? 
        <SimpleModal open = {open} close = {closeModal}>
          <CreateGroup closeModal={closeModal} onChange={onCreate}/>
        </SimpleModal>
        : <></>
      }
    </>
  );
}