import { useEffect, useState } from "react";
import { Field, StringValue, Maybe, JsonValue, JsonArrayValue, DatetimeValue, RelationValue, LinkItem, useStoreValueWithResponseMutation, useAddRelationWithValuesMutation, useRemoveRelationWithValuesMutation, useAddAttachmentsWithValuesMutation, useRemoveAttachmentWithValuesMutation } from "../../../core/graphql/types";

import FieldString from "../Editors/FieldString";
import FieldCheckbox from "../Editors/FieldCheckbox";
import FieldNumber from "../Editors/FieldNumber";
import FieldDecimal from "../Editors/FieldDecimal";
import FieldPercent from "../Editors/FieldPercent";
import FieldCurrency from "../Editors/FieldCurrency";
import FieldSelector from "../Editors/FieldSelector";
import FieldMultiSelector from "../Editors/FieldMultiSelector";
import FieldCollaborator from "../Editors/FieldCollaborator";
import FieldRelation from "../Editors/FieldRelation";
import { FieldFormula } from "../Editors/FieldFormula";
import { FieldInsertedAt } from "../Editors/FieldInsertedAt";
import { FieldUpdatedAt } from "../Editors/FieldUpdatedAt";
import { FieldCreatedBy } from "../Editors/FieldCreatedBy";
import { FieldUpdatedBy } from "../Editors/FieldUpdatedBy";
import { FieldLookup } from "../Editors/FieldLookup";
import FieldDateTime from "../Editors/FieldDateTime";
import FieldUrl from "../Editors/FieldUrl";
import FieldEmail from "../Editors/FieldEmail";
import FieldDuration from "../Editors/FieldDuration";
import FieldPhone from "../Editors/FieldPhone";
import FieldTextarea from "../Editors/FieldTextarea";
import FieldAttachment from "../Editors/FieldAttachment";

interface CardEditProps {
  row: any;
  fields: any;
}

export default function CardEdit({row, fields}: CardEditProps) {
  const [card, _] = useState(row)
  const [timer, setTimer] = useState<ReturnType<typeof setTimeout>>();
  const [, storeValue] = useStoreValueWithResponseMutation();
  const [, addRelationMutation] = useAddRelationWithValuesMutation()
  const [, removeRelationMutation] = useRemoveRelationWithValuesMutation()
  const [, addAttachments] = useAddAttachmentsWithValuesMutation()
  const [, removeAttachment] = useRemoveAttachmentWithValuesMutation()


  function saveValueWithTimeout(row: any, cell: any, value: any) {
    if(timer) clearTimeout(timer);
    setTimer(setTimeout(() => 
      storeValue({
        id: cell.id,
        cardId: cell.cardId,
        fieldId: cell.fieldId,
        boardId: cell.boardId,
        type: cell.type,
        valueAsString: value.valueAsString,
        valueAsBool: value.valueAsBool,
        valueAsNumber: value.valueAsNumber,
        valueAsDecimal: value.valueAsDecimal,
        valueAsJson: value.valueAsJson,
        valueAsJsonArray: value.valueAsJsonArray,
        valueAsDatetime: value.valueAsDatetime
      }).then(resp => {
        // let boardValues = resp.data?.storeValue?.values?.filter(v => v?.cardId === card.id);
        // updateValues(boardValues)
      })
    , 300))
  }

  function saveValue(row: any, cell: any, value: any) {
    storeValue({
      id: cell.id,
      cardId: cell.cardId,
      fieldId: cell.fieldId,
      boardId: cell.boardId,
      type: cell.type,
      valueAsString: value.valueAsString,
      valueAsBool: value.valueAsBool,
      valueAsNumber: value.valueAsNumber,
      valueAsDecimal: value.valueAsDecimal,
      valueAsJson: value.valueAsJson,
      valueAsJsonArray: value.valueAsJsonArray,
      valueAsDatetime: value.valueAsDatetime
    }).then(resp => {
      // let boardValues = resp.data?.storeValue?.values?.filter(v => v?.cardId === card.id);
      // updateValues(boardValues)
    })
  }

  function addRelation(item: any) {
    addRelationMutation(item).then(resp => {
      // let boardValues = resp.data?.addRelation?.values?.filter(v => v?.cardId === card.id);
      // console.log(resp)
      // updateValues(boardValues)
    })
  }

  function removeRelation(item: any) {
    removeRelationMutation(item).then(resp => {
      // let boardValues = resp.data?.removeRelation?.values?.filter(v => v?.cardId === card.id);
      // updateValues(boardValues)
    })
  }

  function addAttachment(id: any, attachments: any) {
    addAttachments({id: id, files: attachments}).then(resp => {
      if(resp.error) {
        return
      }
      // let boardValues = resp.data?.addAttachments?.values?.filter(v => v?.cardId === card.id);
      // updateValues(boardValues)
    })
  }

  function deleteAttachment(attachment_id: any, value: { id: any; }) {
    removeAttachment({attachmentId: attachment_id, valueId: value.id}).then(resp => {
      if(resp.error) {
        return
      }
      // let boardValues = resp.data?.removeAttachment?.values?.filter(v => v?.cardId === card.id);
      // updateValues(boardValues)
    })
  }

  function updateValues(values: any) {
    values.map((v: any) => {
      Object.assign(card[v.fieldId], v)
    })
  }

  function getField(field: Field) {
    switch (field.type) {
      case 'STRING': return <FieldString key={field.id} field={field} card={card} updatePrimitive={saveValueWithTimeout}/>
      case 'URL': return <FieldUrl key={field.id} field={field} card={card} updatePrimitive={saveValueWithTimeout}/>
      case 'EMAIL': return <FieldEmail key={field.id} field={field} card={card} updatePrimitive={saveValueWithTimeout}/>
      case 'TEXTAREA': return <FieldTextarea key={field.id} field={field} card={card} updatePrimitive={saveValueWithTimeout}/>
      case 'NUMBER': return <FieldNumber key={field.id} field={field} card={card} updatePrimitive={saveValueWithTimeout}/>
      case 'DURATION': return <FieldDuration key={field.id} field={field} card={card} updatePrimitive={saveValueWithTimeout}/>
      case 'PHONE': return <FieldPhone key={field.id} field={field} card={card} updatePrimitive={saveValueWithTimeout}/>
      case 'DECIMAL': return <FieldDecimal key={field.id} field={field} card={card} updatePrimitive={saveValueWithTimeout}/>
      case 'PERCENT': return <FieldPercent key={field.id} field={field} card={card} updatePrimitive={saveValueWithTimeout}/>
      case 'CURRENCY': return <FieldCurrency key={field.id} field={field} card={card} updatePrimitive={saveValueWithTimeout}/>
      case 'CHECKBOX': return <FieldCheckbox key={field.id} field={field} card={card} updatePrimitive={saveValue}/>
      case 'SELECT': return <FieldSelector key={field.id} field={field} card={card} updatePrimitive={saveValue}/>
      case 'MULTISELECT': return <FieldMultiSelector key={field.id} field={field} card={card} updatePrimitive={saveValue}/>
      case 'COLLABORATOR': return <FieldCollaborator key={field.id} field={field} card={card} updatePrimitive={saveValue}/>
      case 'DATETIME': return <FieldDateTime key={field.id} field={field} card={card} updatePrimitive={saveValue}/>

      // HARD FIELDS
      case 'RELATION': return <FieldRelation key={field.id} field={field} fromCard={card} addRelation={addRelation} removeRelation={removeRelation} fields={fields}/>
      case 'ATTACHMENT': return <FieldAttachment key={field.id} field={field} card={card} addAttachment={addAttachment} deleteAttachment={deleteAttachment}/>

      // NON EDITABLE 
      case 'INSERTED_AT': return <FieldInsertedAt key={field.id} field={field} value = {(card[field.id] as unknown as DatetimeValue)?.valueAsDatetime!}/>
      case 'UPDATED_AT': return <FieldUpdatedAt key={field.id} field={field} value = {(card[field.id] as unknown as DatetimeValue)?.valueAsDatetime!}/>
      case 'CREATED_BY': return <FieldCreatedBy key={field.id} field={field} value = {(card[field.id] as unknown as JsonValue)?.valueAsJson!}/>
      case 'UPDATED_BY': return <FieldUpdatedBy key={field.id} field={field} value = {(card[field.id] as unknown as JsonValue)?.valueAsJson!}/>
      case 'FORMULA': return <FieldFormula key={field.id} field={field} value = {(card[field.id] as unknown as StringValue)?.valueAsString!}/>
      case 'LOOKUP': return <FieldLookup key={field.id} field={field} value = {(card[field.id] as unknown as RelationValue)?.links! as Maybe<LinkItem>[]}/>

      default: return <div key={field.id}></div>
    }
  }
  
  return (
    <div className="flex flex-col bg-white h-full w-full overflow-scroll">
      <div className="flex flex-col w-full max-w-lg h-fit mx-20 pb-10 pt-8 space-y-6">
        {/* <div className="pb-4 pt-4"> */}
          {
            fields
            .filter((field: Field) => field.primary === true)
            .map((field: Field) => {
              return getField(field)
            })
          }
        {/* </div>
        <div className="pb-10"> */}
          {
            fields
            .filter((field: Field) => field.primary !== true)
            .map((field: Field) => {
              return getField(field)
            })
          }
        {/* </div> */}
      </div>
    </div>
  )
}