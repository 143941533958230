import { DefaultLocale } from './types'

export const DEFAULT_LOCALE_RU: DefaultLocale = {
  everyText: 'every',
  emptyMonths: 'every month',
  emptyMonthDays: 'every day of the month',
  emptyMonthDaysShort: 'day of the month',
  emptyWeekDays: 'every day of the week',
  emptyWeekDaysShort: 'day of the week',
  emptyHours: 'every hour',
  emptyMinutes: 'every minute',
  emptyMinutesForHourPeriod: 'every',
  yearOption: 'year',
  monthOption: 'month',
  weekOption: 'week',
  dayOption: 'day',
  hourOption: 'hour',
  minuteOption: 'minute',
  rebootOption: 'reboot',
  prefixPeriod: 'Every',
  prefixMonths: 'in',
  prefixMonthDays: 'on',
  prefixWeekDays: 'on',
  prefixWeekDaysForMonthAndYearPeriod: 'and',
  prefixHours: 'at',
  prefixMinutes: ':',
  prefixMinutesForHourPeriod: 'at',
  suffixMinutesForHourPeriod: 'minute(s)',
  errorInvalidCron: 'Invalid cron expression',
  clearButtonText: 'Clear',
  altMinutes: [
    '15',
    '30',
    '45',
    '60',
  ],
  weekDays: [
    // Order is important, the index will be used as value
    'Sunday', // Sunday must always be first, it's "0"
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ],
  months: [
    // Order is important, the index will be used as value
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ],
  // Order is important, the index will be used as value
  altWeekDays: [
    'SUN', // Sunday must always be first, it's "0"
    'MON',
    'TUE',
    'WED',
    'THU',
    'FRI',
    'SAT',
  ],
  // Order is important, the index will be used as value
  altMonths: [
    'JAN',
    'FEB',
    'MAR',
    'APR',
    'MAY',
    'JUN',
    'JUL',
    'AUG',
    'SEP',
    'OCT',
    'NOV',
    'DEC',
  ],
}

export const DEFAULT_LOCALE_EN: DefaultLocale = {
  everyText: 'каждый',
  emptyMonths: 'месяцы',
  emptyMonthDays: 'день месяца',
  emptyMonthDaysShort: 'day of the month',
  emptyWeekDays: 'день недели',
  emptyWeekDaysShort: 'day of the week',
  emptyHours: 'часы',
  emptyMinutes: 'минуты',
  emptyMinutesForHourPeriod: 'каждый',
  yearOption: 'год',
  monthOption: 'месяц',
  weekOption: 'неделя',
  dayOption: 'день',
  hourOption: 'час',
  minuteOption: 'минута',
  rebootOption: 'reboot',
  prefixPeriod: 'Каждый',
  prefixMonths: 'в месяц',
  prefixMonthDays: 'в день месяца',
  prefixWeekDays: 'в день недели',
  prefixWeekDaysForMonthAndYearPeriod: 'и в день недели',
  prefixHours: 'в час(а, ов)',
  prefixMinutes: 'в минут(ы)',
  prefixMinutesForHourPeriod: 'в минут',
  suffixMinutesForHourPeriod: '',
  errorInvalidCron: 'Неверное выражение',
  clearButtonText: 'Очистить',
  altMinutes: [
    '15',
    '30',
    '45',
    '60',
  ],
  weekDays: [
    // Order is important, the index will be used as value
    'Воскресенье', // Sunday must always be first, it's "0"
    'Понедельник',
    'Вторник',
    'Среда',
    'Четверг',
    'Пятница',
    'Суббота',
  ],
  months: [
    // Order is important, the index will be used as value
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь',
  ],
  // Order is important, the index will be used as value
  altWeekDays: [
    'ВСК', // Sunday must always be first, it's "0"
    'ПОН',
    'ВТ',
    'СР',
    'ЧЕТ',
    'ПТ',
    'СБ',
  ],
  // Order is important, the index will be used as value
  altMonths: [
    'ЯНВ',
    'ФЕВ',
    'МАР',
    'АПР',
    'МАЙ',
    'ИЮН',
    'ИЮЛ',
    'АВГ',
    'СЕН',
    'ОКТ',
    'НОЯ',
    'ДЕК',
  ],
}