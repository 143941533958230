import React, {useState, useRef} from "react";
import { HiTrash, HiPlusCircle } from "react-icons/hi";
import { TbTrash, TbCopy, TbLayoutGridAdd, TbDots}  from "react-icons/tb"
import { useNavigate } from "react-router-dom";
import { Board } from "../../../core/graphql/types";
import { SimpleModal } from "../../modals/simple";
import { BoardPreviewMenu } from './menu';
import { BoardFormDelete } from "../../../containers/board/form/delete";
import moment from 'moment';
import _ from "lodash";
import CardModal from "../../Cards";

import { intToRGB, hashCode, hexToRGB} from "../../../utils";
import { MatrBaseDialog, MatrPopover } from "../../ui/portal";

export type BoardPreviewProps = {board: Board}

export const BoardPreviewCard: React.FC<BoardPreviewProps> = ({board}) => {
  const [ openMenu, setOpenMenu ] = useState(false);
  const [ buttonRef, setButtonRef ] = useState<any>()
  let navigate = useNavigate();
  const [ openDeleteModal, setOpenDeleteModal ] = useState(false);

  const openBoard = () => {
    navigate(`/boards/${board.id}`);
  }

  function onOpenMenu(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    // e.stopPropagation();
    setOpenMenu(true)
  }

  function closeMenu() { 
    setOpenMenu(false)
  };

  function openModalDelete() {setOpenDeleteModal(true)};
  function closeModalDelete() {setOpenDeleteModal(false)};


  const menu = [
    {
      name: "Добавить запись",
      action: openModalDelete,
      icon: <TbLayoutGridAdd className="w-4 h-4"/>,
      disabled: true
    },
    {
      name: "Копировать",
      action: openModalDelete,
      icon: <TbCopy className="w-4 h-4"/>,
      disabled: true
    },
    {
      name: "Удалить",
      action: openModalDelete,
      icon: <TbTrash className="w-4 h-4"/>,
      disabled: false
    },
  ];


  return (
    <>
      <div className="flex w-full bg-slate-100 rounded-md">
        <div className="rounded-md relative w-full hover:bg-slate-200 cursor-pointer hover:shadow-lg">
          <div className="relative flex flex-col items-center">
            <div className="w-full flex flex-col justify-between items-center bg-indigo-800 bg-opacity-0 text-slate-600 hover:bg-opacity-100 rounded-md hover:text-white" onClick={openBoard}>
              <div className="h-32 w-full flex  overflow-hidden rounded-t-md" style={{background: `${hexToRGB(intToRGB(hashCode(board.name)), 0.4)}`}}>
                <span className="flex items-end px-3 py-1.5 text-lg text-inherit bg-opacity-0 hover:bg-opacity-100 font-semibold text-left whitespace-pre-wrap w-full">{board.name}</span>
              </div>
              <div className="flex items-center w-full px-2 py-3">
                <span className="pl-1 text-xs text-left text-inherit truncate  w-full">Обновлено: {moment(board.updatedAt).fromNow()}</span>
              </div>
            </div>
            <button
              onClick={(e) => onOpenMenu(e)}
              ref={setButtonRef}
              className="absolute right-1.5 bottom-2 h-5 w-5 text-indigo-600"
            >
              <TbDots />
            </button>
          </div>   
        </div>
      </div>
      {openMenu && 
        <MatrPopover onClose={closeMenu} reference={buttonRef} placement="bottom-end">
          <BoardPreviewMenu menu = {menu}/>
        </MatrPopover>
      }
      {openDeleteModal &&
        <MatrBaseDialog open = {openDeleteModal} onClose = {closeModalDelete}>
          <BoardFormDelete closeModal={closeModalDelete} name={board?.name} id={board?.id}/>
        </MatrBaseDialog>
      }
    </>
  );
};


export const BoardPreviewRow: React.FC<BoardPreviewProps> = ({board}) => {
  const [ open, setOpen ] = useState(false);
  let navigate = useNavigate();
  const [ openDeleteModal, setOpenDeleteModal ] = useState(false);

  const openBoard = () => {
    navigate(`/board/${board.id}`);
  }


  function openModal() { setOpen(true)};
  function closeModal() { 
    setOpen(false)
  };

  function openModalDelete() {setOpenDeleteModal(true)};
  function closeModalDelete() {setOpenDeleteModal(false)};

  function handleAddRecord(e: React.MouseEvent<SVGElement, MouseEvent>) {
    e.stopPropagation();
    openModal()
  }

  const menu = [
    {
      name: "Удалить",
      action: openModalDelete,
      icon: <HiTrash className="mr-2 w-3 h-3"/>,
      disabled: false
    },
  ];

  return (
    <>
    <div className="flex w-full bg-slate-50 rounded-md">
      <div className="rounded-sm relative w-full hover:bg-zinc-50 cursor-pointer">
        
        <div className="flex flex-col items-center">
          <div className="w-full flex flex-row justify-between items-center" onClick = {openBoard}>
            <span className="text-sm text-left truncate hover:text-indigo-700 w-full py-4 px-4">{board.name}</span>
            {/* <HiPlusCircle className="h-5 w-5 text-indigo-600" aria-hidden="true" onClick={(e) => handleAddRecord(e)}/> */}
            <div className="text-gray-400 w-8"><BoardPreviewMenu menu = {menu}/></div>
          </div>
        </div>   
      </div>
    </div>
    {openDeleteModal ? 
    <SimpleModal open = {openDeleteModal} close = {closeModalDelete}>
      <BoardFormDelete closeModal={closeModalDelete} name={board?.name} id={board?.id}/>
    </SimpleModal>
    : <></>}
    {/* {open ? <CardModal open={open} close={closeModal} boardId={board.id}/> : <></>} */}
    </>
  );
};