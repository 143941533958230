import { useEffect, useState } from 'react';
import { BlockKanbanOptions, Field, JsonValue, Select, SelectOption } from '../../../core/graphql/types';
import { useBoard } from "../../../core/providers/board";
import { useSpaceStore } from "../../../core/store/store";
import Board from './base/Board';
import { useContainerDimensions } from '../../../core/hooks/useDemensions';


function KanbanView() { 
  const {initId, initType, fields, cards, values, currentView, cardModalIsOpened, modal} = useBoard()
  const init: string = `${initType}:${initId}`
  const [tasksLoaded, setTaskLoaded] = useState(false)
  const [columns, setColumns] = useState<any[]>([])
  const [tasks, setTasks] = useState<any[]>([])
  const [data, setData] = useState<any>({columns: [], tasks: []})


  useEffect(() => {
    setTaskLoaded(false)

    let ts = 
      values.filter(v => v.fieldId === (currentView?.options as BlockKanbanOptions).stackedFieldId && (v as JsonValue).valueAsJson)
      .map(v => (
        {
          id: v.cardId,
          slug: v.cardId,
          columnId: (v as JsonValue).valueAsJson?.id!,
          values: values.filter(tv => tv.cardId === v.cardId && !currentView?.hiddenFields?.includes(tv.fieldId))
        }
      ))

    let cols = 
      (fields.find(f => f.id === (currentView?.options as BlockKanbanOptions).stackedFieldId)?.attributes as Select)?.options!
      .map(c => ({
        id: c?.id,
        name: c?.name,
        slug: c?.id,
        count: ts.filter(t => t.columnId === c?.id).length,
        tasks: ts.filter(t => t.columnId === c?.id).map(t => t.id)
      }))

    setTasks(ts)
    setColumns(cols)

    console.log(values.length)

  }, [cards, values])

  useEffect(() => {
    if(columns.length > 0) { 
      setData({columns: columns, tasks: tasks})
      setTaskLoaded(true) 
    }
  }, [columns, tasks])

  return (
    <>
      <div className='flex h-full overflow-hidden'>
        {tasksLoaded ? 
          <Board kanbanBoard={data}/>
        : <></>}
      </div>

      { cardModalIsOpened && modal }
    </>
  )
}

export default KanbanView;