import _ from "lodash";
import PageBlock from "../..";
import { Block } from "../../../../../../core/graphql/types";
import { usePanel } from "../../../../../../core/providers/panel";
import { DragDropContext, Draggable, DropResult, Droppable, ResponderProvided } from "react-beautiful-dnd";
import { BlockBaseControls } from "../../Controls";
import { TbDragDrop } from "react-icons/tb";


export function TabRelationBlock({relationBlock}: {relationBlock: Block}) {
    const {block, editorMode, changeCurrentBlock, updateBlocksPosition} = usePanel()


    return (
      <></>
    )
}