import Header from "../../components/Headers/Header";
import BoardsManager from "../../containers/boards";

function BoardsPage(): JSX.Element {
  return (
    <>
      <Header location="boards"/>
      <BoardsManager />
    </>
  );
}
export default BoardsPage;