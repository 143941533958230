import {useEffect, Fragment, ChangeEvent, useCallback, useMemo, useState} from 'react';
import { useForm, Controller, FormProvider, useWatch } from "react-hook-form";
import { Listbox, Transition } from "@headlessui/react";
import { HiSelector } from 'react-icons/hi'
import { requestMethods } from '../../../core/enums';
import RequestTabs from './tabs';
import { useFetcherContext } from '../../../core/providers/fetcher';
import { SmallSpinner } from '../../../components/loaders';
import * as Yup from 'yup';
import _ from 'lodash';
import { debounce } from "debounce";
import useDeepCompareEffect from "use-deep-compare-effect";
import { FetcherRequest } from '../../../core/graphql/types';
import FetcherRequestUrl from './url';

const useYupValidationResolver = (validationSchema: any) =>
  useCallback(
    async (data: any) => {
      try {
        const values = await validationSchema.validate(data, {
          abortEarly: false
        });
        return {
          values,
          errors: {}
        };
      } catch (errors: any) {
        return {
          values: {},
          errors: errors.inner.reduce(
            (allErrors: any, currentError: { path: any; type: any; message: any; }) => ({
              ...allErrors,
              [currentError.path]: {
                type: currentError.type ?? "validation",
                message: currentError.message
              }
            }),
            {}
          )
        };
      }
    },
    [validationSchema]
);

const validationSchema = Yup.object().shape({
  method: Yup.mixed().oneOf(['GET', 'POST', 'PUT', 'PATCH', 'DELETE']).defined().required("Метод не указан"),
  authorizationType: Yup.mixed().oneOf(['AUTH_NOAUTH', 'AUTH_BASIC', 'AUTH_BEARER', 'AUTH_OAUTH', 'AUTH_HEADER', 'AUTH_PARAMETER']).defined(),
  authorization: Yup.mixed()
    .when('authorizationType', { is: 'AUTH_BASIC', then: Yup.object({
      dynamic: Yup.boolean().default(true),
      username: Yup.mixed().required("Не указан логин для авторизации"),
      password: Yup.mixed().required("Не указан пароль для авторизации"),
    })})
    .when('authorizationType', { is: 'AUTH_BEARER', then: Yup.object({
      dynamic: Yup.boolean().default(false).required(),
      token: Yup.mixed().required("Токен не может быть пустым"),
      prefix: Yup.string().nullable(),
    })}),
  bodyType: Yup.mixed().oneOf(['BODY_NO', 'BODY_MULTIPART', 'BODY_FROM_URL', 'BODY_JSON', 'BODY_XML', 'BODY_PLAIN', 'BODY_OTHER']).defined(),
  // TODO Сделать чтобы можно было валидиировать IP адрес
  // url: Yup.string().required("Адрес запроса не может быть пустым").matches(
  //   /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gi,
  //   "Неверный адрес"
  // ),
  url: Yup.string().required("Адрес запроса не может быть пустым"),
  headers: Yup.array().of(
    Yup.object().shape({
      key: Yup.string().required('Ключ заголовка: Обязательное поле'),
      name: Yup.string().nullable(),
      description: Yup.string().nullable(),
      type: Yup.mixed().oneOf(['STRING', 'NUMBER', 'DATETIME', 'ANY']).defined(),
      value: Yup.string().required('Тествое значение: Обязательное поле'),
      dynamic: Yup.boolean().default(true),
      disabled: Yup.boolean().default(false),
    })
  ),
  parameters: Yup.array().of(
    Yup.object().shape({
      key: Yup.string().required('Ключ параметра: Обязательное поле'),
      name: Yup.string().nullable(),
      description: Yup.string().nullable(),
      type: Yup.mixed().oneOf(['STRING', 'NUMBER', 'DATETIME', 'ANY']).defined(),
      value: Yup.string().required('Тествое значение: Обязательное поле'),
      dynamic: Yup.boolean().default(true),
      disabled: Yup.boolean().default(false),
    })
  ),
  pathParameters: Yup.array().of(
    Yup.object().shape({
      key: Yup.string().required('Ключ параметра в урле: Обязательное поле'),
      name: Yup.string().nullable(),
      description: Yup.string().nullable(),
      type: Yup.mixed().oneOf(['STRING', 'NUMBER', 'DATETIME', 'ANY']).defined(),
      value: Yup.string().required('Тествое значение: Обязательное поле'),
      dynamic: Yup.boolean().default(true),
      disabled: Yup.boolean().default(false),
    })
  ),
});

const defaultValues = {
  method: requestMethods.find(rm => rm.id === "GET")?.id,
  url: "",
  authorizationType: "AUTH_NOAUTH",
  authorization: {type: "AUTH_NOAUTH"},
  bodyType: "BODY_NO",
  body: {type: "BODY_NO"},
  headers: [],
  parameters: [],
  pathParameters: [],
  depth: 0,
  outputMode: true,
  mode: "APPEND",
  multiple: false
}

export default function Request() {
  const {request, response, currentRequestId, saveRequest, saveRequestAndGetResponse} = useFetcherContext()
  const resolver = useYupValidationResolver(validationSchema);
  const methods = useForm({resolver: resolver, defaultValues: useMemo(() => {return request ? request : defaultValues}, [request])});
  const { reset, handleSubmit, control, setValue, getValues, formState } = methods;
  const [error, setError] = useState(null)


  useEffect(() => {
    reset(request);
    validationSchema.isValid(request).then(res => {
      if(res) {
        setError(null)
      } else {
        validationSchema.validate(getValues()).catch(res => setError(res.message))
      }
    })
  }, [request, currentRequestId]);

  // Обновление

  const debouncedSave = useCallback(
    debounce(() => {
      saveRequest(getValues() as FetcherRequest)
    }, 1000),
    []
  );

  const watchedData = useWatch({
    control: control,
    defaultValue: request
  });

  useDeepCompareEffect(() => {
    if (formState.isDirty) {
      validationSchema.isValid(getValues()).then(res => {
        if(res) {
          setError(null)
          debouncedSave()
        } else {
          validationSchema.validate(getValues()).catch(res => setError(res.message))
          debouncedSave.clear()
        }
      })
    }
  }, [watchedData]);

  function onGetData() {
    saveRequestAndGetResponse({...getValues() as FetcherRequest})
  }

  return (
    request && currentRequestId ?
      <>
        <div className="flex-none h-1/2 w-full overflow-hidden">
          {!request ? <SmallSpinner /> : 
            <div className='flex flex-col h-full'>
              <FormProvider {...methods}>
                <div className="flex-1 flex w-full h-[46px] text-sm items-center border-b px-4 py-2">
                  <FetcherRequestUrl />
                  <button type='button' onClick={() => onGetData()} className='bg-green-600 w-28 justify-center text-white px-4 py-2 rounded-md text-sm font-medium flex items-center ml-4'>Отправить</button>
                </div>
                <div className='grow w-full h-full bg-white overflow-hidden'>
                  <RequestTabs />
                </div>
                <div className='flex-1 h-8 w-full bg-slate-100 border-t text-xs px-4 py-1 items-center'>{error ? <span className='text-red-600'>Ошибка: {error}</span>  : <span className='text-green-600'>Ошибок нет</span>}</div>
              </FormProvider>
            </div>
          }
        </div>
        
      </>
  : <div>Выберите или создайте</div>
  )
}