import { Field, Relation, Lookup, String, Formula } from '../../../../core/graphql/types';
import FieldString from './string';
import FieldNumber from './number';
import FieldDuration from './duration';
import FieldDecimal from './decimal';
import FieldPercent from './percent';
import FieldCurrency from './currency';
import FieldCheckbox from './checkbox';
import FieldTextarea from './textarea';
import FieldSelect from './select';
import FieldMultiSelect from './multiselect';
import FieldCollaborator from './collaborator';
import FieldDateTime from './datetime';
import FieldInsertedAt from './inserted_at';
import FieldUpdatedAt from './updated_at';
import FieldCreatedBy from './created_by';
import FieldUpdatedBy from './updated_by';
import FieldRelation from './relation';
import FieldLookup from './lookup';
import FieldFormula from './formula';
import FieldAttachment from './attachment';
import FieldRecordid from './recordid';


export const getFieldContent = (type: string, field: Field) => {
  switch (type) {
    case 'STRING':
      return (
        <FieldString attributes = {field.attributes as String}/>
      );
    case 'NUMBER':
      return (
        <FieldNumber attributes = {field.attributes}/>
      );
    case 'DURATION':
      return (
        <FieldDuration attributes = {field.attributes}/>
      );
    case 'DECIMAL':
      return (
        <FieldDecimal attributes = {field.attributes}/>
      );
    case 'PERCENT':
      return (
        <FieldPercent attributes = {field.attributes}/>
      );
    case 'CURRENCY':
      return (
        <FieldCurrency attributes = {field.attributes}/>
      );
    case 'CHECKBOX':
      return (
        <FieldCheckbox attributes = {field.attributes}/>
      );
    case 'TEXTAREA':
      return (
        <FieldTextarea attributes = {field.attributes}/>
      );
    case 'SELECT':
      return (
        <FieldSelect field = {field}/>
      );
    case 'MULTISELECT':
      return (
        <FieldMultiSelect field = {field}/>
      );
    case 'COLLABORATOR':
      return (
        <FieldCollaborator attributes = {field.attributes}/>
      );
    case 'DATETIME':
      return (
        <FieldDateTime attributes = {field.attributes}/>
      );
    case 'INSERTED_AT':
      return (
        <FieldInsertedAt attributes = {field.attributes}/>
      );
    case 'UPDATED_AT':
      return (
        <FieldUpdatedAt field={field} attributes = {field.attributes}/>
      );
    case 'CREATED_BY':
      return (
        <FieldCreatedBy attributes = {field.attributes} />
      );
    case 'UPDATED_BY':
      return (
        <FieldUpdatedBy field={field} attributes = {field.attributes}/>
      );
    case 'RELATION':
      return (
        <FieldRelation attributes = {field.attributes as Relation}/>
      );
    case 'LOOKUP':
      return (
        <FieldLookup attributes = {field.attributes  as Lookup}/>
      );
    case 'FORMULA':
      return (
        <FieldFormula field = {field} attributes = {field.attributes as Formula}/>
      );
    case 'ATTACHMENT':
      return (
        <FieldAttachment field = {field} attributes = {field.attributes}/>
      );
    case 'RECORDID':
      return (
        <FieldRecordid attributes = {field.attributes}/>
      );
    default:
      return (
        <></>
      );
  }
};