import CardInBoard from "./Modals/cardInBoard";
import CardOutBoard from "./Modals/cardOutBoard";

// Приходит запись, она может быть новой или старой, приходит функция для изменения списка связей и внешняя доска

interface CardModalProps {
  open: boolean;
  initiator: string; // Кто инциировал открытие 
  close: () => void;
  boardId: string;
  cardId: string;
  fromFieldId?: string;
}

const CardModal = (props:CardModalProps) => {
  switch (props.initiator) {
    case "ROW":
      return <CardInBoard {...props}/>
    case "RELATION_CELL":
      return <CardOutBoard {...props} fromFieldId={props.fromFieldId!}/>
    case "FORM_FIELD":
      return <CardOutBoard {...props} fromFieldId={props.fromFieldId!}/>
    default:
      return <CardOutBoard {...props} fromFieldId={props.fromFieldId!}/>
  }

};

export default CardModal;