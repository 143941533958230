import { useState, useEffect, forwardRef } from "react";
import { DatetimeValue } from "../../../core/graphql/types";
import { DateTimeIcon } from "../../icons";
import {dateFormats, timeFormats} from "../../../core/enums";
import type { FieldPrimitiveEditorProps } from './types';
import DatePicker from "react-datepicker";
import {HiChevronLeft, HiChevronRight} from 'react-icons/hi';

import moment from "moment";
import {DateTime, Settings} from "luxon";
import MatrFormDatePicker from "../../ui/datepicker/form";

export default function FieldDateTime({field, card, updatePrimitive}: FieldPrimitiveEditorProps) {
  Settings.defaultLocale = "ru";
  const [value, setValue] = useState((card[field.id] as unknown as DatetimeValue)?.valueAsDatetime! ? new Date((card[field.id] as unknown as DatetimeValue)?.valueAsDatetime!) : undefined)
  const [dateFormat, setDateFormat] = useState<any>();
  const attr = field.attributes;

  useEffect(() => {
    let curdf = dateFormats.find(format => format.name === attr.dateFormat)
    let curtf = timeFormats.find(format => format.name === attr.timeFormat)

    if (attr.includeTime) {
      setDateFormat(curdf?.spec_format + ' ' + curtf?.spec_format)
    } else { 
      setDateFormat(curdf?.spec_format)
    }
  },[attr.dateFormat, attr.includeTime, attr.timeFormat])

  // const DatePickerInput = forwardRef(({ value, onClick }: {value: any, onClick: any}, ref: any) => (
  //   <button className="text-sm text-left form-input py-1.5 block w-full rounded-sm bg-gray-100 border-0 focus:bg-white focus:ring focus:ring-indigo-200 focus:ring-opacity-50" onClick={onClick} ref={ref}>
  //     {value}
  //   </button>
  // ));

  function handleValue(e: any) {
    setValue(e)

    if(!attr.includeTime) {
      updatePrimitive(
        { ...card, [field.id]: {...card[field.id], valueAsDatetime: new Date(e).toISOString()}}, 
        ({...card[field.id], valueAsDatetime: new Date(e).toISOString()} as unknown as DatetimeValue), 
        {valueAsDatetime: new Date(e).toISOString()}
      )
    } else {
      updatePrimitive(
        { ...card, [field.id]: {...card[field.id], valueAsDatetime: moment(e).toISOString()}}, 
        ({...card[field.id], valueAsDatetime: moment(e).toISOString()} as unknown as DatetimeValue), 
        {valueAsDatetime: moment(e).toISOString()}
      )
    }    
  }


  return (
    <div className="relative flex flex-col">
      <div className="flex items-center h-full mb-1.5">
        <div className="text-slate-400 pr-2"><DateTimeIcon /></div>
        <label className="text-slate-500 text-sm">{field.title}</label>
      </div>
      <MatrFormDatePicker
        selected = {value}
        showTimeInput = {attr.includeTime}
        dateFormat = { dateFormat }
        onChange = {(e) => handleValue(e)}
      />
    </div>
  )
};