import { Account, BlockPermissions, BoardView, BoardViewPermissions, Field, FieldType } from '../../../../core/graphql/types';
import { Row } from '../types';
import { SelectColumn } from './action';
import { createColumn } from './create';
import { stringColumn } from './string';
import { numberColumn } from './number';
import { decimalColumn } from './decimal';
import { attachmentColumn } from './attachment';
import { durationColumn } from './duration';
import { currencyColumn } from './currency';
import { datetimeColumn } from './datetime';
import { percentColumn } from './percent';
import { collaboratorColumn } from './collaborator';
import { emailColumn } from './email';
import { createdByColumn } from './createdBy';
import { updatedByColumn } from './updatedBy';
import { formulaColumn } from './formula';
import { insertedAtColumn } from './insertedAt';
import { updatedAtColumn } from './updatedAt';
import { lookupColumn } from './lookup';
import { multiselectColumn } from './multiselect';
import { selectColumn } from './select';
import { phoneColumn } from './phone';
import { recordidColumn } from './recordid';
import { relationColumn } from './relation';
import { textColumn } from './text';
import { urlColumn } from './url';
import { boolColumn } from './bool';




export const CREATE_COLUMN_KEY = 'create';

export function makeColumns(fields: Field[], currentUser: Account, permissions: BoardViewPermissions | BlockPermissions) {
    const lastColumn = createColumn()
  
    const fieldsToColumns = []
    fieldsToColumns.push(SelectColumn)
  
    fields
    // .filter((field: Field) => !currentView?.hiddenFields!.includes(field?.id!))
    // .filter((field: Field) => !currentView?.customHiddenFields!.includes(field?.id!))
    .map((field: Field) => {return fieldsToColumns.push(columnGenerator(field as Field, permissions?.canEditInline!))});
  
    if(permissions.canAddField || permissions.canDelete) {
      fieldsToColumns.push(lastColumn);
    }
    return fieldsToColumns
}

export function columnGenerator(field: Field, canEditInline: boolean) {
  switch (field.type) {
    case FieldType.String: return stringColumn({field: field, canEditInline: canEditInline});
    case FieldType.Checkbox: return boolColumn({field: field, canEditInline: canEditInline});
    case FieldType.Number: return numberColumn({field: field, canEditInline: canEditInline});
    case FieldType.Decimal: return decimalColumn({field: field, canEditInline: canEditInline});
    case FieldType.Attachment: return attachmentColumn({field: field, canEditInline: canEditInline});
    case FieldType.Datetime: return datetimeColumn({field: field, canEditInline: canEditInline});
    case FieldType.Duration: return durationColumn({field: field, canEditInline: canEditInline});
    case FieldType.Currency: return currencyColumn({field: field, canEditInline: canEditInline});
    case FieldType.Percent: return percentColumn({field: field, canEditInline: canEditInline});
    case FieldType.Collaborator: return collaboratorColumn({field: field, canEditInline: canEditInline});
    case FieldType.CreatedBy: return createdByColumn({field: field, canEditInline: canEditInline});
    case FieldType.UpdatedBy: return updatedByColumn({field: field, canEditInline: canEditInline});
    case FieldType.Email: return emailColumn({field: field, canEditInline: canEditInline});
    case FieldType.Formula: return formulaColumn({field: field, canEditInline: canEditInline});
    case FieldType.InsertedAt: return insertedAtColumn({field: field, canEditInline: canEditInline});
    case FieldType.UpdatedAt: return updatedAtColumn({field: field, canEditInline: canEditInline});
    case FieldType.Lookup: return lookupColumn({field: field, canEditInline: canEditInline});
    case FieldType.Multiselect: return multiselectColumn({field: field, canEditInline: canEditInline});
    case FieldType.Select: return selectColumn({field: field, canEditInline: canEditInline});
    case FieldType.Phone: return phoneColumn({field: field, canEditInline: canEditInline});
    // case FieldType.Rating: return ratingColumn({field: field, canEditInline: canEditInline});
    case FieldType.Recordid: return recordidColumn({field: field, canEditInline: canEditInline});
    case FieldType.Relation: return relationColumn({field: field, canEditInline: canEditInline});
    case FieldType.Textarea: return textColumn({field: field, canEditInline: canEditInline});
    case FieldType.Url: return urlColumn({field: field, canEditInline: canEditInline});
    default: return stringColumn({field: field, canEditInline: canEditInline});
  }
}