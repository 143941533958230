import { useEffect, useState } from 'react';
import { AiOutlineSortAscending, AiOutlineSortDescending } from 'react-icons/ai';
import { HiOutlineTrash } from 'react-icons/hi';
import { Field, GroupByField, GroupOrSortDirectionEnum } from '../../../core/graphql/types';
import { TbCheck } from 'react-icons/tb';
import GroupByFields from './list';

interface GroupByFieldsProps {
  fields: Field[];
  value: GroupByField[] | [];
  onChangeFields: any;
}

function FilterGroupBy(props: GroupByFieldsProps) {
  return (
    <div className='p-4 min-w-max'>
      <div className='flex flex-row leading-8 justify-between min-w-max'>
        <span className='text-base text-slate-600 w-48'>Группировка</span>
        <div className='flex flex-row text-sm w-full min-w-max justify-end gap-2 text-slate-500'>
          <div>Раскрыть все</div>
          <div>Свернуть все</div>
        </div>
      </div>
      <GroupByFields {...props}/>
    </div>
  )
}

export default FilterGroupBy;