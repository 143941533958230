import { DateTime } from "luxon";
import { CardValueInput, DatetimeValue, DecimalValue, FieldType, JsonArrayValue, JsonValue, MattrTypes, NumberValue, RelationValue, StringValue, ValuePayload } from "../../graphql/types";

export const editablbeFields = [
  FieldType.Attachment, FieldType.Checkbox, FieldType.Collaborator, FieldType.Currency, FieldType.Datetime, FieldType.Decimal, FieldType.Duration,
  FieldType.Multiselect, FieldType.Number, FieldType.Percent, FieldType.Phone, FieldType.Relation, FieldType.Select, FieldType.String, FieldType.Textarea,
  FieldType.Url
]

export const nonEditablbeFields = [
  FieldType.Formula, FieldType.InsertedAt, FieldType.Lookup, FieldType.UpdatedAt, FieldType.UpdatedBy, FieldType.CreatedBy, FieldType.Recordid
]

export function transformValueToString(value: ValuePayload) {
  switch (value.type) {
    case FieldType.String:
    case FieldType.Email:
    case FieldType.Url:
    case FieldType.Textarea:
    case FieldType.Formula:
      return (value as StringValue).valueAsString
    case FieldType.Number:
    case FieldType.Phone:
    case FieldType.Duration:
    case FieldType.Rating:
      return (value as NumberValue).valueAsNumber ? (value as NumberValue).valueAsNumber?.toString() : null
    case FieldType.Decimal:
    case FieldType.Currency:
    case FieldType.Percent:
      return (value as DecimalValue).valueAsDecimal ? (value as DecimalValue).valueAsDecimal?.toString() : null
    case FieldType.UpdatedAt:
    case FieldType.InsertedAt:
    case FieldType.Datetime:
      return (value as DatetimeValue).valueAsDatetime ? new Date((value as DatetimeValue).valueAsDatetime).toDateString() : null
    case FieldType.Select:
      return (value as JsonValue).valueAsJson ? (value as JsonValue).valueAsJson?.name : null
    case FieldType.Multiselect:
      return (value as JsonArrayValue).valueAsJsonArray ? (value as JsonArrayValue).valueAsJsonArray?.map(v => v?.name) : null
    case FieldType.Relation:
    case FieldType.Lookup:
      return (value as RelationValue).links?.map(r => r?.value?.value)
    default:
      break;
  }
}

export function typedValue(value: any) {
  switch (value.type) {
    case "STRING": 
    case "URL": 
    case "EMAIL": return value.valueAsString
    case "NUMBER": 
    case "PHONE": return value.valueAsNumber
    case "DECIMAL": 
    case "PERCENT": 
    case "CURRENCY": return value.valueAsDecimal
    case "SELECT": return value.valueAsJson
    case "UPDATED_BY":
    case "CREATED_BY": return value.valueAsJson
    case "MULTISELECT": 
    case "COLLABORATOR": return value.valueAsJsonArray
    case "DATETIME": 
    case "INSERTED_AT": 
    case "UPDATE_AT": return value.valueAsDatetime
    case "RELATION": 
    case "LOOKUP": return value.relations
  }
}


export function transformFormValueToFieldValue(type: FieldType, value: any) {
  switch (type) {
    case FieldType.String:
    case FieldType.Email:
    case FieldType.Url:
      return { valueAsString: value ? value : null}
    case FieldType.Textarea:
      return { valueAsString: value ? value : null}
    case FieldType.Number:
    case FieldType.Phone:
    case FieldType.Duration:
    case FieldType.Rating:
      return { valueAsNumber: value ? value : null}
    case FieldType.Decimal:
    case FieldType.Currency:
    case FieldType.Percent:
      return { valueAsDecimal: value ? value : null}
    case FieldType.Datetime:
      return { valueAsDatetime: value ? value.toISOString() : null}
    case FieldType.Select:
      return { valueAsJson: value ? value : null}
    case FieldType.Collaborator:
      if(Array.isArray(value.value)) {
        return { valueAsJsonArray: value ? value : null}
      } else if(!value.value){
        break
      } else {
        return { valueAsJson: value ? value : null}
      }

    case FieldType.Multiselect:
      return { valueAsJsonArray: value ? value : null}
    case FieldType.Relation:
      return { links: value ? value.links?.map((l: any) => {return {valueId: l?.valueId}}) : null}
    case FieldType.Checkbox:
      return { valueAsBool: value ? value : null}
    case FieldType.UpdatedAt:
    case FieldType.InsertedAt:
    case FieldType.Formula:
    case FieldType.Lookup:
    default:
      break;
  }
}

export function convertFormValueToFieldValue(type: FieldType, value: any) {
  switch (type) {
    case FieldType.String:
    case FieldType.Email:
    case FieldType.Url:
      return value ? value : null
    case FieldType.Textarea:
      return value ? value : null
    case FieldType.Number:
    case FieldType.Phone:
    case FieldType.Duration:
    case FieldType.Rating:
      return value ? value.toString() : null
    case FieldType.Decimal:
    case FieldType.Currency:
    case FieldType.Percent:
      return value ? value.toString() : null
    case FieldType.Datetime:
      return value ? value.toISOString() : null
    case FieldType.Select:
    case FieldType.Multiselect:
    case FieldType.Collaborator:
      return value ? JSON.stringify(value) : null
    case FieldType.Relation:
      return value ? JSON.stringify(value?.map((v: any) => {return {id: v.value.id, name: v.value.value}})) : null
    case FieldType.Checkbox:
      return value ? value.toString() : null
    case FieldType.UpdatedAt:
    case FieldType.InsertedAt:
    case FieldType.Formula:
    case FieldType.Lookup:
    default:
      return value ? value.toString() : null

  }
}



export function deepEqual(object1: { [x: string]: any }, object2: { [x: string]: any }) {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    const val1 = object1[key];
    const val2 = object2[key];
    const areObjects = isObject(val1) && isObject(val2);
    if (
      areObjects && !deepEqual(val1, val2) ||
      !areObjects && val1 !== val2
    ) {
      return false;
    }
  }

  return true;
}

export function isObject(object: { [x: string]: any } | null) {
  return object != null && typeof object === 'object';
}

export function checkJson(value: string) {
  if(!value) {return false}
  try {
    JSON.parse(value);
  } catch (e) {
    return false;
  }
  return true;
}

export function valueToString(value: any, type: MattrTypes | FieldType) {
  switch (type) {
    case MattrTypes.Select:
      return value ? JSON.stringify(value) : null
    case MattrTypes.Multiselect:
    case MattrTypes.Collaborator:
      return value ? JSON.stringify(value) : JSON.stringify([])
    case MattrTypes.Relation: 
      return value ? JSON.stringify(value) : null
    case MattrTypes.Datetime:
      return value ? (value as DateTime).toLocaleString() : null
    default:
      return value ? value.toString() : null
  }
}

export function valueFromString(value: any, type: MattrTypes) {
  switch (type) {
    case MattrTypes.Select:
      return {...value, value: value.value ? JSON.parse(value.value) : null}
    case MattrTypes.Multiselect:
    case MattrTypes.Collaborator:
    case MattrTypes.Relation: 
      return {...value, value: value.value ? JSON.parse(value.value) : []}
    case MattrTypes.Datetime:
      return {...value, value: value.value ? new Date(value.value) : null}
    case MattrTypes.Number:
    case MattrTypes.Duration:
    case MattrTypes.Phone: 
      return {...value, value: value.value ? Number.parseInt(value.value) : null}
    case MattrTypes.Decimal:
    case MattrTypes.Currency:
    case MattrTypes.Percent: 
      return {...value, value: value.value ? Number.parseFloat(value.value) : null}
    case MattrTypes.Boolean: 
      return {...value, value: value.value ? value.value === "true" ? true : false : null}
    default:
      return value
  }
}
