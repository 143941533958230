import { useEffect, useState } from 'react';
import HiddenFieldsList from './list';
import { Field } from '../../../core/graphql/types';

interface HiddenFieldsProps {
  fields: Field[];
  hiddenFields: string[] | [];
  onChangeFields: (state: string[] | []) => void;
}

function HiddenFields({fields, hiddenFields, onChangeFields}: HiddenFieldsProps) {
  const handleChange = (id: string) => {
    let ns: string[] | [] = []
    if(hiddenFields.some(it => it === id)) {
      ns = hiddenFields.filter(it => it !== id)
    } else {
      ns = [...hiddenFields, id]
    }
    
    onChangeFields(ns)
  }

  const handleHideAll = () => {
    let selFields: any = []
    fields.map((field: any) => {
      if(!field.primary) {
        selFields.push(field.id)
      }
    })
    onChangeFields(selFields)
  }

  const handleShowAll = () => {
    onChangeFields([])
  }

  return (
    <HiddenFieldsList fields={fields} selection={hiddenFields} handleChange={handleChange} handleShowAll={handleShowAll} handleHideAll={handleHideAll}/> 
  )
}

export default HiddenFields;