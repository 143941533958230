import { BlockTypeEnum } from "../../../../../core/graphql/types"
import BlockCalendarSettings from "../BlockCalendar/settings"
import BlockFieldSettings from "../BlockField/settings"
import BlockFormSettings from "../BlockForm/settings"
import BlockGridSettings from "../BlockGrid/settings"
import BlockKanbanSettings from "../BlockKanban/settings"
import BlockRecordsListSettings from "../BlockRecordsList/settings"
import BlockSectionSettings from "../BlockSection/settings"
import SummaryBlockSettings from "../BlockSummary/settings"


const BlockSettings = ({type}:{type: BlockTypeEnum}) => {
  function getBlockSettings(type: BlockTypeEnum) {
    switch (type) {
      case BlockTypeEnum.BlockCalendar: return <BlockCalendarSettings />
      case BlockTypeEnum.BlockKanban: return <BlockKanbanSettings />
      case BlockTypeEnum.BlockGrid: return <BlockGridSettings />
      case BlockTypeEnum.BlockRecordsList: return <BlockRecordsListSettings />
      case BlockTypeEnum.BlockForm: return <BlockFormSettings />
      case BlockTypeEnum.BlockSection: return <BlockSectionSettings/>
      case BlockTypeEnum.BlockField: return <BlockFieldSettings />
      case BlockTypeEnum.BlockSummary: return <SummaryBlockSettings />
      default: return <></>
    }
  }

  return (
    getBlockSettings(type!)
  )
}

export default BlockSettings;