import React from "react";
import { useForm, Controller } from "react-hook-form";
import toast from 'react-hot-toast';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import { ForSelect, User } from "../../../../core/graphql/types";
import MatrSelect from "../../../../components/ui/select/base";

interface InviteUserToSpaceFormProps {
  roles: ForSelect[] | any;
  onChange: any;
  closeModal: () => void;
}

export function InviteUserToSpaceForm({ roles, onChange, closeModal }:InviteUserToSpaceFormProps) {
  const { register, handleSubmit, control, formState: { errors } } = useForm();

  const onSubmit = handleSubmit(data => {
    onChange(data)
    closeModal()
  });

  // const ExpiredAtCustomInput: any = forwardRef(({ value, onClick, onChange }: {value: any, onClick: any, onChange: any}, ref) => (
  //   <div onClick={onClick} style={{minWidth: 100}} className="user h-10">
  //     <input onChange={(e) => onChange(e)} type="text" value={value} className="text-sm form-input py-2.5 block h-10 w-full rounded-sm bg-gray-100 border-0 focus:bg-white focus:ring focus:ring-indigo-200 focus:ring-opacity-50"/>
  //   </div>
  // ));

  return (
    <form onSubmit={onSubmit} className="flex flex-col relative w-full space-y-4">
      <div className="flex h-14 w-full">
        <h2 className="text-xl text-slate-800 font-semibold">Пригласить участника</h2>
      </div>
      <div className="flex flex-col space-y-4">
        <div className="relative flex flex-col">
          <label className="font-medium text-gray-600 text-sm mb-2">Электронный адрес:</label>
          <input {...register("email", 
            { required: true, 
              pattern: /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ 
            })}
            className="matr-form-input"
            placeholder="Введите e-mail пользователя"
          />
          {errors.user && <span className="text-red-600 text-xs font-normal px-2">Неверный формат адреса</span>}
        </div>
        <div className="relative flex flex-col">
          <label className="font-medium text-gray-600 text-sm mb-2">Роль участника в пространстве:</label>
          <Controller
            name="role"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value, ref } }) => (
              <MatrSelect
                styles={{control: () => ({border: 0})}}
                classNamePrefix="matr_select_custom"
                placeholder="Роль"
                value={value}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                onChange={onChange}
                options={roles ? roles : []} />
            )} />
          {errors.role && <span className="text-red-600 text-xs font-normal py-2">Роль обязательное поле.</span>}
        </div>
        {/* <div className="relative flex flex-col">
          <label className="font-medium text-gray-600 text-sm mb-2">Доступ истекает:</label>
          <Controller
            name="expired_at"
            control={control}
            render={({ field: {value, onChange, ref} }) => (
              <DatePicker
                selected={value}
                ref={ref}
                className="user"
                customInput={<ExpiredAtCustomInput />}
                onChange={(date:Date) => onChange(date)}
              /> 
            )} 
          />
        </div> */}
        <div className="flex justify-between w-full pt-6">
          <button
            type="button"
            className="inline-flex justify-end px-4 py-2 text-sm font-medium text-indigo-700 bg-indigo-100 border border-transparent rounded-md hover:bg-indigo-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-indigo-500"
            onClick={closeModal}
          >
            Отменить
          </button>
          <button
            type="submit"
            className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md hover:bg-indigo-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-indigo-500"
          >
            Пригласить
          </button>
        </div>
      </div>
    </form>
  );
}