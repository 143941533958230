import React, {Fragment, useCallback, useRef, useState} from "react";
import { Transition, Dialog, Listbox, Tab } from "@headlessui/react";
import { useForm, Controller, FormProvider, useWatch } from "react-hook-form";
import { debounce } from "debounce";
import * as Yup from 'yup';
import { HiSelector } from 'react-icons/hi';
import { requestMethods } from "../../../../core/enums";
import { useFetcherContext } from "../../../../core/providers/fetcher";
import { TbSquaresFilled, TbX } from "react-icons/tb";
import { MatrMediumDialog } from "../../../../components/ui/portal";
import { useSpaceStore } from "../../../../core/store/store";
import FetcherVariables from "./variables";
import useDeepCompareEffect from "use-deep-compare-effect";
import { useUpdateFetcherMutation } from "../../../../core/graphql/types";

const validationSchema = Yup.object().shape({
  title: Yup.string().required(),
  description: Yup.string().nullable(),
  variables: Yup.array().of(
    Yup.object().shape({
      key: Yup.string().required('Ключ заголовка: Обязательное поле'),
      value: Yup.string().nullable(),
      disabled: Yup.boolean().default(false)
    })
  ),
});


const EditFetcher = () => {
  const {space} = useSpaceStore()
  const { fetcher, updateFetcher } = useFetcherContext()
  const methods = useForm({defaultValues: fetcher!})
  const { register, handleSubmit, getValues, control, formState } = methods
  const [error, setError] = useState(null)

  // Обновление

  const debouncedSave = useCallback(
    debounce(() => {
      updateFetcher(getValues())
    }, 1000),
    []
  );

  const watchedData = useWatch({
    control: control,
    defaultValue: fetcher!
  });

  useDeepCompareEffect(() => {
    if (formState.isDirty) {
      validationSchema.isValid(getValues()).then(res => {
        if(res) {
          setError(null)
          debouncedSave()
        } else {
          validationSchema.validate(getValues()).catch(res => setError(res.message))
          debouncedSave.clear()
        }
      })
    }
  }, [watchedData]);

  return (
    <div className="w-full">
      <FormProvider {...methods} >
          <div className="relative w-full mb-4 mt-4">
            <input 
                {...register("title", {required: true})} 
                className="text-xl font-medium text-slate-800 placeholder:text-slate-300 focus:outline-none w-full" 
                placeholder="Название сервиса"
            />
          </div>
          <Tab.Group>
            <Tab.List className="text-base border-b">
              <Tab >{({ selected }) => (<div className={`px-3 py-1.5  font-medium ${selected ? "text-indigo-600 border-b-2 border-indigo-600" : "text-slate-600"} `}>Описание</div>)}</Tab>
              <Tab >{({ selected }) => (<div className={`px-3 py-1.5  font-medium ${selected ? "text-indigo-600 border-b-2 border-indigo-600" : "text-slate-600"} `}>Переменные</div>)}</Tab>
            </Tab.List>
            <Tab.Panels>
              <Tab.Panel className="py-2 space-y-2">
                <div className="my-2">
                  <textarea 
                    {...register("description")} 
                    className="text-base font-medium text-slate-800 placeholder:text-slate-300 focus:outline-none w-full" 
                    placeholder="Описание сервиса"
                  />
                </div>
              </Tab.Panel>
              <Tab.Panel className="py-2 space-y-2">
                <FetcherVariables />
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
      </FormProvider>
    </div>
  )
  
};

export default EditFetcher;