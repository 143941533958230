import type { FormFieldEditorProps } from './types';
import { CheckboxIcon } from "../../icons";
import { Controller, useFormContext } from 'react-hook-form';
import { BiToggleRight, BiToggleLeft } from 'react-icons/bi';


export default function FormCheckboxEditor({formField, value}: FormFieldEditorProps) {
  const methods = useFormContext();
  const {control} = methods;

  function handleChange(value: any, onChange: { (...event: any[]): void; (arg0: any): void; }) {
    onChange(!value)
  }

  return (
    <div className="relative flex flex-col">
      <div className="flex items-center h-full space-x-2">
        <div className='text-slate-400'><CheckboxIcon /></div>
        <label className="font-medium text-indigo-600 text-sm">{formField.name} {formField.required ? <span className="text-red-600">*</span> : ""}</label>
      </div>
      <div className="flex mt-2">
        <Controller
          key={formField.id}
          name={`${formField.fieldId}`}
          control={control}
          defaultValue={value ? value : false}
          rules={{required: formField.required}}
          render={({ field: { onChange, value, ref }}) => (
            <div 
              className={`flex items-center py-2 px-4 text-sm ${value ? "bg-green-300 text-green-800" : "bg-slate-200 text-slate-600"} rounded-md font-normal justify-between w-full cursor-default`} 
              onClick={() => handleChange(value, onChange)}
            >
              {value ? <BiToggleRight className='h-6 w-6 text-green-800'/> : <BiToggleLeft className='h-6 w-6 text-slate-400'/>}
              {value ? "Выбрано" : "Не выбрано"}
            </div>
          )}
        />
      </div>
    </div>
  );
}