import React, {useState, useEffect} from "react";
import { useFormContext } from "react-hook-form";
import { useGetOptionsFromValuesQuery, Field, FieldAttributes, Select, SelectOption } from "../../../../../core/graphql/types";
import { SmallSpinner } from "../../../../loaders";

import OptionList from "./options";


const uniqueId = () => Math.floor(Math.random() * Date.now());

function SelectField({field}: {field: Field}): JSX.Element {
  const methods = useFormContext();
  const [attributes, setAttributes] = useState<Select>()
  const [load, setLoad] = useState(true);
  const {register, unregister, setValue} = methods;

  const [{fetching: fetchingOptions, error: errorOptions, data: dataOptions }, getOptions] = useGetOptionsFromValuesQuery({variables: {fieldId: field.id, type: field.type}, pause: true})

  useEffect(() => {
    if(!field.id || (field.id && ["SELECT", "MULTISELECT"].includes(field.type))) {
      setAttributes(field.attributes as Select)
      setLoad(false)
    } else {
      getOptions()
    }
  },[field])

  useEffect(() => {
    if (dataOptions && dataOptions.getOptionsFromValues && !fetchingOptions) {
      const options: SelectOption[] = []
      dataOptions.getOptionsFromValues.map(item => {
        options.push({id: `${uniqueId()}`, name: item!})
      })
      setAttributes({...attributes, options: options} as Select)
      setLoad(false)
    }
  }, [dataOptions, fetchingOptions]);


  useEffect(() => {
    unregister('attributes');
    register('attributes.type');
    setValue('attributes.type', "SELECT");
  },[])


  return (
    load ? <SmallSpinner /> :
    <div className="container">
      <OptionList
        attributes={attributes as Select}
      />
    </div>
  );
}
export default SelectField;