import { Column, RenderHeaderCellProps } from 'react-data-grid';
import { Field, Formula, Relation, useDeleteFieldMutation, FieldAttributes, FieldType, FieldSummaryType, useFieldWidthMutation, useFieldPositionsMutation } from '../../../../core/graphql/types';
import { StringIcon, UrlIcon, EmailIcon, TextareaIcon, NumberIcon, PhoneIcon, DecimalIcon, PercentIcon, CurrencyIcon, SelectIcon, MultiSelectIcon, DateTimeIcon, InsertedAtIcon, RelationIcon, LookupIcon, CheckboxIcon, FormulaIcon, ErrorIcon, CollaboratorIcon, UpdatedAtIcon, CreatedByIcon, UpdatedByIcon, DurationIcon, AttachmentIcon, RecordidIcon } from '../../../icons'
import { Row } from '../types';
import { useBoard } from '../../../../core/providers/board';
import ModalField from '../../../modals/field';
import { HiOutlineMenu, HiOutlinePlus } from 'react-icons/hi';
import { ChangeEvent, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import ColumnContextMenu from '../menus/ColumnContextMenu';
import { useSpaceStore } from '../../../../core/store/store';
import { MatrPopover } from '../../../ui/portal';


interface BaseRenderHeaderCellProps<TRow, TSummaryRow> extends RenderHeaderCellProps<TRow, TSummaryRow> {
  field: Field;
}

interface DraggableHeaderRendererProps<TRow, TSummaryRow> extends RenderHeaderCellProps<TRow, TSummaryRow> {
  field: Field;
  onColumnsReorder: (sourceKey: string, targetKey: string) => void;
}


export function HeaderRenderer<R>(props:BaseRenderHeaderCellProps<R, any>) {
  const { currentSpaceUser } = useSpaceStore()
  const {initId, initType} = useBoard();
  const init: string = `${initType}:${initId}`;
  const store = useSpaceStore()
  const {setColumns} = store.boardActions
  const {board, columns} = store.data[init];
  const [,changeFieldPositions] = useFieldPositionsMutation()


  const changePositions = (positions: string[]) => {changeFieldPositions({boardId: board!.id, positions: positions})}

  function handleColumnsReorder(sourceKey: string, targetKey: string) {
    const sourceColumnIndex = columns!.findIndex((c: Column<Row>) => c.key === sourceKey);
    const targetColumnIndex = columns!.findIndex((c: Column<Row>) => c.key === targetKey);
    const reorderedColumns = [...columns!];
    const positions: string[] = []

    reorderedColumns.splice(
      targetColumnIndex!,
      0,
      reorderedColumns.splice(sourceColumnIndex!, 1)[0]
    );
    
    setColumns(reorderedColumns, init);

    reorderedColumns.map((rc) => {
      // if(rc?.id !== undefined && !["select-row", "expand-row", "create"].includes(rc?.id)) {positions.push(rc.key)}
      if(rc?.key !== undefined && !["select-row", "create"].includes(rc?.key)) {positions.push(rc.key)}
    })
    changePositions(positions)
  }

  switch (props.column.key) {
    case "select-row": return <></>
    case "create": return <></>
    default: return currentSpaceUser?.role?.name === "Administrator" ? <DraggableHeaderRenderer {...props} onColumnsReorder={handleColumnsReorder}/> : <BasicHeader {...props} />
  }
}

export function DraggableHeaderRenderer<R>({
  onColumnsReorder,
  column,
  field,
  ...props
}: DraggableHeaderRendererProps<R, any>) {
  const {initId, initType, board, fields, currentView, setModal, setFieldModalIsOpened} = useBoard();
  const init: string = `${initType}:${initId}`;
  const [ buttonRef, setButtonRef ] = useState<any>()

  const [ openMenu, setOpenMenu ] = useState(false);

  const [cursor, setCursor] = useState('default')
  const [, deleteField] = useDeleteFieldMutation()

  function onOpenMenu(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) { setOpenMenu(true)}
  function closeMenu() { setOpenMenu(false) };

  const [{ isDragging }, drag] = useDrag({
    type: 'COLUMN_DRAG',
    item: { key: column.key },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  });

  const [{ isOver }, drop] = useDrop({
    accept: 'COLUMN_DRAG',
    drop({ key }: { key: string }) {
      onColumnsReorder(key, column.key);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    })
  });

  function handleClickContextMenu(event: ChangeEvent<Element>, action: string) {
    switch (action) {
      case 'edit':
        showFieldModal(field!, column)
        break;
      case 'remove':
        deleteField({boardId: field.boardId, id: field.id})
        break;
      default:
        break;
    }
  }

  function closeFieldModal() {
    setFieldModalIsOpened(false, init)
  }
  
  function showFieldModal(field: Field, column: any) {
    if(currentView?.permissions?.canEditField) {
      setFieldModalIsOpened(true, init)
      setModal(<ModalField open={true} close={closeFieldModal} field={field!} column={column!} fields={fields} boardId={board!.id!}/>, init)
    }
  }
  
  return (
    <div
      ref={(ref) => {
        setButtonRef(ref)
        drag(ref);
        drop(ref);
      }}
      style={{
        opacity: isDragging ? 0.5 : 1,
        backgroundColor: isOver ? '#ececec' : undefined,
        cursor: cursor,
        zIndex: 10007
      }}
      className="flex flex-row items-center w-full pr-1"
      onDoubleClick={() => showFieldModal(field!, column)}
    >
      <div className="flex-none h-8 w-8">
        {(field.type === "RELATION" && (field.attributes as Relation).relationStatus === "error") || (field.type === "FORMULA" && (field.attributes as Formula).valid === false) ? <ColumnIcon type = {"ERROR"}/> : <ColumnIcon type = {field.type}/>}
      </div>

      <div className="grow w-full text-s font-medium truncate">{column.name}</div>
      {currentView?.permissions?.canEditField ?
        <>
          <button
            onClick={(e) => onOpenMenu(e)}
            className="h-5 w-5 text-slate-600"
          >
            <HiOutlineMenu className="w-4 h-4"/>
          </button>
          {openMenu && <MatrPopover onClose={closeMenu} reference={buttonRef} placement="bottom-start">
            <ColumnContextMenu width={column.width as number} handleClick={handleClickContextMenu} column={column} field={field}/>
          </MatrPopover>}
        </>
        :
        <></>
      }
    </div>
  );
}

export function BasicHeader<R>({
  field,
  column,
  ...props
}: BaseRenderHeaderCellProps<R, any>) {
  return (
    <div className="flex items-center w-full h-full justify-between">
      <div className="flex flex-row items-center w-full pr-1">
        <div className="flex-none h-8 w-8">
          {(field.type === "RELATION" && (field.attributes as Relation).relationStatus === "error") || (field.type === "FORMULA" && (field.attributes as Formula).valid === false) ? <ColumnIcon type = {"ERROR"}/> : <ColumnIcon type = {field.type}/>}
        </div>
        <div className="grow w-full text-xs font-medium truncate">{column.name}</div>
      </div> 
    </div>
  );
}

export function CreateHeaderRenderer<R>(props: RenderHeaderCellProps<R, any>) {
  const {initId, initType} = useBoard();
  const init: string = `${initType}:${initId}`;
  const store = useSpaceStore()
  const {setModal, setFieldModalIsOpened} = store.boardActions
  const {board, fields, currentView} = store.data[init];

  const {column} = props;
  const field: Field = {
    boardId: board!.id,
    id: "",
    title: "",
    type: FieldType.String,
    position: 0,
    width: 200,
    summaryType: FieldSummaryType.None,
    attributes: {} as FieldAttributes
  }


  function closeFieldModal() {
    setFieldModalIsOpened(false, init)
  }
  
  function showFieldModal(field: Field, column: any) {
    setFieldModalIsOpened(true, init)
    setModal(<ModalField open={true} close={closeFieldModal} field={field!} column={column!} fields={fields} boardId={board!.id!}/>, init)
  }
  
  return (
    currentView?.permissions?.canAddField ? 
    <div className="flex items-center w-full h-full justify-center" onClick={() => showFieldModal(field!, column)}>
      <HiOutlinePlus className="w-4 h-4" />
    </div>
    :
    <></>
  );
}

export function ColumnIcon({ type }: { type: string; }) { 
  function getIcon() { 
    switch (type) {
      case '': return <></> 
      case 'STRING': return <div className="bg-slate-300 rounded-sm w-5 h-5 flex justify-center items-center"><StringIcon /></div>
      case 'URL': return <div className="bg-slate-300 rounded-sm w-5 h-5 flex justify-center items-center"><UrlIcon /></div>
      case 'EMAIL': return <div className="bg-slate-300 rounded-sm w-5 h-5 flex justify-center items-center"><EmailIcon /></div>
      case 'TEXTAREA': return <div className="bg-blue-400 rounded-sm w-5 h-5 flex justify-center items-center "><TextareaIcon /></div>
      case 'NUMBER': return <div className="bg-blue-300 rounded-sm w-5 h-5 flex justify-center items-center"><NumberIcon /></div>
      case 'RECORDID': return <div className="bg-indigo-100 rounded-sm w-5 h-5 flex justify-center items-center"><RecordidIcon /></div>
      case 'DURATION': return <div className="bg-blue-300 rounded-sm w-5 h-5 flex justify-center items-center"><DurationIcon /></div>
      case 'PHONE': return <div className="bg-blue-300 rounded-sm w-5 h-5 flex justify-center items-center"><PhoneIcon /></div>
      case 'DECIMAL': return <div className="bg-blue-300 rounded-sm w-5 h-5 flex justify-center items-center"><DecimalIcon /></div>
      case 'PERCENT': return <div className="bg-blue-300 rounded-sm w-5 h-5 flex justify-center items-center"><PercentIcon /></div>
      case 'CURRENCY': return <div className="bg-blue-300 rounded-sm w-5 h-5 flex justify-center items-center"><CurrencyIcon /></div>
      case 'SELECT': return <div className="bg-indigo-200 rounded-sm w-5 h-5 flex justify-center items-center"><SelectIcon /></div>
      case 'MULTISELECT': return <div className="bg-indigo-200 rounded-sm w-5 h-5 flex justify-center items-center"><MultiSelectIcon /></div>
      case 'COLLABORATOR': return <div className="bg-indigo-200 rounded-sm w-5 h-5 flex justify-center items-center"><CollaboratorIcon /></div>
      case 'DATETIME': return <div className="bg-green-200 rounded-sm w-5 h-5 flex justify-center items-center"><DateTimeIcon /></div>
      case 'INSERTED_AT': return <div className="bg-green-200 rounded-sm w-5 h-5 flex justify-center items-center"><InsertedAtIcon /></div>
      case 'UPDATED_AT': return <div className="bg-yellow-200 rounded-sm w-5 h-5 flex justify-center items-center"><UpdatedAtIcon /></div>
      case 'CREATED_BY': return <div className="bg-yellow-200 rounded-sm w-5 h-5 flex justify-center items-center"><CreatedByIcon /></div>
      case 'UPDATED_BY': return <div className="bg-yellow-200 rounded-sm w-5 h-5 flex justify-center items-center"><UpdatedByIcon /></div>
      case 'RELATION': return <div className="bg-pink-400 rounded-sm w-5 h-5 flex justify-center items-center"><RelationIcon /></div>
      case 'CONNECT': return <div className="bg-pink-400 rounded-sm w-5 h-5 flex justify-center items-center"><RelationIcon /></div>
      case 'LOOKUP': return <div className="bg-pink-200 rounded-sm w-5 h-5 flex justify-center items-center"><LookupIcon /></div>
      case 'CHECKBOX': return <div className="bg-red-200 rounded-sm w-5 h-5 flex justify-center items-center"><CheckboxIcon /></div>
      case 'FORMULA': return <div className="bg-purple-400 rounded-sm w-5 h-5 flex justify-center items-center"><FormulaIcon /></div>
      case 'ATTACHMENT': return <div className="bg-sky-400 rounded-sm w-5 h-5 flex justify-center items-center"><AttachmentIcon /></div>
      case 'ERROR': return <div className="bg-red-600 rounded-sm w-5 h-5 flex justify-center items-center text-white"><ErrorIcon /></div>
    }
  }
  return (
    <div className="flex items-center justify-center w-full h-full">{getIcon()}</div>
  )
}