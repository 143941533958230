import { Kind, DocumentNode, OperationDefinitionNode } from 'graphql';
import { filter, merge, mergeMap, pipe, share, takeUntil, onPush } from 'wonka';
import { Exchange } from '@urql/core';

import {
  makeFetchBody,
  makeFetchURL,
  makeFetchOptions,
  makeFetchSource,
} from '@urql/core/internal';

type UploadFileType = File;

interface ExtractedFile {
  file: UploadFileType | UploadFileType[];
  name: string;
}

function isUploadFile(value: any): value is UploadFileType {
  return typeof File !== 'undefined' && value instanceof File;
}

function isObject(value: any) {
  return value !== null && typeof value === 'object';
}

function isFileList(value: any): value is FileList {
  return typeof FileList !== 'undefined' && value instanceof FileList;
}

type IsUploadFileType = typeof isUploadFile;

const extractFiles = (
  variables: object,
  isUploadFile: IsUploadFileType,
): { variables: object; files: ExtractedFile[] } => {
  const files: ExtractedFile[] = [];
  const walkTree = (
    tree: any[] | object,
    path: string[] = [],
  ): any[] | object => {
    const mapped: any = Array.isArray(tree) ? [...tree] : { ...tree };
    Object.keys(mapped).forEach((key) => {
      const value = mapped[key];
      if (isUploadFile(value) || isFileList(value)) {
        const name = [...path, key].join('.');
        const file = isFileList(value)
          ? Array.prototype.slice.call(value)
          : value;
        files.push({ file, name });
        mapped[key] = name;
      } else if (isObject(value)) {
        mapped[key] = walkTree(value, [...path, key]);
      }
    });
    return mapped;
  };

  return {
    files,
    variables: walkTree(variables),
  };
};

const getOperationName = (query: DocumentNode): string | null => {
  const node = query.definitions.find(
    (node: any): node is OperationDefinitionNode => {
      return node.kind === Kind.OPERATION_DEFINITION && node.name;
    },
  );

  return node && node.name ? node.name.value : null;
};



export const abMultipartFetchExchange: Exchange = ({
  forward,
  dispatchDebug,
}) => ops$ => {
  const sharedOps$ = share(ops$);
  const fetchResults$ = pipe(
    sharedOps$,
    filter(operation => {
      return operation.kind === 'query' || operation.kind === 'mutation';
    }),
    mergeMap(operation => {
      const teardown$ = pipe(
        sharedOps$,
        filter(op => op.kind === 'teardown' && op.key === operation.key)
      );

      const { files, variables } = extractFiles(
        operation.variables || {},
        isUploadFile,
      );

      const operationName = getOperationName(operation.query);

      const body = makeFetchBody({ query: operation.query, variables });

      if (operationName !== null) {
        body.operationName = operationName;
      }


      let url: string;
      let fetchOptions: RequestInit | any;
      if (!!files.length) {
        url = makeFetchURL(operation);
        fetchOptions = makeFetchOptions(operation);
        if (fetchOptions.headers!['content-type'] === 'application/json') {
          delete fetchOptions.headers!['content-type'];
        }
        const formData = new FormData();
        // Make fetch auto-append this for correctness
  
        formData.append('query', body.query!);
        formData.append('variables', JSON.stringify(variables));
  
        files.forEach(({ name, file }) => {
          formData.append(name, file as any);
        });
        fetchOptions.body = formData;
      } else {
        fetchOptions = makeFetchOptions(operation, body);
        url = makeFetchURL(operation, body);
      }
      
      dispatchDebug({
        type: 'fetchRequest',
        message: 'A fetch request is being executed.',
        operation,
        data: {
          url,
          fetchOptions,
        },
      });

      return pipe(
        makeFetchSource(operation, url, fetchOptions),
        takeUntil(teardown$),
        onPush(result => {
          const error = !result.data ? result.error : undefined;

          dispatchDebug({
            type: error ? 'fetchError' : 'fetchSuccess',
            message: `A ${
              error ? 'failed' : 'successful'
            } fetch response has been returned.`,
            operation,
            data: {
              url,
              fetchOptions,
              value: error || result,
            },
          });
        })
      );
    })
  );

  const forward$ = pipe(
    sharedOps$,
    filter(operation => {
      return operation.kind !== 'query' && operation.kind !== 'mutation';
    }),
    forward
  );

  return merge([fetchResults$, forward$]);
};