import * as Icons from "../../../icons";
import { typeOptions, primaryTypeOptions } from "../../../../core/enums";

interface OptionProps {
  value: string;
  min_label: string;
  label: string;
  default_title: string;
  icon: JSX.Element;
}

// export const typeOptions = [
//   { value: "STRING", min_label: 'СТРОКА', label: 'Одиночная строка', default_title: "Новая строка", icon: <Icons.StringIcon/>},
//   { value: "URL", min_label: 'URL', label: 'Url', default_title: "Адрес", icon: <Icons.UrlIcon/>},
//   { value: "EMAIL", min_label: 'EMAIL', label: 'Email', default_title: "Адрес", icon: <Icons.EmailIcon/>},
//   { value: "TEXTAREA", min_label: 'ТЕКСТ', label: 'Текст', default_title: "Новый текст", icon: <Icons.TextareaIcon/>},
//   { value: "CHECKBOX", min_label: 'ЧЕКБОКС', label: 'Чекбокс', default_title: "Новый выбор", icon: <Icons.CheckboxIcon/>},
//   { value: "NUMBER", min_label: 'ЧИСЛО', label: 'Число', default_title: "Простое число", icon: <Icons.NumberIcon/>},
//   { value: "PHONE", min_label: 'ТЕЛЕФОН', label: 'Телефон', default_title: "Номер телефона", icon: <Icons.PhoneIcon/>},
//   { value: "DECIMAL", min_label: 'ДРОБЬ', label: 'Дробное число', default_title: "Дробное число", icon: <Icons.DecimalIcon/>},
//   { value: "PERCENT", min_label: 'ПРОЦЕНТ', label: 'Процент', default_title: "Процент", icon: <Icons.PercentIcon/>},
//   { value: "CURRENCY", min_label: 'ВАЛЮТА', label: 'Денежное поле', default_title: "Деньги", icon: <Icons.CurrencyIcon/>},
//   { value: "SELECT", min_label: 'ОДИНОЧНЫЙ ВЫБОР', label: 'Одиночный выбор', default_title: "Одиночный выбор", icon: <Icons.SelectIcon/>},
//   { value: "MULTISELECT", min_label: 'МНЮЕСТВЕННЫЙ ВЫБОР', label: 'Множественный выбор', default_title: "Множественный выбор", icon: <Icons.MultiSelectIcon/>},
//   { value: "COLLABORATOR", min_label: 'ПОЛЬЗОВАТЕЛИ', label: 'Пользователи', default_title: "Пользователи", icon: <Icons.CollaboratorIcon/>},
//   { value: "RELATION", min_label: 'СВЯЗЬ С ДРУГОЙ ДОСКОЙ', label: 'Связь с другой доской', default_title: "Связь с источником", icon: <Icons.RelationIcon/>},
//   { value: "LOOKUP", min_label: 'ОТБРАЖЕНИЕ ИЗ ДРУГОЙ ДОСКИ', label: 'Показывать данные из связанной карточки', default_title: "Из источника", icon: <Icons.LookupIcon/>},
//   { value: "DATETIME", min_label: 'ДАТА И ВРЕМЯ', label: 'Дата и время', default_title: "Дата и время", icon: <Icons.DateTimeIcon/>},
//   { value: "INSERTED_AT", min_label: 'ДАТА СОЗДАНИЯ', label: 'Дата создания', default_title: "Создано", icon: <Icons.InsertedAtIcon/>},
//   { value: "UPDATED_AT", min_label: 'ДАТА ОБНОВЛЕНИЯ', label: 'Дата обновления', default_title: "Обновлено", icon: <Icons.UpdatedAtIcon/>},
//   { value: "CREATED_BY", min_label: 'КТО СОЗДАЛ', label: 'Создано пользователем', default_title: "Создал", icon: <Icons.CreatedByIcon/>},
//   { value: "UPDATED_BY", min_label: 'КТО ОБНОВИЛ', label: 'Обновлено пользователем', default_title: "Обновил", icon: <Icons.UpdatedByIcon/>},
//   { value: "FORMULA", min_label: 'ФОРМУЛА', label: 'Формула', default_title: "Расчет", icon: <Icons.FormulaIcon/>}
// ];

// export const primaryTypeOptions = [
//   { value: "STRING", min_label: 'СТРОКА', label: 'Одиночная строка', default_title: "Новая строка", icon: <Icons.StringIcon/>},
//   { value: "URL", min_label: 'URL', label: 'Url', default_title: "Адрес", icon: <Icons.UrlIcon/>},
//   { value: "EMAIL", min_label: 'EMAIL', label: 'Email', default_title: "Адрес", icon: <Icons.EmailIcon/>},
//   { value: "TEXTAREA", min_label: 'ТЕКСТ', label: 'Текст', default_title: "Новый текст", icon: <Icons.TextareaIcon/>},
//   { value: "NUMBER", min_label: 'ЧИСЛО', label: 'Число', default_title: "Простое число", icon: <Icons.NumberIcon/>},
//   { value: "PHONE", min_label: 'ТЕЛЕФОН', label: 'Телефон', default_title: "Номер телефона", icon: <Icons.PhoneIcon/>},
//   { value: "DECIMAL", min_label: 'ДРОБЬ', label: 'Дробное число', default_title: "Дробное число", icon: <Icons.DecimalIcon/>},
//   { value: "PERCENT", min_label: 'ПРОЦЕНТ', label: 'Процент', default_title: "Процент", icon: <Icons.PercentIcon/>},
//   { value: "CURRENCY", min_label: 'ВАЛЮТА', label: 'Денежное поле', default_title: "Деньги", icon: <Icons.CurrencyIcon/>},
//   { value: "DATETIME", min_label: 'ДАТА И ВРЕМЯ', label: 'Дата и время', default_title: "Дата и время", icon: <Icons.DateTimeIcon/>},
//   { value: "FORMULA", min_label: 'ФОРМУЛА', label: 'Формула', default_title: "Расчет", icon: <Icons.FormulaIcon/>}
// ];

export const formatOptionLabel = ({ value, label, default_title, icon }: OptionProps ) => (

    <div className="flex flex-row w-full justify-between">
      <div className="flex flex-row">
        <div className="rounded-sm w-5 h-5 items-center mr-2 flex justify-center">{icon}</div>
        <div>{label}</div>
      </div>
      <div style={{ marginLeft: "10px", color: "#ccc" }}>
        {default_title}
      </div>
    </div>


);