import { useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";

import * as Yup from 'yup';

import { TbSquaresFilled, TbX } from "react-icons/tb";
import { Block, BlockSourceTypeEnum, BlockTypeEnum, Page } from "../../../../../core/graphql/types";
import { usePanel } from "../../../../../core/providers/panel";
import BlockSettings from "./settings";
import { getBlockWHByType } from "../../../../../core/Controllers/Panel/blocks_functions";

const blocks = [
    {
      name: "Текстовый блок",
      description: "описание",
      value: BlockTypeEnum.BlockSection,
      options: {type: BlockTypeEnum.BlockSection}
    },
    {
      name: "Таблица",
      description: "описание",
      value: BlockTypeEnum.BlockGrid,
      options: {type: BlockTypeEnum.BlockGrid}
    },
    {
      name: "Сводный",
      description: "описание",
      value: BlockTypeEnum.BlockSummary,
      options: {type: BlockTypeEnum.BlockSummary}
    }
]

// Написать валидацию для каждого блока
const validationSchema = Yup.object().shape({

});

export const BlockCreate = ({inBlock, onClose}:{inBlock: Block | null, onClose: any}) => {
  const {page, addBlock} = usePanel()
  const methods = useForm();
  const { handleSubmit, control, getValues, setValue, formState: { errors } } = methods;
  const [blockType, setBlockType] = useState<BlockTypeEnum | null>(null)
  const [ error, setError ] = useState(null);

  function handleChageBlock(b: any, onChange: (...event: any[]) => void) {
    setBlockType(b.value)
    setValue('pageId', page.id)
    setValue('parentId', inBlock ? inBlock.id : null)

    let wh = getBlockWHByType(BlockTypeEnum.BlockField, b.value)
    setValue('position', 
    {...wh, ...{
      x: page?.blocks && page.blocks.length > 0 ? page.blocks.length + 1 : 0,
      y: 1
    }}
    )
    setValue('options', b.options)
    onChange(b.value)
  }


  function backToType() { setBlockType(null)}

  const onSubmit = () => { 
    validationSchema.isValid(getValues()).then(res => {
      if(res) {
        let data = getValues()
        setError(null)
        addBlock(data as Block)
        onClose()
      } else {
        validationSchema.validate(getValues()).catch(res => setError(res.message))
      }
    })
  };



  return (
    <>
      <div className="flex items-center justify-between">
        <div className="flex items-center space-x-2">
          <div className="py-1 px-2 bg-slate-100 flex space-x-2 rounded">
            <TbSquaresFilled size={20} className="text-indigo-600"/>
            <div className="flex font-medium text-sm">{page.name}</div>
          </div>
          <div className="flex font-normal text-sm">Добавление нового блока</div>
        </div>
        <button onClick={onClose}><TbX size={18}/></button>
      </div>
      <FormProvider {...methods}>
        <div className="flex flex-col relative w-full space-y-4">
          {!blockType && 
            <div className="relative space-y-2 mt-4">
                <Controller
                name="type"
                control={control}
                rules={{required: true}}
                render={({ field: { onChange, value }}) => (
                    <>
                        {blocks.map(b => (
                            <div className="flex flex-col px-2 py-1.5 bg-slate-50" key={b.value} onClick={() => handleChageBlock(b, onChange)}>
                                <span className="text-base text-slate-800">{b.name}</span>
                                <span className="text-sm text-slate-400">{b.description}</span>
                            </div>
                        ))}
                    </>
                )}
                />
            </div>
          }
          {blockType && <BlockSettings type={blockType}/>}
          <div className="flex w-full h-full justify-between pt-4 items-center">
            {blockType &&  <button
                type="button"
                className="rounded text-sm py-2 px-4 bg-slate-200 text-slate-400 hover:bg-slate-300 hover:text-slate-500"
                onClick={backToType}
            >Назад</button>}
            <div className="flex w-full h-full justify-end space-x-4">
              <button
                type="button"
                className="rounded text-sm py-2 px-4 bg-slate-200 text-slate-400 hover:bg-slate-300 hover:text-slate-500"
                onClick={onClose}
              >Отменить</button>
              <button type="button" onClick={() => onSubmit()} className="rounded text-sm py-2 px-4 bg-indigo-600 text-white hover:bg-indigo-700">Сохранить</button>
            </div>
          </div>
        </div>
      </FormProvider>
    </>

  )
};