import { useFormContext } from "react-hook-form";
import { LayoutEnum } from "../../../../core/graphql/types";
import PageLayoutBlankOptions from "./options/blankOptions";
import PageLayoutCalendarOptions from "./options/calendarOptions";
import PageLayoutRecordsListOptions from "./options/recordsListOptions";
import PageLayoutGridOptions from "./options/gridOptions";
import PageLayoutKanbanOptions from "./options/kanbanOptions";

const PageFormOptions = () => {
  const {getValues} = useFormContext()

  switch (getValues("pageLayout")) {
    case LayoutEnum.Blank: return <PageLayoutBlankOptions />
    case LayoutEnum.Grid: return <PageLayoutGridOptions />
    case LayoutEnum.Calendar: return <PageLayoutCalendarOptions />
    case LayoutEnum.Kanban: return <PageLayoutKanbanOptions />
    case LayoutEnum.RecordsList: return <PageLayoutRecordsListOptions />
    default: return <PageLayoutBlankOptions />
  }
}

export default PageFormOptions;