import { useEffect, useLayoutEffect, useRef, useState } from "react"
import { useFormContext, Controller } from "react-hook-form"
import { useSpace } from "../../../../../core/providers/space"
import MatrNarrowSelect from "../../../../../components/ui/select/narrow"
import { BlockSourceBlock, BlockSourceBoard, BlockSourceField, BlockSourceTypeEnum, Board, Field, FieldType, Relation } from "../../../../../core/graphql/types"
import { SingleValue } from "react-select"
import { usePanel } from "../../../../../core/providers/panel"
import { Tab } from "@headlessui/react"
import { TbDots } from "react-icons/tb"
import { MatrPopover } from "../../../../../components/ui/portal"


export function BlockSourceSelect() {
  const {page} = usePanel()
  const {boards} = useSpace()
  const {control, getValues, setValue, watch, formState} = useFormContext()
  const [relationFields, setRelationFields] = useState<Field[]>([])


  function handleChangeBoard(e: SingleValue<Board>) {
    setValue('sourceType', BlockSourceTypeEnum.Board)
    setValue('source.type', BlockSourceTypeEnum.Board)
    setValue('source.boardId', e?.id)
    setValue('source.blockId', watch('parentId'))
    setValue('source.fieldId', null)
  }

  function handleChangeField(e: SingleValue<Field>) {
    setValue('sourceType', BlockSourceTypeEnum.Field)
    setValue('source.type', BlockSourceTypeEnum.Field)
    setValue('source.boardId', (e?.attributes as Relation).boardId)
    setValue('source.blockId', watch('parentId'))
    setValue('source.fieldId', e?.id)
  }

  useEffect(() => {
    if(!watch('parentId')) { return }
    let parentBoardId = (page.blocks?.find((b: any) => b.id === watch('parentId'))?.source as BlockSourceBoard | BlockSourceBlock | BlockSourceField).boardId
    setRelationFields(boards.find((b: Board) => b.id === parentBoardId)?.fields?.filter((f: any) => f.type === FieldType.Relation) as Field[])
  }, [watch('parentId')])

  return (
    <div className="overflow-hidden w-full flex min-h-[20rem]">
      <Tab.Group selectedIndex={watch('source.type') === BlockSourceTypeEnum.Board ? 0 : 1}>
        <div className="flex w-full">
          <Tab.List className="w-1/4 flex flex-col pr-4 border-r">
            <Tab as="div">{({ selected }) => (<div className={`px-3 py-1.5 text-base rounded font-medium cursor-default ${selected ? "text-slate-800 bg-slate-200" : "text-slate-600"} `}>Таблицы</div>)}</Tab>
            {watch('parentId') && <Tab as="div">{({ selected }) => (<div className={`px-3 py-1.5 text-base rounded font-medium ${selected ? "text-slate-800 bg-slate-200" : "text-slate-600"} `}>Поля</div>)}</Tab>}
          </Tab.List>
          <Tab.Panels className="w-3/4 px-2">
            <Tab.Panel>
              <Controller
                name="source"
                control={control}
                rules={{required: true}}
                render={({ field: { onChange, value }}) => (
                  <div className="overflow-scroll">
                    {
                      boards.map(item => (
                        <div 
                          key={item.id} 
                          className={`${item.id === value?.boardId && value?.type === BlockSourceTypeEnum.Board ? "bg-indigo-600 text-white" : "bg-white text-slate-600"} cursor-default text-base py-1.5 px-2 truncate`}
                          onClick={() => handleChangeBoard(item)}
                        >{item.name}</div>
                      ))
                    }  
                  </div>
                )}
              />
            </Tab.Panel>
            {watch('parentId') && <Tab.Panel>
              <Controller
                  name="source"
                  control={control}
                  rules={{required: true}}
                  render={({ field: { onChange, value }}) => (
                    <div className="overflow-scroll">
                      {
                        relationFields.map(item => (
                          <div 
                            key={item.id} 
                            className={`${item.id === value?.fieldId && value?.type === BlockSourceTypeEnum.Field ? "bg-indigo-600 text-white" : "bg-white text-slate-600"} cursor-default text-base py-1.5 px-2 truncate`}
                            onClick={() => handleChangeField(item)}
                          >{boards.find(b => b.id === item.boardId)?.name} / {item.title}</div>
                        ))
                      }  
                    </div>
                  )}
                />
            </Tab.Panel>}
          </Tab.Panels>
        </div>
      </Tab.Group>
    </div>
  )
}