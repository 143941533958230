import React, { useEffect, useState, useCallback } from "react";
import { SmallSpinner } from "../../../components/loaders";
import { useUserSpacesQuery } from "../../../core/graphql/types"

export function AccountSpaces() {
  const [{fetching, error, data}] = useUserSpacesQuery()

  if (error) return <>`Error! ${error.message}`</>;

  return (
    <section className="w-full">
      <h4 className="text-xl font-semibold">Пространства</h4>
      <p className="text-sm text-gray-500 leading-8 mt-4">Список пространств в которых вы состоите.</p>
      <div className="relative w-full py-4">
        { fetching ? <SmallSpinner/> : 
          data?.userSpaces?.map((space, index) => (
            <div key={index} className="flex flex-col rounded-sm text-base py-1 my-2 hover:ring-8 hover:ring-slate-100 hover:bg-slate-100 cursor-default">
              <div>{space?.name}</div>
            </div>
          ))
        }
      </div>
    </section>
  )
}