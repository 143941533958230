import { useForm, Controller } from "react-hook-form"
import { Exact, Fetcher, InputMaybe, useCreateFetcherMutation, useListFetchersQuery } from "../../../../core/graphql/types";
import toast from "react-hot-toast";
import { useSpace } from "../../../../core/providers/space";
import { useNavigate } from "react-router-dom";
import { TbSquaresFilled, TbX } from "react-icons/tb";

const defaultValues = {
  title: "",
  description: "",
  available: false
}

export default function CreateFetcher({close}:{close: () => void}) {
  const {space, actionServicesUpdate} = useSpace()
  const navigate = useNavigate()
  const methods = useForm()
  const {control, register, handleSubmit} = methods;
  const [, create] = useCreateFetcherMutation()

  const onSubmit = handleSubmit(data => {
    createFetcher({title: data.title, description: data.description})
    close()
  })

  function createFetcher(data: Exact<{ title: string; description?: InputMaybe<string> | undefined; }> | undefined) {
    toast.promise(
      create(data).then().then(result => {
        if(result.error) { return result }
        actionServicesUpdate(result.data?.createFetcher!)
        return result
      }),
      {
        loading: 'Создаем новoe api...',
        success: (data) => {
          if (data.error) throw new Error();
          navigate(`/services/${data.data?.createFetcher?.id}`)
          return 'Api создана';
        },
        error: 'Ошибка. Не удалось создать api',
      }
    );
  }

  return (
    <>
      <div className="flex items-center justify-between">
        <div className="flex items-center space-x-2">
        <div className="py-1 px-2 bg-slate-100 flex space-x-2 rounded">
            <TbSquaresFilled size={20} className="text-indigo-600"/>
            <div className="flex font-medium text-sm">{space?.name}</div>
        </div>
        <div className="flex font-normal text-sm">Создание сервиса</div>
        </div>
        <button onClick={() => close()}><TbX size={18}/></button>
      </div>
    
      <form onSubmit={onSubmit} className="flex flex-col space-y-4">
        <div className="relative flex flex-col space-y-1.5 pt-4">
            <input 
              {...register("title", {required: true})} 
              className="text-xl font-medium text-slate-800 placeholder:text-slate-300 focus:outline-none mb-4" 
              placeholder="Название сервиса"
            />
            <Controller
                name="description"
                control={control}
                render={({ field: { onChange, value, ref }}) => (
                <textarea {...register("description", { required: false })} 
                placeholder="Описание сервиса" 
                className={`matr-input h-[100px]`}
                />
                )}
            />
        </div>
        <div className="flex justify-between w-full pt-6">
            <button
            type="button"
            className="inline-flex justify-end px-3 py-1.5 text-sm font-medium text-indigo-700 bg-indigo-100 border border-transparent rounded-md hover:bg-indigo-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-indigo-500"
            onClick={close}
            >Отменить</button>
            <button
            type="submit"
            className="inline-flex justify-center px-3 py-1.5 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md hover:bg-indigo-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-indigo-500"
            >Создать</button>
        </div>
      </form>
    </>
  )
}