import { UserNotification, useAcceptSpaceInvitationMutation, useRejectSpaceInvitationMutation, InviteToSpace } from "../../../../core/graphql/types"

interface InvitationNotificationProps {
  data: UserNotification
  onRemove: (id: string) => void
}

export default function InvitationNotification({data, onRemove}:InvitationNotificationProps) {
  const [,acceptInvitation] = useAcceptSpaceInvitationMutation()
  const [,rejectInvitation] = useRejectSpaceInvitationMutation()

  function accept() {
    acceptInvitation({id: data.spaceId!}).then(res => {
      if(!res.data || res.error) {return}
      res.data.acceptSpaceInvitation?.status === "ok" && onRemove(data.id!) 
    })
  }

  function reject() {
    rejectInvitation({id: data.spaceId!}).then(res => {
      if(!res.data || res.error) {return}
      res.data.rejectSpaceInvitation?.status === "ok" && onRemove(data.id!) 
    })
  }


  return (
    <div className="py-2 rounded">
      <div className="flex w-full font-medium text-base">Вы приглашены в простанство</div> 
      <div className="flex w-full items-center justify-between pt-2">
        <div className="flex-1 w-full truncate">
          <span className="font-meduim truncate">{(data.resource as InviteToSpace).content}</span>
        </div>
        <div className="flex-none flex space-x-2">
          <button onClick={() => reject()} className="px-3 py-1 rounded bg-slate-200 text-slate-400 hover:bg-slate-300 hover:text-slate-700" type="button">Отклонить</button>
          <button onClick={() => accept()} className="px-3 py-1 rounded bg-indigo-600 text-white hover:bg-indigo-700 hover:text-white" type="button">Принять</button>
        </div>
      </div>
    </div>
  )
}