import { TbArrowLeft, TbChevronUp, TbEye, TbEyeOff } from "react-icons/tb";
import { usePanel } from "../../../../../core/providers/panel";
import { Block, BlockFieldOptions, BlockFieldRelation, BlockTypeEnum, Board, Field, FieldType, Relation } from "../../../../../core/graphql/types";
import { Disclosure, Tab } from "@headlessui/react";
import { useForm, Controller, useWatch, useFormContext, FormProvider } from "react-hook-form";
import { useSpace } from "../../../../../core/providers/space";
import { BiToggleLeft, BiToggleRight } from "react-icons/bi";
import { useCallback, useEffect, useState } from "react";
import { debounce } from "debounce";
import useDeepCompareEffect from "use-deep-compare-effect";
import { formNotAvailableTypes } from "../../../../../core/enums";

const BlockFieldSettings = () => {
  const {boards} = useSpace()
  const {page} = usePanel()
  const methods = useFormContext()
  const {control, watch, getValues} = methods

  function handleChange(e: any, onChange: { (...event: any[]): void; (arg0: any): void; }) {
    onChange(e?.target.value)
  }

  function handleChangeCheckbox(value: any, onChange: { (...event: any[]): void; (arg0: any): void; }) {
    onChange(!value)
  }

  return (
    <>
      <div className="relative mt-4 flex flex-col">
        <label className="matr-label w-full">Таблица / колонка:</label>
        <span className="text-slate-500 font-medium text-lg w-full pt-2">{boards.find(b => b.id === getValues()?.source.boardId)?.name} / {(page.blocks?.find(b => b?.id === getValues('source.blockId'))?.options as any).title} / {getValues()?.source.field?.title}</span>
      </div>
      <Tab.Group>
        <Tab.List className="text-base border-b">
          <Tab >{({ selected }) => (<div className={`px-3 py-1.5  font-medium ${selected ? "text-indigo-600 border-b-2 border-indigo-600" : "text-slate-600"} `}>Настройки</div>)}</Tab>
          {watch('source.field.type') === FieldType.Relation && <Tab >{({ selected }) => (<div className={`px-3 py-1.5  font-medium ${selected ? "text-indigo-600 border-b-2 border-indigo-600" : "text-slate-600"} `}>Разрешения</div>)}</Tab>}
          <Tab >{({ selected }) => (<div className={`px-3 py-1.5  font-medium ${selected ? "text-indigo-600 border-b-2 border-indigo-600" : "text-slate-600"} `}>Условия</div>)}</Tab>
          <Tab >{({ selected }) => (<div className={`px-3 py-1.5  font-medium ${selected ? "text-indigo-600 border-b-2 border-indigo-600" : "text-slate-600"} `}>Оформление</div>)}</Tab>
        </Tab.List>
        <Tab.Panels>
          <Tab.Panel className="py-2 space-y-2">

            <div className="relative space-y-1.5">
              <div className="flex justify-between items-center">
                <label className="matr-label w-full">Заголовок:</label>
                <Controller
                  name="options.showTitle"
                  control={control}
                  rules={{required: true}}
                  render={({ field: { onChange, value, ref }}) => (
                    <div 
                      className={`flex items-center  rounded-md font-normal  cursor-default`} 
                      onClick={() => handleChangeCheckbox(value, onChange)}
                    >
                      {!value ? <BiToggleLeft className='h-6 w-6 text-slate-400'/> : <BiToggleRight className='h-6 w-6 text-blue-600'/>}
                    </div>
                  )}
                />
              </div>
              <Controller
                name="options.title"
                control={control}
                rules={{required: true}}
                render={({ field: { onChange, value, ref }}) => (
                  <div className="mt-2">
                    <input className="matr-form-narrow-input" value={value} onChange={(e) => handleChange(e, onChange)} />
                  </div>
                )}
              />
            </div>
            {!formNotAvailableTypes.includes(getValues('options.fieldType')) &&
              <div className="relative flex flex-col w-full">
                <div className="flex mt-2 w-full items-center">
                  <label className="matr-label w-full">Редактируемое</label>
                  <Controller
                    name="options.editable"
                    control={control}
                    rules={{required: true}}
                    render={({ field: { onChange, value, ref }}) => (
                      <div 
                        className={`flex items-center  rounded-md font-normal  cursor-default`} 
                        onClick={() => handleChangeCheckbox(value, onChange)}
                      >
                        {value === false ? <BiToggleLeft className='h-6 w-6 text-slate-400'/> : <BiToggleRight className='h-6 w-6 text-blue-600'/>}
                      </div>
                    )}
                  />
                </div>
              </div> 
            }
            {!formNotAvailableTypes.includes(getValues('options.fieldType')) &&
              <div className="relative flex flex-col w-full">
                <div className="flex mt-2 w-full">
                  <label className="matr-label w-full items-center">Обязательное</label>
                  <Controller
                    name="options.required"
                    control={control}
                    rules={{required: true}}
                    render={({ field: { onChange, value, ref }}) => (
                      <div 
                        className={`flex items-center  rounded-md font-normal  cursor-default`} 
                        onClick={() => handleChangeCheckbox(value, onChange)}
                      >
                        {value === false ? <BiToggleLeft className='h-6 w-6 text-slate-400'/> : <BiToggleRight className='h-6 w-6 text-blue-600'/>}
                      </div>
                    )}
                  />
                </div>
              </div>
            }
          </Tab.Panel>
          {watch('source.field.type') === FieldType.Relation && <Tab.Panel className="py-2 space-y-2">
            <div className="relative">
                <div className="flex justify-between items-center w-full">
                  <label className="matr-label w-full">Может добавлять записи</label>
                  <Controller
                    name="permissions.canAdd"
                    control={control}
                    rules={{required: true}}
                    render={({ field: { onChange, value, ref }}) => (
                      <div 
                        className={`flex items-center  rounded-md font-normal  cursor-default`} 
                        onClick={() => handleChangeCheckbox(value, onChange)}
                      >
                        {value === false ? <BiToggleLeft className='h-6 w-6 text-slate-400'/> : <BiToggleRight className='h-6 w-6 text-blue-600'/>}
                      </div>
                    )}
                  />
                </div>
                <div className="flex w-full pb-2">
                  {/* {watch("permissions.canAdd") === false ? <div className="text-sm text-slate-500">Не активно</div> : <CanAddForm />} */}
                </div>
                <div className="flex justify-between items-center w-full">
                  <label className="matr-label w-full">Может изменять и просматривать записи</label>
                  <Controller
                    name="permissions.canEdit"
                    control={control}
                    rules={{required: true}}
                    render={({ field: { onChange, value, ref }}) => (
                      <div 
                        className={`flex items-center  rounded-md font-normal  cursor-default`} 
                        onClick={() => handleChangeCheckbox(value, onChange)}
                      >
                        {value === false ? <BiToggleLeft className='h-6 w-6 text-slate-400'/> : <BiToggleRight className='h-6 w-6 text-blue-600'/>}
                      </div>
                    )}
                  />
                </div>
                <div className="flex justify-between items-center w-full">
                  <label className="matr-label w-full">Может редактировать в таблице</label>
                  <Controller
                    name="permissions.canEditInline"
                    control={control}
                    rules={{required: true}}
                    render={({ field: { onChange, value, ref }}) => (
                      <div 
                        className={`flex items-center  rounded-md font-normal  cursor-default`} 
                        onClick={() => handleChangeCheckbox(value, onChange)}
                      >
                        {value === false ? <BiToggleLeft className='h-6 w-6 text-slate-400'/> : <BiToggleRight className='h-6 w-6 text-blue-600'/>}
                      </div>
                    )}
                  />                    
                </div>
                <div className="flex justify-between items-center w-full">
                  <label className="matr-label w-full">Может фильтровать записи</label>
                  <Controller
                    name="permissions.canFilter"
                    control={control}
                    rules={{required: true}}
                    render={({ field: { onChange, value, ref }}) => (
                      <div 
                        className={`flex items-center  rounded-md font-normal  cursor-default`} 
                        onClick={() => handleChangeCheckbox(value, onChange)}
                      >
                        {value === false ? <BiToggleLeft className='h-6 w-6 text-slate-400'/> : <BiToggleRight className='h-6 w-6 text-blue-600'/>}
                      </div>
                    )}
                  />                    
                </div>
                <div className="flex justify-between items-center w-full">
                  <label className="matr-label w-full">Может искать по записям</label>
                  <Controller
                    name="permissions.canSearch"
                    control={control}
                    rules={{required: true}}
                    render={({ field: { onChange, value, ref }}) => (
                      <div 
                        className={`flex items-center  rounded-md font-normal  cursor-default`} 
                        onClick={() => handleChangeCheckbox(value, onChange)}
                      >
                        {value === false ? <BiToggleLeft className='h-6 w-6 text-slate-400'/> : <BiToggleRight className='h-6 w-6 text-blue-600'/>}
                      </div>
                    )}
                  />
                </div>
                <div className="flex justify-between items-center w-full">
                  <label className="matr-label w-full">Может удалять записи</label>
                  <Controller
                    name="permissions.canDelete"
                    control={control}
                    rules={{required: true}}
                    render={({ field: { onChange, value, ref }}) => (
                      <div 
                        className={`flex items-center  rounded-md font-normal  cursor-default`} 
                        onClick={() => handleChangeCheckbox(value, onChange)}
                      >
                        {value === false ? <BiToggleLeft className='h-6 w-6 text-slate-400'/> : <BiToggleRight className='h-6 w-6 text-blue-600'/>}
                      </div>
                    )}
                  />
                </div>
              </div>
          </Tab.Panel> }
          <Tab.Panel className="py-2 space-y-2">
            <div className="relative">
            </div>
          </Tab.Panel>
          <Tab.Panel className="py-2 space-y-2">
            <div className="relative">
              <BlockAppearance fieldType={watch('options.fieldType')}/>
            </div>
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </>
  )
}

export default BlockFieldSettings;


const BlockAppearance = ({fieldType}:{fieldType: FieldType}) => {
  console.log(fieldType)
  switch (fieldType) {
    case FieldType.Relation: return <BlockFieldRelationAppearance />
    default: return <></>
  }
}

const BlockFieldRelationAppearance = () => {
  const {boards} = useSpace()
  const {control, watch, setValue, getValues} = useFormContext();
  const [fields, setFields] = useState<Field[] | any>()

  useEffect(() => {
    setValue('options.appearance.type', FieldType.Relation)
  }, [])

  useEffect(() => {
    // let boardFields = boards.find(b => b.id === ((block as BlockField).field.attributes as Relation).boardId)?.fields?.filter(f => f?.primary !== true)
    // setFields(boardFields)
  }, [watch('options.appearance.showAs')])

  function handleHiddenFields(fieldId: string, value: string[], onChange: { (...event: any[]): void; (arg0: any[]): void; }) {
    if (value.some((v: string) => v === fieldId)) {
      onChange(value.filter((v:string) => v !== fieldId))
    } else {
      onChange([...value, fieldId])
    }
  }

  function handleChangeShowAs(value: string, onChange: { (...event: any[]): void; (...event: any[]): void; }) {
    // let position = getValues('position')
    // if(value === "GRID") {
    //   setValue("position", {x: 0, y: position.y, w: 12, h: 6})
    // } else {
    //   setValue("position", {x: 0, y: position.y, w: 6, h: 2})
    // }
    onChange(value)
  }

  return (
    <>
      <div className="flex flex-col w-full space-y-2">
          <label className="matr-label w-full">Показывать данные как:</label>
          <div className="relative">
            <Controller
              name="options.appearance.showAs"
              control={control}
              render={({ field: { onChange, value, ref }}) => (
                <div className="relative">
                  <div className="grid grid-cols-2 bg-blue-100 rounded-md p-0.5">
                    <div 
                      className={`w-full text-center text-sm p-2 font-medium rounded-md cursor-pointer ${value === "SELECT" ? "bg-blue-600 text-white" : "text-blue-600"}`}
                      onClick={() => handleChangeShowAs("SELECT", onChange)}
                    >Только связь</div>
                    <div 
                      className={`w-full text-center text-sm p-2 font-medium rounded-md cursor-pointer ${value === "GRID" ? "bg-blue-600 text-white" : "text-blue-600"}`}
                      onClick={() => handleChangeShowAs("GRID", onChange)}
                    >Таблица</div>
                  </div>
                </div>
              )}
            />
          </div>
      </div>

      {fields && fields.length > 0 && watch('options.appearance.showAs') === "GRID" ? 
      <div className="relative space-y-2 text-base">
        <Controller
          name="options.appearance.hiddenFields"
          control={control}
          render={({ field: { onChange, value, ref }}) => (
            <div className="relative space-y-1.5 py-2">
              <div className="text-sm font-medium text-slate-600">Видемые</div>
              {fields?.filter((f: Field) => !value.includes(f.id)).map((field: Field) => (
                <div key={field.id} className="flex w-full items-center justify-between text-slate-800" onClick={() => handleHiddenFields(field.id, value, onChange)}>
                  <span>{field.title}</span>
                  <span className="p-1"><TbEye /></span>
                </div>
              ))}
              <div className="text-sm font-medium text-slate-600">Не видемые</div>
              {fields?.filter((f: Field) => value.includes(f.id)).map((field: Field) => (
                <div key={field.id} className="flex w-full items-center justify-between text-slate-500" onClick={() => handleHiddenFields(field.id, value, onChange)}>
                  <span>{field.title}</span>
                  <span className="p-1"><TbEyeOff /></span>
                </div>
              ))}
            </div>
          )}
        />
      </div>
      : <></> }
     
    </>
  )
}