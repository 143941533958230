import { useEffect, useState, useCallback } from "react";
import { SmallSpinner } from "../../../components/loaders";
import { SimpleModal } from "../../../components/modals/simple"
import { PopoverWrapper } from "../../../components/ui/popover";
import toast from 'react-hot-toast';
import { HiDotsHorizontal, HiOutlinePlay, HiPlay, HiStop, HiTrash } from "react-icons/hi";
import { SpaceTelegramBot, useGetSpaceTelegramBotQuery, useRemoveTelegramBotMutation, useTurnTelegramBotMutation } from "../../../core/graphql/types";
import ConnectTelegramBot from './connect';

export function SpaceSettingsTelegram() {
  const [open, setOpen] = useState(false);
  const [bot, setBot] = useState<SpaceTelegramBot | any>()
  const [{fetching, data, error}] = useGetSpaceTelegramBotQuery();
  const [, removeBot] = useRemoveTelegramBotMutation();
  const [, turnBot] = useTurnTelegramBotMutation()
  
  useEffect(() => {
    if(!data) {
      return
    }
    setBot(data.getSpaceTelegramBot)
  },[data])

  function openModal() { setOpen(true)}
  function closeModal() { setOpen(false)}

  function onCreate(bot: SpaceTelegramBot) {
    setBot(bot)
  }

  function onRemove() {
    toast.promise(
      removeBot().then(res => {
        if(res.error) {
          return res
        }
        setBot(res.data?.removeTelegramBot)
        return res
      }),
      {
        loading: 'Удаляю бота...',
        success: (data) => {
          if (data.error) throw new Error();
          return 'Бот удален';
        },
        error: 'Ошибка. Не удалось удалить бота.',
      }
    );
  }

  function onTurn() {
    toast.promise(
      turnBot().then(res => {
        if(res.error) {
          return res
        }
        setBot(res.data?.turnTelegramBot)
        return res
      }),
      {
        loading: bot.active ? 'Останавливаю бота...' : 'Запускаю бота...',
        success: (data) => {
          if (data.error) throw new Error();
          return  data.data?.turnTelegramBot?.active ? 'Бот запущен' : 'Бот остановлен';
        },
        error: bot.active ? 'Не удалось остановить бота' : 'Не удалось запустить бота',
      }
    );
  }

  const popoverConfig = {
    button: <HiDotsHorizontal size={16}/>,
    actions: [
      {
        title: "Удалить",
        icon: <HiTrash size={16}/>,
        onClick: onRemove
      }
    ]
  }

  return (
    <>
      <section className="w-full">
        <div className="w-full flex justify-between items-center">
          <h4 className="text-xl font-semibold">Телеграм бот</h4>
          <button
            type="button"
            onClick={openModal}
            className="px-4 py-2 text-sm font-medium text-white bg-indigo-600 rounded-md hover:bg-indigo-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
          >
            Подключить
          </button>
        </div>
        <p className="text-sm text-gray-500 leading-8 mb-6 mt-4">Подключайте телеграм бота к своему пространству.</p>
        <div className="relative w-full py-4">
          { !bot?.telegramKey ? <></> : 
            <div 
              className="flex flex-row items-center justify-between py-2 px-2 w-full rounded-md bg-slate-100 hover:bg-slate-200 cursor-pointer focus:outline-none focus:ring-2 ring-offset-2 ring-offset-indigo-200 ring-white ring-opacity-60"
            >
              <div className="flex flex-row items-center">
                <button className="flex items-centers px-2" onClick={() => onTurn()}>
                  {!bot?.active ? <HiPlay className="w-8 h-8 text-green-600"/> : <HiStop className="w-8 h-8 text-red-600"/>}
                </button>
                <div className="flex flex-col space-y-1">
                  <div className="text-sm font-medium">
                    {bot?.botName}
                  </div>
                  <div className="inline-flex text-xs text-gray-600 space-x-2 items-center">
                    <span>{!bot?.active ? "Не активен" : " Активен"}</span>
                  </div>
                </div>
              </div>
              {!bot?.telegramKey ? <></> : <PopoverWrapper config={popoverConfig} item={bot}/>}
            </div>
          }
        </div>
      </section>
      { open ? 
        <SimpleModal open = {open} close = {closeModal}>
          <ConnectTelegramBot closeModal={closeModal} onChange={onCreate}/>
        </SimpleModal>
        : <></>
      }
    </>
  );
}