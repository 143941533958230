import { Column, RenderCellProps } from "react-data-grid";
import { Row } from "../types";
import { CreateHeaderRenderer } from '../rows/header';
import { useDeleteCardMutation } from "../../../../core/graphql/types";
import { HiOutlineTrash } from "react-icons/hi";
export const CREATE_COLUMN_KEY = 'create';


export function createColumn(): Column<Row, any> {
  return {
    key: CREATE_COLUMN_KEY,
    name: 'createColumn',
    width: 40,
    minWidth: 40,
    maxWidth: 40,
    resizable: false,
    sortable: false,
    renderHeaderCell: (p) => { return <CreateHeaderRenderer {...p}/> },
    renderCell: (p) => { return <DeleteCell {...p}/> },
  }
};

export function DeleteCell<R>(props: RenderCellProps<Row>) {
  const [,deleteCard] = useDeleteCardMutation()

  function handleDeleteCard(row: Row) {
    deleteCard({id: (props.row as Row).id})
  }
  
  if((props.row).id === "new") {
    return (<></>);
  } else {
    return (
      <div className="flex items-start py-2 w-full h-full justify-center bg-slate-100 text-slate-300 hover:text-slate-500" onClick={() => handleDeleteCard(props.row as Row)}><HiOutlineTrash className="w-4 h-4" /></div>
    );
  }
}
