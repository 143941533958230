import { useEffect } from 'react';
import { useAuth } from '../../core/providers/auth';
import { useSidebar } from "../../core/providers/sidebar";
import BoardHeader from './BoardHeader';
import { useLocation } from 'react-router-dom';
import PanelHeader from './PanelHeader';
import ServiceHeader from './ServiceHeader';
import FlowHeader from './FlowHeader';


const Header = ({location}:{location: string | null}) => {
  let {currentUser} = useAuth()
  let {isSidebarOpen, openSidebar, closeSidebar} = useSidebar()

  useEffect(() => {
    if(location === "flows") {closeSidebar()}
  },[])

  function getHeader() {
    switch (location) {
      case "page": return <span>{currentUser.space.name}</span>
      case "board": return <BoardHeader />
      case "flows": return <FlowHeader />
      case "panels": return <PanelHeader />
      case "services": return <ServiceHeader />
      case "space": return <span>{currentUser.space.name} / Настройки</span>
      default: return <span>{currentUser.space.name}</span>
    }
  }


  return (
    isSidebarOpen ? 
      <div className='flex-none flex w-full h-14 items-center px-6 text-base border-b'>{getHeader()}</div>
    :
      <div className='flex-none flex w-full h-14 items-center px-4 text-base border-b'>
        {getHeader()}
      </div>
  )
};

export default Header;