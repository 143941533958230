import { useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import { BlockKanbanOptions, CardWithValues, ValuePayload } from "../../../../core/graphql/types";
import ValueShow from "../../../Forms/Fields/Values";
import LabelShow from "../../../Forms/Fields/Labels";
import { useBoard } from "../../../../core/providers/board";
import { Field } from "react-hook-form";
import CardModal from "../../../Cards";
import { TbDotsVertical } from "react-icons/tb";


const Task = ({ data, index }: any) => {
  const {initType, initId, inBlock, board, cards, fields, values, currentView, setCurrentCardId, setCurrentCardPrimaryKey, setCurrentCard, setCardModalIsOpened, setModal} = useBoard()
  const init: string = `${initType}:${initId}`
  const titleValue = data.values.find((v: ValuePayload) => v.fieldId === (currentView?.options as BlockKanbanOptions).titleFieldId)
  const restValues = data.values.filter((v: ValuePayload) => v.fieldId !== (currentView?.options as BlockKanbanOptions).titleFieldId)

  function close() {
    setCardModalIsOpened(false, init)
  }

  function handleSelectCard(cardId: string) {
    if(currentView?.permissions?.canEdit === false) { 
      setCardModalIsOpened(false, init)
      return 
    }
    if(inBlock) {
      setCurrentCardId(cardId, init)
      setCurrentCardPrimaryKey(values.find(v => v.cardId === cardId && v.primary === true)?.id!, init)
      setCurrentCard({...(cards.find(c => c.id === cardId) as CardWithValues), values: values.filter(v => v.cardId === cardId)}, init)
      setCardModalIsOpened(true, init)
    } else {
      setCardModalIsOpened(true, init)
      setModal(<CardModal close={close} open={true} cardId={cardId} boardId={board?.id!} initiator="ROW"/>, init)
    }
  }


  return (
    <Draggable draggableId={data.slug} index={index} >
    {(provided) => (
      <>
        <li className="group select-none shadow border px-3 py-2 mr-2 rounded cursor-pointer text-sm bg-white text-slate-800"
        {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}
        onClick={() => handleSelectCard(data.id)}>
          <div className="flex h-8 relative justify-between items-center mb-2">
            <div className="text-base group-hover:text-indigo-600">
              {titleValue && <ValueShow value={titleValue} type={titleValue.type} fieldAttributes={fields.find(f => f.id === titleValue.fieldId)?.attributes!}/>}
            </div>
            <div className="text-slate-400">
              <TbDotsVertical size={16}/>
            </div>
          </div>
          <div className="space-y-2">
            {restValues.map((v: ValuePayload, i: number) => {
              const field = fields.find((f: Field | any) => f.id === v?.fieldId)
              return (
                <div key={i}>
                  <LabelShow type={v.type} field={field!}/>
                  <ValueShow value={v} type={v.type} fieldAttributes={field?.attributes!}/>
                </div>
              )
            })}
          </div>
        </li>
      </>
    )}
    </Draggable>
  )
}
export default Task