import { useRef, useState } from 'react';
import { NodeField } from '../../../../../core/graphql/types';
import { MatrPopover } from '../../../../../components/ui/portal';
import _ from 'lodash';
import { TbLink } from 'react-icons/tb';
import { Popover } from '@headlessui/react';

interface LinkFieldDropDownProps {
  value: string | null,
  links: any[],
  onChange: (id: string) => void;
}

function LinkNodeFieldDropDown({value, onChange, links}: LinkFieldDropDownProps) {

  return (
    <Popover className="relative">
      <Popover.Button className='flex items-center'><TbLink  className='bg-slate-200 w-7 h-7 px-1.5 rounded-sm'/></Popover.Button>

      <Popover.Panel className="absolute z-10 right-0">
        <div className='bg-white h-fit max-h-[32rem] w-64 rounded p-2 text-sm border shadow-sm mt-2 flex flex-col'>
          {links.length > 0 ? 
            links.map((f:NodeField) => 
            <button type='button' key={f.id} className={`${value === f.id ? "bg-indigo-600 text-white" : "bg-white text-slate-600"} px-2 text-left py-1.5 rounded`} onClick={() => onChange(f?.id!)}>
              <span>{f.name}</span>
            </button>
          ) : <div>Нет входных данных</div>}
        </div>
      </Popover.Panel>
    </Popover>
  )
}

export default LinkNodeFieldDropDown;