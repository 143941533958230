import { makeOperation, createClient, dedupExchange, fetchExchange, subscriptionExchange, defaultExchanges } from 'urql';
import { createClient as createWSClient } from 'graphql-ws';
import cacheExchange from './updaters/mutations';
import { devtoolsExchange } from '@urql/devtools';
// import schema from './graphql/schema';
// import { retryExchange } from '@urql/exchange-retry';
import { authExchange } from '@urql/exchange-auth';
import { abMultipartFetchExchange } from './lib/abMultipartFetchExchange'
import { getToken } from './store/authStore';


const api_url = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : ""
const ws_url = process.env.REACT_APP_WS_URL ? process.env.REACT_APP_WS_URL : ""

const wsClient = createWSClient({
  url: ws_url,
  keepAlive: 30_000,
  connectionParams: async () => {
    const token = await getToken();
    return {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
  },
});

export const makeClient = () => createClient({
  url: api_url,
  exchanges: [
    // devtoolsExchange,
    dedupExchange,
    // cacheExchange,
    authExchange({
      async getAuth({ authState, mutate } : {authState: any, mutate: any}) {
        if (!authState) {
          // console.log("in no auth");
          const token = getToken();
          // console.log("token", token);

          if (token) {
            return { token };
          }

          return null;
        }

        // console.log("result");
        // console.log("authState.refreshToken", authState.refreshToken);
        // const result = await mutate(REFRESH_TOKEN_MUTATION, {
        //   refreshToken: authState.refreshToken
        // });

        // if (result.data?.refreshCredentials) {
        //   saveAuthData(result.data.refreshCredentials);

        //   initialized = new Date().getTime();
        //   return result.data.refreshCredentials;
        // }

        // This is where auth has gone wrong and we need to clean up and redirect to a login page
        // window.location.reload();

        // return null;
      },

      addAuthToOperation({ authState, operation }) {
        if (!authState || !authState.token) {
          return operation;
        }

        const fetchOptions =
          typeof operation.context.fetchOptions === "function"
            ? operation.context.fetchOptions()
            : operation.context.fetchOptions || {};

        return makeOperation(operation.kind, operation, {
          ...operation.context,
          fetchOptions: {
            ...fetchOptions,
            headers: {
              ...fetchOptions.headers,
              Authorization: `Bearer ${authState.token}`
            }
          }
        });
      },

      // didAuthError({ error }) {
      //   return error.graphQLErrors.some(
      //     (e) => e.extensions?.code === "UNAUTHORIZED"
      //   );
      // },

      // willAuthError({ operation, authState }) {
      //   if (!authState) {
      //     // Detect our login mutation and let this operation through:
      //     return (
      //       operation.kind !== "mutation" ||
      //       // Here we find any mutation definition with the "signin" field
      //       !operation.query.definitions.some((definition) => {
      //         return (
      //           definition.kind === "OperationDefinition" &&
      //           definition.selectionSet.selections.some((node) => {
      //             // The field name is just an example, since register may also be an exception
      //             return node.kind === "Field" && node.name.value === "signin";
      //           })
      //         );
      //       })
      //     );
      //   } else
      //     return (
      //       operation.kind === "query" &&
      //       new Date().getTime() - initialized > 5000
      //     );
      // }
    }),
    subscriptionExchange({
      forwardSubscription(operation) {
        return {
          subscribe: (sink) => {
            const dispose = wsClient.subscribe(operation, sink);
            return {
              unsubscribe: dispose,
            };
          },
        };
      },
    }),
    abMultipartFetchExchange,
  ]
});

export const client = makeClient()