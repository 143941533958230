export const getFieldDescription = (type: string) => {
  switch (type) {
    case '':
      return (
        <></>
      );
    case 'NUMBER':
      return (
        ""
      );
    case 'DURATION':
      return (
        <p className="text-xs">Продолжительность на основе числа, выберите дискретность и формат вывода в доске</p>
      );
    case 'PHONE':
      return (
        ""
      );
    case 'DECIMAL':
      return (
        <div></div>
      );
    case 'PERCENT':
      return (
        <div></div>
      );
    case 'CURRENCY':
      return (
        <div></div>
      );
    case 'STRING':
      return (
        <p className="leading-5 text-xs text-gray-600">Одна строка текста. При желании вы можете предварительно заполнить каждую новую ячейку значением по умолчанию.</p>
      );
    case 'URL':
      return (
        <p className="text-xs text-gray-600">Одна строка текста. При желании вы можете предварительно заполнить каждую новую ячейку значением по умолчанию.</p>
      );
    case 'EMAIL':
      return (
        <p className="text-xs text-gray-600">Одна строка текста. При желании вы можете предварительно заполнить каждую новую ячейку значением по умолчанию.</p>
      );
    case 'CHECKBOX':
      return (
        <p className="text-xs">Единый флаг, который можно установить или снять.</p>
      );
    case 'TEXTAREA':
      return (
        <p className="text-xs">Длинное текстовое поле, которое может занимать несколько строк.</p>
      );
    case 'SELECT':
      return (
        <p className="text-xs">Одиночный выбор позволяет выбрать один вариант из предопределенных вариантов в раскрывающемся списке.</p>
      );
    case 'MULTISELECT':
      return (
        <p className="text-xs">Множественный выбор позволяет выбрать один или несколько предопределенных параметров, перечисленных ниже.</p>
      );
    case 'COLLABORATOR':
      return (
        <p className="text-xs">Поле соавтора позволяет вам добавлять соавторов к вашим записям. При желании соавторов можно уведомить о том, что они добавлены.</p>
      );
    case 'DATETIME':
      return (
        <p className="text-xs">Enter a date (e.g. 11/12/2013) or pick one from a calendar.</p>
      );
    case 'INSERTED_AT':
      return (
        <p className="text-xs">Enter a date (e.g. 11/12/2013) or pick one from a calendar.</p>
      );
    case 'UPDATED_AT':
      return (
        <p className="text-xs">Enter a date (e.g. 11/12/2013) or pick one from a calendar.</p>
      );
    case 'RELATION':
      return (
        <div></div>
      );
    case 'LOOKUP':
      return (
        <div></div>
      );
    case 'FORMULA':
      return (
        <p className="text-xs">Вычислить значение в каждой записи на основе других полей той же записи.</p>
      );
    case 'ATTACHMENT':
      return (
        <p className="text-xs">Вложения.</p>
      );
    case 'RECORDID':
      return (
        <div>Порядковый номер</div>
      );
    default:
      return (
        <></>
      );
  }
};