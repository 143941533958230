import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { SmallSpinner } from "../../../../components/loaders";
import MatrSelect from "../../../../components/ui/select/base";
import { Board, ForSelect, useGroupsForSelectQuery, useUpdateBoardMutation } from "../../../../core/graphql/types";
import { useBoard } from "../../../../core/providers/board";
import { useSpaceStore } from "../../../../core/store/store";
import { TbSquaresFilled, TbX } from "react-icons/tb";


function BoardInfo({close}:{close: () => void}): JSX.Element {
  const {initId, initType} = useBoard()
  const init: string = `${initType}:${initId}`
  const store = useSpaceStore()
  const {board} = store.data[init];
  const {setBoard} = store.boardActions;

  const { register, handleSubmit, control, setValue, formState: { errors } } = useForm();
  const [{fetching: groupsFetching, error: groupsError, data: groupsData}] = useGroupsForSelectQuery()

  const [,updateBoard] = useUpdateBoardMutation()

  console.log(board)

  useEffect(() => {
    if(!board && !groupsData?.groupsForSelect) {
      return
    }
    setValue('name', board!.name)
    setValue('group', board!.group)
    setValue('description', board!.description!)
    setValue('copyable', board!.copyable!)
    setValue('template', board!.template!)
  },[board, groupsData?.groupsForSelect])

  const onSubmit = handleSubmit(data => {
    updateBoard({
      id: board!.id,
      name: data.name,
      groupId: data.group.id,
      description: data.description
    }).then(res => {
      setBoard({...board, ...res.data?.updateBoard} as Board, init)
    })
  });

  if(groupsFetching) {return <SmallSpinner/>}

  return (
    <>
      <div className="flex items-center justify-between mb-4">
        <div className="flex items-center space-x-2">
          <div className="py-1 px-2 bg-slate-100 flex space-x-2 rounded">
            <TbSquaresFilled size={20} className="text-indigo-600"/>
            <div className="flex font-medium text-sm">{board.name}</div>
          </div>
          <div className="flex font-normal text-sm">Настройка таблицы</div>
        </div>
        <button onClick={() => close()}><TbX size={18}/></button>
      </div>
      <form onSubmit={onSubmit} className="flex flex-col w-full space-y-4">
        <div className="relative space-y-1.5">
          <label className="font-medium text-slate-600 text-sm">Название:</label>
          <input {...register("name", { required: true })} 
            className="matr-form-input"
          />
          {errors.name && <span>This field is required</span>}
        </div>
        <div className="relative">
          <label className="font-medium text-gray-600 text-sm">Группа:</label>
          <Controller
            name="group"
            control={control}
            render={({ field: { onChange, value }}) => (
              <div className="mt-2">
                <MatrSelect
                  value={value}
                  onChange={onChange}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id}
                  options={groupsData?.groupsForSelect as any}
                />
              </div>
            )}
          />
        </div>
        <div>
          <label className="font-medium text-slate-600 text-sm">Описание:</label>
          <textarea {...register("description")} 
            className="form-textarea text-sm form-input mt-2 py-2.5 block w-full h-32 rounded-sm bg-gray-100 border-0 focus:bg-white focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          />
        </div>
        <div>
          <label className="font-medium text-slate-600 text-sm flex items-center">
            <input {...register("copyable")} type="checkbox" className="mr-2"/>
            Разрешить копирование доски
          </label>
        </div>
        <div>
          <label className="font-medium text-slate-600 text-sm flex items-center">
            <input {...register("template")} type="checkbox" className="mr-2"/>
            Использовать доску как шаблолн
          </label>
        </div>
        <div className="flex justify-end">
          <button type="submit" className="flex px-4 py-2.5 text-sm font-medium text-center text-white transition duration-200 bg-indigo-600 rounded-sm hover:bg-indigo-700 ease" >Сохранить</button>
        </div>
      </form>
    </>
  );
}
export default BoardInfo;