import React, {useEffect, useState} from "react";
import { Board, Constant, Fetcher, FetcherInListFragment, FieldType, Flow, ForSelect, Group, MattrTypes, PanelInList, Role, Space, useBoardsChangedSubscription, useGetFetcherQuery, useGetRolesQuery, useListBoardsQuery, useListConstantsQuery, useListFetchersWithDataQuery, useListGroupsWithBoardsQuery, useListOfFlowsQuery, useListPanelsQuery, useSpaceUsersForSelectQuery, useStoreFormValueMutation } from "../../graphql/types";
import { useAuth } from "../auth";
import { SmallSpinner } from "../../../components/loaders";
import { useSpaceStore, BaseActions } from "../../store/store";
import { debounce } from "debounce";

interface SpaceContextType extends BaseActions {
  timezone: string;
  roles: Role[];
  spaceUsers: ForSelect[];
  boards: Board[];
  services: FetcherInListFragment[];
  panels: PanelInList[];
  groups: Group[];
  constants: Constant[];
  flows: Flow[];
  space: Space;
  storeCardValue: (boardId: string, cardId: string, fieldId: string, type: MattrTypes | FieldType, value: any) => void;
}

let SpaceContext = React.createContext<SpaceContextType>(null!);


export function SpaceProvider({ children }: { children: React.ReactNode }) {
  const { currentUser } = useAuth();
  const {boards, constants, groups, panels, services, roles, flows, space, spaceUsers} = useSpaceStore()
  const {actionBoardsSet, actionPanelsSet, actionServicesSet, actionConstantsSet, actionGroupsSet, actionRolesSet, actionSpaceSet, actionCurrentSpaceUserSet, actionFlowsSet, actionSpaceUsersSet} = useSpaceStore().baseActions
  let [timezone, setTimezone] = React.useState<any>(currentUser.space.timezone);
  const [{ fetching: boardsFetching, error: boardsError, data: boardsData }, getBoards] = useListBoardsQuery({pause: true});
  const [{ fetching: panelsFetching, error: panelsError, data: panelsData }, getPanels] = useListPanelsQuery({pause: true});
  const [{ data: dataGroups, fetching: fetchingGroups}, getGroups] = useListGroupsWithBoardsQuery({pause: true})
  const [{ data: dataConstants, fetching: fetchingConstants}, getConstants] = useListConstantsQuery({pause: true})
  const [{ fetching: rolesFetching, error: rolesError, data: rolesData }, getRoles] = useGetRolesQuery({pause: true});
  const [{ data: dataFetchers, fetching: fetchingFetchers}, getServices] = useListFetchersWithDataQuery({pause: true});
  const [{ data: dataFlows, fetching: fetchingFlows}, getFlows] = useListOfFlowsQuery({pause: true});
  const [{ data: dataSpaceUsers, fetching: fetchingSpaceUsers}, getSpaceUsers] = useSpaceUsersForSelectQuery({pause: true});
  const [, storeFormValue] = useStoreFormValueMutation()

  const [ boardsChanged ] = useBoardsChangedSubscription();

  useEffect(() => {
    getBoards()
    getGroups()
    getRoles()
    getConstants()
    getPanels()
    getServices()
    getFlows()
    getSpaceUsers()
    actionCurrentSpaceUserSet(currentUser)
    actionSpaceSet(currentUser?.space)
  }, [currentUser?.space])

  useEffect(() => {
    if(!boardsData?.listBoards) {
      return
    }
    actionBoardsSet(boardsData.listBoards as Board[])
  },[boardsData])

  useEffect(() => {
    if(!panelsData?.listPanels) {
      return
    }
    actionPanelsSet(panelsData.listPanels as PanelInList[])
  },[panelsData])


  useEffect(() => {
    if(!dataFetchers?.listFetchersWithData) {
      return
    }
    actionServicesSet(dataFetchers?.listFetchersWithData as Fetcher[])
  },[dataFetchers])

  useEffect(() => {
    if(!dataFetchers?.listFetchersWithData) {
      return
    }
    actionFlowsSet(dataFlows?.listOfFlows as Flow[])
  },[dataFlows])

  useEffect(() => {
    if(!boardsChanged?.data) {
      return
    }
    actionBoardsSet(boardsChanged.data.boardsChanged as Board[])
  },[boardsChanged])

  useEffect(() => {
    if(!dataGroups?.listGroupsWithBoards) {
      return
    }
    actionGroupsSet(dataGroups?.listGroupsWithBoards as Group[])
  },[dataGroups])

  useEffect(() => {
    if(!dataConstants?.listConstants) {
      return
    }
    actionConstantsSet(dataConstants?.listConstants as Constant[])
  },[dataConstants])


  useEffect(() => {
    if(!rolesData?.roles) {
      return
    }
    actionRolesSet(rolesData.roles as Role[])
  },[rolesData])

  useEffect(() => {
    if(!dataSpaceUsers?.spaceUsersForSelect) {
      return
    }
    actionSpaceUsersSet(dataSpaceUsers.spaceUsersForSelect as ForSelect[])
  },[dataSpaceUsers])

  function storeCardValue(boardId: string, cardId: string, fieldId: string, valueType: MattrTypes | FieldType, value: any) {
    storeFormValue({boardId: boardId, cardId: cardId, fieldId: fieldId, type: valueType as MattrTypes, value: value})
  }

  let value = {
    ...useSpaceStore().baseActions,
    space,
    timezone, 
    roles,
    spaceUsers, 
    boards, 
    panels, 
    groups, 
    constants, 
    services,
    flows,
    storeCardValue
  };

  if (boardsFetching || fetchingGroups || rolesFetching || fetchingConstants) return (<SmallSpinner/>);

  return <SpaceContext.Provider value={value}>{children}</SpaceContext.Provider>

}

export function useSpace() {return React.useContext(SpaceContext);}