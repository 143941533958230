import { memo, FC } from 'react';
import { Handle, Position, NodeProps } from 'reactflow';
import { useFlowContext } from '../../../../core/providers/flow';
import { TbSettings } from 'react-icons/tb';
import { NodeTriggerTypesEnum } from '../../../../core/graphql/types';
import { useSpace } from '../../../../core/providers/space';


const NodeTrigger: FC<NodeProps> = ({ id, data, selected }) => {
  const {boards} = useSpace()
  const {chooseNode} = useFlowContext()

  const description = (type: string) => {
    switch (type) {
      case NodeTriggerTypesEnum.CardCreated:
        return ( 
          <div>
            Когда будет создана запись в таблице: <b>{boards.find(b => b.id === data?.options?.boardId)?.name}</b>,
            данный процесс будет запущен.
          </div>
        )
      case NodeTriggerTypesEnum.CardCreatedWithValues:
        return ( 
          <div>
            Когда будет создана запись в таблице: <b>{boards.find(b => b.id === data?.options?.boardId)?.name}</b>, из формы
            данный процесс будет запущен.
          </div>
        )
      case NodeTriggerTypesEnum.CardUpdated:
        return ( 
          <div>
            Когда будет обновлена запись в таблице: {boards.find(b => b.id === data?.options?.boardId)?.name},
            данный процесс будет запущен.
          </div>
        )
      case NodeTriggerTypesEnum.CardInConditions:
        return ( 
          <div>
            Когда какая либо запись в таблице: {boards.find(b => b.id === data?.options?.boardId)?.name},
            будет соответствовать заданным условиям, данный процесс будет запущен.
          </div>
        )
      case NodeTriggerTypesEnum.CardInView:
        return ( 
          <div>
            Когда какая либо запись в таблице: {boards.find(b => b.id === data?.options?.boardId)?.name},
            будет соответсвовать условиям указанным в срезе таблицы, данный процесс будет запущен.
          </div>
        )
      case NodeTriggerTypesEnum.BySchedule:
        return ( 
          <div>
            НУЖНО ПОКАЗАТЬ ВРЕМЯ
          </div>
        )
      default:
        return "Сейчас событие пустое. Процесс не будет запускаться. Необходимо выбрать условие, когда процесс будет запущен. Для этого нажмите дважды на блок и в панели Конфигурация настройте событие."
    }
  }


  return (
    <div className='w-64'>
      <div className={`shadow-sm rounded bg-white ${selected ? "ring-2 ring-blue-400" : "ring-1 ring-slate-200"}`}>
        <div className='flex h-8 w-full px-2 border-b rounded-t items-center justify-between bg-blue-800'>
          <div className='text-base font-medium text-white truncate'>{data.name}</div>
          <div className='flex items-center'>
            <button type='button' className='text-indigo-400 hover:text-indigo-200' onClick={() => chooseNode(id)}><TbSettings /></button>
          </div>
        </div>
        <div className='min-h-[100px] px-2 py-2 text-slate-800 text-sm'>
          {description(data?.options?.triggerType)}
        </div>
        <div className='h-6 border-t items-center flex justify-end'>
          <div className='px-1 text-xs text-slate-600'>Начало</div>
          <Handle type="source" position={Position.Right} id='SUCCESS' style={{position: "relative", top: 8}} className="h-4 !bg-green-500" />
        </div>  
      </div>
    </div>

  );
};

export default memo(NodeTrigger);