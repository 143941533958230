import { AccountNotifications } from "../../containers/notifications";

function Notifications(): JSX.Element {
  return (
    <>
      <section className="flex flex-col w-full bg-white h-full overflow-hidden">
        <div className="h-full border-t overflow-scroll">
          <AccountNotifications />
        </div>
      </section>
    </>
  );
}
export default Notifications;