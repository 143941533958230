import { useState } from "react";
import type { FormFieldEditorProps } from './types';
import { RelationIcon } from "../../icons";
import { useFormContext, useFieldArray } from 'react-hook-form';
import { HiPlus, HiXCircle } from "react-icons/hi";
import { Field } from "../../../core/graphql/types";
import { MatrPopover } from "../../ui/portal";
import BaseRelationList from "../../Cards/Lists/BaseFormRelationsList";


export default function FormRelationEditor({formField, form}: FormFieldEditorProps) {
  let [buttonRef, setButtonRef] = useState<any>(null)
  const [ open, setOpen ] = useState(false);
  // @ts-ignore: Unreachable code error
  const attr: any = formField?.field?.attributes
  const methods = useFormContext();
  const { control, resetField, formState: {errors}} = methods;
  const { fields, append, remove } = useFieldArray({
    control,
    rules: {required: formField.required},
    name: `${formField.fieldId}`,
    keyName: "fid"
  });

  const [links, setLinks] = useState<any>([])

  function openRelations() { setOpen(true) };
  function closeRelations() { 
    setOpen(false)
  };

  function handleChange(item: any) {
    if(attr.multiple) {
      if(fields.some((f: any) => f.id === item.id)) {
        let findex = fields.findIndex((f: any) => f.id === item.id)
        remove(findex)
        setLinks(links.filter((r: string) => r !== item.id))
      } else{
        append({...item, foreginRelationFieldId: attr.foreginRelationFieldId})
        setLinks([...links, item.id])
      }
    } else {
      resetField(`${formField.fieldId}`)
      append({...item, foreginRelationFieldId: attr.foreginRelationFieldId})
      setLinks([item.id])
    }
  }

  return (
    <>
    <div className="relative flex flex-col">
      <div className="flex justify-between items-center">
        <div className="flex items-center h-full space-x-2">
          <div className="text-slate-400"><RelationIcon /></div>
          <label className="font-medium text-indigo-600 text-sm">{formField.name} {formField.required ? <span className="text-red-600">*</span> : ""}</label>
        </div>
        <div className='h-6 flex items-center space-x-2 cursor-pointer bg-slate-200 text-slate-600 px-3 rounded-md' ref={setButtonRef} onClick={() => openRelations()}>
          <HiPlus className="h-3 w-3"/>
          <span className='text-sm'>Добавить</span>
        </div>
      </div>
      <div className="flex flex-col mt-2">
        <div className="flex flex-col space-y-4">
          {fields.map((item: any, index: number) => (
            <div key={index} className="flex w-full bg-blue-200 text-blue-800 border border-blue-300 py-1.5 px-2 justify-between rounded-md items-center truncate text-sm cursor-pointer">
              <div className="flex flex-row text-sm font-medium text-indigo-600">{item.fields.find((f: Field) => f.primary === true)?.value}</div>
              <HiXCircle className="ml-1 h-4 w-4 z-auto text-blue-400 hover:text-blue-500" onClick={() => remove(item)}></HiXCircle>
            </div> 
          ))}
        </div>
      </div>
    </div>
    {open &&
        <MatrPopover onClose={closeRelations} reference={buttonRef} placement="bottom-end">
          <BaseRelationList 
            close={closeRelations} 
            boardId={form?.boardId!}
            fieldId={formField.fieldId}
            foreginFieldId={attr.fieldId!}
            links={links} 
            onChange={handleChange}
            canAdd={false}
          />
        </MatrPopover>
      }  
    </>
  );
}