import React, { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import toast from 'react-hot-toast';
import { SpaceUser } from "../../../../core/graphql/types";

const DeleteUser = ({user, closeModal, onChange}: {user: SpaceUser, closeModal: any, onChange: any}) => {
  const { register, handleSubmit, watch, control, setValue, formState: { errors } } =  useForm();

  useEffect(() => {
    setValue('id', user.id)
  }, [])

  const onSubmit = handleSubmit(data => {
    onChange(data)
    closeModal()
  });

  return (
    <form onSubmit={onSubmit} className="flex flex-col relative w-full space-y-4 p-6">
      <h2 className="text-lg font-semibold">Удаление участника</h2>
      <div className="text-xs text-gray-600">После удаления участник будет удален из всех досок и ему будет закрыт доступ к пространству.</div>
      <div className="flex justify-between w-full pt-6">
        <button
          type="button"
          className="inline-flex justify-end px-4 py-2 text-sm font-medium text-indigo-700 bg-indigo-100 border border-transparent rounded-md hover:bg-indigo-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-indigo-500"
          onClick={closeModal}
        >
          Отменить
        </button>
        <button
          type="submit"
          className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-red-600 border border-transparent rounded-md hover:bg-red-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-indigo-500"
        >
          Удалить
        </button>
      </div>
    </form>
  )
}

export default DeleteUser;