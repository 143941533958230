import { useEffect, useState } from "react";
import CardWithBlockForm from "../../../../../components/Cards/Modals/cardWithBlockForm";
import { Block, BlockFormOptions, BlockFormTypeEnum, BlockTypeEnum } from "../../../../../core/graphql/types";
import { useSpaceStore } from "../../../../../core/store/store";
import { usePanel } from "../../../../../core/providers/panel";
import useDeepCompareEffect from "use-deep-compare-effect";

export function BlockListActions({initiator}: { initiator: {type: string, id: string} }) {
  const { id: initId, type: initType } = initiator
  const init: string = `${initType}:${initId}`
  const store = useSpaceStore()
  const {page} = usePanel()
  const { setCardModalIsOpened, setCurrentCardId, setCurrentCard } = store.boardActions
  const {block, currentCard, currentCardId, cardModalIsOpened} = store.data[init]

  let [createForm, setCreateForm] = useState<Block>()
  let [updateForm, setUpdateForm] = useState<Block>()
  let [addCard, setAddCard] = useState<boolean>(false)

  useDeepCompareEffect(() => {
    if(!block.id) { return }
    setCreateForm(page.blocks?.find(b => b?.parentId === block?.id && b?.type === BlockTypeEnum.BlockForm && (b?.options as BlockFormOptions).formType === BlockFormTypeEnum.Create)!)
    setUpdateForm(page.blocks?.find(b => b?.parentId === block?.id && b?.type === BlockTypeEnum.BlockForm && (b?.options as BlockFormOptions).formType === BlockFormTypeEnum.Edit)!)
  },[page])


  function closeCardModal() {
    setAddCard(false)
    setCardModalIsOpened(false, init)
    setCurrentCardId(null, init)
    setCurrentCard(null, init)
  }

  function showAddCard() {
    setAddCard(true)
    setCardModalIsOpened(true, init)
  }

  return (
    <div className="flex-none h-14 text-lg flex items-center font-semibold justify-end">
      <div className="flex items-center space-x-4">
        {block.permissions?.canFilter ? 
          <button className="text-sm text-slate-500 rounded px-4 py-1">Фильтры</button> : <></>
        }
        {block.permissions?.canSearch ? 
          <button className="text-sm bg-indigo-600 text-white rounded px-4 py-1">Найти</button> : <></>
        }
        {block.permissions?.canAdd ? 
          <button type="button" className="text-base bg-indigo-600 text-white font-medium rounded px-3 py-1.5" onClick={() => showAddCard()}>Добавить</button> : <></>
        }
      </div>
      {cardModalIsOpened && addCard && block.permissions?.canAdd && <CardWithBlockForm open={cardModalIsOpened} close={closeCardModal} block={createForm!} />}
      {cardModalIsOpened && !!currentCard && block.permissions?.canEdit && <CardWithBlockForm open={cardModalIsOpened} card={currentCard} close={closeCardModal} block={updateForm!} />}
    </div>
  )
}