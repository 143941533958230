import { NavLink, useLocation } from 'react-router-dom';
import { useAuth } from '../../../core/providers/auth';
import { useSpace } from '../../../core/providers/space';
import { TbArrowsSort, TbChevronRight, TbFolderPlus, TbSquareRoundedPlusFilled } from 'react-icons/tb';


const account_menu = [
    {name: "Базовые настройки", path: "/account"},
    // {name: "Безопасность", path: "/account/security"}, 
    {name: "Пространства", path: "/account/spaces"},
    {name: "Телеграм", path: "/account/telegram"}
  ]

const AccountMenu = () => {
    let location = useLocation();
    return (
        <div className='px-2 pt-2'>
            <div className="flex items-center text-base font-semibold text-slate-500 justify-between py-2 px-2">
                <span>Аккаунт</span>
            </div>
            <div className="flex flex-col pt-1">
                {account_menu.map((item, index) => (
                <NavLink
                    key={index}
                    to={item.path}
                    className={
                    `px-2 py-1 w-full text-left text-base font-medium
                    focus:outline-none rounded-sm
                    ${item.path === location.pathname
                        ? 'bg-slate-200 text-indigo-600'
                        : 'text-slate-600 hover:bg-white/[0.12] hover:text-indigo-600'}`
                    }
                >
                    {item.name}
                </NavLink>
                ))}
            </div>
        </div>
    );
};

export default AccountMenu;