import { useEffect, useState, useCallback } from 'react';
import { RelationIcon } from "../../icons";
import { splitNames } from '../../../utils';
import { HiXCircle, HiPlus } from "react-icons/hi";
import { RelationValue, LinkItem, useCreateCardInRelationBoardMutation, FieldType, ValuePayload} from '../../../core/graphql/types';
import CardModal from '..';
import type { FieldRelationEditorProps } from './types';
import { MatrPopover } from '../../ui/portal';
import BaseListRelationCards from '../Lists/BaseFormRelationsList';
import BaseRelationList from '../Lists/BaseFormRelationsList';

export default function FieldRelation({field, fromCard, addRelation, removeRelation, fields}: FieldRelationEditorProps) {
  let [buttonRef, setButtonRef] = useState<any>(null)
  const [ open, setOpen ] = useState(false);
  const [ openEdit, setOpenEdit ] = useState(false);
  const [ relation, setRelation] = useState<LinkItem>()
  const [ relationCardId, setRelationCardId] = useState<any>("")
  const attr = field.attributes;

  const [,createNewCardInRelationBoard] = useCreateCardInRelationBoardMutation()

  const [selection, setSelection] = useState<LinkItem[] | any>(
    (fromCard[field.id] as RelationValue).links && 
    (fromCard[field.id] as RelationValue).links!.length > 0 &&
    (fromCard[field.id] as RelationValue).links![0]?.valueId ? 
    (fromCard[field.id] as RelationValue).links : []
  );
  
  useEffect(() => {
    setSelection(
      (fromCard[field.id] as RelationValue).links && 
      (fromCard[field.id] as RelationValue).links!.length > 0 &&
      (fromCard[field.id] as RelationValue).links![0]?.valueId ? 
      (fromCard[field.id] as RelationValue).links : []
    )
  },[field, fromCard])

  function openRelations() { setOpen(true) };
  function closeRelations() { 
    setOpen(false)
  };

  function openModalCardRelation(element: LinkItem) { 
    setRelationCardId(element?.value?.cardId!)
    setRelation(element)
    setOpenEdit(true) 
  };

  function closeModalCardRelation() { 
    setOpenEdit(false)
  };


  function createRelation(item: LinkItem) {
    const forSave: any = {
      ...item, 
      fromValueId: (fromCard[field.id] as RelationValue).id,
      fromValueType: field.type,
      throughValueId: field.attributes.valueLinkFieldId
    }
    addRelation(forSave)
  };

  function deleteRelation(item: LinkItem) {
    const forDelete: any = {
      valueId: item.valueId, 
      fromValueId: (fromCard[field.id] as RelationValue).id
    }
    removeRelation(forDelete)
  };
  
  function handleChange(relationCard: { id: any; fields: any[]; }) {
    const primary = relationCard.fields.find(v => v.primary === true)
    const relation = {
      valueId: primary.id, 
    }
    processUpdate(relation)
  }

  const handleUpdate = useCallback((card: any) => {
    closeModalCardRelation()
  }, [])


  function processUpdate(item: LinkItem) {
    if (!selection.some((current: LinkItem) => current.valueId === item.valueId)) {
      if (!attr.multiple) {
        setSelection([item]);
        createRelation(item)

      } else {
        setSelection([...selection, item]);
        createRelation(item)
      }
    } else {
      let selectionAfterRemoval = selection;
      selectionAfterRemoval = selectionAfterRemoval.filter(
        (current: LinkItem) => current.valueId !== item.valueId
      );
      setSelection([...selectionAfterRemoval]);
      deleteRelation(item)
    }
  }


  function addNewCardToRelationBoard() {
    let pfid = fields.find(f => f.primary === true)!.id
    let pv = (fromCard[pfid] as unknown as ValuePayload)

    let value = {
      valueId: pv.id, // id текущего первичного значени
      valueLinkFieldId: field.id,
    }

    createNewCardInRelationBoard({
      boardId: field.attributes.boardId,
      values: [{
        fieldId: field.attributes.foreginRelationFieldId,
        type: FieldType.Relation,
        links: [value]
      }]
    }).then(resp => {
      if(resp.error) {
        return
      }
      closeRelations()
      setRelationCardId(resp.data?.createCardWithValues?.id)
      setOpenEdit(true)
    })
  }

  return (
    <>
      <div className="relative flex flex-col space-y-1.5">
        <div className="flex justify-between h-full items-center">
          <div className='flex items-center'>
            <div className="text-slate-400 pr-2"><RelationIcon /></div>
            <label className="text-slate-500 text-sm">{field.title}</label>
          </div>
          <div className='h-6 flex items-center space-x-2 cursor-pointer bg-slate-200 text-slate-600 px-3 rounded-md' ref={setButtonRef} onClick={() => openRelations()}>
            <HiPlus className="h-3 w-3"/>
            <span className='text-sm'>Добавить</span>
          </div>
        </div>
        <div className="h-full">
          <div className="flex w-full flex-col">
            <div className="flex flex-col w-full items-center space-y-2">
            { selection.length ? 
              selection.map((element: LinkItem) => { 
                return element.value?.value ? 
                <div className="flex w-full bg-blue-200 text-blue-800 border border-blue-300 py-1.5 px-2 justify-between rounded-md items-center truncate text-sm cursor-pointer" key={element.valueId}>
                  <span className='w-full truncate' onClick={()=>openModalCardRelation(element)}>{element.value.value ? splitNames(element.value.value as string) : "Без названия"}</span>
                  <HiXCircle className="ml-1 h-4 w-4 z-auto text-blue-400 hover:text-blue-500" onClick={() => processUpdate(element)}></HiXCircle>
                </div> : 
                <div className="flex w-full bg-blue-100 text-blue-800 border border-blue-300 py-1.5 px-2 justify-between rounded-md items-center truncate text-sm cursor-pointer" key={element.valueId}>
                  <span className='w-full truncate'>"Без названия"</span>
                  <HiXCircle className="ml-1 h-4 w-4 z-auto text-blue-400 hover:text-blue-500" onClick={() => processUpdate(element)}></HiXCircle>
                </div>
            }) : <></> }
            </div>
          </div>
        </div>
      </div>
      {open &&
        <MatrPopover onClose={closeRelations} reference={buttonRef} placement="bottom-end">
          <BaseRelationList
            close={closeRelations} 
            boardId={field.attributes.boardId} 
            fieldId={field.id} 
            links={selection} 
            onChange={handleChange}
            addNewCardToRelationBoard={addNewCardToRelationBoard}
            canAdd={true}
          />
        </MatrPopover>
      }      
      {openEdit ? 
        <CardModal 
          open={openEdit} 
          close={closeModalCardRelation} 
          boardId={field.attributes.boardId} 
          fromFieldId={field.id} 
          cardId={relationCardId}
          initiator="FORM_FIELD"
        /> : <></>}
    </>
  )
}