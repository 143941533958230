import React from "react";
import { BigSpinner } from "../../../components/loaders/index";
import { MattrDataContextType, useMattrDataProvider } from "../data";

interface BoardContextType extends MattrDataContextType {
  initId: string,
  initType: string,
  savePrimitiveValue: (value: any) => void;
}

let BoardContext = React.createContext<BoardContextType>(null!);

export function BoardProvider({ children }: {children: React.ReactNode }) {
  const data = useMattrDataProvider()
  const {fields} = data;

  let value = {...data};

  return fields.length > 0 ? <BoardContext.Provider value={value}>{children}</BoardContext.Provider> : <BigSpinner />

}

export function useBoard() {return React.useContext(BoardContext);}