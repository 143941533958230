import './spinner.css';

export const BigSpinner = () => {
  return (
    <div className="blackout">
      <div className="spinner" />
    </div>
  )
};

export const SmallSpinner = () => {
  return (
    <div className="flex items-center justify-center h-full w-full">
      <div className="loader ease-linear rounded-full border-2 border-t-2 border-gray-200 h-4 w-4"></div>
    </div>
  )
}