
import { ViewType } from '../../core/graphql/types';
import { useBoard } from '../../core/providers/board';
import CalendarView from './Calendar';
import MattrGrid from './MattrGrid';
import { ViewMenu } from "./ViewsMenu";

export default function ViewsContainer():JSX.Element {
  const {currentView} = useBoard()

  function getViewComponent(type: ViewType) {
    switch (type) {
      case ViewType.Grid:
        return <MattrGrid/>
      case ViewType.Calendar:
        return <CalendarView />
      case ViewType.Kanban:
        return <MattrGrid/>   
      case ViewType.List:
        return <MattrGrid/>
      default:
        return <MattrGrid/>
    }
  }

  return (
    <>
      <ViewMenu />
      {getViewComponent(currentView!.type)}
    </>
  )
}