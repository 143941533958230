import { useEffect, useState } from "react";
import { useFormContext, useFieldArray, Controller } from "react-hook-form";
import { DragDropContext, Droppable, Draggable, DropResult } from "react-beautiful-dnd";
import { HiDotsVertical, HiX } from "react-icons/hi";
import { Select } from "../../../../../../core/graphql/types";

const uniqueId = () => Math.floor(Math.random() * Date.now());


export default function OptionList({attributes}: {attributes: Select}): JSX.Element {
  const methods = useFormContext();
  const {register} = methods;
  const { fields, append, remove, move, replace } = useFieldArray({
    name: "attributes.options",
    keyName: "rid"
  });

  useEffect(()=> {
    if(!attributes.options) {
      return
    }
    replace(attributes.options)
  },[attributes.options])

  const newOptionHandler = () => {
    append({id: `${uniqueId()}`, name: "Пусто"});
  };

  const deleteOptionHandler = (index: number) => {
    remove(index)
  };

  function onDragEnd(result: DropResult) {
    if (!result.destination) {
      return;
    }
    if (result.destination.index === result.source.index) {
      return;
    }
    move(result.source.index, result.destination.index)
  }


  return (
    <div className="border-t border-b border-gray-200 py-2">
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="list">
          {provided => (
            <div ref={provided.innerRef} {...provided.droppableProps} className="flex flex-col space-y-1" >
              {!fields?.length ? "" : fields.map((option, index) => (
                <div key={option.rid}>
                  <Draggable draggableId={option.rid} index={index}>
                    {provided => (
                      <div 
                        className="flex flex-row w-full px-1 bg-gray-100 rounded-sm h-8 items-center text-xs"
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      > 
                        <HiDotsVertical className="w-4 h-4 text-gray-400 hover:text-indigo-600 mr-1"/>
                        {/* <input {...register(`attributes.options.${index}.id`)} hidden defaultValue={option.id} />
                        <input {...register(`attributes.options.${index}.name`)} defaultValue={option.name} className="w-full bg-gray-100 py-2.5 px-2 focus:outline-none focus:bg-white text-gray-800"/> */}
                        <input {...register(`attributes.options.${index}.id`)} hidden />
                        <input {...register(`attributes.options.${index}.name`)} className="w-full bg-gray-100 py-2 px-2 focus:outline-none focus:bg-slate-200 text-gray-800"/>
                        <button className="pl-3 pr-2 py-3 focus:outline-none text-gray-400 hover:text-indigo-600" type="button" onClick={() => deleteOptionHandler(index)}>
                          <HiX className="w-4 h-4"/>
                        </button>
                      </div>
                    )}
                  </Draggable>
                </div>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <div className="flex justify-end">
        <button className={`flex text-xs bg-indigo-100 text-indigo-600 rounded-sm font-medium justify-end px-3 py-2 ${!fields?.length ? "" : "mt-2"}`} type="button" onClick={newOptionHandler}>Добавить опцию</button>
      </div>
    </div>
  );
}