import _ from 'lodash';
import { useEffect, useRef, useState } from "react";
import Avatar from "react-avatar";
import { ActionMenu } from "../../../components/Menus/ActionMenu";
import { useAuth } from "../../../core/providers/auth";
import { useBoard } from "../../../core/providers/board";
import { useSpace } from "../../../core/providers/space";

import { TbDots, TbPlugConnected, TbSettings2, TbSquareRoundedChevronDownFilled, TbTableExport, TbUsersPlus } from "react-icons/tb";
import { Link } from "react-router-dom";
import { MatrMediumDialog, MatrPopover } from "../../../components/ui/portal";
import { SpaceUser } from "../../../core/graphql/types";
import { useSpaceStore } from "../../../core/store/store";
import BoardExport from "../settings/export";
import BoardInfo from "../settings/info";
import BoardSources from "../settings/sources";
import BoardSettingsUsers from "../settings/users";

export default function BoardHeader() {
  const auth = useAuth();
  const [ buttonRef, setButtonRef ] = useState<any>()

  const {initId, initType} = useBoard()
  const init: string = `${initType}:${initId}`
  const store = useSpaceStore()
  const {board, users} = store.data[init];
  const { boards } = useSpace();

  const [openMenu, setOpenMenu] = useState(false);
  const [openBoardSettings, setOpenBoardSettings] = useState(false);
  const [openInviteUsers, setOpenInviteUsers] = useState(false);
  const [openBoardSources, setOpenBoardSources] = useState(false);
  const [openExportBoard, setOpenExportBoard] = useState(false);

  const boardsRef = useRef(null)
  const [openOpenBoards, setOpenBoards] = useState(false)
  function closeBoards() { setOpenBoards(false)}

  function onOpenMenu(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) { setOpenMenu(true)}
  function closeMenu() { setOpenMenu(false) };

  function onOpenBoardSettings() { 
    closeMenu()
    setOpenBoardSettings(true)
  }
  function closeBoardSettings() { setOpenBoardSettings(false) };
  function onOpenInviteUsers() { 
    closeMenu()
    setOpenInviteUsers(true)
  }
  function closeInviteUsers() { setOpenInviteUsers(false) };
  function onOpenBoardSources() { 
    closeMenu()
    setOpenBoardSources(true)
  }
  function closeBoardSources() { setOpenBoardSources(false) };
  function onExportBoard() { 
    closeMenu()
    setOpenExportBoard(true)
  }
  function closeExportBoard() { setOpenExportBoard(false) };

  useEffect(() => {
    document.title = board!.name;
  });



  const menu = [
    {
      name: "Настройки таблицы",
      action: onOpenBoardSettings,
      icon: <TbSettings2 className="mr-2 w-3 h-3"/>,
      disabled: false
    },
    {
      name: "Участники",
      action: onOpenInviteUsers,
      icon: <TbUsersPlus className="mr-2 w-3 h-3"/>,
      disabled: false
    },
    {
      name: "Связные доски",
      action: onOpenBoardSources,
      icon: <TbPlugConnected className="mr-2 w-3 h-3"/>,
      disabled: false
    },
    {
      name: "Экспорт",
      action: onExportBoard,
      icon: <TbTableExport className="mr-2 w-3 h-3"/>,
      disabled: false
    }
  ] 


  return (
    <>
      <div className="flex w-full justify-between">
        <div className="flex items-center font-medium text-base text-slate-800">
          <span className="px-1">{auth.currentUser.space.name}</span>
          <span className="px-1">/</span>
          <span className="px-1">{board!.group?.name}</span>
          <span className="px-1">/</span>
          <div ref={boardsRef} className='items-center flex justify-center text-indigo-600 space-x-1' onClick={() => setOpenBoards(!openOpenBoards)}>
            <span className="px-1">{board!.name}</span>
            {openOpenBoards ? <TbSquareRoundedChevronDownFilled size={18}/> : <TbSquareRoundedChevronDownFilled size={18}/>}
          </div>
        </div>
        <div className="flex space-x-2 items-center"> 
          <div className="flex px-4">
            <div className="flex -space-x-1 overflow-hidden items-center">
              {users && users.length ? users.map((user: SpaceUser, index: number) => 
                <Avatar className="inline-block rounded-full ring-2 ring-white cursor-pointer" size="24" key={index} name={`${user.firstName} ${user.lastName}`}/>
              ) : ""}
            </div>
          </div>
          <button
            onClick={(e) => onOpenMenu(e)}
            ref={setButtonRef}
            className="h-5 w-5 text-slate-600"
          >
            <TbDots />
          </button>
        </div>
      </div>
      {openMenu && 
        <MatrPopover onClose={closeMenu} reference={buttonRef} placement="bottom-end">
          <ActionMenu menu = {menu}/>
        </MatrPopover>
      }
      {openBoardSettings ? 
        <MatrMediumDialog open={openBoardSettings} onClose={closeBoardSettings}>
          <BoardInfo close={closeBoardSettings}/>
        </MatrMediumDialog>
      : <></>}
      {openInviteUsers ? 
        <MatrMediumDialog open={openInviteUsers} onClose={closeInviteUsers}>
          <BoardSettingsUsers close={closeInviteUsers}/>
        </MatrMediumDialog>
      : <></>}
      {openBoardSources ? 
        <MatrMediumDialog open={openBoardSources} onClose={closeBoardSources}>
          <BoardSources close={closeBoardSources}/>
        </MatrMediumDialog>
      : <></>}
      {openExportBoard ? 
        <MatrMediumDialog open={openExportBoard} onClose={closeExportBoard}>
          <BoardExport close={closeExportBoard}/>
        </MatrMediumDialog>
      : <></>}
      {openOpenBoards && <MatrPopover onClose={closeBoards} reference={boardsRef.current} placement="bottom">
        <div className='w-96 flex p-3 shadow-lg bg-white max-h-[24rem] border mt-6 rounded-md'>
          <ul className='flex flex-col space-y-2 w-full overflow-scroll'>
            {_.sortBy(boards, ['name']).map((item, i) => (
              <Link key={i} to={`/boards/${item.id}`} className='cursor-default'>
                <li className={`flex flex-col border hover:bg-indigo-200 w-full rounded p-2 ${item.id === board!.id ? "bg-indigo-600 text-white" : "text-slate-800"}`}>
                  <span className="text-sm font-medium  cursor-default">{item.name}</span>
                </li>
              </Link>
            ))}
          </ul>
        </div>
      </MatrPopover>}
    </>

  )
}