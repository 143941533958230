import { forwardRef, useEffect, useState } from "react";
import { Column } from "react-data-grid";
import { Row } from "../types";
import type { RenderCellProps } from 'react-data-grid';
import { Datetime as DatetimeType, DatetimeValue, Field } from '../../../../core/graphql/types';
import SummaryRows from "../rows/summary";
import { GroupCell } from "../rows/group";
import { HeaderRenderer } from "../rows/header";
import {dateFormats, timeFormats} from "../../../../core/enums";
import "moment/locale/ru";
import moment from "moment";


interface RenderStringCellProps<TRow, TSummaryRow> extends RenderCellProps<TRow, TSummaryRow> {
  field: Field;
}

function datetimeFormat(value: string, attr: DatetimeType) {
  let format = "";
  
  if(!attr.includeTime) {
    format = dateFormats.find(format => format.name === attr.dateFormat)?.format!;
  } else {
    format = dateFormats.find(format => format.name === attr.dateFormat)?.format! + ' ' + timeFormats.find(format => format.name === attr.timeFormat)?.format!;
  }

  return (
    moment(new Date(value)).format(format)
  )
}
  
// Column

export function updatedAtColumn({ field, canEditInline }: { field: Field; canEditInline: boolean }): Column<Row, any> {
  return {
    key: field.id,
    name: field.title,
    width: field.width,
    resizable: true,
    frozen: field.primary ? true : false,
    renderHeaderCell: (p) => { return <HeaderRenderer field={field} {...p}/> },
    renderCell: (p) => { return <DatetimeCell {...p} field={field}/> },
    renderGroupCell: (p) => { return <GroupCell {...p} field={field}/> },
    renderSummaryCell: (p) => { return <SummaryRows {...p} field={field}/> },
    editorOptions: {
      displayCellContent: !canEditInline
    }
  }
};

// Cell

export function DatetimeCell<Row, SummaryRow>({field, row, column, onRowChange}: RenderStringCellProps<Row, SummaryRow>) {
  const value = (row[column.key as keyof Row] as unknown as DatetimeValue)?.valueAsDatetime!
  const attr = (field.attributes as DatetimeType);
  return (
    <div className="px-2 text-left h-full flex items-start py-2 text-sm truncate">{value ? datetimeFormat(value, attr) : ""}</div>
  );
}
