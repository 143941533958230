import { useFormContext, Controller } from "react-hook-form";
import MatrSelect from "../../../../../components/ui/select/base";
import { SingleValue } from "react-select";
import { useSpace } from "../../../../../core/providers/space";
import { Board } from "../../../../../core/graphql/types";

const PageLayoutBlankOptions = () => {
  const {boards} = useSpace()
  const {control, formState: {errors}} = useFormContext()

  function handleChangeBoard(e: SingleValue<Board>, onChange: (...event: any[]) => void) {
    onChange(e?.id)
  }

  return (
    <>
      <div className="relative">
        <label className="font-medium text-gray-600 text-sm">Доска:</label>
        <Controller
          name="pageOptions.boardId"
          control={control}
          rules={{required: true}}
          render={({ field: { onChange, value }}) => (
            <div className="mt-1.5">
              <MatrSelect
                getOptionLabel={(option) => option?.name!}
                getOptionValue ={(option)=> option?.id!}
                value={value && value.length > 0 ? boards.find(r => value.includes(r.id)) : []}
                onChange={(e) => handleChangeBoard(e, onChange)}
                options={boards}
              />
            </div>
          )}
        />
        {errors.boardId && <span className="text-red-600 text-xs font-normal">"Доска" обязательное поле.</span>}
      </div>
    </>
  )
}

export default PageLayoutBlankOptions;