import _ from "lodash";
import { useEffect, useState } from "react";
import { DragDropContext, Draggable, DropResult, Droppable, ResponderProvided } from "react-beautiful-dnd";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { TbDragDrop } from "react-icons/tb";
import { MatrMediumDialog } from "../../../../../../components/ui/portal";
import { transformFormValueToFieldValue, valueToString } from "../../../../../../core/Controllers/Base";
import { Block, BlockFieldOptions, BlockFormOptions, BlockFormTypeEnum, BlockSourceBlock, BlockSourceBoard, BlockSourceField, BlockSourceTypeEnum, BlockTypeEnum, CardWithValues, FieldType, Relation } from "../../../../../../core/graphql/types";
import { usePanel } from "../../../../../../core/providers/panel";
import { useSpaceStore } from "../../../../../../core/store/store";
import { getKeyForType } from "../../../../../../utils";
import { BlockBaseControls } from "../../Controls";
import { BlockEdit } from "../../Forms/editBlock";
import PageBlock from "../../index";
import "/node_modules/react-grid-layout/css/styles.css";
import "/node_modules/react-resizable/css/styles.css";
import BlockFormFields from "../fields";
import { useNavigate } from "react-router-dom";
import FieldBlock from "../../BlockField";


const reorder = (list: Block[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};


function FormBlockCreate({formBlock}:{formBlock: Block}) {
  const {page, editorMode, updateBlocksPosition, storeFormBlock} = usePanel()
  const init = `block:${formBlock.id}`
  const parentInit = `block:${formBlock.parentId}`
  let spaceStore = useSpaceStore()
  let store = spaceStore.data[init]
  let parentStore = spaceStore.data[parentInit]
  const [ currentBlock, setCurrentBlock ] = useState<Block>()
  const [ openSettings, setOpenSettings ] = useState(false);
  const [ openFields, setOpenFields ] = useState(false);

  const methods = useForm()
  const {getValues, reset, formState, control} = methods
  const [defValues, setDefValues] = useState<Record<string, any>>()
  const [loading, setLoading] = useState(true)
  const [parentBlock, setParentBlock] = useState<Block | null>()
  const [formBlocks, setFormBlocks] = useState<Block[] | null>()

  const [formSubmitted, setFormSubmitted] = useState(false)
  const navigate = useNavigate()

  function onOpenFields(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) { setOpenFields(true)}
  function closeFields() { setOpenFields(false) };

  function onOpenSettings(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) { setOpenSettings(true)}
  function closeSettings() { setOpenSettings(false) };

  useEffect(() => {
    if(!(formBlock?.options as BlockFormOptions).contentColumns) {return}
    if(!formBlock.parentId) {
      setParentBlock(null)
    } else {
      setParentBlock(page.blocks?.find(b => b?.id === formBlock.parentId))
    }
    setFormBlocks(_.orderBy((page?.blocks?.filter(b => b?.parentId === formBlock?.id)! as Block[]), item => item?.position?.x, "asc"))
  },[page?.blocks, formBlock])

  useEffect(() => {
    let form: Record<string, any> = {}

    if((formBlock.options as BlockFormOptions)?.formType === BlockFormTypeEnum.Create) {
      (page?.blocks?.filter(b => b?.parentId === formBlock?.id && b.type === BlockTypeEnum.BlockField)! as Block[]).map(bf => 
        form[(bf.source as BlockSourceField).field?.id!] = null
      )
    } 

    setDefValues(form)
    reset(form)
    setLoading(false)
  },[reset])

  const watchedData = useWatch({
    control: control,
    defaultValue: defValues
  });
  

  function onDragEnd(result: DropResult, provided: ResponderProvided) {
    // dropped outside the list
    if (!result.destination) { return; }

    const items = reorder(
      formBlocks!,
      result.source.index,
      result.destination.index
    );

    updateBlocksPosition(items?.map((item, index) => ({id: item?.id!, x: index!, y: item?.position?.y!, w: item?.position?.w!, h: item?.position?.h!})))
    setFormBlocks(items)
  }

  function handleSubmit() {
    let values = getValues()

    if(formState.isValid && watchedData) {
      if(!parentBlock) {
        storeFormBlock(prepareValuesForSave(values))
        setFormSubmitted(true)
        reset()
      } else {
        storeFormBlock(prepareValuesForSave(values)) 
        setFormSubmitted(true)
        reset()
        // Закрыть модалку и очистить карточку
        spaceStore.boardActions.setCurrentCard(null, parentInit)
        spaceStore.boardActions.setCurrentCardId(null, parentInit)
        spaceStore.boardActions.setCardModalIsOpened(false, parentInit)
      } 
    } else {
    }
  }

  function prepareValuesForSave(values: any) {
    let data = (formBlocks?.filter(fb => fb.type === BlockTypeEnum.BlockField) as Block[]).map((bf) => (
      {...{fieldId: (bf.source as BlockSourceField).fieldId!, type: (bf.source as BlockSourceField)?.field?.type!}, value: valueToString(values[(bf.source as BlockSourceField)?.field?.id!], (bf.source as BlockSourceField)?.field?.type!)} 
    ))
    if(parentBlock && parentBlock.source?.type === BlockSourceTypeEnum.Field) {
      return {
        boardId: (formBlock.source as BlockSourceField).boardId!, 
        cardId: null,
        values:
          [...data,
            {
              fieldId: ((parentBlock.source as BlockSourceField).field?.attributes as Relation).foreginRelationFieldId!,
              type: FieldType.Relation,
              value: JSON.stringify([parentStore.relationPrimaryKey!])
            }
          ]
      }
    } else {
      return {boardId: (formBlock.source as BlockSourceField).boardId!, cardId: null, values: data}
    }
  }


  return (
    loading ? <></> : 
    <>
      <div className="w-full h-full relative">
        {editorMode && 
          <div className="absolute right-0">
            <div className="flex flex-row w-full justify-end space-x-2 py-2 px-2">
              <button 
                className="bg-slate-200 rounded text-slate-400 flex px-2 py-1 text-sm font-medium hover:bg-indigo-600 hover:text-white" 
                onClick={(e) => onOpenSettings(e)}
                >
                    Настройка
              </button>
              <button 
                className="bg-slate-200 rounded text-slate-400 flex px-2 py-1 text-sm font-medium hover:bg-indigo-600 hover:text-white" 
                onClick={(e) => onOpenFields(e)}
                >
                  Поля
              </button>
            </div>
          </div>
        }
        { !formSubmitted ?
        <div className="flex flex-col overflow-scroll h-full w-full py-10">
          {(formBlock.options as BlockFormOptions).showTitle ?
            <div className={`max-w-full w-[900px] mx-auto space-y-2 flex flex-col text-xl font-semibold py-4 px-4`}>{(formBlock.options as BlockFormOptions).title}</div> 
            : 
            <></>
          }
          <FormProvider {...methods}>
            {editorMode ? 
              <div className={`w-full max-w-[900px] mx-auto space-y-2 flex flex-col`}>
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        className={`flex-1 flex flex-col items-start w-full px-2 ${snapshot.isDraggingOver ? "bg-blue-100" : ""}`}
                      >
                        {(_.orderBy(formBlocks, item => item?.position?.x, "asc")).map((item, index) => (
                          <Draggable key={item?.id} draggableId={item?.id!} index={index}>
                            {(provided, snapshot) => (
                              <div 
                              className={
                                `mx-auto bg-white hover:bg-slate-100 relative p-2 w-full mb-2
                                ${item?.position?.w! < 13 ? 'max-w-[900px]' : ''}
                                ${currentBlock?.id === item?.id ? "ring-blue-500 ring-2 ring-inset hover:ring-blue-600" : ""}
                                ${snapshot.isDragging ? "bg-blue-200" : ""}
                                `} 
                                onClick={(event) => setCurrentBlock(item)}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                              >
                                {currentBlock?.id === item?.id ? <BlockBaseControls block={item}/> : <></>}
                                <div {...provided.dragHandleProps} className="absolute -left-6 top-1 w-6 h-6 flex items-center justify-center text-slate-400">
                                  <TbDragDrop size={18}/>
                                </div>
                                <FieldBlock block={item}/>
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                    
                  </Droppable>
                </DragDropContext>
                {(formBlock.options as BlockFormOptions)?.submitText ? 
                  <div className="flex-none flex justify-between items-center w-full max-w-[900px] mx-auto text-sm pt-8 pb-20 px-4">
                    <div className="text-slate-300">
                      {formState.isValid ? "Нет ошибок" : "Есть ошибки в форме"}
                    </div>
                    <button type="button" onClick={handleSubmit} className="px-4 py-1.5 bg-indigo-600 disabled:bg-indigo-300 rounded text-white text-sm font-medium">{(formBlock.options as BlockFormOptions)?.submitText}</button>
                  </div> 
                  : 
                  <div className="flex-none flex justify-between items-center w-full max-w-[900px] mx-auto text-sm pt-8 pb-20 px-4">
                    <div className="text-slate-300">
                      {formState.isValid ? "Нет ошибок" : "Есть ошибки в форме"}
                    </div>
                    <button type="button" onClick={handleSubmit} className="px-4 py-1.5 bg-indigo-600 disabled:bg-indigo-300 rounded text-white text-sm font-medium">Сохранить</button>
                  </div> 
                }
              </div>
              :
              <div className={`max-w-full w-full mx-auto space-y-2 flex flex-col`}>
                {(_.orderBy(formBlocks, item => item?.position?.x, "asc")).map(item => (
                  <div key={item.id} 
                    className={
                    `mx-auto bg-white relative py-2 px-4 w-full mb-2
                    ${item?.position?.w! < 13 ? 'max-w-[900px]' : 'w-full'}
                    `} 
                  >
                    <PageBlock pageBlock={item}/>
                  </div>
                ))}
                {(formBlock.options as BlockFormOptions)?.submitText ? 
                  <div className="flex-none flex justify-between items-center w-full max-w-[900px] mx-auto text-sm pt-8 pb-20 px-4">
                    <div className="text-slate-300">
                      {formState.isValid ? "Нет ошибок" : "Есть ошибки в форме"}
                    </div>
                    <button type="button" onClick={handleSubmit} className="px-4 py-1.5 bg-indigo-600 disabled:bg-indigo-300 rounded text-white text-base font-medium">{(formBlock.options as BlockFormOptions)?.submitText}</button>
                  </div> 
                : 
                  <div className="flex-none flex justify-between items-center w-full max-w-[900px] mx-auto text-sm pt-8 pb-20 px-4">
                    <div className="text-slate-300">
                      {formState.isValid ? "Нет ошибок" : "Есть ошибки в форме"}
                    </div>
                    <button type="button" onClick={handleSubmit} className="px-4 py-1.5 bg-indigo-600 disabled:bg-indigo-300 rounded text-white text-base font-medium">Сохранить</button>
                  </div> 
                }
              </div>
            }
          </FormProvider>
        </div>
        : 
        <div className="flex items-center justify-center">
          Отправлено
          <button type="button" className="" onClick={() => navigate("/")}>На главную</button>
          <button type="button" className="" onClick={() => setFormSubmitted(false)}>Отправить еще</button>
        </div>}
      </div>
      {openFields && 
        <MatrMediumDialog open={openFields} onClose={closeFields}>
          <BlockFormFields id={formBlock.id} boardId={(formBlock.source as BlockSourceBlock | BlockSourceBoard).boardId!} onClose={closeFields}/>
        </MatrMediumDialog>
      }
      {openSettings && 
        <MatrMediumDialog open={openSettings} onClose={closeSettings}>
          <BlockEdit block={formBlock} onClose={closeSettings}/>
        </MatrMediumDialog>
      }      
    </>
  );
}

export default FormBlockCreate;