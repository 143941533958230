import _ from "lodash";
import { useEffect, useState } from "react";
import { DragDropContext, Draggable, DropResult, Droppable, ResponderProvided } from "react-beautiful-dnd";
import { TbDragDrop } from "react-icons/tb";
import useDeepCompareEffect from "use-deep-compare-effect";
import { Block, BlockTypeEnum, Maybe } from "../../../../../core/graphql/types";
import useDebounce from "../../../../../core/hooks/debounce/useDebounce";
import { usePanel } from "../../../../../core/providers/panel";
import PageBlock from "../../blocks";
import FormBlock from "../../blocks/BlockForm";
import {BlockBaseControls} from "../../blocks/Controls";
import { LayoutBlankMenu } from "./menu";


const reorder = (list: Maybe<Block>[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export default function LayoutBlank() {
  const {page, block, pageBlock, setPageBlock, changeCurrentBlock, updateBlocksPosition, editorMode, updatePage} = usePanel()
  const [blocks, setBlocks] = useState<Maybe<Block>[]>()


  useDeepCompareEffect(() => {
    setPageBlock(null)
    if(!page?.blocks || page?.blocks.length === 0) {return}
    setBlocks(_.orderBy(page?.blocks!.filter(b => b?.type !== BlockTypeEnum.BlockForm && b?.type !== BlockTypeEnum.BlockField), item => item?.position?.x, "asc"))
  },[page])



  function handleClickPageBlock(e: any, block: Block) {
    changeCurrentBlock(e, block)
  }


  function onDragEnd(result: DropResult, provided: ResponderProvided) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    
    const items = reorder(
      blocks!,
      result.source.index,
      result.destination.index
    );

    updateBlocksPosition(items?.map((item, index) => ({id: item?.id!, x: index!, y: item?.position?.y!, w: item?.position?.w!, h: item?.position?.h!})))
    setBlocks(items)
  }

  return (
    <div className="flex h-full w-full relative">
      {editorMode ? 
        <>
          <div className={`h-full w-full overflow-scroll`} onClick={(event) => changeCurrentBlock(event, null)}>
            <LayoutBlankMenu />            
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    className={`flex flex-col items-start max-w-[900px] mx-auto ${snapshot.isDraggingOver ? "bg-blue-100" : ""}`}
                  >
                    {blocks?.map((item, index) => (
                      <Draggable key={item?.id} draggableId={item?.id!} index={index}>
                        {(provided, snapshot) => (
                          <div 
                            className={
                              `max-w-[900px] mx-auto bg-white hover:bg-slate-100 relative p-2 w-full mb-2
                              ${block?.id === item?.id ? "ring-blue-500 ring-2 ring-inset hover:ring-blue-600" : ""}
                              ${snapshot.isDragging ? "bg-blue-200" : ""}
                              `} 
                            onClick={(event) => changeCurrentBlock(event, item)}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                          >
                            {block?.id === item?.id ? <BlockBaseControls block={item!}/> : <></>}
                            <div {...provided.dragHandleProps} className="absolute -left-6 w-6 h-6 flex items-center justify-center text-slate-400">
                              <TbDragDrop />
                            </div>
                            <PageBlock pageBlock={item!}/>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        </>
        : 
        <>
          <div className="h-full w-full overflow-scroll">
            <div className="flex flex-col items-start space-y-2 pt-8">
              {blocks?.map(item => (
                <div 
                  key={item?.id}
                  className="max-w-[900px] mx-auto w-full"
                >
                  <PageBlock pageBlock={item!}/>
                </div>
              ))}
            </div>
          </div>
        </>
      }
    </div>
  )
}