import React, {useEffect} from "react";
import { useFormContext, Controller } from "react-hook-form";
import { BiToggleLeft, BiToggleRight } from "react-icons/bi";
import Select from "react-select";
import {dateFormatList, timeFormatList} from '../../../../../core/enums';
import { customStyles } from "../../../../../utils";

interface FieldDateTimeProps {
  type: string;
  includeTime: boolean;
  useOnTimezone: boolean;
  decimalDefaultValue?: number;
  dateFormat: string;
  timeFormat: string;
}

function FieldDateTime({attributes}: FieldDateTimeProps | any) {
  const methods = useFormContext();
  const {register, unregister, watch, control, setValue} = methods;

  useEffect(() => {
    unregister('attributes');
  },[])

  useEffect(() => {
    register('attributes.type');
    setValue('attributes.type', "DATETIME");
    setValue('attributes.includeTime', attributes.includeTime ? attributes.includeTime : false);
    setValue('attributes.useOnTimezone', attributes.useOnTimezone ? attributes.useOnTimezone : true);
    setValue('attributes.dateFormat', attributes.dateFormat ? attributes.dateFormat : "LOCAL");
    setValue('attributes.timeFormat', attributes.timeFormat ? attributes.timeFormat : "FULL");
  },[attributes])

  return (
    <>
      <div className="relative">
        <label className="font-medium text-gray-600 text-sm">Формат даты:</label>
        <Controller
          name="attributes.dateFormat"
          control={control}
          render={({ field: { onChange, value }}) => (
            <div className="mt-2">
              <Select
                styles={customStyles}
                classNamePrefix="matr_select_form"
                placeholder="Выберите значение по умолчанию"
                isSearchable={false}
                defaultValue={dateFormatList.find(format => format.value === value)}
                value={dateFormatList.find(format => format.value === value)}
                onChange={val => onChange(val?.value)}
                options={dateFormatList}
              />
            </div>
          )}
        />
      </div>
      <div className="relative inline-flex">
        <label className="font-medium text-slate-800 text-sm flex items-center gap-2">
          <Controller
            name="attributes.includeTime"
            control={control}
            render={({ field: { onChange, value, ref }}) => (
              <div 
                className={`flex items-center  text-sm rounded-md font-normal justify-center cursor-default`} 
                onClick={() => onChange(!value)}
              >
                {value ? <BiToggleLeft className='h-6 w-6 text-green-600'/> : <BiToggleRight className='h-6 w-6 text-slate-400'/>}
              </div>
            )}
          />
          Включить выбор времени
        </label>
        {/* <label className="font-medium text-gray-600 text-sm flex items-center">
          <input 
            {...register("attributes.includeTime")}
            className="form-checkbox rounded-sm focus:outline-none focus:outline-offset-0 mr-2"
            type="checkbox" 
          />
          Включить выбор времени
        </label> */}
      </div>
      {!watch('attributes.includeTime') ? "" :
        <>
          <div className="relative">
            <label className="font-medium text-gray-600 text-sm">Формат времени:</label>
            <Controller
              name="attributes.timeFormat"
              control={control}
              render={({ field: { onChange, value, ref }}) => (
                <div className="mt-2">
                  <Select
                    styles={customStyles}
                    classNamePrefix="matr_select_form"
                    placeholder="Выберите значение по умолчанию"
                    isSearchable={false}
                    defaultValue={timeFormatList.find(format => format.value === value)}
                    value={timeFormatList.find(format => format.value === value)}
                    onChange={val => onChange(val?.value)}
                    options={timeFormatList}
                  />
                </div>
              )}
            />
          </div>
          {/* <div className="relative inline-flex">
            <label className="font-medium text-slate-800 text-sm flex items-center gap-2">
              <Controller
                name="attributes.useOnTimezone"
                control={control}
                render={({ field: { onChange, value, ref }}) => (
                  <div 
                    className={`flex items-center  text-sm rounded-md font-normal justify-center cursor-default`} 
                    onClick={() => onChange(!value)}
                  >
                    {value ? <BiToggleLeft className='h-6 w-6 text-green-600'/> : <BiToggleRight className='h-6 w-6 text-slate-400'/>}
                  </div>
                )}
              />
              Использовать единый часовой пояс для всех участников
            </label>
          </div> */}
        </>  
        }
    </>
  );
}
export default FieldDateTime;