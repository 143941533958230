import Header from "../../components/Headers/Header";
import { useAuth } from "../../core/providers/auth";

export function PrivatePage() {
  let {currentUser} = useAuth()
  return (
    <>
      <Header location="page"/>
    </>
  );
}