import { useState } from "react";
import NumberFormat, {NumberFormatValues} from 'react-number-format';
import { NumberValue } from '../../../core/graphql/types';
import { NumberIcon } from "../../icons";
import type { FieldPrimitiveEditorProps } from './types';


export default function FieldNumber({field, card, updatePrimitive}: FieldPrimitiveEditorProps) {
  const [value, setValue] = useState((card[field.id] as unknown as NumberValue)?.valueAsNumber! ? (card[field.id] as unknown as NumberValue)?.valueAsNumber! : "")
  const attr = field.attributes;
  
  function handleChange(e: NumberFormatValues) {
    updatePrimitive(
      { ...card, [field.id]: {...card[field.id], valueAsNumber: e.floatValue}}, 
      ({...card[field.id], valueAsNumber: e.floatValue} as unknown as NumberValue), 
      {valueAsNumber: e.floatValue}
    )
    setValue(e?.floatValue!)
  }


  return (
    <div className="relative flex flex-col space-y-1.5">
      <div className="flex items-center h-full">
        <div className="text-slate-400 pr-2"><NumberIcon /></div>
        <label className="text-slate-500 text-sm">{field.title}</label>
      </div>
      <NumberFormat
        autoFocus={false}
        allowNegative={attr.negative}
        decimalScale={0}
        className="matr-form-input"
        displayType="input"
        value={value}
        onValueChange={(values) => handleChange(values)}
      />
    </div>
  );
}