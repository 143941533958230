/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Outlet} from "react-router-dom";
import { Helmet } from 'react-helmet-async';
import { useTelegram } from "../../../core/hooks/useTelegram";
import { useEffect } from "react";

export function TelegramLayout({ children }: { children: React.ReactNode }) {
  const {tg} = useTelegram()
  useEffect(() => {
    tg.ready()
  },[])

  return (
    <section className="telegram w-full h-screen p-6">
      {/* <Helmet>
        <script src="https://telegram.org/js/telegram-web-app.js"></script>
      </Helmet> */}
      {children}
    </section>
  );
}