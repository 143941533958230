import { Cookies } from "react-cookie";
import { TOKEN_NAME } from "../constants";

export const saveToken = ( token: any ) => {
  new Cookies().set(TOKEN_NAME, token)
  // localStorage.setItem(TOKEN_NAME, token);
};

export const getToken = () => {
  return new Cookies().get(TOKEN_NAME)
  // return localStorage.getItem(TOKEN_NAME);
};


export const clearStorage = () => {
  new Cookies().remove(TOKEN_NAME)
  // localStorage.clear();
};
