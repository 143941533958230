import React, {useState, useEffect} from "react";
import Avatar from "react-avatar";
import {HiOutlineTrash} from "react-icons/hi";
import toast from 'react-hot-toast';
import Select from 'react-select';
import { useSearchBoardsQuery, useBoardSourcesQuery, useGetFieldsQuery, useAddBoardToBoardSourcesMutation, useRemoveBoardFromBoardSourcesMutation, Board} from "../../../../core/graphql/types"
import { useBoard } from "../../../../core/providers/board";
import { useSpaceStore } from "../../../../core/store/store";
import { TbSquaresFilled, TbX } from "react-icons/tb";
import MatrSelect from "../../../../components/ui/select/base";



function BoardSources({close}:{close: () => void}) { 
  const {initId, initType} = useBoard()
  const init: string = `${initType}:${initId}`
  const store = useSpaceStore()
  const {boards} = store;
  const {board, sources} = store.data[init];
  const {setBoardSources} = store.boardActions;

  const [term, setTerm] = useState('');
  const [options, setOptions] = useState<any>();
  const [source, setSource] = useState<any>();
  const [{ data }, searchBoards] = useSearchBoardsQuery({variables: {board: board?.id!, term: term}, pause: true});
  const [{ data: boardSourcesData}, getSources] = useBoardSourcesQuery({variables: {id: board?.id!}, pause: true, requestPolicy: "network-only"})
  const [,addSource] = useAddBoardToBoardSourcesMutation()
  const [,removeSource] = useRemoveBoardFromBoardSourcesMutation()

  useEffect(() => {

  },[])


  const handleSave = () => {
    if(!source) {
      return
    }
    addSource({
      board_id: board?.id!,
      source_id: source.id
    }).then((result: any) => {
      if(result.error) {return}
      let newSources = [...sources, ...boards.filter(b => b.id === result.data?.addBoardToBoardSources?.id)]
      setBoardSources(newSources, init)
    })
  }

  const handleDelete = (source_id: string) => {
    removeSource({
      board_id: board?.id!,
      source_id: source_id
    }).then(result => {
      if(result.error) {return}
      let newSources = sources.filter((s: { id: string; }) => s.id !== source_id)
      setBoardSources(newSources, init)
    })
  }
  
  return (
    <>
      <div className="flex items-center justify-between mb-4">
        <div className="flex items-center space-x-2">
          <div className="py-1 px-2 bg-slate-100 flex space-x-2 rounded">
            <TbSquaresFilled size={20} className="text-indigo-600"/>
            <div className="flex font-medium text-sm">{board.name}</div>
          </div>
          <div className="flex font-normal text-sm">Связывание таблиц</div>
        </div>
        <button onClick={() => close()}><TbX size={18}/></button>
      </div>
      <div className="relative w-full text-left">
        <label className="font-medium text-gray-600 text-sm">Добавление таблицы, как источника:</label>
        <div className="flex flex-row items-center w-full mt-2">
          <div className="w-full">
            <div className="relative">
              <MatrSelect
                placeholder="Поиск доступных досок"
                onChange={(v) => setSource(v)}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                options={boards.filter(b => b.id != board.id)}
              />
            </div>
          </div>
          <button type="submit" className="flex rounded-sm px-4 py-2 ml-4 font-medium text-base bg-indigo-600 text-white" onClick={() => handleSave()}>Добавить</button>
        </div>
      </div>
        
      <div className="flex mt-4 text-left text-sm text-gray-600 font-medium">Источники</div>
      <div className="flex mb-2 py-1 text-left text-xs text-gray-600">При удалении источника будут удалены все связныеи с ним колонки</div>
      <div className="flex flex-col space-y-4 pb-2"> 
      {sources.map((board: any) => {
        return (
          <div key={board.id} className="flex flex-row items-center w-full rounded focus:outline-none hover:ring-8 hover:ring-slate-100 hover:bg-slate-100">
            <div className="flex w-full items-center">
              <div className="mr-2">
                <Avatar name={`${board.name}`} round size="30" className="font-medium"/>
              </div>
              <div className="">
                <div className="text-sm font-medium">
                  {`${board.name}`}
                </div>
              </div>
            </div>
            <div className="flex justify-end">
              <button className="flex w-6 h-6 bg-red-200 rounded-md items-center justify-center">
                <HiOutlineTrash className="w-3 h-3 text-red-600" onClick={() => handleDelete(board.id)}/>
              </button>
            </div>
          </div>
        )
      })}
      </div>
    </>
  );

}

export default BoardSources;