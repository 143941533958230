import { FetcherVariable, Maybe } from "../../graphql/types"

export function isVariable(value: string) {
    if(!value) { return false }
    if(value.startsWith("{{") && value.endsWith("}}")) {
      return true
    } else {
      return false
    }
  }

export function isVariableExist(value: string, variables: Maybe<FetcherVariable>[]) {
    let key = value.slice(2, -2)
    if(variables?.map(v => v?.key).includes(key)) {
      return true
    } else {
      return false
    }
}