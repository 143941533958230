import { useEffect, useState } from 'react';
import { AiOutlineSortAscending, AiOutlineSortDescending } from 'react-icons/ai';
import { HiOutlineTrash } from 'react-icons/hi';
import { Field, GroupByField, GroupOrSortDirectionEnum } from '../../../../core/graphql/types';
import { TbCheck } from 'react-icons/tb';

interface GroupByFieldsProps {
  fields: Field[];
  value: GroupByField[] | [];
  onChangeFields: any;
}

function GroupByFields({fields, value, onChangeFields}: GroupByFieldsProps) {
  const [selection, setSelection] = useState<GroupByField[]>(value)
  const [options, setOptions] = useState<Field[]>([]);

  useEffect(() => {
    let items: any[] = []
    
    fields.filter((f: Field) => !["TEXTAREA", "ATTACHMENT"].includes(f.type) ).map((f: Field) => {
      items.push(f)
    })

    setOptions(items)
  },[fields])


  const handleChange = (item: Field) => {
    let ns: GroupByField[] = []
    if(selection.some(it => it.fieldId === item.id)) {
      ns = selection.filter(it => it.fieldId !== item.id)
    } else {
      ns = [...selection, {fieldId: item.id, fieldType: item.type, direction: GroupOrSortDirectionEnum.Asc, position: selection.length + 1}]
    }
    setSelection(ns)
    onChangeFields(ns)
  }

  const handleChangeDirection = (item: GroupByField, direction: GroupOrSortDirectionEnum) => {
    let ns: GroupByField[] = selection.map(i => {
      if (i.fieldId === item.fieldId) {
        return {...i, direction: direction}
      }
      return i;
    })
    setSelection(ns)
    onChangeFields(ns)
  }

  const handleDelete = (item: GroupByField) => {
    let ns: GroupByField[] = selection.filter(it => it.fieldId !== item.fieldId)
    setSelection(ns)
    onChangeFields(ns)
  }
  
  return (
    <>
      <div className='py-1'>
        {selection.length > 0 && selection.map((item: GroupByField) => (
          <div key={item?.position} className='flex w-full hover:bg-indigo-800 px-1 py-1 rounded justify-between items-center cursor-pointer bg-indigo-600 text-white'>
            <div className='flex flex-row'>
              <div className='px-0.5 bg-indigo-600 hover:bg-indigo-400 hover:text-indigo-800 h-full py-0.5 rounded'>{item?.direction && item?.direction === GroupOrSortDirectionEnum.Asc ? <AiOutlineSortAscending onClick={() => handleChangeDirection(item, GroupOrSortDirectionEnum.Desc)} size={20}/> : <AiOutlineSortDescending onClick={() => handleChangeDirection(item, GroupOrSortDirectionEnum.Asc)} size={20}/>}</div>
              <span className='text-base py-0.5 px-1'>{options.find((f: Field) => f.id === item?.fieldId)?.title}</span>
            </div>
            <div className='px-1.5 py-1.5 bg-indigo-600 hover:bg-indigo-400 hover:text-indigo-800 rounded'><HiOutlineTrash size={14} onClick={() => handleDelete(item)}/></div>
          </div>
        ))}
      </div>
      {selection.length == 0 &&
      <div>
        <div className='text-sm px-2 font-medium text-slate-600'>Выберите колонку для группировки:</div>
        <div className="flex flex-col max-h-96 space-y-1.5 py-2 overflow-auto">
          {options.map((f: Field) => (
            <div 
              key={f.id}
              className={`flex items-center py-2 pl-2 pr-3 text-base ${selection.some(s => s?.fieldId === f.id) ? "text-slate-800" : "text-slate-600"} rounded hover:bg-slate-100 font-medium justify-between w-full cursor-default`} 
              onClick={() => handleChange(f)}
            >
              {f.title}
              {selection.some(s => s?.fieldId === f.id) ? <TbCheck size={16} className='text-green-800'/> : <></>}
            </div>
          ))}
        </div>
      </div>}
    </>
  )
}

export default GroupByFields;