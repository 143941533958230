import { Menu, Transition } from "@headlessui/react";
import { HiDotsHorizontal, HiDuplicate, HiTrash } from "react-icons/hi";
import { Fragment } from "react";

export default function RequestPopper({request, onRemove}:{request: any, onRemove: any}) {
  return (
    <div className="flex w-8 h-8 items-center">
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button className="inline-flex w-full justify-center rounded-sm px-4 py-2 text-sm font-medium text-gray-400 hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
            <HiDotsHorizontal size={16}/>
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute z-10 right-0 mt-2 w-40 origin-top-right divide-y divide-gray-100 rounded-sm bg-white shadow-sm ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="px-0.5 py-0.5 ">
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={`${
                      active ? 'bg-slate-500 text-white' : 'text-gray-900'
                    } group flex w-full items-center rounded-sm px-2 py-1.5 text-xs`}
                  >
                    {active ? (
                      <HiDuplicate className="mr-2 h-4 w-4" aria-hidden="true"/>
                    ) : (
                      <HiDuplicate className="mr-2 h-4 w-4" aria-hidden="true"/>
                    )}
                    Дублировать
                  </button>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={`${
                      active ? 'bg-slate-500 text-white' : 'text-gray-900'
                    } group flex w-full items-center rounded-sm px-2 py-1.5 text-xs`}
                    onClick={() => onRemove(request)}
                  >
                    {active ? (
                      <HiTrash className="mr-2 h-4 w-4" aria-hidden="true"/>
                    ) : (
                      <HiTrash className="mr-2 h-4 w-4" aria-hidden="true"/>
                    )}
                    Удалить
                  </button>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  )
}