import { useEffect, useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import { HiOutlineDotsVertical } from "react-icons/hi";
import { HiMinusCircle } from "react-icons/hi";
import { operators, conditions } from "../../../../core/enums";
import { Field, Filter, Relation, Lookup, Select as SelectType, Multiselect } from "../../../../core/graphql/types";
import MatrAsyncNarrowSelect from "../../../ui/select/narrow/async";
import MatrNarrowSelect from "../../../ui/select/narrow";

import { StringValue, NumberValue, DecimalValue, SelectValue, DateTimeValue, UserValue, UpdatedByValue, CheckboxValue, RelationValue, LookupValue} from "./values";
import { useSpace } from "../../../../core/providers/space";
import { useFormContext, Controller } from "react-hook-form";
import { SingleValue } from "react-select";

interface FilterItemProps {
  filters: any;
  index: number;
  boardFields: any;
  users: any;
  onFilterChange: () => void;
  deleteFilter: (index: number) => void;
}

function FilterItem({filters, index, boardFields, users, onFilterChange, deleteFilter}: FilterItemProps) {
  const methods = useFormContext();
  const {register, unregister, watch, control, setValue, getValues} = methods;
  const {boards: sources} = useSpace();
  // const [filterItem, setFilterItem] = useState<Filter>(filters[index]);


  function handleChangeConjuction(e: SingleValue<{ label: string; value: string; }>, onChange: { (...event: any[]): void; (arg0: any): void; }) {
    onChange(e?.value)
    onFilterChange()
  }

  function handleChangeField(e: { id: any; type: any; }, onChange: (...event: any[]) => void) {
    setValue(`filters.${index}.fieldType`, e.type)
    setValue(`filters.${index}.fieldValueType`, getFieldValueType(e))
    setValue(`filters.${index}.operator`, operators.filter((o: any) => o.type === getFieldValueType(e))[0].value)
    setValue(`filters.${index}.currentUser`, false)
    setValue(`filters.${index}.value`, null)
    setValue(`filters.${index}.valueAsNumber`, null)
    setValue(`filters.${index}.valueAsDecimal`, null)
    setValue(`filters.${index}.valueAsArray`, [])
    setValue(`filters.${index}.valueAsBoolean`, null)
    setValue(`filters.${index}.valueAsDate`, null)
    setValue(`filters.${index}.currentUser`, null)
    onChange(e.id)
    onFilterChange()
  }

  function getFieldValueType(e: any) {
    if(["FORMULA"].includes(e.type)) { return e.attributes.expressionType } 
    else if(["LOOKUP"].includes(e.type)) { return e.attributes.fieldType } 
    else { return e.type }
  }

  function resetValue() {
    setValue(`filters.${index}.value`, null)
    setValue(`filters.${index}.valueAsNumber`, null)
    setValue(`filters.${index}.valueAsDecimal`, null)
    setValue(`filters.${index}.valueAsArray`, [])
    setValue(`filters.${index}.valueAsBoolean`, null)
    setValue(`filters.${index}.valueAsDate`, null)
    setValue(`filters.${index}.currentUser`, null)
  }

  function handleChangeOperator(e: SingleValue<{ label: string; value: string; type: string; editor: string; multi?: undefined; values?: undefined; } | { label: string; value: string; type: string; multi: boolean; editor: string; values?: undefined; } | { label: string; value: string; type: string; editor: string; values: { label: string; value: string; editor: boolean; }[]; multi?: undefined; }>, onChange: (...event: any[]) => void) {
    onChange(e?.value)
    maybeChangeValueOnOperatorChange(e)
    onFilterChange()
  }

  function maybeChangeValueOnOperatorChange(operator: any) {
    if(operator.editor === "NO") { return resetValue()}
    if(operator.editor === "SELECT" && operator.multi !== operators.find((o: any) => o.type === getValues(`filters.${index}.fieldType`) && o.value === getValues(`filters.${index}.operator`))?.multi) { 
      return resetValue()
    }
    if(["DATETIME", "INSERTED_AT", "UPDATED_AT"].includes(operator.type)) {
      setValue(`filters.${index}.mode`, operator.values[0].value)
      setValue(`filters.${index}.valueAsDate`, null)
      setValue(`filters.${index}.valueAsNumber`, null)
    }
  }

  function handleChangeValue(value: any, for_lookup?: any) {
    if(["STRING", "URL", "EMAIL"].includes(getValues(`filters.${index}.fieldValueType`))) {setValue(`filters.${index}.value`, value)}
    if(["NUMBER", "DURATION", "PHONE"].includes(getValues(`filters.${index}.fieldValueType`))) {setValue(`filters.${index}.valueAsNumber`, parseInt(value))}
    if(["DECIMAL", "PERCENT", "CURRENCY"].includes(getValues(`filters.${index}.fieldValueType`))) {setValue(`filters.${index}.valueAsDecimal`, parseFloat(value))}
    if(["SELECT", "MULTISELECT"].includes(getValues(`filters.${index}.fieldValueType`))) {setValue(`filters.${index}.valueAsArray`, value)}
    if(["CREATED_BY", "UPDATED_BY", "COLLABORATOR"].includes(getValues(`filters.${index}.fieldValueType`))) {
      setValue(`filters.${index}.valueAsArray`, value.filter((v: {label: string, value: string}) => v.value !== "current_user").map((v: {label: string, value: string}) => v.value))
      setValue(`filters.${index}.currentUser`, value.find((v: {label: string, value: string}) => v.value === "current_user") ? true : false)
    }
    if(["CHECKBOX"].includes(getValues(`filters.${index}.fieldValueType`))) {setValue(`filters.${index}.valueAsBoolean`, value)}
    if(["DATETIME", "INSERTED_AT", "UPDATED_AT"].includes(getValues(`filters.${index}.fieldValueType`))) {
      if(value.mode === "CUSTOM") {
        setValue(`filters.${index}.valueAsDate`, value.date.toISOString())
        setValue(`filters.${index}.valueAsNumber`, null)
        setValue(`filters.${index}.mode`, value.mode)
      } else if(["NUMBER_OF_DAYS_AGO", "NUMBER_OF_DAYS_FUTURE", "PAST_DAYS", "NEXT_DAYS"].includes(value.mode)) {
        setValue(`filters.${index}.valueAsDate`, null)
        setValue(`filters.${index}.valueAsNumber`, parseInt(value.number_of_days))
        setValue(`filters.${index}.mode`, value.mode)
      } else {
        setValue(`filters.${index}.valueAsDate`, null)
        setValue(`filters.${index}.valueAsNumber`, null)
        setValue(`filters.${index}.mode`, value.mode)
      }
    }

    if(getValues(`filters.${index}.fieldValueType`) === "RELATION") {
      if(typeof value === "string") {
        setValue(`filters.${index}.value`, value)
      }
      if(typeof value === "object") {
        setValue(`filters.${index}.valueAsArray`, value)
      }
    }

    if(getValues(`filters.${index}.fieldValueType`) === "LOOKUP") {
      if(typeof value === "string") {
        setValue(`filters.${index}.value`, value)
      }
      if(typeof value === "object") {
        setValue(`filters.${index}.valueAsArray`, value)
      }
    }
    onFilterChange()
  }

  function getOpearatorsList(type: any) {
    return operators.filter((op: any) => op.type === type)
  }

  const promiseOptions = (type: any) => {
  new Promise((resolve) => {
    setTimeout(() => {
      resolve(getOpearatorsList(type));
    }, 1);
  })};

  function getValueInput() {
    const boardField = getBoardField()
    let filterItem = getValues(`filters.${index}`) 
    let foreginFieldId = ""
    if(filterItem.fieldType === "RELATION") {
      foreginFieldId = (boardField.attributes as Relation).fieldId
    } else if(filterItem.fieldType === "LOOKUP") {
      foreginFieldId = (boardFields.find((bf: any) => bf.id === filterItem.fieldId).attributes as Lookup).fieldId
    }

    const operator = operators.find((o: any) => o.type === filterItem.fieldValueType && o.value === filterItem.operator);
    switch (filterItem.fieldValueType) {
      case "STRING": return <StringValue item={filterItem} onChange={handleChangeValue} operator={operator}/>
      case "URL": return <StringValue item={filterItem} onChange={handleChangeValue} operator={operator}/>
      case "EMAIL": return <StringValue item={filterItem} onChange={handleChangeValue} operator={operator}/>
      case "NUMBER": return <NumberValue item={filterItem} onChange={handleChangeValue} operator={operator} attr={boardField?.attributes}/>
      case "DURATION": return <NumberValue item={filterItem} onChange={handleChangeValue} operator={operator} attr={boardField?.attributes}/>
      case "PHONE": return <NumberValue item={filterItem} onChange={handleChangeValue} operator={operator} attr={boardField?.attributes}/>
      case "DECIMAL": return <DecimalValue item={filterItem} onChange={handleChangeValue} operator={operator} attr={boardField?.attributes}/>
      case "PERCENT": return <DecimalValue item={filterItem} onChange={handleChangeValue} operator={operator} attr={boardField?.attributes}/>
      case "CURRENCY": return <DecimalValue item={filterItem} onChange={handleChangeValue} operator={operator} attr={boardField?.attributes}/>
      case "CHECKBOX": return <CheckboxValue item={filterItem} onChange={handleChangeValue}/>
      case "SELECT": return <SelectValue options={(boardField?.attributes as SelectType).options} item={filterItem} onChange={handleChangeValue} operator={operator}/>
      case "MULTISELECT": return <SelectValue options={(boardField?.attributes as Multiselect).options} item={filterItem} onChange={handleChangeValue} operator={operator}/>
      case "COLLABORATOR": return <UserValue options={users} item={filterItem} onChange={handleChangeValue} operator={operator}/>
      case "CREATED_BY": return <UserValue options={users} item={filterItem} onChange={handleChangeValue} operator={operator}/>
      case "UPDATED_BY": return <UpdatedByValue options={users} item={filterItem} onChange={handleChangeValue} operator={operator}/>
      case "DATETIME": return <DateTimeValue filter={filterItem} onChange={handleChangeValue} operator={operator} attr={boardField?.attributes}/>
      case "INSERTED_AT": return <DateTimeValue filter={filterItem} onChange={handleChangeValue} operator={operator} attr={boardField?.attributes}/>
      case "UPDATED_AT": return <DateTimeValue filter={filterItem} onChange={handleChangeValue} operator={operator} attr={boardField?.attributes}/>
      case "RELATION": return <RelationValue field={boardField} item={filterItem} foreginFieldId={foreginFieldId} onChange={handleChangeValue} operator={operator}/>
      case "LOOKUP": return <LookupValue field={boardField} item={filterItem} foreginFieldId={foreginFieldId} onChange={handleChangeValue} operator={operator}/>
      default:
        break;
    }
  }


  function getBoardField() {
    if(getValues(`filters.${index}`).fieldType! === "RELATION") {
      let currentFieldAttr: Relation = boardFields.find((bf: any) => bf.id === getValues(`filters.${index}`).fieldId).attributes
      return sources.find(bs => bs.id === currentFieldAttr.boardId)?.fields!.find(bsf => bsf?.id === currentFieldAttr.fieldId) as Field
    } else if(getValues(`filters.${index}`).fieldType! === "LOOKUP") {
      let currentFieldAttr: Lookup = boardFields.find((bf: any) => bf.id === getValues(`filters.${index}`).fieldId).attributes
      return boardFields!.find((f: Field) => f.id === currentFieldAttr.relation) as Field
    } else {
      return boardFields!.find((bf: any) => bf.id === getValues(`filters.${index}`).fieldId) as Field
    }
  }

  const styles = {
    menu: (css: any) => ({
      ...css,
      width: "auto",
      minWidth: "max-content"
    }),
    control: () => ({border: 0}),
    option: (styles: any, { isDisabled}: {isDisabled: boolean}) => {
      return {
        ...styles,
        cursor: isDisabled ? 'not-allowed' : 'default',
      };
    },
  }
  
  return (
    <div className="flex relative flex-row min-w-min bg-white rounded-sm items-center text-sm justify-between border"> 
      <div className="flex flex-row w-full">
        <div className="flex flex-row items-center w-6 mr-2">
          <div className="focus:outline-none bg-gray-100 h-full text-gray-400 hover:text-indigo-600 items-center flex">
            <HiOutlineDotsVertical className="w-4 h-4 text-gray-400 hover:text-indigo-600"/>
          </div>
        </div>
        <div className="flex flex-col w-full items-center space-y-2 py-2 nodrag">
          <div className="relative flex flex-row w-full items-center">
            {index === 0 ? <div style={{minWidth: 80}}>Когда</div> : 
              <Controller 
                name={`filters.${index}.conjunction`}
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, onBlur, value, ref }}) => ( 
                  <div className="relative" style={{minWidth: 80}}>
                    <MatrNarrowSelect
                      isSearchable={false}
                      value={conditions.find((c: any) => c.value === value)}
                      onChange={e => handleChangeConjuction(e, onChange)}
                      options={conditions}
                    />
                  </div>
              )}/>
            }
            <Controller 
              name={`filters.${index}.fieldId`}
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, value, ref }}) => ( 
                <div className="relative ml-2 w-full">
                  <MatrNarrowSelect
                    isSearchable={false}
                    isOptionDisabled={(option) => ["TEXTAREA"].includes(option.type)}
                    getOptionLabel ={(option)=>option.title}
                    getOptionValue ={(option)=>option.id}
                    value={boardFields.find((f:Field) => f.id === value)}
                    onChange={e => handleChangeField(e, onChange)}
                    options={boardFields}
                  />
                </div>
            )}/>
          </div>
          <div className="grid grid-cols-6 w-full items-center gap-2">
            <Controller 
              name={`filters.${index}.operator`}
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, value, ref }}) => ( 
                <div className="relative mr-2 w-full col-span-2">
                  <MatrAsyncNarrowSelect
                    styles={styles}
                    classNamePrefix="matr_select_filters"
                    isSearchable={false}
                    value={operators.find((o: any) => o.value === value && o.type === getValues(`filters.${index}.fieldValueType`))}
                    onChange={e => handleChangeOperator(e, onChange)}
                    defaultOptions={operators.filter((op: any) => op.type === getValues(`filters.${index}.fieldValueType`))}
                    loadOptions={promiseOptions}
                  />
                </div>
            )}/>
            <div className="relative w-full min-w-min col-span-4">
              {getValueInput()}
            </div>
          </div>
        </div>
        <div className="flex flex-row items-start justify-end pt-2 pr-2 pl-2">
          <button className="focus:outline-none text-gray-400 hover:text-indigo-600" type="button" onClick={() => deleteFilter(index)}>
            <HiMinusCircle className="w-4 h-4"/>
          </button>
        </div>
      </div>
    </div>
  )
}

export default FilterItem;