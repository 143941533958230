import { useEffect, useState, useRef } from 'react';
import RelationExternalListItem from './item'; 
import { sortBy } from 'lodash';
import { CardWithValues, Field, useListCardsOfRelationBoardQuery} from '../../../../core/graphql/types';
import { SmallSpinner } from '../../../loaders';
import { HiOutlineXCircle } from 'react-icons/hi';

interface ListRelationCardsProps {
  boardId: string;
  fieldId: string;
  viewId?: string | null;
  foreginFieldId?: string;
  relations: string[];
  onChange: any;
  close: () => void;
}

function ExternalListRelationCards({fieldId, foreginFieldId, relations, boardId, onChange, close, viewId}:ListRelationCardsProps) {
  const listInnerRef = useRef<HTMLDivElement>(null);
  const [timer, setTimer] = useState<ReturnType<typeof setTimeout>>();
  const [ cards, setCards ] = useState<CardWithValues[] | any>([]);
  const [ loader, setLoader] = useState(true);
  const [ search, setSearch] = useState<string | null>(null)
  const [ searchTerm, setSearchTerm] = useState<string | null>(null)
  const [ fields, setFields] = useState<Field[] | any>([]);
  const [ foreginCards, setForeginCards] = useState([]);
  const [ countOfValues, setCountOfValues ] = useState<number>(1);
  const [ after, setAfter] = useState<any>(null)
  const limit = 40

  const [{fetching, data, error}, getCards] = useListCardsOfRelationBoardQuery({variables: {fromFieldId: fieldId, metadata: {after: after, before: null, limit: limit}, search: searchTerm}, pause: true, requestPolicy: 'network-only'})

  useEffect(() => {getCards()},[])

  useEffect(() => {
    if(!data) {
      return
    }
    setCards([...cards, ...data.listCardsOfRelationBoard?.board?.cards!])
    setFields(data.listCardsOfRelationBoard?.board?.fields)
    setAfter(data.listCardsOfRelationBoard?.metadata?.after)
  },[data])


  useEffect(() => {
    if(timer) clearTimeout(timer);
    setTimer(setTimeout(() => (
      setSearchTerm(search)
    ),500))
  }, [search])

  useEffect(() => {
    setAfter(null)
    setCards([])
    getCards()
  }, [searchTerm])

  const onScroll = () => {  
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop + clientHeight === scrollHeight) {
        getCards()
      }
    }
  };

  useEffect(() => {
    if(!cards || !cards.length || !fields || !fields.length) {
      setForeginCards([])
      return
    }

    const prepareForeginCards: any = []
    cards.map((item: CardWithValues) => {

        prepareForeginCards.push({
          id: item.id,
          position: item.position,
          selected: false,
          fields: preparingValues(item.values)
        })

    })
    setForeginCards(prepareForeginCards)
    setLoader(false)
  },[fields, cards, relations])


  function preparingValues(values: any) {
    const preValues: any = []
    values.map((value: any) => {preValues.push(transformValue(value))})

    const headerValue = preValues.find((val: any) => val.primary === true)
    const showValues = sortBy(preValues.filter((val: any) => val.preview === true), 'position')

    return [headerValue].concat(showValues)
  }

  function transformValue(value: any) {
    return ({
      id: value.id,
      fieldId: value.fieldId,
      preview: fields.find((f: Field) => f.id === value.fieldId).preview,
      position: fields.find((f: Field) => f.id === value.fieldId).position,
      title: fields.find((f: Field) => f.id === value.fieldId).title,
      primary: fields.find((f: Field) => f.id === value.fieldId).primary,
      value: typedValue(value),
      type: value.type,
      attributes: fields.find((f: Field) => f.id === value.fieldId).attributes,
    })
  }

  function typedValue(value: any) {
    switch (value.type) {
      case "STRING": 
      case "URL": 
      case "EMAIL": return value.valueAsString
      case "NUMBER": 
      case "PHONE": return value.valueAsNumber
      case "DECIMAL": 
      case "PERCENT": 
      case "CURRENCY": return value.valueAsDecimal
      case "SELECT": return value.valueAsJson
      case "UPDATED_BY":
      case "CREATED_BY": return value.valueAsJson
      case "MULTISELECT": 
      case "COLLABORATOR": return value.valueAsJsonArray
      case "DATETIME": 
      case "INSERTED_AT": 
      case "UPDATE_AT": return value.valueAsDatetime
      case "RELATION": 
      case "LOOKUP": return value.relations
    }
  }

  if(loader) return (<SmallSpinner />)

  return (
    <>
      <div className="flex flex-col h-full rounded-md">
        <div className="flex w-full h-16 justify-between text-xs bg-slate-200 items-center px-4 rounded-t-md font-medium border-b text-slate-600">
          <div className='flex flex-row w-full gap-2 pr-4'>
            <div className='flex w-full'>
              <input placeholder='Найти' className='text-sm w-full px-2 py-2 outline-none rounded focus:ring-2 focus:ring-indigo-300' value={search ? search! : ""} onChange={(e) => setSearch(e.target.value)}/>
            </div>
          </div>
          <div onClick={() => close()}><HiOutlineXCircle size={18}/></div>
        </div>
        <div ref={listInnerRef} onScroll={onScroll} className="flex w-full h-full  bg-white rounded-b-md overflow-scroll">
          {foreginCards?.length > 0 ?
          <div className="flex flex-col w-full px-4 py-4">
            <div  className="flex flex-col space-y-4 w-full last:pb-4">
              {
                foreginCards.map((item: any) => {
                  return <RelationExternalListItem key={item.id} card={item} fields={item.fields} onChange={() => onChange(item)}/>
                })
              }
            </div>
          </div>
          :
          <div className='flex w-full h-full items-center justify-center'></div>}
        </div>
      </div>
    </>
  )
}

export default ExternalListRelationCards;