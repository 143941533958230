import React, {ChangeEvent, useEffect} from "react";
import { useFormContext, Controller } from "react-hook-form";
import { BiToggleLeft, BiToggleRight } from "react-icons/bi";
import NumberFormat from 'react-number-format';

// Переписать на Intl.NumberFormat, используя локали пользователя

interface FieldNumberProps {
  type: string;
  negative: boolean;
  numberDefaultValue?: string;
  placeholder?: string;
  helpText?: string;
}

function FieldNumber({attributes}: FieldNumberProps | any) {
  const methods = useFormContext();
  const {register, watch, control, unregister, setValue} = methods;

  useEffect(() => {
    unregister('attributes');
  },[])

  useEffect(() => {
    register('attributes.type');
    setValue('attributes.type', "NUMBER");
    setValue('attributes.negative', attributes.negative ? attributes.negative : false);
    setValue('attributes.numberDefaultValue', attributes.numberDefaultValue ? attributes.numberDefaultValue : null);
    setValue('attributes.placeholder', attributes.placeholder ? attributes.placeholder : "");
    setValue('attributes.helpText', attributes.helpText ? attributes.helpText : "");
  },[attributes])

  return (
    <>
      <div className="relative inline-flex">
        <label className="font-medium text-slate-800 text-sm flex items-center gap-2">
          <Controller
            name="attributes.negative"
            control={control}
            render={({ field: { onChange, value, ref }}) => (
              <div 
                className={`flex items-center  text-sm rounded-md font-normal justify-center cursor-default`} 
                onClick={() => onChange(!value)}
              >
                {value ? <BiToggleLeft className='h-6 w-6 text-green-600'/> : <BiToggleRight className='h-6 w-6 text-slate-400'/>}
              </div>
            )}
          />
          Включить отрицательные числа
        </label>
      </div>
      {/* <div className="relative mb-8">
        <label className="font-medium text-gray-600 text-sm">Значение по умолчанию</label>
        <Controller
          name="attributes.numberDefaultValue"
          control={control}
          render={({ field: { onChange, value }}) => (
            <NumberFormat
              allowNegative = {watch('attributes.negative')}
              value={value}
              onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(parseInt(e.target.value))}
              className="text-sm form-input mt-2 py-2.5 block w-full rounded-md bg-gray-100 border-0 focus:bg-white focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              placeholder="Введите значение по умолчанию для этого поля"
            />
          )}
        />
      </div> */}
      {/* <div className="relative">
        <label className="font-medium text-gray-600 text-sm">Подсказка в поле:</label>
        <input 
          {...register("attributes.placeholder")}
          className="text-sm form-input mt-2 py-2.5 block w-full rounded-md bg-gray-100 border-0 focus:bg-white focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          placeholder="Введите подсказку для этого поля"
        />
      </div>
      <div className="relative mb-8">
        <label className="font-medium text-gray-600 text-sm">Вспомогательный текст к полю:</label>
        <input 
          {...register("attributes.helpText")}
          className="text-sm form-input mt-2 py-2.5 block w-full rounded-md bg-gray-100 border-0 focus:bg-white focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          placeholder="Введите дополнительный текст для этого поля"
        />
      </div> */}
    </>
  );
}
export default FieldNumber;