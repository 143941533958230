import { useEffect, useState } from 'react';
import { AiOutlineSortAscending, AiOutlineSortDescending } from 'react-icons/ai';
import { HiOutlineTrash } from 'react-icons/hi';
import { Field, GroupOrSortDirectionEnum, SortByField } from '../../../core/graphql/types';
import { TbCheck } from 'react-icons/tb';
import SortByFields from './list';


interface SortFieldsProps {
  fields: Field[];
  value: SortByField[] | [];
  onChangeFields: any;
}

function FilterSortBy(props: SortFieldsProps) {
  return (
    <div className='p-4 min-w-max'>
      <div className='flex flex-row leading-8 justify-between min-w-max'>
        <span className='text-base text-slate-600 w-48'>Сортировка</span>
      </div>
      <SortByFields {...props}/>
    </div>
  )
}

export default FilterSortBy;