import { useEffect, useCallback, useState } from 'react';
import { useForm, useFieldArray, FormProvider, useWatch } from 'react-hook-form';
import MappingField from './fields';
import _ from 'lodash';
import { useFetcherContext } from '../../../../../core/providers/fetcher';
import { BiToggleLeft, BiToggleRight } from 'react-icons/bi';
import { BsFillArrowLeftSquareFill, BsFillArrowRightSquareFill } from 'react-icons/bs';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { debounce } from 'debounce';
import { FetcherRequestField } from '../../../../../core/graphql/types';

export default function MappingPanel():JSX.Element {
  const {requestFields, turnOffAllRequestField, turnOnAllRequestField, records, updateRequestField} = useFetcherContext()
  const methods = useForm({defaultValues: {fields: requestFields as FetcherRequestField[]}});
  const {control, setValue, getValues, formState} = methods;
  const [rowNumber, setRowNumber] = useState(0)
  const { fields } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "fields", // unique name for your Field Array
    keyName: "fa_id"
  });
  const [toggle, setToggle] = useState(true)
  const [fieldIndex, setFieldIndex] = useState<number | null>(null)

  useEffect(() => {
    setValue('fields', requestFields as FetcherRequestField[])
  },[requestFields])

  
  function handleToggleSwitch() {
    setToggle(!toggle)
    !!toggle ? turnOffAllRequestField() : turnOnAllRequestField()
  }

  // Обновление

  const debouncedSave = useCallback(
    debounce(() => {
      updateRequestField(getValues(`fields.${fieldIndex!}`))
      setFieldIndex(null)
    }, 1000),
    [fieldIndex]
  );

  const watchedData = useWatch({
    control: control,
    defaultValue: {fields: requestFields as FetcherRequestField[]}
  });

  useDeepCompareEffect(() => {
    if (formState.isDirty) {
      debouncedSave()
    } else {
      debouncedSave.clear()
    }
  }, [watchedData]);

  return (
    <div className='flex flex-col w-full'>
      <div className='flex w-full h-8 items-center border-b px-4 text-sm font-medium'>
        <button className='flex items-center px-1 text-sm font-normal justify-between w-10 cursor-default' onClick={() => handleToggleSwitch()}>
          {toggle ? <BiToggleRight className='h-6 w-6 text-blue-800'/> : <BiToggleLeft className='h-6 w-6 text-slate-400'/>}
        </button>
        <div className='grid grid-cols-6 w-full h-full items-center'>
          <div className='col-span-2 truncate px-2 w-full flex items-center'>Пользователеское название</div>
          <div className='col-span-1 truncate px-2 w-full flex items-center'>Ключ в ответе</div>
          <div className='col-span-1 truncate px-2 w-full flex items-center'>Тип данных</div>
          <div className='col-span-2 truncate px-2 w-full flex items-center space-x-2'>
            <span>Пример данных</span>
            {rowNumber > 0 ? <button onClick={() => (setRowNumber(rowNumber - 1))}><BsFillArrowLeftSquareFill size={16}/></button> : <></>}
            {records.length >= rowNumber ? <button onClick={() => (setRowNumber(rowNumber + 1))}><BsFillArrowRightSquareFill size={16}/></button> : <></>}
          </div>
        </div>
      </div>
      <FormProvider {...methods}>
        {fields.map((column: any, index: number) => {
          return <MappingField key={column.id} index={index} setIndex={setFieldIndex} rowNumber={rowNumber}></MappingField>
        })}
      </FormProvider>
    </div>
  )
}