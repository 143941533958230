import { Board, Card, FieldType, RowHeightType, ValuePayload } from "../../../../core/graphql/types";
import { orderBy } from "lodash";
import { Row } from "../types";

export function makeRows(cards: Card[], values: ValuePayload[], board: Board) {
    const emptyRow: Row = {id: "new", position: cards.length ? cards.length : 0}
  
    if(!cards || !cards.length || !values || !values.length) {
      return [emptyRow]
    }
  
    let original: any = []
    cards
    // .filter((f: any) => currentView.customHiddenCards!.includes(f.id))
    .forEach((card: any) => {
      let card_values = values.filter((value: ValuePayload) => value?.cardId === card?.id);
      let obj = makeRow({ card, values: card_values, boardId: board.id})
      original.push(obj)
    });
  
    return [...orderBy(original, ['position'], ['asc']), emptyRow]
  }

export function makeRow({ card, values, boardId}: { card: any; values: any; boardId: string}) {
  let obj: any = {}
  obj["id"] = card?.id
  obj["position"] = card?.position
  obj["boardId"] = boardId
  values?.forEach((value: any) => {
      let id: string | any = value?.fieldId
      obj[id] = value ? value : '';
  })
  return obj
}

type Comparator = (a: Row, b: Row) => number;
export function getComparator(fieldId: string, type: FieldType): Comparator {
  switch (type) {
    case FieldType.String:
    case FieldType.Email:
    case FieldType.Formula:
    case FieldType.Url:
    case FieldType.Number:
    case FieldType.Phone:
    case FieldType.Duration:
    case FieldType.Currency:
    case FieldType.Decimal:
    case FieldType.Percent:
    case FieldType.Rating:
    case FieldType.Select:
    case FieldType.Multiselect:
    case FieldType.Lookup:
    case FieldType.Relation:
    case FieldType.UpdatedBy:
    case FieldType.CreatedBy:
    case FieldType.Recordid:
      return (a, b) => {
        //@ts-ignore
        return a[fieldId as keyof Row]?.value ? a[fieldId as keyof Row]?.value!.localeCompare(b[fieldId as keyof Row]?.value!) : 0;
      };
    case FieldType.Checkbox:
      return (a, b) => {
        //@ts-ignore
        return a[fieldId as keyof Row]?.["valueAsBool"] === b[fieldId as keyof Row]?.["valueAsBool"] ? 0 : a[fieldId as keyof Row]?.["valueAsBool"] ? 1 : -1;
      };
    case FieldType.Datetime:
    case FieldType.UpdatedAt:
    case FieldType.InsertedAt:
      return (a, b) => {
        //@ts-ignore
        return a[fieldId as keyof Row]?.["valueAsDatetime"] === b[fieldId as keyof Row]?.["valueAsDatetime"] ? 0 : a[fieldId as keyof Row]?.["valueAsDatetime"] ? 1 : -1;
      };
    default:
      throw new Error(`unsupported sortColumn: "${fieldId}"`);
  }
}


export function calculateRowHeight(rowHeight: RowHeightType): number {
  switch (rowHeight) {
    case RowHeightType.Short: return 36
    case RowHeightType.Medium: return 76
    case RowHeightType.Tall: return 136
    case RowHeightType.Extra: return 216
    case RowHeightType.Content: return 25 + Math.round(Math.random() * 75);
    default: return 36
  }
}