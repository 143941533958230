import { useRef, useState } from 'react';
import { MatrPopover } from '../../../ui/portal';
import FilterSortBy from '../../../Filters/sort';
import { Field, SortByField } from '../../../../core/graphql/types';
import { useBoard } from '../../../../core/providers/board';
import { useSpaceStore } from '../../../../core/store/store';

interface SortByDropdownProps {
  viewFields: Field[];
  onChange: any;
}

function SortByDropdown({viewFields, onChange}: SortByDropdownProps): JSX.Element {
  const {initId, initType} = useBoard()
  const init: string = `${initType}:${initId}`
  const store = useSpaceStore()
  const {userCurrentView} = store.data[init];

  const buttonRef = useRef<any>(null);
  const [ open, setOpen ] = useState(false);

  function closePopover() { 
    setOpen(false)
  };

  return (
    <>
      <button 
        ref={buttonRef}
        className={`
        ${open ? 'bg-indigo-600 text-white hover:bg-indigo-700 hover:text-white ring-2 ring-indigo-300' : 'bg-slate-200 text-slate-700 hover:bg-slate-300 hover:text-slate-800'}
        ${userCurrentView?.sorts?.length ? "bg-indigo-200 text-indigo-700" : ""}
        h-7 text-sm font-medium flex items-center px-2 space-x-2 rounded-sm`}
        onClick={() => setOpen(true)}
        >
        Сортировка
        {userCurrentView?.sorts?.length ? <span className='flex h-4 w-4 rounded-sm bg-indigo-600 text-xxs ml-2 items-center justify-center text-white'>{userCurrentView.sorts.length}</span> : <></>}
      </button>
      {open && <MatrPopover onClose={closePopover} reference={buttonRef.current} placement="bottom-start">
        <div className='bg-white h-fit max-h-[32rem] min-w-[24rem] rounded-md border shadow-lg mt-2'>
          <FilterSortBy fields={viewFields} value={userCurrentView?.sorts! && userCurrentView?.sorts?.length > 0 ? userCurrentView?.sorts as SortByField[] : []} onChangeFields={onChange}/>
        </div>
      </MatrPopover>}
    </>
  )
}

export default SortByDropdown;