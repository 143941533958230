import { useFetcherContext } from '../../../core/providers/fetcher';
import ResponseTabs from './tabs';

export default function Response() {
  const {response} = useFetcherContext()

  return (
    response ? 
      <div className="flex-none h-1/2 w-full overflow-hidden">
        <div className='flex flex-col h-full'>
          <div className='grow w-full h-full bg-white overflow-hidden'>
            <ResponseTabs />
          </div>
          <div className='flex-none h-8 w-full bg-slate-100 border-t text-xs px-4 py-1 items-center'>dddd</div>
        </div>
      </div>
      :
      <div className='flex w-full h-1/2 items-center justify-center text-sm text-slate-600'>Нет результата выполнения запросa</div>
  )
}