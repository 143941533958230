import { useEffect, useState } from "react";
import { CollaboratorIcon } from "../../icons";
import Select from 'react-select';
import { ArrayInput, useBoardUsersQuery, CollaboratorValue } from "../../../core/graphql/types";
import type { FieldPrimitiveEditorProps } from './types';
import { JsonArrayValue, MapInput} from '../../../core/graphql/types';
import { Maybe } from "graphql/jsutils/Maybe";
import MatrSelect from "../../ui/select/base";


export default function FieldCollaborator({field, card, updatePrimitive}: FieldPrimitiveEditorProps) {
  const [options, setOptions] = useState<any[]>([]);
  const [selection, setSelection] = useState<Maybe<CollaboratorValue>[]>((card[field.id] as unknown as JsonArrayValue)?.valueAsJsonArray! ? (card[field.id] as unknown as JsonArrayValue)?.valueAsJsonArray! as CollaboratorValue[] : []);
  const [{fetching, error, data }] = useBoardUsersQuery({variables: {id: field.boardId}})

  function handleOnClick(item: any) {
    if(field.attributes.multiple) {
      if (!selection.some(current => current?.id === item.id)) {
        setSelection([...selection, ...item]);
        handleChange([...selection, ...item]);
      } else {
        let selectionAfterRemoval = selection;
        selectionAfterRemoval = selectionAfterRemoval.filter(
          current => current?.id !== item.id
        );
        setSelection([...selectionAfterRemoval]);
        handleChange([...selectionAfterRemoval]);
      }
    } else {
      if (!selection.some(current => current?.id === item.id)) {
        setSelection([item]);
        handleChange([item]);
      } else {
        setSelection([]);
        handleChange(null);
      }
    }
  }

  function handleChange(item: any) {
    updatePrimitive(
      { ...card, [field.id]: {...card[field.id], valueAsJsonArray: item as ArrayInput[]}}, 
      ({...card[field.id], valueAsJsonArray: item as ArrayInput[]} as unknown as JsonArrayValue), 
      {valueAsJsonArray: item as ArrayInput[]}
    )
  }

  useEffect(() => {
    if(!data) {
      return
    }
    const users: any[] = []
    data?.boardUsers?.map((u: any) => {
      users.push({name: `${u?.firstName} ${u.lastName}`, id: u.id})
    })
    setOptions(users)

  },[data])


  return (
    <div className="relative flex flex-col space-y-1.5">
      <div className="flex items-center h-full">
        <div className="text-slate-400 pr-2"><CollaboratorIcon /></div>
        <label className="text-slate-500 text-sm">{field.title}</label>
      </div>
      <MatrSelect
        isMulti={field.attributes.multiple}
        placeholder="Выберите..."
        isClearable={!field.attributes.multiple}
        classNamePrefix="matr_select_form"
        isSearchable={false}
        value={selection}
        getOptionLabel ={(option)=>option?.name!}
        getOptionValue ={(option)=>option?.id!}
        onChange={(e) => handleOnClick(e)}
        options={options}
      />
    </div>

  );
}