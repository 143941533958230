import { useCallback, useEffect, useMemo, useState } from 'react';
import { Calendar, luxonLocalizer, Views } from 'react-big-calendar';
import { useSpace } from "../../../core/providers/space";
// import events from './devdata/events'
import { DateTime, Settings } from 'luxon';
import { transformValueToString } from "../../../core/Controllers/Base";
import { BoardView, CardWithValues, DatetimeValue, ValuePayload } from "../../../core/graphql/types";
import { useBoard } from "../../../core/providers/board";
import { useSpaceStore } from "../../../core/store/store";
import CardModal from "../../Cards";
import MonthDateHeader from './components/MonthDateHeader';
import CustomToolbar from './components/Tooolbar';


function CalendarView() { 
  const {initId, initType, inBlock, cards, values, currentView, userCurrentView, cardModalIsOpened, modal, editMode, setEditMode, setCurrentCardId, setCurrentCardPrimaryKey, setCurrentCard} = useBoard()
  const init: string = `${initType}:${initId}`
  const store = useSpaceStore()
  const {setCardModalIsOpened, setModal} = store.boardActions;

  let {timezone} = useSpace();
  const [events, setEvents] =useState<any[]>([])

  useEffect(() => {
    if(editMode) { return }
    cardsToEvents(values, (currentView as BoardView)!)
  },[cards, values, currentView, userCurrentView, editMode])


  function cardsToEvents(values: ValuePayload[], view: BoardView) {
    let events: any[] = []
    cards.map(card => {
      if(!(values.find(v => v.cardId === card.id && v.fieldId === view.options?.stackedFieldId) as unknown as DatetimeValue)?.valueAsDatetime) {
        return events
      } else {
        events.push({
          id: card.id,
          title: getEventTitle(values.filter(v => v.cardId === card.id), view?.options?.titleFieldId!, view.options?.stackedFieldId!),
          card: card,
          allDay: getEventEnd(values.filter(v => v.cardId === card.id), view?.options?.endEventFieldId!, view.options?.stackedFieldId!) ? false : true,
          start: getEventStart(values.filter(v => v.cardId === card.id), view?.options?.startEventFieldId!, view.options?.stackedFieldId!),
          end: getEventEnd(values.filter(v => v.cardId === card.id), view?.options?.endEventFieldId!, view.options?.stackedFieldId!),
        })
      }
    })
    setEvents(events)
  }


  function getEventStart(card_values: ValuePayload[], startFieldId: string | null, stackedFieldId: string) {
    if(!startFieldId || !(card_values.find(v => v.fieldId === stackedFieldId) as unknown as DatetimeValue)?.valueAsDatetime) {
      return new Date((card_values.find(v => v.fieldId === stackedFieldId) as unknown as DatetimeValue)?.valueAsDatetime!)
    } else {
      return new Date((card_values.find(v => v.fieldId === startFieldId) as unknown as DatetimeValue)?.valueAsDatetime!)
    }
  }

  function getEventEnd(card_values: ValuePayload[], endFieldId: string | null, stackedFieldId: string) {
    if(!endFieldId || !(card_values.find(v => v.fieldId === stackedFieldId) as unknown as DatetimeValue)?.valueAsDatetime) {
      return new Date((card_values.find(v => v.fieldId === stackedFieldId) as unknown as DatetimeValue)?.valueAsDatetime!)
    } else {
      return new Date((card_values.find(v => v.fieldId === endFieldId) as unknown as DatetimeValue)?.valueAsDatetime!)
    }
  }

  function getEventTitle(card_values: ValuePayload[], titleFieldId: string | null, stackedFieldId: string) {
    if(!titleFieldId) {
      return (card_values.find(v => v.fieldId === stackedFieldId) as unknown as DatetimeValue)?.valueAsDatetime!
    } else {
      return transformValueToString((card_values.find(v => v.fieldId === titleFieldId) as unknown as ValuePayload))
    }
  }

  function close() {
    setCardModalIsOpened(false, init)
  }

  const handleSelectEvent = useCallback(
    (event: any) => {
      if(inBlock) {
        setCurrentCardId(event.id!, init)
        setCurrentCardPrimaryKey(values.find(v => v.cardId === event?.id! && v.primary === true)?.id!, init)
        setCurrentCard({...(cards.find(c => c.id === event?.id!) as CardWithValues), values: values.filter(v => v.cardId === event?.id!)}, init)
        setCardModalIsOpened(true, init)
      } else {
        setCardModalIsOpened(true, init)
        setModal(<CardModal close={close} open={true} cardId={event?.card?.id} boardId={event.card.boardId} initiator="ROW"/>, init)
      }
    },
    []
  )

  const {components, defaultDate, getNow, localizer, scrollToTime } =
    useMemo(() => {
      Settings.defaultZone = timezone
      return {
        components: {
          toolbar: CustomToolbar,
          month: {
            dateHeader: MonthDateHeader,
            // event: MyMonthEvent,
          }
        },
        defaultDate: DateTime.local().toJSDate(),
        getNow: () => DateTime.local().toJSDate(),
        localizer: luxonLocalizer(DateTime, {firstDayOfWeek: 1}),
        scrollToTime: DateTime.local().toJSDate(),
      }
    }, [timezone, editMode])


  return (
    <>
      <div className="h-full overflow-hidden">
        <Calendar
          defaultDate={defaultDate}
          defaultView={Views.MONTH}
          events={events}
          getNow={getNow}
          popup={true}
          localizer={localizer}
          scrollToTime={scrollToTime}
          onSelectEvent={handleSelectEvent}
          // @ts-ignore
          components={components}
        />
      </div>
      { cardModalIsOpened && modal }
    </>
  )
}

export default CalendarView;