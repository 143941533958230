import { useEffect, useState } from 'react';
import FieldsForCardList from './list';
import { Field } from '../../../core/graphql/types';

interface FieldsForCardProps {
  fields: Field[];
  hiddenFields: string[] | [];
  onChangeFields: (state: string[] | []) => void;
}

function FieldsForCard({fields, hiddenFields, onChangeFields}: FieldsForCardProps) {
  console.log(fields)
  const handleChange = (id: string) => {
    let ns: string[] | [] = []
    if(hiddenFields.some(it => it === id)) {
      ns = hiddenFields.filter(it => it !== id)
    } else {
      ns = [...hiddenFields, id]
    }
    
    onChangeFields(ns)
  }

  const handleHideAll = () => {
    let selFields: any = []
    fields.map((field: any) => {
      selFields.push(field.id)
    })
    onChangeFields(selFields)
  }

  const handleShowAll = () => {
    onChangeFields([])
  }

  return (
    <FieldsForCardList fields={fields} selection={hiddenFields} handleChange={handleChange} handleShowAll={handleShowAll} handleHideAll={handleHideAll}/> 
  )
}

export default FieldsForCard;