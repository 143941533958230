import { useEffect, useState } from "react";
import { Column } from "react-data-grid";
import { Row } from "../types";
import type { RenderCellProps, RenderEditCellProps } from 'react-data-grid';
import { Field,  Number, NumberValue, Duration as DurationType } from '../../../../core/graphql/types';
import SummaryRows from "../rows/summary";
import { GroupCell } from "../rows/group";
import { HeaderRenderer } from "../rows/header";
import NumberFormat, { NumberFormatValues } from "react-number-format";
import { useBoard } from "../../../../core/providers/board";
import { Duration, DurationLike } from 'luxon'
import { useSpaceStore } from "../../../../core/store/store";

interface BaseRenderCellProps<TRow, TSummaryRow> extends RenderCellProps<TRow, TSummaryRow> {
  field: Field;
}

interface BaseRenderEditCellProps<TRow, TSummaryRow> extends RenderEditCellProps<TRow, TSummaryRow> {
  field: Field;
}

// Column

export function durationColumn({ field, canEditInline }: { field: Field; canEditInline: boolean }): Column<Row, any> {
  return {
    key: field.id,
    name: field.title,
    width: field.width,
    resizable: true,
    frozen: field.primary ? true : false,
    renderHeaderCell: (p) => { return <HeaderRenderer field={field} {...p}/> },
    renderCell: (p) => { return <DurationCell {...p} field={field}/> },
    renderEditCell: (p) => { return canEditInline ? <DurationCellEditor {...p} field={field}/>  : <></> },
    renderGroupCell: (p) => { return <GroupCell {...p} field={field}/> },
    renderSummaryCell: (p) => { return <SummaryRows {...p} field={field}/> },
    editorOptions: {
      displayCellContent: !canEditInline
    }
  }
};

// Cell

export function DurationCell<Row, SummaryRow>({field, row, column}: BaseRenderCellProps<Row, SummaryRow>) {
    const value = (row[column.key as keyof Row] as unknown as NumberValue)?.valueAsNumber!
    const attr = (field.attributes as DurationType);

    function getDurationValue(value: any, attr: DurationType) {
        let milliseconds = getDurationMills(value, attr.durationInputDiscret)
        let format: string = convertFormat(attr.durationTimeFormat)!
        if(milliseconds) {
          return (Duration.fromMillis(milliseconds!)).toFormat(format).toString()
        } else {
          return ""
        }
      }
    
    function convertFormat(format: string) {
      switch (format) {
        case 'HH_MM':
          return 'hh:mm'
        case 'HH_MM_SS':
          return 'hh:mm:ss'
        case 'HH_MM_SS_SSS':
          return 'hh:mm:ss.SSS'
      }
    }
    
    function getDurationMills(value: number, discret: string) : number | undefined {
      switch (discret) {
        case 'MINUTES':
          return value * 60 * 1000
        case 'SECONDS':
          return value * 1000
        case 'MILLISECONDS':
          return value 
      }
    }

    return (
        <div className="flex items-start h-full text-sm justify-end px-2 py-2">{value ? getDurationValue(value, attr) : ""}</div>
    );
}

// CellEditor

export function DurationCellEditor<Row, SummaryRow>({field, row, column, onRowChange, onClose}: BaseRenderEditCellProps<Row, SummaryRow>) {  
    const [value, setValue] = useState((row[column.key as keyof Row] as unknown as NumberValue)?.valueAsNumber! ? (row[column.key as keyof Row] as unknown as NumberValue)?.valueAsNumber! : "")
    const {initId, initType} = useBoard();
    const init: string = `${initType}:${initId}`;
    const store = useSpaceStore()
    const {setEditMode} = store.boardActions
    const {rowHeight} = store.data[init];
    const height = rowHeight - 20
  
    function handleChange(e: NumberFormatValues) {
      setEditMode(true, init)
      setValue(e.floatValue!)
      onRowChange({ ...row, [column.key]: {...row[column.key as keyof Row], valueAsNumber: e.floatValue}})
      // if(timer) clearTimeout(timer);
      // setTimer(setTimeout(() => setValueForUpdate({...row[column.key as keyof Row], valueAsNumber: e.floatValue}, init), 500))
    }
  
    function handleBlur() {
      setEditMode(false, init)
      onClose(true)
    }
  
    return (
        <NumberFormat
            autoFocus
            allowNegative={false}
            decimalScale={0}
            className={`w-full leading-5 text-sm text-right focus:outline-none p-2`}
            style={{paddingBottom: rowHeight - 28}}
            displayType="input"
            value={value}
            onFocus={()=>setEditMode(true, init)}
            onValueChange={(e) => handleChange(e)}
            onBlur={() => handleBlur()}
        />
    );
}

