import { Board, BoardView, Field, FieldType, FieldSummaryType, Card, ValuePayload, Account } from "../../graphql/types";

import { columnGenerator } from "../../../components/Views/MattrGrid/columns";
import { createColumn } from "../../../components/Views/MattrGrid/columns/create";
import { transformValueToString } from "../Base";
import { orderBy } from "lodash";
import { SelectColumn } from "react-data-grid";
import { Row } from "../../../components/Views/MattrGrid/types";

export function makeRow({ card, values, boardId}: { card: any; values: any; boardId: string}) {
  let obj: any = {}
  obj["id"] = card?.id
  obj["position"] = card?.position
  obj["boardId"] = boardId
  values?.forEach((value: any) => {
    let id: string | any = value?.fieldId
    obj[id] = value ? value : '';
  })
  return obj
}

export function makeColumns(fields: Field[], currentView: BoardView, currentUser: Account, board: Board) {
  const newField = {
    id: '',
    title: '',
    key: 'new',
    boardId: board.id,
    type: FieldType.String,
    position: 0,
    width: 200,
    primary: false,
    preview: false,
    hidden: false,
    summaryType: FieldSummaryType.Total,
    attributes: {type: FieldType.String}
  };

  const fieldsToColumns = []
  fieldsToColumns.push(SelectColumn)


  fields
  .filter((field: Field) => !currentView?.hiddenFields!.includes(field?.id!))
  // .filter((field: Field) => !currentView?.customHiddenFields!.includes(field?.id!))
  .map((field: Field) => {return fieldsToColumns.push(columnGenerator(field as Field, currentView.permissions?.canEditInline!))});

  if(currentUser?.role?.name === "Administrator") {
    fieldsToColumns.push(createColumn());
  }
  return fieldsToColumns
}

export function makeColumnsForBlock(fields: Field[], currentView: BoardView) {

  const fieldsToColumns = []
  fieldsToColumns.push(SelectColumn)


  fields
  .filter((field: Field) => !currentView?.hiddenFields!.includes(field?.id!))
  // .filter((field: Field) => !currentView?.customHiddenFields!.includes(field?.id!))
  .map((field: Field) => {return fieldsToColumns.push(columnGenerator(field as Field, currentView.permissions?.canEditInline!))});

  return fieldsToColumns
}


export function makeRows(cards: Card[], values: ValuePayload[], currentView: BoardView, board: Board) {
  const emptyRow: Row = {id: "new", position: cards.length ? cards.length : 0}

  if(!cards || !cards.length || !values || !values.length) {
    return [emptyRow]
  }

  let original: any = []
  cards
  // .filter((f: any) => currentView.customHiddenCards!.includes(f.id))
  .forEach((card: any) => {
    let card_values = values.filter((value: ValuePayload) => value?.cardId === card?.id);
    let obj = makeRow({ card, values: card_values, boardId: board.id})
    original.push(obj)
  });

  return [...orderBy(original, ['position'], ['asc']), emptyRow]
}

export function makeRowsInBlock(cards: Card[], values: ValuePayload[], canAdd: boolean, board: Board) {
  const emptyRow: Row = {id: "new", position: cards.length ? cards.length : 0}

  if(!cards || !cards.length || !values || !values.length) {
    return [emptyRow]
  }

  let original: any = []
  cards
  .forEach((card: any) => {
    let card_values = values.filter((value: ValuePayload) => value?.cardId === card?.id);
    let obj = makeRow({ card, values: card_values, boardId: board.id})
    original.push(obj)
  });
  return canAdd ? [...orderBy(original, ['position'], ['asc']), emptyRow] : orderBy(original, ['position'], ['asc'])
}


type Comparator = (a: Row, b: Row) => number;
export function getComparator(fieldId: string, type: FieldType): Comparator {
  switch (type) {
    case FieldType.String:
    case FieldType.Email:
    case FieldType.Formula:
    case FieldType.Url:
    case FieldType.Number:
    case FieldType.Phone:
    case FieldType.Duration:
    case FieldType.Currency:
    case FieldType.Decimal:
    case FieldType.Percent:
    case FieldType.Rating:
    case FieldType.Select:
    case FieldType.Multiselect:
    case FieldType.Lookup:
    case FieldType.Relation:
    case FieldType.UpdatedBy:
    case FieldType.CreatedBy:
    case FieldType.Recordid:
      return (a, b) => {
        //@ts-ignore
        return a[fieldId as keyof Row]?.value ? a[fieldId as keyof Row]?.value!.localeCompare(b[fieldId as keyof Row]?.value!) : 0;
      };
    case FieldType.Checkbox:
      return (a, b) => {
        //@ts-ignore
        return a[fieldId as keyof Row]?.["valueAsBool"] === b[fieldId as keyof Row]?.["valueAsBool"] ? 0 : a[fieldId as keyof Row]?.["valueAsBool"] ? 1 : -1;
      };
    case FieldType.Datetime:
    case FieldType.UpdatedAt:
    case FieldType.InsertedAt:
      return (a, b) => {
        //@ts-ignore
        return a[fieldId as keyof Row]?.["valueAsDatetime"] === b[fieldId as keyof Row]?.["valueAsDatetime"] ? 0 : a[fieldId as keyof Row]?.["valueAsDatetime"] ? 1 : -1;
      };
    default:
      throw new Error(`unsupported sortColumn: "${fieldId}"`);
  }
}