import { Field, FieldType, MattrTypes } from "../../../../core/graphql/types";
import * as Icons from "../../../icons";

interface LabelShowProps {
    type: FieldType | MattrTypes,
    field: Field;
  }

export default function LabelShow({type, field}:LabelShowProps) {
  switch (type) {
    case MattrTypes.String:
      return (
        <div className="flex space-x-2 items-center text-xs text-slate-500 uppercase">
          <Icons.StringIcon />
          <div>{field.title}</div>
        </div>
      ) 
    case MattrTypes.Url:
      return (
        <div className="flex space-x-2 items-center text-xs text-slate-500 uppercase">
          <Icons.UrlIcon />
          <div>{field.title}</div>
        </div>
      ) 
    case MattrTypes.Email:
      return (
        <div className="flex space-x-2 items-center text-xs text-slate-500 uppercase">
          <Icons.EmailIcon />
          <div>{field.title}</div>
        </div>
      ) 
    case MattrTypes.Number:
      return (
        <div className="flex space-x-2 items-center text-xs text-slate-500 uppercase">
          <Icons.NumberIcon />
          <div>{field.title}</div>
        </div>
      ) 
    case MattrTypes.Phone:
      return (
        <div className="flex space-x-2 items-center text-xs text-slate-500 uppercase">
          <Icons.PhoneIcon />
          <div>{field.title}</div>
        </div>
      ) 
    case MattrTypes.Duration:
      return (
        <div className="flex space-x-2 items-center text-xs text-slate-500 uppercase">
          <Icons.DurationIcon />
          <div>{field.title}</div>
        </div>
      ) 
    case MattrTypes.Decimal:
      return (
        <div className="flex space-x-2 items-center text-xs text-slate-500 uppercase">
          <Icons.DecimalIcon />
          <div>{field.title}</div>
        </div>
      ) 
    case MattrTypes.Percent:
      return (
        <div className="flex space-x-2 items-center text-xs text-slate-500 uppercase">
          <Icons.PercentIcon />
          <div>{field.title}</div>
        </div>
      ) 
    case MattrTypes.Currency:
      return (
        <div className="flex space-x-2 items-center text-xs text-slate-500 uppercase">
          <Icons.CurrencyIcon />
          <div>{field.title}</div>
        </div>
      ) 
    case MattrTypes.Checkbox:
      return (
        <div className="flex space-x-2 items-center text-xs text-slate-500 uppercase">
          <Icons.CheckboxIcon />
          <div>{field.title}</div>
        </div>
      ) 
    case MattrTypes.Recordid: 
      return (
        <div className="flex space-x-2 items-center text-xs text-slate-500 uppercase">
          <Icons.RecordidIcon />
          <div>{field.title}</div>
        </div>
      ) 
    case MattrTypes.Formula: 
      return (
        <div className="flex space-x-2 items-center text-xs text-slate-500 uppercase">
          <Icons.FormulaIcon />
          <div>{field.title}</div>
        </div>
      ) 
    case MattrTypes.Select:
      return (
        <div className="flex space-x-2 items-center text-xs text-slate-500 uppercase">
          <Icons.SelectIcon />
          <div>{field.title}</div>
        </div>
      ) 
    case MattrTypes.UpdatedBy:
      return (
        <div className="flex space-x-2 items-center text-xs text-slate-500 uppercase">
          <Icons.UpdatedByIcon />
          <div>{field.title}</div>
        </div>
      ) 
    case MattrTypes.CreatedBy:
      return (
        <div className="flex space-x-2 items-center text-xs text-slate-500 uppercase">
          <Icons.CreatedByIcon />
          <div>{field.title}</div>
        </div>
      ) 
    case MattrTypes.Collaborator:
      return (
        <div className="flex space-x-2 items-center text-xs text-slate-500 uppercase">
          <Icons.CollaboratorIcon />
          <div>{field.title}</div>
        </div>
      ) 
    case MattrTypes.Multiselect: 
      return (
        <div className="flex space-x-2 items-center text-xs text-slate-500 uppercase">
          <Icons.MultiSelectIcon />
          <div>{field.title}</div>
        </div>
      ) 
    case MattrTypes.Datetime:
      return (
        <div className="flex space-x-2 items-center text-xs text-slate-500 uppercase">
          <Icons.DateTimeIcon />
          <div>{field.title}</div>
        </div>
      ) 
    case MattrTypes.InsertedAt:
      return (
        <div className="flex space-x-2 items-center text-xs text-slate-500 uppercase">
          <Icons.InsertedAtIcon />
          <div>{field.title}</div>
        </div>
      ) 
    case MattrTypes.UpdatedAt: 
      return (
        <div className="flex space-x-2 items-center text-xs text-slate-500 uppercase">
          <Icons.UpdatedAtIcon />
          <div>{field.title}</div>
        </div>
      ) 
    case MattrTypes.Lookup: 
      return (
        <div className="flex space-x-2 items-center text-xs text-slate-500 uppercase">
          <Icons.LookupIcon />
          <div>{field.title}</div>
        </div>
      ) 
    case MattrTypes.Relation: 
      return (
        <div className="flex space-x-2 items-center text-xs text-slate-500 uppercase">
          <Icons.RelationIcon />
          <div>{field.title}</div>
        </div>
      )
    case MattrTypes.Textarea: 
      return (
        <div className="flex space-x-2 items-center text-xs text-slate-500 uppercase">
          <Icons.TextareaIcon />
          <div>{field.title}</div>
        </div>
      ) 
    default: 
      return (
        <div className="flex space-x-2 items-center text-xs text-slate-500 uppercase">
          <Icons.ErrorIcon />
          <div>{field.type}</div>
        </div>
      ) 
  }
}