import { useCallback, useEffect, useState } from "react";
import { array_move, uuidv4 } from "../../../utils";
import { operators } from "../../../core/enums";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import FilterItem from "./item";
import { SmallSpinner } from "../../loaders";
import { Field, FieldType, Formula } from "../../../core/graphql/types";
import { useForm, useFieldArray, FormProvider } from "react-hook-form";

interface FiltersListProps {
  currentFilters: any;
  boardFields: any;
  users: any;
  saveFilters: (rest: any) => void; 
  links: any[];
}

export default function FiltersListWithLink({currentFilters, boardFields, users, saveFilters, links}: FiltersListProps) {
  const methods = useForm();
  const { control, register, setValue, watch, getValues, handleSubmit, formState } = methods;
  const { fields, append, prepend, remove, replace, swap, move, insert } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "filters", // unique name for your Field Array
  });

  useEffect(() => {
    setValue('filters', currentFilters)
  },[])

  const addFilterHandler = () => {
    const first_field: Field = boardFields?.find((it: any) => it.primary === true)
    append({ 
      id: uuidv4(),
      position: watch('filters').length,
      fieldId: first_field.id,
      fieldType: first_field.type,
      fieldValueType: setFilterFieldValueType(first_field),
      conjunction: "AND",
      operator: setFilterOperator(first_field),
      value: "",
      linkId: null,
    });
    startSaving()
  }

  function setFilterFieldValueType(primaryField: Field) {
    switch (primaryField.type) {
      case FieldType.Formula: return (primaryField.attributes as Formula).expressionType
      case FieldType.Recordid: return FieldType.Number
      default: return primaryField.type
    }
  }

  function setFilterOperator(primaryField: Field) {
    switch (primaryField.type) {
      case FieldType.Formula: return operators.filter((o: any) => o.type === (primaryField.attributes as Formula).expressionType)[0].value
      case FieldType.Recordid: return operators.filter((o: any) => o.type === FieldType.Number)[0].value
      default: return operators.filter((o: any) => o.type === primaryField.type)[0].value
    }
  }

  function onFilterChange() {
    startSaving()
    // replace(items)
    // startSaving()
  }

  function startSaving() {
    let updatedFilters = getValues('filters')
    saveFilters(prepareForSave(updatedFilters))
  }

  function prepareForSave(items: any) {
    const filtersForSave: any = []
    items.map((filter: any, index: number) => {
      let filterForSave = {
        ...filter,
        position: index
      }

      filtersForSave.push(filterForSave)
    })
    return filtersForSave
  }

  const deleteFilterHandler = (index: number) => {
    remove(index)
    startSaving()
  };

  const onFormChange = handleSubmit(data => {
    startSaving()
  })

  return (
    <div className="relative text-left">
      <FormProvider {...methods}>
        <form onChange={onFormChange}>
          <div className="flex relative flex-col space-y-2 filters h-full">
          {!fields?.length ? <></> : fields.map((filter: any, index: number) => (
            <FilterItem key={filter.id} boardFields={boardFields} users={users} index={index} filters={fields} onFilterChange={onFilterChange} deleteFilter={deleteFilterHandler} links={links}/>
          ))}
          </div>
          <button typeof="button" className={`flex flex-row whitespace-nowrap max-w-min text-sm text-indigo-600 rounded-sm font-medium pt-2 pb-6  ${!watch('filters')?.length ? "" : "mt-2"}`} type="button" onClick={addFilterHandler}>Добавить условие</button>
        </form>
      </FormProvider>
    </div>
  );
}