/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState } from "react";
import { FieldValues, FormProvider, useForm } from "react-hook-form";
import toast from 'react-hot-toast';

import { useNavigate } from "react-router-dom";
import { LayoutEnum, Panel, PanelInList, useCreatePageMutation } from "../../../core/graphql/types";
import { useSpace } from "../../../core/providers/space";
import PageFormLayout from "./steps/pageLayout";
import PageFormOptions from "./steps/pageOptions";
import { TbSquaresFilled, TbX } from "react-icons/tb";


export const PageFormCreate = ({panel, close}:{panel?: Panel, close: any}) => {
  const spaceStore = useSpace()
  const navigate = useNavigate()
  const methods = useForm({defaultValues: {panelId: panel ? panel.id : spaceStore.panels[0].id, pageName: "", pageLayout: LayoutEnum.Blank, pageOptions: {}}});
  const { handleSubmit, formState: { errors } } = methods;
  const [, createPage] = useCreatePageMutation();
  const [step, setStep] = useState("pageLayout")

  const onSubmit = handleSubmit(data => {
    if(step === "pageLayout") {
      setStep("pageOptions")
      return
    }

    if(step === "pageOptions") {
      savePage(data)
    }
  });

  function savePage(data: FieldValues) {
    toast.promise(
      createPage({panelId: data.panelId, pageLayout: data.pageLayout, pageName: data.pageName, pageOptions: data.pageOptions}).then(result => {
        if(result.error) {
          return result
        }
        let a_panel = spaceStore.panels.find(p => p.id == data.panelId)
        let panelPages = a_panel?.pages
        let newPanel = {...a_panel, pages: [...panelPages!, result.data?.createPage?.page]} as PanelInList  
        spaceStore.actionPanelsUpdate(newPanel)
        return result
      }),
      {
        loading: 'Создаем новую страницу...',
        success: (data) => {
          if (data.error) throw new Error();
          close();
          navigate(data.data?.createPage?.page?.id!)
          return 'Страница создана';
        },
        error: 'Ошибка. Не удалось создать страницу',
      }
    );
  }


  return (
    <>
      <div className="flex items-center justify-between">
        <div className="flex items-center space-x-2">
          <div className="py-1 px-2 bg-slate-100 flex space-x-2 rounded">
            <TbSquaresFilled size={20} className="text-indigo-600"/>
            <div className="flex font-medium text-sm">{spaceStore.space?.name}</div>
          </div>
          <div className="flex font-normal text-sm">Создание новой страницы</div>
        </div>
        <button onClick={() => close()}><TbX size={18}/></button>
      </div>
      <FormProvider {...methods}>
        <form onSubmit={onSubmit} className="flex flex-col relative w-full space-y-4">
          {step === "pageLayout" && <PageFormLayout newPanel={false}/>}
          {step === "pageOptions" && <PageFormOptions />}
          <div className="flex w-full h-full justify-end pt-4 space-x-4">
            <button
              type="button"
              className="rounded text-sm py-2 px-4 bg-slate-200 text-slate-400 hover:bg-slate-300 hover:text-slate-500"
              onClick={close}
            >Отменить</button>
            <button
              type="submit"
              className="rounded text-sm py-2 px-4 bg-indigo-600 text-white hover:bg-indigo-700"
            >Создать</button>
          </div>
        </form>
      </FormProvider>
    </>

  )
};