import { TbDots, TbEditCircle, TbTrash } from "react-icons/tb";
import { usePanel } from "../../../../../core/providers/panel";
import { MatrBaseDialog, MatrMediumDialog, MatrPopover } from "../../../../../components/ui/portal";
import { ActionMenu } from "../../../../../components/Menus/ActionMenu";
import { useState } from "react";
import BlockSettings from "../Forms/settings";
import { BlockEdit } from "../Forms/editBlock";
import { Block } from "../../../../../core/graphql/types";

export const BlockBaseControls = ({block}:{block: Block}) => {
  const { removeBlock } = usePanel()
  const [ openMenu, setOpenMenu ] = useState(false);
  const [ openBlockSettings, setOpenBlockSettings ] = useState(false);
  const [ buttonRef, setButtonRef ] = useState<any>()

  function onOpenMenu(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) { setOpenMenu(true)}
  function closeMenu() { setOpenMenu(false) };

  function openBlockSettingsModal() {setOpenBlockSettings(true)}
  function closeBlockSettingsModal() {setOpenBlockSettings(false)}

  function handleChangeRemoveBlock() {
    removeBlock(block.id)
  }

  const blockMenu = [
    {
      name: "Настройки",
      action: openBlockSettingsModal,
      icon: <TbEditCircle />,
      disabled: false
    },
    {
      name: "Удалить блок",
      action: handleChangeRemoveBlock,
      icon: <TbTrash />,
      disabled: false
    }
  ]

  return (
    <>
      <button 
        type="button"
        className="absolute right-2 -top-2 bg-blue-600 rounded-sm text-white flex z-[21] px-2 items-center justify-center" 
        ref={setButtonRef}
        onClick={(e) => onOpenMenu(e)}
      >
        <TbDots size={20}/>
      </button>

      {openMenu && 
        <MatrPopover onClose={closeMenu} reference={buttonRef} placement="bottom-end">
          <ActionMenu menu = {blockMenu}/>
        </MatrPopover>
      }

      {openBlockSettings &&
        <MatrMediumDialog open = {openBlockSettings} onClose = {closeBlockSettingsModal}>
          <BlockEdit block={block} onClose={closeBlockSettingsModal}/>
        </MatrMediumDialog>
      }
    </>
  )
}



export const BlockRelationFieldControls = ({block}:{block: Block}) => {
  const { removeBlock, switchTab } = usePanel()
  const [ openMenu, setOpenMenu ] = useState(false);
  const [ openBlockSettings, setOpenBlockSettings ] = useState(false);
  const [ buttonRef, setButtonRef ] = useState<any>()

  function onOpenMenu(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) { setOpenMenu(true)}
  function closeMenu() { setOpenMenu(false) };

  function openBlockSettingsModal() {setOpenBlockSettings(true)}
  function closeBlockSettingsModal() {setOpenBlockSettings(false)}

  function handleChangeRemoveBlock() {
    removeBlock(block.id)
  }

  function handleChangeBlockAsTab() {
    switchTab(block)
  }

  const blockMenu = [
    {
      name: "Настройки",
      action: openBlockSettingsModal,
      icon: <TbEditCircle />,
      disabled: false
    },
    {
      name: "Отдельной вкладкой",
      action: handleChangeBlockAsTab,
      icon: <TbEditCircle />,
      disabled: false
    },
    {
      name: "Удалить блок",
      action: handleChangeRemoveBlock,
      icon: <TbTrash />,
      disabled: false
    }
  ]

  return (
    <>
      <button 
        type="button"
        className="absolute right-0 -top-2 bg-blue-600 rounded-sm text-white flex z-[21] px-2 items-center justify-center" 
        ref={setButtonRef}
        onClick={(e) => onOpenMenu(e)}
      >
        <TbDots size={20}/>
      </button>

      {openMenu && 
        <MatrPopover onClose={closeMenu} reference={buttonRef} placement="bottom-end">
          <ActionMenu menu = {blockMenu}/>
        </MatrPopover>
      }

      {openBlockSettings &&
        <MatrMediumDialog open = {openBlockSettings} onClose = {closeBlockSettingsModal}>
          <BlockEdit block={block} onClose={closeBlockSettingsModal}/>
        </MatrMediumDialog>
      }
    </>
  )
}