import { useFormContext, Controller } from "react-hook-form";
import MatrSelect from "../../../../components/ui/select/base";
import { SingleValue } from "react-select";
import { pageLayouts } from "../../../../core/enums";
import { useSpace } from "../../../../core/providers/space";

const PageFormLayout = ({newPanel}:{newPanel: boolean}) => {
  const {panels} = useSpace()
  const {register, control, formState: {errors}} = useFormContext()

  function handleChangePanel(e: SingleValue<{ name: string; id: string; }>, onChange: (...event: any[]) => void) {
    onChange(e?.id)
  }

  function handleChangeLayout(e: SingleValue<{ label: string; value: string; }>, onChange: (...event: any[]) => void) {
    onChange(e?.value)
  }

  return (
    <>
      <div className="relative flex flex-col space-y-1.5 pt-4">
        <input 
          {...register("pageName", {required: true})} 
          className="text-xl font-medium text-slate-800 placeholder:text-slate-300 focus:outline-none mb-4" 
          placeholder="Название страницы"
        />
        {errors.pageName && <span className="text-red-600 text-xs font-normal">"Название страницы" обязательное поле.</span>}
      </div>
      {newPanel ? <></> : <div className="relative">
        <label className="font-medium text-gray-600 text-sm">Раздел:</label>
        <Controller
          name="panelId"
          control={control}
          rules={{required: true}}
          render={({ field: { onChange, value }}) => (
            <div className="mt-1.5">
              <MatrSelect
                value={panels.find(p => p.id === value)}
                onChange={(e) => handleChangePanel(e, onChange)}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                options={panels}
              />
            </div>
          )}
        />
        {errors.pageLayout && <span className="text-red-600 text-xs font-normal">"Макет" обязательное поле.</span>}
      </div>}
      <div>
        <div className="relative">
          <label className="font-medium text-gray-600 text-sm">Макет:</label>
          <Controller
            name="pageLayout"
            control={control}
            rules={{required: true}}
            render={({ field: { onChange, value }}) => (
              <div className="mt-1.5">
                <MatrSelect
                  value={pageLayouts.find(r => r.value === value)}
                  onChange={(e) => handleChangeLayout(e, onChange)}
                  options={pageLayouts}
                />
              </div>
            )}
          />
          {errors.pageLayout && <span className="text-red-600 text-xs font-normal">"Макет" обязательное поле.</span>}
        </div>
      </div>
    </>
  )

}

export default PageFormLayout;