import { ChangeEvent, Fragment } from "react";
import { useFormContext, Controller } from "react-hook-form";
import { Listbox, Transition } from "@headlessui/react";
import { HiSelector } from 'react-icons/hi'
import { requestMethods } from '../../../../core/enums';
import { FetcherValue } from "../../../../core/graphql/types";

export default function FetcherRequestUrl() {
  const methods = useFormContext();
  const { reset, handleSubmit, control, setValue, getValues, formState } = methods;

  function handleChangeMethod(e: any, onChange: (...event: any[]) => void) {
    onChange(e)
  }

  function handleChangeUrlBlur(e: ChangeEvent<HTMLInputElement>, onChange: (...event: any[]) => void) {
    if(!e.target.value) {
      setValue('parameters', [])
      setValue('pathParameters', [])
      onChange(e.target.value)

      return
    }

    let url = new URL(e.target.value)
    let disabledParams = getValues('parameters').filter((p: FetcherValue) => p.disabled === true)
    let urlPathParameters = url.pathname.split('/').filter(p => p.startsWith(':'))
    let urlParameters = new URLSearchParams(url.search);

    let resultParameters: any = [];
    for (const [key, value] of urlParameters.entries()) {
      if(getValues('parameters').filter((p: FetcherValue) => p.disabled === true).length === 0) {
        resultParameters.push({key: `${key}`, value: `${value}`, disabled: false, dynamic: false, type: "STRING"})
      } else {
        let parameter = getValues('parameters').filter((p: FetcherValue) => p.disabled === true).find((p: any) => p.key === key)
        resultParameters.push(parameter ? {...parameter, value: value} : {key: `${key}`, value: `${value}`, disabled: false, dynamic: false, type: "STRING"})
      }
    }

    let resultPathParameters: any = [];
    urlPathParameters.map((pp: string, index: number) => {
      let key = pp.substring(1)
      if(getValues('pathParameters').length === 0) {
        resultPathParameters.push({key: key, value: "test", disabled: false, dynamic: false, type: "STRING"})
      } else  {
        let parameter = getValues(`pathParameters.[${index}]`) 
        resultPathParameters.push(parameter && parameter.key === key ? parameter : {key: key, value: "test", disabled: false, dynamic: false, type: "STRING"}) 
      }
    })
    
    onChange(`${url.origin}${url.pathname}${urlParameters.toString() ? '?' : ''}${urlParameters.toString()}`)
    setValue('parameters', [...resultParameters, ...disabledParams])
    setValue('pathParameters', resultPathParameters)
  }

  function handleChangeUrl(e: ChangeEvent<HTMLInputElement>, onChange: (...event: any[]) => void) {
    onChange(e.target.value)
  }


  return (
    <div className='flex w-full items-center bg-slate-100 rounded-md'>
      <Controller
        name="method"
        control={control}
        render={({ field: { onChange, value, ref }}) => (
          <Listbox value={value} onChange={(e) => handleChangeMethod(e, onChange)}>
            <div className="relative w-max rounded-md">
              <Listbox.Button className="relative h-8 min-w-max max-w-max w-max cursor-default bg-slate-100 pl-3 text-left focus:outline-none rounded-l-md">
                <span className="block truncate min-w-max w-20 text-sm">{requestMethods.find(rm => rm.id === value)?.name}</span>
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <HiSelector className="h-5 w-5 text-gray-400" aria-hidden="true"/>
                </span>
              </Listbox.Button>
              <Transition
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute z-10 max-h-60 w-full overflow-auto bg-white focus:outline-none text-sm shadow-lg rounded-md ">
                  {requestMethods.map((m) => (
                    <Listbox.Option
                      key={m.id}
                      className={({ active }) =>
                        `relative cursor-default select-none py-2 pl-3 pr-4 hover:bg-indigo-100 ${
                          active ? 'bg-indigo-200 text-indigo-900' : 'text-slate-900'
                        }`
                      }
                      value={m.name}
                    >
                      {({ selected }) => (
                        <span className={`block text-sm truncate ${selected ? 'font-medium' : 'font-normal'}`}>
                          {m.name}
                        </span>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </Listbox>
        )}
      />
      <div className="relative items-center flex h-full w-full bg-slate-100 focus:bg-slate-50 rounded-r-md">
        <Controller
          name="url"
          control={control}
          render={({ field: { onChange, value, ref }}) => (
            <input 
              value={value ? value : ""}
              onChange={(e) => handleChangeUrl(e, onChange)}
              onBlur={(e) => handleChangeUrlBlur(e, onChange)}
              placeholder="https://api.someproduct.com/v1/bigthing"
              className="px-2 py-2 border-0 focus:outline-none w-full bg-slate-100 focus:bg-slate-100 ring-0 rounded-r-md"
            />
          )} 
        />
      </div>
    </div>
  )
}