import { useRef, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { BoardViewPermissions } from '../../../../core/graphql/types';
import { TbCheck } from 'react-icons/tb';
import { BiToggleLeft, BiToggleRight } from 'react-icons/bi';


function ViewPermissions(): JSX.Element {
  const methods = useFormContext();
  const {control} = methods;

  function handleChangeCheckbox(value: any, onChange: { (...event: any[]): void; (arg0: any): void; }) {
    onChange(!value)
  }

  return (
    <>
      <Controller
        name="permissions.canAdd"
        control={control}
        render={({ field: { onChange, value, ref }}) => (
          <div className='flex w-full px-1 py-1 rounded justify-between items-center cursor-pointer text-slate-800'>
            <div className='flex flex-row'>
              <span className='text-base py-0.5 px-1'>Можно добавлять новые записи</span>
            </div>
            <div 
              className={`flex items-center  rounded-md font-normal  cursor-default`} 
              onClick={() => handleChangeCheckbox(value, onChange)}
            >
              {value === false ? <BiToggleLeft className='h-6 w-6 text-slate-400'/> : <BiToggleRight className='h-6 w-6 text-blue-600'/>}
            </div>
          </div>
        )}
      />
      <Controller
        name="permissions.canEdit"
        control={control}
        render={({ field: { onChange, value, ref }}) => (
          <div className='flex w-full px-1 py-1 rounded justify-between items-center cursor-pointer text-slate-800'>
            <div className='flex flex-row'>
              <span className='text-base py-0.5 px-1'>Можно редактировать записи</span>
            </div>
            <div 
              className={`flex items-center  rounded-md font-normal  cursor-default`} 
              onClick={() => handleChangeCheckbox(value, onChange)}
            >
              {value === false ? <BiToggleLeft className='h-6 w-6 text-slate-400'/> : <BiToggleRight className='h-6 w-6 text-blue-600'/>}
            </div>
          </div>
        )}
      />
      <Controller
        name="permissions.canEditInline"
        control={control}
        render={({ field: { onChange, value, ref }}) => (
          <div className='flex w-full px-1 py-1 rounded justify-between items-center cursor-pointer text-slate-800'>
            <div className='flex flex-row'>
              <span className='text-base py-0.5 px-1'>Можно редактировать записи в таблице</span>
            </div>
            <div 
              className={`flex items-center  rounded-md font-normal  cursor-default`} 
              onClick={() => handleChangeCheckbox(value, onChange)}
            >
              {value === false ? <BiToggleLeft className='h-6 w-6 text-slate-400'/> : <BiToggleRight className='h-6 w-6 text-blue-600'/>}
            </div>
          </div>
        )}
      />
      <Controller
        name="permissions.canFilter"
        control={control}
        render={({ field: { onChange, value, ref }}) => (
          <div className='flex w-full px-1 py-1 rounded justify-between items-center cursor-pointer text-slate-800'>
            <div className='flex flex-row'>
              <span className='text-base py-0.5 px-1'>Можно фильтровать записи</span>
            </div>
            <div 
              className={`flex items-center  rounded-md font-normal  cursor-default`} 
              onClick={() => handleChangeCheckbox(value, onChange)}
            >
              {value === false ? <BiToggleLeft className='h-6 w-6 text-slate-400'/> : <BiToggleRight className='h-6 w-6 text-blue-600'/>}
            </div>
          </div>
        )}
      />
      <Controller
        name="permissions.canSort"
        control={control}
        render={({ field: { onChange, value, ref }}) => (
          <div className='flex w-full px-1 py-1 rounded justify-between items-center cursor-pointer text-slate-800'>
            <div className='flex flex-row'>
              <span className='text-base py-0.5 px-1'>Можно сортировать</span>
            </div>
            <div 
              className={`flex items-center  rounded-md font-normal  cursor-default`} 
              onClick={() => handleChangeCheckbox(value, onChange)}
            >
              {value === false ? <BiToggleLeft className='h-6 w-6 text-slate-400'/> : <BiToggleRight className='h-6 w-6 text-blue-600'/>}
            </div>
          </div>
        )}
      />
      <Controller
        name="permissions.canGroup"
        control={control}
        render={({ field: { onChange, value, ref }}) => (
          <div className='flex w-full px-1 py-1 rounded justify-between items-center cursor-pointer text-slate-800'>
            <div className='flex flex-row'>
              <span className='text-base py-0.5 px-1'>Можно группировать</span>
            </div>
            <div 
              className={`flex items-center  rounded-md font-normal  cursor-default`} 
              onClick={() => handleChangeCheckbox(value, onChange)}
            >
              {value === false ? <BiToggleLeft className='h-6 w-6 text-slate-400'/> : <BiToggleRight className='h-6 w-6 text-blue-600'/>}
            </div>
          </div>
        )}
      />
      <Controller
        name="permissions.canAddField"
        control={control}
        render={({ field: { onChange, value, ref }}) => (
          <div className='flex w-full px-1 py-1 rounded justify-between items-center cursor-pointer text-slate-800'>
            <div className='flex flex-row'>
              <span className='text-base py-0.5 px-1'>Можно добавлять новые колонки</span>
            </div>
            <div 
              className={`flex items-center  rounded-md font-normal  cursor-default`} 
              onClick={() => handleChangeCheckbox(value, onChange)}
            >
              {value === false ? <BiToggleLeft className='h-6 w-6 text-slate-400'/> : <BiToggleRight className='h-6 w-6 text-blue-600'/>}
            </div>
          </div>
        )}
      />
      <Controller
        name="permissions.canEditField"
        control={control}
        render={({ field: { onChange, value, ref }}) => (
          <div className='flex w-full px-1 py-1 rounded justify-between items-center cursor-pointer text-slate-800'>
            <div className='flex flex-row'>
              <span className='text-base py-0.5 px-1'>Можно редактировать новые колонки</span>
            </div>
            <div 
              className={`flex items-center  rounded-md font-normal  cursor-default`} 
              onClick={() => handleChangeCheckbox(value, onChange)}
            >
              {value === false ? <BiToggleLeft className='h-6 w-6 text-slate-400'/> : <BiToggleRight className='h-6 w-6 text-blue-600'/>}
            </div>
          </div>
        )}
      />
      <Controller
        name="permissions.canDelete"
        control={control}
        render={({ field: { onChange, value, ref }}) => (
          <div className='flex w-full px-1 py-1 rounded justify-between items-center cursor-pointer text-slate-800'>
            <div className='flex flex-row'>
              <span className='text-base py-0.5 px-1'>Можно удалять строки</span>
            </div>
            <div 
              className={`flex items-center  rounded-md font-normal  cursor-default`} 
              onClick={() => handleChangeCheckbox(value, onChange)}
            >
              {value === false ? <BiToggleLeft className='h-6 w-6 text-slate-400'/> : <BiToggleRight className='h-6 w-6 text-blue-600'/>}
            </div>
          </div>
        )}
      />
    </>
  )
}

export default ViewPermissions;