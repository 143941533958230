import { useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import Select from "react-select";
import { Field, Formula } from "../../../../../core/graphql/types";
import { customStyles } from "../../../../../utils";
import ExpressionEditor, { ExpressionOption } from "../../../../expression_editor";

import { expressionTypes, listOfFunctionsForFormula } from "../../../../../core/enums";
import { useBoard } from "../../../../../core/providers/board";
import { SmallSpinner } from "../../../../loaders";
import { useSpaceStore } from "../../../../../core/store/store";


const FieldFormula = ({field, attributes}: {field: Field, attributes: Formula}) => {
  const {initId, initType} = useBoard();
  const init: string = `${initType}:${initId}`;
  const store = useSpaceStore()
  const {fields} = store.data[init];

  const methods = useFormContext();
  const {register, unregister, watch, control, setValue, getValues} = methods;

  const [options, setOptions] = useState<ExpressionOption[]>()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    unregister('attributes');
  },[])

  useEffect(() => {
    register('attributes.type');
    setValue('attributes.type', "FORMULA");
    setValue('attributes.expression_type', attributes.expressionType ? attributes.expressionType : "DECIMAL");
    setValue('attributes.original', attributes.original ? attributes.original : "");
  },[attributes])
  
  useEffect(() => {
    setOptions([fields.filter((boardField: Field) => boardField.id !== field.id && boardField.hidden === false).map((boardField: Field) => {
      return {id: boardField.id, display: boardField.title, type: "field", field_type: boardField.type}
    }), listOfFunctionsForFormula].flat())

    setLoading(false);
  },[fields, attributes])

  const handleChange = (e: string, onChange: { (...event: any[]): void; (arg0: any): void; }) => {
    onChange(e)
    // setValue('attributes.type', "FORMULA");
    // setValue('attributes.original', e)
    // setValue('attributes.expression_type', watch('attributes.expression_type'))
  }

  const handleChangeET = (e: {label: string, value: string}, onChange: { (...event: any[]): void; (arg0: any): void; }) => {
    // onChange(e.value)
    setValue('attributes.expression_type', e.value)
  }


  return (
    loading ? <SmallSpinner /> :
    <>
      <Controller
        name="attributes.original"
        control={control}
        rules={{ required: true }}
        render={({ field: { onChange, onBlur, value, ref }}) => ( 
          <ExpressionEditor 
            value={value} 
            options={options!} 
            onChange={(e) => handleChange(e, onChange)}
          />
        )}
      />
      <div className="relative">
        <label className="font-medium text-gray-600 text-sm">Формат результата формулы:</label>
        <Controller
          name="attributes.expression_type"
          control={control}
          render={({ field: { onChange, value, ref }}) => (
            <div className="mt-2">
              <Select
                styles={customStyles}
                classNamePrefix="matr_select_form"
                placeholder="Выберите тип"
                isSearchable={false}
                defaultValue={expressionTypes.find(et => et.value == "DECIMAL")}
                value={expressionTypes.find(et => et.value === value)}
                onChange={(v) => handleChangeET(v!, onChange)}
                options={expressionTypes}
              />
            </div>
          )}
        />
      </div>
    </>
  )
}

export default FieldFormula;