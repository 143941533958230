import { Disclosure } from '@headlessui/react';
import _ from "lodash";
import { MutableRefObject, useState } from 'react';
import { TbCalendar, TbChevronRight, TbDots, TbFile, TbFolderPlus, TbForms, TbLayoutGrid, TbLayoutKanban, TbList, TbListDetails, TbSettings, TbSquare, TbSquareRoundedPlusFilled, TbTemplate, TbTrash } from 'react-icons/tb';
import { useNavigate } from 'react-router-dom';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { PageFormCreate } from '../../../containers/Panel/forms/createPage';
import { PanelFormCreate } from '../../../containers/Panel/forms/createPanel';
import { PanelFormDelete } from '../../../containers/Panel/forms/deletePanel';
import PanelFormEdit from '../../../containers/Panel/forms/editPanel';
import { LayoutEnum, Page, PanelInList } from '../../../core/graphql/types';
import { useSpaceStore } from '../../../core/store/store';
import { ActionMenu, Menu } from '../../Menus/ActionMenu';
import { MatrMediumDialog, MatrPopover } from '../../ui/portal';

const PanelsMenu = () => {
  const { panels, currentPage, panel } = useSpaceStore()
  const navigate = useNavigate()
  const [ menuPanelRef, setMenuPanelRef] = useState<any>()
  const [ currentOpenedPanels, setCurrentOpenedPanels] = useState<string[]>([])
  const [ currentPanel, setCurrentPanel] = useState<PanelInList | null>(null)
  const [ openCreatePanel, setOpenCreatePanel ] = useState(false);
  const [ openEditPanel, setOpenEditPanel ] = useState(false);
  const [ openCreatePage, setOpenCreatePage ] = useState(false);
  const [ openPanelMenu, setOpenPanelMenu ] = useState(false);
  const [ openDeleteModal, setOpenDeleteModal ] = useState(false);
  const [ isHover, setIsHover ] = useState<string | null>(null)

  useDeepCompareEffect(() => {
    if(panels.length === 0) {return}
    panels?.map(panel => {
      if(panel.pages?.some(p => p?.id === currentPage?.id)) {
        setCurrentOpenedPanels(_.uniq([...currentOpenedPanels, panel.id])) 
      }
    })
  }, [currentPage, panels])

  function onPanelClick(page_id: string, panel_id: string, close: (focusableElement?: HTMLElement | MutableRefObject<HTMLElement | null> | undefined) => void) {
    if(currentOpenedPanels.includes(panel_id)) {
      let index = currentOpenedPanels.indexOf(panel_id);
      if (index !== -1) {currentOpenedPanels.splice(index, 1);}
      close()
    } else {
      setCurrentOpenedPanels(_.uniq([...currentOpenedPanels, panel_id]))
      navigate(`/${page_id}`)
    }
  }

  function openPage(page_id: string) {
    panels?.map(panel => {
      if(panel.pages?.some(p => p?.id === currentPage?.id)) {
        setCurrentOpenedPanels(_.uniq([...currentOpenedPanels, panel.id])) 
      }
    })
    navigate(`/${page_id}`)
  }

  function openCreatePanelModal() { 
    setOpenCreatePanel(true)
  };
  
  function closeCreatePanelModal() { 
    setOpenCreatePanel(false)
  };

  function openCreatePageModal() { 
    setOpenCreatePage(true)
  };
  
  function closeCreatePageModal() { 
    setOpenCreatePage(false)
  };


  function getPageIcon(layout: LayoutEnum) {
    switch (layout) {
      case LayoutEnum.Grid: return <TbLayoutGrid className="flex-none h-4 w-4"/>
      case LayoutEnum.Blank: return <TbTemplate className="flex-none h-4 w-4"/>    
      case LayoutEnum.Calendar: return <TbCalendar className="flex-none h-4 w-4"/>
      case LayoutEnum.Kanban: return <TbLayoutKanban className="flex-none h-4 w-4"/>    
      case LayoutEnum.RecordsList: return <TbListDetails className="flex-none h-4 w-4"/>
      case LayoutEnum.List: return <TbList className="flex-none h-4 w-4"/>   
      case LayoutEnum.Form: return <TbForms className="flex-none h-4 w-4"/>    
      default: <TbSquare className="flex-none h-4 w-4"/>
    }
  }

  return (
    <>
      <div className='px-2 pt-2'>
        <div className="flex items-center text-base font-semibold text-slate-500 py-2 px-2 max-h-9 mb-1">
          <span>Страницы</span>
        </div>
        <div className='px-1 flex items-center max-h-9 py-1 text-slate-600 rounded-sm mb-2 space-x-2'>
          <div className='flex'>
            {panels?.length > 0 ? <TbFile className='px-1 w-6 h-6 hover:bg-slate-200 rounded-sm' onClick={() => openCreatePageModal()}/> : <></>}
          </div>
          <div className='flex'>
            <TbFolderPlus className='px-1 w-6 h-6 hover:bg-slate-200 rounded-sm' onClick={() => openCreatePanelModal()}/>
          </div>
        </div>
        {
          panels?.map(p => (
            p && p.pages && p?.pages?.length > 0 ?
              <Disclosure as={"div"} key={p.id} defaultOpen={true}>
                {({ open, close }) => (
                  <>
                    <div  className='flex items-center w-full px-2 relative py-1 rounded hover:bg-slate-200' onMouseEnter={() => setIsHover(p.id)} onMouseLeave={() => setIsHover(null)}>
                      <div className='flex items-center space-x-2 w-full'>
                        <Disclosure.Button className="h-5 w-4 flex items-center justify-center" ref={setMenuPanelRef}>
                          <TbChevronRight
                            size={16}
                            className={`${
                              open ? 'rotate-90 transform' : ''
                            } text-slate-500 flex-none`}
                          />
                        </Disclosure.Button>
                        <button type='button' onClick={() => openPage(`${p?.pages?.find(p => p?.index === true)?.id}`)} 
                          className={`block truncate text-base font-medium text-slate-800 pr-6 w-full text-left`}
                        >
                          {p.name}
                        </button>
                      </div>
                    </div>

                    <Disclosure.Panel className="pb-2">
                      {
                        _.orderBy(p?.pages!, ['position'], "asc").map((page: any) => (
                          <div key={page?.id} onClick={() => openPage(`${page?.id}`)} className='cursor-pointer px-2 py-0.5 flex rounded hover:bg-slate-200 items-center space-x-2 w-full'>
                            {getPageIcon(page?.layout!)}
                            <div 
                              className={
                                page?.id === currentPage?.id ? 'flex w-full text-base font-medium rounded-sm text-indigo-600 py-0.5 text-left items-center justify-between' : 
                                'flex w-full text-base font-medium py-0.5 text-left items-center justify-between'
                            }>
                              <span>{page?.name}</span>
                             {page.index && <span className='text-xxs text-slate-400 bg-slate-200 py-0.5 px-2'>Основная</span>}
                            </div>
                          </div>
                        )) 
                      }
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            : <></>
          ))
        }
      </div>
      {openCreatePanel ? 
        <MatrMediumDialog open={openCreatePanel} onClose={closeCreatePanelModal}>
          <PanelFormCreate close={closeCreatePanelModal}/>
        </MatrMediumDialog>
        : <></>}
      {openCreatePage ? 
        <MatrMediumDialog open={openCreatePage} onClose={closeCreatePageModal}>
          <PageFormCreate close={closeCreatePageModal}/>
        </MatrMediumDialog>
        : <></>}
    </>
  );
};

export default PanelsMenu;