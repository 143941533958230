import React, {useEffect} from "react";
import { useFormContext, Controller } from "react-hook-form";
import { BiToggleLeft, BiToggleRight } from "react-icons/bi";

interface FieldCollaboratorProps {
  type: string;
  multiple?: boolean;
}

function FieldCollaborator({attributes}: FieldCollaboratorProps | any) {
  const methods = useFormContext();
  const {register, control, unregister, setValue} = methods;

  useEffect(() => {
    unregister('attributes');
  },[])

  useEffect(() => {
    register('attributes.type');
    setValue('attributes.type', "COLLABORATOR");
    setValue('attributes.multiple', attributes.multiple ? attributes.multiple : false);
  },[attributes])

  return (
    <>
      <div className="relative inline-flex">
        <label className="font-medium text-slate-800 text-sm flex items-center gap-2">
          <Controller
            name="attributes.multiple"
            control={control}
            render={({ field: { onChange, value, ref }}) => (
              <div 
                className={`flex items-center  text-sm rounded-md font-normal justify-center cursor-default`} 
                onClick={() => onChange(!value)}
              >
                {value ? <BiToggleLeft className='h-6 w-6 text-green-600'/> : <BiToggleRight className='h-6 w-6 text-slate-400'/>}
              </div>
            )}
          />
          Разрешить множественный выбор
        </label>
        {/* <label className="font-medium text-gray-600 text-sm flex items-center">
          <input 
            {...register("attributes.multiple")}
            className="form-checkbox rounded-sm focus:outline-none focus:outline-offset-0 mr-2"
            type="checkbox" 
          />
          Разрешить множественный выбор
        </label> */}
      </div>

    </>
  );
}
export default FieldCollaborator;