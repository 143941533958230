import React, { FC, useContext } from "react";
import { HiDownload, HiTrash } from "react-icons/hi";
import { DocViewerContext } from "../state";
import { nextDocument, previousDocument } from "../state/actions";

export const BottomBar: FC<{onRemove: (index: number) => void}> = ({onRemove}) => {
  const { state, dispatch } = useContext(DocViewerContext);
  const { config } = state;

  if (config?.header?.disableHeader) return null;

  const override = config?.header?.overrideComponent?.(
    state,
    () => dispatch(previousDocument()),
    () => dispatch(nextDocument())
  );

  if (override) {
    return override;
  } else {
    return (
      <div className="flex flex-row justify-end items-center px-4 bg-slate-100 h-10 space-x-2">
        <div className="flex item-center justify-center p-1 rounded-md bg-slate-200 hover:bg-indigo-600 text-slate-800 hover:text-white">
          <a href={state.currentDocument?.uri} download={state.currentDocument?.uri}><HiDownload /></a>
        </div>
        <button className="flex item-center justify-center p-1 rounded-md bg-slate-200 hover:bg-indigo-600 text-slate-800 hover:text-white" 
          onClick={() => onRemove(state.currentFileNo)}><HiTrash /></button>
      </div>
    );
  }
};
