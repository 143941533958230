import { max, min, sum, uniq } from "lodash";
import { getKeyForType } from "../../../utils";
import { DateTime } from "luxon";
import { Collaborator, ExpressionType, Field, FieldSummaryType, FieldType, Formula, Updated_At, ValuePayload } from "../../graphql/types";

export function getSummaryValue(values:  ValuePayload[], summaryType: FieldSummaryType, field: Field) {
  return getSummaryValueByType(values, summaryType!, field, additionalData(field))
}

function additionalData(field: Field) {
  switch (field.type) {
    case 'FORMULA': return {formulaValueType: (field.attributes as Formula).expressionType}
    case 'SELECT': 
    case 'CREATED_BY': 
    case 'UPDATED_BY': 
      return {key: 'name', multi: false, relation: false}
    case 'MULTISELECT': 
      return {key: 'name', multi: true, relation: false}
    case 'COLLABORATOR': 
      return {key: 'name', multi: (field.attributes as Collaborator).multiple, relation: false}
    case 'RELATION':
    case 'LOOKUP':
      return {key: 'foreginName', multi: true, relation: true}
    case 'DATETIME':
    case 'INSERTED_AT':
    case 'UPDATED_AT':
      return {includeTime: (field.attributes as Updated_At).includeTime}

    default: return {}
  }
}

function getSummaryValueByType(values: ValuePayload[], summaryType: string, field: Field, additionalData?: any) {
  let jv = []

  if(additionalData.hasOwnProperty('key')) {
    if(additionalData.relation) {
      jv = values?.map((v: any) => v && v[getKeyForType(field.type)] ? v[getKeyForType(field.type)].join([additionalData.key], ", ") : "")
    } else {
      if(additionalData.multi) {
        jv = values?.map((v: any) => v && v[getKeyForType(field.type)] ? v[getKeyForType(field.type)].join([additionalData.key], ", ") : "")
      } else {
        jv = values?.map((v: any) => v && v[getKeyForType(field.type)] ? v[getKeyForType(field.type)][additionalData.key] : "")
      }
    }
  } else {
    if(additionalData.hasOwnProperty('formulaValueType')) {
      if(additionalData.formulaValueType === 'DECIMAL') { 
        jv = values?.map((v: any) => v && parseFloat(v[getKeyForType(field.type)!]))
      } else if(additionalData.formulaValueType === 'DATETIME') {
        jv = values?.map((v: any) => v && v[getKeyForType(field.type)])
      } else {
        jv = values?.map((v: any) => v && v[getKeyForType(field.type)]) 
      }
    } else if(additionalData.hasOwnProperty('includeTime')) {
      if(additionalData.includeTime) {
        jv = values?.map((v: any) => v && v[getKeyForType(field.type)])
      } else {
        jv = values?.map((v: any) => v && DateTime.fromISO(v[getKeyForType(field.type)]).toISODate())
      }
    } else {
      jv = values?.map((v: any) => {
        return v && v[getKeyForType(field.type)]
      })
    }
  }

  let total = jv?.length
  let unique = uniq(jv)?.length
  let empty = jv?.filter((i: any) => !i).length
  let filled = jv?.filter((i: any) => !!i).length

  switch (summaryType) {
    case FieldSummaryType.Total:
      return <div className="text-sm text-slate-700">{total}</div>
    case FieldSummaryType.Sum:
      return <div className="text-sm text-slate-700">{checkTypes(summaryType, field) ? checkResult(sum(jv?.map(i => !isNaN(i) && i)).toFixed(2)) : "Ошибка типа"}</div>
    case FieldSummaryType.Max:
      return <div className="text-sm text-slate-700">{checkTypes(summaryType, field) ? checkResult(max(jv?.map(i => !isNaN(i) && i))) :  "Ошибка типа"}</div>
    case FieldSummaryType.Min:
      return <div className="text-sm text-slate-700">{checkTypes(summaryType, field) ? checkResult(min(jv?.map(i => !isNaN(i) && i))) : "Ошибка типа"}</div>
    case FieldSummaryType.Avg:
      return <div className="text-sm text-slate-700">{checkTypes(summaryType, field) ? checkResult((sum(jv?.map(i => !isNaN(i) && i)) / total).toFixed(2)) : "Ошибка типа"}</div>
    case FieldSummaryType.Median:
      return <div className="text-sm text-slate-700">{checkTypes(summaryType, field) ? checkResult(median(jv?.map(i => !isNaN(i) && i))) : "Ошибка типа"}</div>
    case FieldSummaryType.Deviation:
      return <div className="text-sm text-slate-700">{checkTypes(summaryType, field) ? checkResult(deviation(jv?.map(i => !isNaN(i) && i))?.toFixed(2)) : "Ошибка типа"}</div>
    case FieldSummaryType.None:
      return <div className="text-sm text-slate-700">-</div>
    case FieldSummaryType.Unique:
      return <div className="text-sm text-slate-700">{checkResult(unique)}</div>
    case FieldSummaryType.Empty:
      return <div className="text-sm text-slate-700">{checkResult(empty)}</div>
    case FieldSummaryType.Filled:
      return <div className="text-sm text-slate-700">{checkResult(filled)}</div>
    case FieldSummaryType.UniquePercent:
      return <div className="text-sm text-slate-700">{checkResult(((unique / total) * 100).toFixed(1))} %</div>
    case FieldSummaryType.EmptyPercent:       
      return <div className="text-sm text-slate-700">{checkResult(((empty / total) * 100).toFixed(1))} %</div>
    case FieldSummaryType.FilledPercent:
      return <div className="text-sm text-slate-700">{checkResult(((filled / total) * 100).toFixed(1))} %</div>
    default: return <div className="text-sm text-slate-700">-</div>
  }
}

function checkTypes(summaryType: FieldSummaryType, field: Field) {
  switch (summaryType) {
    case FieldSummaryType.Avg:
    case FieldSummaryType.Deviation:
    case FieldSummaryType.Max:
    case FieldSummaryType.Median: 
    case FieldSummaryType.Min: 
    case FieldSummaryType.Sum: 
      if(field.type === FieldType.Formula) {
        return (field.attributes as Formula).expressionType === ExpressionType.Decimal
      } else {
        return [
          FieldType.Decimal, 
          FieldType.Currency, 
          FieldType.Decimal,
          FieldType.Duration,
          FieldType.Number,
          FieldType.Percent
        ].includes(field.type)
      }
    default:
      return true
  }
}

function checkResult(result: any) {
  if(result === "NaN") { return "Ошибка расчета"}

  return result
}

function median(values: any[]){
  if(values.length === 0) throw new Error("Нет данных");

  values.sort(function(a,b){
    return a-b;
  });

  var half = Math.floor(values.length / 2);
  
  if (values.length % 2)
    return values[half];
  
  return (values[half - 1] + values[half]) / 2.0;
}

function deviation (array: any[]) {
  const n = array?.length
  if(n === 0) { return }
  const mean = array?.reduce((a, b) => a + b) / n
  return Math.sqrt(array?.map(x => Math.pow(x - mean, 2)).reduce((a, b) => a + b) / n)
}

