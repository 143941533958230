import { useEffect, useState, useRef, useCallback, FocusEvent, ChangeEvent, SetStateAction } from 'react';
import CardModal from "../..";
import RelationExternalListItem from './item'; 
import { isNil, sortBy } from 'lodash';
import { CardWithValues, Field, LinkItem, useListCardsOfRelationBoardQuery} from '../../../../core/graphql/types';
import { SmallSpinner } from '../../../loaders';
import { HiOutlineViewGrid, HiOutlineViewGridAdd, HiOutlineXCircle, HiPlusCircle } from 'react-icons/hi';
import { transformValueToString } from '../../../../core/Controllers/Base';
import useDebounce from '../../../../core/hooks/debounce/useDebounce';

interface ListRelationCardsProps {
  boardId: string;
  fieldId: string;
  foreginFieldId?: string;
  links: {id: string, name: string}[] | LinkItem[] | [];
  onChange: any;
  close?: () => void;
  canAdd: boolean;
  addNewCardToRelationBoard?: () => void;
}

function BaseFormRelationList({fieldId, links, onChange, close, canAdd, addNewCardToRelationBoard}:ListRelationCardsProps) {
  let selected = links?.map(l => l.id);
  const listInnerRef = useRef<HTMLDivElement>(null);
  const [ timer, setTimer ] = useState<ReturnType<typeof setTimeout>>();
  const [ cards, setCards ] = useState<CardWithValues[] | any>([]);
  const [ loader, setLoader] = useState(true);
  const [ search, setSearch] = useState<string | null>(null)
  const [ searchTerm ] = useDebounce(search, 500);
  const [ fields, setFields] = useState<Field[] | any>([]);
  const [ foreginCards, setForeginCards] = useState([]);
  const [ after, setAfter] = useState<any>(null)
  const [ before, setBefore] = useState<any>(null)
  const limit = 10

  const [{data}, getCards] = useListCardsOfRelationBoardQuery({variables: {fromFieldId: fieldId, metadata: {after: after, before: before, limit: limit}, search: search}, pause: true, requestPolicy: 'network-only'})

  useEffect(() => {getCards()},[])

  const handleAddCard = () => {
    addNewCardToRelationBoard!()
  }

  useEffect(() => {
    if(!data) {
      return
    }
    setCards([...cards, ...data.listCardsOfRelationBoard?.board?.cards!])
    setFields(data.listCardsOfRelationBoard?.board?.fields)
    setAfter(data.listCardsOfRelationBoard?.metadata?.after)
  },[data])

  useEffect(() => {
    setAfter(null)
    setCards([])
    getCards()
  }, [searchTerm])

  const onScroll = () => {  
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (Math.round(scrollTop) + clientHeight === scrollHeight) {
        if(!after) {
          return
        }
        getCards()
      }
    }
  };

  useEffect(() => {
    if(!cards || !cards.length || !fields || !fields.length) {
      setForeginCards([])
      return
    }

    const prepareForeginCards: any = []
    cards.map((item: CardWithValues) => {
      prepareForeginCards.push({
        id: item.id,
        position: item.position,
        selected: selected?.includes(item.id) ? true : false,
        fields: preparingValues(item.values)
      })
    })
    setForeginCards(prepareForeginCards)
    setLoader(false)
  },[fields, cards, links])

  function preparingValues(values: any) {
    const preValues: any = []
    values.map((value: any) => {preValues.push(transformValue(value))})

    const headerValue = preValues.find((val: any) => val.primary === true)
    const showValues = sortBy(preValues.filter((val: any) => val.primary === false), 'position').slice(0, 3)

    return [headerValue].concat(showValues)
  }

  function transformValue(value: any) {
    return ({
      id: value.id,
      fieldId: value.fieldId,
      preview: fields.find((f: Field) => f.id === value.fieldId).preview,
      position: fields.find((f: Field) => f.id === value.fieldId).position,
      title: fields.find((f: Field) => f.id === value.fieldId).title,
      primary: fields.find((f: Field) => f.id === value.fieldId).primary,
      value: transformValueToString(value),
      type: value.type,
      attributes: fields.find((f: Field) => f.id === value.fieldId).attributes,
    })
  }

  const handleSearch = (value: string) => {
    setSearch(value)
  }

  return (
    <div 
      className="w-[32rem] inline-block h-[32rem] text-left align-middle transition-all transform bg-white shadow-lg rounded-md border mt-1.5"
    >
      {loader ? <SmallSpinner /> :
      <div className="flex flex-col h-full rounded-md">
        <div className="flex w-full h-14 justify-between text-xs bg-white items-center px-3 rounded-t-md font-medium border-b text-slate-600 space-x-3">
          <div className='flex flex-row w-full items-center '>
            <input placeholder='Поиск по связанным записям' className='matr-form-input' value={search ? search! : ""} onChange={(e) => handleSearch(e.target.value)}/>
          </div>
          {canAdd ? <button className="text-slate-500 cursor-pointer hover:text-indigo-800" onClick={() => handleAddCard()}><HiPlusCircle size={20}/></button> : <></>}
        </div>
        <div ref={listInnerRef} onScroll={onScroll} className="flex w-full h-full  bg-white rounded-b-md overflow-scroll">
          {foreginCards?.length > 0 ?
          <div className="flex flex-col w-full px-3 py-4">
            <div  className="flex flex-col space-y-3 w-full last:pb-4">
              {
                foreginCards.map((item: any) => {
                  return <RelationExternalListItem key={item.id} card={item} fields={item.fields} onChange={() => onChange(item)}/>
                })
              }
            </div>
          </div>
          :
          <div className='flex w-full h-full items-center justify-center'></div>}
        </div>
      </div>}
    </div>
  )
}


export default BaseFormRelationList;