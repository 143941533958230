import { useState } from "react";
import { HiTrash } from "react-icons/hi";
import { TbDots, TbEdit, TbEditOff, TbEye, TbEyeOff, TbFilePlus } from "react-icons/tb";
import { ActionMenu } from "../../components/Menus/ActionMenu";
import { MatrMediumDialog, MatrPopover } from "../../components/ui/portal";
import { Panel } from "../../core/graphql/types";
import { useAuth } from "../../core/providers/auth";
import { usePanel } from "../../core/providers/panel";
import { PageFormCreate } from "./forms/createPage";
import PageFormEdit from "./forms/editPage";
import PanelFormEdit from "./forms/editPanel";
import PanelPage from "./page";

export const PanelContainer = () => {
  const auth = useAuth();
  const { panel, page, updatePage, block, setBlock, editorMode, canEdit, changeEditorMode, publishPanel, setPanel, removePage } = usePanel();
  const [ buttonRef, setButtonRef ] = useState<any>()
  const [ openMenu, setOpenMenu ] = useState(false);
  const [ statePageCreateModal, setStatePageCreateModal ] = useState(false);
  const [ statePageEditModal, setStatePageEditModal ] = useState(false);
  const [ statePanelEditModal, setStatePanelEditModal ] = useState(false);
  const [ statePageDeleteModal, setStatePageDeleteModal ] = useState(false);

  function onOpenMenu(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) { setOpenMenu(true)}
  function closeMenu() { setOpenMenu(false) };

  function openPageCreateModal() { setStatePageCreateModal(true) };
  function closePageCreateModal() { setStatePageCreateModal(false) };

  function openPageEditModal() { setStatePageEditModal(true) };
  function closePageEditModal() { setStatePageEditModal(false) };

  function openPanelEditModal() { setStatePanelEditModal(true) };
  function closePanelEditModal() { setStatePanelEditModal(false) };

  function resetBlock(event: any, block: any) {
    event?.stopPropagation()
    setBlock(block)
  }

  function updatePanel(panel: Panel) {
    setPanel(panel)
  }

  function handleChangePagePublished() {
    updatePage({...page, published: !page.published})
  }

  function handleChangePageIsDraft() {
    updatePage({...page, isDraft: !page.isDraft})
  }

  function handleChangePublishPanel() {
    publishPanel(panel?.id!)
  }

  function deletePage() {removePage(page.id)}

  const menu = [
    {
      name: `${editorMode ? "Режим редактора" : "Режим чтения"}`,
      action: changeEditorMode,
      icon: <TbFilePlus className="mr-2 w-3 h-3"/>,
      disabled: false
    },
    {
      name: `${panel?.published ? "Раздел опубликован" : "Раздел отключен"}`,
      action: handleChangePublishPanel,
      icon: <TbFilePlus className="mr-2 w-3 h-3"/>,
      disabled: false
    },
    {
      name: "Новая страниц",
      action: openPageCreateModal,
      icon: <TbFilePlus className="mr-2 w-3 h-3"/>,
      disabled: false
    },
    {
      name: "Настройка страницы",
      action: openPageEditModal,
      icon: <TbFilePlus className="mr-2 w-3 h-3"/>,
      disabled: false
    },
    {
      name: "Настройки панели",
      action: openPanelEditModal,
      icon: <TbFilePlus className="mr-2 w-3 h-3"/>,
      disabled: false
    },
    {
      name: "Удалить страницу",
      action: deletePage,
      icon: <HiTrash className="mr-2 w-3 h-3"/>,
      disabled: false
    },
    // {
    //   name: "Удалить раздел",
    //   action: deletePage,
    //   icon: <HiTrash className="mr-2 w-3 h-3"/>,
    //   disabled: false
    // },
  ] 

  return (
    <>
      <div className='flex-none flex w-full h-14 items-center text-base px-4 justify-between border-b'>
        <div className="flex w-full justify-between">
          <div className="flex items-center font-medium text-base text-slate-800">
            <span className="px-1">{auth.currentUser.space.name}</span>
            <span className="px-1">/</span>
            <span className="px-1">{panel?.name ? panel?.name : "..."}</span>
            <span className="px-1">/</span>
            <span className="px-1">{page?.name ? page?.name : "..."}</span>
          </div>
          {canEdit ?
            <div className='flex items-center space-x-4'>
              <div className='text-sm font-medium text-slate-600 cursor-default flex items-center hover:text-indigo-600'>
                <button type="button" onClick={() => handleChangePublishPanel()}>{panel?.published ? <TbEyeOff size={20} /> : <TbEye size={20}/>}</button>
              </div>
              <div className='text-sm font-medium text-slate-600 cursor-default flex items-center hover:text-indigo-600' onClick={() => changeEditorMode()}>{editorMode ? <TbEditOff size={20}/> : <TbEdit size={20}/>}</div>
              <button
                onClick={(e) => onOpenMenu(e)}
                ref={setButtonRef}
                className="h-5 w-5 text-slate-600"
              >
                <TbDots />
              </button>
            </div> : <></>}
        </div>
      </div>
      <div className="flex h-full overflow-hidden">
        <div className="flex h-full w-full overflow-hidden">
          {editorMode ?
            <div className="flex flex-col flex-1 h-full bg-white w-full overflow-hidden">
              {page && <PanelPage/>}
            </div>
          : 
            <div className="flex flex-col flex-1 h-full w-full">
              {page && <PanelPage/>}
            </div>
          }
        </div> 
        {openMenu && 
          <MatrPopover onClose={closeMenu} reference={buttonRef} placement="bottom-end">
            <ActionMenu menu = {menu}/>
          </MatrPopover>
        }
        {statePageCreateModal ? 
          <MatrMediumDialog open={statePageCreateModal} onClose={closePageCreateModal}>
            <PageFormCreate panel={panel as Panel} close={closePageCreateModal}/>
          </MatrMediumDialog>
        : <></>}
        {statePageEditModal ? 
          <MatrMediumDialog open={statePageEditModal} onClose={closePageEditModal}>
            <PageFormEdit onClose={closePageEditModal}/>
          </MatrMediumDialog>
        : <></>}
        {statePanelEditModal ? 
          <MatrMediumDialog open={statePanelEditModal} onClose={closePanelEditModal}>
            <PanelFormEdit panel={panel as Panel} updatePanel={updatePanel} close={closePanelEditModal}/>
          </MatrMediumDialog>
        : <></>}
      </div>
    </>
  )
}
