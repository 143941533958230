import {useEffect, useState} from "react";
import { sortBy } from "lodash";
import { SpaceUser, useRolesForSelectQuery, useSpaceUsersQuery, useInviteSpaceUserMutation, useUpdateSpaceUserMutation, useDeleteSpaceUserMutation } from "../../../core/graphql/types";
import Avatar from 'react-avatar';
import { InviteUserToSpaceForm } from "./invite";
import { SimpleModal } from "../../../components/modals/simple";
import { PopoverWrapper } from "../../../components/ui/popover";
import { SmallSpinner } from "../../../components/loaders";
import { HiDotsHorizontal, HiPencil, HiTrash } from "react-icons/hi"
import EditUser from "./edit";
import DeleteUser from "./delete";
import { useSpaceStore } from "../../../core/store/store";

const spaceUserStatuses = [
  {value: "ACCEPTED", label: "Подтвержден"},
  {value: "INVITED", label: "Приглашение отправлено"},
  {value: "REJECTED", label: "Приглашение отклонено"},
]


export function SpaceUsers() {
  const {currentSpaceUser} = useSpaceStore()
  const [openCreate, setOpenCreate] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [users, setUsers] = useState<SpaceUser[] | any>();
  const [selectedUser, setSelectedUser] = useState<SpaceUser | null>(null)
  const [errors, setErrors] = useState({});

  const [{ fetching: fetchingRoles, error: errorRoles, data: dataRoles}] = useRolesForSelectQuery({requestPolicy: 'network-only'});
  const [{ fetching, error, data}] = useSpaceUsersQuery({requestPolicy: 'network-only'});

  const [{fetching: invitingUser},inviteUser] = useInviteSpaceUserMutation();
  const [{fetching: updatingUser},updateUser] = useUpdateSpaceUserMutation();
  const [{fetching: deletingUser},deleteUser] = useDeleteSpaceUserMutation();

  function openCreateModal() { setOpenCreate(true)};
  function closeCreateModal() { 
    setOpenCreate(false)
  };

  function openEditModal() { setOpenEdit(true)};
  function closeEditModal() { 
    setSelectedUser(null)
    setOpenEdit(false)
  };

  function openDeleteModal() { setOpenDelete(true)};
  function closeDeleteModal() { 
    setSelectedUser(null)
    setOpenDelete(false)
  };

  useEffect(() => {
    if(!data?.spaceUsers) {
      return
    }
    setUsers(sortBy(data?.spaceUsers, ['lastName']))
  },[data?.spaceUsers])

  function handleInvite(data: {[x: string]: any;}) {
    inviteUser({
      email: data.email,
      roleId: data.role.id,
      expiredAt: data.expiredAt
    }).then(res => {
      if(res.error) {
        return
      }
      let newUsers = [...users, res.data?.inviteSpaceUser]
      setUsers(sortBy(newUsers, ['lastName']))
      closeCreateModal();
    })
  }

  function handleEdit(data: {[x: string]: any;}) {
    updateUser({
      id: data.id,
      roleId: data.role.id,
      expiredAt: data.expiredAt
    }).then(res => {
      if(res.error) {
        return
      }
      let restUsers = users.filter((user: SpaceUser) => user.id !== res.data?.updateSpaceUser?.id!)
      let newUsers = [...restUsers, res.data?.updateSpaceUser]
      setUsers(sortBy(newUsers, ['lastName']))
      closeEditModal();
    })
    setSelectedUser(null)
  }

  function handleDelete(data: {[x: string]: any;}) {
    deleteUser({
      id: data.id
    }).then(res => {
      if(res.error) {
        return
      }
      let restUsers = users.filter((user: SpaceUser) => user.id !== res.data?.deleteSpaceUser?.id!)
      setUsers(sortBy(restUsers, ['lastName']))
      closeDeleteModal();
    })
    setSelectedUser(null)
  }

  function handleEditMode(user: SpaceUser) {
    if(user.isOwner) {
      return
    }
    setSelectedUser(user)
    openEditModal()
  }

  function handleDeleteMode(user: SpaceUser) {
    if(user.isOwner) {
      return
    }
    setSelectedUser(user)
    openDeleteModal()
  }

  const popoverConfig = {
    button: <HiDotsHorizontal size={16}/>,
    actions: [
      {
        title: "Редактировать",
        icon: <HiPencil size={16}/>,
        onClick: handleEditMode
      },
      {
        title: "Удалить",
        icon: <HiTrash size={16}/>,
        onClick: handleDeleteMode
      }
    ]
  }

  if(currentSpaceUser?.role?.name !== "Administrator") { return <div className="w-full h-full flex items-center justify-center">Не существует.</div>}

  return (
    <>
      <section className="w-full">
        <div className="w-full flex justify-between items-center">
          <h4 className="text-xl font-semibold">Участники пространства</h4>
          <button
            type="button"
            onClick={openCreateModal}
            className="px-4 py-2 text-sm font-medium text-white bg-indigo-600 rounded-md hover:bg-indigo-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
          >
            Пригласить
          </button>
        </div>
        <p className="text-sm text-gray-500 leading-8 mb-6 mt-4">Список участников пространства. Приглашайте участников, для совместной работы.</p>
        <div className="relative w-full">
          <div className="flex flex-wrap space-y-1">
          {fetching || invitingUser || updatingUser || deletingUser || !users ? <SmallSpinner/> : users.map((user: SpaceUser, index: number) => (
            <div key={index} className="flex flex-row w-full py-2 items-center focus:outline-none hover:bg-slate-100 rounded-md cursor-default">
              <div className="flex w-5/12 items-centers px-2">
                <div className="w-9 mr-4 ">
                  <Avatar name={`${user.firstName} ${user.lastName}`} round size="36" textMarginRatio={.3} textSizeRatio={0.8} className="font-medium"/>
                </div>
                <div className="w-full">
                  <div className="text-sm font-medium">{`${user.firstName} ${user.lastName}`}</div>
                  {user.isOwner ?  <div className="text-xs text-gray-600">Владелец</div> : <div className="text-xs text-gray-600">Участник</div>}
                </div>
              </div>
              <div className="flex flex-col w-3/12">
                <div className="text-sm font-medium">{`${user.role?.name}`}</div>
                <div className="text-xs text-gray-600 font-medium">{user.expiredAt ? user.expiredAt : <>Бессрочно</>}</div>
              </div>
              <div className="flex flex-col w-3/12">
                <div className="text-sm font-medium">{spaceUserStatuses.find(s => s.value === user.status)?.label}</div>
              </div>
              <div className="flex w-1/12 justify-end px-2">
                {user.isOwner ? <div className="h-6"></div> : 
                  <PopoverWrapper config={popoverConfig} item={user}/>
                }
              </div>
            </div>
          ))}
          </div>
        </div>
      </section>
      {openCreate && <SimpleModal 
        open = {openCreate}
        close = {closeCreateModal}
        isWide = {false}
      >
        <InviteUserToSpaceForm roles={dataRoles?.rolesForSelect} onChange={handleInvite} closeModal={closeCreateModal}/>
      </SimpleModal>}
      {openEdit && <SimpleModal 
        open = {openEdit}
        close = {closeEditModal}
        isWide = {false}
      >
        <EditUser user={selectedUser!} roles={dataRoles?.rolesForSelect} onChange={handleEdit} closeModal={closeEditModal}/>
      </SimpleModal>}
      {openDelete && <SimpleModal 
        open = {openDelete}
        close = {closeDeleteModal}
        isWide = {false}
      >
        <DeleteUser user={selectedUser!} onChange={handleDelete} closeModal = {closeDeleteModal}/>
      </SimpleModal>}
    </>
  );
}