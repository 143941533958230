import { Outlet } from "react-router-dom";
import Header from "../../Headers/Header";
import Sidebar from '../../sidebar/SpaceSidebar';
import toast, { Toaster, ToastBar } from 'react-hot-toast';
import { SpaceProvider } from "../../../core/providers/space";
import { HiX } from "react-icons/hi";

export function PrivateLayout () {
  return (
    <>
      <Toaster 
        position="bottom-left"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          // Define default options
          className: '',
          duration: 5000,
          style: {
            background: '#363636',
            padding: 0,
            color: '#fff',
          },
      
          // Default options for specific types
          success: {
            duration: 3000,
          },
        }}
      >
        {(t) => (
          <ToastBar toast={t}>
            {({ icon, message }) => (
              <div
                className={`${
                  t.visible ? 'animate-enter' : 'animate-leave'
                } max-w-md w-full bg-white shadow rounded-md pointer-events-auto flex`}
              >
                <div className="flex-1 w-1/6 px-4 py-2 items-center">
                  <div className="flex items-center">
                    {icon}
                    <div className="ml-2 flex-1 text-sm text-slate-600 font-medium">
                      {message}
                    </div>
                  </div>
                </div>
                {t.type !== 'loading' && (
                  <div className="flex border-l border-gray-200">
                    <button
                      onClick={() => toast.dismiss(t.id)}
                      className="w-full border border-transparent rounded-none rounded-r-md p-2 flex items-center justify-center text-sm font-medium outline-none text-slate-600 hover:text-indigo-500 focus:outline-none"
                    >
                      <HiX className="h-5 w-5"/>
                    </button>
                  </div>
                )}
              </div>
            )}
          </ToastBar>
        )}
      </Toaster>
      <div className="flex min-h-screen h-screen overflow-hidden">
        <Sidebar />
        <main className="relative flex flex-col w-full h-screen overflow-hidden">
          <Outlet />
        </main>
      </div>
    </>
  );
  
};