import { useFormContext } from "react-hook-form";
import FormFieldInput from "../../../../../components/Forms/Fields/Inputs";
import { nonEditablbeFields, valueToString } from "../../../../../core/Controllers/Base";
import { Block, BlockFieldOptions, BlockSourceField, FieldType } from "../../../../../core/graphql/types";
import useDebouncedCallback from "../../../../../core/hooks/debounce/useDebouncedCallback";
import { useSpace } from "../../../../../core/providers/space";
import { useSpaceStore } from "../../../../../core/store/store";
import ValueShow from "../../../../../components/Forms/Fields/Values";

export default function EditableField({block, formBlock}:{block: Block, formBlock: Block}) {
  const init = `block:${formBlock!.id}`
  let spaceStore = useSpaceStore()
  let formStore = spaceStore.data[init]
  const {storeCardValue} = useSpace()

  const options = (block.options! as BlockFieldOptions)
  const source = (block.source as BlockSourceField)
  const methods = useFormContext()
  const {getValues} = methods
  const debounced = useDebouncedCallback(
    (value) => {
      storeCardValue(source.boardId!, formStore?.currentCardId!, source.fieldId!, (block.source as BlockSourceField).field?.type!, valueToString(value, (block.source as BlockSourceField).field?.type!))
    },
    1000
  );

  function handleChangeValue(e: any, onChange: (...event: any[]) => void, index: number | null | undefined) {
    onChange(e)
    if(!!formStore?.currentCardId) {
      if((!e || e.length === 0) && options.required!) { return }
      debounced(e)
    }
  }
  
  function showValue() {
    switch (source.field?.type) {
      case FieldType.Lookup: return <div>{getValues(`${source.field?.id}`)?.map((v:any) => v.value.value).join(", ")}</div>
      case FieldType.Recordid: return <div>{getValues(`${source.field?.id}`)}</div>
      case FieldType.Formula: return <div>{getValues(`${source.field?.id}`)}</div>
      case FieldType.CreatedBy: return <div>{getValues(`${source.field?.id}`)?.name}</div>
      case FieldType.UpdatedBy: return <div>{getValues(`${source.field?.id}`)?.name}</div>
      case FieldType.InsertedAt: return <div>{getValues(`${source.field?.id}`)}</div>
      case FieldType.UpdatedAt: return <div>{getValues(`${source.field?.id}`)}</div>
      default: return <div>{getValues(`${source.field?.id}`)?.value}</div>
    }
  }

  let fieldOptions = {
    methods: methods,
    attributes: source.field?.attributes,
    boardId: source.boardId,
    fieldId: source.fieldId,
    value: getValues(`${source.field?.id}`), 
    autoFocus: false,
    name: `${source.field?.id}`,
    index: null,
    narrow: false,
    placeholder: "",
    disabled: !options.editable,
    required: options.required!,
    handleChange: handleChangeValue
  }

  return (
    <>
      <div className="relative flex flex-col w-full space-y-2">
        {options.showTitle ? <label className="matr-label">{options.title} {options.required ? <span className="text-red-600">*</span> : ""}</label> : <></>}
        {options.editable && !nonEditablbeFields.includes((block.source as BlockSourceField).field?.type!) ?
          <div className="flex w-full">
            <FormFieldInput 
              type={(block.source as BlockSourceField).field?.type!}
              fieldOptions={fieldOptions}
            />
          </div>
        :
          <div className="flex w-full text-sm">
            <ValueShow type={source.field?.type!} fieldAttributes={source.field?.attributes!} value={getValues(`${source.field?.id}`)}/>
          </div>
        }
      </div>
    </>
  )
}