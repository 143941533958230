//@ts-nocheck
import React from 'react'

import { EditorContent, useEditor, ReactRenderer } from '@tiptap/react'
import Mention from './Mention'
import Document from '@tiptap/extension-document'
import Paragraph from '@tiptap/extension-paragraph'
import Heading from '@tiptap/extension-heading'
import Placeholder from '@tiptap/extension-placeholder'
import Text from '@tiptap/extension-text'
import TextStyle from '@tiptap/extension-text-style'
import ListItem from '@tiptap/extension-list-item'
import OrderedList from '@tiptap/extension-ordered-list'
import BulletList from '@tiptap/extension-bullet-list'
import TaskItem from '@tiptap/extension-task-item'
import TaskList from '@tiptap/extension-task-list'

import { Color } from '@tiptap/extension-color'

import Bold from '@tiptap/extension-bold'
import Strike from '@tiptap/extension-strike'
import Italic from '@tiptap/extension-italic'
import Underline from '@tiptap/extension-underline'

import EditorToolbar from '../components/Toolbar'

const PlainTextEditor = ({value, onChange}: PlainTextEditorProps) => {
  const editor = useEditor({
    autofocus: true,
    extensions: [
      Document,
      Paragraph,
      Text,
    ],
    content: value ? JSON.parse(value) : null,
    onUpdate: ({ editor }) => {
      const json = editor.getJSON()
      onChange(json)
    },
  })

  return (
    <>
      <EditorContent editor={editor} className="cell w-full"/>
    </>
  )
}



export default PlainTextEditor


export const PlainTextEditorShow = ({value}: PlainTextEditorProps) => {
  const editor = useEditor({
    autofocus: false,
    editable: false,
    extensions: [
      Document,
      Paragraph,
      Text,
      Heading.configure({
        levels: [1, 2],
      }),
      Bold, Strike, Italic, Underline,
      Color.configure({ types: [TextStyle.name, ListItem.name, TaskItem.name] }),
      TextStyle.configure({ types: [ListItem.name, TaskItem.name] }),
      ListItem,
      OrderedList.configure({
        keepMarks: true,
        keepAttributes: false
      }),
      BulletList.configure({
        keepMarks: true,
        keepAttributes: false
      }),
      TaskList,
      TaskItem.configure({
        nested: true
      }),
    ],
    content: value ? JSON.parse(value) : null,
  })

  return (
    <div className='truncate h-full w-full'>
      {editor?.getText()}
    </div>
  )
}