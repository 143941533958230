import React from "react";
import { useRemoveFlowMutation } from "../../../../core/graphql/types";
import { useForm } from "react-hook-form";
import toast from 'react-hot-toast';
import { useSpace } from "../../../../core/providers/space";
import { useNavigate } from "react-router-dom";
import { TbSquaresFilled, TbX } from "react-icons/tb";

export type FlowFormDeleteProps = {
  id: string;
  name: string;
  closeModal: () => void;
}

export const FlowFormDelete: React.FC<FlowFormDeleteProps> = ({id, name, closeModal}) => {
    const {space, actionFlowDelete} = useSpace()
    const navigate = useNavigate()
    const { register, handleSubmit, formState: { errors } } =  useForm();

    const [, deleteFlow] = useRemoveFlowMutation();

    const onSubmit = handleSubmit(data => {
        if(data.name === name) {
        toast.promise(
            deleteFlow({id: id}).then(result => {
                actionFlowDelete(id)
                closeModal();
                return result
            }),
            {
            loading: 'Удаляем процесс...',
            success: (data) => {
                if (data.error) throw new Error();
                if (data.data?.removeFlow?.id === id) {navigate('/')}
                return 'Процесс удален';
            },
            error: 'Ошибка. Не удалось удалить процесс',
            }
        );
        }
  });

  return (
    <>
      <div className="flex items-center justify-between">
        <div className="flex items-center space-x-2">
        <div className="py-1 px-2 bg-slate-100 flex space-x-2 rounded">
            <TbSquaresFilled size={20} className="text-indigo-600"/>
            <div className="flex font-medium text-sm">{space?.name}</div>
        </div>
        <div className="flex font-normal text-sm">Удаление процесса</div>
        </div>
        <button onClick={() => closeModal()}><TbX size={18}/></button>
      </div>
    
      <form onSubmit={onSubmit} className="flex flex-col relative w-full space-y-4">
        <div className="relative flex flex-col space-y-1.5 pt-4">
          <label className="font-medium text-gray-600 text-sm">Название процесса:</label>
          <input {...register("name", { required: true })}
            className="matr-form-input"
          />
          {errors.name && <span className="text-red-600 text-xs font-normal">Вы не ввели название доски.</span>}
        </div>
        <div className="flex justify-between w-full pt-6">
          <button
            type="button"
            className="inline-flex justify-end px-3 py-1.5 text-sm font-medium text-indigo-700 bg-indigo-100 border border-transparent rounded-md hover:bg-indigo-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-indigo-500"
            onClick={closeModal}
          >
            Отменить
          </button>
          <button
            type="submit"
            className="inline-flex justify-center px-3 py-1.5 text-sm font-medium text-white bg-red-600 border border-transparent rounded-md hover:bg-red-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-indigo-500"
          >
            Удалить
          </button>
        </div>
      </form>
    </>
  )
}