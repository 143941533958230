import { TbAbacus, TbCalendarEvent, TbChartBar, TbDivide, TbFilter, TbForms, TbLayoutGrid, TbLayoutKanban, TbList, TbSelect, TbTextCaption, TbTextWrap } from "react-icons/tb";
import * as Icons from "../../components/icons";
import { BlockTypeEnum, ExpressionType, Field, Formula, LayoutEnum, NodeOutputTypesEnum, NodeTriggerTypesEnum } from "../graphql/types";

const typeOptions = [
  { value: "STRING", min_label: 'СТРОКА', label: 'Одиночная строка', default_title: "Новая строка", icon: <Icons.StringIcon/>},
  { value: "URL", min_label: 'URL', label: 'Url', default_title: "Адрес", icon: <Icons.UrlIcon/>},
  { value: "EMAIL", min_label: 'EMAIL', label: 'Email', default_title: "Адрес", icon: <Icons.EmailIcon/>},
  { value: "TEXTAREA", min_label: 'ТЕКСТ', label: 'Текст', default_title: "Новый текст", icon: <Icons.TextareaIcon/>},
  { value: "CHECKBOX", min_label: 'ЧЕКБОКС', label: 'Чекбокс', default_title: "Новый выбор", icon: <Icons.CheckboxIcon/>},
  { value: "NUMBER", min_label: 'ЧИСЛО', label: 'Число', default_title: "Простое число", icon: <Icons.NumberIcon/>},
  { value: "DURATION", min_label: 'ПРОДОЛЖИТЕЛЬНОСТЬ', label: 'Продолжительность', default_title: "Продолжительность", icon: <Icons.DurationIcon/>},
  { value: "PHONE", min_label: 'ТЕЛЕФОН', label: 'Телефон', default_title: "Номер телефона", icon: <Icons.PhoneIcon/>},
  { value: "DECIMAL", min_label: 'ДРОБЬ', label: 'Дробное число', default_title: "Дробное число", icon: <Icons.DecimalIcon/>},
  { value: "PERCENT", min_label: 'ПРОЦЕНТ', label: 'Процент', default_title: "Процент", icon: <Icons.PercentIcon/>},
  { value: "CURRENCY", min_label: 'ВАЛЮТА', label: 'Денежное поле', default_title: "Деньги", icon: <Icons.CurrencyIcon/>},
  { value: "DATETIME", min_label: 'ДАТА И ВРЕМЯ', label: 'Дата и время', default_title: "Дата и время", icon: <Icons.DateTimeIcon/>},
  { value: "SELECT", min_label: 'ОДИНОЧНЫЙ ВЫБОР', label: 'Одиночный выбор', default_title: "Одиночный выбор", icon: <Icons.SelectIcon/>},
  { value: "MULTISELECT", min_label: 'МНЮЕСТВЕННЫЙ ВЫБОР', label: 'Множественный выбор', default_title: "Множественный выбор", icon: <Icons.MultiSelectIcon/>},
  { value: "COLLABORATOR", min_label: 'ПОЛЬЗОВАТЕЛИ', label: 'Пользователи', default_title: "Пользователи", icon: <Icons.CollaboratorIcon/>},
  { value: "RELATION", min_label: 'СВЯЗЬ С ДРУГОЙ ДОСКОЙ', label: 'Связь с другой доской', default_title: "Связь с источником", icon: <Icons.RelationIcon/>},
  { value: "LOOKUP", min_label: 'ОТБРАЖЕНИЕ ИЗ ДРУГОЙ ДОСКИ', label: 'Показывать данные из связанной карточки', default_title: "Из источника", icon: <Icons.LookupIcon/>},
  { value: "INSERTED_AT", min_label: 'ДАТА СОЗДАНИЯ', label: 'Дата создания', default_title: "Создано", icon: <Icons.InsertedAtIcon/>},
  { value: "UPDATED_AT", min_label: 'ДАТА ОБНОВЛЕНИЯ', label: 'Дата обновления', default_title: "Обновлено", icon: <Icons.UpdatedAtIcon/>},
  { value: "CREATED_BY", min_label: 'КТО СОЗДАЛ', label: 'Создано пользователем', default_title: "Создал", icon: <Icons.CreatedByIcon/>},
  { value: "UPDATED_BY", min_label: 'КТО ОБНОВИЛ', label: 'Обновлено пользователем', default_title: "Обновил", icon: <Icons.UpdatedByIcon/>},
  { value: "FORMULA", min_label: 'ФОРМУЛА', label: 'Формула', default_title: "Расчет", icon: <Icons.FormulaIcon/>},
  { value: "ATTACHMENT", min_label: 'ВЛОЖЕНИЕ', label: 'Вложение', default_title: "Добавить вложение", icon: <Icons.AttachmentIcon/>},
  { value: "RECORDID", min_label: 'ПОРЯДКОВЫЙ НОМЕР', label: 'Порядковый номер', default_title: "Номер", icon: <Icons.AttachmentIcon/>}
];

const primaryTypeOptions = [
  { value: "STRING", min_label: 'СТРОКА', label: 'Одиночная строка', default_title: "Новая строка", icon: <Icons.StringIcon/>},
  { value: "URL", min_label: 'URL', label: 'Url', default_title: "Адрес", icon: <Icons.UrlIcon/>},
  { value: "EMAIL", min_label: 'EMAIL', label: 'Email', default_title: "Адрес", icon: <Icons.EmailIcon/>},
  { value: "TEXTAREA", min_label: 'ТЕКСТ', label: 'Текст', default_title: "Новый текст", icon: <Icons.TextareaIcon/>},
  { value: "NUMBER", min_label: 'ЧИСЛО', label: 'Число', default_title: "Простое число", icon: <Icons.NumberIcon/>},
  { value: "DURATION", min_label: 'ПРОДООЛЖИТЕЛЬНОСТЬ', label: 'Продолюительность', default_title: "Продолжительность", icon: <Icons.DurationIcon/>},
  { value: "PHONE", min_label: 'ТЕЛЕФОН', label: 'Телефон', default_title: "Номер телефона", icon: <Icons.PhoneIcon/>},
  { value: "DECIMAL", min_label: 'ДРОБЬ', label: 'Дробное число', default_title: "Дробное число", icon: <Icons.DecimalIcon/>},
  { value: "PERCENT", min_label: 'ПРОЦЕНТ', label: 'Процент', default_title: "Процент", icon: <Icons.PercentIcon/>},
  { value: "CURRENCY", min_label: 'ВАЛЮТА', label: 'Денежное поле', default_title: "Деньги", icon: <Icons.CurrencyIcon/>},
  { value: "DATETIME", min_label: 'ДАТА И ВРЕМЯ', label: 'Дата и время', default_title: "Дата и время", icon: <Icons.DateTimeIcon/>},
  { value: "FORMULA", min_label: 'ФОРМУЛА', label: 'Формула', default_title: "Расчет", icon: <Icons.FormulaIcon/>},
  { value: "RECORDID", min_label: 'ПОРЯДКОВЫЙ НОМЕР', label: 'Порядковый номер', default_title: "Номер", icon: <Icons.AttachmentIcon/>}
];

const constantTypes = [
  { value: "STRING", min_label: 'СТРОКА', label: 'Строка', default_title: "Строка", icon: <Icons.StringIcon/>},
  { value: "DECIMAL", min_label: 'ДРОБЬ', label: 'Число', default_title: "Число", icon: <Icons.DecimalIcon/>},
  { value: "DATETIME", min_label: 'ДАТА И ВРЕМЯ', label: 'Дата и время', default_title: "Дата и время", icon: <Icons.DateTimeIcon/>},
]

const nodeFieldValueTypes = [
  { value: "STRING", label: 'Строка', icon: <Icons.StringIcon/>},
  { value: "DECIMAL", label: 'Число', icon: <Icons.DecimalIcon/>},
  { value: "DATE", label: 'Дата', icon: <Icons.DateTimeIcon/>},
  { value: "DATETIME", label: 'Дата и время', icon: <Icons.DateTimeIcon/>},
  { value: "NOW", label: 'Сейчас', icon: <Icons.DateTimeIcon/>},
]

const summaryTypes = [
  {label: "Нет", value: "NONE"},
  {label: "Количество", value: "TOTAL"},
  {label: "Уникальные", value: "UNIQUE"},
  {label: "Пустые", value: "EMPTY"},
  {label: "Заполненные", value: "FILLED"},
  {label: "Процент уникальных", value: "UNIQUE_PERCENT"},
  {label: "Процент пустых", value: "EMPTY_PERCENT"},
  {label: "Процент заполненных", value: "FILLED_PERCENT"},
  {label: "Сумма", value: "SUM"},
  {label: "Макс", value: "MAX"},
  {label: "Мин", value: "MIN"},
  {label: "Среднее", value: "AVG"},
  {label: "Медиана", value: "MEDIAN"},
  {label: "Дивиация", value: "DEVIATION"}
]

const datetime = [
  {label: "точная дата", value: "CUSTOM", editor: true},
  {label: "сегодня", value: "TODAY", editor: false},
  {label: "завтра", value: "TOMORROW", editor: false},
  {label: "вчера", value: "YESTERDAY", editor: false},
  {label: "неделю назад", value: "ONE_WEEK_AGO", editor: false},
  {label: "неделю вперед", value: "ONE_WEEK_FUTURE", editor: false},
  {label: "месяц назад", value: "ONE_MONTH_AGO", editor: false},
  {label: "месяц вперед", value: "ONE_MONTH_FUTURE", editor: false},
  {label: "дней назад", value: "NUMBER_OF_DAYS_AGO", editor: true},
  {label: "дней вперед", value: "NUMBER_OF_DAYS_FUTURE", editor: true}
]

const datetime_period = [
  {label: "за прошлую неделю", value: "PAST_WEEK", editor: false},
  {label: "за следующую неделю", value: "NEXT_WEEK", editor: false},
  {label: "за прошлый месяц", value: "PAST_MONTH", editor: false},
  {label: "за следующий месяц", value: "NEXT_MONTH", editor: false},
  {label: "за прошлый год", value: "PAST_YEAR", editor: false},
  {label: "за следующий год", value: "NEXT_YEAR", editor: false},
  {label: "за предидущее количество дней", value: "PAST_DAYS", editor: true},
  {label: "за следующее количество дней", value: "NEXT_DAYS", editor: true},
]

const operators = [
  {label: "содержит", value: "CONTAINS", type: "STRING", editor: "STRING"},
  {label: "не равно", value: "NOT_CONTAINS", type: "STRING", editor: "STRING"},
  {label: "соответствует", value: "EQUAL", type: "STRING", editor: "STRING"},
  {label: "не соответствует", value: "NOT_EQUAL", type: "STRING", editor: "STRING"},
  {label: "пустое", value: "EMPTY", type: "STRING", editor: "NO"},
  {label: "не пусто", value: "NOT_EMPTY", type: "STRING", editor: "NO"},

  {label: "содержит", value: "CONTAINS", type: "URL", editor: "STRING"},
  {label: "не равно", value: "NOT_CONTAINS", type: "URL", editor: "STRING"},
  {label: "соответствует", value: "EQUAL", type: "URL", editor: "STRING"},
  {label: "не соответствует", value: "NOT_EQUAL", type: "URL", editor: "STRING"},
  {label: "пустое", value: "EMPTY", type: "URL", editor: "NO"},
  {label: "не пусто", value: "NOT_EMPTY", type: "URL", editor: "NO"},

  {label: "содержит", value: "CONTAINS", type: "EMAIL", editor: "STRING"},
  {label: "не равно", value: "NOT_CONTAINS", type: "EMAIL", editor: "STRING"},
  {label: "соответствует", value: "EQUAL", type: "EMAIL", editor: "STRING"},
  {label: "не соответствует", value: "NOT_EQUAL", type: "EMAIL", editor: "STRING"},
  {label: "пустое", value: "EMPTY", type: "EMAIL", editor: "NO"},
  {label: "не пусто", value: "NOT_EMPTY", type: "EMAIL", editor: "NO"},

  {label: "=", value: "EQUAL", type: "NUMBER", editor: "NUMBER"},
  {label: "не равно", value: "NOT_EQUAL", type: "NUMBER", editor: "NUMBER"},
  {label: ">", value: "GREAT", type: "NUMBER", editor: "NUMBER"},
  {label: ">=", value: "GREAT_OR_EQUAL", type: "NUMBER", editor: "NUMBER"},
  {label: "<", value: "LEAST", type: "NUMBER", editor: "NUMBER"},
  {label: "=<", value: "LEAST_OR_EQUAL", type: "NUMBER", editor: "NUMBER"},
  {label: "пусто", value: "EMPTY", type: "NUMBER", editor: "NO"},
  {label: "не пусто", value: "NOT_EMPTY", type: "NUMBER", editor: "NO"},

  {label: "=", value: "EQUAL", type: "PHONE", editor: "NUMBER"},
  {label: "не равно", value: "NOT_EQUAL", type: "PHONE", editor: "NUMBER"},
  {label: ">", value: "GREAT", type: "PHONE", editor: "NUMBER"},
  {label: ">=", value: "GREAT_OR_EQUAL", type: "PHONE", editor: "NUMBER"},
  {label: "<", value: "LEAST", type: "PHONE", editor: "NUMBER"},
  {label: "=<", value: "LEAST_OR_EQUAL", type: "PHONE", editor: "NUMBER"},
  {label: "пусто", value: "EMPTY", type: "PHONE", editor: "NO"},
  {label: "не пусто", value: "NOT_EMPTY", type: "PHONE", editor: "NO"},

  {label: "=", value: "EQUAL", type: "DURATION", editor: "NUMBER"},
  {label: "не равно", value: "NOT_EQUAL", type: "DURATION", editor: "NUMBER"},
  {label: ">", value: "GREAT", type: "DURATION", editor: "NUMBER"},
  {label: ">=", value: "GREAT_OR_EQUAL", type: "DURATION", editor: "NUMBER"},
  {label: "<", value: "LEAST", type: "DURATION", editor: "NUMBER"},
  {label: "=<", value: "LEAST_OR_EQUAL", type: "DURATION", editor: "NUMBER"},
  {label: "пусто", value: "EMPTY", type: "DURATION", editor: "NO"},
  {label: "не пусто", value: "NOT_EMPTY", type: "DURATION", editor: "NO"},

  {label: "=", value: "EQUAL", type: "DECIMAL", editor: "DECIMAL"},
  {label: "не равно", value: "NOT_EQUAL", type: "DECIMAL", editor: "DECIMAL"},
  {label: ">", value: "GREAT", type: "DECIMAL", editor: "DECIMAL"},
  {label: ">=", value: "GREAT_OR_EQUAL", type: "DECIMAL", editor: "DECIMAL"},
  {label: "<", value: "LEAST", type: "DECIMAL", editor: "DECIMAL"},
  {label: "=<", value: "LEAST_OR_EQUAL", type: "DECIMAL", editor: "DECIMAL"},
  {label: "пусто", value: "EMPTY", type: "DECIMAL", editor: "NO"},
  {label: "не пусто", value: "NOT_EMPTY", type: "DECIMAL", editor: "NO"},

  {label: "=", value: "EQUAL", type: "PERCENT", editor: "DECIMAL"},
  {label: "не равно", value: "NOT_EQUAL", type: "PERCENT", editor: "DECIMAL"},
  {label: ">", value: "GREAT", type: "PERCENT", editor: "DECIMAL"},
  {label: ">=", value: "GREAT_OR_EQUAL", type: "PERCENT", editor: "DECIMAL"},
  {label: "<", value: "LEAST", type: "PERCENT", editor: "DECIMAL"},
  {label: "=<", value: "LEAST_OR_EQUAL", type: "PERCENT", editor: "DECIMAL"},
  {label: "пусто", value: "EMPTY", type: "PERCENT", editor: "NO"},
  {label: "не пусто", value: "NOT_EMPTY", type: "PERCENT", editor: "NO"},

  {label: "=", value: "EQUAL", type: "CURRENCY", editor: "DECIMAL"},
  {label: "не равно", value: "NOT_EQUAL", type: "CURRENCY", editor: "DECIMAL"},
  {label: ">", value: "GREAT", type: "CURRENCY", editor: "DECIMAL"},
  {label: ">=", value: "GREAT_OR_EQUAL", type: "CURRENCY", editor: "DECIMAL"},
  {label: "<", value: "LEAST", type: "CURRENCY", editor: "DECIMAL"},
  {label: "=<", value: "LEAST_OR_EQUAL", type: "CURRENCY", editor: "DECIMAL"},
  {label: "пусто", value: "EMPTY", type: "CURRENCY", editor: "NO"},
  {label: "не пусто", value: "NOT_EMPTY", type: "CURRENCY", editor: "NO"},

  {label: "равно", value: "EQUAL", type: "CHECKBOX", editor: "NO"},

  {label: "равно", value: "EQUAL", type: "SELECT", multi: false, editor: "SELECT"},
  {label: "не равно", value: "NOT_EQUAL", type: "SELECT", multi: false, editor: "SELECT"},
  {label: "любой из", value: "ANY", type: "SELECT", multi: true, editor: "SELECT"},
  {label: "никто из", value: "NOT_ANY", type: "SELECT", multi: true, editor: "SELECT"},
  {label: "пусто", value: "EMPTY", type: "SELECT", multi: false, editor: "NO"},
  {label: "не пусто", value: "NOT_EMPTY", type: "SELECT", multi: false, editor: "NO"},

  {label: "равно", value: "EQUAL", type: "CREATED_BY", multi: false, editor: "SELECT"},
  {label: "не равно", value: "NOT_EQUAL", type: "CREATED_BY", multi: false, editor: "SELECT"},
  {label: "любой из", value: "ANY", type: "CREATED_BY", multi: true, editor: "SELECT"},
  {label: "никто из", value: "NOT_ANY", type: "CREATED_BY", multi: true, editor: "SELECT"},
  {label: "пустое", value: "EMPTY", type: "CREATED_BY", multi: false, editor: "NO"},
  {label: "не пусто", value: "NOT_EMPTY", type: "CREATED_BY", multi: false, editor: "NO"},

  {label: "равно", value: "EQUAL", type: "UPDATED_BY", multi: false, editor: "SELECT"},
  {label: "не равно", value: "NOT_EQUAL", type: "UPDATED_BY", multi: false, editor: "SELECT"},
  {label: "любой из", value: "ANY", type: "UPDATED_BY", multi: true, editor: "SELECT"},
  {label: "никто из", value: "NOT_ANY", type: "UPDATED_BY", multi: true, editor: "SELECT"},
  {label: "пустое", value: "EMPTY", type: "UPDATED_BY", multi: false, editor: "NO"},
  {label: "не пусто", value: "NOT_EMPTY", type: "UPDATED_BY", multi: false, editor: "NO"},

  {label: "любой из", value: "ANY", type: "MULTISELECT", multi: true, editor: "SELECT"},
  {label: "не один из", value: "NOT_ANY", type: "MULTISELECT", multi: true, editor: "SELECT"},
  {label: "все из", value: "ALL", type: "MULTISELECT", multi: true, editor: "SELECT"},
  {label: "равно", value: "EQUAL", type: "MULTISELECT", multi: true, editor: "SELECT"},
  {label: "пусто", value: "EMPTY", type: "MULTISELECT", multi: false, editor: "NO"},
  {label: "не пусто", value: "NOT_EMPTY", type: "MULTISELECT", multi: false, editor: "NO"},

  {label: "равно", value: "EQUAL", type: "COLLABORATOR", multi: false, editor: "SELECT"},
  {label: "не равно", value: "NOT_EQUAL", type: "COLLABORATOR", multi: false, editor: "SELECT"},
  {label: "все из", value: "ALL", type: "COLLABORATOR", multi: true, editor: "SELECT"},
  {label: "любой из", value: "ANY", type: "COLLABORATOR", multi: true, editor: "SELECT"},
  {label: "никто из", value: "NOT_ANY", type: "COLLABORATOR", multi: true, editor: "SELECT"},
  {label: "пустое", value: "EMPTY", type: "COLLABORATOR", multi: false, editor: "NO"},
  {label: "не пусто", value: "NOT_EMPTY", type: "COLLABORATOR", multi: false, editor: "NO"},

  {label: "любой из", value: "ANY", type: "RELATION", multi: true, editor: "SELECT"},
  {label: "все из", value: "ALL", type: "RELATION", multi: true, editor: "SELECT"},
  {label: "только", value: "EQUAL", type: "RELATION", multi: true, editor: "SELECT"},
  {label: "ни один из", value: "NOT_ANY", type: "RELATION", multi: true, editor: "SELECT"},
  {label: "содержит", value: "CONTAINS", type: "RELATION", editor: "STRING"},
  {label: "не содержит", value: "NOT_CONTAINS", type: "RELATION", editor: "STRING"},
  {label: "пустое", value: "EMPTY", type: "RELATION", multi: false, editor: "NO"},
  {label: "не пусто", value: "NOT_EMPTY", type: "RELATION", multi: false, editor: "NO"},

  {label: "любой из", value: "ANY", type: "LOOKUP", multi: true, editor: "SELECT"},
  {label: "все из", value: "ALL", type: "LOOKUP", multi: true, editor: "SELECT"},
  {label: "только", value: "EQUAL", type: "LOOKUP", multi: true, editor: "SELECT"},
  {label: "ни один из", value: "NOT_ANY", type: "LOOKUP", multi: true, editor: "SELECT"},
  {label: "содержит", value: "CONTAINS", type: "LOOKUP", editor: "STRING"},
  {label: "не равно", value: "NOT_CONTAINS", type: "LOOKUP", editor: "STRING"},
  {label: "пустое", value: "EMPTY", type: "LOOKUP", multi: false, editor: "NO"},
  {label: "не пусто", value: "NOT_EMPTY", type: "LOOKUP", multi: false, editor: "NO"},

  {label: "равно", value: "EQUAL", type: "DATETIME", editor: "SELECT", values: datetime},
  {label: "не равно", value: "NOT_EQUAL", type: "DATETIME", editor: "SELECT", values: datetime},
  {label: "внутри", value: "BETWEEN", type: "DATETIME", editor: "SELECT", values: datetime_period},
  {label: "до", value: "LEAST", type: "DATETIME", editor: "SELECT", values: datetime},
  {label: "сейчас или до", value: "LEAST_OR_EQUAL", type: "DATETIME", editor: "SELECT", values: datetime},
  {label: "после", value: "GREAT", type: "DATETIME", editor: "SELECT", values: datetime},
  {label: "сейчас или после", value: "GREAT_OR_EQUAL", type: "DATETIME", editor: "SELECT", values: datetime},
  {label: "пусто", value: "EMPTY", type: "DATETIME", editor: "NO", values: []},
  {label: "не пусто", value: "NOT_EMPTY", type: "DATETIME", editor: "NO", values: []},

  {label: "равно", value: "EQUAL", type: "INSERTED_AT", editor: "SELECT", values: datetime},
  {label: "не равно", value: "NOT_EQUAL", type: "INSERTED_AT", editor: "SELECT", values: datetime},
  {label: "внутри", value: "BETWEEN", type: "INSERTED_AT", editor: "SELECT", values: datetime_period},
  {label: "до", value: "LEAST", type: "INSERTED_AT", editor: "SELECT", values: datetime},
  {label: "сейчас или до", value: "LEAST_OR_EQUAL", type: "INSERTED_AT", editor: "SELECT", values: datetime},
  {label: "после", value: "GREAT", type: "INSERTED_AT", editor: "SELECT", values: datetime},
  {label: "сейчас или после", value: "GREAT_OR_EQUAL", type: "INSERTED_AT", editor: "SELECT", values: datetime},
  {label: "пусто", value: "EMPTY", type: "INSERTED_AT", editor: "NO", values: []},
  {label: "не пусто", value: "NOT_EMPTY", type: "INSERTED_AT", editor: "NO", values: []},

  {label: "равно", value: "EQUAL", type: "UPDATED_AT", editor: "SELECT", values: datetime},
  {label: "не равно", value: "NOT_EQUAL", type: "UPDATED_AT", editor: "SELECT", values: datetime},
  {label: "внутри", value: "BETWEEN", type: "UPDATED_AT", editor: "SELECT", values: datetime_period},
  {label: "до", value: "LEAST", type: "UPDATED_AT", editor: "SELECT", values: datetime},
  {label: "сейчас или до", value: "LEAST_OR_EQUAL", type: "UPDATED_AT", editor: "SELECT", values: datetime},
  {label: "после", value: "GREAT", type: "UPDATED_AT", editor: "SELECT", values: datetime},
  {label: "сейчас или после", value: "GREAT_OR_EQUAL", type: "UPDATED_AT", editor: "SELECT", values: datetime},
  {label: "пусто", value: "EMPTY", type: "UPDATED_AT", editor: "NO", values: []},
  {label: "не пусто", value: "NOT_EMPTY", type: "UPDATED_AT", editor: "NO", values: []},

  {label: "содержит", value: "CONTAINS", type: "FORMULA_STRING", editor: "STRING"},
  {label: "не равно", value: "NOT_CONTAINS", type: "FORMULA_STRING", editor: "STRING"},
  {label: "соответствует", value: "EQUAL", type: "FORMULA_STRING", editor: "STRING"},
  {label: "не соответствует", value: "NOT_EQUAL", type: "FORMULA_STRING", editor: "STRING"},
  {label: "пустое", value: "EMPTY", type: "FORMULA_STRING", editor: "NO"},
  {label: "не пусто", value: "NOT_EMPTY", type: "FORMULA_STRING", editor: "NO"},


  {label: "имя файла содержит", value: "CONTAINS", type: "ATTACHMENT", editor: "STRING"},
  {label: "тип файла", value: "EQUAL", type: "ATTACHMENT", editor: "SELECT"},
  {label: "пустое", value: "EMPTY", type: "ATTACHMENT", editor: "NO"},
  {label: "не пусто", value: "NOT_EMPTY", type: "ATTACHMENT", editor: "NO"},

  {label: "=", value: "EQUAL", type: "FORMULA_NUMBER", editor: "DECIMAL"},
  {label: "не равно", value: "NOT_EQUAL", type: "FORMULA_NUMBER", editor: "DECIMAL"},
  {label: ">", value: "GREAT", type: "FORMULA_NUMBER", editor: "DECIMAL"},
  {label: ">=", value: "GREAT_OR_EQUAL", type: "FORMULA_NUMBER", editor: "DECIMAL"},
  {label: "<", value: "LEAST", type: "FORMULA_NUMBER", editor: "DECIMAL"},
  {label: "=<", value: "LEAST_OR_EQUAL", type: "FORMULA_NUMBER", editor: "DECIMAL"},
  {label: "пусто", value: "EMPTY", type: "FORMULA_NUMBER", editor: "NO"},
  {label: "не пусто", value: "NOT_EMPTY", type: "FORMULA_NUMBER", editor: "NO"},
]

const conditions = [
  {label: "и", value: "AND"},
  {label: "или", value: "OR"}
]

const dateFormats = [
  {name: "LOCAL", format: "DD.MM.yyyy", spec_format: "dd.MM.yyyy"},
  {name: "FRIENDLY", format: "DD MMM yyyy", spec_format: "d MMM yyyy"},
  {name: "US", format: "MM/DD/yyyy", spec_format: "MM/dd/yyyy"},
  {name: "EUROPE", format: "DD/MM/yyyy", spec_format: "dd/MM/yyyy"},
  {name: "ISO", format: "yyyy-MM-DD", spec_format: "yyyy-MM-dd"}
]

const timeFormats = [
  {name: "AM_PM", format: "H:mm a", spec_format: "h:mm a"},
  {name: "FULL", format: "HH:mm", spec_format: "HH:mm"}
]

const dateFormatList = [
  {label: "Локальное", value: "LOCAL"},
  {label: "Дружелюбное", value: "FRIENDLY"},
  {label: "Американское", value: "US"},
  {label: "Европейское", value: "EUROPE"},
  {label: "ISO", value: "ISO"}
]

const timeFormatList = [
  {label: "12 часовой", value: "AM_PM"},
  {label: "24 часовой", value: "FULL"}
]

const durationInputDiscretList = [
  {label: "Минуты", value: "MINUTES"},
  {label: "Секунды", value: "SECONDS"},
  {label: "Миллисекунды", value: "MILLIECONDS"},
]

const durationTimeFormatList = [
  {label: "ЧЧ:ММ", value: "HH_MM"},
  {label: "ЧЧ:ММ:СС", value: "HH_MM_SS"},
  {label: "ЧЧ:ММ:СС:МММ", value: "HH_MM_SS_SSS"},
]

const dateTimeConstants = [
  {label: "Сегодня", value: "TODAY"},
  {label: "Завтра", value: "TOMMOROW"},
  {label: "Вчера", value: "YESTERDAY"}
]

const triggers = [
  {label: "Когда запись соответсвует условиям", value: NodeTriggerTypesEnum.CardInConditions},
  {label: "Когда запись появилась в виде", value: NodeTriggerTypesEnum.CardInView},
  {label: "Когда пустая запись создана", value: NodeTriggerTypesEnum.CardCreated},
  {label: "Когда запись с данными создана", value: NodeTriggerTypesEnum.CardCreatedWithValues},
  {label: "Когда запись обнавлена", value: NodeTriggerTypesEnum.CardUpdated},
  {label: "По расписанию", value: NodeTriggerTypesEnum.BySchedule},
  {label: "Пусто", value: NodeTriggerTypesEnum.Empty},
]

const actions = [
  {label: "Создать уведомление", value: NodeOutputTypesEnum.CreateNotification},
  {label: "Создать уведомление Telegram", value: NodeOutputTypesEnum.SendTelegramMessage},
  {label: "Создать новую запись", value: NodeOutputTypesEnum.CreateCard},
  {label: "Обновить запись", value: NodeOutputTypesEnum.UpdateCard},
  {label: "Отправить письмо", value: NodeOutputTypesEnum.SendEmail},
  {label: "Пустое", value: NodeOutputTypesEnum.Empty}
]


const intervals = [
  {label: "Минуты", value: "minutes"},
  {label: "Часы", value: "hours"},
  {label: "Дни", value: "days"},
  {label: "Недели", value: "weeks"},
  {label: "Месяцы", value: "months"},
  {label: "Единоразово", value: "one_time"}
]

const percisions = [
  { value: 0, label: '1'},
  { value: 1, label: '1.0'},
  { value: 2, label: '1.00'},
  { value: 3, label: '1.000'},
  { value: 4, label: '1.0000'},
  { value: 5, label: '1.00000'},
  { value: 6, label: '1.000000'},
  { value: 7, label: '1.0000000'},
  { value: 8, label: '1.00000000'}
];

const currencies = [
  { value: "", label: 'Без знака'},
  { value: "₽", label: 'Рубли'},
  { value: "$", label: 'Доллары'},
  { value: "€", label: 'Евро'},
  { value: "£", label: 'Фунты'},
];

const expressionTypes = [
  {value: "DECIMAL", label: "Число"},
  {value: "STRING", label: "Строка"},
  {value: "DATE", label: "Дата"}
]

const listOfFunctionsForFormula = [
  {id: "abs", display: "ABS", type: "math function", annatation: "ABS(field or value)"},
  {id: "max", display: "MAX", type: "math function"},
  {id: "min", display: "MIN", type: "math function"},
  {id: "power", display: "POWER", type: "math function"},
  {id: "sum", display: "SUM", type: "math function"},

  {id: "and", display: "AND", type: "logical function"},
  {id: "if", display: "IF", type: "logical function"},
  {id: "or", display: "OR", type: "logical function"},

  {id: "date", display: "DATE", type: "datetime function"},
  {id: "datevalue", display: "DATEVALUE", type: "datetime function"},
  {id: "day", display: "DAY", type: "datetime function"},
  {id: "edate", display: "EDATE", type: "datetime function"},
  {id: "hour", display: "HOUR", type: "datetime function"},
  {id: "minute", display: "MINUTE", type: "datetime function"},
  {id: "month", display: "MONTH", type: "datetime function"},
  {id: "now", display: "NOW", type: "datetime function"},
  {id: "second", display: "SECOND", type: "datetime function"},
  {id: "time", display: "TIME", type: "datetime function"},
  {id: "timevalue", display: "TIMEVALUE", type: "datetime function"},
  {id: "today", display: "TODAY", type: "datetime function"},
  {id: "weekday", display: "WEEKDAY", type: "datetime function"},
  {id: "year", display: "YEAR", type: "datetime function"},

  {id: "char", display: "CHAR", type: "text function"},
  {id: "clean", display: "CLEAN", type: "text function"},
  {id: "code", display: "CODE", type: "text function"},
  {id: "concantenate", display: "CONCATENATE", type: "text function"},
  {id: "fixed", display: "FIXED", type: "text function"},
  {id: "left", display: "LEFT", type: "text function"},
  {id: "len", display: "LEN", type: "text function"},
  {id: "lower", display: "LOWER", type: "text function"},
  {id: "proper", display: "PROPER", type: "text function"},
  {id: "rept", display: "REPT", type: "text function"},
  {id: "right", display: "RIGHT", type: "text function"},
  {id: "substitute", display: "SUBSTITUTE", type: "text function"},
  {id: "unichar", display: "UNICHAR", type: "text function"},
  {id: "unicode", display: "UNICODE", type: "text function"},
  {id: "upper", display: "UPPER", type: "text function"},

  {id: "first_word", display: "FIRST_WORD", type: "special function"},
  {id: "percent", display: "PERCENT", type: "special function"},
  {id: "read_digits", display: "READ_DIGITS", type: "special function"},
  {id: "remove_first_word", display: "REMOVE_FIRST_WORD", type: "special function"},
  {id: "word", display: "WORD", type: "special function"},
  {id: "word_count", display: "WORD_COUNT", type: "special function"},
  {id: "word_slice", display: "WORD_SLICE", type: "special function"},

  {id: "is_number", display: "ISNUMBER", type: "special function"},
  {id: "is_bool", display: "ISBOOL", type: "special function"},
  {id: "is_string", display: "ISSTRING", type: "special function"},
]


const nonEditableFieldTypes = [
  'FORMULA', 'LOOKUP', 'INSERTED_AT', 'UPDATED_AT', 'CREATED_BY', 'UPDATED_BY', 'RECORDID'
]

const formNotAvailableTypes = [
  'FORMULA', 'LOOKUP', 'INSERTED_AT', 'UPDATED_AT', 'CREATED_BY', 'UPDATED_BY', 'RECORDID'
]

const externalFormNotAvailableTypes = [
  'FORMULA', 'LOOKUP', 'INSERTED_AT', 'UPDATED_AT', 'CREATED_BY', 'UPDATED_BY', "COLLABORATOR"
]

const boardFormTypes = [
  {label: "Обычная", value: "COMMON"},
  {label: "Для процесса", value: "PROCESS"},
  {label: "Внешняя", value: "EXTERNAL"}
]


function getSummaryOptionsByType(field: Field) {
  switch (field.type) {
    case 'STRING':
    case 'URL':
    case 'EMAIL':
    case 'PHONE':
    case 'SELECT':
    case 'MULTISELECT':
    case 'COLLABORATOR':
    case 'CREATED_BY':
    case 'UPDATED_BY':
    case 'RELATION':
    case 'LOOKUP':
      return summaryTypes.filter(st => ['NONE', 'TOTAL', 'UNIQUE', 'EMPTY', 'FILLED', 'UNIQUE_PERCENT', 'EMPTY_PERCENT', 'FILLED_PERCENT'].includes(st.value))
    case 'CHECKBOX': 
      return summaryTypes.filter(st => ['NONE', 'TOTAL', 'EMPTY', 'FILLED', 'EMPTY_PERCENT', 'FILLED_PERCENT'].includes(st.value))
    case "FORMULA":
      if((field.attributes as Formula).expressionType === ExpressionType.String) {
        return summaryTypes.filter(st => ['NONE', 'TOTAL', 'UNIQUE', 'EMPTY', 'FILLED', 'UNIQUE_PERCENT', 'EMPTY_PERCENT', 'FILLED_PERCENT'].includes(st.value))
      } else if((field.attributes as Formula).expressionType === ExpressionType.Date) {
        return summaryTypes.filter(st => ['NONE', 'TOTAL', 'UNIQUE', 'EMPTY', 'FILLED', 'UNIQUE_PERCENT', 'EMPTY_PERCENT', 'FILLED_PERCENT'].includes(st.value))
      } else {
        return summaryTypes.filter(st => ['NONE', 'SUM', 'AVG', 'MAX', 'MIN', 'MEDIAN', 'DEVIATION', 'TOTAL', 'UNIQUE', 'EMPTY', 'FILLED', 'UNIQUE_PERCENT', 'EMPTY_PERCENT', 'FILLED_PERCENT'].includes(st.value))
      }  
    case 'NUMBER':
    case 'DURATION':
    case 'DECIMAL':
    case 'CURRENCY':
    case 'PERCENT':
      return summaryTypes.filter(st => ['NONE', 'SUM', 'AVG', 'MAX', 'MIN', 'MEDIAN', 'DEVIATION', 'TOTAL', 'UNIQUE', 'EMPTY', 'FILLED', 'UNIQUE_PERCENT', 'EMPTY_PERCENT', 'FILLED_PERCENT'].includes(st.value))
    case 'DATETIME':
    case 'INSERTED_AT':
    case 'UPDATED_AT':
      return summaryTypes.filter(st => ['NONE', 'MAX', 'MIN', 'TOTAL', 'UNIQUE', 'EMPTY', 'FILLED', 'UNIQUE_PERCENT', 'EMPTY_PERCENT', 'FILLED_PERCENT'].includes(st.value))
    default: return []
  }
}

const pageLayouts = [
  {label: "Пустой", value: LayoutEnum.Blank},
  {label: "Список записей", value: LayoutEnum.RecordsList},
  {label: "Таблица", value: LayoutEnum.Grid},
  {label: "Список", value: LayoutEnum.List},
  {label: "Календарь", value: LayoutEnum.Calendar},
  {label: "Канбан доска", value: LayoutEnum.Kanban},
  {label: "Форма", value: LayoutEnum.Form},
]

const panelBlocks = [
  {type: BlockTypeEnum.BlockCalendar, name: "Календарь", icon: <TbCalendarEvent />, description: "Описание", position: {x: null, y: null, w: 10, h: 10, minH: null, maxH: null, minW: null, maxW: null}},
  {type: BlockTypeEnum.BlockKanban, name: "Канбан доска", icon: <TbLayoutKanban />, description: "Описание", position: {x: null, y: null, w: 10, h: 10, minH: null, maxH: null, minW: null, maxW: null}},
  {type: BlockTypeEnum.BlockGrid, name: "Таблица", icon: <TbLayoutGrid />, description: "Описание", position: {x: null, y: null, w: 10, h: 10, minH: null, maxH: null, minW: null, maxW: null}},
  {type: BlockTypeEnum.BlockList, name: "Список", icon: <TbList />,  description: "Описание", position: {x: null, y: null, w: 10, h: 10, minH: null, maxH: null, minW: null, maxW: null}},
  // {type: BlockTypeEnum.BlockFilter, name: "Фильтр", icon: <TbFilter />, description: "Описание", position: {x: null, y: null, w: 10, h: 10, minH: null, maxH: null, minW: null, maxW: null}},
  {type: BlockTypeEnum.BlockSection, name: "Секция", icon: <TbTextCaption />, description: "Описание", position: {x: null, y: 1, w: 10, h: 10, minH: null, maxH: null, minW: null, maxW: null}},
  {type: BlockTypeEnum.BlockSummary, name: "Сводный", icon: <TbAbacus />,  description: "Описание", position: {x: null, y: null, w: 10, h: 10, minH: null, maxH: null, minW: null, maxW: null}},
  {type: BlockTypeEnum.BlockRecordsSelect, name: "Выбор записей", icon: <TbSelect />,  description: "Описание", position: {x: null, y: null, w: 10, h: 10, minH: null, maxH: null, minW: null, maxW: null}},
  {type: BlockTypeEnum.BlockChart, name: "График", icon: <TbChartBar />,  description: "Описание", position: {x: null, y: null, w: 10, h: 10, minH: null, maxH: null, minW: null, maxW: null}},
  // {type: BlockTypeEnum.BlockForm, name: "Форма", icon: <TbForms />,  description: "Описание", position: {x: null, y: null, w: 10, h: 10, minH: null, maxH: null, minW: null, maxW: null}}
]


export const requestMethods = [
  {name: "GET", id: "GET"},
  {name: "POST", id: "POST"},
  {name: "PUT", id: "PUT"},
  {name: "PATCH", id: "PATCH"},
  {name: "DELETE", id: "DELETE"},
]

export const bodyTypes = [
  {name: "Без содержимого", id: "BODY_NO"},
  {name: "Составной тип", id: "BODY_MULTIPART"},
  {name: "Тип данных медиа", id: "BODY_FROM_URL"},
  {name: "JSON", id: "BODY_JSON"},
  {name: "XML", id: "BODY_XML"},
  // {name: "GRAPHQL", id: "BODY_GRAPHQL"},
  {name: "Обычный текст", id: "BODY_PLAIN"},
  {name: "Другое", id: "BODY_OTHER"}
]

export const authTypes = [
  {name: "Без авторизации", id: "AUTH_NOAUTH"},
  {name: "По логину и паролю", id: "AUTH_BASIC"},
  {name: "По Bearer токену", id: "AUTH_BEARER"}
]

export const valueTypes = [
  {name: "Текст", id: "VALUE_CUSTOM"},
  // {name: "Константа", id: "VALUE_CONSTANT"},
  {name: "Значение из таблицы", id: "VALUE_FIELD"},
  // {name: "Переменная", id: "VALUE_VARIABLE"}
]

export const requestMode = [
  {name: "Добавление к существующим карточкам", id: "APPEND"},
  {name: "Обновление существующих карточек", id: "UPDATE"},
]

export const multipleOnError = [
  {name: "Игнорировать", id: "IGNORE"},
  {name: "Остановить", id: "STOP"},
  {name: "Повторить", id: "RETRY"},
]



export {
  datetime,
  datetime_period,
  getSummaryOptionsByType,
  conditions,
  operators,
  dateFormats,
  timeFormats,
  dateTimeConstants,
  durationInputDiscretList,
  dateFormatList,
  timeFormatList,
  durationTimeFormatList,
  triggers,
  actions,
  intervals,
  percisions,
  currencies,
  listOfFunctionsForFormula,
  expressionTypes,
  nodeFieldValueTypes,
  typeOptions,
  primaryTypeOptions,
  summaryTypes,
  boardFormTypes,
  nonEditableFieldTypes,
  formNotAvailableTypes,
  externalFormNotAvailableTypes,
  constantTypes,
  pageLayouts,
  panelBlocks
}