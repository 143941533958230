import { useState } from 'react';
import { TbCubePlus, TbSearch, TbSquareRoundedPlusFilled } from 'react-icons/tb';
import { useLocation } from 'react-router-dom';
import { Flow } from '../../../core/graphql/types';
import { useSpace } from '../../../core/providers/space';
import { FlowPreview } from './preview';
import { FlowFormCreate } from '../../../containers/Flows/form/create';
import { SimpleModal } from '../../modals/simple';
import { MatrMediumDialog } from '../../ui/portal';


const FlowsMenu = () => {
    const {flows} = useSpace();
    const [open, setOpen] = useState(false)
  
    function openCreateModal() {setOpen(true)}
    function closeCreateModal() {setOpen(false)}

    return (
        <>
            <div className='px-2 pt-2'>
                <div className="flex items-center text-base font-semibold text-slate-500 py-2 px-2 max-h-9 mb-1">
                    <span>Процессы</span>
                </div>
                <div className='px-1 flex items-center max-h-9 py-1 justify-between text-slate-600 rounded-sm mb-2'>
                    <div className='flex space-x-2'>
                        <TbCubePlus className='px-1 w-6 h-6 hover:bg-slate-200 rounded-sm' onClick={() => openCreateModal()}/>
                    </div>
                    <div className='flex space-x-2'>
                    <TbSearch className='px-1 w-6 h-6 hover:bg-slate-200 rounded-sm'/>
                    </div>
                </div>
                {flows.map((flow: Flow, idx: number) => (
                    <FlowPreview key={idx} flow={flow}/>
                ))}
            </div>
            {open && 
                <MatrMediumDialog open={open} onClose={closeCreateModal}>
                    <FlowFormCreate closeModal={closeCreateModal}/>
                </MatrMediumDialog>
            }
        </>
    );
};

export default FlowsMenu;