import React from 'react';
import { TbX } from 'react-icons/tb';
import { EdgeProps, getSmoothStepPath, useReactFlow } from 'reactflow';
import { useFlowContext } from '../../../../core/providers/flow';


const foreignObjectSize = 40;


export default function DeleteableEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  markerEnd,
}:EdgeProps) {
  const [edgePath, labelX, labelY] = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });
  const {disconnectNodes} = useFlowContext()
  const {getEdges} = useReactFlow()
  const onEdgeClick = (evt: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: string) => {
    evt.stopPropagation();
    let edge = getEdges().find(e => e.id === id)
    disconnectNodes(edge?.id!)
  };

  return (
    <>
      <path
        id={id}
        style={style}
        className="react-flow__edge-path"
        d={edgePath}
        markerEnd={markerEnd}
      />
      <foreignObject
        width={foreignObjectSize}
        height={foreignObjectSize}
        x={labelX - foreignObjectSize / 2}
        y={labelY - foreignObjectSize / 2}
        className="flex bg-transparent justify-center items-center w-8 h-8"
        requiredExtensions="http://www.w3.org/1999/xhtml"
      >
        <div className='flex justify-end items-center'>
          <button className="w-6 h-6 border bg-slate-200 rounded-full flex justify-center items-center" onClick={(event) => onEdgeClick(event, id)}>
            <TbX />
          </button>
        </div>
      </foreignObject>
    </>
  );
}