import type { FormFieldEditorProps } from './types';
import { DecimalIcon } from "../../icons";
import { Controller, useFormContext } from 'react-hook-form';
import NumberFormat, {NumberFormatValues} from 'react-number-format';


export default function FormDecimalEditor({formField, value}: FormFieldEditorProps) {
  const methods = useFormContext();
  const {control} = methods;
  const attr: any = formField.field.attributes;

  function handleChange(e: NumberFormatValues, onChange: { (...event: any[]): void; (arg0: number): void; }) {
    onChange(e?.floatValue ? e?.floatValue : null)
  }

  return (
    <div className="relative flex flex-col">
      <div className="flex items-center h-full space-x-2">
        <div className='text-slate-400'><DecimalIcon /></div>
        <label className="font-medium text-indigo-600 text-sm">{formField.name} {formField.required ? <span className="text-red-600">*</span> : ""}</label>
      </div>
      <div className="flex mt-2">
        <Controller
          key={formField.id}
          name={`${formField.fieldId}`}
          defaultValue={value ? value : null}
          control={control}
          rules={{required: formField.required}}
          render={({ field: {value, onChange} }) => (
            <NumberFormat
              autoFocus={false}
              allowNegative={attr.negative}
              decimalScale={attr.percision}
              className="matr-form-input"
              displayType="input"
              value={value}
              onValueChange={(values) => handleChange(values, onChange)}
            />
          )}
        />
      </div>
    </div>
  );
}