import { useCallback, useEffect, useState } from "react";
import { Block, BlockSectionOptions } from "../../../../../core/graphql/types";
import { usePanel } from "../../../../../core/providers/panel";

import { debounce } from "debounce";
import { Controller, useForm, useWatch } from "react-hook-form";
import useDeepCompareEffect from "use-deep-compare-effect";
import BlockEditor from "../../../../../components/ui/TextEditors/BlockEditor";

function SectionBlock({sectionBlock}:{sectionBlock: Block}) {
  const [isEditable, setIsEditable] = useState<boolean>(sectionBlock.permissions?.canEdit!)
  const {control, getValues, reset, watch, formState} = useForm({defaultValues: sectionBlock})
  const {updateBlock} = usePanel()

  useEffect(() => {
    setIsEditable(sectionBlock.permissions?.canEdit!)
    reset(sectionBlock)
  },[sectionBlock])


  const debouncedSave = useCallback(
    debounce(() => {
      updateBlock(getValues() as Block)
    }, 1000),
    []
  );

  const watchedData = useWatch({
    control: control,
    defaultValue: sectionBlock
  });

  useDeepCompareEffect(() => {
    if (formState.isDirty) {
      debouncedSave();
    }
  }, [watchedData]);

  function handleChange(json: any, onChange: any) {
    onChange(JSON.stringify(json))
  }

  return (
    <>
      {(sectionBlock.options as BlockSectionOptions).showTitle ? 
        <div className="w-full h-full">
          <div className="flex-none h-full flex w-full text-lg text-slate-800 font-semibold items-center truncate">{(sectionBlock.options as BlockSectionOptions)?.title!}</div>
        </div>
        :
        <></>
      }
      <div className="relative">
        <Controller
          name="options.content"
          control={control}
          render={({ field: { onChange, value }}) => (
            <BlockEditor onChange={(e) => handleChange(e, onChange)} value={value} tokens={[]} editable={isEditable}/>
          )}
        />
      </div>
    </>
  );
}

export default SectionBlock;