/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import Select, { SingleValue } from "react-select";
import { Board, Field, Lookup, Relation } from "../../../../../core/graphql/types";
import { useBoard } from "../../../../../core/providers/board";
import { customStyles } from "../../../../../utils";
import { useSpaceStore } from "../../../../../core/store/store";


function FieldLookup({attributes}: {attributes: Lookup}): JSX.Element {
  const {initId, initType} = useBoard();
  const init: string = `${initType}:${initId}`;
  const store = useSpaceStore()
  const {sources, fields} = store.data[init];

  const methods = useFormContext();
  const { register, watch, control, unregister, setValue, resetField, formState: {errors} } = methods;
  const [ board, setBoard ] = useState<string | undefined>('');
  const [ relationsList, setRelationsList] = useState<Field[] | any>([])
  const [ fieldsList, setFieldsList] = useState<Field[] | any>([])

  useEffect(() => {
    unregister('attributes');
  },[])

  useEffect(() => {
    register('attributes.type');
    setValue('attributes.type', "LOOKUP");

    setValue('attributes.relation', attributes.relation ? attributes.relation : "");
    setValue('attributes.boardId', attributes.boardId ? attributes.boardId : "");
    setValue('attributes.fieldId', attributes.fieldId ? attributes.fieldId : "");
    setValue('attributes.fieldType', attributes.fieldType ? attributes.fieldType : "");
  },[attributes])

  useEffect(() => {
    if(!fields) {
      return
    }

    const forList: Field[] = []

    fields.map((field: Field) => {
      if( field.type === "RELATION" ) {  forList.push(field) }
    })
    setRelationsList(forList)
  },[fields])

  useEffect(() => {
    if(!sources || !sources.length || !watch('attributes.boardId')) { return }

    setFieldsList(sources.find((board: Board) => board?.id! === watch('attributes.boardId'))!.fields)
  },[sources, watch('attributes.boardId')])


  const handelChangeRelation = (val: SingleValue<Field>, onChange: { (...event: any[]): void; (arg0: any): void; }) => {
    console.log(val)
    onChange(val?.id)
    setBoard((val?.attributes as Relation).boardId)
    setValue('attributes.boardId', (val?.attributes as Relation).boardId)
    resetField('attributes.fieldId')
    resetField('attributes.fieldType')
  }

  const handelChangeField = (val: SingleValue<Field>, onChange: { (...event: any[]): void; (arg0: any): void; }) => {
    onChange(val?.id)
    setValue('attributes.fieldType', val?.type)
  }

  if(!relationsList || !relationsList.length) { return (<div className="flex py-4 text-sm justify-center">У вас нет полей "Связь с источником"</div>)}
  if(!sources || !sources.length) { return (<div className="flex py-4 text-sm justify-center">Подключите источники, чтобы создавать связи с ними.</div>)}

  return (
    <>
      {errors.attributes && <span className="text-red-600 text-xs font-normal">Oбязательное поле.</span>}
      <div className="relative">
        <label className="font-medium text-gray-600 text-sm">Выберите колонку со связью:</label>
        <Controller
          name="attributes.relation"
          control={control}
          rules={{ required: true }}
          render={({ field: { onChange, value, ref }}) => (
            <div className="mt-2">
              <Select
                styles={customStyles}
                classNamePrefix="matr_select_form"
                placeholder="Выберите колонку со связью"
                getOptionLabel={(option) => option.title}
                getOptionValue={(option) => option.id}
                value={value ? relationsList?.find((per: Field) => per.id === value) : ""}
                onChange={val => handelChangeRelation(val, onChange)}
                options={relationsList}
              />
            </div>
          )}
        />
      </div>
      { watch('attributes.boardId') ? 
      <div className="relative">
        <label className="font-medium text-gray-600 text-sm">Выберите поле для отображения:</label>
        <Controller
          name="attributes.fieldId"
          control={control}
          rules={{ required: true }}
          render={({ field: { onChange, value, ref }}) => (
            <div className="mt-2">
              <Select
                styles={customStyles}
                classNamePrefix="matr_select_form"
                placeholder="Выберите поле"
                getOptionLabel={(option) => option.title}
                getOptionValue={(option) => option.id}
                defaultValue={fieldsList?.find((per: Field) => per.id === value)}
                value={fieldsList?.find((per: Field) => per.id === value)}
                onChange={val => handelChangeField(val, onChange)}
                options={fieldsList}
              />
            </div>
          )}
        />
      </div>
      : <></>
      }
    </>
  );
}
export default FieldLookup;