import { useState } from "react";
import { Field, LinkItem, RelationValue } from "../../../../core/graphql/types";
import { HiXCircle, HiPlus} from 'react-icons/hi';
import { splitNames } from "../../../../utils";
import {ModalRelations, PopoverRelations} from "../../../modals/relations";

interface RelationInputProps {
  relations: LinkItem[];
  onChange: any;
  foreginFieldId?: string; 
  fieldId: string;
  boardId: string;
}

function LookupInput({fieldId, boardId, foreginFieldId, relations, onChange}: RelationInputProps) {
  const [ open, setOpen ] = useState(false);
  const [ openEdit, setOpenEdit ] = useState(false);

  function openCardModal(element: LinkItem) { 
    setOpenEdit(true)
  };

  function closeCardModal() { 
    setOpenEdit(false)
  };

  function handleChange(e: any) {
    let el = {
      foreginCardId: e.id,
      foreginId: e.fields.find((f:any) => f.fieldId === foreginFieldId).id,
      foreginName: foreginFieldId && e.fields.find((f:any) => f.fieldId === foreginFieldId).value ? transformRelationValue(e.fields.find((f:any) => f.fieldId === foreginFieldId).value) : e.fields.find((f:any) => f.primary === true).value?.toString(),
      foreginType: e.fields.find((f:any) => f.fieldId === foreginFieldId).type
    }
    onChange(el)
  }

  function transformRelationValue(value: any) {
    if(typeof value === 'object') {
      return value.map((v: any) => v.foreginName).join(", ")
    } else {
      return value
    }
  }

  return (
    <>
      <div className="w-full h-full focus:outline-none">
        <div className="flex w-full bg-white items-center">
          <div className="flex flex-row py-1 px-2 items-center w-80 bg-gray-100 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 focus:outline-none" >
            <div className="flex-none w-8">
            <PopoverRelations boardId={boardId} fieldId={fieldId} foreginFieldId={foreginFieldId} relations={relations} onChange={handleChange} />
            </div>
         
          { 
            relations ? 
              <div className="grow overflow-hidden">
                <div className="flex flex-row space-x-2">
                  {relations.map((element: any) => { 
                    return element.foreginCardId ? 
                      <div className="flex max-w-min bg-blue-200 text-blue-800 h-5 py-0.5 rounded-sm pl-2 pr-1 items-center truncate" key={element.foreginCardId}>
                        <span className='truncate' onClick={()=>openCardModal(element)}>{element.foreginName ? element.foreginName as string : "Без названия"}</span>
                        <HiXCircle className="ml-1 h-4 w-4 z-10" onClick={() => onChange(element)}></HiXCircle>
                      </div> : <></>
                  })}
                </div>
              </div>
            : 
            <></>
          }
          </div>
        </div>
      </div>
      {/* {open ? <ModalRelations open={open} close={closeRelationsList} boardId={boardId} fieldId={fieldId} relations={relations} onChange={handleChange} /> : <></>} */}
      {/* {openEdit ? <CardModal open={openEdit} close={closeCardModal} boardId={column.attributes.boardId} cardId={relation?.foreginCardId!} fromFieldId={column.id}/> : <></>} */}
    </>
  )
} 

export default LookupInput;