/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import Select, {MultiValue} from 'react-select';
import { SmallSpinner } from "../../../../components/loaders";
import { Group, useGetGroupQuery, useUpdateGroupMutation, useListGroupsQuery, useRolesForSelectQuery, ForSelect } from "../../../../core/graphql/types";

export default function UpdateGroup({group, onChange, closeModal}: {group: Group, onChange: any, closeModal: any}) {
  const { register, handleSubmit, watch, control, setValue, formState: { errors } } =  useForm();
  const [,updateGroup] = useUpdateGroupMutation()
  const [{data, fetching, error}] = useListGroupsQuery();
  const [{data: dataRoles, fetching: fetchingRoles, error: errorRoles}] = useRolesForSelectQuery();
  const [{data: dataGroup, fetching: fetchingGroup, error: errorGroup},] = useGetGroupQuery({variables: {id: group?.id!}})

  useEffect(() => {
    if(!dataGroup?.group || !data?.listGroups || !dataRoles?.rolesForSelect) {
      return
    }
    setValue("name", dataGroup?.group.name)
    setValue("addable", dataGroup?.group.addable)
    setValue("parent", data?.listGroups ? data.listGroups.find((g: any) => g.id! === dataGroup?.group?.parentId!) : [] )
    setValue("roles", dataGroup?.group.roles ? dataGroup?.group.roles : [] )
  },[dataGroup?.group, data?.listGroups, dataRoles?.rolesForSelect])

  const onSubmit = handleSubmit(data => {
    console.log(data)
    updateGroup({id: group.id!, name: data.name!, parentId: data.parent.id, addable: data.addable, roles: data.addable ? [] : data.roles}).then(res => {
      onChange(res.data?.updateGroup);
      closeModal();
    })
  });


  const handleChangeRoles = (e: MultiValue<ForSelect | null>, onChange: (...event: any[]) => void) => {
    onChange(e.map(r => r?.id))
  }

  if(fetching || fetchingGroup || fetchingRoles) return <SmallSpinner />

  return (
    <form onSubmit={onSubmit} className="flex flex-col relative w-full space-y-4">
      <h2 className="text-lg font-semibold">Редактирование группы</h2>
      <div>
        <label className="font-medium text-gray-600 text-sm">Название:</label>
        <input {...register("name", { required: true })} className="text-sm form-input mt-1.5 py-2.5 block w-full rounded-sm bg-gray-100 border-0 focus:bg-white focus:ring focus:ring-indigo-200 focus:ring-opacity-50"/>
        {errors.name && <span className="text-red-600 text-xs font-normal">"Название" обязательное поле</span>}
      </div>
      <div className="relative">
        <Controller
          name="parent"
          control={control}
          rules={{ required: true }}
          render={({ field: { onChange, value, ref } }) => (
            <Select
              styles={{control: () => ({border: 0})}}
              classNamePrefix="matr_select_custom"
              placeholder="В группе"
              getOptionLabel={(option) => option?.name}
              getOptionValue ={(option)=>option.id}
              value={value}
              onChange={onChange}
              options={data?.listGroups ? data.listGroups : []} />
          )} />
      </div>
      <div className="relative inline-flex">
        <label className="font-medium text-gray-600 text-sm flex items-center">
          <input 
            {...register("addable")}
            className="form-checkbox rounded-sm focus:outline-none focus:outline-offset-0 mr-2"
            type="checkbox" 
            placeholder="Добавление в группу" 
          />
          Все роли могут добавлять в группу
        </label>
      </div>
      {watch("addable") === false ?
      <div className="relative">
        <Controller
          name="roles"
          control={control}
          render={({ field: { onChange, value, ref } }) => (
            <Select
              styles={{control: () => ({border: 0})}}
              isMulti={true}
              isClearable={false}
              classNamePrefix="matr_select_custom"
              placeholder="Роли"
              value={dataRoles?.rolesForSelect?.filter(r => value.includes(r?.id))}
              getOptionLabel ={(option)=>option?.name!}
              getOptionValue ={(option)=>option?.id!}
              onChange={(e) => handleChangeRoles(e, onChange)}
              options={dataRoles?.rolesForSelect ? dataRoles.rolesForSelect : []} />
          )} />
      </div> : <></>}
      <div className="flex justify-between w-full pt-6">
        <button
          type="button"
          className="inline-flex justify-end px-4 py-2 text-sm font-medium text-indigo-700 bg-indigo-100 border border-transparent rounded-sm hover:bg-indigo-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-indigo-500"
          onClick={closeModal}
        >
          Отменить
        </button>
        <button
          type="submit"
          className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-indigo-700 border border-transparent rounded-sm hover:bg-indigo-800 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-indigo-500"
        >
          Обновить
        </button>
      </div>
    </form>
  )
}