import { useEffect, useRef, useState } from "react";
import type { RenderCellProps, RenderEditCellProps, useRowSelection } from 'react-data-grid';
import { Column } from "react-data-grid";
import { HiPlus, HiXCircle } from "react-icons/hi";
import { Field, FieldType, LinkItem, Maybe, Relation, RelationValue, ValuePayload, useAddRelationMutation, useCreateCardInRelationBoardMutation, useRemoveRelationMutation, CardValueInput } from '../../../../core/graphql/types';
import { useBoard } from "../../../../core/providers/board";
import { useSpaceStore } from "../../../../core/store/store";
import { splitNames } from "../../../../utils";
import CardModal from "../../../Cards";
import BaseRelationList from "../../../Cards/Lists/BaseFormRelationsList";
import { MatrPopover } from "../../../ui/portal";
import { GroupCell } from "../rows/group";
import { HeaderRenderer } from "../rows/header";
import SummaryRows from "../rows/summary";
import { Row } from "../types";



interface BaseRenderCellProps<TRow, TSummaryRow> extends RenderCellProps<TRow, TSummaryRow> {
  field: Field;
  canEdit: boolean;
}

interface BaseRenderEditCellProps<TRow, TSummaryRow> extends RenderEditCellProps<TRow, TSummaryRow> {
  field: Field;
}


// Column

export function relationColumn({ field, canEditInline }: { field: Field; canEditInline: boolean }): Column<Row, any> {
  return {
    key: field.id,
    name: field.title,
    width: field.width,
    resizable: true,
    frozen: field.primary ? true : false,
    editable: canEditInline,
    renderHeaderCell: (p) => { return <HeaderRenderer field={field} {...p}/> },
    renderCell: (p) => { return <RelationCell {...p} field={field} canEdit={canEditInline}/> },
    // renderEditCell: (p) => { return <RelationEditCell {...p} field={field}/> },
    renderGroupCell: (p) => { return <GroupCell {...p} field={field}/> },
    renderSummaryCell: (p) => { return <SummaryRows {...p} field={field}/> },
    editorOptions: {
      displayCellContent: true
    }
  }
};

// Cell

export function RelationCell<Row, SummaryRow>(props: BaseRenderCellProps<Row, SummaryRow>) {
  const {initId, initType, inBlock, fields, setEditMode, setExternalModalIsOpened, setModal} = useBoard();
  const init: string = `${initType}:${initId}`;
  const {row, column, tabIndex, field, canEdit, onRowChange} = props
  const attr = (field.attributes as Relation);
  const value = (row[column.key as keyof Row] as unknown as RelationValue)?.links! as Maybe<LinkItem>[]
  const cellRef = useRef<any>(null);
  const [selection, setSelection] = useState<LinkItem[] | any>([]);
  const [ open, setOpen ] = useState(false);
  const [ relation, setRelation ] = useState<LinkItem>()
  const [ relationCardId, setRelationCardId] = useState<any>("")

  const [,addRelationMutation] = useAddRelationMutation()
  const [,removeRelationMutation] = useRemoveRelationMutation()
  const [,createNewCardInRelationBoard] = useCreateCardInRelationBoardMutation()

  let relationBoardId = (field.attributes as Relation).boardId;

  function openRelations() { setOpen(true) };
  function closeRelations() { setOpen(false)};

  useEffect(() => {
    if(tabIndex === 0) {
      setSelection(
        (row[column.key as keyof Row] as unknown as RelationValue).links && 
        (row[column.key as keyof Row] as unknown as RelationValue).links!.length > 0 &&
        (row[column.key as keyof Row] as unknown as RelationValue).links![0]?.valueId ? 
        (row[column.key as keyof Row] as unknown as RelationValue).links : []
      )
    }
  },[column.key, row, tabIndex])

  function addRelation(item: LinkItem) {
    const forSave: any = {
      ...item, 
      fromValueId: (row[column.key as keyof Row] as unknown as RelationValue).id,
      fromValueType: field.type,
      throughValueId: attr.foreginRelationFieldId
    }
    addRelationMutation(forSave)
  }

  function removeRelation(item: LinkItem) {
    const forDelete: any = {
      valueId: item.valueId, 
      fromValueId: (row[column.key as keyof Row] as unknown as RelationValue).id
    }
    removeRelationMutation(forDelete)
  }
  
  function handleChange(relationCard: { id: any; fields: any[]; }) {
    const primary = relationCard.fields.find(v => v.primary === true)
    const relation = {
      valueId: primary.id, 
    }
    processUpdate(relation)
  }

  function processUpdate(item: LinkItem) {
    if (!selection.some((current: LinkItem) => current.valueId === item.valueId)) {
      if (!attr.multiple) {
        setSelection([item]);
        addRelation(item)
        onRowChange({ ...row, [column.key]: {...row[column.key as keyof Row], links: [item]}})
      } else {
        setSelection([...selection, item]);
        addRelation(item)
        onRowChange({ ...row, [column.key]: {...row[column.key as keyof Row], links: [...selection, item]}})
      }
    } else {
      let selectionAfterRemoval = selection;
      selectionAfterRemoval = selectionAfterRemoval.filter(
        (current: LinkItem) => current.valueId !== item.valueId
      );
      setSelection([...selectionAfterRemoval]);
      removeRelation(item)
      onRowChange({ ...row, [column.key]: {...row[column.key as keyof Row], links: [...selectionAfterRemoval]}})
    }
  }


  function addNewCardToRelationBoard() {
    let pfid = fields.find((f: Field) => f.primary === true)!.id
    let pv = (row[pfid as keyof Row] as unknown as ValuePayload)

    let value = {
      valueId: pv.id, // id текущего первичного значени
      // valueLinkFieldId: column.key,
    }

    createNewCardInRelationBoard({
      boardId: attr.boardId,
      values: [{
        fieldId: attr?.foreginRelationFieldId!,
        type: FieldType.Relation,
        links: [value]
      }]
    }).then(resp => {
      if(resp.error) {
        return
      }

      setRelationCardId(resp.data?.createCardWithValues?.id)
      setExternalModalIsOpened(true, init)
      setModal(
        <CardModal 
          open={true} 
          close={closeCardModal} 
          boardId={relationBoardId} 
          cardId={resp.data?.createCardWithValues?.id!} 
          fromFieldId={column.key}
          initiator="RELATION_CELL"
        />, 
        init
      )
      closeRelations()
    })
  }

  function closeCardModal() {
    setExternalModalIsOpened(false, init)
  }
  
  function openCardModal(element: LinkItem) {
    setRelationCardId(element?.value?.cardId!)
    setRelation(element)
    if(inBlock) {
      setExternalModalIsOpened(true, init)
      setModal(
        <CardModal 
          open={true} 
          close={closeCardModal} 
          boardId={relationBoardId} 
          cardId={element?.value?.cardId!} 
          fromFieldId={column.key}
          initiator="RELATION_CELL"
        />, 
        init
      )
    } else {
      setExternalModalIsOpened(true, init)
      setModal(
        <CardModal 
          open={true} 
          close={closeCardModal} 
          boardId={relationBoardId} 
          cardId={element?.value?.cardId!} 
          fromFieldId={column.key}
          initiator="RELATION_CELL"
        />, 
        init
      )
    }
  }


  return (
    <>
      <div className="flex my-2 px-2" ref={cellRef}>
        {
          tabIndex == 0 && canEdit ?
          <div className="bg-gray-300 w-5 h-5 flex items-center px-1 rounded-sm mr-1"  onClick={() => openRelations()}>
            <HiPlus className="h-4 w-4" ></HiPlus>
          </div> 
          :
          <></>
        }

        <div className="flex space-x-1 text-sm truncate">
          {value && Array.isArray(value) && value.length ? 
            value.map((element, idx) => {
              if(element?.valueId!) {
                return (
                  <div className="flex max-w-min bg-pink-200 text-pink-800 h-5 py-0.5 rounded-sm pl-2 pr-1 items-center truncate" key={element.valueId}>
                    {tabIndex == 0 && canEdit ? 
                      <>
                        <span className='truncate text-sm' onClick={()=>openCardModal(element)}>{element.value?.value ? splitNames(element.value.value as string) : "Без названия"}</span>
                        <HiXCircle className="ml-1 h-4 w-4 z-10" onClick={() => processUpdate(element)}></HiXCircle> 
                      </>
                      : 
                        <span className='truncate text-sm'>{element.value?.value ? splitNames(element.value.value as string) : "Без названия"}</span>
                    }
                  </div>
                )
              }
            })
            : 
            <></>
          }
        </div>
      </div>
      {open &&
        <MatrPopover onClose={closeRelations} reference={cellRef.current} placement="bottom-end">
          <BaseRelationList
            boardId={relationBoardId} 
            fieldId={field.id} 
            links={selection} 
            onChange={handleChange} 
            addNewCardToRelationBoard={addNewCardToRelationBoard}
            canAdd={true}
          />
        </MatrPopover>
      }
    </>
  )
}

// Cell Editor

export function RelationEditCell<Row, SummaryRow>({field, row, column, onRowChange, onClose}: BaseRenderEditCellProps<Row, SummaryRow>) {
  const {initId, initType, inBlock, fields, setEditMode, setExternalModalIsOpened, setModal} = useBoard();
  const init: string = `${initType}:${initId}`;


  const cellRef = useRef<any>(null);
  let relationBoardId = (field.attributes as Relation).boardId;
  const [ open, setOpen ] = useState(false);
  const [ relation, setRelation ] = useState<LinkItem>()
  const [ relationCardId, setRelationCardId] = useState<any>("")
  const attr = (field.attributes as Relation);
  const [selection, setSelection] = useState<LinkItem[] | any>(
    (row[column.key as keyof Row] as unknown as RelationValue).links && 
    (row[column.key as keyof Row] as unknown as RelationValue).links!.length > 0 &&
    (row[column.key as keyof Row] as unknown as RelationValue).links![0]?.valueId ? 
    (row[column.key as keyof Row] as unknown as RelationValue).links : []
  );

  const [,addRelationMutation] = useAddRelationMutation()
  const [,removeRelationMutation] = useRemoveRelationMutation()
  const [,createNewCardInRelationBoard] = useCreateCardInRelationBoardMutation()

  useEffect(() => {
    setSelection(
      (row[column.key as keyof Row] as unknown as RelationValue).links && 
      (row[column.key as keyof Row] as unknown as RelationValue).links!.length > 0 &&
      (row[column.key as keyof Row] as unknown as RelationValue).links![0]?.valueId ? 
      (row[column.key as keyof Row] as unknown as RelationValue).links : []
    )
  },[column.key, row])

  function openRelations() { 
    setOpen(true) 
  };

  function closeRelations() { 
    setEditMode(false, init)
    setOpen(false)
  };

  function addRelation(item: LinkItem) {
    const forSave: any = {
      ...item, 
      fromValueId: (row[column.key as keyof Row] as unknown as RelationValue).id,
      fromValueType: field.type,
      throughValueId: attr.foreginRelationFieldId
    }
    addRelationMutation(forSave)
  }

  function removeRelation(item: LinkItem) {
    const forDelete: any = {
      valueId: item.valueId, 
      fromValueId: (row[column.key as keyof Row] as unknown as RelationValue).id
    }
    removeRelationMutation(forDelete)
  }
  
  function handleChange(relationCard: { id: any; fields: any[]; }) {
    const primary = relationCard.fields.find(v => v.primary === true)
    const relation = {
      valueId: primary.id, 
    }
    processUpdate(relation)
  }

  function processUpdate(item: LinkItem) {
    if (!selection.some((current: LinkItem) => current.valueId === item.valueId)) {
      if (!attr.multiple) {
        setEditMode(true, init)
        setSelection([item]);
        addRelation(item)
        onRowChange({ ...row, [column.key]: {...row[column.key as keyof Row], links: [item]}})
      } else {
        setEditMode(true, init)
        setSelection([...selection, item]);
        addRelation(item)
        onRowChange({ ...row, [column.key]: {...row[column.key as keyof Row], links: [...selection, item]}})
      }
    } else {
      let selectionAfterRemoval = selection;
      selectionAfterRemoval = selectionAfterRemoval.filter(
        (current: LinkItem) => current.valueId !== item.valueId
      );
      setEditMode(true, init)
      setSelection([...selectionAfterRemoval]);
      removeRelation(item)
      onRowChange({ ...row, [column.key]: {...row[column.key as keyof Row], links: [...selectionAfterRemoval]}})
    }
  }


  function addNewCardToRelationBoard() {
    setEditMode(true, init)
    let pfid = fields.find((f: Field) => f.primary === true)!.id
    let pv = (row[pfid as keyof Row] as unknown as ValuePayload)

    let value = {
      valueId: pv.id, // id текущего первичного значени
      // valueLinkFieldId: column.key,
    }

    createNewCardInRelationBoard({
      boardId: attr.boardId,
      values: [{
        fieldId: attr?.foreginRelationFieldId!,
        type: FieldType.Relation,
        links: [value]
      }]
    }).then(resp => {
      if(resp.error) {
        return
      }

      setRelationCardId(resp.data?.createCardWithValues?.id)
      setExternalModalIsOpened(true, init)
      setModal(
        <CardModal 
          open={true} 
          close={closeCardModal} 
          boardId={relationBoardId} 
          cardId={resp.data?.createCardWithValues?.id!} 
          fromFieldId={column.key}
          initiator="RELATION_CELL"
        />, 
        init
      )
      closeRelations()
    })
  }

  function closeCardModal() {
    setEditMode(true, init)
    onRowChange({ ...row, [column.key]: {...row[column.key as keyof Row], links: selection}})
    setExternalModalIsOpened(false, init)
  }
  
  function openCardModal(element: LinkItem) {
    setRelationCardId(element?.value?.cardId!)
    setRelation(element)
    if(inBlock) {
      setExternalModalIsOpened(true, init)
      setModal(
        <CardModal 
          open={true} 
          close={closeCardModal} 
          boardId={relationBoardId} 
          cardId={element?.value?.cardId!} 
          fromFieldId={column.key}
          initiator="RELATION_CELL"
        />, 
        init
      )
    } else {
      setExternalModalIsOpened(true, init)
      setModal(
        <CardModal 
          open={true} 
          close={closeCardModal} 
          boardId={relationBoardId} 
          cardId={element?.value?.cardId!} 
          fromFieldId={column.key}
          initiator="RELATION_CELL"
        />, 
        init
      )
    }
  }

  return (
    <>
      <div className="w-full z-auto h-full focus:outline-none" onBlur={() => onClose(true)} ref={cellRef}>
        <div className="flex w-full items-center">
          <div className="truncate flex flex-row flex-nowrap p-2 space-x-2 z-10 w-full rounded-sm" >
            <div className="bg-gray-300 w-5 h-5 flex items-center px-1 rounded-sm"  onClick={() => openRelations()}>
              <HiPlus className="h-4 w-4" ></HiPlus>
            </div> 
            { selection.length ? 
              selection.map((element: LinkItem) => { 
                return element.valueId ? 
                  <div className="flex max-w-min bg-pink-200 text-pink-800 h-5 py-0.5 rounded-sm pl-2 pr-1 items-center truncate" key={element.valueId}>
                    <span className='truncate text-sm' onClick={()=>openCardModal(element)}>{element.value?.value ? splitNames(element.value.value as string) : "Без названия"}</span>
                    <HiXCircle className="ml-1 h-4 w-4 z-10" onClick={() => processUpdate(element)}></HiXCircle>
                  </div> : <></>
            }) : <></> }
          </div>
        </div>
      </div>
      {open &&
        <MatrPopover onClose={closeRelations} reference={cellRef.current} placement="bottom-end">
          <BaseRelationList
            boardId={relationBoardId} 
            fieldId={field.id} 
            links={selection} 
            onChange={handleChange} 
            addNewCardToRelationBoard={addNewCardToRelationBoard}
            canAdd={true}
          />
        </MatrPopover>
      }
    </>
  )
}
