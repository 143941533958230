import React, {Fragment, memo} from "react";
import { Transition, Popover } from "@headlessui/react";
import { HiDotsHorizontal } from "react-icons/hi";

export interface PopoverMenuWrapperProps {
  config: {
    button: React.ReactElement<any>, 
    actions: PopoverMenuAction[] | any[]
  };
  item: any
}

export interface PopoverMenuAction {
  title: string,
  icon?: React.ReactElement<any>,
  onClick: any;
}

export const PopoverWrapper: React.FC<PopoverMenuWrapperProps> = memo(({config, item}) => {
  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          <Popover.Button
            className={`
              ${open ? '' : 'text-opacity-90'}
              group inline-flex items-center rounded-sm px-2 py-2 text-base font-medium text-slate-800 hover:text-opacity-80 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
          >
            {React.cloneElement(config.button as React.ReactElement<any>)}
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute right-0 z-10 mt-3 w-min max-w-min transform">
              <div className="bg-white shadow-md ring ring-slate-200 rounded-sm flex flex-col">
              {config.actions.map((action: PopoverMenuAction, i: number) => (
                <div key={i} onClick={() => action.onClick(item)} className="flex flex-row items-center space-x-2 pl-2 pr-4 py-1.5 text-sm hover:bg-blue-200 hover:text-blue-600">
                  {!!action.icon && action.icon ? action.icon : <></>}
                  <div>{action.title}</div>
                </div>
              ))}
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  )
});


