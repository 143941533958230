import { Block, BlockFieldOptions, BlockSourceField, BlockTypeEnum, FieldType } from "../../../../../core/graphql/types";
import { usePanel } from "../../../../../core/providers/panel";
import EditableField from "./EditableField";


function FieldBlock({block}:{block: Block}) { 
  const {page} = usePanel()
  const formBlock = page!.blocks!.find(b => b?.id == block.parentId && b?.type == BlockTypeEnum.BlockForm)

  return (
    <>
      {formBlock ?
      <div className="h-full w-full flex">
        <EditableField block={block} formBlock={formBlock!}/>
      </div>
      :
      <></>}
    </>
  )
}

export default FieldBlock;