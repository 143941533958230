import React, {useEffect} from "react";
import { useFormContext } from "react-hook-form";

interface FieldCheckboxProps {
  type: string;
  checkedText?: string;
  uncheckedText?: string;
}

function FieldCheckbox({attributes}: FieldCheckboxProps | any) {
  const methods = useFormContext();
  const {register, unregister, setValue} = methods;

  useEffect(() => {
    unregister('attributes');
  },[])

  useEffect(() => {
    register('attributes.type');
    setValue('attributes.type', "CHECKBOX");
    // setValue('attributes.checkedText', attributes.checkedText ? attributes.checkedText : "");
    // setValue('attributes.uncheckedText', attributes.uncheckedText ? attributes.uncheckedText : "");
  },[attributes])


  return (
    <>
      {/* <div className="relative">
        <label className="font-medium text-gray-600 text-sm">Текст в активном состоянии:</label>
        <input {...register("attributes.checkedText")}
          className="text-sm form-input mt-2 py-2.5 block w-full rounded-md bg-gray-100 border-0 focus:bg-white focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          placeholder="Введите значение для активного состояния"
        />
      </div>
      <div className="relative">
        <label className="font-medium text-gray-600 text-sm">Текст в неактивном состоянии:</label>
        <input {...register("attributes.uncheckedText")}
          className="text-sm form-input mt-2 py-2.5 block w-full rounded-md bg-gray-100 border-0 focus:bg-white focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          placeholder="Введите значение для неактивного состояния"
        />
      </div> */}
    </>
  );
}
export default FieldCheckbox;