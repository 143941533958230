import { Droppable } from "react-beautiful-dnd";
import { useEffect, useState } from 'react';
import { hashCode, hexToRGB, intToRGB } from "../../../../utils";
import { TbPlus } from "react-icons/tb";
import { useBoard } from "../../../../core/providers/board";
import { BlockSourceField, BlockSourceTypeEnum, BoardViewOptionsInput, FieldType, Relation, useCreateCardWithValuesMutation } from "../../../../core/graphql/types";

const Column = ({data, children}:any) => {
  const {initType, initId, inBlock, board, block, cards, fields, values, relationPrimaryKey, currentView, setCurrentCardId, setCurrentCardPrimaryKey, setCurrentCard, setCardModalIsOpened, setModal} = useBoard()
  const [winReady, setWinReady] = useState(false);
  const [,createCardWithValues] = useCreateCardWithValuesMutation()
  useEffect(() => {
    setWinReady(true);
  }, [])

  function addTask(): void {
    if(inBlock && block?.source?.type === BlockSourceTypeEnum.Field) {
      createCardWithValues({boardId: board!.id, values: [
        {
          fieldId: ((block.source as BlockSourceField).field?.attributes as Relation).foreginRelationFieldId!,
          type: FieldType.Relation,
          links: [{valueId: relationPrimaryKey!}]
        },
        {
          fieldId: (currentView?.options as BoardViewOptionsInput).stackedFieldId!,
          type: FieldType.Select,
          valueAsJson: {id: data.id, name: data.name}
        }
      ]}).then(res => {
        if(res.error) { return }
        // setEditMode(true, init)
        // setCurrentCardId(res.data?.createCardWithValues?.id!, init)
        // setCurrentCardPrimaryKey(values.find(v => v.cardId === res.data?.createCardWithValues?.id! && v.primary === true)?.id!, init)
        // setCurrentCard({...(cards.find(c => c.id === res.data?.createCardWithValues?.id!) as CardWithValues), values: values.filter(v => v.cardId === res.data?.createCardWithValues?.id!)}, init)
        // setCardModalIsOpened(true, init)
      });
    } else {    
      createCardWithValues({boardId: board!.id, values: [
        {
          fieldId: (currentView?.options as BoardViewOptionsInput).stackedFieldId!,
          type: FieldType.Select,
          valueAsJson: {id: data.id, name: data.name}
        }
      ]}).then(res => {
        if(res.error) { return }

        // function close() {
        //   setCardModalIsOpened(false, init)
        //   setEditMode(false, init)
        // } 

        // if(inBlock) {
        //   setCurrentCardId(res.data?.createCardWithValues?.id!, init)
        //   setCurrentCardPrimaryKey(values.find(v => v.cardId === res.data?.createCardWithValues?.id! && v.primary === true)?.id!, init)
        //   setCurrentCard({...(cards.find(c => c.id === res.data?.createCardWithValues?.id!) as CardWithValues), values: values.filter(v => v.cardId === res.data?.createCardWithValues?.id!)}, init)
        //   setEditMode(true, init)
        //   setCardModalIsOpened(true, init)
        // } else {
        //   setCardModalIsOpened(true, init)
        //   setEditMode(true, init)
        //   setModal(<CardModal open={true} close={close} cardId={res.data?.createCardWithValues?.id!} boardId={res.data?.createCardWithValues?.boardId!} initiator="ROW"/>, init)
        // }
      });
    }
  }


  return (
    <div className="column w-[360px] shrink-0 bg-white text-slate-800  pb-10">
      <div className="text-base mb-2 flex items-center py-2 bg-white justify-between pr-7">
        <div>
          <span className="task-status inline-block h-3 w-3 rounded-full mr-2" style={{backgroundColor: `${hexToRGB(intToRGB(hashCode(data?.name!)), 0.4)}`}}></span>
          <span>{data.name}</span>
          <span className="text-slate-400 ml-2">({data.tasks.length})</span>
        </div>
        {currentView?.permissions?.canAdd ?  <TbPlus size={18} onClick={() => addTask()} className="text-slate-400 hover:text-indigo-600"/> : <></>}
      </div>
      {
        winReady ? (
        <Droppable droppableId={data.name}>
            {(provided) => (
            <ul className="scrollbar-thin scrollbar-thumb-slate-200 scrollbar-track-transparent overflow-y-scroll h-full pb-12 flex flex-col gap-4" {...provided.droppableProps} ref={provided.innerRef}>
              {children}
              {provided.placeholder}
            </ul>
            )}
        </Droppable>
        ) : ( null )}
    </div>
  )
}
export default Column