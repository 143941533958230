import { useFormContext, Controller } from "react-hook-form";
import MatrSelect from "../../../../../components/ui/select/base";
import { SingleValue } from "react-select";
import { useSpace } from "../../../../../core/providers/space";
import { Board, Field, FieldType } from "../../../../../core/graphql/types";
import { useState } from "react";

const PageLayoutRecordsListOptions = () => {
  const {boards} = useSpace()
  const {control, formState: {errors}} = useFormContext()
  const [fields, setFields] = useState<Field[]>()

  function handleChangeBoard(e: SingleValue<Board>, onChange: (...event: any[]) => void) {
    setFields(boards.find(b => b.id === e?.id)?.fields! as Field[])
    onChange(e?.id)
  }

  function handleSelectTitleField(e: SingleValue<Field>, onChange: (...event: any[]) => void) {
    onChange(e?.id)
  }

  function handleSelectSecondField(e: SingleValue<Field>, onChange: (...event: any[]) => void) {
    onChange(e?.id)
  }

  function handleSelectThirdField(e: SingleValue<Field>, onChange: (...event: any[]) => void) {
    onChange(e?.id)
  }

  function handleSelectImageField(e: SingleValue<Field>, onChange: (...event: any[]) => void) {
    onChange(e?.id)
  }

  return (
    <>
      <div className="relative">
        <label className="font-medium text-gray-600 text-sm">Доска:</label>
        <Controller
          name="pageOptions.boardId"
          control={control}
          rules={{required: true}}
          render={({ field: { onChange, value }}) => (
            <div className="mt-1.5">
              <MatrSelect
                getOptionLabel={(option) => option?.name!}
                getOptionValue ={(option)=> option?.id!}
                value={value && value.length > 0 ? boards.find(r => value.includes(r.id)) : []}
                onChange={(e) => handleChangeBoard(e, onChange)}
                options={boards}
              />
            </div>
          )}
        />
        {errors.boardId && <span className="text-red-600 text-xs font-normal">"Доска" обязательное поле.</span>}
      </div>
      <div className="relative space-y-1.5">
        <label className="matr-label">Колонка для заголовка:</label>
        <Controller
          name="pageOptions.titleFieldId"
          control={control}
          rules={{required: true}}
          render={({ field: { onChange, value, ref }}) => (
            <div className="mt-2">
              <MatrSelect 
                classNamePrefix="matr_select_form"
                placeholder="Выберите поле на базе которого будет построен вид"
                isSearchable={false}
                getOptionLabel ={(option)=>option?.title!}
                getOptionValue ={(option)=>option?.id!}
                value={fields?.find((f: any) => f.id === value)}
                onChange={(e) => handleSelectTitleField(e, onChange)}
                options={fields}
              />
            </div>
          )}
        />
      </div>
      <div className="relative space-y-1.5">
        <label className="matr-label">Колонка для второй записи:</label>
        <Controller
          name="pageOptions.secondFieldId"
          control={control}
          render={({ field: { onChange, value, ref }}) => (
            <div className="mt-2">
              <MatrSelect 
                classNamePrefix="matr_select_form"
                placeholder="Выберите поле на базе которого будет построен вид"
                isSearchable={false}
                getOptionLabel ={(option)=>option?.title!}
                getOptionValue ={(option)=>option?.id!}
                value={fields?.find((f: any) => f.id === value)}
                onChange={(e) => handleSelectSecondField(e, onChange)}
                options={fields}
              />
            </div>
          )}
        />
      </div>
      <div className="relative space-y-1.5">
        <label className="matr-label">Колонка для третей записи:</label>
        <Controller
          name="pageOptions.thirdFieldId"
          control={control}
          render={({ field: { onChange, value, ref }}) => (
            <div className="mt-2">
              <MatrSelect 
                classNamePrefix="matr_select_form"
                placeholder="Выберите поле на базе которого будет построен вид"
                isSearchable={false}
                getOptionLabel ={(option)=>option?.title!}
                getOptionValue ={(option)=>option?.id!}
                value={fields?.find((f: any) => f.id === value)}
                onChange={(e) => handleSelectThirdField(e, onChange)}
                options={fields}
              />
            </div>
          )}
        />
      </div>
      <div className="relative space-y-1.5">
        <label className="matr-label">Колонка для изображения:</label>
        <Controller
          name="pageOptions.imageFieldId"
          control={control}
          render={({ field: { onChange, value, ref }}) => (
            <div className="mt-2">
              <MatrSelect 
                classNamePrefix="matr_select_form"
                placeholder="Выберите поле на базе которого будет построен вид"
                isSearchable={false}
                getOptionLabel ={(option)=>option?.title!}
                getOptionValue ={(option)=>option?.id!}
                value={fields?.find((f: any) => f.id === value)}
                onChange={(e) => handleSelectImageField(e, onChange)}
                options={fields?.filter((f: any) => f.type === FieldType.Attachment)}
              />
            </div>
          )}
        />
      </div>
    </>
  )
}

export default PageLayoutRecordsListOptions;