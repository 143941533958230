import { useEffect, useRef, useState } from 'react';
import { Field, Filter, NodeField } from '../../../../../core/graphql/types';
import { MatrPopover } from '../../../../../components/ui/portal';
import _ from 'lodash';
import FiltersListWithLink from '../../../../../components/Filters/filterWithLink';
import { useFlowContext } from '../../../../../core/providers/flow';

interface BlockSettingsFiltersDropdownProps {
  fields: Field[],
  filters: Filter[] | any,
  onChangeFilters: any;
}

function BlockSettingsFiltersDropdown({fields, filters, onChangeFilters}: BlockSettingsFiltersDropdownProps) {
  const {node, nodes, edges} = useFlowContext()
  const buttonRef = useRef<any>(null);
  const [ open, setOpen ] = useState(false);
  const [users, setUsers] = useState([])
  const [sourceFields, setSourceFields] = useState<NodeField[]>([])

  useEffect(() => {
    let parentNodeIds: string[] = edges.filter(edge => edge.target === node.id).map(edge => edge.source)
    let fields: any = []
    nodes.filter(n => parentNodeIds.includes(n.id)).map(node => fields = [...fields, ...node?.data?.fields.filter((f:NodeField) => f.external === true).map((f:NodeField) => ({id: f.id, name: f.name}))])
    setSourceFields(fields)
  }, [edges, node])
  

  function closePopover() { 
    setOpen(false)
  };

  return (
    <>
      <button 
        ref={buttonRef}
        type='button'
        className={`
        ${open ? 'bg-indigo-600 text-white hover:bg-indigo-700 hover:text-white' : 'bg-slate-200 text-slate-700 hover:bg-slate-300 hover:text-slate-800'}
        ${filters?.length ? "bg-green-200 text-green-800" : ""}
        h-9 w-full text-base flex items-center px-3 py-1.5 space-x-2 rounded-md justify-between`}
        onClick={() => setOpen(true)}
        >
        <span>Фильтры</span>
        {filters?.length ? <span className='flex h-4 w-4 rounded-sm bg-green-600 text-xxs ml-2 items-center justify-center text-white'>{filters?.length}</span> : <></>}
      </button>
      {open && <MatrPopover onClose={closePopover} reference={buttonRef.current} placement="bottom-start">
        <div className='bg-white h-fit max-h-[32rem] min-w-[48rem] rounded-md p-6 border shadow-lg mt-2'>
          <FiltersListWithLink boardFields={_.orderBy(fields, 'position', 'asc')} currentFilters={filters} users={users} saveFilters={onChangeFilters} links={sourceFields}/>
        </div>
      </MatrPopover>}
    </>
  )
}

export default BlockSettingsFiltersDropdown;