import { Popover } from '@headlessui/react';
import { useRef, useState } from 'react';
import { BoardView, Field, UserSpaceBoardView } from '../../../../core/graphql/types';
import FiltersList from "../../../Filters/filter";
import { MatrPopover } from '../../../ui/portal';
import { useBoard } from '../../../../core/providers/board';
import { useSpaceStore } from '../../../../core/store/store';
import { TbFilter } from 'react-icons/tb';

interface BoardFiltersDropdownProps {
  viewFields: Field[],
  onChange: any;
}

function BoardFiltersDropdown({viewFields, onChange}: BoardFiltersDropdownProps) {
  const {initId, initType} = useBoard()
  const init: string = `${initType}:${initId}`
  const store = useSpaceStore()
  const {users, userCurrentView} = store.data[init];

  const buttonRef = useRef<any>(null);
  const [ open, setOpen ] = useState(false);

  function closePopover() { 
    setOpen(false)
  };

  return (
    <>
      <button 
        ref={buttonRef}
        className={`
        ${open ? 'bg-indigo-600 text-white hover:bg-indigo-700 hover:text-white ring-2 ring-indigo-300' : 'bg-slate-200 text-slate-700 hover:bg-slate-300 hover:text-slate-800'}
        ${userCurrentView?.filters?.length ? "bg-green-200 text-green-800" : ""}
        h-7 text-sm font-medium flex items-center px-1.5 space-x-2 rounded-sm`}
        onClick={() => setOpen(true)}
        >
        <TbFilter size={18}/>
        {userCurrentView?.filters?.length ? <span className='flex h-4 w-4 rounded-sm bg-green-600 text-xxs ml-2 items-center justify-center text-white'>{userCurrentView.filters?.length}</span> : <></>}
      </button>
      {open && <MatrPopover onClose={closePopover} reference={buttonRef.current} placement="bottom-start">
        <div className='bg-white h-fit max-h-[32rem] min-w-[48rem] rounded-sm p-6 border shadow-lg mt-2'>
          <FiltersList boardFields={viewFields} currentFilters={userCurrentView.filters} users={users} saveFilters={onChange}/>
        </div>
      </MatrPopover>}
    </>
  )
}

export default BoardFiltersDropdown;