import { Tab } from "@headlessui/react";
import { useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { TbSquaresFilled, TbTrash, TbX } from "react-icons/tb";
import { SingleValue } from "react-select";
import { iconByType } from "../../../../../components/icons";
import MatrNarrowSelect from "../../../../../components/ui/select/narrow";
import { getBlockWHByType } from "../../../../../core/Controllers/Panel/blocks_functions";
import { Block, BlockFieldOptions, BlockSourceField, BlockSourceTypeEnum, BlockTypeEnum, Board, Field, LayoutEnum } from "../../../../../core/graphql/types";
import { usePanel } from "../../../../../core/providers/panel";
import { useSpace } from "../../../../../core/providers/space";
import { BiToggleLeft, BiToggleRight } from "react-icons/bi";

const contentWidth = [
  {label: "На всю ширину", value: 0},
  {label: "Десктоп", value: 1},
  {label: "Планшет", value: 2},
  {label: "Телефон", value: 3},
]

const contentColumns = [
  {label: "Одна колонка", value: 1},
  {label: "Две колонки", value: 2},
]

const BlockFormFields = ({id, boardId, onClose}:{id: string, boardId: string, onClose: () => void}) => {
  const {boards} = useSpace()
  const {page, addBlock, removeBlock} = usePanel()
  const [columns, setColumns] = useState<{field:Field, selected: boolean, block: Block | null}[]>()

  useEffect(() => {
    let boardFields = boards.find(b => b.id === boardId)?.fields! as Field[]
    prepareFields(boardFields)
  }, [page])


  function prepareFields(boardFields: Field[]) {
    let blocksInUse = page?.blocks?.filter(pb => pb?.parentId === id)
    let fieldIdsInUse = blocksInUse?.map(pb => (pb?.source as BlockSourceField).fieldId)
    let cols: {field: Field, selected: boolean}[] | any = []
    
    boardFields?.map((f: Field) => {
      if(fieldIdsInUse?.includes(f.id)) {
        cols.push({field: f, selected: true, block: blocksInUse?.find(bf => (bf?.source as BlockSourceField).fieldId === f.id)})
      } else {
        cols.push({field: f, selected: false, block: null})
      }
    })

    setColumns(cols)
  }

  function onFieldClick(item: Field)  {
    let wh = getBlockWHByType(BlockTypeEnum.BlockField, item.type)
    addBlock({
      pageId: page.id, 
      parentId: id, 
      type: BlockTypeEnum.BlockField,
      sourceType: BlockSourceTypeEnum.Field,
      position: {...wh, ...{
        x: page?.blocks && page.blocks.length > 0 ? page.blocks.length + 1 : 0,
        y: 1
      }},
      source: {
        type: BlockSourceTypeEnum.Field,
        blockId: id,
        boardId: boardId,
        fieldId: item.id
      },
      options: {
        type: BlockTypeEnum.BlockField,
        editable: true,
        title: item.title
      }
    })
  }

  return (
    <>
      <div className="flex items-center justify-between">
        <div className="flex items-center space-x-2">
          <div className="py-1 px-2 bg-slate-100 flex space-x-2 rounded">
            <TbSquaresFilled size={20} className="text-indigo-600"/>
            <div className="flex font-medium text-sm">{page.name}</div>
          </div>
          <div className="flex font-normal text-sm">Поля для формы</div>
        </div>
        <button onClick={onClose}><TbX size={18}/></button>
      </div>
      <div className="py-4 space-y-2 overflow-scroll">
      {columns && columns.length > 0 && columns!.map((column: {field: Field, selected: boolean, block: Block | null}) => (
        column.selected ? 
        <div 
          key={column.field.id} 
          className="flex items-center bg-white rounded-md justify-between"
          unselectable="on"
        >
          <div className="opacity-50 space-x-4 flex items-center">
            <div className="w-10 h-10 flex justify-center items-center bg-slate-100 rounded-md">{iconByType(column.field.type)}</div>
            <div className="flex flex-col">
              <span className="text-base text-slate-800 cursor-default">{column.field?.title}</span>
              <span className="text-xs text-slate-500 cursor-default">{column.field.type}</span>
            </div>
          </div>
          <div className="text-slate-600" onClick={() => removeBlock(column?.block!.id)}>
            <TbTrash size={18}/>
          </div>
        </div>
        :
        <div 
          key={column.field.id} 
          className="flex items-center space-x-4 droppable-element bg-white rounded-md"
          onClick={() => onFieldClick(column.field)}
        >
          <div className="w-10 h-10 flex justify-center items-center bg-slate-100 rounded-md">{iconByType(column.field.type)}</div>
          <div className="flex flex-col">
            <span className="text-base text-slate-800 cursor-default">{column.field?.title}</span>
            <span className="text-xs text-slate-500 cursor-default">{column.field.type}</span>
          </div>
        </div>
      ))}
      </div>
    </>
  )
}

export default BlockFormFields;