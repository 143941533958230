import { LayoutEnum } from "../../../core/graphql/types";

import { usePanel } from "../../../core/providers/panel";
import LayoutBlank from "./layouts/blank";
import LayoutRecordsList from "./layouts/records_list";
import LayoutCalendar from "./layouts/calendar";
import LayoutGrid from "./layouts/grid";
import LayoutForm from "./layouts/form";
import LayoutList from "./layouts/list";
import LayoutKanban from "./layouts/kanban";


export default function PanelPage() {
  const {page} = usePanel()

  switch (page.layout) {
    case LayoutEnum.Blank: return <LayoutBlank />
    case LayoutEnum.RecordsList: return <LayoutRecordsList />
    case LayoutEnum.List: return <LayoutList />
    case LayoutEnum.Kanban: return <LayoutKanban />
    case LayoutEnum.Calendar: return <LayoutCalendar />
    case LayoutEnum.Grid: return <LayoutGrid />
    case LayoutEnum.Form: return <LayoutForm />
    default: return <></>
  }
}