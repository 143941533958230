import { useEffect, useState } from "react";
import { orderBy } from "lodash";
import { Controller, useFormContext } from 'react-hook-form';
import { SingleValue } from "react-select";
import { SmallSpinner } from "../../../../../components/loaders";
import MatrNarrowSelect from "../../../../../components/ui/select/narrow";
import { Board, NodeField } from "../../../../../core/graphql/types";
import { useFlowContext } from "../../../../../core/providers/flow";
import { useSpace } from "../../../../../core/providers/space";
import NodeFields from "../nodeField/NodeFields";


function ActionCreateCard() {
  const {boards} = useSpace();
  const {node} = useFlowContext()
  const [loading, setLoading] = useState(true)
  const [nodeFields, setNodeFields] = useState<NodeField[]>([])
  const { control, formState: {isDirty, errors}} = useFormContext();

  useEffect(() => {
    let ordered = orderBy(node.data?.fields?.filter((f: NodeField) => f.external === true), ["name"], ["asc"])
    setNodeFields(ordered ? ordered : [])
    setLoading(false)
  },[node.data.options.boardId])

  
  function handleChangeBoard(e: SingleValue<Board>, onChange: { (...event: any[]): void; (arg0: any): void; }) {
    onChange(e?.id)
    setLoading(true)
  }

  

  return (
    <div className="flex flex-col h-full">
      <div className="flex flex-col py-4 space-y-2">
        <div className="relative">
          <label className="matr-label w-full">Таблица в которой создаем:</label>
          <Controller
            name="boardId"
            control={control}
            render={({ field: { onChange, value }}) => (
              <div className="mt-1.5">
                <MatrNarrowSelect
                  getOptionLabel={(option) => option?.name!}
                  getOptionValue ={(option)=> option?.id!}
                  value={value && value.length > 0 ? boards?.find(r => value.includes(r.id)) : []}
                  onChange={(e) => handleChangeBoard(e, onChange)}
                  options={boards}
                />
              </div>
            )}
          />
          {errors.boardId && <span className="text-red-600 text-xs font-normal">"Доска" обязательное поле.</span>}
        </div>
        {!loading ?
          <div className="relative">
            <label className="matr-label w-full">Поля для заполнения</label>
            {nodeFields.length > 0 ? <NodeFields nodeFields={nodeFields} /> : <></>}
          </div>
          :
          <div className="min-h-[4rem] flex items-center">
            <SmallSpinner />
          </div>
        }
      </div>
    </div>
  );
}

export default ActionCreateCard;