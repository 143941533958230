import { UserNotification, Space, FlowNotification } from "../../../../core/graphql/types"
import { useAuth } from "../../../../core/providers/auth"
import moment from "moment";

interface NotificationProps {
  data: UserNotification
  onRemove: (id: string) => void
}

export default function NotificationFromFlow({data, onRemove}:NotificationProps) {
  return (
    <>
      <div className="flex w-full items-center justify-between">
        <div className="flex w-full">
          <span className="font-meduim">{(data.resource as FlowNotification).content}</span>
        </div>
        <div className="flex w-20 items-center justify-end h-10">
          <div className="flex w-fit text-rigth whitespace-pre text-slate-400 text-xs">{moment(data.updatedAt).fromNow()}</div> 
        </div>
      </div>
    </>
  )
}