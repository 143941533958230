import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { BiToggleLeft, BiToggleRight } from 'react-icons/bi';
import { HiMenuAlt4 } from 'react-icons/hi';
import { TbAlertTriangle, TbAlertTriangleFilled, TbTrash } from 'react-icons/tb';

export default function FetcherVariables():JSX.Element {
  const methods = useFormContext();
  const {watch, control, register, setValue, getValues, formState: {errors}} = methods;
  const { fields, append, remove, move } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "variables", // unique name for your Field Array
  });

  function handleChangeCheckbox(value: any, onChange: { (...event: any[]): void; (arg0: any): void; }) {
    onChange(!value)
  }
  
  return (
    <div className='flex flex-col w-full mt-2'>
      <div className='flex justify-between items-start pb-4'>
        <div className='flex flex-col'>
          <label className='text-sm text-slate-600 font-medium'>Переменные</label>
          <div className='text-xs text-slate-600'>
            <p>Что такое переменные и как их использовать?</p>
          </div>
        </div>
        <div>
          <button
            type="button"
            className='text-sm px-2 py-1 bg-slate-100 text-slate-600 rounded mt-2'
            onClick={() => {
              append({ key: "", value: "", disabled: false});
            }}
          >
            Добавить
          </button>
        </div>
      </div>
      <ul className='space-y-2'>
        {fields.map((item, index) => {
          return (
            <li key={item.id} className='flex items-center space-x-2'>
              <Controller
                name={`variables.${index}.disabled`}
                control={control}
                render={({ field: { onChange, value, ref }}) => (
                  <div 
                    className={`flex items-center  rounded-md font-normal  cursor-default`} 
                    onClick={() => handleChangeCheckbox(value, onChange)}
                  >
                    {value === false ? <BiToggleRight className='h-6 w-6 text-blue-600'/> :  <BiToggleLeft className='h-6 w-6 text-slate-400'/>}
                  </div>
                )}
              />
              <div className='flex space-x-2 w-full'>
                <div className='w-1/2 relative'>
                  <input
                    className=' matr-form-narrow-input'
                    placeholder='Название'
                    {...register(`variables.${index}.key`, { required: true })}
                  />
                  {watch('variables').filter((v: any, idx: number) => idx !== index).map((v: any) => v.key).includes(watch(`variables.${index}.key`)) && <div className='absolute right-2 top-2 text-yellow-500'>
                    <TbAlertTriangleFilled size={16}/>
                  </div>}
                </div>
                <div className='w-1/2'>
                  <Controller
                    render={({ field }) => <input className='matr-form-narrow-input' placeholder='Значение' {...field} />}
                    name={`variables.${index}.value`}
                    control={control}
                  />
                </div>

              </div>
              <button type="button" className='flex-none  text-slate-500 p-1.5' onClick={() => remove(index)}>
                <TbTrash size={18}/>
              </button>
            </li>
          );
        })}
      </ul>

    </div>
  )
}