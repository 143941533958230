import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useAuth } from '../../core/providers/auth';
import { HiPlus } from 'react-icons/hi';
import { Board} from '../../core/graphql/types';
import { SmallSpinner } from '../../components/loaders';
import { SimpleModal } from "../../components/modals/simple";
import { BoardFormCreate } from "../board/form/create";
import { BoardFormImport } from "../board/form/import";

function BoardsActions(): JSX.Element {
  const auth = useAuth();
  const [ open, setOpen ] = useState(false);
  const [ modalType, setModalType ] = useState("");

  function openModal(type: string) { 
    setModalType(type)
    setOpen(true)
  };
  
  function closeModal() { 
    setModalType("")
    setOpen(false)
  };

  function onCreate(board: Board) {
    // setListBoards([...listBoards, board])
  }

  function getModal() {
    switch (modalType) {
      case "create":
        return (
          <SimpleModal open={open} close={closeModal}>
            <BoardFormCreate closeModal={closeModal} onCreate={onCreate}/>
          </SimpleModal>
        )
      // case "template":
      //   return (
      //     <SimpleModal open={open} close={closeModal}>
      //       <BoardFormCreateFromTemplate closeModal={closeModal} onCreate={onCreate}/>
      //     </SimpleModal>
      //     )
      case "import":
        return (
          <SimpleModal open={open} close={closeModal}>
            <BoardFormImport closeModal={closeModal} onCreate={onCreate}/>
          </SimpleModal>
          )
      default:
        break;
    }
  }

  return (
    <>
      <div className='grid grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 w-full rounded-sm gap-4'>
        <div  
          className="flex col-span-1 bg-slate-100 rounded-sm p-2 text-sm gap-4 cursor-default hover:bg-indigo-50 hover:shadow"
          onClick={() => openModal("create")}
        >
          <div className="flex shrink-0 w-10 h-10 bg-indigo-600 rounded-sm text-white items-center justify-center">
            <HiPlus />
          </div>
          <div className="flex flex-col w-full truncate">
            <div className='text-slate-600 font-medium'>Новая доска</div>
            <div className='text-slate-400'>Создание пустой доски</div>
          </div>
        </div>
        <div  
          className="flex col-span-1 bg-slate-100 rounded-sm p-2 text-sm gap-4 cursor-default hover:bg-indigo-50 hover:shadow"
          onClick={() => openModal("import")}
        >
          <div className="flex shrink-0 w-10 h-10 bg-indigo-600 rounded-sm text-white items-center justify-center">
            <HiPlus />
          </div>
          <div className="flex flex-col w-full truncate">
            <div className='text-slate-600 font-medium'>Импортировать</div>
            <div className='text-slate-400'>Импортировать xlsx</div>
          </div>
        </div>
      </div>
      {modalType && open ? getModal() : <></>}
    </>
  );
}
export default BoardsActions;