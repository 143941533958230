import { useState, createContext, useContext } from 'react';

interface SidebarContextType {
  isSidebarOpen: boolean;
  openSidebar: () => void;
  closeSidebar: () => void;
}

let SidebarContext = createContext<SidebarContextType>(null!);


export function SidebarProvider({ children }: { children: React.ReactNode }) {
  let [isSidebarOpen, setIsSidebarOpen] = useState(true);

	const openSidebar = () => {
		setIsSidebarOpen(true);
	};

	const closeSidebar = () => {
		setIsSidebarOpen(false);
	};


  let value = {isSidebarOpen, openSidebar, closeSidebar };

  return <SidebarContext.Provider value={value}>{children}</SidebarContext.Provider>;
}

export function useSidebar() {return useContext(SidebarContext);}