import { useRef, useState } from 'react';
import { MatrPopover } from '../../../../../components/ui/portal';
import _ from 'lodash';
import { nodeFieldValueTypes } from '../../../../../core/enums';

interface NodeFieldTypeDropDownProps {
  value: string | null,
  onChange: any;
}

function NodeFieldTypeDropDown({value, onChange}: NodeFieldTypeDropDownProps) {
  const buttonRef = useRef<any>(null);
  const [ open, setOpen ] = useState(false);

  function closePopover() { 
    setOpen(false)
  };

  function handleChange(id: string) {
    onChange(id)
  }

  return (
    <>
      <button 
        ref={buttonRef}
        className={`
        ${open ? 'bg-indigo-600 text-white hover:bg-indigo-700 hover:text-white' : 'bg-slate-200 text-slate-700 hover:bg-slate-300 hover:text-slate-800'}
        flex w-7 h-7 rounded-sm bg-slate-200 items-center justify-center`}
        onClick={() => setOpen(true)}
        >
        <span>{nodeFieldValueTypes.find(type => type.value === value)?.icon}</span>
      </button>
      {open && <MatrPopover onClose={closePopover} reference={buttonRef.current} placement="bottom-start">
        <div className='bg-white h-fit max-h-[32rem] w-64 rounded p-2 text-sm border shadow-sm mt-2'>
          {nodeFieldValueTypes.map((type: any) => 
            <div key={type.value} className={`px-2 py-1.5 font-medium rounded cursor-default ${value === type.value ? "bg-indigo-600 text-white" : "text-indigo-600 hover:bg-indigo-200"}`} onClick={() => handleChange(type.value)}>
              <span>{type.label}</span>
            </div>
          )}
        </div>
      </MatrPopover>}
    </>
  )
}

export default NodeFieldTypeDropDown;