import React, { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import toast from 'react-hot-toast';
import { Role, useCreateSpaceRoleMutation } from "../../../../core/graphql/types";

const DeleteRole = ({role, closeModal, onChange}: {role: Role, closeModal: any, onChange: any}) => {
  const { register, handleSubmit, watch, control, setValue, formState: { errors } } =  useForm();

  useEffect(() => {
    setValue('id', role.id)
    setValue('name', "")
  }, [])

  const onSubmit = handleSubmit(data => {
    onChange(data)
    closeModal()
  });

  return (
    <form onSubmit={onSubmit} className="flex flex-col relative w-full space-y-4 p-6">
      <h2 className="text-lg font-semibold">Удаление роли</h2>
      <div className="text-xs text-gray-600">Для подтверждения введит название роли, которую собираетесь удалить. К роли не должны быть привязаны участники.</div>
      <div>
        <label className="font-medium text-gray-600 text-sm">Название роли:</label>
        <input {...register("name", { required: true })}
          className="text-sm form-input mt-2 py-2.5 block w-full rounded-sm bg-gray-100 border-0 focus:bg-white focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
        />
        {errors.name && <span className="text-red-600 text-xs font-normal">"Название" обязательное поле.</span>}
      </div>
      <div className="flex justify-between w-full pt-6">
        <button
          type="button"
          className="inline-flex justify-end px-4 py-2 text-sm font-medium text-indigo-700 bg-indigo-100 border border-transparent rounded-md hover:bg-indigo-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-indigo-500"
          onClick={closeModal}
        >
          Отменить
        </button>
        <button
          type="submit"
          className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-red-600 border border-transparent rounded-md hover:bg-red-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-indigo-500"
        >
          Удалить
        </button>
      </div>
    </form>
  )
}

export default DeleteRole;