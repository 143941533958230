import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { isVariable, isVariableExist } from '../../../../../../core/Controllers/Service';
import { useFetcherContext } from '../../../../../../core/providers/fetcher';
import { TbExchange } from 'react-icons/tb';

export default function PathParametersPanel():JSX.Element {
  const {fetcher} = useFetcherContext()
  const methods = useFormContext();
  const {watch, control, setValue, getValues, formState: {errors}} = methods;
  const { fields, append, remove, move } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "pathParameters", // unique name for your Field Array
  });

  function handleChangeCheckbox(value: any, onChange: { (...event: any[]): void; (arg0: any): void; }) {
    onChange(!value)
  }

  
  return (
    <div className='flex flex-col w-full mt-2 border-b'>
      <div className='flex flex-col justify-between pb-4'>
        <label className='text-sm text-slate-600 font-medium'>Параметры в адресе</label>
        <div className='text-xs text-slate-600 py-2'>
          <p>Параметры, это значения, которые вставлются во внутрь урла. Так вы можете указать любое количество значений. Для этого необходимо добавить переменную в урл с двоеточием в начале</p>
        </div>
      </div>
      {fields.map((field, index) => {
        return (
          <div key={field.id} className="flex items-center w-full mb-4">
            <section className={"section grid grid-cols-4 gap-2 w-full"}>
              <Controller
                name={`pathParameters.${index}.key`}
                control={control}
                rules={{required: true, minLength: 1}}
                render={({ field: { onChange, value, ref }}) => (
                  <input 
                    value={value}
                    disabled={true}
                    placeholder="Ключ"
                    className={`matr-form-narrow-input`}
                  />
                )} 
              />
              <Controller
                name={`pathParameters.${index}.value`}
                control={control}
                rules={{required: true, minLength: 1}}
                render={({ field: { onChange, value, ref }}) => (
                  <input 
                    value={value}
                    onChange={onChange}
                    placeholder="Тестовое значение"
                    className={`matr-form-narrow-input ${isVariable(value) ? isVariableExist(value, fetcher?.variables!) ? "!text-blue-700" : "!text-red-400" : ""}`}
                  />
                )} 
              />
              <Controller
                name={`pathParameters.${index}.dynamic`}
                control={control}
                rules={{required: true}}
                render={({ field: { onChange, value, ref }}) => (
                  <div 
                    className={`flex-none w-7 items-center justify-center py-1.5 px-1.5 text-sm ${value ? "bg-green-300 text-green-800" : "bg-slate-200 text-slate-600"} rounded font-normal justify-between w-full cursor-default`} 
                    onClick={() => handleChangeCheckbox(value, onChange)}
                  >
                    {value ? <TbExchange className='h-4 w-4 text-green-800'/> : <TbExchange className='h-4 w-4 text-slate-400'/>}
                  </div>
                )}
              />
              <Controller
                name={`pathParameters.${index}.name`}
                control={control}
                rules={{required: true, minLength: 1}}
                render={({ field: { onChange, value, ref }}) => (
                  <input 
                    value={value}
                    disabled={!watch(`pathParameters.${index}.dynamic`)}
                    onChange={onChange}
                    placeholder="Название"
                    className={`matr-form-narrow-input`}
                  />
                )} 
              />
              <div className='col-span-1 flex items-center'>
                <Controller
                  name={`pathParameters.${index}.description`}
                  control={control}
                  rules={{required: true, minLength: 1}}
                  render={({ field: { onChange, value, ref }}) => (
                    <input 
                      value={value}
                      disabled={!watch(`pathParameters.${index}.dynamic`)}
                      onChange={onChange}
                      placeholder="Описание"
                      className={`matr-form-narrow-input`}
                    />
                  )} 
                />
              </div>
            </section>
          </div>
        );
      })}
    </div>
  )
}