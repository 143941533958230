import { Maybe, LinkItem } from "../../../core/graphql/types";
import { splitNames } from "../../../utils";
import { LookupIcon } from "../../icons";

export function FieldLookup({ value, field }: {value: Maybe<LinkItem>[], field: any}) {
  return (
    <div className="relative flex flex-row justify-between items-center pb-1.5 border-b">
      <div className="flex items-center h-full">
        <div className="text-slate-400 pr-2"><LookupIcon /></div>
        <label className="text-slate-500 text-sm">{field.title}</label>
      </div>
      <div className="flex flex-row col-span-9">
        <div className="flex my-1.5 ">
          <div className="flex space-x-1 truncate">
            {value && value.length && Array.isArray(value) ? 
              value.map((element, idx) => {
                return element?.value?.value ?  
                  <div key={element?.valueId} className="flex bg-cyan-200 text-cyan-800 h-5 py-1 rounded-sm px-2 items-center text-sm">{splitNames(element?.value.value as string)}</div>
                  : <div key={idx}></div>
              })
              : <></>
            }
          </div>
        </div>
      </div>
    </div>
  )
}