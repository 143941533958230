import { RenderRowProps, Row } from "react-data-grid";
import { BlockSourceField, BlockSourceTypeEnum, FieldType, Relation, useChangeCardPositionMutation, useCreateCardMutation, useCreateCardWithValuesMutation } from "../../../../core/graphql/types";
import { useBoard } from "../../../../core/providers/board";
import CardModal from "../../../Cards";
import { useEffect, useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import { TbCirclePlus } from "react-icons/tb";
import { useSpaceStore } from "../../../../core/store/store";


interface DraggableRowProps<R, SR> extends RenderRowProps<R, SR> {
    idRow: React.Key;
}


export function renderRow<R>(key: React.Key, props:RenderRowProps<R, any>) {
    return (
        key === "new" ?  <CreateRow key={key} {...props}/> : <DraggableRow key={key} {...props} idRow={key}/>
    );
}
    
export function CreateRow<R, SR>({rowIdx, isRowSelected, ...props}: RenderRowProps<R,SR>) {
    const {initId, initType, inBlock, block, currentView, relationPrimaryKey, board, setCardModalIsOpened, setModal} = useBoard();
    const init: string = `${initType}:${initId}`;

    const [,createCard] = useCreateCardMutation()
    const [,createCardWithValues] = useCreateCardWithValuesMutation()

    const width =  props.viewportColumns.map(c => c.width).reduce((partialSum, a) => (partialSum as number) + (a as number), 0)
    const style = { "--rdg-grid-row-start": props.gridRowStart, } as React.CSSProperties;
    const cellStyle = { gridColumnStart: 1, height: 36, insetInlineStart: "var(--rdg-frozen-left-0)", marginTop: -(props.height + 40)}

    function addRow(): void {
      if(inBlock && block?.source?.type === BlockSourceTypeEnum.Field) {
        createCardWithValues({boardId: board!.id, values: [{
          fieldId: ((block.source as BlockSourceField).field?.attributes as Relation).foreginRelationFieldId!,
          type: FieldType.Relation,
          links: [{valueId: relationPrimaryKey!}]
        }]})
      } else {
        createCard({boardId: board!.id}).then(res => {
          if(res.error) { return }
    
          if(currentView?.filters && currentView?.filters!.length > 0) {
            function close() {
              setCardModalIsOpened(false, init)
            } 
            setCardModalIsOpened(true, init)
            setModal(<CardModal open={true} close={close} cardId={res.data?.createCard?.id!} boardId={res.data?.createCard?.boardId!} initiator="NEW_ROW"/>, init)
          }
        });
      }
    }


    return (
      currentView?.permissions?.canAdd ?
      <div 
        role='row' 
        aria-rowindex={props['aria-rowindex']}
        aria-selected={props['aria-selected']}
        aria-readonly={true}
        style={style}
        className='rdg-row contents relative bg-slate-50'
      >
        <div role='gridcell' aria-colindex={1} tabIndex={-1} className='rdg-cell-frozen sticky' style={ cellStyle }>
          <span className='bg-slate-200 text-slate-800 w-full py-1 flex justify-center hover:bg-indigo-200 hover:text-indigo-600' onClick={() => addRow()}><TbCirclePlus size={18}/></span>
        </div>
      </div>
      : 
      <></>
    )
}

export function DraggableRow<R, SR>({idRow, rowIdx, isRowSelected, className, ...props}: DraggableRowProps<R, SR>): JSX.Element {
  const {initId, initType} = useBoard();
  const init: string = `${initType}:${initId}`;
  const store = useSpaceStore()
  const {rows} = store.data[init];
    
    let rowRef = useRef(null)
    const [,changeCardPosition] = useChangeCardPositionMutation()
    function onRowReorder(fromIndex: number, toIndex: number) {
        changeCardPosition({cardId: rows[fromIndex].id, position: toIndex})
    }
  
    useEffect(() => {
      if (rowRef) {
        //@ts-ignore
        drag(rowRef.current.firstElementChild);
      }
      drop(rowRef.current);
    }, [rowRef])
      
    const [{ isDragging }, drag] = useDrag({
      type: 'ROW_DRAG',
      item: { index: rowIdx },
      collect: (monitor) => ({
        isDragging: monitor.isDragging()
      })
    });
  
    const [{ isOver }, drop] = useDrop({
      accept: 'ROW_DRAG',
      drop({ index }: { index: number}) {
        onRowReorder(index, rowIdx);
      },
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop()
      })
    });
  
    return (
        <Row
          ref={rowRef}
          className={`${isDragging ? "opacity-50" : ""} ${isOver ? "bg-slate-50" : ""} ${className}`}
          rowIdx={rowIdx}
          isRowSelected={isRowSelected}
          {...props}
        />
    );
}