import { useEffect, useState, useCallback } from "react";
import Avatar from "react-avatar";
import { useForm, Controller } from "react-hook-form";
import { Link } from "react-router-dom";
import Select from "react-select";
import { BigSpinner } from "../../../components/loaders";
import { useGetUserQuery, useUpdateTelegramMutation } from "../../../core/graphql/types"
import { useAuth } from "../../../core/providers/auth";

export function AccountTelegram() {
  const {currentUser, setCurrentUser} = useAuth()
  const { register, handleSubmit, setValue, control, formState: { errors } } = useForm();
  const [{fetching, data, error}] = useGetUserQuery();
  const [, updateTelegram] = useUpdateTelegramMutation();


  useEffect(() => {
    if(!data?.getUser) {return}
    setValue('username', data?.getUser.telegram?.username || "")
  },[data?.getUser, setValue])

  const onSubmit = handleSubmit(data => {
    updateTelegram({
      username: data.username,
    }).then(result => {
      setCurrentUser(
        {...currentUser, 
          telegram: result.data?.updateTelegram?.telegram
        }
      )
    })
  });

  if (fetching) return <BigSpinner/>;
  if (error) return <>`Error! ${error.message}`</>;

  return (
    <div className="w-full">
      <h4 className="text-xl font-semibold">Подключение к Телеграм</h4>
      <p className="text-sm text-gray-500 leading-8 mt-4">1. Введите имя пользователя Телеграм и сохраните.</p>
      <p className="text-sm text-gray-500 leading-8 mb-6">2. Зайдите в Телеграм бота <a className="font-bold text-indigo-600" href="https://t.me/mattr_mattr_bot" target="_blank">Mattr</a> и введите /start.</p>
      <form onSubmit={onSubmit} className="flex flex-col relative w-full space-y-4">
        {currentUser?.telegram?.confirmed ? <div className="text-green-500 text-sm">Ваш аккаунт подтвержден</div> : <div className="text-slate-500 text-sm">Ваш аккаунт еще не подтвержден</div>}
        <div className="col-span-4">
          <input {...register("username", 
            { required: true})}
            className="matr-form-input" 
            placeholder="Имя пользователя в Телеграм"
          />
          {errors.username && <span className="text-red-600 text-xs font-normal px-2">Не может быть пустым</span>}
        </div>
        <div className="col-span-2">
          <button
            type="submit"
            className="flex items-center w-full justify-center px-4 h-9 text-sm font-medium text-white bg-indigo-600 rounded-tr-sm rounded-br-sm  hover:bg-indigo-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-indigo-500"
          >
            Сохранить
          </button>
        </div>
      </form>
    </div>
  )
}