import { useEffect, useState } from "react";
import { Column } from "react-data-grid";
import { Row } from "../types";
import type { RenderCellProps, RenderEditCellProps } from 'react-data-grid';
import { Field,  Number, NumberValue } from '../../../../core/graphql/types';
import SummaryRows from "../rows/summary";
import { GroupCell } from "../rows/group";
import { HeaderRenderer } from "../rows/header";
import NumberFormat, { NumberFormatValues } from "react-number-format";
import { useBoard } from "../../../../core/providers/board";
import { useSpaceStore } from "../../../../core/store/store";

interface BaseRenderCellProps<TRow, TSummaryRow> extends RenderCellProps<TRow, TSummaryRow> {
  field: Field;
}

interface BaseRenderEditCellProps<TRow, TSummaryRow> extends RenderEditCellProps<TRow, TSummaryRow> {
  field: Field;
}

// Column

export function phoneColumn({ field, canEditInline }: { field: Field; canEditInline: boolean }): Column<Row, any> {
  return {
    key: field.id,
    name: field.title,
    width: field.width,
    resizable: true,
    frozen: field.primary ? true : false,
    renderHeaderCell: (p) => { return <HeaderRenderer field={field} {...p}/> },
    renderCell: (p) => { return <PhoneCell {...p} field={field}/> },
    renderEditCell: (p) => { return canEditInline ? <PhoneCellEditor {...p} field={field}/>  : <></> },
    renderGroupCell: (p) => { return <GroupCell {...p} field={field}/> },
    renderSummaryCell: (p) => { return <SummaryRows {...p} field={field}/> },
    editorOptions: {
      displayCellContent: !canEditInline
    }
  }
};

// Cell

export function PhoneCell<Row, SummaryRow>({field, row, column}: BaseRenderCellProps<Row, SummaryRow>) {
  const value = (row[column.key as keyof Row] as unknown as NumberValue)?.valueAsNumber!
  return (
    <div className="justify-end text-sm p-2 items-start flex h-full w-full">
      <NumberFormat 
        format="+# (###) ###-####"
        value={value} 
        displayType={'text'} 
        thousandSeparator={false}
      />
    </div>
  );
}

// CellEditor

export function PhoneCellEditor<Row, SummaryRow>({field, row, column, onRowChange, onClose}: BaseRenderEditCellProps<Row, SummaryRow>) {  
    const [value, setValue] = useState((row[column.key as keyof Row] as unknown as NumberValue)?.valueAsNumber! ? (row[column.key as keyof Row] as unknown as NumberValue)?.valueAsNumber! : "")

    const {initId, initType} = useBoard();
    const init: string = `${initType}:${initId}`;
    const store = useSpaceStore()
    const {setEditMode} = store.boardActions
    const {rowHeight} = store.data[init];

    const height = rowHeight - 20
  
    function handleChange(e: NumberFormatValues) {
      setEditMode(true, init)
      setValue(e.floatValue!)
      onRowChange({ ...row, [column.key]: {...row[column.key as keyof Row], valueAsNumber: e.floatValue}})
      // if(timer) clearTimeout(timer);
      // setTimer(setTimeout(() => setValueForUpdate({...row[column.key as keyof Row], valueAsNumber: e.floatValue}, init), 500))
    }
  
    function handleBlur() {
      setEditMode(false, init)
      onClose(true)
    }
  
    return (
      <NumberFormat
        autoFocus
        type="tel"
        format="+# (###) ###-####"
        className={`w-full leading-5 text-sm text-right focus:outline-none p-2`}
        style={{paddingBottom: rowHeight - 28}}
        displayType="input"
        value={value}
        onFocus={()=>setEditMode(true, init)}
        onValueChange={(e) => handleChange(e)}
        onBlur={() => handleBlur()}
      />
    );
}

