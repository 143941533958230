import { useCreateCardAboveMutation, useCreateCardBelowMutation, useDuplicateCardMutation } from "../../../../core/graphql/types"
import toast from 'react-hot-toast';
import CardModal from "../../../Cards";
import { useBoard } from "../../../../core/providers/board";
import { useSpaceStore } from "../../../../core/store/store";

export default function SingleContextMenu(props:any) {
  const {initId, initType} = useBoard();
  const init: string = `${initType}:${initId}`;
  const store = useSpaceStore()
  const {setCardModalIsOpened, setModal} = store.boardActions
  const {currentView} = store.data[init];

  const {row, onClose} = props
  const [,addRowAbove] = useCreateCardAboveMutation()
  const [,addRowBelow] = useCreateCardBelowMutation()
  const [,duplicateRow] = useDuplicateCardMutation()

  function close() {
    setCardModalIsOpened(false, init)
  }

  function showCardModal(props:any) {
    setCardModalIsOpened(true, init)
    setModal(<CardModal open={true} close={close} cardId={props.row.id} boardId={props.row.boardId} initiator="ROW"/>, init)
  }

  function showNewCardModal(cardId: string, boardId: string) {
    setCardModalIsOpened(true, init)
    setModal(<CardModal open={true} close={close} cardId={cardId} boardId={cardId} initiator="NEW_ROW"/>, init)
  }

  function handleAddRowAbove() {
    toast.promise(
      addRowAbove({boardId: row.boardId, cardId: row.id}).then(resp => {
        onClose()
        return resp
      }),
      {
        loading: 'Добавляю запись...',
        success: (data) => {
          if (data.error) throw new Error();
          if(currentView?.filters && currentView?.filters!.length > 0) {
            showNewCardModal(data.data?.createCardAbove?.id!, data.data?.createCardAbove?.boardId!)
          }
          return 'Запись добавлена';
        },
        error: 'Ошибка. Не удалось создать запись.',
      }
    );
  }

  function handleAddRowBelow() {
    toast.promise(
      addRowBelow({boardId: row.boardId, cardId: row.id}).then(resp => {
        onClose()
        return resp
      }),
      {
        loading: 'Добавляю запись...',
        success: (data) => {
          if (data.error) throw new Error();
          if(currentView?.filters && currentView?.filters!.length > 0) {
            showNewCardModal(data.data?.createCardBelow?.id!, data.data?.createCardBelow?.boardId!)
          }
          return 'Запись добавлена';
        },
        error: 'Ошибка. Не удалось создать запись.',
      }
    );
  }

  function handleDuplicateRow() {
    toast.promise(
      duplicateRow({boardId: row.boardId, cardId: row.id}).then(resp => {
        onClose()
        return resp
      }),
      {
        loading: 'Копирую запись...',
        success: (data) => {
          if (data.error) throw new Error();
          return 'Запись скопирована';
        },
        error: 'Ошибка. Не удалось скопировать запись.',
      }
    );
  }

  function handleShowForm() {
    showCardModal(props)
    onClose()
  }

  return (
    <div className="bg-white w-56 h-fit flex p-3 rounded-md border shadow-lg text-sm font-medium text-slate-600">
      <ul className="w-full space-y-1.5">
        <li className="w-full">
          <button type="button" className="w-full text-left px-2 py-1.5 hover:bg-slate-100 rounded cursor-default" onClick={() => handleAddRowAbove()}>Вствить запись сверху</button>
        </li>
        <li className="w-full">
          <button type="button" className="w-full text-left px-2 py-1.5 hover:bg-slate-100 rounded cursor-default" onClick={() => handleAddRowBelow()}>Вствить запись снизу</button>
        </li>
        <hr className="mx-2"></hr>
        <li className="w-full">
          <button type="button" className="w-full text-left px-2 py-1.5 hover:bg-slate-100 rounded cursor-default" onClick={() => handleDuplicateRow()}>Дублировать запись</button>
        </li>
        <li className="w-full">
          <button type="button" className="w-full text-left px-2 py-1.5 hover:bg-slate-100 rounded cursor-default" onClick={() => handleShowForm()}>Показать форму</button>
        </li>
        <hr className="mx-2"></hr>
        <li className="px-2 py-1.5 hover:bg-slate-100 rounded cursor-default">Удалить запись</li>
      </ul>
    </div>
  )
}