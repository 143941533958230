import React, { useState, useEffect, useRef, Fragment, KeyboardEvent } from 'react';
import { Popper, usePopper } from 'react-popper';
import { HiX } from 'react-icons/hi';
import ReactDOM from 'react-dom';
import useOnClickOutside from '../../../core/hooks/useOnClickOutside';

// @ts-ignore
export const MatrPortal = ({ children }) => {
  const [container] = useState(() => document.createElement('div'));

  useEffect(() => {
    document.body.appendChild(container);
    return () => {
      document.body.removeChild(container);
    };
  }, []);

  return ReactDOM.createPortal(children, container);
};

// @ts-ignore

interface MatrDialogProps {
  children: JSX.Element;
  onClose: () => void;
  open: boolean;
}



export const MatrCardDialog = ({ children, onClose, open}: MatrDialogProps) => {
  if (!open) {
    return null;
  }

  return (
    <MatrPortal>
      <div className="fixed inset-0 overflow-y-auto" role="dialog" 
        // style={{zIndex: 21}}
      >
        <div className='relative h-full flex items-center justify-center py-14'>
          <div className="fixed inset-0 bg-black opacity-30 cursor-default" role="button" tabIndex={0} onClick={onClose}/>
          <div className="relative flex flex-col max-w-4xl w-full h-full text-left overflow-hidden transition-all transform bg-white shadow-lg rounded-lg" >{children}</div>
        </div>
      </div>
    </MatrPortal>
  );
};


export const MatrBaseDialog = ({ children, onClose, open}: MatrDialogProps) => {
  if (!open) {
    return null;
  }

  return (
    <MatrPortal>
      <div className="fixed inset-0 overflow-y-auto" role="dialog" style={{zIndex: 21}}>
        <div className='relative h-full flex items-start justify-center py-14'>
          <div className="fixed inset-0 bg-black opacity-30 cursor-default" role="button" tabIndex={0} onClick={onClose}/>
          <div className="max-w-lg inline-block w-full text-left align-middle transition-all transform bg-white shadow-lg rounded-lg p-8">
            {React.cloneElement(children as React.ReactElement<any>, { closeModal: onClose })}
          </div>
        </div>
      </div>
    </MatrPortal>
  );
};

export const MatrMediumDialog = ({ children, onClose, open}: MatrDialogProps) => {
  if (!open) {
    return null;
  }

  return (
    <MatrPortal>
      <div className="fixed inset-0 overflow-y-auto" role="dialog" style={{zIndex: 21}}>
        <div className='relative h-full flex items-start justify-center py-14'>
          <div className="fixed inset-0 bg-black opacity-30 cursor-default" role="button" tabIndex={0} onClick={onClose}/>
          <div className="max-w-2xl inline-block w-full text-left align-middle transition-all transform bg-white shadow-lg rounded-lg px-6 py-5">
            {React.cloneElement(children as React.ReactElement<any>, { closeModal: onClose })}
          </div>
        </div>
      </div>
    </MatrPortal>
  );
};


export const MatrSidebarDialog = ({ children, onClose, open}: MatrDialogProps) => {
  if (!open) {
    return null;
  }

  return (
    <MatrPortal>
      <div className="fixed inset-0 overflow-y-auto" role="dialog" style={{zIndex: 21}}>
        <div className='relative h-screen flex items-start justify-center py-14'>
          <div className="fixed inset-0 bg-black opacity-30 cursor-default" role="button" tabIndex={0} onClick={onClose}/>
          <div className="max-w-5xl inline-block w-full transition-all transform bg-white shadow-lg rounded-lg h-4/6">
            {React.cloneElement(children as React.ReactElement<any>, { closeModal: onClose })}
          </div>
        </div>
      </div>
    </MatrPortal>
  );
};

// @ts-ignore
export const MatrActionDialog = ({ onClose, open, children}) => {
  if (!open) {
    return null;
  }

  return (
    <MatrPortal>
      <div className="fixed inset-0 overflow-y-auto" role="dialog" style={{zIndex: 21}}>
        <div className='relative h-full flex items-center justify-center py-14'>
          <div className="fixed inset-0 bg-black opacity-30 cursor-default" role="button" tabIndex={0} onClick={onClose}/>
          <div className="relative flex flex-col max-w-4xl w-full h-full text-left overflow-hidden transition-all transform bg-white shadow-lg rounded-lg" >{children}</div>
        </div>
      </div>
    </MatrPortal>
  );
};


export const MatrDocumentDialog = ({ children, onClose, open}: MatrDialogProps) => {
  if (!open) {
    return null;
  }

  function handleKeyDown(e: React.KeyboardEvent<HTMLSpanElement>) {
    switch (e.key) {
      case 'Esc': 
        onClose();
        break;
      default:
        e.stopPropagation();
        break;
    }
  }

  return (
    <MatrPortal>
      <div className="fixed inset-0 overflow-y-auto" role="dialog" style={{zIndex: 21}}>
        <div className='relative h-full flex items-center justify-center py-14' onKeyDown={handleKeyDown}>
          <div className="fixed inset-0 bg-black opacity-30 cursor-default" role="button" tabIndex={0} onClick={onClose}/>
          <div className="relative flex flex-col max-w-4xl w-full h-full text-left overflow-hidden transition-all transform bg-white shadow-lg rounded-lg">
            {/* <button className="absolute flex justify-center z-10 items-center w-6 h-6 -right-3 -top-3 rounded-full bg-slate-200 hover:text-slate-300 text-slate-600 hover:bg-indigo-600" onClick={() => onClose()}><HiX size={18}/></button> */}
            <div className="overflow-hidden flex flex-col w-full h-full">
              {React.cloneElement(children as React.ReactElement<any>, { closeModal: onClose })}
            </div>
          </div>
        </div>
      </div>
    </MatrPortal>
  );
};

// @ts-ignore
export const MatrPopover = ({ onClose, reference, placement, children }) => {
  const popperRef = useRef(null);
  useOnClickOutside(popperRef, onClose)
  return (
    <MatrPortal>
      <div className='fixed inset-0 overflow-y-auto' style={{zIndex: 21}}>
      <Popper
        innerRef={popperRef}
        referenceElement={reference}
        placement={placement}
      >
        {({ ref, style }) => (
          <div ref={ref} style={{...style, zIndex: 21}}>
            {children}
          </div>
        )}
      </Popper>
      </div>
    </MatrPortal>
  );
};


// @ts-ignore
export const MatrTextPopover = ({onClose, reference, placement, children }) => {
  const popperRef = useRef(null);
  useOnClickOutside(popperRef, onClose)
  return (
    <MatrPortal>
      {/* <div className='fixed inset-0 overflow-y-auto' style={{zIndex: 21}}> */}
      <Popper
        innerRef={popperRef}
        referenceElement={reference}
        placement={placement}
      >
        {({ ref, style }) => (
          <div ref={ref} style={{...style, zIndex: 21}}>
            {children}
          </div>
        )}
      </Popper>
      {/* </div> */}
    </MatrPortal>
  );
};