import React, {useState} from "react";
import { useCreateSpaceMutation } from "../../../core/graphql/types";
import { useNavigate } from "react-router-dom";


function NewSpace() {
  let navigate = useNavigate();
  const [formState, setFormState] = useState({
    name: ''
  });
  const [errors, setErrors] = useState([])

  const [,createSpace] = useCreateSpaceMutation();

  function handleCreateSpace() {
    createSpace({
      name: formState.name
    }).then(res => {
      if(!res.data) {
        return
      } 
      navigate("/", { replace: true });
    })
  }


  return (
    <section className="w-full pt-4 px-8">
      <div className="max-w-2xl">
        <div className="flex flex-col w-full h-full">
          <div className="w-full flex justify-between items-center h-14">
            <h4 className="text-xl font-semibold">Создание пространства</h4>
          </div>
          <div className="relative w-full">
            <div className="relative space-y-1.5">
              <label className="matr-label">Название пространства</label>
              <input
                value={formState.name}
                onChange={(e) =>
                  setFormState({
                    ...formState,
                    name: e.target.value
                  })
                }
                className="matr-form-input"
                placeholder="Введите название пространства"
              />
            </div>
            <div className="flex justify-end mt-4">
              <button className="flex px-4 py-2 text-sm rounded-md bg-indigo-600 text-white" onClick={() => handleCreateSpace()}>Создать</button>
            </div>
          </div>
        </div> 
      </div>
    </section>

  )
}
export default NewSpace;