import { useSetCurrentSpaceMutation, Space } from '../../../core/graphql/types';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../../../core/providers/auth';
import { hashCode, intToRGB, hexToRGB } from '../../../utils';
import _ from "lodash";
import { Listbox, Transition } from '@headlessui/react';
import { Fragment, useState } from 'react';
import { TbArrowsUpDown } from 'react-icons/tb';
import { useSpaceStore } from '../../../core/store/store';

const space_menu = [
  {name: "Базовые настройки", path: "/space/settings"}, 
  {name: "Группы", path: "/space/groups"}, 
  {name: "Участники", path: "/space/users"},
  {name: "Роли и доступы", path: "/space/roles"},
  {name: "Телеграм бот", path: "/space/telegram"}
]

const SpaceMenu = () => {
  let location = useLocation();
  const {currentUser, setCurrentUser} = useAuth()
  const navigate = useNavigate()
  const [, setCurrentSpace] = useSetCurrentSpaceMutation();
  const space = currentUser.space

  const handleCurrentSpace = (space: Space) => {
    setCurrentSpace({spaceId: space?.spaceId!}).then(res => {
      setCurrentUser(res.data?.setCurrentSpace)
      navigate("/")
    })
  };

  return (
    <div className='px-2 pt-2'>
      <Listbox value={space} onChange={handleCurrentSpace}>
        <div className="relative">
          <Listbox.Button className="flex items-center text-base font-semibold text-slate-500 justify-between py-2 px-2 max-h-9 w-full">
            <span>{space.name}</span>
            <div className='flex'>
              <TbArrowsUpDown className='px-1 w-6 h-6 hover:bg-slate-200 rounded-sm'/>
            </div>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute mt-1 max-h-60 w-full z-10 overflow-auto rounded bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-base">
              {_.orderBy(currentUser.spaces, "insertedAt", 'asc').map((space, spaceIdx) => (
                <Listbox.Option
                  key={spaceIdx}
                  className={({ active }) =>
                    `relative flex items-center space-x-2 cursor-default select-none py-2 px-2 ${
                      active ? 'bg-indigo-100 text-indigo-900' : 'text-slate-800'
                    }`
                  }
                  value={space}
                >
                  {({ selected }) => (
                    <>
                      {selected ? (
                        <span className='w-4 h-4 rounded-sm' style={{backgroundColor: `${hexToRGB(intToRGB(hashCode(space?.name!)), 0.7)}`}} />
                      ) : <span className='w-4 h-4 rounded-sm' style={{backgroundColor: `${hexToRGB(intToRGB(hashCode(space?.name!)), 0.7)}`}} />}
                      <span
                        className={`block truncate ${
                          selected ? 'font-medium' : 'font-normal'
                        }`}
                      >
                        {space.name}
                      </span>
                    </>
                  )}
                </Listbox.Option>
              ))}
              <Listbox.Option 
                key="new-space" 
                className={({ active }) =>
                  `relative flex items-center space-x-2 cursor-default select-none py-2 px-2 ${
                    active ? 'bg-indigo-100 text-indigo-900' : 'text-slate-800'
                  }`
                }
                value={null}
              >
                <Link
                  to="/spaces/add"
                >
                  Создать пространство
                </Link>
              </Listbox.Option>
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
      {currentUser.role.name === "Administrator" ? 
        <div className="flex flex-col pt-1">
            {space_menu.map((item, index) => (
            <NavLink
                key={index}
                to={item.path}
                className={
                `px-2 py-1 w-full text-left text-base font-medium
                focus:outline-none rounded-sm
                ${item.path === location.pathname
                    ? 'bg-slate-200 text-indigo-600'
                    : 'text-slate-600 hover:bg-white/[0.12] hover:text-indigo-600'}`
                }
            >
                {item.name}
            </NavLink>
            ))}
        </div>
        :
        <></>
      }
    </div>
  );
};

export default SpaceMenu;