import {useState, useEffect, useCallback} from "react";
import CardEdit from "../Form";
import { HiOutlineChevronDown, HiOutlineChevronUp, HiOutlineXCircle } from "react-icons/hi";
import { transformValueToString } from "../../../core/Controllers/Base";
import { MatrCardDialog } from "../../ui/portal";
import { useBoard } from "../../../core/providers/board";
import { useSpaceStore } from "../../../core/store/store";
import { Row } from "../../Views/MattrGrid/types";
import { Field } from "../../../core/graphql/types";

interface CardInBoardProps {
  open: boolean;
  close: any;
  cardId: string;
  boardId: string;
  initiator?: string;
}

const CardInBoard = (props: CardInBoardProps) => {
  let {cardId, open, close} = props;

  const {initId, initType} = useBoard();
  const init: string = `${initType}:${initId}`;
  const store = useSpaceStore()
  const {fields, rows} = store.data[init];

  let [loading, setLoading] = useState(true);
  let [position, setPosition] = useState(0);
  let [currentRow, setCurrentRow] = useState<Row | any>();
  let primaryFieldId = fields?.find((f:Field) => f.primary === true)!.id!

  let [title, setTitle] = useState("")

  useEffect(() => {
    if(!rows || !rows.length) {
      return
    }
    let r = rows.find((r: Row) => r.id === cardId)

    if(!r){
      setLoading(false)
    } else {
      setCurrentRow(r)
    }
  },[rows])


  useEffect(() => {
    if(!currentRow) {
      return
    }
    setTitle(transformValueToString(currentRow![primaryFieldId]))
    setPosition(currentRow.position)
    setLoading(false)
  },[currentRow])

  const handleChangePosition = useCallback((pos: number) => {
    let r = rows.find((r: Row) => r.position === pos)
    setCurrentRow(r)
    setLoading(true) 
  }, [rows]);



  return (
    <MatrCardDialog open={open} onClose={close}>
      <div className="relative flex flex-col max-w-4xl w-full h-full text-left overflow-hidden transition-all transform bg-white shadow-lg rounded-lg">
        <div className="flex flex-row items-center h-16 bg-white justify-between px-4 border-b">
          <div className="flex flex-row h-full items-center gap-3">
            <div className="flex flex-row text-sm text-slate-400 items-center gap-1">
              { position === 0 ? <span><HiOutlineChevronUp size={18}/></span> : <span onClick={() => handleChangePosition(position - 1)}><HiOutlineChevronUp size={18}/></span>}
              { position === rows.length ? <span><HiOutlineChevronDown size={18}/></span> : <span onClick={() => handleChangePosition(position + 1)}><HiOutlineChevronDown size={18}/></span>}
            </div>
            <div className="flex flex-col py-3 px-3 h-full">
              <span className="text-md font-medium truncate h-8 text-slate-800">{title ? title : "Без названия"}</span>
              <span className="text-xs text-slate-400">Позиция: {position + 1}</span>
            </div>
          </div>
          <div className="text-sm text-slate-400 hover:text-indigo-600" onClick={() => close()}>
            <HiOutlineXCircle size={18}/>
          </div>
        </div>
        {!loading && <CardEdit row={currentRow} fields={fields}/>}
      </div>
    </MatrCardDialog>
  )
};

export default CardInBoard;