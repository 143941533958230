import { useState } from "react";
import { HiPlus } from "react-icons/hi";
import CardModal from "../../../Cards";
import { useBoard } from "../../../../core/providers/board";
import { BlockSourceField, BlockSourceTypeEnum, BoardView, BoardViewOptionsInput, CardWithValues, FieldType, Relation, useCreateCardWithValuesMutation } from "../../../../core/graphql/types";

export default function MonthDateHeader(props: { date: any; label: any; onDrillDown: any; drilldownView: any; }) {
  const {initId, initType, inBlock, block, currentView, relationPrimaryKey, board, cards, values, setEditMode, setCurrentCardId, setOpenCreateForm, setCurrentCardPrimaryKey, setCurrentCard, setCardModalIsOpened, setModal} = useBoard();
  const init: string = `${initType}:${initId}`;
  let {date, label, onDrillDown, drilldownView} = props;
  const [mouseOn, setMouseOn] = useState(false)
  const [,createCardWithValues] = useCreateCardWithValuesMutation()

  if (!drilldownView) {
    return <span>{label}</span>
  }

  function addEvent(): void {
    if(inBlock && block?.source?.type === BlockSourceTypeEnum.Field) {
      createCardWithValues({boardId: board!.id, values: [
        {
          fieldId: ((block.source as BlockSourceField).field?.attributes as Relation).foreginRelationFieldId!,
          type: FieldType.Relation,
          links: [{valueId: relationPrimaryKey!}]
        },
        {
          fieldId: (currentView?.options as BoardViewOptionsInput).stackedFieldId!,
          type: FieldType.Datetime,
          valueAsDatetime: date ? new Date(date).toISOString() : null
        }
      ]}).then(res => {
        if(res.error) { return }
        // setEditMode(true, init)
        // setCurrentCardId(res.data?.createCardWithValues?.id!, init)
        // setCurrentCardPrimaryKey(values.find(v => v.cardId === res.data?.createCardWithValues?.id! && v.primary === true)?.id!, init)
        // setCurrentCard({...(cards.find(c => c.id === res.data?.createCardWithValues?.id!) as CardWithValues), values: values.filter(v => v.cardId === res.data?.createCardWithValues?.id!)}, init)
        // setCardModalIsOpened(true, init)
      });
    } else {    
      createCardWithValues({boardId: board!.id, values: [
        {
          fieldId: (currentView?.options as BoardViewOptionsInput).stackedFieldId!,
          type: FieldType.Datetime,
          valueAsDatetime: date ? new Date(date).toISOString() : null
        }
      ]}).then(res => {
        if(res.error) { return }

        // function close() {
        //   setCardModalIsOpened(false, init)
        //   setEditMode(false, init)
        // } 

        // if(inBlock) {
        //   setCurrentCardId(res.data?.createCardWithValues?.id!, init)
        //   setCurrentCardPrimaryKey(values.find(v => v.cardId === res.data?.createCardWithValues?.id! && v.primary === true)?.id!, init)
        //   setCurrentCard({...(cards.find(c => c.id === res.data?.createCardWithValues?.id!) as CardWithValues), values: values.filter(v => v.cardId === res.data?.createCardWithValues?.id!)}, init)
        //   setEditMode(true, init)
        //   setCardModalIsOpened(true, init)
        // } else {
        //   setCardModalIsOpened(true, init)
        //   setEditMode(true, init)
        //   setModal(<CardModal open={true} close={close} cardId={res.data?.createCardWithValues?.id!} boardId={res.data?.createCardWithValues?.boardId!} initiator="ROW"/>, init)
        // }
      });
    }
  }

  return (
    <div className="flex w-full h-full justify-between px-2 py-1.5" onMouseLeave={() => setMouseOn(false)} onMouseEnter={() => setMouseOn(true)}>
      <button
        type="button"
        onClick={onDrillDown}
        role="cell"
      >
        {label}
      </button>
      {mouseOn && <button className="p-0.5 bg-slate-100 rounded-sm text-slate-400 hover:bg-indigo-100 hover:text-indigo-400" onClick={() => addEvent()}><HiPlus /></button>}
    </div>
  )
}