import React, { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import toast from 'react-hot-toast';

import { useConnectTelegramBotMutation} from "../../../../core/graphql/types";

export default function ConnectTelegramBot({closeModal, onChange}:{closeModal: any, onChange: any}) {
  const { register, handleSubmit, watch, control, setValue, formState: { errors } } =  useForm();
  const [,connectTelegramBot] = useConnectTelegramBotMutation();

  const onSubmit = handleSubmit(data => {
    toast.promise(
      connectTelegramBot({
        botToken: data.bot_token, 
      }).then(res => {
        if(res.error) {
          return res
        }
        onChange(res.data?.connectTelegramBot)
        closeModal();

        return res
      }),
      {
        loading: 'Подключаю бота...',
        success: (data) => {
          if (data.error) throw new Error();
          return 'Бот подключен';
        },
        error: 'Ошибка. Не удалось подключить бота.',
      }
    );

  });


  return (
    <form onSubmit={onSubmit} className="flex flex-col relative w-full space-y-4 p-6">
      <h2 className="text-lg font-semibold">Подключение бота к простанству</h2>
      <div className="text-xs text-gray-600">Описанние</div>
      <div>
        <label className="font-medium text-gray-600 text-sm">Токен:</label>
        <input {...register("bot_token", { required: true })}
          className="text-sm form-input mt-2 py-2.5 block w-full rounded-sm bg-gray-100 border-0 focus:bg-white focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
        />
        {errors.bot_name && <span className="text-red-600 text-xs font-normal">"Токен" обязательное поле.</span>}
      </div>
      <div className="flex justify-between w-full pt-6">
        <button
          type="button"
          className="inline-flex justify-end px-4 py-2 text-sm font-medium text-indigo-700 bg-indigo-100 border border-transparent rounded-md hover:bg-indigo-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-indigo-500"
          onClick={closeModal}
        >
          Отменить
        </button>
        <button
          type="submit"
          className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md hover:bg-indigo-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-indigo-500"
        >
          Создать
        </button>
      </div>
    </form>
  )
}