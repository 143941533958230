import { useEffect, useState } from "react";
import Avatar from "react-avatar";
import { Controller, useForm } from "react-hook-form";
import AvatarModalUploader from "../../../components/Uploaders/AvatarUploader";
import { BigSpinner } from "../../../components/loaders";
import MatrSelect from "../../../components/ui/select/base";
import { useGetUserQuery, useTimezonesQuery, useUpdateSpaceMutation } from "../../../core/graphql/types";
import { useAuth } from "../../../core/providers/auth";
import { TbCircleCheck } from "react-icons/tb";

const storage_url = process.env.REACT_APP_STORAGE_URL ? process.env.REACT_APP_STORAGE_URL : ""

export function AccountBase() {
  const {currentUser, setCurrentUser} = useAuth()
  const { register, handleSubmit, watch, setValue, control, formState: { errors } } = useForm();
  const [timezones, setTimezones] = useState<{label: string | null, value: string | null}[]>();
  const [{fetching: fetchingTimezones, data: dataTimezones}] = useTimezonesQuery()
  const [{fetching, data, error}, getUser] = useGetUserQuery();
  const [, updateSpace] = useUpdateSpaceMutation();
  const [avatar, setAvatar] = useState(currentUser.avatar)

  useEffect(() => {
    if(!data?.getUser) {return}
    setValue('firstName', data?.getUser.firstName || "")
    setValue('lastName', data?.getUser.lastName || "")
    setValue('username', data?.getUser.username || "")
    setValue('email', data?.getUser.email || "")
    setValue('timezone', data?.getUser.timezone || "")
    setValue('confirmed', data?.getUser.confirmedAt ? true : false)
    
    setAvatar(data?.getUser.avatar ? `${data?.getUser.avatar}?${Date.now()}` : "")
    setCurrentUser({...currentUser, avatar: `${currentUser.avatar}?${Date.now()}`})
  },[data?.getUser, setValue])

  useEffect(() => {
    if(!dataTimezones?.timezones || !dataTimezones?.timezones.length) {return}
    const timezoneList = 
    dataTimezones?.timezones!.map(t => {
      return {label: t, value: t}
    })

    setTimezones(timezoneList)
  },[dataTimezones?.timezones])

  const onSubmit = handleSubmit(data => {
    updateSpace({
      name: data.name,
      private: data.private
    }).then(result => {
      setCurrentUser(
        {...currentUser, 
          space: {...currentUser.space, name: result.data?.updateSpace?.name}, 
          spaces: currentUser.spaces.map((space: any) => {
            if(space.spaceId === result.data?.updateSpace?.spaceId) {
              return {...space, name: result.data?.updateSpace?.name}
            } else {
              return space
            }
          })
        }
      )
    })
  });

  function onChangeAvatar() {
    getUser()
  }

  const selectStyles = {
    control: () => ({border: 0, height: 36, borderRadius: 2})
  }

  if (fetching && fetchingTimezones) return <BigSpinner/>;
  if (error) return <>`Error! ${error.message}`</>;
  

  return (
    <div className="w-full">
      <div className="flex pb-8 w-full justify-center">
        <div className="bg-white w-40">
          <div className="mb-4 w-full">
            {avatar && storage_url ? 
            <img className="w-32 h-32 mx-auto rounded-full object-cover object-center" src={`${storage_url}${avatar}`} alt="avatar"></img>
            :
            <Avatar className="w-auto mx-auto rounded-full object-cover object-center" size="150" name={`${currentUser.firstName} ${currentUser.lastName}`}/>
            }
          </div>
          <AvatarModalUploader onChange={onChangeAvatar}/>
        </div>
      </div>
      <form onSubmit={onSubmit} className="flex flex-col relative w-full space-y-4 max-w-[900px]">
        <div className="relative space-y-1.5">
          <label className="matr-label">Имя</label>
          <input {...register("firstName", 
            { required: true})}
            className="matr-form-input" 
            placeholder="Имя"
          />
          {errors.name && <span className="text-red-600 text-xs font-normal px-2">Не может быть пустым</span>}
        </div>
        <div className="relative space-y-1.5">
          <label className="matr-label">Фамилия</label>
          <input {...register("lastName", 
            { required: true})}
            className="matr-form-input" 
            placeholder="Фамилия"
          />
          {errors.name && <span className="text-red-600 text-xs font-normal px-2">Не может быть пустым</span>}
        </div>
        <div className="relative space-y-1.5">
          <label className="matr-label">Имя в Mattr</label>
          <input {...register("username", 
            { required: true})}
            className="matr-form-input" 
            placeholder="Имя пользователя"
          />
          {errors.name && <span className="text-red-600 text-xs font-normal px-2">Не может быть пустым</span>}
        </div>
        <div className="relative space-y-1.5">
          <label className="matr-label">Электронная почта</label>
          <input {...register("email", 
            { required: true, disabled: true})}
            className="matr-form-input"
            placeholder="Электронная почта"
          />
          {
            watch('confirmed') ?
            <div className="text-green-600 absolute bottom-2 right-2"><TbCircleCheck /></div>
            :
            <div className="flex flex-col w-full bg-blue-200 rounded text-sm text-blue-700 p-2">
              <div className="font-semibold text-base text-blue-700 mb-2">Внимание: Почта не подтверждена</div>
              <div className="text-sm text-blue-600">
                Для того чтобы получать уведомления на почту Вам надо подтвердить Ваш адрес.
              </div>
            </div>
          }
        </div>
        <div className="relative space-y-1.5">
        <label className="matr-label">Временная зона</label>
          <Controller
            name="timezone"
            control={control}
            render={({ field: { onChange, value, ref }}) => (
              <div className="mt-2">
                <MatrSelect
                  placeholder="Выберите часовой пояс"
                  isSearchable={true}
                  value={value ? {label: value, value: value} : ""}
                  onChange={onChange}
                  options={timezones}
                />
              </div>
            )}
          />
        </div>
        <div className="relative flex justify-end pt-4">
          <button
            type="submit"
            className="flex items-center h-full text-sm justify-center px-4 py-2 bg-indigo-600 rounded text-white font-medium"
          >
            Сохранить
          </button>
        </div>
      </form>
    </div>
  )
}