import { useState } from "react";
import { MatrMediumDialog } from "../../../../../components/ui/portal";
import { usePanel } from "../../../../../core/providers/panel";
import { BlockCreate } from "../../blocks/Forms/createBlock";

export function LayoutBlankMenu() {
    const {page} = usePanel()
    const [ openMenu, setOpenMenu ] = useState(false);
    const [ buttonRef, setButtonRef ] = useState<any>()
  
    function onOpenMenu(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) { setOpenMenu(true)}
    function closeMenu() { setOpenMenu(false) };

    return (
        <div className="flex flex-row w-full space-x-2 justify-end px-4 py-2">
            <button 
            className="bg-slate-200 rounded text-slate-400 flex z-10 px-2 py-1 text-sm font-medium hover:bg-indigo-600 hover:text-white" 
            ref={setButtonRef}
            onClick={(e) => onOpenMenu(e)}
            >
                Добавить блок
            </button>

            {openMenu && 
            <MatrMediumDialog open={openMenu} onClose={closeMenu}>
                <BlockCreate inBlock={null} onClose={closeMenu}/>
            </MatrMediumDialog>
            }
        </div>
    )
}