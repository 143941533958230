import { Tab } from "@headlessui/react";
import _ from "lodash";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import useDeepCompareEffect from "use-deep-compare-effect";
import { MatrMediumDialog } from "../../../../../../components/ui/portal";
import { Block, BlockFieldOptions, BlockFormOptions, BlockFormTypeEnum, BlockSourceBlock, BlockSourceBoard, CardWithValues } from "../../../../../../core/graphql/types";
import { usePanel } from "../../../../../../core/providers/panel";
import { useSpaceStore } from "../../../../../../core/store/store";
import { getKeyForType } from "../../../../../../utils";
import { BlockCreate } from "../../Forms/createBlock";
import { BlockEdit } from "../../Forms/editBlock";
import BlockFormFields from "../fields";
import { SimpleFormBlockEdit } from "./simple";
import { TabRelationBlock } from "./tab";
import "/node_modules/react-grid-layout/css/styles.css";
import "/node_modules/react-resizable/css/styles.css";


function FormBlockEdit({formBlock}:{formBlock: Block}) {
  const init = `block:${formBlock.id}`
  let spaceStore = useSpaceStore()
  let store = spaceStore.data[init]

  const { page, editorMode, storeFormBlock } = usePanel()
  const [ openMenu, setOpenMenu ] = useState(false);
  const [ openSettings, setOpenSettings ] = useState(false);
  const [ openFields, setOpenFields ] = useState(false);

  const methods = useForm()
  const {getValues, reset, formState, control} = methods
  const [defValues, setDefValues] = useState<Record<string, any>>()
  const [loading, setLoading] = useState(true)
  const [formBlocks, setFormBlocks] = useState<Block[] | []>(_.orderBy((page?.blocks?.filter(b => b?.parentId === formBlock?.id && b.isPage === false)! as Block[]), item => item?.position?.x, "asc"))
  const [tabBlocks, setTabBlocks] = useState<Block[] | []>(_.orderBy((page?.blocks?.filter(b => b?.parentId === formBlock?.id && b.isPage === true)! as Block[]), item => item?.position?.x, "asc"))

  function onOpenMenu(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) { setOpenMenu(true)}
  function closeMenu() { setOpenMenu(false) };

  function onOpenSettings(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) { setOpenSettings(true)}
  function closeSettings() { setOpenSettings(false) };

  function onOpenFields(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) { setOpenFields(true)}
  function closeFields() { setOpenFields(false) };

  useDeepCompareEffect(() => {
    if(!(formBlock?.options as BlockFormOptions).contentColumns) {return}
    setFormBlocks(_.orderBy((page?.blocks?.filter(b => b?.parentId === formBlock?.id && b.isPage === false)! as Block[]), item => item?.position?.x, "asc"))
    setTabBlocks(_.orderBy((page?.blocks?.filter(b => b?.parentId === formBlock?.id && b.isPage === true)! as Block[]), item => item?.position?.x, "asc"))
  },[page?.blocks, formBlock])

  useEffect(() => {
    if(!store || !store.relationCardId) { return }
    let form: Record<string, any> = {}
    let values = store.cards.find((card: CardWithValues) => card?.id === store.relationCardId).values

    values.map((v: any) => {
      form[v?.fieldId!] = v[getKeyForType(v?.type!)]
    })

    setDefValues(form)
    reset(form)
    setLoading(false)
  },[store.relationCardId, reset])

  // Обновление

  // const debouncedSave = useCallback(
  //   debounce(() => {
  //     storeFormBlock(prepareValuesForSave(getValues())) 
  //   }, 1000),
  //   [store.relationCardId]
  // );

  // const watchedData = useWatch({
  //   control: control,
  //   defaultValue: defValues
  // });

  // useDeepCompareEffect(() => {
  //   if (formState.isDirty && formState.isValid) {
  //     debouncedSave()
  //   }
  // }, [watchedData]);


  // function prepareValuesForSave(values: any) {
  //   let data = (formBlocks?.filter(fb => fb.type === BlockTypeEnum.BlockField) as Block[]).map((bf) => (
  //     {...{fieldId: (bf.source as BlockSourceField)?.fieldId!, type: (bf.source as BlockSourceField)?.field?.type!}, value: valueToString(values[(bf.source as BlockSourceField)?.field?.id!], (bf.source as BlockSourceField)?.field?.type! as any)} 
  //   ))
  //   return {boardId: (formBlock.source as BlockSourceField).boardId!, cardId: store.relationCardId, values: data}
  // }

  return (
    loading && !defValues ? <></> : 
    <>
      <div className="w-full h-full relative">
        {editorMode && 
          <div className="absolute right-0">
            <div className="flex flex-row w-full justify-end space-x-2 py-2 px-2">
              <button 
                className="bg-slate-200 rounded text-slate-400 flex px-2 py-1 text-sm font-medium hover:bg-indigo-600 hover:text-white" 
                onClick={(e) => onOpenSettings(e)}
                >
                    Настройка
              </button>
              <button 
                className="bg-slate-200 rounded text-slate-400 flex px-2 py-1 text-sm font-medium hover:bg-indigo-600 hover:text-white" 
                onClick={(e) => onOpenFields(e)}
                >
                    Поля
              </button>
              {(formBlock.options as BlockFormOptions).formType === BlockFormTypeEnum.Edit && <button 
                className="bg-slate-200 rounded text-slate-400 flex px-2 py-1 text-sm font-medium hover:bg-indigo-600 hover:text-white" 
                onClick={(e) => onOpenMenu(e)}
                >
                    Добавить блок
              </button>}
            </div>
          </div>
        }
        <div className="flex flex-col overflow-scroll h-full w-full py-10">
          {(formBlock.options as BlockFormOptions).showTitle ?
            <div className={`max-w-full w-[900px] mx-auto space-y-2 flex flex-col text-xl font-semibold py-4 px-4`}>{(formBlock.options as BlockFormOptions).title}</div> 
            : 
            <></>
          }
          <FormProvider {...methods}>
            {tabBlocks.length > 0 ?
              <Tab.Group>
                <Tab.List className="text-base font-semibold border-b mb-4 max-w-full w-[900px] mx-auto">
                  <Tab >{({ selected }) => (<div className={`px-3 py-1.5  font-medium ${selected ? "text-indigo-600 border-b-2 border-indigo-600" : "text-slate-600"} `}>Общие</div>)}</Tab>

                  {tabBlocks.map(rb => (
                    <Tab >{({ selected }) => (<div className={`px-3 py-1.5  font-medium ${selected ? "text-indigo-600 border-b-2 border-indigo-600" : "text-slate-600"} `}>{(rb.options as BlockFieldOptions).showTitle ? (rb.options as BlockFieldOptions).title : "Без названия"}</div>)}</Tab>
                  ))}
                </Tab.List>
                <Tab.Panels>
                  <Tab.Panel>
                    <SimpleFormBlockEdit formBlocks={formBlocks} setFormBlocks={setFormBlocks}/>
                  </Tab.Panel>
                  {tabBlocks.map(rb => (
                    <Tab.Panel>
                      <div 
                        className={`max-w-[900px] mx-auto bg-white hover:bg-slate-100 relative p-2 w-full mb-2`} 
                      >
                        <TabRelationBlock relationBlock={rb}/>
                      </div>
                    </Tab.Panel>
                  ))}
                </Tab.Panels>
              </Tab.Group>
              :
              <SimpleFormBlockEdit formBlocks={formBlocks} setFormBlocks={setFormBlocks}/>
            }
          </FormProvider>
        </div>
      </div>
      {openSettings && 
        <MatrMediumDialog open={openSettings} onClose={closeSettings}>
          <BlockEdit block={formBlock} onClose={closeSettings}/>
        </MatrMediumDialog>
      }
      {openFields && 
        <MatrMediumDialog open={openFields} onClose={closeFields}>
          <BlockFormFields id={formBlock.id} boardId={(formBlock.source as BlockSourceBlock | BlockSourceBoard).boardId!} onClose={closeFields}/>
        </MatrMediumDialog>
      }
      {openMenu && (formBlock.options as BlockFormOptions).formType === BlockFormTypeEnum.Edit &&
        <MatrMediumDialog open={openMenu} onClose={closeMenu}>
          <BlockCreate inBlock={formBlock} onClose={closeMenu}/>
        </MatrMediumDialog>
      }
    </>
  );
}

export default FormBlockEdit;