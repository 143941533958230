import { Field } from "../../../../core/graphql/types";
import Formatter from "../../Formatters";
import { transformValueToString } from "../../../../core/Controllers/Base";

interface RelationExternalListItemProps {
  card: any;
  fields: any;
  onChange: any;
}

const RelationExternalListItem = ({card, fields, onChange}: RelationExternalListItemProps) => {
  function getValues(values: any) {
    let primary = values.find((v: any) => v.primary === true)
    let additional = values.filter((v: any) => v.primary === false)

    return (
      <>
        <div className="flex flex-col text-slate-800 font-medium">
          {primary.value}
        </div>
        <div className="grid grid-cols-3 space-x-2 truncate w-full">
          {additional.map((av: any) => 
            av.value && <div key={av.id} className="text-xs truncate text-slate-600">{av.value}</div>
          )}
        </div>
      </>
    )
  }
  

  return (
    <div 
      key={card.id} 
      className={`flex flex-col items-start px-3 py-2 w-full space-y-2 rounded-sm text-sm cursor-default ring-1 ring-slate-200 hover:bg-slate-100 ${card.selected ? "ring-1 inset-2 bg-indigo-100 ring-indigo-600" : "bg-slate-50"}`}
      onClick={() => onChange(card)}
      >
        {getValues(fields)}
    </div>
  )

}

export default RelationExternalListItem;