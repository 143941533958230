import { HiArrowCircleLeft } from 'react-icons/hi';
import { Link, useFetcher } from 'react-router-dom';
import { useAuth } from '../../core/providers/auth';
import { TbEye, TbEyeOff, TbSettings } from 'react-icons/tb';
import { useFetcherContext } from '../../core/providers/fetcher';


const ServiceHeader = () => {
  const {fetcher} = useFetcherContext();
  return (
    <>
      <header className="relative flex w-full h-14 items-center border-b">
        <div className="flex w-full my-2 items-center h-full justify-between">
          <div className='flex w-full items-center'>
            <div className="flex-none h-full px-4">
              <div className='flex h-full justify-start items-center'>
                <Link to="/services" className="flex font-medium text-gray-900 lg:w-auto items-center justify-center space-x-2">
                  <HiArrowCircleLeft size={22} className="text-slate-400 hover:text-slate-600"/>
                  <span className="mx-auto text-base font-bold leading-none text-gray-900 select-none">Mattr<span className="text-indigo-600">.</span></span>
                </Link>
              </div>
            </div>
            <div className="flex h-10 w-full items-center justify-between border-l space-x-2 cursor-default px-4">
              <div className='flex items-center'>
                <div className='text-base font-medium text-slate-600 cursor-default flex flex-col space-x-2'>
                  <span>{fetcher?.title}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  )
};

export default ServiceHeader;