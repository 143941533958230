import { Block, BlockSourceTypeEnum, BlockTypeEnum } from "../../../../core/graphql/types";
import {CalendarBlock, CalendarBlockWithField} from "./BlockCalendar";
import FieldBlock from "./BlockField";
import {GridBlock, GridBlockWithField} from "./BlockGrid";
import { KanbanBlock, KanbanBlockWithField } from "./BlockKanban";
import ListBlock from "./BlockList";
import RecordsListBlock from "./BlockRecordsList";
import SectionBlock from "./BlockSection";
import { SummaryBlock, SummaryBlockWithField } from "./BlockSummary";

interface PageBlockType {
  pageBlock: Block
}

const PageBlock = ({pageBlock}:PageBlockType) => {
  switch (pageBlock?.type!) {
    case BlockTypeEnum.BlockCalendar: return pageBlock.source?.type == BlockSourceTypeEnum.Field ? <CalendarBlockWithField block={pageBlock!}/> : <CalendarBlock block={pageBlock!}/>
    case BlockTypeEnum.BlockKanban: return pageBlock.source?.type == BlockSourceTypeEnum.Field ? <KanbanBlockWithField block={pageBlock!}/> : <KanbanBlock block={pageBlock!}/>
    case BlockTypeEnum.BlockGrid: return pageBlock.source?.type == BlockSourceTypeEnum.Field ? <GridBlockWithField block={pageBlock!}/> : <GridBlock block={pageBlock!}/>
    case BlockTypeEnum.BlockList: return <ListBlock block={pageBlock!}/>
    case BlockTypeEnum.BlockRecordsList: return <RecordsListBlock block={pageBlock!}/>
    case BlockTypeEnum.BlockField: return <FieldBlock block={pageBlock}/>
    case BlockTypeEnum.BlockSection: return <SectionBlock sectionBlock={pageBlock}/>
    case BlockTypeEnum.BlockSummary: return pageBlock.source?.type == BlockSourceTypeEnum.Field ? <SummaryBlockWithField block={pageBlock!}/> : <SummaryBlock block={pageBlock!}/>
    default: return <></>
  }
}

export default PageBlock;