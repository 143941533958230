import { useFormContext, Controller } from "react-hook-form";
import MatrSelect from "../../../../components/ui/select/base";
import { useSpace } from "../../../../core/providers/space";
import { Role } from "../../../../core/graphql/types";
import { MultiValue } from "react-select";


const PanelFormInfo = () => {
  const {roles} = useSpace()
  const {register, control, formState: {errors}} = useFormContext()

  function handleChangeRoles(e: MultiValue<Role>, onChange: (...event: any[]) => void) {
    onChange(e.map(r => r.id))
  }

  return (
    <>
      <div className="relative flex flex-col space-y-1.5 pt-4">
        <input 
          {...register("name", {required: true})} 
          className="text-xl font-medium text-slate-800 placeholder:text-slate-300 focus:outline-none mb-4" 
          placeholder="Название раздела"
        />
        {errors.name && <span className="text-red-600 text-xs font-normal">"Название раздела" обязательное поле.</span>}
      </div>
      <div className="relative">
        <label className="font-medium text-gray-600 text-sm">Для ролей:</label>
        <Controller
          name="roles"
          control={control}
          rules={{required: true}}
          render={({ field: { onChange, value }}) => (
            <div className="mt-1.5">
              <MatrSelect
                isMulti={true}
                getOptionLabel={(option) => option?.name!}
                getOptionValue ={(option)=> option?.id!}
                value={value && value.length > 0 ? roles.filter(r => value.includes(r.id)) : []}
                onChange={(e) => handleChangeRoles(e, onChange)}
                options={roles}
              />
            </div>
          )}
        />
        {errors.roles && <span className="text-red-600 text-xs font-normal">Должна быть выбрана хоть одна роль.</span>}
      </div>
    </>
  )

}

export default PanelFormInfo;