import { useFormContext, Controller } from 'react-hook-form';
import { useFetcherContext } from '../../../../../core/providers/fetcher';
import { isVariable, isVariableExist } from '../../../../../core/Controllers/Service';
import { TbExchange } from 'react-icons/tb';

export default function AuthBearer():JSX.Element {
  const {fetcher} = useFetcherContext()
  const methods = useFormContext();
  const {control} = methods;

  function handleChangeCheckbox(value: any, onChange: { (...event: any[]): void; (arg0: any): void; }) {
    onChange(!value)
  }

  return (
    <div className='flex flex-col space-y-2 mt-4'>
      <Controller
        name={`authorization.dynamic`}
        control={control}
        rules={{required: true}}
        render={({ field: { onChange, value, ref }}) => (
          <div className='flex items-center space-x-2'>
            <div 
              className={`flex-none w-7 items-center justify-center py-1.5 px-1.5 text-sm ${value ? "bg-green-300 text-green-800" : "bg-slate-200 text-slate-600"} rounded font-normal justify-between w-full cursor-default`} 
              onClick={() => handleChangeCheckbox(value, onChange)}
            >
              {value ? <TbExchange className='h-4 w-4 text-green-800'/> : <TbExchange className='h-4 w-4 text-slate-400'/>}
            </div>
            <div className='text-base'>Разрешить динамически изменять из процессов</div>
          </div>
        )}
      />
      <Controller
        name={`authorization.token`}
        control={control}
        rules={{required: true, minLength: 1}}
        render={({ field: { onChange, value, ref } }) => (
          <div className="relative flex flex-col">
            <label className="font-medium text-slate-600 text-sm mb-2">Токен</label>
            <input
              value={value!}
              onChange={onChange}
              placeholder="Токен"
              className={`matr-form-narrow-input ${isVariable(value) ? isVariableExist(value, fetcher?.variables!) ? "!text-blue-700" : "!text-red-400" : ""}`}
            />
          </div>
        )} 
      />
      <Controller
        name={`authorization.prefix`}
        control={control}
        rules={{minLength: 1}}
        render={({ field: { onChange, value, ref } }) => (
          <div className="relative flex flex-col">
            <label className="font-medium text-slate-600 text-sm mb-2">Префикс</label>
            <input
              value={value}
              onChange={onChange}
              placeholder="Префикс"
              className={`matr-form-narrow-input ${isVariable(value) ? isVariableExist(value, fetcher?.variables!) ? "!text-blue-700" : "!text-red-400" : ""}`}
            />
          </div>
        )} 
      />
    </div>
  )
}