import { useEffect, useState } from "react";
import { Column } from "react-data-grid";
import { Row } from "../types";
import type { RenderCellProps, RenderEditCellProps } from 'react-data-grid';
import { Field, LinkItem, Maybe, RelationValue, StringValue } from '../../../../core/graphql/types';
import SummaryRows from "../rows/summary";
import { GroupCell } from "../rows/group";
import { HeaderRenderer } from "../rows/header";
import { useBoard } from "../../../../core/providers/board";



interface RenderStringCellProps<TRow, TSummaryRow> extends RenderCellProps<TRow, TSummaryRow> {
  field: Field;
}

interface RenderEditStringCellProps<TRow, TSummaryRow> extends RenderEditCellProps<TRow, TSummaryRow> {
  field: Field;
}

// Column

export function lookupColumn({ field, canEditInline }: { field: Field; canEditInline: boolean }): Column<Row, any> {
  return {
    key: field.id,
    name: field.title,
    width: field.width,
    resizable: true,
    frozen: field.primary ? true : false,
    renderHeaderCell: (p) => { return <HeaderRenderer field={field} {...p}/> },
    renderCell: (p) => { return <LookupCell {...p} field={field}/> },
    renderGroupCell: (p) => { return <GroupCell {...p} field={field}/> },
    renderSummaryCell: (p) => { return <SummaryRows {...p} field={field}/> },
    editorOptions: {
      displayCellContent: !canEditInline
    }
  }
};

// Cell

export function LookupCell<Row, SummaryRow>({row, column}: RenderStringCellProps<Row, SummaryRow>) {
  const value = (row[column.key as keyof Row] as unknown as RelationValue)?.links! as Maybe<LinkItem>[]
  return (
    <div className="flex h-full w-full text-sm items-start px-2 py-1.5 space-x-1">
      {value?.map(v => (
        v?.value?.value && <div key={v?.valueId} className="py-0.5 rounded-sm bg-blue-50 px-1">
          {v?.value?.value}
        </div>
      ))}
    </div>
  )
}