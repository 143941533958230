import { HiOutlineMenu, HiOutlineTrash, HiOutlineEyeOff } from "react-icons/hi";
import { VscEdit } from "react-icons/vsc";
import { BsSnow } from "react-icons/bs";
import { Menu } from '@headlessui/react';
import { useEffect, useState } from "react";
import type {CalculatedColumn} from "react-data-grid";
import { Field } from "../../../../core/graphql/types";

interface ColumnContextMenuProps<R> {
  field: Field;
  column: CalculatedColumn<R>;
  width: number;
  handleClick: (event: any, action: string) => void;
}

function ColumnContextMenu<R>({width, handleClick, column, field}: ColumnContextMenuProps<R>) {
  const [columnWidth, setColumnWidth] = useState(width)

  useEffect(() => {setColumnWidth(width)}, [width])

  return (
      <div style={{width: columnWidth + "px", minWidth: 180}}>
        <div className="flex flex-col text-sm font-normal shadow rounded-sm rounded-t-none bg-slate-600 py-1">

            <div className="hover:bg-slate-700 px-2 py-2 text-xs text-white flex flex-row items-center" onClick={(event)=>handleClick(event, "edit")}>
              <VscEdit width={16} height={16} className="mx-1"/>
              <span className="pl-2">Изменить колонку</span>
            </div>

          {/* <Menu.Item>
            <div className="hover:bg-gray-100 px-2 py-2" onClick={(event)=>handleClick(event, "left")}>
              Ваствить слева
            </div>
          </Menu.Item> */}
          {/* <Menu.Item >
            <div className="hover:bg-gray-100 px-2 py-2" onClick={(event)=>handleClick(event, "right")}>
              Ваствить спарава
            </div>
          </Menu.Item> */}

            <div className="hover:bg-slate-700 px-2 py-2 text-xs text-white flex flex-row items-center" onClick={(event)=>handleClick(event, "hide")}>
              <HiOutlineEyeOff width={16} height={16} className="mx-1"/>
              <span className="pl-2">Спрятать</span>
            </div>

            <div className="hover:bg-slate-700 px-2 py-2 text-xs text-white flex flex-row items-center" onClick={(event)=>handleClick(event, "freez")}>
              <BsSnow width={16} height={16} className="mx-1"/>
              <span className="pl-2">Заморозить</span>
            </div>

          {field.primary ? <></> :
              <div className="hover:bg-slate-700 px-2 py-2 text-xs text-white flex flex-row items-center" onClick={(event)=>handleClick(event, "remove")}>
                <HiOutlineTrash width={16} height={16} className="mx-1"/>
                <span className="pl-2">Удалить</span>
              </div>
          }
        </div>
      </div>
  )

}

export default ColumnContextMenu;