import { useEffect, useState } from "react";
import { TbArrowLeftBar, TbFilePlus, TbFilter, TbSearch, TbSquareRoundedArrowLeft } from "react-icons/tb";
import useDeepCompareEffect from "use-deep-compare-effect";
import { SmallSpinner } from "../../../../../components/loaders";
import { MatrMediumDialog } from "../../../../../components/ui/portal";
import { Block, BlockFormOptions, BlockFormTypeEnum, BlockRecordsListOptions, BlockSourceBoard, BlockTypeEnum, Board, CardWithValues, ViewType } from "../../../../../core/graphql/types";
import useDebounce from "../../../../../core/hooks/debounce/useDebounce";
import { MattrDataProvider } from '../../../../../core/providers/data';
import { usePanel } from "../../../../../core/providers/panel";
import { BlockSubscriptionProvider } from '../../../../../core/providers/subscriptions/block';
import { DataStore, dataInitialState, useSpaceStore, userViewInitial } from "../../../../../core/store/store";
import FormBlock from "../BlockForm";
import { BlockEdit } from "../Forms/editBlock";
import List from "./list";
import { BlockRecordsListActions } from "./actions";
import { BoardProvider } from "../../../../../core/providers/board";


export default function RecordsListBlock({block}:{block: Block}) { 
  const {page, editorMode} = usePanel()
  const init = `${"block"}:${block.id}`
  const store = useSpaceStore()
  const {addBoardData, resetDataItem, setCurrentCardId, setCurrentCardPrimaryKey} = store.boardActions
  const storeData = store.data
  const [showIndex, setShowIndex] = useState(true)

  const [ openSettings, setOpenSettings ] = useState(false);
  let [createForm, setCreateForm] = useState<Block>()
  let [updateForm, setUpdateForm] = useState<Block>()
  let [addCard, setAddCard] = useState<boolean>(false)

  useDeepCompareEffect(() => {
    if(!block.id) { return }
    setCreateForm(page.blocks?.find(b => b?.parentId === block?.id && b?.type === BlockTypeEnum.BlockForm && (b?.options as BlockFormOptions).formType === BlockFormTypeEnum.Create)!)
    setUpdateForm(page.blocks?.find(b => b?.parentId === block?.id && b?.type === BlockTypeEnum.BlockForm && (b?.options as BlockFormOptions).formType === BlockFormTypeEnum.Edit)!)
  },[page])


  useDeepCompareEffect(() => {
    resetDataItem(init)
    let newObject: Record<string, DataStore> = {}
    newObject[init] = {
      ...dataInitialState,
      block: block,
      board: {id: (block.source as BlockSourceBoard).boardId} as Board,
      currentView: {
        type: ViewType.List, 
        filters: (block?.source as any)?.filters, 
        hiddenFields: [],
        permissions: block.permissions!, 
        options: (block.options as BlockRecordsListOptions)
      },
      userCurrentView: store.currentSpaceUser?.blocks?.find(cub => cub?.blockId === block.id) ? store.currentSpaceUser?.blocks?.find(cub => cub?.blockId === block.id)! : userViewInitial,
      inBlock: true,
      showModalAs: "modal",
      initId: block.id!, 
      initType: "block", 
      boardIsLoading: false
    }

    addBoardData(newObject)
    setShowIndex(true)
  },[block])


  function onOpenSettings(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) { setOpenSettings(true)}
  function closeSettings() { setOpenSettings(false) };

  function showAddForm() {
    setAddCard(true)
    setShowIndex(false)
  }

  function onCardChange(card: CardWithValues | null) {
    setCurrentCardId(card?.id!, init)
    setCurrentCardPrimaryKey(card?.values?.find(v => v?.cardId === card.id && v?.primary === true)?.id!, init)
    setAddCard(false)
    setShowIndex(false)
  }

  function backToIndex() {
    setCurrentCardId(null, init)
    setCurrentCardPrimaryKey(null, init)
    setAddCard(false)
    setShowIndex(true)
  }

  return (
    <>
      {(init in storeData) ? 
        <>
          <MattrDataProvider initiator={{id: block.id!, type: "block"}}>
            <BlockSubscriptionProvider blockId={block.id!} boardId={(block.source as  BlockSourceBoard).boardId!}>
              <BoardProvider>
                {showIndex ? 
                  <div className="w-full h-full relative">
                    {editorMode ?
                      <div className="absolute right-0">
                        <div className="flex flex-row w-full justify-end space-x-2 py-2 px-2">
                          <button 
                            className="bg-slate-200 rounded text-slate-400 flex px-2 py-1 text-sm font-medium hover:bg-indigo-600 hover:text-white" 
                            onClick={(e) => onOpenSettings(e)}
                            >
                              Настройка
                          </button>
                        </div>
                      </div> : <></>
                    }
                    <div className="flex-none flex flex-col w-full overflow-scroll h-full py-10">
                      {block.isPage ?
                        <>
                          {(block.options as BlockRecordsListOptions).showTitle ?
                            <div className={`max-w-full w-[900px] mx-auto space-y-2 flex flex-col text-xl font-semibold py-4 px-4`}>{(block.options as BlockFormOptions).title}</div> 
                            : 
                            <></>
                          }
                        </>
                        :
                        <></>
                      }
                      <div className=" flex flex-col h-full w-full max-w-[900px] mx-auto">
                        <BlockRecordsListActions initiator={{type: "block", id: block.id!}} showCreate={showAddForm}/>
                        <div className="flex-1 w-full h-full relative">
                          <List block={block} onCardChange={onCardChange}/>
                        </div>
                      </div>
                    </div>
                  </div>
                :
                  <div className="flex flex-col w-full mx-auto h-full overflow-hidden">
                    <div className="absolute left-0 z-10">
                      <button type="button" className="mt-2 ml-4"><TbSquareRoundedArrowLeft size={26} onClick={() => backToIndex()}/></button>
                    </div>
                    {createForm && addCard && <FormBlock block={createForm} />}
                    {updateForm && !addCard && <FormBlock block={updateForm} />}
                  </div>
                }
              </BoardProvider>
            </BlockSubscriptionProvider>
          </MattrDataProvider>
          {openSettings && 
            <MatrMediumDialog open={openSettings} onClose={closeSettings}>
              <BlockEdit block={block} onClose={closeSettings}/>
            </MatrMediumDialog>
          }
        </>
      :
        <SmallSpinner />}
    </>
  )
}
