import { BlockTypeEnum } from "../../../../../core/graphql/types";
import { usePanel } from "../../../../../core/providers/panel";
import { GridBlock } from "../../blocks/BlockGrid";

export default function LayoutGrid() {
  const { page } = usePanel()

  return (
    <div className={`flex flex-col overflow-hidden w-full h-full`}>
      <div className="flex flex-col overflow-hidden w-full h-full pb-4 relative">
        <GridBlock block={page.blocks?.find(b => b?.isPage === true && b.type === BlockTypeEnum.BlockGrid)!}/>
      </div>
    </div>
  );
}