import { ChangeEvent } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { HiOutlineTrash } from 'react-icons/hi';
import { TbExchange, TbSquare, TbSquareCheck } from 'react-icons/tb';
import { isVariable, isVariableExist } from '../../../../../../core/Controllers/Service';
import { useFetcherContext } from '../../../../../../core/providers/fetcher';

export default function BaseParametersPanel():JSX.Element {
  const {fetcher} = useFetcherContext()
  const methods = useFormContext();
  const {watch, control, setValue, getValues, formState: {errors}} = methods;
  const { fields, append, remove, move } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "parameters", // unique name for your Field Array
  });

  function onChangeParameterKey(e: ChangeEvent<HTMLInputElement>, index: number, onChange: (...event: any[]) => void) {

    let url = (new URL(getValues('url')))
    let urlParams = new URLSearchParams();
    getValues('parameters').map((p: { key: string; value: string; }, i: number) => {
      if(i === index) {
        urlParams.set(e.target.value, p.value)
      } else {
        urlParams.set(p.key, p.value)
      }
    })
    
    setValue('url', `${url.origin}${url.pathname}${urlParams.toString() ? '?' : ''}${urlParams.toString()}`)
    onChange(e.target.value)
  }

  function onChangeParameterValue(e: ChangeEvent<HTMLInputElement>, index: number, onChange: (...event: any[]) => void) {

    let url = (new URL(getValues('url')))
    let urlParams = new URLSearchParams();
    getValues('parameters').map((p: { key: string; value: string; }, i: number) => {
      if(i === index) {
        urlParams.set(p.key, e.target.value)
      } else {
        urlParams.set(p.key, p.value)
      }
    })
    
    setValue('url', `${url.origin}${url.pathname}${urlParams.toString() ? '?' : ''}${urlParams.toString()}`)

    onChange(e.target.value)
  }

  function handleChangeCheckbox(value: any, onChange: { (...event: any[]): void; (arg0: any): void; }) {
    onChange(!value)
  }

  function handleRemove(index: number) {
    let url = (new URL(getValues('url')))
    let urlParams = new URLSearchParams();
    getValues('parameters').filter((p: any, i: number) => i !== index).map((p: { key: string; value: string; }, i: number) => {
      urlParams.set(p.key, p.value)
    })
    
    setValue('url', `${url.origin}${url.pathname}${urlParams.toString() ? '?' : ''}${urlParams.toString()}`)

    remove(index)
  }
  
  return (
    <div className='flex flex-col w-full mt-2 border-b'>
      <div className='flex items-center justify-between pb-4'>
        <label className='text-sm text-slate-600 font-medium'>Параметры</label>
        <div
          className='flex py-1 px-2 text-sm bg-slate-200 text-slate-600 hover:bg-slate-300 cursor-pointer font-medium rounded-sm'
          onClick={() =>
            append({
              name: "",
              description: "",
              key: "",
              value: "",
              type: "STRING",
              disabled: false,
              dynamic: false
            })
          }
        >
          + Добавить
        </div>
      </div>
      {fields.map((field, index) => {
        return (
          <div key={field.id} className="flex items-center w-full mb-4">
            
            <section className={"flex gap-2 w-full"}>
              <Controller
                name={`parameters.${index}.disabled`}
                control={control}
                rules={{required: true}}
                render={({ field: { onChange, value, ref }}) => (
                  <div 
                    className={`flex-none w-7 justify-center items-center py-1.5 px-1.5 text-sm ${value ? "bg-slate-200 text-slate-600" : "bg-green-300 text-green-800" } rounded font-normal justify-between w-full cursor-default`} 
                    onClick={() => handleChangeCheckbox(value, onChange)}
                  >
                    {value ? <TbSquare className='h-4 w-4 text-slate-400'/> : <TbSquareCheck className='h-4 w-4 text-green-800'/>}
                  </div>
                )}
              />
              <Controller
                name={`parameters.${index}.key`}
                control={control}
                rules={{required: true, minLength: 1}}
                render={({ field: { onChange, value, ref }}) => (
                  <input 
                    value={value}
                    onChange={(e) => onChangeParameterKey(e, index, onChange)}
                    placeholder="Ключ"
                    className={`matr-form-narrow-input`}
                  />
                )} 
              />
              <Controller
                name={`parameters.${index}.value`}
                control={control}
                rules={{required: true, minLength: 1}}
                render={({ field: { onChange, value, ref }}) => (
                  <input 
                    value={value}
                    onChange={(e) => onChangeParameterValue(e, index, onChange)}
                    placeholder="Тестовое значение"
                    className={`matr-form-narrow-input ${isVariable(value) ? isVariableExist(value, fetcher?.variables!) ? "!text-blue-700" : "!text-red-400" : ""}`}
                  />
                )} 
              />
              <Controller
                name={`parameters.${index}.dynamic`}
                control={control}
                rules={{required: true}}
                render={({ field: { onChange, value, ref }}) => (
                  <div 
                    className={`flex-none w-7 items-center justify-center py-1.5 px-1.5 text-sm ${value ? "bg-green-300 text-green-800" : "bg-slate-200 text-slate-600"} rounded font-normal justify-between w-full cursor-default`} 
                    onClick={() => handleChangeCheckbox(value, onChange)}
                  >
                    {value ? <TbExchange className='h-4 w-4 text-green-800'/> : <TbExchange className='h-4 w-4 text-slate-400'/>}
                  </div>
                )}
              />
              <Controller
                name={`parameters.${index}.name`}
                control={control}
                rules={{required: true, minLength: 1}}
                render={({ field: { onChange, value, ref }}) => (
                  <input 
                    disabled={!watch(`parameters.${index}.dynamic`)}
                    value={value}
                    onChange={onChange}
                    placeholder="Название"
                    className={`matr-form-narrow-input`}
                  />
                )} 
              />
              <Controller
                name={`parameters.${index}.description`}
                control={control}
                rules={{minLength: 1}}
                render={({ field: { onChange, value, ref }}) => (
                  <input 
                    value={value}
                    disabled={!watch(`parameters.${index}.dynamic`)}
                    onChange={onChange}
                    placeholder="Описание"
                    className={`matr-form-narrow-input`}
                  />
                )} 
              />



            </section>
            <HiOutlineTrash size={16} className="ml-2 text-slate-600" onClick={() => handleRemove(index)}/>
          </div>
        );
      })}

      <div className='text-xs text-slate-600 py-2'>
        <p>Параметры, это значения, которые вставлются после знака ?. Ткак вы можете указать любое количество значений. Например, чтобы указать ?color=red, нужно создать параметер color, со значением red</p>
      </div>
    </div>
  )
}