  // @ts-ignore-next-line
const tg = window?.Telegram?.WebApp!;

export function useTelegram() {

  const onClose = () => {
    tg.close()
  }


  function onToggleButton() {
    if(tg.MainButton.isVisiible) {
      tg.MainButton.hide()
    } else {
      tg.MainButton.show()
    }
  }

  return {
    tg,
    user: tg.initDataUnsafe?.user,
    onClose,
    onToggleButton
  }
}