import { Listbox } from "@headlessui/react";
import { max, min, sum, uniq } from "lodash";
import { DateTime } from "luxon";
import { Fragment, useEffect, useState } from "react";
import { CalculatedColumn, RenderSummaryCellProps } from "react-data-grid";
import { getSummaryOptionsByType, summaryTypes } from "../../../../core/enums";
import { BoardView, Collaborator, Field, FieldSummaryType, Formula, Updated_At, ViewAppearanceParamEnum, ViewAppearanceTypeEnum, useBlockChangeFieldSummaryMutation, useChangeFieldSummaryMutation, useFieldSummaryMutation } from "../../../../core/graphql/types";
import { getKeyForType } from "../../../../utils";
import { useBoard } from "../../../../core/providers/board";
import useDeepCompareEffect from "use-deep-compare-effect";
import { useSpace } from "../../../../core/providers/space";
import { getSummaryValue } from "../../../../core/Controllers/Summary";

interface SummaryCellProps<R, SR> extends RenderSummaryCellProps<R, SR> {
  field: Field;
}

export default function SummaryRows<R, SR>({field, column, row}: SummaryCellProps<R, SR>) {
    let values = (row as any)[column.key]
    return <SummaryCell field={field} column={column} values={values} />
  }

export function SummaryCell<R, SR>({field, column, values}:{field: Field, column: CalculatedColumn<R, SR>, values: any}) {
  const {inBlock, block, currentView, userCurrentView} = useBoard()
  const {actionUserViewsSet, actionUserBlocksSet} = useSpace()

  const [summaryType, setSummaryType] = useState<FieldSummaryType>()
  const [, changeFieldSummaryType] = useChangeFieldSummaryMutation()
  const [, blockChangeFieldSummaryType] = useBlockChangeFieldSummaryMutation()


  useDeepCompareEffect(() => {
    let v = userCurrentView.appearance?.find(a => a?.type == ViewAppearanceTypeEnum.Field && a.param == ViewAppearanceParamEnum.Summary && a.id == field.id)
    v ? setSummaryType(v.value as FieldSummaryType) : setSummaryType(field.summaryType as FieldSummaryType)
  }, [userCurrentView.appearance])

  function additionalData(field: Field) {
    switch (field.type) {
      case 'FORMULA': return {formulaValueType: (field.attributes as Formula).expressionType}
      case 'SELECT': 
      case 'CREATED_BY': 
      case 'UPDATED_BY': 
        return {key: 'name', multi: false, relation: false}
      case 'MULTISELECT': 
        return {key: 'name', multi: true, relation: false}
      case 'COLLABORATOR': 
        return {key: 'name', multi: (field.attributes as Collaborator).multiple, relation: false}
      case 'RELATION':
      case 'LOOKUP':
        return {key: 'foreginName', multi: true, relation: true}
      case 'DATETIME':
      case 'INSERTED_AT':
      case 'UPDATED_AT':
        return {includeTime: (field.attributes as Updated_At).includeTime}

      default: return {}
    }
  }

  function getSummaryValueByType(summaryType: string, fieldType: string, additionalData?: any) {
    let jv = []

    if(additionalData.hasOwnProperty('key')) {
      if(additionalData.relation) {
        jv = values?.map((v: any) => v && v[getKeyForType(fieldType)] ? v[getKeyForType(fieldType)].join([additionalData.key], ", ") : "")
      } else {
        if(additionalData.multi) {
          jv = values?.map((v: any) => v && v[getKeyForType(fieldType)] ? v[getKeyForType(fieldType)].join([additionalData.key], ", ") : "")
        } else {
          jv = values?.map((v: any) => v && v[getKeyForType(fieldType)] ? v[getKeyForType(fieldType)][additionalData.key] : "")
        }
      }
    } else {
      if(additionalData.hasOwnProperty('formulaValueType')) {
        if(additionalData.formulaValueType === 'DECIMAL') { 
          jv = values?.map((v: any) => v && parseFloat(v[getKeyForType(fieldType)!]))
        } else if(additionalData.formulaValueType === 'DATETIME') {
          jv = values?.map((v: any) => v && v[getKeyForType(fieldType)])
        } else {
          jv = values?.map((v: any) => v && v[getKeyForType(fieldType)]) 
        }
      } else if(additionalData.hasOwnProperty('includeTime')) {
        if(additionalData.includeTime) {
          jv = values?.map((v: any) => v && v[getKeyForType(fieldType)])
        } else {
          jv = values?.map((v: any) => v && DateTime.fromISO(v[getKeyForType(fieldType)]).toISODate())
        }
      } else {
        jv = values?.map((v: any) => {
          return v && v[getKeyForType(fieldType)]
        })
      }
    }

    let total = jv?.length
    let unique = uniq(jv)?.length
    let empty = jv?.filter((i: any) => !i).length
    let filled = jv?.filter((i: any) => !!i).length

    switch (summaryType) {
      case 'TOTAL':
        return <div className="text-sm text-slate-700">{total}</div>
      case 'SUM':
        return <div className="text-sm text-slate-700">{sum(jv).toFixed(2)}</div>
      case 'MAX':
        return <div className="text-sm text-slate-700">{max(jv)}</div>
      case 'MIN':
        return <div className="text-sm text-slate-700">{min(jv)}</div>
      case 'AVG':
        return <div className="text-sm text-slate-700">{(sum(jv) / total).toFixed(2)}</div>
      case 'MEDIAN':
        return <div className="text-sm text-slate-700">{median(jv).toFixed(2)}</div>
      case 'DEVIATION':
        return <div className="text-sm text-slate-700">{deviation(jv).toFixed(2)}</div>
      case 'NONE':
        return <div className="text-sm text-slate-700">-</div>
      case 'UNIQUE':
        return <div className="text-sm text-slate-700">{unique}</div>
      case 'EMPTY':
        return <div className="text-sm text-slate-700">{empty}</div>
      case 'FILLED':
        return <div className="text-sm text-slate-700">{filled}</div>
      case 'UNIQUE_PERCENT':
        return <div className="text-sm text-slate-700">{((unique / total) * 100).toFixed(1)} %</div>
      case 'EMPTY_PERCENT':       
        return <div className="text-sm text-slate-700">{((empty / total) * 100).toFixed(1)} %</div>
      case 'FILLED_PERCENT':
        return <div className="text-sm text-slate-700">{((filled / total) * 100).toFixed(1)} %</div>
      default: return <div className="text-sm text-slate-700">-</div>
    }
  }

  function median(values: any[]){
    if(values.length ===0) throw new Error("No inputs");
  
    values.sort(function(a,b){
      return a-b;
    });
  
    var half = Math.floor(values.length / 2);
    
    if (values.length % 2)
      return values[half];
    
    return (values[half - 1] + values[half]) / 2.0;
  }

  function deviation (array: any[]) {
    const n = array.length
    const mean = array.reduce((a, b) => a + b) / n
    return Math.sqrt(array.map(x => Math.pow(x - mean, 2)).reduce((a, b) => a + b) / n)
  }

  function handleCahnge(e: any) {
    if(inBlock) {
      blockChangeFieldSummaryType({blockId: block?.id!, id: field.id, value: e.value}).then(r => {
        if(r.error) return {}
        setSummaryType(r.data?.blockChangeFieldSummary?.appearance?.find(a => a?.type == ViewAppearanceTypeEnum.Field && a.param == ViewAppearanceParamEnum.Summary && a.id == field.id)?.value as FieldSummaryType)
        actionUserBlocksSet(r.data?.blockChangeFieldSummary!)
      })
    } else {
      changeFieldSummaryType({viewId: (currentView as BoardView)!.id, id: field.id, value: e.value}).then(r => {
        if(r.error) return {}
        setSummaryType(r.data?.changeFieldSummary?.appearance?.find(a => a?.type == ViewAppearanceTypeEnum.Field && a.param == ViewAppearanceParamEnum.Summary && a.id == field.id)?.value as FieldSummaryType)
        actionUserViewsSet(r.data?.changeFieldSummary!)
      })
    }
  }

  return (
    <div className="flex justify-between px-2 items-center h-full w-full bg-slate-100">
      <div className="relative">
        <Listbox value={summaryType} onChange={handleCahnge}>
          <Listbox.Button className="text-xs text-slate-400">{summaryType ? summaryTypes.find(t => t.value === summaryType)?.label : summaryTypes.find(t => t.value === 'NONE')?.label}</Listbox.Button>
          <Listbox.Options className="absolute -left-2 bottom-10 z-10 bg-white rounded shadow flex flex-col p-1 max-w-max" style={{minWidth: column.width}}>
            {getSummaryOptionsByType(field).map((type) => (
              <Listbox.Option key={type.value} value={type} as={Fragment}>
                {({ active, selected }) => (
                  <li
                    className={`${
                      active ? 'text-slate-700 bg-slate-100 rounded' : 'text-slate-500'
                    } text-sm px-3 py-1.5`}
                  >
                    {type.label}
                  </li>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Listbox>
      </div>
      {getSummaryValue(values, summaryType!, field)}
      {/* {getSummaryValueByType(summaryType!, field.type, additionalData(field))}   */}
    </div>    
  )
}