import { useEffect, useState } from "react";
import { Column } from "react-data-grid";
import { Row } from "../types";
import type { RenderCellProps, RenderEditCellProps } from 'react-data-grid';
import { Field,  Number, NumberValue, SpaceUser, Map, JsonArrayValue, Maybe, CollaboratorValue, Collaborator } from '../../../../core/graphql/types';
import SummaryRows from "../rows/summary";
import { GroupCell } from "../rows/group";
import { HeaderRenderer } from "../rows/header";
import NumberFormat, { NumberFormatValues } from "react-number-format";
import { useBoard } from "../../../../core/providers/board";
import { HiCheck, HiChevronDown, HiChevronUp } from "react-icons/hi";
import { useSpaceStore } from "../../../../core/store/store";

interface BaseRenderCellProps<TRow, TSummaryRow> extends RenderCellProps<TRow, TSummaryRow> {
  field: Field;
}

interface BaseRenderEditCellProps<TRow, TSummaryRow> extends RenderEditCellProps<TRow, TSummaryRow> {
  field: Field;
}

// Column

export function createdByColumn({ field, canEditInline }: { field: Field; canEditInline: boolean }): Column<Row, any> {
  return {
    key: field.id,
    name: field.title,
    width: field.width,
    resizable: true,
    frozen: field.primary ? true : false,
    renderHeaderCell: (p) => { return <HeaderRenderer field={field} {...p}/> },
    renderCell: (p) => { return <CreatedByCell {...p} field={field}/> },
    renderGroupCell: (p) => { return <GroupCell {...p} field={field}/> },
    renderSummaryCell: (p) => { return <SummaryRows {...p} field={field}/> },
    editorOptions: {
      displayCellContent: !canEditInline
    }
  }
};

// Cell

export function CreatedByCell<Row, SummaryRow>({field, row, column}: BaseRenderCellProps<Row, SummaryRow>) {
    const value = (row[column.key as keyof Row] as unknown as JsonArrayValue)?.valueAsJsonArray! || []
    
    const {initId, initType} = useBoard()
    const init: string = `${initType}:${initId}`
    const store = useSpaceStore()
    const {users} = store.data[init];

    const searchInOptions = (value: any) => {
      if(!value || value == null) {
        return 
      }
      let result = users.find((obj: SpaceUser) => obj?.id === value.id);
      if(result) {
        return `${result?.firstName!} ${result?.lastName!}`;
      } else {
        return "Пользователя не существует"
      }
    }
    return (
      <div className="truncate text-sm flex my-1.5 px-2">
        <div className="flex text-sm bg-blue-200 h-5 py-1 rounded-sm px-2 items-center">{searchInOptions(value)}</div>
      </div>
    );
}