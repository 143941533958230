import React, {useEffect, useState} from "react";
import { Board, Group } from '../../core/graphql/types';
import { BoardPreviewCard } from "../../components/board/boardPreview";
import { SmallSpinner } from '../../components/loaders';

import _ from "lodash";
import BoardsActions from "./actions";
import { useSpace } from "../../core/providers/space";


export default function BoardsManager() {
  const {boards, groups} = useSpace()

  return (
  <div className="w-full h-full flex overflow-hidden">
    <div className="flex flex-col w-full h-full flex-1 overflow-scroll pb-16">
      <div className="my-6 px-6 xl:px-6">
        <BoardsActions />
      </div>
      <div className="">
        {groups.map(group => (
          <div key={group.id}>
            <div className="px-6 text-lg font-semibold text-slate-500 py-4">{group.name}</div>
            <div className="grid xl:grid-cols-5 lg:grid-cols-4 md:grid-cols-3 grid-cols-2 gap-4 w-full px-6 xl:px-6">
              {_.orderBy(boards.filter(b => b.group?.id === group.id), ['updatedAt'], 'desc').map((board: Board, idx: number) => (
                <BoardPreviewCard board ={board!} key={idx}/>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  </div>
  );
}