import { useEffect, useRef, useState } from "react";
import { Column } from "react-data-grid";
import { Row } from "../types";
import type { RenderCellProps, RenderEditCellProps } from 'react-data-grid';
import { Field, StringValue, Textarea } from '../../../../core/graphql/types';
import SummaryRows from "../rows/summary";
import { GroupCell } from "../rows/group";
import { HeaderRenderer } from "../rows/header";
import { useBoard } from "../../../../core/providers/board";
import { transformValueToString } from '../../../../core/Controllers/Base';
import { MatrDocumentDialog, MatrTextPopover } from "../../../ui/portal";
import RichTextCellEditor from '../../../ui/TextEditors/RichTextCellEditor';
import PlainTextCellEditor, {PlainTextEditorShow} from '../../../ui/TextEditors/PlainTextCellEditor';
import { EditorContent, useEditor } from "@tiptap/react";
import Color from "@tiptap/extension-color";
import StarterKit from "@tiptap/starter-kit";
import Mention from "../../../ui/TextEditors/components/Mention";
import TextStyle from "@tiptap/extension-text-style";
import ListItem from "@tiptap/extension-list-item";
import { useSpaceStore } from "../../../../core/store/store";
import { TbArrowsMaximize, TbSquaresFilled, TbX } from "react-icons/tb";
import BlockEditor from "../../../ui/TextEditors/BlockEditor";



interface RenderStringCellProps<TRow, TSummaryRow> extends RenderCellProps<TRow, TSummaryRow> {
  field: Field;
  canEdit: boolean;
}

interface RenderEditStringCellProps<TRow, TSummaryRow> extends RenderEditCellProps<TRow, TSummaryRow> {
  field: Field;
}

function autoFocusAndSelect(input: HTMLInputElement | null) {
  input?.focus();
  input?.select();
}

// Column

export function textColumn({ field, canEditInline }: { field: Field; canEditInline: boolean }): Column<Row, any> {
  return {
    key: field.id,
    name: field.title,
    width: field.width,
    resizable: true,
    frozen: field.primary ? true : false,
    renderHeaderCell: (p) => { return <HeaderRenderer field={field} {...p}/> },
    renderCell: (p) => { return <TextCell {...p} field={field} canEdit={canEditInline}/> },
    // renderEditCell: (p) => { return canEditInline ? <TextCellEditor {...p} field={field}/>  : <></> },
    renderGroupCell: (p) => { return <GroupCell {...p} field={field}/> },
    renderSummaryCell: (p) => { return <SummaryRows {...p} field={field}/> },
    // editorOptions: {
    //   displayCellContent: !canEditInline
    // }
  }
};

// Cell

const initialValue = {type: "doc", content: []}

export function TextCell<Row, SummaryRow>(props: RenderStringCellProps<Row, SummaryRow>) {
  const {initId, initType} = useBoard();
  const init: string = `${initType}:${initId}`;
  const store = useSpaceStore()
  const {rowHeight} = store.data[init];
  const {setEditMode} = store.boardActions
  const {row, column, tabIndex, field, canEdit, onRowChange} = props
  const [value, setValue] = useState((row[column.key as keyof Row] as unknown as StringValue)?.valueAsString!)
  const cellRef = useRef<any>(null);
  const [open, setOpen] = useState(false);
  const [openEditor, setOpenEditor] = useState(false);
  const editor = (field.attributes as Textarea).editor;
  const [tokens, setTokens] = useState<any[]>([])

  function handleClose() {
    setOpen(false)
    setEditMode(false, init)
  }

  function handleCloseEditor() {
    setOpenEditor(false)
    setEditMode(false, init)
  }

  function handleOpen() {
    if(!open && tabIndex === 0 && canEdit) {
      setOpen(true)
      setEditMode(true, init)
    }
  }

  function handleOpenEditor() {
    if(!openEditor && tabIndex === 0 && canEdit) {
      setOpenEditor(true)
      setOpen(false)
      setEditMode(true, init)
    }
  }

  useEffect(() => {
    const tok = Object.values(Object.fromEntries(Object.entries(row as {}).filter(([key]) => !["id", "boardId", "position"].includes(key)))).filter((i: any) => !["TEXTAREA"].includes(i.type)).map((i:any) => ({
      id: i.id,
      value: transformValueToString(i),
      data: {
        type: "editor",
        source: "internal",
        sourceId: i.boardId, 
        valueType: i.type,
        boardId: i.boardId,
        fieldId: i.fieldId,
        cardId: i.cardId,
        function: null,
      }
    }));
    setTokens(tok)
  },[row])

  useEffect(() =>{
    if(tabIndex === 0 && canEdit) {
      setOpen(true)
      setEditMode(true, init)
    } else {
      setOpen(false)
      setEditMode(false, init)
    }
    
  },[tabIndex])

  function handleRichTextChange(e: any) {
    setValue(JSON.stringify(e))
    onRowChange({ ...row, [column.key]: {...row[column.key as keyof Row], valueAsString: JSON.stringify(e)}})
  }

  return (
    <>
      <div ref={cellRef} className="relative">
        {
          tabIndex == 0 && canEdit && open ?
            editor ?
            <div className={`px-2 py-2 w-full flex ${(rowHeight / 36) > 2 ? "whitespace-nowrap truncate" : "whitespace-wrap"}  text-sm text-slate-500 cursor-pointer`} style={{height: rowHeight}} onClick={() => handleOpenEditor()}>
              Редактировать...
            </div>
            :
            <MatrTextPopover onClose={handleClose} reference={cellRef.current} placement="bottom-start">
              <div className={`h-full flex text-sm min-w-[200px] bg-white p-1.5 border-blue-600 shadow border-2 min-h-[90px]`} style={{width: column.width, minHeight: rowHeight}}>
                <PlainTextCellEditor value={value} onChange={handleRichTextChange} />
              </div>
              {editor && <div className="absolute bottom-1 right-1 p-1 hover:bg-slate-200 text-slate-500" onClick={handleOpenEditor}>
                <TbArrowsMaximize size={12}/>
              </div>}
            </MatrTextPopover>
          :
            <div className={`px-2 py-2 w-full flex ${(rowHeight / 36) > 2 ? "whitespace-nowrap truncate" : "whitespace-wrap"}  text-sm`} style={{height: rowHeight}} onClick={() => handleOpen()}>
              {editor ? value ? "Редактировать..." : null : <PlainTextEditorShow value={value} />}
            </div>
        }
      </div>
      {editor && openEditor && <MatrDocumentDialog open={openEditor} onClose={handleCloseEditor}>
        <div className="p-6 w-full h-full">
          <div className="flex items-center justify-between mb-6">
            <div className="flex items-center space-x-2">
              <div className="py-1 px-2 bg-slate-100 flex space-x-2 rounded">
                <TbSquaresFilled size={20} className="text-indigo-600"/>
                <div className="flex font-medium text-sm">{field?.title}</div>
              </div>
              <div className="flex font-normal text-sm">Редактирование записи</div>
            </div>
            <button onClick={() => handleCloseEditor()}><TbX size={18}/></button>
          </div>
          <BlockEditor value={value} onChange={handleRichTextChange} tokens={tokens} editable={true}/>
        </div>
      </MatrDocumentDialog>}
    </>
  )
}

// CellEditor

// export function TextCellEditor<Row, SummaryRow>({field, row, column, onRowChange, onClose}: RenderEditStringCellProps<Row, SummaryRow>) {
//   const [value, setValue] = useState((row[column.key as keyof Row] as unknown as StringValue)?.valueAsString!)
//   const editor = (field.attributes as Textarea).editor

//   const {initId, initType} = useBoard();
//   const init: string = `${initType}:${initId}`;
//   const store = useSpaceStore()
//   const {setEditMode, setModal} = store.boardActions

//   const [open, setOpen] = useState(false);
//   const [tokens, setTokens] = useState<any[] | null>(null)


//   useEffect(() => {
//     setEditMode(true, init)
//     if(editor) {
//       setOpen(true)
//     }
//   },[])


//   function handleRichTextChange(e: any) {
//     setValue(JSON.stringify(e))
//     onRowChange({ ...row, [column.key]: {...row[column.key as keyof Row], valueAsString: JSON.stringify(e)}})
//   }

//   function handleClose() {
//     setOpen(false)
//     onClose(true)
//   }

//   function handleKeyDown(e: React.KeyboardEvent<HTMLSpanElement>) {
//     if (e.key === 'Enter') {
//       e.stopPropagation();
//     }
//   }


//   useEffect(() => {
//     const tok = Object.values(Object.fromEntries(Object.entries(row as {}).filter(([key]) => !["id", "boardId", "position"].includes(key)))).filter((i: any) => !["TEXTAREA"].includes(i.type)).map((i:any) => ({
//       id: i.id,
//       value: transformValueToString(i),
//       data: {
//         type: "editor",
//         source: "internal",
//         sourceId: i.boardId, 
//         valueType: i.type,
//         boardId: i.boardId,
//         fieldId: i.fieldId,
//         cardId: i.cardId,
//         function: null,
//       }
//     }));
//     setTokens(tok)
//   },[row])


//   return (
//     editor ? 
//       <MatrDocumentDialog open={open} onClose={handleClose}>
//         <RichTextCellEditor value={value} onChange={handleRichTextChange} tokens={tokens}/>
//       </MatrDocumentDialog>
//       : 
//       <div className="relative flex w-full bg-white items-center">
//         <div className="truncate flex flex-col px-2 py-2 items-left z-10 w-full h-48 max-h-48 outline-2 outline outline-blue-600 rounded-sm bg-white">
//           <div className="w-full h-full flex text-sm">
//             <PlainTextCellEditor value={value} onChange={handleRichTextChange} />
//           </div>
//         </div>
//       </div>
//   );
// }

