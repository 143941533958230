import { TbSquaresFilled, TbX } from "react-icons/tb";
import FormBlock from "../../../containers/Panel/page/blocks/BlockForm";
import { Block, BlockFormOptions, Card, CardWithValues } from "../../../core/graphql/types";
import { useBoard } from "../../../core/providers/board";
import { MatrCardDialog } from "../../ui/portal";

// Приходит запись, она может быть новой или старой, приходит функция для изменения списка связей и внешняя доска

interface CardWithBlockForm {
  open: boolean;
  close: () => void;
  block: Block;
  card?: Card;

}

const CardWithBlockForm = ({open, close, block, card}:CardWithBlockForm) => {
  const {currentCardId, cards, values} = useBoard()

  return (
    <MatrCardDialog open={open} onClose={close}>
      <div className="relative flex flex-col max-w-4xl w-full h-full text-left overflow-hidden transition-all transform bg-white shadow-lg rounded-md px-6 py-4">
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-2">
            <div className="py-1 px-2 bg-slate-100 flex space-x-2 rounded">
              <TbSquaresFilled size={20} className="text-indigo-600"/>
              <div className="flex font-medium text-sm">{(block?.options as BlockFormOptions).showTitle ? (block?.options as BlockFormOptions).title : "..."}</div>
            </div>
            <div className="flex font-normal text-sm">{currentCardId ?  "Редактирование записи" : "Добавление новой записи"}</div>
          </div>
          <button onClick={() => close()}><TbX size={18}/></button>
        </div>
        <div className="w-full">
          <FormBlock block={block} 
            // card={({...(cards.find(c => c.id === currentCardId) as CardWithValues), values: values.filter(v => v.cardId === currentCardId)} as CardWithValues)!}
          />
        </div>
      </div>
    </MatrCardDialog>
  )
};

export default CardWithBlockForm;