import { useState } from "react";
import { NavLink, useLocation, Outlet } from "react-router-dom";
import BaseMenu from "../../components/navigation/base";
import Header from "../../components/Headers/Header";

export function Account() {
  return (
    <>
      <Header location={"account"}/>
      <section className="w-full bg-white flex h-full">
        <div className="flex w-full max-w-[900px] mx-auto px-6 py-4">
          <Outlet />
        </div>
      </section>
    </>
  )
}