import { useState } from "react";
import { Controller, FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { BiToggleLeft, BiToggleRight } from "react-icons/bi";
import { TbSquaresFilled, TbX } from "react-icons/tb";
import { Block, Page } from "../../../core/graphql/types";
import { usePanel } from "../../../core/providers/panel";
import { useSpaceStore } from "../../../core/store/store";
import * as Yup from 'yup';


// Написать валидацию для каждого блока
const validationSchema = Yup.object().shape({

});

const PageFormEdit = ({onClose}:{onClose: () => void}) => {
  const spaceStore = useSpaceStore()
  const {page, updatePage} = usePanel()
  const methods = useForm({defaultValues: page})
  const { getValues, control, formState: { errors } } = methods;
  const [ error, setError ] = useState(null);


  const onSubmit = () => { 
    validationSchema.isValid(getValues()).then(res => {
      if(res) {
        let data = getValues()
        setError(null)
        updatePage(data as Page)
        onClose()
      } else {
        validationSchema.validate(getValues()).catch(res => setError(res.message))
      }
    })
  };

  function handleChangeCheckbox(value: any, onChange: { (...event: any[]): void; (arg0: any): void; }) {
    onChange(!value)
  }
 
  

  return (
    page && 
    <>
      <div className="flex items-center justify-between">
        <div className="flex items-center space-x-2">
          <div className="py-1 px-2 bg-slate-100 flex space-x-2 rounded">
            <TbSquaresFilled size={20} className="text-indigo-600"/>
            <div className="flex font-medium text-sm">{spaceStore.space?.name}</div>
          </div>
          <div className="flex font-normal text-sm">Редактирование страницы</div>
        </div>
        <button onClick={() => onClose()}><TbX size={18}/></button>
      </div>

          <div className="relative flex w-full flex-col space-y-1.5 pt-4">
            <Controller 
              name="name"
              control={control}
              rules={{required: true}}
              render={({ field: { onChange, value }}) => (
                <div className="mt-1.5">
                  <input
                    className="text-xl w-full font-medium text-slate-800 placeholder:text-slate-300 focus:outline-none mb-4" 
                    placeholder="Название страницы"
                    value={value!}
                    onChange={onChange}
                  />
                </div>
              )}
            />
            <div className="flex mt-2 w-full">
              <div className="flex justify-between items-center w-full">
                <label className="matr-label w-full">Черновик</label>
                <Controller
                  name="isDraft"
                  control={control}
                  rules={{required: true}}
                  render={({ field: { onChange, value, ref }}) => (
                    <div 
                      className={`flex items-center  rounded-md font-normal  cursor-default`} 
                      onClick={() => handleChangeCheckbox(value, onChange)}
                    >
                      {value === false ? <BiToggleLeft className='h-6 w-6 text-slate-400'/> : <BiToggleRight className='h-6 w-6 text-blue-600'/>}
                    </div>
                  )}
                />
              </div>
            </div>
            <div className="flex mt-2 w-full">
              <div className="flex justify-between items-center w-full">
                <label className="matr-label w-full">Показывать в меню</label>
                <Controller
                  name="published"
                  control={control}
                  rules={{required: true}}
                  render={({ field: { onChange, value, ref }}) => (
                    <div 
                      className={`flex items-center  rounded-md font-normal  cursor-default`} 
                      onClick={() => handleChangeCheckbox(value, onChange)}
                    >
                      {value === false ? <BiToggleLeft className='h-6 w-6 text-slate-400'/> : <BiToggleRight className='h-6 w-6 text-blue-600'/>}
                    </div>
                  )}
                />
              </div>
            </div>
          </div>
          <div className="flex w-full h-full justify-end pt-4 space-x-4">
            <button
              type="button"
              className="rounded text-sm py-2 px-4 bg-slate-200 text-slate-400 hover:bg-slate-300 hover:text-slate-500"
              onClick={onClose}
            >Отменить</button>
            <button
              type="button"
              onClick={onSubmit}
              className="rounded text-sm py-2 px-4 bg-indigo-600 text-white hover:bg-indigo-700"
            >Обновить</button>
          </div>
    </>
  )
}

export default PageFormEdit;