import { useEffect, useState, forwardRef, ChangeEvent } from "react";
import NumberFormat from 'react-number-format';
import moment from "moment";
import Select, {ActionMeta, components} from "react-select";
import DatePicker from "react-datepicker";
import { HiChevronLeft, HiChevronRight} from 'react-icons/hi';
import { dateFormats, timeFormats } from "../../../../../core/enums";
import RelationInput from "../../../../ui/forms/relation";
import LookupInput from "../../../../ui/forms/lookup";
import MatrNarrowSelect from "../../../../ui/select/narrow";
import MatrNarrowDatePicker from "../../../../ui/datepicker/narrow";

interface DateTimeValueProps {
  filter: any;
  onChange: any;
  operator: any;
  attr: any;
}

interface DateTimeValueItemProps {
  [key: string]: any;
}

const DateTimeValue = ({filter, onChange, operator, attr}: DateTimeValueProps) => {
  const dateFormat = dateFormats.find((it: any) => attr.dateFormat)?.spec_format
  const timeFormat = timeFormats.find((it: any) => attr.timeFormat)?.spec_format
  const options = operator.values 
  const [value, setValue] = useState<any>({})
  const [mode, setMode] = useState()

  useEffect(() => {
    let item:DateTimeValueItemProps = {}

    if(operator.value === "EMPTY" || operator.value === "NOT_EMPTY") {
      item["mode"] = null
      item["date"] = null
      item["number_of_days"] = null

      return
    }

    if(filter.mode) {
      if(filter.mode === "CUSTOM") {
        item["mode"] = filter.mode
        item["date"] = filter.valueAsDate ? filter.valueAsDate : new Date()
      } else if(["NUMBER_OF_DAYS_AGO", "NUMBER_OF_DAYS_FUTURE", "PAST_DAYS", "NEXT_DAYS"].includes(filter.mode)) {
        item["mode"] = filter.mode
        item["number_of_days"] = filter.valueAsNumber ? filter.valueAsNumber : ""
      } else {
        item["mode"] = filter.mode
      }
    } else {
      if(options[0].value === "CUSTOM") {
        item["mode"] = options[0].value
        item['date'] = new Date()
        onChange({mode: options[0].value, date: new Date()})
      } else if(["NUMBER_OF_DAYS_AGO", "NUMBER_OF_DAYS_FUTURE", "PAST_DAYS", "NEXT_DAYS"].includes(options[0].value)) {
        item["mode"] = options[0].value
        item["number_of_days"] = ""
        onChange({mode: options[0].value, number_of_days: ""})
      } else {
        item["mode"] = options[0].value
        onChange({mode: options[0].value})
      }
    }

    setValue(item)
  },[filter.operator])

  const handleChangeDateTime = (e: any) => {
    if(e.value === "CUSTOM") {
      setValue({mode: e.value, date: new Date(), number_of_days: null,})
      onChange({mode: e.value, date: new Date(), number_of_days: null,})
    } else if(["NUMBER_OF_DAYS_AGO", "NUMBER_OF_DAYS_FUTURE", "PAST_DAYS", "NEXT_DAYS"].includes(e.value)) {
      setValue({mode: e.value, number_of_days: "", date: null})
      onChange({mode: e.value, number_of_days: "", date: null})
    } else {
      setValue({mode: e.value, number_of_days: null, date: null})
      onChange({mode: e.value, number_of_days: null, date: null})
    }
  }

  const handleDateValue = (e: any) => {
    let item = {...value, date: new Date(e)}
    setValue(item)
    onChange(item)
  }

  const handleNumberValue = (e: any) => {
    let item = {...value, number_of_days: e}
    setValue(item)
    onChange(item)
  }


  return (
    operator.editor === "NO" ? <></> :
    <div className="flex flex-row w-full">
      <div style={{minWidth: 120}} className={`${value.mode ? "col-span-2" : "col-span-4"} w-full relative pr-2`}>
        <MatrNarrowSelect
          isSearchable={false}
          value={options && value.mode ? options.filter((o: any) => o.value === value.mode) : ""}
          options={options}
          onChange={(e) => handleChangeDateTime(e)}
        />
      </div>
      {value.mode === "CUSTOM" ? <DateTimePickerForValue value={value.date} handleValue={handleDateValue} dateFormat={dateFormat} timeFormat={timeFormat}/> : ""}
      {value.mode === "NUMBER_OF_DAYS_AGO" ? <DateTimeNumberForValue value={value.number_of_days} handleValue={handleNumberValue}/> : ""}
      {value.mode === "NUMBER_OF_DAYS_FUTURE" ? <DateTimeNumberForValue value={value.number_of_days} handleValue={handleNumberValue}/>: ""}
      {value.mode === "PAST_DAYS" ? <DateTimeNumberForValue value={value.number_of_days} handleValue={handleNumberValue}/> : ""}
      {value.mode === "NEXT_DAYS" ? <DateTimeNumberForValue value={value.number_of_days} handleValue={handleNumberValue}/> : ""}
      {value.mode === null ? "" : "" }
    </div>
  )
}

interface DateTimeNumberForValueProps {
  value: any;
  handleValue: any;
}

const DateTimeNumberForValue = ({value, handleValue}: DateTimeNumberForValueProps) => {
  return (
    <input value={value ? value : ""} onChange={(e) => handleValue(parseInt(e.target.value))} style={{minWidth: 60}} className="col-span-2 matr-form-narrow-input"/> 
  )
}

interface DateTimePickerForValueProps {
  value: any;
  handleValue: any;
  dateFormat: any;
  timeFormat: any;
}

const DateTimePickerForValue = ({value, handleValue, dateFormat, timeFormat}: DateTimePickerForValueProps) => {

  return (
    <MatrNarrowDatePicker 
      selected = {value ? new Date(value) : new Date()}
      dateFormat={dateFormat}
      onChange = {(e) => handleValue(e)}
    />
  )
}

interface SelectValueProps {
  options: any;
  item: any;
  onChange: any;
  operator: any;
}

const SelectValue = ({options, item, onChange, operator}:SelectValueProps) => {
  const handleChange = (e: any) => {
    if(operator.multi) {
      if(item.fieldType === "LOOKUP") {
        let names: any = []
        e.map((v: any) => {
          names.push(v.name)
        })
        onChange(names)
      } else {
        let values: any = []
        e.map((v: any) => {
          values.push(v)
        })
        onChange(values)
      }
    } else {
      if(item.fieldType === "LOOKUP") {
        onChange([e.name])
      } else {
        onChange([e])
      }
    }
  }

  console.log(item)

  return (
    operator.editor === "NO" ? <></> :
    <MatrNarrowSelect
      isMulti={operator.multi}
      isSearchable={false}
      placeholder="Выберите..."
      getOptionLabel ={(option)=>option.name}
      getOptionValue ={(option)=>option.id}
      value={options && item.valueAsArray ? item.fieldType === "LOOKUP" ? options.filter((f: any) => item.valueAsArray.includes(f.name)) : options.filter((f: any) => item.valueAsArray.map((v: any) => v.id).includes(f.id)) : []}
      options={options}
      onChange={(e) => handleChange(e)}
    />
  )
}

interface UserValueProps {
  options: any;
  item: any;
  onChange: any;
  operator: any;
}

const UserValue = ({options, item, onChange, operator}:UserValueProps) => {
  const users: any = [{label: "Текущий пользователь", value: "current_user"}]

  options.map((it: any) => {
    users.push({value: it.id, label: `${it.firstName} ${it.lastName}`})
  })

  const handleChange = (e: any, meta: ActionMeta<any>) => {
    if(operator.multi) {
      onChange(e)
    } else {
      if(meta.action === 'clear') {
        onChange([])
      } else {
        onChange([e])
      }
    }
  }
  
  return (
    operator.editor === "NO" ? <></> :
    <MatrNarrowSelect
      isMulti={operator.multi}
      placeholder="Выберите..."
      isClearable={true}
      isSearchable={false}
      value={users && item.valueAsArray ? users.map((f: any) => {
        if(item.valueAsArray.includes(f.value)) {
          return f
        } else if(f.value === "current_user" && item.currentUser) {
          return f
        } else {
          return
        }
        })
        : []}
      options={users}
      onChange={(e, meta) => handleChange(e, meta)}
    />
  )
}

interface UpdatedByValueProps {
  options: any;
  item: any;
  onChange: any;
  operator: any;
}

const UpdatedByValue = ({options, item, onChange, operator}:UpdatedByValueProps) => {

  const users: any = [{value: "current_user", label: "Текущий пользователь"}]

  options.map((it: any) => {
    users.push({value: it.id, label: `${it.firstName} ${it.lastName}`})
  })

  const handleChange = (e: { map: (arg0: (v: any) => void) => void; value: any; }, meta: ActionMeta<any>) => {
    if(operator.multi) {
      let values: any = []
      e.map((v: any) => {
        values.push(v.value)
      })
      onChange(values)
    } else {
      if(meta.action === 'clear') {
        onChange([])
      } else {
        onChange([e.value])
      }
    }
  }
  
  return (
    operator.editor === "NO" ? <></> :
    <MatrNarrowSelect
      isMulti={operator.multi}
      isSearchable={false}
      isClearable={true}
      value={users && item.valueAsArray ? users.filter((f: any) => item.valueAsArray.includes(f.value)) : []}
      options={users}
      onChange={(e, meta) => handleChange(e, meta)}
    />
  )
}

interface CheckboxValueProps {
  item: any;
  onChange: any;
}

const CheckboxValue = ({item, onChange}: CheckboxValueProps) => {
  let [value, setValue] = useState(item.valueAsBoolean !== null ? item.valueAsBoolean : false)
  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    onChange(e.target.checked)
    setValue(e.target.checked)
  }
  return (
    <input type="checkbox" defaultChecked={value} onChange={(e) => handleChange(e)} className="py-1.5 px-2 flex w-full bg-gray-100 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 focus:outline-none"/>
  )
}

interface StringValueProps {
  item: any;
  onChange: any;
  operator: any;
}

const StringValue = ({item, onChange, operator}: StringValueProps) => {
  return (
    operator.editor === "NO" ? <></> :
    <input value={item.value ? item.value : ""} onChange={(e) => onChange(e.target.value)} style={{minWidth: 60}}  className="matr-form-narrow-input"/>
  )
}

interface NumberValueProps {
  item: any;
  onChange: any;
  operator: any;
  attr: any;
}

const NumberValue = ({item, onChange, operator, attr}: NumberValueProps) => {
  return (
    operator.editor === "NO" ? <></> :
    <NumberFormat
      autoFocus={false}
      allowLeadingZeros={false}
      allowNegative = {attr.negative}
      fixedDecimalScale = {true}
      value={item.valueAsNumber !== null || item.valueAsNumber !== undefined ? item.valueAsNumber : ""}
      onChange={(e: any) => onChange(parseInt(e.target.value))}
      style={{minWidth: 60}} 
      className="matr-form-narrow-input"
    />
  )
}

interface DecimalValueProps {
  item: any;
  onChange: any;
  operator: any;
  attr: any;
}

const DecimalValue = ({item, onChange, operator, attr}: DecimalValueProps) => {
  return (
    operator.editor === "NO" ? <></> :
    <NumberFormat
      autoFocus={false}
      allowNegative = {attr.negative}
      decimalScale = {attr.percision}
      fixedDecimalScale = {true}
      value={item.valueAsDecimal ? item.valueAsDecimal : ""}
      onChange={(e: any) => onChange(parseFloat(e.target.value))}
      style={{minWidth: 60}} 
      className="matr-form-narrow-input"
    />
  )
}

interface RelationValueProps {
  field: any;
  item: any;
  foreginFieldId: any;
  onChange: any;
  operator: any;
}

const RelationValue = ({field, item, foreginFieldId, onChange, operator}: RelationValueProps) => {
  const handleChange = (el: any) => {
    if(!item.valueAsArray) {
      onChange([{id: el.id, name: el.name}]);
      return
    }
    if (!item.valueAsArray.some((current: any) => current.id === el.id)) {
      if (!operator.multi) {
        onChange([{id: el.id, name: el.name}]);
      } else {
        onChange([...item.valueAsArray, {id: el.id, name: el.name}]);
      }
    } else {
      let selectionAfterRemoval = item.valueAsArray;
      selectionAfterRemoval = selectionAfterRemoval.filter(
        (current: any) => current.id !== el.id
      );
      onChange([...selectionAfterRemoval]);
    }
  }

  if(operator.editor === "STRING") {
    return <input value={item.value ? item.value : ""} onChange={(e) => onChange(e.target.value)} style={{minWidth: 60}}  className="py-1.5 px-2 flex w-full bg-gray-100 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 focus:outline-none"/>
  } else if(operator.editor === "SELECT"){
    if(item.fieldType === "LOOKUP") {
      return (
        <RelationInput fieldId={field.id} boardId={item.boardId} foreginFieldId={foreginFieldId} relations={item.valueAsArray} onChange={handleChange} narrow={true}/>
      )
    } else {
      return (
        <RelationInput fieldId={item.fieldId} boardId={item.boardId} foreginFieldId={foreginFieldId} relations={item.valueAsArray} onChange={handleChange} narrow={true}/>
      )
    }
  } else {
    return <></>
  }
}

const LookupValue = ({field, item, foreginFieldId, onChange, operator}: RelationValueProps) => {
  const handleChange = (el: any) => {
    if(!item.relations) {
      onChange([el]);
      return
    }
    if (!item.relations.some((current: any) => current.foreginCardId === el.foreginCardId)) {
      if (!operator.multi) {
        onChange([el]);
      } else {
        onChange([...item.relations, el]);
      }
    } else {
      let selectionAfterRemoval = item.relations;
      selectionAfterRemoval = selectionAfterRemoval.filter(
        (current: any) => current.foreginCardId !== el.foreginCardId
      );
      onChange([...selectionAfterRemoval]);
    }
  }

  if(operator.editor === "STRING") {
    return <input value={item.value ? item.value : ""} onChange={(e) => onChange(e.target.value)} style={{minWidth: 60}}  className="py-1.5 px-2 flex w-full bg-gray-100 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 focus:outline-none"/>
  } else if(operator.editor === "SELECT"){
    if(item.fieldType === "LOOKUP") {
      return (
        <LookupInput fieldId={field.id} boardId={item.boardId} foreginFieldId={foreginFieldId} relations={item.relations} onChange={handleChange}/>
      )
    } else {
      return (
        <LookupInput fieldId={item.fieldId} boardId={item.boardId} foreginFieldId={foreginFieldId} relations={item.relations} onChange={handleChange}/>
      )
    }
  } else {
    return <></>
  }
}



export {
  StringValue,
  NumberValue,
  DecimalValue,
  SelectValue,
  CheckboxValue,
  UserValue,
  UpdatedByValue,
  DateTimeValue,
  RelationValue,
  LookupValue
}