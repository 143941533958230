import React, {useEffect} from "react";
import { useFormContext } from "react-hook-form";

interface FieldTextareaProps {
  type: string;
  stringDefaultValue?: string;
  placeholder?: string;
  helpText?: string;
}

function FieldTextarea({attributes}: FieldTextareaProps | any) {
  const methods = useFormContext();
  const {register, unregister, setValue} = methods;

  useEffect(() => {
    unregister('attributes');
  },[])

  useEffect(() => {
    register('attributes.type');
    setValue('attributes.type', "TEXTAREA");
    setValue('attributes.editor', attributes.editor ? attributes.editor : false);
    setValue('attributes.stringDefaultValue', attributes.stringDefaultValue ? attributes.stringDefaultValue : "");
    setValue('attributes.placeholder', attributes.placeholder ? attributes.placeholder : "");
    setValue('attributes.helpText', attributes.helpText ? attributes.helpText : "");
  },[attributes])

  return (
    <>
      <div className="relative inline-flex">
        <label className="font-medium text-gray-600 text-sm flex items-center">
          <input 
            {...register("attributes.editor")}
            className="form-checkbox rounded-sm focus:outline-none focus:outline-offset-0 mr-2"
            type="checkbox" 
          />
          Редактор
        </label>
      </div>
    </>
  );
}
export default FieldTextarea;