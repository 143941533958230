import { useEffect, useRef, useState } from "react";
import { TbFilePlus, TbSearch } from "react-icons/tb";
import BoardFiltersDropdown from "../../../../../components/Views/ViewsMenu/filters";
import { Field, useBlockChangeFiltersMutation, useBlockChangeSearchMutation } from "../../../../../core/graphql/types";
import { usePanel } from "../../../../../core/providers/panel";
import { useSpaceStore } from "../../../../../core/store/store";

export function BlockRecordsListActions({initiator, showCreate}:{initiator: {type: string, id: string}, showCreate: () => void}) {
  const { id: initId, type: initType } = initiator
  const init: string = `${initType}:${initId}`
  const store = useSpaceStore()
  const {page} = usePanel()
  const { actionUserBlocksSet } = store.baseActions
  const { setUserCurrentView } = store.boardActions
  const {block, fields, currentView} = store.data[init]

  let ref = useRef(null)
  const [ viewFields, setViewFields ] = useState<Field[] | any>([]);

  const [, changeViewCustomFilters] = useBlockChangeFiltersMutation()
  const [, changeSearch] = useBlockChangeSearchMutation()

  useEffect(() => {
    if(!fields) { return }
    let fourFields = [currentView.options.titleFieldId, currentView.options.secondField, currentView.options.thirdField].filter(i => i !== null)
    const vf = fields?.filter((f:any) => !currentView?.hiddenFields!.includes(f.id) && fourFields.includes(f.id))
    setViewFields(vf)
  }, [fields])

  
  function onChangeViewFilters(state: any) {
    changeViewCustomFilters({
      blockId: block.id,
      filters: state
    }).then(res => {
      actionUserBlocksSet(res.data?.blockChangeFilters!)
      setUserCurrentView(res.data?.blockChangeFilters!, init)
    })
  }


  function onChangeSearch(state: any) {
    changeSearch({
      blockId: block.id,
      searchTerm: state
    }).then(res => {
      actionUserBlocksSet(res.data?.blockChangeSearch!)
      setUserCurrentView(res.data?.blockChangeSearch!, init)
    })
  }


  return (
    <div className='px-2 flex justify-end items-center max-h-10 w-full text-slate-600 mb-2'>
      {block.permissions?.canSearch && 
        <div className='flex w-full bg-slate-100 mx-2 p-1.5'>
          <TbSearch className='w-4 h-4'/>
        </div>
      }
      {block.permissions?.canFilter &&
        <div className='flex-none rounded-sm p-1.5'>
          <BoardFiltersDropdown viewFields={viewFields} onChange={onChangeViewFilters}/>
        </div>
      }
      {block.permissions?.canAdd &&
        <div className='flex-none hover:bg-slate-200 rounded-sm p-1.5'>
          <TbFilePlus className='w-4 h-4' onClick={() => showCreate()}/>
        </div>
      }
    </div>
  )
}