import { useEffect, useRef, useState } from "react";
import { BoardView, Field, useChangeFiltersMutation, useChangeGroupsMutation, useChangeHiddenFieldsMutation, useChangeRowHeightMutation, useChangeSortsMutation } from "../../../../core/graphql/types";
import { useBoard } from "../../../../core/providers/board";
import HiddenFieldsDropdown from "../fields";
import BoardFiltersDropdown from "../filters";
import GroupByDropdown from "../group";
import RowHeight from "../row_height";
import SortByDropdown from "../sort";
import { useSpace } from "../../../../core/providers/space";
import { useSpaceStore } from "../../../../core/store/store";

export default function UserView() {
    const {initId, initType, fields, currentView, setUserCurrentView} = useBoard()
    const init: string = `${initType}:${initId}`

    const {actionUserViewsSet} = useSpace();
    let ref = useRef(null)
    const [ viewFields, setViewFields ] = useState<Field[] | any>([]);

    const [, changeGroupBy] = useChangeGroupsMutation()
    const [, changeSortBy] = useChangeSortsMutation()
    const [, showHideCustomFields] = useChangeHiddenFieldsMutation()
    const [, changeViewCustomFilters] = useChangeFiltersMutation()
    const [, changeRowHeight] = useChangeRowHeightMutation()

    useEffect(() => {
      if(!fields) { return }
      const vf = fields?.filter((f:any) => !currentView?.hiddenFields!.includes(f.id))
      setViewFields(vf)
    }, [fields])

    function onChangeFieldsVisible(fields: any) {
      showHideCustomFields({
          viewId: (currentView as BoardView)!.id,
          hiddenFields: fields
      }).then(res => {
        actionUserViewsSet(res.data?.changeHiddenFields!)
        setUserCurrentView(res.data?.changeHiddenFields!, init)
      })
    }
    
    function onChangeViewFilters(state: any) {
      changeViewCustomFilters({
        viewId: (currentView as BoardView)!.id,
        filters: state
      }).then(res => {
        actionUserViewsSet(res.data?.changeFilters!)
        setUserCurrentView(res.data?.changeFilters!, init)
      })
    }
  
    function onChangeGroupBy(state: any) {
      changeGroupBy({
        viewId: (currentView as BoardView)!.id,
        groups: state
      }).then(res => {
        actionUserViewsSet(res.data?.changeGroups!)
        setUserCurrentView(res.data?.changeGroups!, init)
      })
    }
  
    function onChangeSortBy(state: any) {
      changeSortBy({
        viewId: (currentView as BoardView)!.id,
        sorts: state
      }).then(res => {
        actionUserViewsSet(res.data?.changeSorts!)
        setUserCurrentView(res.data?.changeSorts!, init)
      })
    }
  
    function onChangeRowHeight(state: any) {
      changeRowHeight({
        viewId: (currentView as BoardView)!.id,
        rowHeight: state
      }).then(res => {
        actionUserViewsSet(res.data?.changeRowHeight!)
        setUserCurrentView(res.data?.changeRowHeight!, init)
      })
    }

    return (
        <div className="flex space-x-2 px-4 py-2 border-b items-center" ref={ref}>
          {currentView!.type === "GRID"  && <HiddenFieldsDropdown viewFields={viewFields} onChange={onChangeFieldsVisible} custom={true}/>}
          {currentView?.permissions?.canFilter && <BoardFiltersDropdown viewFields={viewFields} onChange={onChangeViewFilters}/>}
          {currentView!.type === "GRID" && currentView?.permissions?.canGroup && <GroupByDropdown viewFields={viewFields} onChange={onChangeGroupBy}/>}
          {currentView!.type === "GRID" && currentView?.permissions?.canSort && <SortByDropdown viewFields={viewFields} onChange={onChangeSortBy}/>}
          {currentView!.type === "GRID" && <RowHeight onChange={onChangeRowHeight}/>}
        </div>
    )
}