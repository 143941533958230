//@ts-nocheck
import React from 'react'
import Document from '@tiptap/extension-document'
import { EditorContent, useEditor, ReactRenderer } from '@tiptap/react'
import Mention from './Mention'
import Paragraph from '@tiptap/extension-paragraph'
import Text from '@tiptap/extension-text'
import { Color } from '@tiptap/extension-color'
import ListItem from '@tiptap/extension-list-item'
import TextStyle from '@tiptap/extension-text-style'
import StarterKit from '@tiptap/starter-kit'


const PlainTextEditor = ({value, onChange}: PlainTextEditorProps) => {
  const editor = useEditor({
    extensions: [
      Document,
      Paragraph,
      Text
    ],
    content: value,
    onUpdate: ({ editor }) => {
      const json = editor.getJSON()
      onChange(json)
    },
  })

  return (
    <>
      <EditorContent editor={editor} className="form w-full"/>
    </>
  )
}



export default PlainTextEditor