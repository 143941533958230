import {useEffect} from "react";
import { useFormContext } from "react-hook-form";
import { String } from "../../../../../core/graphql/types";

function FieldAttachment({attributes}: {attributes: String} | any) {
  const methods = useFormContext();
  const {register, unregister, setValue} = methods;


  useEffect(() => {
    unregister('attributes');
  },[])

  useEffect(() => {
    register('attributes.type');
    setValue('attributes.type', "ATTACHMENT");
  },[attributes])

  return (
    <>
    </>
  );
}
export default FieldAttachment;