import { useState } from "react";
import Select from "react-select";
import type { FieldPrimitiveEditorProps } from './types';
import { JsonArrayValue, MultiselectValue, ArrayInput} from '../../../core/graphql/types';
import { MultiSelectIcon } from "../../icons";
import MatrSelect from "../../ui/select/base";


export default function FieldMultiSelector({field, card, updatePrimitive}: FieldPrimitiveEditorProps) {
  const options = field.attributes.options as MultiselectValue[];
  const [selection, setSelection] = useState<MultiselectValue[]>((card[field.id] as unknown as JsonArrayValue)?.valueAsJsonArray! ? (card[field.id] as unknown as JsonArrayValue)?.valueAsJsonArray! as MultiselectValue[] : []);


  function handleOnClick(item: any) {
    setSelection(item as any);
    handleChange(item as MultiselectValue[]);
  }
  
  function handleChange(item: any) {
    updatePrimitive(
      { ...card, [field.id]: {...card[field.id], valueAsJsonArray: item as ArrayInput[]}}, 
      ({...card[field.id], valueAsJsonArray: item as ArrayInput[]} as unknown as JsonArrayValue), 
      {valueAsJsonArray: item as ArrayInput[]}
    )
  }



  return (
    <>
      <div className="relative flex flex-col space-y-1.5">
        <div className="flex items-center h-full">
          <div className="text-slate-400 pr-2"><MultiSelectIcon /></div>
          <label className="text-slate-500 text-sm">{field.title}</label>
        </div>
        <MatrSelect
          isMulti={true}
          placeholder="Выберите..."
          isClearable={true}
          classNamePrefix="matr_select_form"
          isSearchable={false}
          value={selection}
          getOptionLabel = {(option)=>option?.name!}
          getOptionValue = {(option)=>option?.id!}
          onChange={(e) => handleOnClick(e)}
          options={options}
        />
      </div>
    </>
  )
}