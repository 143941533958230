import { memo, FC } from 'react';
import { Handle, Position, NodeProps } from 'reactflow';
import { useFlowContext } from '../../../../core/providers/flow';
import { TbSettings, TbTrash } from 'react-icons/tb';
import { useSpace } from '../../../../core/providers/space';
import { Node } from '../../../../core/graphql/types';


const NodeRequest: FC<NodeProps> = ({ id, data, selected }) => {
  const {services} = useSpace()
  const {chooseNode, deleteNode} = useFlowContext()

  let service = services.find(b => b.id === data?.options?.fetcherId)
  let request = service?.requests?.find(r => r?.id === data.options.requestId)

  return (
    <div className='w-64'>
      <div className={`shadow-sm rounded bg-white ${selected ? "ring-2 ring-blue-400" : "ring-1 ring-slate-200"}`}>
        <div className='flex h-8 w-full px-2 border-b rounded-t items-center justify-between bg-indigo-800'>
          <div className='text-base font-medium text-white truncate'>{data.name}</div>
          <div className='flex items-center gap-2'>
            {/* <button type='button' className='text-indigo-400 hover:text-indigo-200' onClick={() => deleteNode(id)}><TbTrash /></button> */}
            <button type='button' className='text-indigo-400 hover:text-indigo-200' onClick={() => chooseNode(id)}><TbSettings /></button>
          </div>
        </div>
        <div className='h-6 flex border-b px-2 justify-between items-center text-xs text-slate-600'>
          <span>Старт</span>
          <span>Выполнено {(data as Node).outputIsArray ? "[]" : "{}"}</span>
          <Handle type="target" position={Position.Left} style={{top: 43}} className="h-4 !bg-blue-500" />
          <Handle type="source" position={Position.Right} id="SUCCESS" style={{top: 43}} className="h-4 !bg-green-500" />
        </div>
        <div className='min-h-[100px] px-2 py-2 text-slate-800 text-sm'>
          <div>
            <p>Будет исполнен запрос:</p>
            <p><b>{request?.name}</b></p>
            <p>из сервиса: <b>{service?.title}</b></p>
            <p>На выходе будет:</p>
            <p>{data?.outputIsArray ? "Коллекция записей" : "Одиночная запиись"}</p>
          </div>
        </div>
        <div className='h-6 border-t items-center flex justify-end'>
          <div className='px-1 text-xs text-slate-600'>Ошибка</div>
          <Handle type="source" position={Position.Right} id="ERROR" style={{position: "relative", top: 8}} className="h-4 !bg-red-500" />
        </div>   
      </div>
    </div>

  );
};

export default memo(NodeRequest);