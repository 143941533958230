import React, {Fragment, useState, useMemo} from "react";
import { Transition, Dialog } from "@headlessui/react";
import {uuidv4} from '../../../utils'

import { Dashboard } from '@uppy/react'
import Uppy from '@uppy/core'
import AwsS3 from '@uppy/aws-s3'
import ImageEditor from '@uppy/image-editor'
import Webcam from '@uppy/webcam'
import Unsplash from '@uppy/unsplash'
import Compressor from '@uppy/compressor'

import {AttachmentType, useUploadMutation  } from "../../../core/graphql/types";

import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'
import '@uppy/image-editor/dist/style.css'

export default function AvatarModal({onChange}: {onChange: any}) {
  const [, upload] = useUploadMutation()
  let [isOpen, setIsOpen] = useState(false)

  const uppy =
     new Uppy({
      autoProceed: false,
      allowMultipleUploadBatches: false,
      restrictions: {
        maxFileSize: 2 * 1024 * 1024,
        maxNumberOfFiles: 1,
        allowedFileTypes: ['.jpg', '.jpeg', 'gif', '.png'],
      },
      onBeforeFileAdded: (currentFile, files) => {
        const modifiedFile = {
          ...currentFile,
          file_id: uuidv4()
        }
        return modifiedFile
      },
      })
      .use(ImageEditor, {
        quality: 0.96,
        cropperOptions: {
          viewMode: 1,
          background: false,
          autoCropArea: 1,
          responsive: true,
          croppedCanvasOptions: {},
        },
        actions: {
          revert: true,
          rotate: true,
          granularRotate: true,
          flip: true,
          zoomIn: true,
          zoomOut: true,
          cropSquare: true,
          cropWidescreen: true,
          cropWidescreenVertical: true,
        },
      })
      .use(Compressor)
      .use(Webcam, {
        id: 'Webcam',
        modes: ['picture'],
      })
      .use(AwsS3, {
        fields: [],
        // @ts-ignore-next-line
        getUploadParameters (file) {
          console.log(file)
          return upload({
            // @ts-ignore
            fileId: file.file_id,
            fileSize: file.size, 
            fileName: file.name, 
            fileType: file.type || "", 
            attachmentType: AttachmentType.Avatar
          }).then(response => {
            if(response.error) {
              return
            } else {
              return  {
                method: "PUT",
                url: response?.data?.upload?.uploadUrl,
                fields: [],
                headers: {
                  'Content-Type': file.type,
                },
              }
            }
          })
        },
      })
      .on('complete', (result) => {
        if(result.successful){
          console.log('Upload complete! We’ve uploaded these files:', result.successful); // if upload succeeds, let's see what we uploaded
          onChange()
          setIsOpen(false)
          } else {
          console.log('Upload error: ', result.failed); // if upload failed, let's see what went wrong
          }                               
      })



  function closeModal() {
    setIsOpen(false)
  }

  function openModal() {
    setIsOpen(true)
  }

  return (
    <>
      <div className="inset-0 flex items-center justify-center">
        <button
          type="button"
          onClick={openModal}
          className="rounded-md bg-slate-100 px-3 py-1.5 text-sm font-medium text-slate-600 hover:bg-slate-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
        >
          Изменить аватар
        </button>
      </div>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full h-full max-w-min transform overflow-hidden rounded-md bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dashboard
                    uppy={uppy}
                    proudlyDisplayPoweredByUppy={false}
                    showProgressDetails={true}
                    hideProgressAfterFinish={true}
                    plugins={['Webcam', 'ImageEditor']}
                  /> 
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}