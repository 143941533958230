/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect} from "react";
import { useForm, Controller, FormProvider } from "react-hook-form";
import Select from "react-select";
import { 
  useCreateFieldMutation,
  useUpdateFieldMutation,
  useDeleteFieldMutation,
  Field
} from "../../../core/graphql/types";
import { getFieldDescription } from "./description";
import { formatOptionLabel } from "./options";
import { primaryTypeOptions, typeOptions } from "../../../core/enums";
import { customStyles } from "../../../utils";
import { getFieldContent } from "./field";

import { FieldFormProps } from "./FieldFormProps";


import { HiOutlineTrash } from "react-icons/hi";
import { TbSearch, TbSearchOff, TbSquaresFilled, TbX } from "react-icons/tb";
import { SmallSpinner } from "../../loaders";
import { useSpace } from "../../../core/providers/space";

export function FieldForm({boardId, field, fields, column, close}: FieldFormProps): JSX.Element {
  const {boards} = useSpace()
  const methods = useForm();
  const [ loading, setLoading] = useState(true)
  const [ currentType, setCurrentType ] = useState<string>();
  const { register, handleSubmit, control, setValue, formState: { errors } } = methods;
  const [ , createField] = useCreateFieldMutation()
  const [ , updateField] = useUpdateFieldMutation()
  const [ , deleteField] = useDeleteFieldMutation()

  useEffect(() => {
    setCurrentType(typeOptions.find(type => type.value === field.type)?.value);

    setValue('title', field.title || "")
    setValue('type', field.type || "STRING")
    setValue('hidden', field.hidden || false)
    setValue('id', field.id || "")
    setValue('position', field.position || column.idx - 2 ? column.idx - 2 : 0)
    setValue('preview', field.preview)
    setValue('primary', field.primary || false)
    setLoading(false)

  },[field])

  const handleSelectType = (e: any) => {
    setLoading(true)
    setCurrentType(e.value);
    setValue('type', e.value);
    setLoading(false)
  }

  function setTitleForField(title: string) {
    if(!title) {
      return "Колонка " + (fields.length + 1)
    } else {
      return title
    }
  }

  const handelDelete = (field: Field) => {
    deleteField({id: field.id, boardId: field.boardId})
    close()
  }

  const onSubmit = handleSubmit(data => {
    // console.log(data)
    field.id === '' ?
    createField({
      title: setTitleForField(data.title),
      type: data.type,
      position: data.position,
      boardId: boardId,
      preview: data.preview,
      attributes: data.attributes
    })
    : 
    updateField({
      id: data.id,
      type: data.type,
      title: setTitleForField(data.title),
      boardId: boardId,
      preview: data.preview,
      attributes: data.attributes
    })
    close()
  })

  return (
    loading ? 
    <SmallSpinner /> :
    <>
    <div className="flex items-center justify-between">
      <div className="flex items-center space-x-2">
        <div className="py-1 px-2 bg-slate-100 flex space-x-2 rounded">
          <TbSquaresFilled size={20} className="text-indigo-600"/>
          <div className="flex font-medium text-sm">{boards.find(b => b.id === boardId)?.name}</div>
        </div>
        <div className="flex font-normal text-sm">{field.id ? "Редактирование колонки" : "Добавление колонки"}</div>
      </div>
      <button onClick={() => close()}><TbX size={18}/></button>
    </div>
    <FormProvider {...methods}>
      <form onSubmit={onSubmit} className="relative w-full space-y-4 mx-auto">

          <div className="relative flex flex-col space-y-1.5 pt-4">
            <input 
              {...register("title", {required: true})} 
              className="text-xl font-medium text-slate-800 placeholder:text-slate-300 focus:outline-none mb-4" 
              placeholder="Введите название колонки"
            />
          </div>
          {/* <Controller
            name="preview"
            control={control}
            render={({ field: { onChange, value, ref }}) => (
              <div 
                className={`flex items-center py-2 px-2 text-sm ${value ? "bg-green-300 text-green-800" : "bg-slate-200 text-slate-600"} rounded-sm font-normal justify-center w-10 cursor-default`} 
                onClick={() => onChange(!value)}
              >
                {value ? <TbSearch className='h-4 w-4 text-green-800'/> : <TbSearchOff className='h-4 w-4 text-slate-400'/>}
              </div>
            )}
          /> */}
          {/* <div className="relative w-10 h-10 ml-4 bg-gray-100 rounded-sm items-center justify-center flex">
            <input {...register("preview")}
              type="checkbox"
              className=""
            />
          </div> */}

        <div className="relative">
          <Controller
            name="type"
            control={control}
            render={({ field: { onChange, value, ref }}) => (
              <div className="mt-2">
                <Select
                  classNamePrefix="matr_select_form"
                  styles={customStyles}
                  placeholder="Выберите тип поля"
                  isSearchable={false}
                  ref={ref}
                  formatOptionLabel={formatOptionLabel}
                  defaultValue={typeOptions.find(type => type.value === value)}
                  value={typeOptions.find(type => type.value === value)}
                  onChange={(e) => handleSelectType(e)}
                  options={field.id && field.primary ? primaryTypeOptions :typeOptions}
                />
              </div>
            )}
          />
        </div>
        <div className="leading-6 flex">{getFieldDescription(currentType!)}</div>
        <div className="flex flex-col space-y-4">{getFieldContent(currentType!, field)}</div>
        <div className="flex flex-row justify-between">
          {field.primary || field.id === '' ? "" : 
            <a className="p-2 text-sm font-medium text-center text-red-800 transition duration-200 rounded-md bg-red-400 hover:bg-red-500 ease" onClick={() => handelDelete(field)}><HiOutlineTrash size={18}/></a>
          }
          <div className={`flex flex-row gap-4 ${field.primary || field.id === '' ? "w-full justify-end" : ""}`}>
            <button type="button" className="flex px-3 py-2 text-sm font-medium justify-center items-center text-slate-600 hover:bg-slate-200 rounded-md cursor-pointer" onClick={() => close()}>Отмена</button>
            <button type="submit" className="flex items-center justify-center px-3 py-2 text-sm font-medium text-center text-white transition bg-indigo-600 rounded-md hover:bg-indigo-700">Сохранить</button>
          </div>
        </div>
      </form>
    </FormProvider>
    </>
  );
};