import { useEffect, useState } from "react";
import { Column } from "react-data-grid";
import { Row } from "../types";
import type { RenderCellProps, RenderEditCellProps } from 'react-data-grid';
import { Currency, Decimal, DecimalValue, Field,  Number, NumberValue } from '../../../../core/graphql/types';
import SummaryRows from "../rows/summary";
import { GroupCell } from "../rows/group";
import { HeaderRenderer } from "../rows/header";
import NumberFormat, { NumberFormatValues } from "react-number-format";
import { useBoard } from "../../../../core/providers/board";
import { useSpaceStore } from "../../../../core/store/store";

interface BaseRenderCellProps<TRow, TSummaryRow> extends RenderCellProps<TRow, TSummaryRow> {
  field: Field;
}

interface BaseRenderEditCellProps<TRow, TSummaryRow> extends RenderEditCellProps<TRow, TSummaryRow> {
  field: Field;
}

// Column

export function currencyColumn({ field, canEditInline }: { field: Field; canEditInline: boolean }): Column<Row, any> {
  return {
    key: field.id,
    name: field.title,
    width: field.width,
    resizable: true,
    frozen: field.primary ? true : false,
    renderHeaderCell: (p) => { return <HeaderRenderer field={field} {...p}/> },
    renderCell: (p) => { return <CurrencyCell {...p} field={field}/> },
    renderEditCell: (p) => { return canEditInline ? <CurrencyCellEditor {...p} field={field}/> : <></> },
    renderGroupCell: (p) => { return <GroupCell {...p} field={field}/> },
    renderSummaryCell: (p) => { return <SummaryRows {...p} field={field}/> },
    editorOptions: {
      displayCellContent: !canEditInline
    }
  }
};

// Cell

export function CurrencyCell<Row, SummaryRow>({field, row, column}: BaseRenderCellProps<Row, SummaryRow>) {
  const value = (row[column.key as keyof Row] as unknown as DecimalValue)?.valueAsDecimal!
  const attr = (field.attributes as Currency);
  return (
    <div className="flex items-start h-full text-sm justify-end px-2 py-2">
      <NumberFormat 
        allowNegative={attr.negative ? true : false}
        fixedDecimalScale={true}
        decimalScale={attr.percision ? attr.percision : 0} 
        value={value} 
        displayType={'text'} 
        thousandSeparator={true} 
        prefix={` ${attr?.currency! ? attr?.currency! : ''} `} 
      />
    </div>
  );
}

// CellEditor

export function CurrencyCellEditor<Row, SummaryRow>({field, row, column, onRowChange, onClose}: BaseRenderEditCellProps<Row, SummaryRow>) {  
  const {initId, initType} = useBoard()
  const init: string = `${initType}:${initId}`
  const store = useSpaceStore()
  const {setEditMode} = store.boardActions;
  const {rowHeight} = store.data[init];

  const [value, setValue] = useState((row[column.key as keyof Row] as unknown as DecimalValue)?.valueAsDecimal! ? (row[column.key as keyof Row] as unknown as DecimalValue)?.valueAsDecimal! : "")
  
  const attr = (field.attributes as Currency);

  function handleChange(e: NumberFormatValues) {
    console.log(e)
    setEditMode(true, init)
    setValue(e?.floatValue!)
    onRowChange({ ...row, [column.key]: {...row[column.key as keyof Row], valueAsDecimal: e.floatValue?.toFixed(attr.percision!)}})
  }

  function handleBlur() {
    setEditMode(false, init)
    onClose(true)
  }

  return (
    <NumberFormat
      autoFocus
      allowNegative={attr.negative!}
      prefix={attr.currency ? attr.currency! + " " : ""}
      decimalScale={attr.percision!}
      className={`w-full leading-5 text-sm text-right focus:outline-none p-2`}
      style={{paddingBottom: rowHeight - 28}}
      displayType="input"
      value={value}
      onFocus={()=>setEditMode(true, init)}
      onValueChange={(values) => handleChange(values)}
      onBlur={() => handleBlur()}
    />
  );
}

