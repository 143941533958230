export type Menu = {
    menu: MenuItems;
  };
  
export type MenuItem = {
  name: string;
  action: () => void;
  icon: React.ReactNode;
  disabled: boolean;
};
  
export type MenuItems = MenuItem[];

export const ActionMenu: React.FC<Menu> = ({menu}) => {  
  function handleClick(action: () => void) {
    action()
  }

  return (
    <div className="overflow-hidden rounded border shadow-lg bg-white p-1 mt-1.5">
      <div className="relative bg-white text-slate-600">
        {menu.map((item, i: number) => {
          return (
            <button
              type="button"
              disabled={item.disabled}
              key={i}
              onClick={(e) => handleClick(item.action)}
              className="w-full py-1.5 px-2 space-x-1.5 hover:bg-indigo-600 hover:text-white disabled:bg-white disabled:text-slate-300 flex text-sm text-right rounded whitespace-nowrap items-center cursor-pointer"
            >
              {item.icon}
              <div className="text-sm font-medium pr-1.5">
                {item.name}
              </div>
            </button>
          )
        })}
      </div>
    </div>
  );
}
