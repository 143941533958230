import { RenderCheckboxProps } from "react-data-grid";
import { forwardRef } from 'react';


// export function checkboxColumn({ field }: { field: Field; }): Column<Row, any> {
//     return {
//       key: field.id,
//       name: field.title,
//       width: field.width,
//       resizable: true,
//       frozen: field.primary ? true : false,
//       renderHeaderCell: (p) => { return <HeaderRenderer field={field} {...p}/> },
//       renderCell: (p) => { return <StringCell {...p} field={field}/> },
//       renderEditCell: (p) => { return <StringCellEditor {...p} field={field}/> },
//       renderGroupCell: (p) => { return <GroupCell {...p} field={field}/> },
//       renderSummaryCell: (p) => { return <SummaryRows {...p} field={field}/> },
//     }
//   };




export const CheckboxFormatter = forwardRef<HTMLInputElement, RenderCheckboxProps>(
  function CheckboxFormatter({ onChange, ...props }: RenderCheckboxProps, ref) {
    function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
      onChange(e.target.checked, (e.nativeEvent as MouseEvent).shiftKey);
    }

    return (          
      <input 
        type="checkbox"
        ref={ref}
        {...props}
        className="form-check-input border border-gray-300 rounded-sm outline-0 focus:outline-0 items-start"
        onChange={handleChange}
      />
    );
  }
);