import { useEffect, useState } from "react";
import { Column } from "react-data-grid";
import { Row } from "../types";
import type { RenderCellProps, RenderEditCellProps } from 'react-data-grid';
import { Attachment, AttachmentItem, AttachmentValue, Field, Maybe, StringValue, useAddAttachmentsMutation, useRemoveAttachmentMutation } from '../../../../core/graphql/types';
import SummaryRows from "../rows/summary";
import { GroupCell } from "../rows/group";
import { HeaderRenderer } from "../rows/header";
import AttachmentUploader from '../../../Uploaders/AttachmentUploader';
import Viewer from '../../../Viewer/viewer';
import { HiDocument } from "react-icons/hi";

const storage_url = process.env.REACT_APP_STORAGE_URL ? process.env.REACT_APP_STORAGE_URL : ""

interface RenderStringCellProps<TRow, TSummaryRow> extends RenderCellProps<TRow, TSummaryRow> {
  field: Field;
}

interface RenderEditStringCellProps<TRow, TSummaryRow> extends RenderEditCellProps<TRow, TSummaryRow> {
  field: Field;
}

// Column

export function attachmentColumn({ field, canEditInline }: { field: Field; canEditInline: boolean }): Column<Row, any> {
  return {
    key: field.id,
    name: field.title,
    width: field.width,
    resizable: true,
    frozen: field.primary ? true : false,
    renderHeaderCell: (p) => { return <HeaderRenderer field={field} {...p}/> },
    renderCell: (p) => { return <AttachmentCell {...p} field={field}/> },
    renderEditCell: (p) => { return canEditInline ? <AttachmentCellEditor {...p} field={field}/> : <></>},
    renderGroupCell: (p) => { return <GroupCell {...p} field={field}/> },
    renderSummaryCell: (p) => { return <SummaryRows {...p} field={field}/> },
    editorOptions: {
      displayCellContent: !canEditInline
    }
  }
};

// Cell

export function AttachmentCell<Row, SummaryRow>({field, row, column, onRowChange}: RenderStringCellProps<Row, SummaryRow>) {
  const value = (row[column.key as keyof Row] as unknown as AttachmentValue)?.attachments! as Maybe<AttachmentItem>[]

  const attachments = value?.filter(i => i?.id).map(item => {
    if(item?.thumbnail) {
      if(item.contentType?.startsWith("image/")) {
        return (
          <div className="flex h-full border" key={item?.id}>
            <img className="w-fit mx-auto h-full object-cover object-center rounded-sm" alt={`${item?.filename!}`} src={`${storage_url}${item?.path!}/thumb/${item.filename}`}></img>
          </div>
        )
      } else {
        return (
          <div className="flex h-full border" key={item?.id}>
            <img className="w-full mx-auto h-full object-cover object-center rounded-sm" alt={`${item?.filename!}`} src={`${storage_url}${item?.path!}/thumb/${item?.filename!.replace(/\.[^/.]+$/, "")}.png`}></img>
          </div>
        )
      }
    } else {
      return (
        <div className="flex h-full" key={item?.id}>
          <HiDocument className="h-full"/>
        </div>
      )
    }
  })

  return <div className="flex py-0.5 px-0.5 flex-nowrap h-full overflow-hidden w-full space-x-0.5">{attachments}</div>
}

// CellEditor

export function AttachmentCellEditor<Row, SummaryRow>({field, row, column, onRowChange, onClose}: RenderEditStringCellProps<Row, SummaryRow>) {
    const attr = (field.attributes as Attachment);
    
    const [attachments, setAttachments] = useState<any>([])
    const [value, setValue] = useState((row[column.key as keyof Row] as unknown as AttachmentValue)?.attachments! ? (row[column.key as keyof Row] as unknown as AttachmentValue)?.attachments! : [])
    const [, addAttachments] = useAddAttachmentsMutation()
    const [, removeAttachment] = useRemoveAttachmentMutation()
  
    let [isOpen, setIsOpen] = useState(false)
    let [currentFileNo, setCurrentFileNo] = useState(0)
  
    function closeViewer() {
      setCurrentFileNo(0)
      setIsOpen(false)
    }
  
    function openViewer(index: number) {
      setCurrentFileNo(index)
      setIsOpen(true)
    }
  
    useEffect(() => {
      setAttachments(value?.filter(i => i?.id).map((item, index) => {
        if(item?.thumbnail) {
          if(item.contentType?.startsWith("image/")) {
            return (
              <div className="flex col-span-1 h-full w-full items-center justify-center ring-1 rounded-sm hover:ring-2 ring-slate-200 hover:ring-sky-400" key={item?.id} onClick={() => openViewer(index)}>
                <img className="w-fit mx-auto h-full object-cover object-center rounded-sm" alt={`${item?.filename!}`} src={`${storage_url}${item?.path!}/thumb/${item.filename}`}></img>
              </div>
            )
          } else {
            return (
              <div className="flex col-span-1 h-full w-full items-center justify-center ring-1 rounded-sm hover:ring-2 ring-slate-200 hover:ring-sky-400" key={item?.id} onClick={() => openViewer(index)}>
                <img className="w-full mx-auto h-full object-cover object-center rounded-sm" alt={`${item?.filename!}`} src={`${storage_url}${item?.path!}/thumb/${item?.filename!.replace(/\.[^/.]+$/, "")}.png`}></img>
              </div>
            )
          }
        } else {
          return (
            <div className="flex col-span-1 h-full w-full items-center justify-center ring-1 rounded-sm hover:ring-2 ring-slate-200 hover:ring-sky-400" key={item?.id} onClick={() => openViewer(index)}>
              <HiDocument className="h-full"/>
            </div>
          )
        }
      }))
    },[value])
  
    function handleChange(files: any) {
      let id = (row[column.key as keyof Row] as unknown as AttachmentValue).id
      let attachments = files.map((f: { file_id: string; }) => f.file_id)
  
      addAttachments({id: id, files: attachments}).then(resp => {
        if(resp.error) {
          return
        }
        setValue((row[column.key as keyof Row] as unknown as AttachmentValue)?.attachments!)
      })
    }
  
    function onRemove(index: number) {
      let value = (row[column.key as keyof Row] as unknown as AttachmentValue)!
      let attachment_id = value.attachments![index]?.id!
  
      removeAttachment({attachmentId: attachment_id, valueId: value.id}).then(resp => {
        if(resp.error) {
          return
        }
        let new_attachments =  (row[column.key as keyof Row] as unknown as AttachmentValue)?.attachments!
        if(new_attachments.length > 0) {
          setValue(new_attachments)
        } else {
          closeViewer()
          setValue(new_attachments)
        }
        
      })
    }
  
    return (
      <>
        <div className="w-full z-auto h-full focus:outline-none">
          <div className="flex w-full bg-white items-center">
            
            <div className="truncate flex flex-col bg-white shadow-sm z-10 w-full outline-2 outline outline-blue-600 rounded-sm" >
              <div className='p-1.5'>
                <AttachmentUploader buttonType="iconText" onChange={handleChange}/>
              </div>
              {attachments.length > 0 && <div className="grid grid-cols-3 flex-nowrap h-full overflow-hidden w-full gap-2 p-2">{attachments}</div>}
            </div>
          </div>
        </div>
        {isOpen && <Viewer open={isOpen} close={closeViewer} attachments={value} currentFileNo={currentFileNo} onRemove={onRemove}/>}
      </>
    )
}

