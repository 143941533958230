import { useState } from "react";
import NumberFormat, {NumberFormatValues} from 'react-number-format';
import { DecimalValue } from '../../../core/graphql/types';
import { PercentIcon } from '../../icons';
import type { FieldPrimitiveEditorProps } from './types';

export default function FieldPercent({field, card, updatePrimitive}: FieldPrimitiveEditorProps) {
  const [value, setValue] = useState((card[field.id] as unknown as DecimalValue)?.valueAsDecimal! ? (card[field.id] as unknown as DecimalValue)?.valueAsDecimal! * 100 : "")
  const attr = field.attributes;

  function handleChange(e: NumberFormatValues) {
    updatePrimitive(
      { ...card, [field.id]: {...card[field.id], valueAsDecimal: e?.floatValue!/100}}, 
      ({...card[field.id], valueAsDecimal: e?.floatValue!/100} as unknown as DecimalValue), 
      {valueAsDecimal: e?.floatValue!/100}
    )
    setValue(e?.floatValue!/100)
  }


  return (
    <div className="relative flex flex-col space-y-1.5">
      <div className="flex items-center h-full">
        <div className="text-slate-400 pr-2"><PercentIcon /></div>
        <label className="text-slate-500 text-sm">{field.title}</label>
      </div>
      <NumberFormat
        autoFocus={false}
        allowNegative={attr.negative}
        suffix={"%"}
        decimalScale={attr.percision}
        className="matr-form-input"
        displayType="input"
        value={value}
        onValueChange={(values) => handleChange(values)}
      />
    </div>
  );
}