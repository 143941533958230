import React, { useEffect } from "react";
import { BigSpinner } from "../../../components/loaders/index";
import { mergeArrays } from "../../../utils";
import {
  Board,
  BoardView,
  Card, Field,
  ValuePayload,
  useBoardChangesSubscription
} from "../../graphql/types";
import { useSpaceStore } from "../../store/store";

interface BoardSubscriptionContextType {
    boardId: string,
    initType: string
}

let BoardSubscriptionContext = React.createContext<BoardSubscriptionContextType>(null!);

export function BoardSubscriptionProvider({ boardId, children }: { boardId: string, children: React.ReactNode }) {
  const initType = "board"
  const init: string = `${initType}:${boardId}`
  const store = useSpaceStore()
  const {addView, updateView, updateViews, deleteView, setFields, setCards, setValues, updateSources} = store.boardActions
  const {inBlock, views, sources, fields, cards, values, currentView} = store.data[init]

  const [boardChanges] = useBoardChangesSubscription({variables: {boardId: boardId!}});

  function reorderCards(card: Card) {
    let fromCardIndex = cards.findIndex((c: { id: string | undefined; }) => c.id === card?.id)
    let toCardIndex = card?.position!
    const newCards = [...cards];

    newCards.splice(toCardIndex, 0, newCards.splice(fromCardIndex, 1)[0]);
    newCards.map((row, idx) => row['position'] = idx)

    setCards(newCards, init)
  }

  // SUBSCRIPTIONS
  useEffect(() => {
    if(inBlock || !boardChanges.data?.boardChanges || !boardChanges.data?.boardChanges) {
      return
    }

    switch (boardChanges.data?.boardChanges?.action!) {
      case "createField":
        setFields([...fields, ...boardChanges.data?.boardChanges?.fields!] as Field[], init)
        setValues([...values, ...boardChanges.data?.boardChanges?.values!] as ValuePayload[], init)
        break;
      case "updateField":
        updateViews(mergeArrays(views, boardChanges.data?.boardChanges?.views!) as BoardView[], init)
        setFields(mergeArrays(fields, boardChanges.data?.boardChanges?.fields!) as Field[], init)
        setValues(mergeArrays(values, boardChanges.data?.boardChanges?.values!) as ValuePayload[], init)
        break;
      case "deleteField":
        updateViews(mergeArrays(views, boardChanges.data?.boardChanges?.views!) as BoardView[], init)
        let fields_ids = boardChanges.data?.boardChanges?.fields!.map((f: any) => f.id)
        setFields(fields.filter((f: any) => !fields_ids.includes(f.id)) as Field[], init)
        setValues(values.filter((v: any) => !fields_ids.includes(v.fieldId)) as ValuePayload[],init)
        break;
      case "changeFieldPositions":
        setFields(boardChanges.data?.boardChanges?.fields! as Field[], init)
        break;
      case "changeFieldWidth":
        setFields(mergeArrays(fields, boardChanges.data?.boardChanges?.fields!) as Field[], init)
        break;
      case "changeFieldSummary":
        setFields(mergeArrays(fields, boardChanges.data?.boardChanges?.fields!) as Field[], init)
        break;
      case "createCard":
        updateViews(mergeArrays(views, boardChanges.data?.boardChanges?.views!) as BoardView[], init)
        let newCards = [...cards, ...boardChanges.data?.boardChanges?.cards!]
        newCards.map((card: Card, idx: number) => ({...card, position: idx}))
        setCards(newCards as Card[], init)
        setValues([...values, ...boardChanges.data?.boardChanges?.values!] as ValuePayload[], init)
        break;
      case "createCardWithValues":
        updateViews(mergeArrays(views, boardChanges.data?.boardChanges?.views!) as BoardView[], init)
        setCards(boardChanges.data?.boardChanges?.cards! as Card[], init)
        setValues(mergeArrays(values, boardChanges.data?.boardChanges?.values!) as ValuePayload[], init)
        break;
      case "updateCard":
        updateViews(mergeArrays(views, boardChanges.data?.boardChanges?.views!) as BoardView[], init)
        setValues(mergeArrays(values, boardChanges.data?.boardChanges?.values!) as ValuePayload[], init)
        break;
      case "deleteCard":
        let cards_ids = boardChanges.data?.boardChanges?.cards!.map((c: any) => c.id)
        let restCards = cards.filter((f: any) => !cards_ids.includes(f.id))
        restCards.map((row: any, idx: number) => ({...row, position: idx}))
        setCards(restCards as Card[], init)
        setValues(values.filter((v: any) => !cards_ids.includes(v.cardId)) as ValuePayload[], init)
        break;
      case "changeCardPosition":
        let card = boardChanges.data?.boardChanges?.cards![0]
        reorderCards(card!)
        break;
      case "addAttachments":
        setValues(mergeArrays(values, boardChanges.data?.boardChanges.values!) as ValuePayload[], init)
        break;
      case "removeAttachment":
        setValues(mergeArrays(values, boardChanges.data?.boardChanges.values!) as ValuePayload[], init)
        break;
      case "updateValues":
        setValues(mergeArrays(values, boardChanges.data?.boardChanges.values!) as ValuePayload[], init)
        break;
      case "createBoardView":
        addView(boardChanges.data?.boardChanges.views![0]!, init)
        break;
      case "updateBoardView":
        updateView(boardChanges.data?.boardChanges.views![0]!, init)
        break;
      case "deleteBoardView":
        deleteView(boardChanges.data?.boardChanges.views![0]!, init)
        break;
      case "updateExternalSources":
        updateSources(mergeArrays(sources, boardChanges.data?.boardChanges?.sources?.boards!) as Board[], init)
        break;
      case "boardDataFromServices":
        setFields(boardChanges.data?.boardChanges?.fields! as Field[], init)
        setCards(boardChanges.data?.boardChanges?.cards! as Card[], init)
        setValues(boardChanges.data?.boardChanges?.values! as ValuePayload[], init)
        break;
      default:
        break;
    }
  },[boardChanges.data?.boardChanges])



  let value = { 
    boardId,
    initType
  };

  return <BoardSubscriptionContext.Provider value={value}>{children}</BoardSubscriptionContext.Provider>

}