import { Column } from "react-data-grid";
import { Row } from "../types";
import type { RenderCellProps, RenderEditCellProps } from 'react-data-grid';
import { DatetimeValue, DecimalValue, Field, Formula, StringValue } from '../../../../core/graphql/types';
import SummaryRows from "../rows/summary";
import { GroupCell } from "../rows/group";
import { HeaderRenderer } from "../rows/header";
import { DateTime } from "luxon"



interface RenderStringCellProps<TRow, TSummaryRow> extends RenderCellProps<TRow, TSummaryRow> {
  field: Field;
}

interface RenderEditStringCellProps<TRow, TSummaryRow> extends RenderEditCellProps<TRow, TSummaryRow> {
  field: Field;
}

function autoFocusAndSelect(input: HTMLInputElement | null) {
  input?.focus();
  input?.select();
}

// Column

export function formulaColumn({ field, canEditInline }: { field: Field; canEditInline: boolean }): Column<Row, any> {
  return {
    key: field.id,
    name: field.title,
    width: field.width,
    resizable: true,
    frozen: field.primary ? true : false,
    renderHeaderCell: (p) => { return <HeaderRenderer field={field} {...p}/> },
    renderCell: (p) => { return <FormulaCell {...p} field={field}/> },
    renderGroupCell: (p) => { return <GroupCell {...p} field={field}/> },
    renderSummaryCell: (p) => { return <SummaryRows {...p} field={field}/> },
    editorOptions: {
      displayCellContent: !canEditInline
    }
  }
};

// Cell

export function FormulaCell<Row, SummaryRow>({field, row, column}: RenderStringCellProps<Row, SummaryRow>) {
  const value = (row[column.key as keyof Row] as unknown as StringValue)?.value!
  const attr = (field.attributes as Formula);

  if (value && attr.expressionType === "DECIMAL") {
    return (
      <div className="w-full flex items-start text-sm h-full p-2 text-right">{parseFloat(value).toFixed(2)} </div>
    )
  } else if(value && attr.expressionType === "DATE") {
    return (
      <div className="w-full flex items-start text-sm h-full p-2">{DateTime.fromMillis(Date.parse(value)).toLocaleString()}</div>
    )
  } else if(value) {
    return (
      <div className="w-full flex items-start text-sm h-full p-2">{value}</div>
    )
  } else {
    return (
      <div className="w-full flex items-start text-sm h-full"></div>
    )
  }
}