import { useEffect, useRef, useState } from "react";
import { Column } from "react-data-grid";
import { Row } from "../types";
import type { RenderCellProps, RenderEditCellProps } from 'react-data-grid';
import { Field,  Number, NumberValue, SpaceUser, Map, JsonArrayValue, Maybe, CollaboratorValue, Collaborator, Multiselect, MultiselectValue, SelectOption } from '../../../../core/graphql/types';
import SummaryRows from "../rows/summary";
import { GroupCell } from "../rows/group";
import { HeaderRenderer } from "../rows/header";
import { intToRGB, hashCode, hexToRGB} from "../../../../utils";
import { useBoard } from "../../../../core/providers/board";
import { HiCheck, HiChevronDown, HiChevronUp } from "react-icons/hi";
import { useSpaceStore } from "../../../../core/store/store";
import { TbCaretDownFilled, TbCaretUpFilled, TbSearch } from "react-icons/tb";
import { MatrPopover } from "../../../ui/portal";

interface BaseRenderCellProps<TRow, TSummaryRow> extends RenderCellProps<TRow, TSummaryRow> {
  field: Field;
  canEdit: boolean;
}

interface BaseRenderEditCellProps<TRow, TSummaryRow> extends RenderEditCellProps<TRow, TSummaryRow> {
  field: Field;
}

// Column

export function multiselectColumn({ field, canEditInline }: { field: Field; canEditInline: boolean }): Column<Row, any> {
  return {
    key: field.id,
    name: field.title,
    width: field.width,
    resizable: true,
    frozen: field.primary ? true : false,
    renderHeaderCell: (p) => { return <HeaderRenderer field={field} {...p}/> },
    renderCell: (p) => { return <MultiselectCell {...p} field={field} canEdit={canEditInline}/> },
    // renderEditCell: (p) => { return canEditInline ? <MultiselectCellEditor {...p} field={field}/>  : <></> },
    renderGroupCell: (p) => { return <GroupCell {...p} field={field}/> },
    renderSummaryCell: (p) => { return <SummaryRows {...p} field={field}/> },
    editorOptions: {
      displayCellContent: true
    }
  }
};


export function MultiselectCell<Row, SummaryRow>(props: BaseRenderCellProps<Row, SummaryRow>) {
  const {initId, initType, setEditMode} = useBoard();
  const init: string = `${initType}:${initId}`;
  const {row, column, tabIndex, field, canEdit, onRowChange} = props
  const cellRef = useRef<any>(null);
  const options: Maybe<SelectOption>[] = (field?.attributes! as Multiselect)?.options!
  const [ open, setOpen ] = useState(false);
  const value = (row[column.key as keyof Row] as unknown as JsonArrayValue)?.valueAsJsonArray as Maybe<SelectOption>[] || []
  const [searchTerm, setSearchTerm] = useState<any>("")
  const [selection, setSelection] = useState<MultiselectValue[] | any>((row[column.key as keyof Row] as unknown as JsonArrayValue)?.valueAsJsonArray ? (row[column.key as keyof Row] as unknown as JsonArrayValue)?.valueAsJsonArray : []);


  function openOptions() { setOpen(true) };
  function closeOptions() { setOpen(false)};

  function handleOnClick(item: MultiselectValue) {
    if (!selection.some((current: MultiselectValue) => current?.id === item.id)) {
      setSelection([...selection, item]);
      handleChange([...selection, item]);
    } else {
      let selectionAfterRemoval = selection;
      selectionAfterRemoval = selectionAfterRemoval.filter(
        (current: MultiselectValue) => current?.id !== item.id
      );
      setSelection([...selectionAfterRemoval]);
      handleChange([...selectionAfterRemoval]);
    }
  }

  function handleChange(item: Maybe<MultiselectValue>[] | null) {
    setEditMode(true, init)
    onRowChange({ ...row, [column.key]: {...row[column.key as keyof Row], valueAsJsonArray: item}})
  }

  function isItemInSelection(item: MultiselectValue) {
    if (selection.some((current: MultiselectValue) => current?.id === item.id)) {return true;}
    return false;
  }

  function searchInOptions(value: SelectOption) {
    let result = options?.findIndex((obj) => obj?.id === value.id);
    if(result === -1) { 
      return <div className='h-5'></div>
    } else {
      return (
        <div className="flex h-5 py-0.5 rounded-full px-2 items-center text-sm truncate" style={{backgroundColor: `${hexToRGB(intToRGB(hashCode(value?.name!)), 0.4)}`}}>
          <span className='truncate'>{options[result!]?.name}</span>
        </div>
      )
    }
  }

  return (
    <>
      <div className="flex h-full my-2 px-2" ref={cellRef}>
        {
          tabIndex == 0 && canEdit ?
            <div className="flex h-full relative w-full space-x-1" onClick={() => openOptions()}>
              {value.length && Array.isArray(value) ? 
                value.map((it: Maybe<SelectOption>) => {
                  return <span key={it?.id} className="truncate">{searchInOptions(it!)}</span>
                })
                : 
                <div className={`block w-full h-full pr-6`}>
                  <span className="truncate"></span>
                </div>
              }
              <div className="absolute right-0 w-5 h-5 flex items-center justify-center hover:bg-slate-200 text-slate-600">
                {open ? <TbCaretUpFilled className="h-4 w-4" /> : <TbCaretDownFilled className="h-4 w-4" />}
              </div>
            </div>
          :
          <div className="flex relative w-full space-x-1">
            {value.length && Array.isArray(value) ? 
              value.map((it: Maybe<SelectOption>) => {
                return <span key={it?.id} className="truncate">{searchInOptions(it!)}</span>
              })
              : <></>
            }
          </div>
        }
      </div>
      
      {tabIndex == 0 && canEdit && open &&
        <MatrPopover onClose={closeOptions} reference={cellRef.current} placement={"bottom-start"}>
          <div className="bg-white shadow rounded-sm min-w-min max-h-96 relative -mt-1.5 mb-2">
            <div className="w-full">
              <TbSearch size={16} className="absolute left-2 top-2 text-slate-400"/>
              <input className="bg-slate-100 outline-none focus:outline-none w-full py-1.5 pl-8 pr-2 text-sm" onChange={(v) => setSearchTerm(v.target.value)}/>
            </div>
            <ul className="overflow-auto outline-0" style={{width: column.width + 'px'}}>
              {(searchTerm ? options?.filter(option => option?.name.toLowerCase().startsWith(searchTerm.toLowerCase())) : options).map(item => (
                <li className="flex w-full" key={item?.id}>
                  <div onClick={() => handleOnClick(item as MultiselectValue)} className="w-full flex px-2 py-1.5 text-sm justify-between items-center text-left hover:bg-gray-100">
                    <span className="truncate">{searchInOptions(item!)}</span>
                    {isItemInSelection(item as MultiselectValue) ? <HiCheck className="w-4 h-4 text-green-500"/> : ""}
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </MatrPopover>
      }
    </>
  );
}


// Cell

// export function MultiselectCell<Row, SummaryRow>({field, row, column}: BaseRenderCellProps<Row, SummaryRow>) {
//     const value = (row[column.key as keyof Row] as unknown as JsonArrayValue)?.valueAsJsonArray as Maybe<Map>[] || []
//     const attr = (field.attributes as Multiselect);
//     const searchInOptions = (value: Map | null) => {
//       if(!value || value == null) {
//         return 
//       }
//       let result = attr?.options?.find(obj => obj?.id === value.id);
//       return result?.name!;
//     }
  
//     return (
//       <div className="flex my-2 px-2">
//         <div className="flex space-x-1 text-sm truncate">
//           {value.length && Array.isArray(value) ? 
//             value.map((it: Maybe<Map>) => {
//               return <div key={it?.id} className="flex h-5 py-1 rounded-full text-sm px-2 items-center" style={{backgroundColor: `${hexToRGB(intToRGB(hashCode(it?.name!)), 0.4)}`}}>{searchInOptions(it)}</div>
//             })
//             : <></>
//           }
//         </div>
//       </div>
//     );
// }

// // CellEditor

// export function MultiselectCellEditor<Row, SummaryRow>({field, row, column, onRowChange, onClose}: BaseRenderEditCellProps<Row, SummaryRow>) {  
//   const {initId, initType} = useBoard();
//   const init: string = `${initType}:${initId}`;
//   const store = useSpaceStore()
//   const {setEditMode} = store.boardActions
//   const options = (field.attributes as Multiselect).options as Map[];
//   const [open, setOpen] = useState(true);
//   const [selection, setSelection] = useState<MultiselectValue[] | any>((row[column.key as keyof Row] as unknown as JsonArrayValue)?.valueAsJsonArray ? (row[column.key as keyof Row] as unknown as JsonArrayValue)?.valueAsJsonArray : []);

//   let value = (row[column.key as keyof Row] as unknown as JsonArrayValue)?.valueAsJsonArray! || []

//   function handleOnClick(item: MultiselectValue) {
//     if (!selection.some((current: MultiselectValue) => current?.id === item.id)) {
//       setSelection([...selection, item]);
//       handleChange([...selection, item]);
//     } else {
//       let selectionAfterRemoval = selection;
//       selectionAfterRemoval = selectionAfterRemoval.filter(
//         (current: MultiselectValue) => current?.id !== item.id
//       );
//       setSelection([...selectionAfterRemoval]);
//       handleChange([...selectionAfterRemoval]);
//     }
//   }

//   function handleChange(item: Maybe<MultiselectValue>[] | null) {
//     setEditMode(true, init)
//     onRowChange({ ...row, [column.key]: {...row[column.key as keyof Row], valueAsJsonArray: item}})
//     // setValueForUpdate({...row[column.key as keyof Row], valueAsJsonArray: item}, init)
//   }

//   function isItemInSelection(item: MultiselectValue) {
//     if (selection.some((current: MultiselectValue) => current?.id === item.id)) {return true;}
//     return false;
//   }

//   const searchInOptions = (value: MultiselectValue | null) => {
//     if(!value || value == null) { return }
//     let result = options.findIndex((obj: Map) => obj.id === value.id);

//     return options[result].name;
//   }


//   return (
//     <>
//       <div className="block z-10 w-full placeholder-gray-400 bg-transparent h-full items-center focus:bg-white focus:outline-none focus:ring-2 focus:ring-indigo-600 bg-white text-right">
//         <div className="relative">
//         <div
//             tabIndex={0}
//             className="flex flex-row items-center text-sm relative justify-between px-2"
//             style={{width: column.width + 'px'}}
//             role="button"
//             onKeyDown={() => setOpen(!open)}
//             onClick={() => setOpen(!open)}
//           >
//             <div className="truncate flex space-x-1">
//               { value.length ? 
//                 value.map((element: any) => {
//                   return <div className="flex h-5 py-0.5 rounded-full px-2 items-center truncate" style={{backgroundColor: `${hexToRGB(intToRGB(hashCode(element?.name!)), 0.4)}`}} key={element?.id}><span className='truncate text-sm'>{searchInOptions(element)}</span></div>
//                 }) 
//                 : <div className="flex h-5 py-0.5"></div>
//               }
//             </div>
//             <div className="my-2 pl-2 flex">
//               {open ? <HiChevronUp className = "w-4 h-5"/> : <HiChevronDown className = "w-4 h-5"/> }
//             </div>
//           </div>
//         </div>
//         {open && (
//           <ul className="absolute bg-white shadow rounded-sm min-w-min max-h-96 overflow-auto p-1" style={{width: column.width + 'px'}}>
//             {options.map((item: any) => (
//               <li className="flex w-full" key={item.id}>
//                 <div onClick={() => handleOnClick(item)} className="w-full flex py-1.5 text-sm justify-between pl-2 pr-2 items-center text-left hover:bg-gray-100">
//                   <span>{item.name}</span>
//                   {isItemInSelection(item) ? <HiCheck className="w-4 h-4 text-green-500"/> : ""}
//                 </div>
//               </li>
//             ))}
//           </ul>
//         )}
//       </div>
//     </>
//   )
// }