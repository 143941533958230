import { useState } from "react";
import NumberFormat, {NumberFormatValues} from 'react-number-format';
import { DecimalValue } from '../../../core/graphql/types';
import { CurrencyIcon } from '../../icons';
import type { FieldPrimitiveEditorProps } from './types';


export default function FieldCurrency({field, card, updatePrimitive}: FieldPrimitiveEditorProps) {
  const [value, setValue] = useState((card[field.id] as unknown as DecimalValue)?.valueAsDecimal! ? (card[field.id] as unknown as DecimalValue)?.valueAsDecimal! : "")
  const attr = field.attributes;

  function handleChange(e: NumberFormatValues) {
    updatePrimitive(
      { ...card, [field.id]: {...card[field.id], valueAsDecimal: e.floatValue}}, 
      ({...card[field.id], valueAsDecimal: e.floatValue} as unknown as DecimalValue), 
      {valueAsDecimal: e.floatValue}
    )
    setValue(e.floatValue)
  }


  return (
    <div className="relative flex flex-col space-y-1.5">
      <div className="flex items-center h-full">
        <div className="text-slate-400 pr-2"><CurrencyIcon /></div>
        <label className="text-slate-500 text-sm">{field.title}</label>
      </div>
      <NumberFormat
        autoFocus={false}
        allowNegative={attr.negative}
        prefix={attr.currency ? attr.currency + " " : ""}
        decimalScale={attr.percision}
        className="matr-form-input"
        displayType="input"
        value={value}
        onValueChange={(values) => handleChange(values)}
      />
    </div>
  );
}