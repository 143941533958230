import React, { FC, useContext } from "react";
import styled from "styled-components";
import { DocViewerContext } from "../state";
import { IStyledProps } from "../types";

export const FileName: FC<{}> = () => {
  const {
    state: { config, currentDocument },
  } = useContext(DocViewerContext);

  if (!currentDocument || config?.header?.disableFileName) return null;

  let fileName = currentDocument.uri || "";
  fileName = decodeURI(fileName);

  if (!config?.header?.retainURLParams) {
    fileName = fileName.split("?")[0];
  }

  const splitURL = fileName.split("/");
  if (splitURL.length) {
    fileName = splitURL[splitURL.length - 1];
  }

  return (
    <div className="flex flex-1 h-full text-sm text-left items-center text-slate-800 truncate p-4">
      {fileName}
    </div>
  );
};
