import { useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { BiToggleLeft, BiToggleRight } from "react-icons/bi";
import Select from "react-select";
import { Field } from "../../../../../core/graphql/types";
import { useBoard } from "../../../../../core/providers/board";
import { customStyles } from "../../../../../utils";
import { useSpaceStore } from "../../../../../core/store/store";

interface FieldUpdatedByProps {
  attributes: any;
  field: Field;
}

function FieldUpdatedBy({attributes, field}: FieldUpdatedByProps) {
  const {initId, initType} = useBoard();
  const init: string = `${initType}:${initId}`;
  const store = useSpaceStore()
  const {setEditMode} = store.boardActions
  const {fields} = store.data[init];

  const methods = useFormContext();
  const [options, setOptions] = useState<Field[]>([])

  console.log(attributes)
  
  const {register, unregister, watch, control, setValue} = methods;

  useEffect(() => {
    let opt = fields.filter((f: Field) => f.type !== "LOOKUP" && f.id !== field.id)
    setOptions(opt as Field[])
  },[])

  useEffect(() => {
    unregister('attributes');
  },[])

  useEffect(() => {
    register('attributes.type');
    setValue('attributes.type', "UPDATED_BY");
    setValue('attributes.byField', attributes.byField ? attributes.byField : false)
    setValue('attributes.updatedByFieldId', attributes.updatedByFieldId ? attributes.updatedByFieldId : "")
  },[attributes])

  return (
    <>
      <div className="relative inline-flex">
        {/* <label className="font-medium text-gray-600 text-sm flex items-center">
          <input 
            {...register("attributes.byField")}
            className="form-checkbox rounded-sm focus:outline-none focus:outline-offset-0 mr-2"
            type="checkbox" 
          />
          По конкртному полю
        </label> */}
        <label className="font-medium text-slate-800 text-sm flex items-center gap-2">
          <Controller
            name="attributes.byField"
            control={control}
            render={({ field: { onChange, value, ref }}) => (
              <div 
                className={`flex items-center  text-sm rounded-md font-normal justify-center cursor-default`} 
                onClick={() => onChange(!value)}
              >
                {value ? <BiToggleLeft className='h-6 w-6 text-green-600'/> : <BiToggleRight className='h-6 w-6 text-slate-400'/>}
              </div>
            )}
          />
          По конкртному полю
        </label>
      </div>
      {!watch('attributes.byField') ? "" : 
        <div className="relative">
          <label className="font-medium text-gray-600 text-sm">Выберите поле:</label>
          <Controller
            name="attributes.updatedByFieldId"
            control={control}
            render={({ field: { onChange, value, ref }}) => (
              <div className="mt-2">
                <Select
                  styles={customStyles}
                  classNamePrefix="matr_select_form"
                  placeholder="Выберите целевое поле"
                  isSearchable={false}
                  getOptionLabel ={(option)=>option.title}
                  getOptionValue ={(option)=>option.id}
                  defaultValue={options.find((field) => field?.primary === true)}
                  value={options.find((field: { id: string; }) => field.id === value)}
                  onChange={val => onChange(val?.id)}
                  options={options}
                />
              </div>
            )}
          />
        </div>
      }
    </>
  );
}
export default FieldUpdatedBy;