import { SetStateAction, useCallback, useEffect, useState } from "react";
import { debounce } from "debounce";
import { Controller, useFieldArray, useForm, useWatch } from 'react-hook-form';
import { MultiValue } from "react-select";
import useDeepCompareEffect from "use-deep-compare-effect";
import { SmallSpinner } from "../../../../../components/loaders";
import EditorWithMentionsFlow from "../../../../../components/ui/forms/editor_with_mentions_flow/EdtorWithMentions";
import MatrNarrowSelect from "../../../../../components/ui/select/narrow";
import { NodeField, NodeFieldContextEnum, NodeTypesEnum, Role, SpaceUser, useSpaceUsersQuery } from "../../../../../core/graphql/types";
import { useFlowContext } from "../../../../../core/providers/flow";
import { useSpace } from "../../../../../core/providers/space";
import { checkJson } from "../../../../../core/Controllers/Base";


function ActionCreateNotification() {
  const {node, nodes, edges, updateNode, updateNodeFieldValue} = useFlowContext()
  const {roles} = useSpace();
  const [spaceUsers, setSpaceUsers] = useState<SpaceUser[] | any>([])
  const [{ fetching, error, data}] = useSpaceUsersQuery({requestPolicy: 'network-only'});
  const internalFields = node.data?.fields?.filter((f: NodeField) => f.external === false).sort((a: any, b: any) => a.context - b.context)
  const externalFields = node.data?.fields?.filter((f: NodeField) => f.external === true).sort((a: any, b: any) => a.name - b.name)
  const methods = useForm({defaultValues: {
    internal_fields: internalFields,
    external_fields: externalFields,
  }})
  const { control, getValues, formState: {isDirty, errors}} = methods;
  const { fields: settingsFields } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "internal_fields", // unique name for your Field Array
  });
  const [fieldIndex, setFieldIndex] = useState<number | null>(null)
  const [sourceFields, setSourceFields] = useState<NodeField[]>([])
  const rolesIndex = internalFields?.findIndex((intf: { context: NodeFieldContextEnum; }) => intf.context === NodeFieldContextEnum.Roles)
  const [selectedRoles, setSelectedRoles] = useState<any[]>(getValues(`internal_fields.${rolesIndex}.value`) ? getValues(`internal_fields.${rolesIndex}.value`).split(",") : [])
  const usersIndex = internalFields?.findIndex((intf: { context: NodeFieldContextEnum; }) => intf.context === NodeFieldContextEnum.Users)
  const [selectedUsers, setSelectedUsers] = useState<any[]>(getValues(`internal_fields.${usersIndex}.value`) ? getValues(`internal_fields.${usersIndex}.value`).split(",") : [])
  const messageIndex = internalFields?.findIndex((intf: { context: NodeFieldContextEnum; }) => intf.context === NodeFieldContextEnum.JsonData)
  const [loadedTokens, setLoadedTokens] = useState(false)

  useEffect(() => {
    let parentNodeIds: string[] = edges.filter(edge => edge.target === node.id).map(edge => edge.source)
    let fields: any = []
    nodes.filter(n => parentNodeIds.includes(n.id)).map(node => fields = [...fields, ...node?.data?.fields.filter((f:NodeField) => f.external === true).map((f:NodeField) => ({id: f.id, name: f.name}))])
    setSourceFields(fields)
    setLoadedTokens(true)
  }, [edges, node])

  useEffect(() => {
    if(!data?.spaceUsers) {return}
    setSpaceUsers(data?.spaceUsers)
  }, [data?.spaceUsers])

  // Обновление

  const debouncedSaveName = useCallback(
    debounce((name: string) => {
      updateNode({type: NodeTypesEnum.Output, name: name})
    }, 1000),
    []
  );

  const debouncedSave = useCallback(
    debounce(() => {
      updateNodeFieldValue(getValues(`internal_fields.${fieldIndex}`))
      setFieldIndex(null)
    }, 1000),
    [fieldIndex]
  );

  const watchedData = useWatch({
    control: control,
    defaultValue: {internal_fields: internalFields}
  });

  useDeepCompareEffect(() => {
    if (isDirty) {
      debouncedSave()
    } else {
      debouncedSave.clear()
    }
  }, [watchedData]);

  
  function handleChangeRole(e: MultiValue<Role>, onChange: (...event: any[]) => void, index: SetStateAction<number | null>) {
    setFieldIndex(index)
    onChange(e.map(i => i.id).join(","))
    setSelectedRoles(e.map(i => i.id))
  }

  function handleChangeUser(e: MultiValue<SpaceUser>, onChange: (...event: any[]) => void, index: SetStateAction<number | null>) {
    setFieldIndex(index)
    onChange(e.map(i => i.id).join(","))
    setSelectedUsers(e.map(i => i.id))
  }

  function handleChangeJsonData(e: any, onChange: { (...event: any[]): void; (arg0: any): void; }, index: SetStateAction<number | null>) {
    setFieldIndex(index)
    onChange(JSON.stringify(e))
  }

  return (
    <div className="flex flex-col h-full">
      <div className="py-4 space-y-2">
        <div className="relative space-y-1.5">
          <label className="matr-label w-full">Роли, которые получат сообщение:</label>
          <Controller
              name={`internal_fields.${rolesIndex}.value`}
              control={control}
              rules={{required: true}}
              render={({ field: { onChange, value }}) => (
              <MatrNarrowSelect
                isSearchable={false}
                isMulti={true}
                getOptionLabel={option => option.name!}
                getOptionValue={option => option.id!}
                value={selectedRoles ? roles.filter(b => selectedRoles.includes(b.id)) : []}
                onChange={e => handleChangeRole(e, onChange, rolesIndex)}
                options={roles}
                placeholder="Выберите роль"
              />
            )}/>
        </div>
        <div className="relative space-y-1.5">
          <label className="matr-label w-full">Пользователи которые получат сообщения:</label>
          <Controller
              name={`internal_fields.${usersIndex}.value`}
              control={control}
              rules={{required: true}}
              render={({ field: { onChange, value }}) => (
              <MatrNarrowSelect
                isSearchable={false}
                isMulti={true}
                getOptionLabel={option => `${option.firstName!} ${option.lastName!} `}
                getOptionValue={option => option.id!}
                value={selectedUsers ? spaceUsers.filter((su: SpaceUser) => selectedUsers.includes(su.id)) : []}
                onChange={e => handleChangeUser(e, onChange, usersIndex)}
                options={spaceUsers}
                placeholder="Выберите пользователей"
              />
            )}/>
        </div>
        {loadedTokens ?
        <div className="relative space-y-1.5">
          <label className="matr-label w-full">Текст сообщения</label>
          <Controller
            name={`internal_fields.${messageIndex}.value`}
            control={control}
            render={({ field: { onChange, value, ref }}) => (
              <EditorWithMentionsFlow tokens={sourceFields} onChange={(e: any) => handleChangeJsonData(e, onChange, messageIndex)} value={checkJson(value) ? JSON.parse(value) : null}/>
            )}
          />
        </div>
        :
        <SmallSpinner />
        }
      </div>
    </div>
  );
}

export default ActionCreateNotification;