import React, {useState, useRef} from "react";
import { HiTrash, HiPlusCircle } from "react-icons/hi";
import { TbTrash, TbCopy, TbLayoutGridAdd, TbDots}  from "react-icons/tb"
import { useNavigate, useParams } from "react-router-dom";
import { Board } from "../../../../core/graphql/types";
import { SimpleModal } from "../../../modals/simple";
import { BoardFormDelete } from "../../../../containers/board/form/delete";
import moment from 'moment';
import _ from "lodash";
import { intToRGB, hashCode, hexToRGB} from "../../../../utils";
import { MatrBaseDialog, MatrPopover } from "../../../ui/portal";
import { ActionMenu } from "../../../Menus/ActionMenu";

export type BoardPreviewProps = {board: Board}

export const BoardPreview: React.FC<BoardPreviewProps> = ({board}) => {
  let params = useParams()
  const active = params?.board_id! === board.id ? true : false
  const [ openMenu, setOpenMenu ] = useState(false);
  const [ buttonRef, setButtonRef ] = useState<any>()
  let navigate = useNavigate();
  const [ openDeleteModal, setOpenDeleteModal ] = useState(false);

  const openBoard = () => {
    navigate(`/boards/${board.id}`);
  }

  function onOpenMenu(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    // e.stopPropagation();
    setOpenMenu(true)
  }

  function closeMenu() { 
    setOpenMenu(false)
  };

  function openModalDelete() {setOpenDeleteModal(true)};
  function closeModalDelete() {setOpenDeleteModal(false)};


  const menu = [
    {
      name: "Добавить запись",
      action: openModalDelete,
      icon: <TbLayoutGridAdd className="w-4 h-4"/>,
      disabled: true
    },
    {
      name: "Копировать",
      action: openModalDelete,
      icon: <TbCopy className="w-4 h-4"/>,
      disabled: true
    },
    {
      name: "Удалить",
      action: openModalDelete,
      icon: <TbTrash className="w-4 h-4"/>,
      disabled: false
    },
  ];


  return (
    <>
      <div className="flex w-full">
        <div className={`rounded-sm relative w-full cursor-pointer px-2 ${active ? "bg-indigo-600 text-white hover:bg-indigo-700 " : "text-slate-600 hover:bg-slate-200"}`}>
          <div className="relative flex items-center">
            <div className="w-full flex items-center" onClick={openBoard}>
              <div className="h-4 w-4 flex-none rounded-sm" style={{background: `${hexToRGB(intToRGB(hashCode(board.name)), 0.4)}`}}>
              </div>
              <span className="flex items-end px-2 py-1 text-base bg-opacity-0 hover:bg-opacity-100 text-left whitespace-pre-wrap w-full">{board.name}</span>
            </div>
            <button
              onClick={(e) => onOpenMenu(e)}
              ref={setButtonRef}
              className="h-5 w-5"
            >
              <TbDots />
            </button>
          </div>   
        </div>
      </div>
      {openMenu && 
        <MatrPopover onClose={closeMenu} reference={buttonRef} placement="bottom-end">
          <ActionMenu menu = {menu}/>
        </MatrPopover>
      }
      {openDeleteModal &&
        <MatrBaseDialog open = {openDeleteModal} onClose = {closeModalDelete}>
          <BoardFormDelete closeModal={closeModalDelete} name={board?.name} id={board?.id}/>
        </MatrBaseDialog>
      }
    </>
  );
};


export const BoardPreviewRow: React.FC<BoardPreviewProps> = ({board}) => {
  const [ open, setOpen ] = useState(false);
  let navigate = useNavigate();
  const [ openDeleteModal, setOpenDeleteModal ] = useState(false);

  const openBoard = () => {
    navigate(`/board/${board.id}`);
  }


  function openModal() { setOpen(true)};
  function closeModal() { 
    setOpen(false)
  };

  function openModalDelete() {setOpenDeleteModal(true)};
  function closeModalDelete() {setOpenDeleteModal(false)};

  function handleAddRecord(e: React.MouseEvent<SVGElement, MouseEvent>) {
    e.stopPropagation();
    openModal()
  }

  const menu = [
    {
      name: "Удалить",
      action: openModalDelete,
      icon: <HiTrash className="mr-2 w-3 h-3"/>,
      disabled: false
    },
  ];

  return (
    <>
    <div className="flex w-full bg-slate-50 rounded-md">
      <div className="rounded-sm relative w-full hover:bg-zinc-50 cursor-pointer">
        
        <div className="flex flex-col items-center">
          <div className="w-full flex flex-row justify-between items-center" onClick = {openBoard}>
            <span className="text-sm text-left truncate hover:text-indigo-700 w-full py-4 px-4">{board.name}</span>
            {/* <HiPlusCircle className="h-5 w-5 text-indigo-600" aria-hidden="true" onClick={(e) => handleAddRecord(e)}/> */}
            <div className="text-gray-400 w-8"><ActionMenu menu = {menu}/></div>
          </div>
        </div>   
      </div>
    </div>
    {openDeleteModal ? 
    <SimpleModal open = {openDeleteModal} close = {closeModalDelete}>
      <BoardFormDelete closeModal={closeModalDelete} name={board?.name} id={board?.id}/>
    </SimpleModal>
    : <></>}
    {/* {open ? <CardModal open={open} close={closeModal} boardId={board.id}/> : <></>} */}
    </>
  );
};