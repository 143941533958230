import Select, {GroupBase, Props, components} from "react-select";
import { IoCaretDown, IoCaretUp } from "react-icons/io5";
import { HiXCircle } from "react-icons/hi";

export const customStyles = {
  container: () => ({
    width: '100%'
  }),
  control: (provided: any, state: { isFocused: any; }) => ({
    ...provided,
    // background: '#fff',
    // borderColor: '#9e9e9e',
    border: 0,
    minHeight: '34px',
    height: '34px',
    boxShadow: state.isFocused ? null : null,
  }),

  // Нужно ститлизовать тэги

  // multiValue: (base: any) => ({
  //   ...base,
  //   backgroundColor: 'red',
  //   borderRadius: '6px',
  //   color: 'white',
  // }),

  // multiValueLabel: (base: any) => ({
  //   ...base,
  //   backgroundColor: 'red',
  //   borderTopLeftRadius: '6px',
  //   borderBottomLeftRadius: '6px',
  //   color: 'white',
  // }),

  valueContainer: (provided: any, state: any) => ({
    ...provided,
    height: '34px',
    padding: '0 6px'
  }),

  input: (provided: any, state: any) => ({
    ...provided,
    margin: '0px',
  }),
  indicatorSeparator: (state: any) => ({
    display: 'none',
  }),
  indicatorsContainer: (provided: any, state: any) => ({
    ...provided,
    height: '34px',
  }),
};

export default function MatrSelect<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>(props: Props<Option, IsMulti, Group>) {

  // @ts-ignore
  const DropdownIndicator = props => {
    return (
      <components.DropdownIndicator {...props}>
        {props.isFocused ? <IoCaretUp size={12} className="text-indigo-600"/> : <IoCaretDown size={12} className="text-slate-400 hover:text-indigo-600"/>}
      </components.DropdownIndicator>
    );
  };

  // @ts-ignore
  const ClearIndicator = props => {
    return (
      <components.ClearIndicator {...props}>
        {props.hasValue ? <HiXCircle size={16} className="text-slate-400"/> : <></>}
      </components.ClearIndicator>
    );
  };
  

  return (
    <Select 
      {...props} 
      styles={customStyles} 
      classNamePrefix="matr_select_form"
      components={{DropdownIndicator, ClearIndicator}}
      menuPlacement="auto"
    />
  );
}