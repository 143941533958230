import { BrowserRouter } from "react-router-dom";
import { Provider } from 'urql';
import { makeClient } from '../core/api';
import { AuthProvider } from "../core/providers/auth";
import { Routes as MatrRoutes} from "../routes";
import { Settings } from 'luxon';
import { useEffect, useMemo, useState } from "react";
import { getToken } from "../core/store/authStore";

function App() {
  let t = getToken()
  Settings.defaultLocale = "ru";
  Settings.defaultZone = "Europe/Moscow";

  const client = useMemo(() => {
    if (t === null) {
      return null;
    }

    return makeClient()
  }, [t]);

  if (!client) {
    return null;
  }

  return (
    <Provider value={client}>
      <BrowserRouter>
        <AuthProvider>
          <MatrRoutes />
        </AuthProvider>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
