import { Controller, useForm } from "react-hook-form";
import _ from "lodash"
import { DragDropContext, Draggable, DropResult, Droppable, ResponderProvided } from "react-beautiful-dnd";
import MatrSelect from "../../../components/ui/select/base";
import { BiToggleLeft, BiToggleRight } from "react-icons/bi";
import { Maybe, Page, Panel, PanelInList, Role, useChangePanelIndexPageMutation, useSpaceUsersForSelectQuery, useUpdatePagePositionMutation } from "../../../core/graphql/types";
import { MultiValue } from "react-select";
import { useEffect, useState } from "react";
import { ForSelect } from "../../../core/graphql/types";
import { TbDotsVertical, TbDragDrop, TbSquaresFilled, TbX } from "react-icons/tb";
import { Tab } from "@headlessui/react";
import { useSpaceStore } from "../../../core/store/store";
import { usePanel } from "../../../core/providers/panel";
import useDeepCompareEffect from "use-deep-compare-effect";

const reorder = (list: Maybe<Page>[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const PanelFormEdit = ({panel, updatePanel, close}:{panel: Panel, updatePanel: (data: any) => void; close: () => void}) => {
  const store = useSpaceStore()
  const {roles, space} = store
  const { panelsSetPanel, panelsUpdate } = store.panelActions
  const [pages, setPages] = useState<Page[] | any>([])
  const {control, handleSubmit, getValues} = useForm({defaultValues: panel!})
  const [users, setUsers] = useState<ForSelect[] | any[]>([])
  const [{data: spaceUsers}] = useSpaceUsersForSelectQuery()
  const [, updtaePosition] = useUpdatePagePositionMutation()
  const [, updateIndexPage] = useChangePanelIndexPageMutation()

  useEffect(() => {
    if(!spaceUsers || !spaceUsers?.spaceUsersForSelect) { return }
    setUsers(spaceUsers?.spaceUsersForSelect)
  },[spaceUsers?.spaceUsersForSelect])

  useDeepCompareEffect(() => {
    setPages(_.orderBy(panel.pages, ['position'], "asc"))
  }, [panel])

  function handleChangeCheckbox(value: any, onChange: { (...event: any[]): void; (arg0: any): void; }) {
    onChange(!value)
  }

  function handleChangeRoles(e: MultiValue<Role>, onChange: (...event: any[]) => void) {
    onChange(e.map(r => r.id))
  }

  function handleChangeEditors(e: MultiValue<any>, onChange: { (...event: any[]): void; (arg0: any): void; }) {
    onChange(e?.map((u: { value: any; }) => u.value))
  }

  function onDragEnd(result: DropResult, provided: ResponderProvided) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      pages,
      result.source.index,
      result.destination.index
    ).map((p, i) => ({...p, position: i}))

    updtaePosition({id: result.draggableId, position: result.destination.index}).then(res => {
      if (res.error) {return}
      panelsSetPanel({...panel, pages: items} as Panel)
      panelsUpdate({...panel, pages: items} as PanelInList)
    })
  }

  function handleChangeIndex(pageId: string) {
    updateIndexPage({panelId: panel?.id!, pageId: pageId})
    panelsSetPanel({...panel, pages: panel.pages?.map(p => p?.id === pageId ? {...p, index: true} : {...p, index: false})} as Panel)
    panelsUpdate({...panel, pages: panel.pages?.map(p => p?.id === pageId ? {...p, index: true} : {...p, index: false})} as PanelInList)
  }

  const onSubmit = (data: any, e: any) => {
    updatePanel(data)
    close()
  }
  const onError = (errors: any, e: any) => console.log(errors, e);

  return (
    <>
      <div className="flex items-center justify-between">
        <div className="flex items-center space-x-2">
          <div className="py-1 px-2 bg-slate-100 flex space-x-2 rounded">
            <TbSquaresFilled size={20} className="text-indigo-600"/>
            <div className="flex font-medium text-sm">{space?.name}</div>
          </div>
          <div className="flex font-normal text-sm">Настройка панели</div>
        </div>
        <button onClick={() => close()}><TbX size={18}/></button>
      </div>
      <form onSubmit={handleSubmit(onSubmit, onError)} className="h-full space-y-2">
        <div className="relative mt-2">
          <Controller 
            name="name"
            control={control}
            rules={{required: true}}
            render={({ field: { onChange, value }}) => (
              <div className="mt-1.5">
                <input
                  className="text-xl w-full font-medium text-slate-800 placeholder:text-slate-300 focus:outline-none mb-4" 
                  placeholder="Название панели"
                  value={value!}
                  onChange={onChange}
                />
              </div>
            )}
          />
        </div>
        <Tab.Group>
          <Tab.List className="text-base border-b">
            <Tab >{({ selected }) => (<div className={`px-3 py-1.5  font-medium ${selected ? "text-indigo-600 border-b-2 border-indigo-600" : "text-slate-600"} `}>Страницы</div>)}</Tab>
            <Tab >{({ selected }) => (<div className={`px-3 py-1.5  font-medium ${selected ? "text-indigo-600 border-b-2 border-indigo-600" : "text-slate-600"} `}>Доступы</div>)}</Tab>
          </Tab.List>
          <Tab.Panels>
          <Tab.Panel className="py-2 space-y-2 flex flex-col relative">
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(p, s) => (
                  <>
                  <div
                    {...p.droppableProps}
                    ref={p.innerRef}
                    className={`flex flex-col items-start  ${s.isDraggingOver ? "bg-blue-100" : ""}`}
                  >
                    {pages?.map((page: Page, idx: number) => (
                      <Draggable key={page?.id} draggableId={page?.id!} index={idx}>
                        {(provided, snapshot) => (
                          <div 
                            {...provided.draggableProps}
                            className={`
                              text-base py-1.5 rounded-sm hover:bg-slate-100 flex relative items-center justify-between w-full
                              ${snapshot.isDragging ? "bg-slate-200" : ""}
                            `}
                            style={{...provided.draggableProps.style, left: snapshot.isDragging ? 20 : 0}}
                            ref={provided.innerRef}
                            
                          >
                            <div className="flex items-center space-x-1">
                              <div {...provided.dragHandleProps}>
                                <TbDotsVertical className="mx-1"/>
                              </div>
                              <span>{page?.name}</span>
                            </div>
                            <div 
                              className={`flex items-center  rounded-md font-normal cursor-default mx-2`} 
                              onClick={() => handleChangeIndex(page?.id!)}
                            >
                              {page?.index === false ? <BiToggleLeft className='h-6 w-6 text-slate-400'/> : <BiToggleRight className='h-6 w-6 text-blue-600'/>}
                            </div>
                          </div>
                        )}
                      </Draggable>
                    ))}
                  </div>
                  {p.placeholder}
                  </>
                )}
              </Droppable>
            </DragDropContext>
          </Tab.Panel>
          <Tab.Panel className="py-2 space-y-2">
            <div className="relative flex flex-col w-full">
              <div className="flex items-center h-full space-x-2">
                <label className="font-medium text-slate-600 text-sm">Опубликовано:</label>
              </div>
              <div className="flex mt-2 w-full">
                <Controller
                  name="published"
                  control={control}
                  render={({ field: { onChange, value, ref }}) => (
                    <div 
                      className={`flex items-center py-1.5 px-4 text-sm ${value ? "bg-green-300 text-green-800" : "bg-slate-200 text-slate-600"} rounded-md font-normal justify-between w-full cursor-default`} 
                      onClick={() => handleChangeCheckbox(value, onChange)}
                    >
                      {value ? <BiToggleRight className='h-6 w-6 text-green-800'/> : <BiToggleLeft className='h-6 w-6 text-slate-400'/>}
                      {value ? "Да" : "Нет"}
                    </div>
                  )}
                />
              </div>
            </div>
            <div className="relative">
              <label className="font-medium text-gray-600 text-sm">Для ролей:</label>
              <Controller
                name="roles"
                control={control}
                rules={{required: true}}
                render={({ field: { onChange, value }}) => (
                  <div className="mt-1.5">
                    <MatrSelect
                      isMulti={true}
                      getOptionLabel={(option) => option?.name!}
                      getOptionValue ={(option)=> option?.id!}
                      value={value && value.length > 0 ? roles.filter(r => value.includes(r?.id!)) : []}
                      onChange={(e) => handleChangeRoles(e, onChange)}
                      options={roles}
                    />
                  </div>
                )}
              />
            </div>
            <div className="relative">
              <label className="font-medium text-gray-600 text-sm">Редакторы:</label>
              <Controller
                name="editors"
                control={control}
                rules={{required: true}}
                render={({ field: { onChange, value }}) => (
                  <div className="mt-1.5">
                    <MatrSelect
                      isMulti={true}
                      value={value && value.length > 0 ? users.filter((u: ForSelect) => value.includes(u?.id!)) : []}
                      getOptionLabel ={(option)=>option?.name!}
                      getOptionValue ={(option)=>option?.id!}
                      onChange={(e) => handleChangeEditors(e, onChange)}
                      options={users}
                    />
                  </div>
                )}
              />
            </div>
          </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>

        <div className="flex w-full justify-end pt-4">
          <button className={`px-4 py-1.5 rounded-md text-sm font-medium bg-indigo-600 text-white`}>Обновить</button>
        </div>
      </form>
    </>
  )
}

export default PanelFormEdit;