import { BlockTypeEnum } from "../../../../../core/graphql/types";
import { usePanel } from "../../../../../core/providers/panel";
import FormBlock, { FormBlockOnly } from "../../blocks/BlockForm";

export default function LayoutForm() {
  const {page, editorMode, changeCurrentBlock} = usePanel()
  const pageBlock = page.blocks?.find(b => b?.isPage === true && b.type === BlockTypeEnum.BlockForm)
  return (
    pageBlock! && <div className={`h-full w-full overflow-scroll`} onClick={(event) => changeCurrentBlock(event, null)}>
      {/* <div className={`flex-none h-20 text-xl w-full text-slate-800 font-semibold ${editorMode ? "px-2" : "px-0"} pt-8 pb-4 max-w-[900px] mx-auto`}>{page.name}</div> */}
      <FormBlockOnly block={pageBlock!} card={null}/>
    </div>
  );
}