import { useEffect, useCallback, useState, ChangeEvent } from "react";

import { Controller, useForm, useFormContext, useWatch } from 'react-hook-form';
import { useSpace } from "../../../../../core/providers/space";
import { Board, Field, Node, NodeField, NodeFieldFieldTypeEnum, NodeOutput } from "../../../../../core/graphql/types";
import { SingleValue } from "react-select";
import MatrNarrowSelect from "../../../../../components/ui/select/narrow";
import * as Yup from 'yup';
import { debounce } from "debounce";
import useDeepCompareEffect from "use-deep-compare-effect";
import { useFlowContext } from "../../../../../core/providers/flow";
import { Disclosure } from "@headlessui/react";
import { TbChevronUp, TbInfoSquare, TbSquareCheck } from "react-icons/tb";
import { BiToggleLeft, BiToggleRight } from "react-icons/bi";
import NodeFields from "../nodeField/NodeFields";
import _ from "lodash";



function ActionCreateCard() {
  const {boards} = useSpace();
  const {node, nodes, edges, updateNode} = useFlowContext()
  const block = (node.data as Node).options as NodeOutput
  const [nodeFields, setNodeFields] = useState<NodeField[]>([])
  const { control, getValues, watch, formState: {isDirty, errors}} = useFormContext()
  const [boardFields, setBoardFields] = useState<Field[]>([])
  const [error, setError] = useState(null);
  const [sourceFields, setSourceFields] = useState<NodeField[]>([])

  useEffect(() => {
    setBoardFields(_.sortBy((boards.find(b => b?.id === node.data.options.boardId)?.fields as Field[]), ['title']))
  },[boards, node.data.options.boardId])

  useEffect(() => {
    setNodeFields(_.sortBy(node.data?.fields?.filter((f: NodeField) => f.external === true), ['name']))
  },[node.data?.fields])

  useEffect(() => {
    let parentNodeIds: string[] = edges.filter(edge => edge.target === node.id).map(edge => edge.source)
    let fields: any = []
    nodes.filter(n => parentNodeIds.includes(n.id)).map(node => fields = [...fields, ...node?.data?.fields.filter((f:NodeField) => f.external === true).map((f:NodeField) => ({id: f.id, name: f.name, fieldType: f.fieldType}))])
    setSourceFields(fields)
  }, [edges, node])


  function handleChangeBoard(e: SingleValue<Board>, onChange: { (...event: any[]): void; (arg0: any): void; }) {
    setBoardFields(boards.find(b => b.id === e?.id)?.fields as Field[])
    onChange(e?.id)
  }

  function handleChangeBoardFieldId(e: SingleValue<Field>, onChange: (...event: any[]) => void) {
    onChange(e?.id)
  }


  function handleChangeNodeFieldId(e: SingleValue<NodeField>, onChange: (...event: any[]) => void) {
    onChange(e?.id)
  }

  function handleChangeCheckbox(value: any, onChange: { (...event: any[]): void; (arg0: any): void; }) {
    onChange(!value)
  }
  
  function handleChangeNodeName(e: ChangeEvent<HTMLInputElement>, onChange: (...event: any[]) => void) {
    onChange(e)
  }


  return (
    <div className="flex flex-col space-y-2 py-4 h-full">
        <div className="relative">
          <label className="matr-label w-full">Поле в таблице:</label>
          <Controller
            name="boardId"
            control={control}
            rules={{required: true}}
            render={({ field: { onChange, value }}) => (
              <div className="mt-1.5">
                <MatrNarrowSelect
                  getOptionLabel={(option) => option?.name!}
                  getOptionValue ={(option)=> option?.id!}
                  value={value && value.length > 0 ? boards?.find(r => value.includes(r.id)) : []}
                  onChange={(e) => handleChangeBoard(e, onChange)}
                  options={boards}
                />
              </div>
            )}
          />
          {errors.boardId && <span className="text-red-600 text-xs font-normal">"Доска" обязательное поле.</span>}
        </div>

        {watch('boardId') && 
        <>
          <div className="relaitve">
            <div className="flex justify-between items-center">
              <label className="matr-label w-full">Обновлять по условию:</label>
              <Controller
                name="updateByCondition"
                control={control}
                render={({ field: { onChange, value, ref }}) => (
                  <div 
                    className={`flex items-center  rounded-md font-normal  cursor-default`} 
                    onClick={() => handleChangeCheckbox(value, onChange)}
                  >
                    {value! ? <BiToggleRight className='h-6 w-6 text-blue-600'/> : <BiToggleLeft className='h-6 w-6 text-slate-400'/>}
                  </div>
                )}
              />
            </div>
          </div>
          {watch('updateByCondition') === true ? 
            <>
              <div className="relative">
                <label className="matr-label w-full">Поле в таблице:</label>
                <Controller
                  name="updateByFieldId"
                  control={control}
                  rules={{required: true}}
                  render={({ field: { onChange, value }}) => (
                    <div className="mt-1.5">
                      <MatrNarrowSelect
                        getOptionLabel={(option) => option?.title!}
                        getOptionValue ={(option)=> option?.id!}
                        value={value && boardFields.length > 0 ? boardFields?.find(b => value === b.id) : null}
                        onChange={(e) => handleChangeBoardFieldId(e, onChange)}
                        options={boardFields}
                      />
                    </div>
                  )}
                />
              </div>
              <div className="relative mt-2">
                <label className="matr-label w-full">Занчение:</label>
                <Controller
                  name="nodeFieldId"
                  control={control}
                  rules={{required: true}}
                  render={({ field: { onChange, value }}) => (
                    <div className="mt-1.5">
                      <MatrNarrowSelect
                        getOptionLabel={(option) => option?.name!}
                        getOptionValue ={(option)=> option?.id!}
                        value={value && value.length > 0 ? sourceFields?.find(r => value.includes(r?.id!)) : []}
                        onChange={(e) => handleChangeNodeFieldId(e, onChange)}
                        options={sourceFields.filter(f => f.fieldType !== NodeFieldFieldTypeEnum.MattrId)}
                      />
                    </div>
                  )}
                />
              </div>
            </>
          :
            <div className="relative">
              <label className="matr-label w-full">Идентификатор записи:</label>
              <Controller
                name="nodeFieldId"
                control={control}
                rules={{required: true}}
                render={({ field: { onChange, value }}) => (
                  <div className="mt-1.5">
                    <MatrNarrowSelect
                      getOptionLabel={(option) => option?.name!}
                      getOptionValue ={(option)=> option?.id!}
                      value={value && value.length > 0 ? sourceFields?.find(r => value.includes(r?.id!)) : []}
                      onChange={(e) => handleChangeNodeFieldId(e, onChange)}
                      options={sourceFields.filter(f => f.fieldType === NodeFieldFieldTypeEnum.MattrId)}
                    />
                  </div>
                )}
              />
              {errors.boardId && <span className="text-red-600 text-xs font-normal">"Доска" обязательное поле.</span>}
            </div>
          }
          <div className="relaitve mt-2">
            <div className="flex justify-between items-center">
              <label className="matr-label w-full">Создать, если не найдено:</label>
              <Controller
                name="addIfNotFound"
                control={control}
                render={({ field: { onChange, value, ref }}) => (
                  <div 
                    className={`flex items-center  rounded-md font-normal  cursor-default`} 
                    onClick={() => handleChangeCheckbox(value, onChange)}
                  >
                    {value! ? <BiToggleRight className='h-6 w-6 text-blue-600'/> : <BiToggleLeft className='h-6 w-6 text-slate-400'/>}
                  </div>
                )}
              />
            </div>
          </div>


          {nodeFields.length > 0 && 
            <div className="relative">
              {nodeFields.length > 0 ? <NodeFields nodeFields={nodeFields} /> : <></>}
            </div>
          }
        </> 
        }
    </div>
  );
}

export default ActionCreateCard;