import { useState } from "react";
import type { FieldPrimitiveEditorProps } from './types';
import { BooleanValue} from "../../../core/graphql/types"
import { CheckboxIcon } from "../../icons";
import { Switch } from "@headlessui/react";


export default function FieldCheckbox({field, card, updatePrimitive}: FieldPrimitiveEditorProps) {
  const [value, setValue] = useState((card[field.id] as unknown as BooleanValue)?.valueAsBool!)

  function handleChange(e: boolean) {
    updatePrimitive(
      { ...card, [field.id]: {...card[field.id], valueAsBool: e}}, 
      ({...card[field.id], valueAsBool: e} as unknown as BooleanValue), 
      {valueAsBool: e}
    )
    setValue(e)
  }

  return (
    <div className="relative flex flex-row justify-between items-center py-1.5 border-b">
      <div className="flex items-center h-full">
        <div className="text-slate-400 pr-2"><CheckboxIcon /></div>
        <label className="text-slate-500 text-sm">{field.title}</label>
      </div>
      <Switch
        checked={value}
        onChange={(e) => handleChange(e)}
        className={`${
          value ? 'bg-blue-600' : 'bg-gray-200'
        } relative inline-flex h-4 w-7 items-center rounded-full`}
      >
        <span className="sr-only">Включить</span>
        <span
          className={`${
            value ? 'translate-x-3.5' : 'translate-x-0.5'
          } inline-block h-3 w-3 transform rounded-full bg-white transition`}
        />
      </Switch>
    </div>
  );
}