import React, {useEffect, useState} from "react";
import {BigSpinner} from "../../../components/loaders/index";
import { useAuth } from "../auth";
import { Edge, EdgeStatusEnum, Flow, MattrTypes, Node, NodeOutputTypesEnum, NodeTypesEnum, useChangeNodePositionMutation, useConnectNodesMutation, useCreateFlowMutation, useCreateNodeFieldMutation, useCreateNodeMutation, useCreateOutputNodeMutation, useDeleteNodeFieldMutation, useDeleteNodeMutation, useDisconnectNodesMutation, useGetFlowWithDataQuery, useListOfFlowsQuery, useOnoffFlowMutation, useRemoveFlowMutation, useUpdateNodeFieldMutation, useUpdateNodeFieldValueMutation, useUpdateNodeMutation } from "../../graphql/types";
import toast from "react-hot-toast";
import ReactFlow, {
  ReactFlowProvider,
  useNodesState,
  useEdgesState,
  Position,
  Node as ReactFlowNode,
  Edge as ReactFlowEdge,
  OnNodesChange,
  OnEdgesChange,
  XYPosition
} from 'reactflow';
import { useParams } from "react-router-dom";

interface FlowContextType {
  flow: Flow | any;
  nodes: ReactFlowNode[],
  onNodesChange: OnNodesChange,
  edges: ReactFlowEdge[],
  onEdgesChange: OnEdgesChange,
  node: ReactFlowNode,
  chooseNode: (id: string | null) => void;
  createNode: (type: NodeTypesEnum) => void;
  createOutputNode: (type: NodeTypesEnum, outputType: NodeOutputTypesEnum) => void;
  updateNode: (options: any) => void;
  deleteNode: (id: string) => void;
  changeNodePosition: (node: ReactFlowNode) => void;
  createNodeField: (id: string) => void;
  updateNodeField: (data: any) => void;
  deleteNodeField: (id: string) => void;
  updateNodeFieldValue: (data: {id: string, value: string | null, valueType: MattrTypes, nodeFieldId: string | null, active: boolean}) => void;
  connectNodes: (target: string, source: string, status: EdgeStatusEnum) => void;
  disconnectNodes: (id: string) => void;
  startStopFlow: () => void;
}

let FlowContext = React.createContext<FlowContextType>(null!);

export function FlowProvider({ children }: { children: React.ReactNode }) {
  const { flow_id } = useParams();
  const [ flow, setFlow ] = useState<Flow>()
  const [ nodes, setNodes, onNodesChange] = useNodesState([])
  const [ edges, setEdges, onEdgesChange] = useEdgesState([])
  const [ node, setNode] = useState<Node | any>(null);
  const [ loading, setLoading] = useState<boolean>(true)
  const [{fetching: fetchingFlow, data: flowData, error: flowError}, getFlow] = useGetFlowWithDataQuery({variables: {id: flow_id!}});
  const [, switchFlow] = useOnoffFlowMutation()

  const [{}, createNodeMutation] = useCreateNodeMutation()
  const [{}, createOutputNodeMutation] = useCreateOutputNodeMutation()
  const [{}, updateNodeMutation] = useUpdateNodeMutation()
  const [{}, changePosition] = useChangeNodePositionMutation()
  const [{}, deleteNodeMutation] = useDeleteNodeMutation()

  const [{}, createNodeFieldMut] = useCreateNodeFieldMutation()
  const [{}, updateNodeFieldMut] = useUpdateNodeFieldMutation()
  const [{}, deleteNodeFieldMut] = useDeleteNodeFieldMutation()

  const [{}, updateValue] = useUpdateNodeFieldValueMutation()

  const [{}, connectNodesMut] = useConnectNodesMutation()
  const [{}, disconnectNodesMut] = useDisconnectNodesMutation()

  useEffect(() => {
    if(!flowData) {return}
    let f = flowData.getFlowWithData!
    setFlow(f)

    setNodes(generateRFNodesFromNodes(f?.nodes! as Node[]))
    setEdges(generateRFEdgesFromEdges(f?.edges as Edge[], f?.active!))
    setLoading(false)

  },[flowData])

  function generateRFNodesFromNodes(nodes: Node[]) {
    let listOfNodes: ReactFlowNode[] = []

    nodes!.map((node: Node) => {
      listOfNodes.push({
        id: node?.id!,
        type: node?.type!,
        draggable: true,
        sourcePosition: !!node?.hasInput ? Position.Right : undefined,
        targetPosition: !!node?.hasOutput ? Position.Left : undefined,
        data: node,
        position: node?.position?.x! && node?.position?.y! ? node?.position as XYPosition : {x: 200, y: 200} as XYPosition,
        className: 'react-flow__node-default',
      })
    })

    return listOfNodes
  }

  function generateRFEdgesFromEdges(edges: Edge[], active: boolean) {
    let listOfEdges: ReactFlowEdge[] = []
    edges!.map((edge: Edge) => {
      listOfEdges.push({
        id: edge?.id!, 
        source: edge?.sourceNodeId!, 
        target: edge?.targetNodeId!, 
        sourceHandle: edge?.status,
        type: 'edgewithdelete', 
        animated: active!
      })
    })
    return listOfEdges
  }

  function createNode(type: NodeTypesEnum) {
    toast.promise(
      createNodeMutation({flowId: flow?.id!, type: type}).then(res => {
        if(res.error) {return res}
        setNodes(generateRFNodesFromNodes(res.data?.createNode!.nodes as Node[]))
        setEdges(generateRFEdgesFromEdges(res.data?.createNode!?.edges as Edge[], false))
        stopFlowOnChanges()
        setFlow({...res.data?.createNode!, active: false})
        return res
      }),
      {
        loading: 'Добавляем блок...',
        success: (data) => {
          if (data.error) throw new Error();
          return 'Блок добавлен';
        },
        error: 'Не удалось создать блок',
      }
    );
  } 

  function createOutputNode(type: NodeTypesEnum, outputType: NodeOutputTypesEnum) {
    toast.promise(
      createOutputNodeMutation({flowId: flow?.id!, type: type, outputType: outputType}).then(res => {
        if(res.error) {return res}
        
        setNodes(generateRFNodesFromNodes(res.data?.createOutputNode!.nodes as Node[]))
        setEdges(generateRFEdgesFromEdges(res.data?.createOutputNode!?.edges as Edge[], false))
        stopFlowOnChanges()
        setFlow({...res.data?.createOutputNode!, active: false})
        return res
      }),
      {
        loading: 'Добавляем блок...',
        success: (data) => {
          if (data.error) throw new Error();
          return 'Блок добавлен';
        },
        error: 'Не удалось создать блок',
      }
    );
  }

  function chooseNode(id: string | null) {
    if(node?.id === id || !id) {
      setNode(null)
    } else {
      setNode(nodes?.find(n => n.id === id))
    }
  }

  function updateNode(options: any) {
    toast.promise(
      updateNodeMutation({id: node.id, options: options}).then(res => {
        if(res.error) {return res}
        setFlow({...res.data?.updateNode!, active: false})
        let flowNodes = generateRFNodesFromNodes(res.data?.updateNode!.nodes as Node[])
        setNodes(flowNodes)
        setNode(flowNodes.find((n: ReactFlowNode) => n?.id! === node?.id!) as ReactFlowNode)
        setEdges(generateRFEdgesFromEdges(res.data?.updateNode!?.edges as Edge[], false))
        stopFlowOnChanges()
        setFlow({...res.data?.updateNode!, active: false})
        return res
      }),
      {
        loading: 'Обновляем блок...',
        success: (data) => {
          if (data.error) throw new Error();
          return 'Блок обновлен';
        },
        error: 'Не удалось обновить блок',
      }
    );
    
  }

  function deleteNode(id: string) {
    if(id === node.id) {setNode(null)}
    toast.promise(
      deleteNodeMutation({id: id}).then(res => {
        if(res.error) {return res}
       
        setNodes(generateRFNodesFromNodes(res.data?.deleteNode!.nodes as Node[]))
        setNode(null)
        setEdges(generateRFEdgesFromEdges(res.data?.deleteNode!?.edges as Edge[], false))
        stopFlowOnChanges()
        setFlow({...res.data?.deleteNode!, active: false})
        return res
      }),
      {
        loading: 'Удаляем блок...',
        success: (data) => {
          if (data.error) throw new Error();
          return 'Блок удален';
        },
        error: 'Не удалось удалить блок',
      }
    );
  }

  function changeNodePosition(node: ReactFlowNode) {
    toast.promise(
      changePosition({id: node.data.id, x: Math.round(node.position.x), y: Math.round(node.position.y)}).then(res => {
        if(res.error) {return res}

        let objIndex = flow?.nodes?.findIndex(obj => obj?.id === res.data?.changeNodePosition?.id);
        let flowNodes = flow?.nodes!
        setFlow({...flow, nodes: [...flowNodes.slice(0, objIndex!), res.data?.changeNodePosition, ...flowNodes.slice(objIndex! + 1)] as Node[]})
        setNodes(generateRFNodesFromNodes([...flowNodes.slice(0, objIndex!), res.data?.changeNodePosition, ...flowNodes.slice(objIndex! + 1)] as Node[]))
        return res
      }),
      {
        loading: 'Обновляем процесс...',
        success: (data) => {
          if (data.error) throw new Error();
          return 'Процесс обнавлен';
        },
        error: 'Процесс не удалось обновить',
      }
    );
  }

  function createNodeField(id: string) {
    toast.promise(
      createNodeFieldMut({nodeId: id}).then(res => {
        if(res.error) {return res}

        setFlow(res.data?.createNodeField!)

        let updatedNode: Node = res.data?.createNodeField!.nodes?.find((n: Node | any) => n.id === node.id)!

        let newBlock = {
          id: updatedNode.id!,
          type: updatedNode.type!,
          draggable: true,
          sourcePosition: updatedNode.hasInput ? Position.Right : null,
          targetPosition: updatedNode.hasOutput ? Position.Left : null,
          data: updatedNode,
          position: updatedNode.position?.x && updatedNode.position?.y ? updatedNode.position : {x: 200, y: 200},
          className: 'react-flow__node-default',
        }

        setNode(newBlock)

        return res
      }),
      {
        loading: 'Обновляем блок...',
        success: (data) => {
          if (data.error) throw new Error();
          return 'Блок обновлен';
        },
        error: 'Не удалось обновить блок',
      }
    );
  }

  function updateNodeField(data: any) {
    if(!data?.id) {return}
    toast.promise(
      updateNodeFieldMut({id: data.id, name: data.name, value: data.value, valueType: data.valueType, nodeFieldId: data.nodeFieldId}).then(res => {
        if(res.error) {return res}

        setFlow(res.data?.updateNodeField!)

        let updatedNode: Node = res.data?.updateNodeField!.nodes?.find((n: Node | any) => n.id === node.id)!

        let newBlock = {
          id: updatedNode.id!,
          type: updatedNode.type!,
          draggable: true,
          sourcePosition: !!updatedNode.hasInput ? Position.Right : null,
          targetPosition: !!updatedNode.hasOutput ? Position.Left : null,
          data: res.data?.updateNodeField,
          position: updatedNode.position?.x && updatedNode.position?.y ? updatedNode.position : {x: 200, y: 200},
          className: 'react-flow__node-default',
        }

        setNode(newBlock)

        // let objIndex = nodes.findIndex(obj => obj.id === res.data?.updateNodeField?.id);
        // let newBlock = {...nodes[objIndex], data: res.data?.updateNodeField}
        // setNodes([...nodes.slice(0, objIndex), newBlock, ...nodes.slice(objIndex + 1)] as any)
        // setNode(newBlock)
        return res
      }),
      {
        loading: 'Обновляем блок...',
        success: (data) => {
          if (data.error) throw new Error();
          return 'Блок обновлен';
        },
        error: 'Не удалось обновить блок',
      }
    );
  }

  function deleteNodeField(id: string) {
    toast.promise(
      deleteNodeFieldMut({id: id}).then(res => {
        if(res.error) {return res}

        let objIndex = flow?.nodes?.findIndex(obj => obj?.id === node?.id);
        let flowNodes = flow?.nodes!
        let newNodeFields = (flowNodes[objIndex!]!).fields?.filter(f => f?.id !== id)

        setFlow({...flow, nodes: [...flowNodes.slice(0, objIndex!), {...flowNodes[objIndex!], fields: newNodeFields}, ...flowNodes.slice(objIndex! + 1)] as Node[]})

        let newBlock = {
          id: flowNodes[objIndex!]!.id!,
          type: flowNodes[objIndex!]!.type!,
          draggable: true,
          sourcePosition: !!flowNodes[objIndex!]!.hasInput ? Position.Right : null,
          targetPosition: !!flowNodes[objIndex!]!.hasOutput ? Position.Left : null,
          data: {...flowNodes[objIndex!], fields: newNodeFields},
          position: flowNodes[objIndex!]!.position,
          className: 'react-flow__node-default',
        }

        setNode(newBlock)

        return res
      }),
      {
        loading: 'Обновляем блок...',
        success: (data) => {
          if (data.error) throw new Error();
          setLoading(true)
          return 'Блок обновлен';
        },
        error: 'Не удалось обновить блок',
      }
    );
  }

  function updateNodeFieldValue(data: {id: string, value: string | null, valueType: MattrTypes, nodeFieldId: string | null, active: boolean}) {
    if(!data?.id) {return}
    toast.promise(
      updateValue({id: data.id, value: data.value, nodeFieldId: data.nodeFieldId, active: data.active, valueType: data.valueType!}).then(res => {
        if(res.error) {return res}

        let flowNodes = generateRFNodesFromNodes(res.data?.updateNodeFieldValue!.nodes as Node[])
        let flowEdges = generateRFEdgesFromEdges(res.data?.updateNodeFieldValue!.edges as Edge[], res.data?.updateNodeFieldValue!.active!)
        setNodes(flowNodes)
        setEdges(flowEdges)
        stopFlowOnChanges()
        setFlow({...res.data?.updateNodeFieldValue!, active: false})
        setNode(flowNodes.find((n: ReactFlowNode) => n?.id! === node?.id!) as ReactFlowNode)

        return res
      }),
      {
        loading: 'Обновляем блок...',
        success: (data) => {
          if (data.error) throw new Error();
          return 'Блок обновлен';
        },
        error: 'Не удалось обновить блок',
      }
    );
  }

  function connectNodes(target: string, source: string, status: EdgeStatusEnum) {
    if(!target || !source || !status) {return}
    toast.promise(
      connectNodesMut({targetNodeId: target, sourceNodeId: source, status: status}).then(res => {
        if(res.error) {return res}
        setNodes(generateRFNodesFromNodes(res.data?.connectNodes!.nodes as Node[]))
        setEdges(generateRFEdgesFromEdges(res.data?.connectNodes?.edges as Edge[], false))
        stopFlowOnChanges()
        setFlow({...res.data?.connectNodes!, active: false})
        return res
      }),
      {
        loading: 'Связываем блоки...',
        success: (data) => {
          if (data.error) throw new Error();
          return 'Блоки связаны';
        },
        error: 'Не удалось связатъ блоки',
      }
    );
  }

  function disconnectNodes(id: string) {
    if(!id) {return}
    toast.promise(
      disconnectNodesMut({id: id}).then(res => {
        if(res.error) {return res}
        setNodes(generateRFNodesFromNodes(res.data?.disconnectNodes!.nodes as Node[]))
        setEdges(generateRFEdgesFromEdges(res.data?.disconnectNodes?.edges as Edge[], false))
        stopFlowOnChanges()
        setFlow({...res.data?.disconnectNodes!, active: false})
        return res
      }),
      {
        loading: 'Разрываем связь блоков...',
        success: (data) => {
          if (data.error) throw new Error();
          return 'Связь разорвана';
        },
        error: 'Не удалось разорвать блоки',
      }
    );
  }

  function startStopFlow() {
    if(!flow?.id) {return}

    toast.promise(
      switchFlow({id: flow.id}).then(res => {
        if(res.error) {return res}
        setEdges(generateRFEdgesFromEdges(flow.edges as Edge[], res.data?.onoffFlow?.active!))
        setFlow({...flow, ...res.data?.onoffFlow})
        return res
      }),
      {
        loading: flow?.active ? 'Останавливаем процесс' : 'Запускаем процесс',
        success: (data) => {
          if (data.error) throw new Error();
          return data.data?.onoffFlow?.active ? 'Процесс запущен' : 'Процесс остановлен';
        },
        error: 'Ошибка',
      }
    );
  }

  function stopFlowOnChanges() {
    if(!flow?.id || flow?.active === false) {return}
    if(flow?.active === true) {
      toast.promise(
        switchFlow({id: flow.id}).then(res => {
          if(res.error) {return res}
          setEdges(generateRFEdgesFromEdges(flow.edges as Edge[], res.data?.onoffFlow?.active!))
          setFlow({...flow, ...res.data?.onoffFlow})
          return res
        }),
        {
          loading: 'Останавливаем процесс',
          success: (data) => {
            if (data.error) throw new Error();
            return 'Процесс остановлен';
          },
          error: 'Ошибка',
        }
      );
    } 
  }


  let value = {
    flow,
    nodes, 
    onNodesChange, 
    edges, 
    onEdgesChange, 
    node, 
    chooseNode, 
    createNode,
    createOutputNode,
    updateNode,
    deleteNode, 
    changeNodePosition,
    createNodeField,
    updateNodeField,
    deleteNodeField,
    updateNodeFieldValue,
    connectNodes,
    disconnectNodes,
    startStopFlow
  };

  return (
    loading ? <div>Выберите или создайте процесс</div> : <ReactFlowProvider><FlowContext.Provider value={value}>{children}</FlowContext.Provider></ReactFlowProvider>
  )
}

export function useFlowContext() {return React.useContext(FlowContext);}