import useDeepCompareEffect from "use-deep-compare-effect";
import { Block, BlockFormOptions, BlockFormTypeEnum, BlockSourceBlock, Board, CardWithValues, ViewType } from "../../../../../core/graphql/types";
import FormBlockCreate from "./create/create";
import FormBlockEdit from "./edit/edit";
import { useSpaceStore, dataInitialState, DataStore } from "../../../../../core/store/store";
import { MattrDataProvider } from "../../../../../core/providers/data";
import { BlockSubscriptionProvider } from "../../../../../core/providers/subscriptions/block";
import { SmallSpinner } from "../../../../../components/loaders";
import { useState } from "react";


function FormBlock({block}:{block: Block}) {
  const [loading, setLoading] = useState(true)
  const storeData = useSpaceStore().data
  const {addBoardData, resetDataItem} = useSpaceStore().boardActions
  const init = `${"block"}:${block.id}`
  const parentInit = `${"block"}:${block.parentId}`
  const {currentCardId, currentCardPrimaryKey} = storeData[parentInit]

  useDeepCompareEffect(() => {
    if(init in storeData) {
      resetDataItem(init)
      setLoading(true)
    } 

    let newObject: Record<string, DataStore> = {}
    newObject[init] = {
      ...dataInitialState,
      block: block,
      board: {id: (block.source as BlockSourceBlock).boardId} as Board,
      currentView: {
        type: ViewType.Grid, 
        filters: (block?.source as any)?.filters, 
        hiddenFields: (block?.source as any)?.hiddenFields,
        permissions: block.permissions!, 
        options: {}
      },
      currentCardId: currentCardId,
      currentCardPrimaryKey: currentCardPrimaryKey,
      relationCardId: currentCardId,
      relationPrimaryKey: currentCardPrimaryKey,
      inBlock: true,
      showModalAs: "modal",
      initId: block.id!, 
      initType: "block", 
      boardIsLoading: false
    }

    addBoardData(newObject)
    setLoading(false)
  },[block, currentCardId])


  return (
    <>
      {!loading && (init in storeData) ? 
        <>
          <MattrDataProvider initiator={{id: block.id!, type: "block"}}>
            <BlockSubscriptionProvider blockId={block.id!} boardId={(block.source as  BlockSourceBlock).boardId!}>
              {(block.options as BlockFormOptions).formType === BlockFormTypeEnum.Create ? <FormBlockCreate formBlock={block}/> : <FormBlockEdit formBlock={block}/>}
            </BlockSubscriptionProvider>
          </MattrDataProvider>
        </>
        :
        <SmallSpinner />
      }
    </>
  )
}

export default FormBlock;


export function FormBlockOnly({block, card}:{block: Block, card: CardWithValues | null}) {
  const storeData = useSpaceStore().data
  const {addBoardData, resetDataItem} = useSpaceStore().boardActions
  const init = `${"block"}:${block.id}`
  const parentInit = `${"block"}:${block.parentId}`
  

  useDeepCompareEffect(() => {
    resetDataItem(init)
    let newObject: Record<string, DataStore> = {}
    newObject[init] = {
      ...dataInitialState,
      block: block,
      board: {id: (block.source as BlockSourceBlock).boardId} as Board,
      currentView: {
        type: ViewType.Grid, 
        filters: (block?.source as any)?.filters, 
        hiddenFields: (block?.source as any)?.hiddenFields,
        permissions: block.permissions!, 
        options: {}
      },
      currentCardId: block.parentId ? storeData[parentInit].currentCardId : null,
      currentCardPrimaryKey: card && card.values ? card?.values.find(v => v?.primary === true)?.id! : null,
      inBlock: true,
      showModalAs: "modal",
      initId: block.id!, 
      initType: "block", 
      boardIsLoading: false
    }

    addBoardData(newObject)
  },[block, card])


  return (
    <>
      {(init in storeData) ? 
        <>
          <MattrDataProvider initiator={{id: block.id!, type: "block"}}>
            <BlockSubscriptionProvider blockId={block.id!} boardId={(block.source as  BlockSourceBlock).boardId!}>
              {(block.options as BlockFormOptions).formType === BlockFormTypeEnum.Create ? <FormBlockCreate formBlock={block}/> : <FormBlockEdit formBlock={block}/>}
            </BlockSubscriptionProvider>
          </MattrDataProvider>
        </>
        :
        <SmallSpinner />
      }
    </>
  )
}