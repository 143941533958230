import { useState } from 'react';
import { TbScriptPlus, TbSearch, TbSquareRoundedPlusFilled } from 'react-icons/tb';
import { useLocation } from 'react-router-dom';
import { Fetcher, FetcherInListFragment } from '../../../core/graphql/types';
import { useSpace } from '../../../core/providers/space';
import { ServicePreview } from './preview';
import { SimpleModal } from '../../modals/simple';
import CreateFetcher from '../../../containers/fetcher/form/create';
import { MatrMediumDialog } from '../../ui/portal';


const ServicesMenu = () => {
    const {services} = useSpace();
    let location = useLocation();
    const [open, setOpen] = useState(false)
  
    function openCreateModal() {setOpen(true)}
    function closeCreateModal() {setOpen(false)}

    return (
        <>
        <div className='px-2 pt-2'>
            <div className="flex items-center text-base font-semibold text-slate-500 py-2 px-2 max-h-9 mb-1">
                <span>Сервисы</span>
            </div>
            <div className='px-1 flex items-center max-h-9 py-1 justify-between text-slate-600 rounded-sm mb-2'>
                <div className='flex space-x-2'>
                    <TbScriptPlus className='px-1 w-6 h-6 hover:bg-slate-200 rounded-sm' onClick={() => openCreateModal()}/>
                </div>
                <div className='flex space-x-2'>
                    <TbSearch className='px-1 w-6 h-6 hover:bg-slate-200 rounded-sm'/>
                </div>
            </div>
            {services.map((service: FetcherInListFragment, idx: number) => (
                <ServicePreview key={idx} fetcher={service}/>
            ))}
        </div>
        {open && <MatrMediumDialog open={open} onClose={closeCreateModal}><CreateFetcher close={closeCreateModal}/></MatrMediumDialog>}
        </>
    );
};

export default ServicesMenu;