import React, { useEffect, useState } from "react";
import { getToken, clearStorage } from '../../store/authStore';
import { useCurrentUserQuery, useRevokeTokenMutation } from "../../graphql/types";


interface AuthContextType {
  isLogged: any;
  currentUser: any;
  signin: () => void;
  signout: () => void;
  setCurrentUser: (user: any) => void;
}

let AuthContext = React.createContext<AuthContextType>(null!);

export function AuthProvider({ children }: { children: React.ReactNode }) {
  let [token, setToken] = useState();
  let [isLogged, setIsLogged] = React.useState<any>(null);
  let [currentUser, setCurrentUser] = React.useState<any>(null);

  const [{data, error}, fetchUser] = useCurrentUserQuery({pause: true, requestPolicy: 'network-only'});
  const [, revokeToken] = useRevokeTokenMutation()

  useEffect(() => {
    setToken(getToken())
  }, [])

  useEffect(() => {
    if(!token || token === undefined) {
      setIsLogged(false);
      setCurrentUser(null)
      return
    }
    fetchUser()
  },[token])

  useEffect(() => {
    if(!data?.currentUser) {
      return
    }
    setIsLogged(true);
    setCurrentUser(data?.currentUser)
  },[data?.currentUser])

  useEffect(() => {
    if(!error) {
      return
    }
    setIsLogged(false);
    setCurrentUser(null)
  },[error])

  let signin = () => {
    setToken(getToken())
  };

  let signout = () => {
    revokeToken()
    clearStorage()
    setIsLogged(false);
    setCurrentUser(null)
    setToken(undefined)
  };

  let value = { isLogged, currentUser, signin, signout, setCurrentUser };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuth() {return React.useContext(AuthContext);}




