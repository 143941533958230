import { Tab } from "@headlessui/react";
import { useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { BiToggleLeft, BiToggleRight } from "react-icons/bi";
import { SingleValue } from "react-select";
import FiltersList from "../../../../../components/Filters/filter";
import MatrNarrowSelect from "../../../../../components/ui/select/narrow";
import { Board, Field, FieldType, Filter } from "../../../../../core/graphql/types";
import { useSpace } from "../../../../../core/providers/space";
import { BlockSourceSelect } from "../Forms/blockSource";


const BlockRecordsListSettings = () => {
  const {boards} = useSpace()
  // const {block, page, updateBlock} = usePanel()
  const {control, getValues, reset, watch, formState} = useFormContext()
  const [boardFields, setBoardFields] = useState<Field[]>([])

  useEffect(() => {
    // reset(block)
    setBoardFields(boards.find(b => b.id === getValues()?.source?.boardId)?.fields! as Field[])
  }, [boards])


  function handleChangeBoard(e: SingleValue<Board>, onChange: (...event: any[]) => void) {
    setBoardFields(boards.find(b => b.id === e?.id)?.fields! as Field[])
    onChange(e?.id)
  }

  function handleChangeView(e: SingleValue<Board>, onChange: (...event: any[]) => void) {
    setBoardFields(boards.find(b => b.id === e?.id)?.fields! as Field[])
    onChange(e?.id)
  }


  function handleSelectTitleField(e: SingleValue<Field>, onChange: (...event: any[]) => void) {
    onChange(e?.id)
  }

  function handleSelectSecondField(e: SingleValue<Field>, onChange: (...event: any[]) => void) {
    onChange(e?.id)
  }

  function handleSelectThirdField(e: SingleValue<Field>, onChange: (...event: any[]) => void) {
    onChange(e?.id)
  }

  function handleSelectImageField(e: SingleValue<Field>, onChange: (...event: any[]) => void) {
    onChange(e?.id)
  }

  function handleChangeFilters(filters: Filter[], onChange: { (...event: any[]): void; (arg0: any): void; }) {
    onChange(filters)
  }

  function handleChangeCheckbox(value: any, onChange: { (...event: any[]): void; (arg0: any): void; }) {
    onChange(!value)
  }

  return (
    <>
      <div className="relative w-full space-y-1.5">
        <div className="flex justify-end items-center space-x-2">
          <span className="text-xs">Показывать заголовок</span>
          <Controller
            name="options.showTitle"
            control={control}
            render={({ field: { onChange, value, ref }}) => (
              <div 
                className={`flex items-center  rounded-md font-normal  cursor-default`} 
                onClick={() => handleChangeCheckbox(value, onChange)}
              >
                {value === false ? <BiToggleLeft className='h-6 w-6 text-slate-400'/> : <BiToggleRight className='h-6 w-6 text-blue-600'/>}
              </div>
            )}
          />
        </div>
        <Controller
          name="options.title"
          control={control}
          rules={{required: true}}
          render={({ field: { onChange, value, ref }}) => (
            <div className="mt-2">
              <input className="text-xl w-full font-medium text-slate-800 placeholder:text-slate-300 focus:outline-none" value={value} onChange={onChange} />
            </div>
          )}
        />
      </div>
      <Tab.Group>
        <Tab.List className="text-base border-b">
          <Tab >{({ selected }) => (<div className={`px-3 py-1.5  font-medium ${selected ? "text-indigo-600 border-b-2 border-indigo-600" : "text-slate-600"} `}>Источник</div>)}</Tab>
          <Tab >{({ selected }) => (<div className={`px-3 py-1.5  font-medium ${selected ? "text-indigo-600 border-b-2 border-indigo-600" : "text-slate-600"} `}>Настройки списка</div>)}</Tab>
          <Tab >{({ selected }) => (<div className={`px-3 py-1.5  font-medium ${selected ? "text-indigo-600 border-b-2 border-indigo-600" : "text-slate-600"} `}>Фильтры</div>)}</Tab>
          <Tab >{({ selected }) => (<div className={`px-3 py-1.5  font-medium ${selected ? "text-indigo-600 border-b-2 border-indigo-600" : "text-slate-600"} `}>Разрешения</div>)}</Tab>
        </Tab.List>
        <Tab.Panels>
          <Tab.Panel className="py-2 space-y-2">
            <BlockSourceSelect />
          </Tab.Panel>
          <Tab.Panel className="py-2 space-y-2">
            <div className="relative space-y-1.5">
              <label className="matr-label">Колонка для заголовка:</label>
              <Controller
                name="options.titleFieldId"
                control={control}
                rules={{required: true}}
                render={({ field: { onChange, value, ref }}) => (
                  <div className="mt-2">
                    <MatrNarrowSelect 
                      classNamePrefix="matr_select_form"
                      placeholder="Выберите поле на базе которого будет построен вид"
                      isSearchable={false}
                      getOptionLabel ={(option)=>option?.title!}
                      getOptionValue ={(option)=>option?.id!}
                      value={boardFields?.find((f: any) => f.id === value)}
                      onChange={(e) => handleSelectTitleField(e, onChange)}
                      options={boardFields}
                    />
                  </div>
                )}
              />
            </div>
            <div className="relative space-y-1.5">
              <label className="matr-label">Колонка для второй записи:</label>
              <Controller
                name="options.secondFieldId"
                control={control}
                render={({ field: { onChange, value, ref }}) => (
                  <div className="mt-2">
                    <MatrNarrowSelect 
                      classNamePrefix="matr_select_form"
                      isSearchable={false}
                      getOptionLabel ={(option)=>option?.title!}
                      getOptionValue ={(option)=>option?.id!}
                      value={boardFields?.find((f: any) => f.id === value)}
                      onChange={(e) => handleSelectSecondField(e, onChange)}
                      options={boardFields}
                    />
                  </div>
                )}
              />
            </div>
            <div className="relative space-y-1.5">
              <label className="matr-label">Колонка для третей записи:</label>
              <Controller
                name="options.thirdFieldId"
                control={control}
                render={({ field: { onChange, value, ref }}) => (
                  <div className="mt-2">
                    <MatrNarrowSelect 
                      {...ref}
                      classNamePrefix="matr_select_form"
                      isSearchable={false}
                      getOptionLabel ={(option)=>option?.title!}
                      getOptionValue ={(option)=>option?.id!}
                      value={boardFields?.find((f: any) => f.id === value)}
                      onChange={(e) => handleSelectThirdField(e, onChange)}
                      options={boardFields}
                    />
                  </div>
                )}
              />
            </div>
            <div className="relative space-y-1.5">
              <label className="matr-label">Колонка для изображения:</label>
              <Controller
                name="options.imageFieldId"
                control={control}
                render={({ field: { onChange, value, ref }}) => (
                  <div className="mt-2">
                    <MatrNarrowSelect 
                      {...ref}
                      classNamePrefix="matr_select_form"
                      isSearchable={false}
                      getOptionLabel ={(option)=>option?.title!}
                      getOptionValue ={(option)=>option?.id!}
                      value={boardFields?.find((f: any) => f.id === value)}
                      onChange={(e) => handleSelectImageField(e, onChange)}
                      options={boardFields?.filter((f: any) => f.type === FieldType.Attachment)}
                    />
                  </div>
                )}
              />
            </div>
          </Tab.Panel>
          <Tab.Panel className="py-2 space-y-2">
            <Controller
              name="source.filters"
              control={control}
              render={({ field: { onChange, value, ref }}) => (
                <FiltersList boardFields={boardFields} currentFilters={value ? value : []} users={[]} saveFilters={(state: Filter[] | []) => handleChangeFilters(state, onChange)}/>
              )}
            />
          </Tab.Panel>
          <Tab.Panel className="py-2 space-y-2">
            <div className="relative">
              <div className="flex justify-between items-center w-full">
                <label className="matr-label w-full">Может добавлять записи</label>
                <Controller
                  name="permissions.canAdd"
                  control={control}
                  rules={{required: true}}
                  render={({ field: { onChange, value, ref }}) => (
                    <div 
                      className={`flex items-center  rounded-md font-normal  cursor-default`} 
                      onClick={() => handleChangeCheckbox(value, onChange)}
                    >
                      {value === false ? <BiToggleLeft className='h-6 w-6 text-slate-400'/> : <BiToggleRight className='h-6 w-6 text-blue-600'/>}
                    </div>
                  )}
                />
              </div>

              <div className="flex justify-between items-center w-full">
                <label className="matr-label w-full">Может изменять и просматривать записи</label>
                <Controller
                  name="permissions.canEdit"
                  control={control}
                  rules={{required: true}}
                  render={({ field: { onChange, value, ref }}) => (
                    <div 
                      className={`flex items-center  rounded-md font-normal  cursor-default`} 
                      onClick={() => handleChangeCheckbox(value, onChange)}
                    >
                      {value === false ? <BiToggleLeft className='h-6 w-6 text-slate-400'/> : <BiToggleRight className='h-6 w-6 text-blue-600'/>}
                    </div>
                  )}
                />
              </div>

              <div className="flex justify-between items-center w-full">
                <label className="matr-label w-full">Может редактировать в таблице</label>
                <Controller
                  name="permissions.canEditInline"
                  control={control}
                  rules={{required: true}}
                  render={({ field: { onChange, value, ref }}) => (
                    <div 
                      className={`flex items-center  rounded-md font-normal  cursor-default`} 
                      onClick={() => handleChangeCheckbox(value, onChange)}
                    >
                      {value === false ? <BiToggleLeft className='h-6 w-6 text-slate-400'/> : <BiToggleRight className='h-6 w-6 text-blue-600'/>}
                    </div>
                  )}
                />                    
              </div>
              <div className="flex justify-between items-center w-full">
                <label className="matr-label w-full">Может фильтровать записи</label>
                <Controller
                  name="permissions.canFilter"
                  control={control}
                  rules={{required: true}}
                  render={({ field: { onChange, value, ref }}) => (
                    <div 
                      className={`flex items-center  rounded-md font-normal  cursor-default`} 
                      onClick={() => handleChangeCheckbox(value, onChange)}
                    >
                      {value === false ? <BiToggleLeft className='h-6 w-6 text-slate-400'/> : <BiToggleRight className='h-6 w-6 text-blue-600'/>}
                    </div>
                  )}
                />                    
              </div>
              <div className="flex justify-between items-center w-full">
                <label className="matr-label w-full">Может искать по записям</label>
                <Controller
                  name="permissions.canSearch"
                  control={control}
                  rules={{required: true}}
                  render={({ field: { onChange, value, ref }}) => (
                    <div 
                      className={`flex items-center  rounded-md font-normal  cursor-default`} 
                      onClick={() => handleChangeCheckbox(value, onChange)}
                    >
                      {value === false ? <BiToggleLeft className='h-6 w-6 text-slate-400'/> : <BiToggleRight className='h-6 w-6 text-blue-600'/>}
                    </div>
                  )}
                />
              </div>
              <div className="flex justify-between items-center w-full">
                <label className="matr-label w-full">Может удалять записи</label>
                <Controller
                  name="permissions.canDelete"
                  control={control}
                  rules={{required: true}}
                  render={({ field: { onChange, value, ref }}) => (
                    <div 
                      className={`flex items-center  rounded-md font-normal  cursor-default`} 
                      onClick={() => handleChangeCheckbox(value, onChange)}
                    >
                      {value === false ? <BiToggleLeft className='h-6 w-6 text-slate-400'/> : <BiToggleRight className='h-6 w-6 text-blue-600'/>}
                    </div>
                  )}
                />
              </div>
            </div>
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </>
  )
}

export default BlockRecordsListSettings;

// const CanAddForm = () => {
//   const {page, block, changeCurrentBlock} = usePanel()
//   const form = page.blocks?.find(b => b?.parentId === block.id && b?.type === BlockTypeEnum.BlockForm && (b.options as BlockFormOptions).formType === BlockFormTypeEnum.Create)

//   return (
//     form ? 
//     <button type="button" className="pl-2 pr-1 py-1 mt-1.5 items-center w-full rounded text-sm flex bg-slate-200 justify-between" onClick={(e) => changeCurrentBlock(e, form)}>
//       <span>{(form?.options as BlockFormOptions)?.title}</span>
//       <TbEdit size={18} className="text-slate-400"/>
//     </button>
//     :
//     <div>Ошибка. Формы не существуе</div>
//   )
// } 

// const CanEditForm = () => {
//   const {page, block, changeCurrentBlock} = usePanel()
//   const form = page.blocks?.find(b => b?.parentId === block.id && b?.type === BlockTypeEnum.BlockForm && (b.options as BlockFormOptions).formType === BlockFormTypeEnum.Edit)

//   return (
//     form ?
//     <button type="button" className="pl-2 pr-1 py-1 mt-1.5 items-center w-full rounded text-sm flex bg-slate-200 justify-between" onClick={(e) => changeCurrentBlock(e, form)}>
//       <span>{(form?.options as BlockFormOptions)?.title}</span>
//       <TbEdit size={18} className="text-slate-400"/>
//     </button> :
//     <div>Ошибка. Формы не существуе</div>
//   )
// } 