import { sortBy } from "lodash";
import React, {useEffect, useState} from "react";
import { HiDotsHorizontal, HiPencil, HiTrash } from "react-icons/hi";
import {TbEdit} from 'react-icons/tb';
import { SmallSpinner } from "../../../components/loaders";
import { SimpleModal } from "../../../components/modals/simple";
import { PopoverWrapper } from "../../../components/ui/popover";
import { Role, useCreateSpaceRoleMutation, useDeleteSpaceRoleMutation, useGetRolesQuery, useUpdateSpaceRoleMutation } from "../../../core/graphql/types";
import CreateRole from "./create";
import DeleteRole from "./delete";
import EditRole from "./edit";

export function SpaceSettingsRoles() {
  const [openCreate, setOpenCreate] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [roles, setRoles] = useState<Role[] | any>([])
  const [selectedRole, setSelectedRole] = useState<Role | null>(null)
  const [errors, setErrors] = useState({});

  const [{data, fetching, error}] = useGetRolesQuery()
  const [{fetching: creatingRole}, createRole] = useCreateSpaceRoleMutation()
  const [{fetching: updatingRole}, updateRole] = useUpdateSpaceRoleMutation()
  const [{fetching: deletingRole}, deleteRole] = useDeleteSpaceRoleMutation()

  function openCreateModal() { setOpenCreate(true)};
  function closeCreateModal() { 
    setOpenCreate(false)
  };

  function openEditModal() { setOpenEdit(true)};
  function closeEditModal() { 
    setSelectedRole(null)
    setOpenEdit(false)
  };

  function openDeleteModal() { setOpenDelete(true)};
  function closeDeleteModal() { 
    setSelectedRole(null)
    setOpenDelete(false)
  };

  useEffect(() => {
    if(!data || !data?.roles) {
      return
    }
    setRoles(sortBy(data?.roles!, ['type', 'name']))
  }, [data]);

  function handleCreate(data: {[x: string]: any;}) {
    createRole({
      name: data.name
    }).then(res => {
      if(res.error) {
        return
      }
      let newRoles = [...roles, res.data?.createSpaceRole]
      setRoles(sortBy(newRoles, ['type', 'name']))
      closeCreateModal();
    })
  }

  function handleEdit(data: {[x: string]: any;}) {
    updateRole({
      id: data.id,
      name: data.name
    }).then(res => {
      if(res.error) {
        return
      }
      let restRoles = roles.filter((role: Role) => role.id !== res.data?.updateSpaceRole?.id!)
      let newRoles = [...restRoles, res.data?.updateSpaceRole]
      setRoles(sortBy(newRoles, ['type', 'name']))
      closeEditModal();
    })
    setSelectedRole(null)
  }

  function handleDelete(data: {[x: string]: any;}) {
    deleteRole({
      id: data.id,
      name: data.name
    }).then(res => {
      if(res.error) {
        return
      }
      let restRoles = roles.filter((role: Role) => role.id !== res.data?.deleteSpaceRole?.id!)
      setRoles(sortBy(restRoles, ['type', 'name']))
      closeDeleteModal();
    })
    setSelectedRole(null)
  }

  function handleEditMode(role: Role) {
    if(role.type === "default") {
      return
    }
    setSelectedRole(role)
    openEditModal()
  }

  function handleDeleteMode(role: Role) {
    if(role.type === "default") {
      return
    }
    setSelectedRole(role)
    openDeleteModal()
  }

  const popoverConfig = {
    button: <HiDotsHorizontal size={16}/>,
    actions: [
      {
        title: "Редактировать",
        icon: <HiPencil size={16}/>,
        onClick: handleEditMode
      },
      {
        title: "Удалить",
        icon: <HiTrash size={16}/>,
        onClick: handleDeleteMode
      }
    ]
  }
  
  return (
    <>
      <section className="w-full">
        <div className="w-full flex justify-between items-center">
          <h4 className="text-xl font-semibold">Роли и доступы</h4>
          <button
            type="button"
            onClick={openCreateModal}
            className="px-4 py-2 text-sm font-medium text-white bg-indigo-600 rounded-md hover:bg-indigo-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
          >
            Создать
          </button>
        </div>
        <p className="text-sm text-gray-500 leading-8 mb-6 mt-4">Список доступных ролей для пространства. Создавайте свои роли и разграничиваете права доступа.</p>
        <div className="relative w-full">
          <div className="flex flex-wrap space-y-1">
            {fetching || creatingRole || updatingRole || deletingRole ? <SmallSpinner/> : (
                roles.map((role: Role, index: number) => (
                  <div key={index} 
                    className="flex flex-row items-center justify-between py-2 px-2 w-full rounded-md hover:bg-slate-100 cursor-pointer focus:outline-none focus:ring-2 ring-offset-2 ring-offset-indigo-200 ring-white ring-opacity-60"
                  >
                    <div className="flex flex-col space-y-1">
                      <div className="text-sm font-medium">
                        {role?.name}
                      </div>
                      <div className="inline-flex text-xs text-gray-600 space-x-2 items-center">
                        <span className={`w-3 h-3 rounded-full ${role?.type == "default" ? "bg-purple-700" : "bg-teal-500"}`}></span>
                        <span>{role?.type == "default" ? "Базовая роль" : " Пользовательская роль"}</span>
                      </div>
                    </div>
                    {role?.type == "default" ? <></> : <PopoverWrapper config={popoverConfig} item={role}/>}
                  </div>
                ))
              )
            }
          </div>
        </div>
      </section>
      {openCreate && <SimpleModal 
        open = {openCreate}
        close = {closeCreateModal}
        isWide = {false}
      >
        <CreateRole onChange={handleCreate} closeModal = {closeCreateModal}/>
      </SimpleModal>}
      {openEdit && <SimpleModal 
        open = {openEdit}
        close = {closeEditModal}
        isWide = {false}
      >
        <EditRole role={selectedRole!} onChange={handleEdit} closeModal = {closeEditModal}/>
      </SimpleModal>}
      {openDelete && <SimpleModal 
        open = {openDelete}
        close = {closeDeleteModal}
        isWide = {false}
      >
        <DeleteRole role={selectedRole!} onChange={handleDelete} closeModal = {closeDeleteModal}/>
      </SimpleModal>}
    </>
  );
}