import { useForm, Controller, useWatch, useFormContext } from "react-hook-form";
import _ from "lodash";
import { usePanel } from "../../../../../core/providers/panel";
import MatrSelect from "../../../../../components/ui/select/base";
import { SingleValue } from "react-select";
import { Block, BlockFieldOptions, BlockSourceField, BlockSourceTypeEnum, BlockTypeEnum, Board, Field } from "../../../../../core/graphql/types";
import { DragEvent, useCallback, useEffect, useState } from "react";
import { useSpace } from "../../../../../core/providers/space";
import { Disclosure } from "@headlessui/react";
import { TbChevronUp, TbTrash } from "react-icons/tb";
import { panelBlocks } from "../../../../../core/enums";
import { iconByType } from "../../../../../components/icons";
import { BiToggleLeft, BiToggleRight } from "react-icons/bi";
import { debounce } from "debounce";
import useDeepCompareEffect from "use-deep-compare-effect";
import { getBlockWHByType } from "../../../../../core/Controllers/Panel/blocks_functions";


const BlockSectionSettings = () => {
  const {boards} = useSpace()
  const {page} = usePanel()
  const {control, watch, getValues, setValue, formState} = useFormContext()
  const [fields, setFields] = useState<Field[]>()
  const [columns, setColumns] = useState<{field:Field, selected: boolean, block: Block | null}[]>()

  useEffect(() => {
    setValue('sourceType', BlockSourceTypeEnum.Empty)
    setValue('source', {type: BlockSourceTypeEnum.Empty})
  }, [])

  useEffect(() => {
    let boardFields = boards.find(b => b.id === getValues()?.boardId)?.fields! as Field[]
    setFields(boardFields)
    prepareFields(boardFields)
  }, [getValues().boardId, page])

  function handleChangeBoard(e: SingleValue<Board>, onChange: (...event: any[]) => void) {
    setFields(boards.find(b => b.id === e?.id)?.fields! as Field[])
    onChange(e?.id)
  }

  function prepareFields(boardFields: Field[]) {
    let blocksInUse = page?.blocks?.filter(pb => pb?.parentId === getValues().id)
    let fieldIdsInUse = blocksInUse?.map(pb => (pb?.source as BlockSourceField).field?.id)
    let cols: {field: Field, selected: boolean}[] | any = []
    
    boardFields?.map((f: Field) => {
      if(fieldIdsInUse?.includes(f.id)) {
        cols.push({field: f, selected: true, block: blocksInUse?.find(bf => (bf?.source as BlockSourceField).field?.id === f.id)})
      } else {
        cols.push({field: f, selected: false, block: null})
      }
    })

    setColumns(cols)
  }

  function handleChangeCheckbox(value: any, onChange: { (...event: any[]): void; (arg0: any): void; }) {
    onChange(!value)
  }

  return (
        <div className="space-y-2">
          <div className="relative w-full pt-4">
            <div className="flex justify-start items-center space-x-2">
              <Controller
                name="options.showTitle"
                control={control}
                render={({ field: { onChange, value, ref }}) => (
                  <div 
                    className={`flex items-center  rounded-md font-normal  cursor-default`} 
                    onClick={() => handleChangeCheckbox(value, onChange)}
                  >
                    {value === false ? <BiToggleLeft className='h-6 w-6 text-slate-400'/> : <BiToggleRight className='h-6 w-6 text-blue-600'/>}
                  </div>
                )}
              />
              <span className="text-xs">Показывать заголовок</span>
            </div>
            <Controller
              name="options.title"
              control={control}
              rules={{required: true}}
              render={({ field: { onChange, value, ref }}) => (
                <div className="">
                  <input className="text-xl w-full font-medium text-slate-800 placeholder:text-slate-300 focus:outline-none" value={value} onChange={onChange} />
                </div>
              )}
            />
          </div>
          <div className="relative">
            <div className="flex justify-between items-center w-full">
              <label className="matr-label w-full">Может редактировать</label>
              <Controller
                name="permissions.canEdit"
                control={control}
                rules={{required: true}}
                render={({ field: { onChange, value, ref }}) => (
                  <div 
                    className={`flex items-center  rounded-md font-normal  cursor-default`} 
                    onClick={() => handleChangeCheckbox(value, onChange)}
                  >
                    {value === false ? <BiToggleLeft className='h-6 w-6 text-slate-400'/> : <BiToggleRight className='h-6 w-6 text-blue-600'/>}
                  </div>
                )}
              />
            </div>
          </div>
        </div>
  )
}

export default BlockSectionSettings;