import { NodeField } from "../../../../../core/graphql/types";
import { useFlowContext } from "../../../../../core/providers/flow"

export default function NodeOutputData() {
  const {node} = useFlowContext();

  function getNodeName(type: string) {
    switch (type) {
      case "flow_record": return "Запись в процессе"
      case "board_record": return "Запись в таблице"
      case "response_record": return "Запись в запросе"
      default: return "Не определено"
    }
  } 

  return (
    <>
      <div  className="p-2">
        <div className="text-base text-slate-400 font-medium w-full px-2 cursor-default mb-2">{getNodeName(node.data.outputType)}</div>
        {node.data.fields.filter((field: NodeField) => field.external === true).map((field: NodeField) => (
          <div key={field.id} className="text-sm text-slate-600 w-full px-2 hover:bg-slate-100 h-8 cursor-default flex items-center rounded-sm">{field.name}</div>
        ))}
      </div>
    </>
  )
}