import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { TbApiApp, TbBell, TbBook, TbBox, TbCategory, TbHexagon, TbLayoutSidebarLeftCollapse, TbLayoutSidebarLeftExpand, TbLogout2, TbServer, TbTable, TbUser, TbWebhook } from 'react-icons/tb';
import { useAuth } from '../../core/providers/auth';
import { useSidebar } from '../../core/providers/sidebar';
import AccountMenu from '../navigation/account';
import BoardsMenu from '../navigation/boards';
import PanelsMenu from '../navigation/panels';
import SpaceMenu from '../navigation/space';
import ServicesMenu from '../navigation/services';
import FlowsMenu from '../navigation/flows';



const SpaceSidebar: React.FC = () => {
	const auth = useAuth()
	const location = useParams()
	const navigate = useNavigate()
	let {isSidebarOpen, openSidebar, closeSidebar} = useSidebar()
	const [section, setSection] = useState('base')

	useEffect(() => {
		if(location.hasOwnProperty('board_id')) { setSection("boards") }
		if(location.hasOwnProperty('page_id')) { setSection("pages") }
		if(location.hasOwnProperty('flow_id')) { setSection("flows") }
		if(location.hasOwnProperty('fetcher_id')) { setSection("services") }
	},[])

	function showSection() {
		switch (section) {
			case "boards": return <BoardsMenu />
			case "pages": return <PanelsMenu />
			case "spaces": return <SpaceMenu />
			case "account": return <AccountMenu />
			case "notifications": 
				closeSidebar()
				navigate("/notifications")
				return <></>
			case "services": return <ServicesMenu />
			case "flows": return <FlowsMenu />
			case "base":
			default:
				return (
					<div className='relative flex flex-col w-full space-y-4 pt-4 text-base font-semibold text-slate-500 px-2'>
						<div className='px-2 hover:text-indigo-600 cursor-pointer' onClick={() => setSection("pages")}>Страницы</div>
						<div className='px-2 hover:text-indigo-600 cursor-pointer' onClick={() => setSection("boards")}>Таблицы</div>
						<div className='px-2 hover:text-indigo-600 cursor-pointer' onClick={() => setSection("flows")}>Процессы</div>
						<div className='px-2 hover:text-indigo-600 cursor-pointer' onClick={() => setSection("services")}>Сервисы</div>
					</div>
				)
		}
	}

	function handleLogout() {
		auth.signout()
		navigate('/')
	}

	return (
		<aside className={`relative h-full flex-none ${isSidebarOpen ? "w-80" : "w-12"} bg-slate-50`}>
			<div className="flex h-full flex-col w-full border-r min-h-full">
				{ isSidebarOpen ?
					<>
						<div className="relative flex w-full h-14 items-center">
							<div className="flex w-full my-2 items-center h-full">
								<div className='w-12 flex items-center justify-center border-r h-full'>
									<TbLayoutSidebarLeftCollapse size={20} onClick={() => closeSidebar()}/>
								</div>
								<div className='flex h-full justify-start items-center px-4 '>
									<a href="/" className="flex font-medium text-gray-900 lg:w-auto items-center justify-center">
										<span className="mx-auto text-base font-bold leading-none text-gray-900 select-none">Mattr<span className="text-indigo-600">.</span></span>
									</a>
								</div>
							</div>
						</div>
						<div className='flex w-full h-full'>
							<div className='flex-none flex flex-col justify-between h-full w-12 border-r'>
								<div className='relative flex flex-col w-full items-center space-y-4 pt-4'>
									<TbBook className={`${section === "pages" ? "text-indigo-600" : "text-slate-600"}`} size={20} onClick={() => setSection("pages")}/>
									<TbTable className={`${section === "boards" ? "text-indigo-600" : "text-slate-600"}`} size={20} onClick={() => setSection("boards")}/>
									<TbHexagon className={`${section === "flows" ? "text-indigo-600" : "text-slate-600"}`} size={20} onClick={() => setSection("flows")}/>
									<TbCategory className={`${section === "services" ? "text-indigo-600" : "text-slate-600"}`} size={20} onClick={() => setSection("services")}/>
								</div>
								<div className='relative flex flex-col w-full items-center space-y-4 pb-4'>
									<TbBell className={`${section === "notifications" ? "text-indigo-600" : "text-slate-600"}`} size={20} onClick={() => setSection("notifications")}/>
									<TbServer className={`${section === "spaces" ? "text-indigo-600" : "text-slate-600"}`} size={20} onClick={() => setSection("spaces")}/>
									<TbUser className={`${section === "account" ? "text-indigo-600" : "text-slate-600"}`} size={20} onClick={() => setSection("account")}/>
									<TbLogout2 size={20} onClick={() => handleLogout()}/>
								</div>
							</div>
							<div className='flex flex-col justify-between h-full w-full'>
								{showSection()}
							</div>
						</div>
					</>
					:
					<>
						<div className="relative flex w-full h-14 items-center ">
							<div className="flex w-full my-2 justify-between items-center h-full pl-3.5">
								<TbLayoutSidebarLeftExpand size={20} onClick={() => openSidebar()}/>							
							</div>
						</div>
						<div className='flex flex-col justify-between h-full'>
							<div className='relative flex flex-col w-full items-center space-y-4 pt-4'>
								<TbBook className={`${section === "pages" ? "text-indigo-600" : "text-slate-600"}`} size={20} onClick={() => {
									openSidebar()
									setSection("pages")
								}}/>
								<TbTable className={`${section === "boards" ? "text-indigo-600" : "text-slate-600"}`}  size={20} onClick={() => {
									openSidebar()
									setSection("boards")
								}}/>
								<TbHexagon className={`${section === "flows" ? "text-indigo-600" : "text-slate-600"}`} size={20} onClick={() => {
									openSidebar()
									setSection("flows")
								}}/>
								<TbCategory className={`${section === "services" ? "text-indigo-600" : "text-slate-600"}`}  size={20} onClick={() => {
									openSidebar()
									setSection("services")
								}}/>
							</div>
							<div className='relative flex flex-col w-full items-center space-y-4 pb-4'>
								<TbBell size={20} className={`${section === "notifications" ? "text-indigo-600" : "text-slate-600"}`} onClick={() => {
									closeSidebar()
									setSection("notifications")
								}}/>
								<TbServer className={`${section === "spaces" ? "text-indigo-600" : "text-slate-600"}`} size={20} onClick={() => {
									openSidebar()
									setSection("spaces")
								}}/>
								<TbUser size={20} onClick={() => {
									openSidebar()
									setSection("account")
								}}/>
								<TbLogout2 size={20} onClick={() => handleLogout()}/>
							</div>
						</div>
					</>
				}
				
				{/* <PanelsMenu />
      			{currentUser.role.name === "Administrator" && <BaseMenu />}
				<SpaceMenu />
				<UserMenu/> */}
			</div>
		</aside>
	);
};

export default SpaceSidebar;