import _ from 'lodash';
import { HiArrowCircleLeft } from 'react-icons/hi';
import { Link } from 'react-router-dom';
import { useAuth } from '../../core/providers/auth';
import { useSpace } from '../../core/providers/space';

import { useEffect, useRef, useState } from 'react';
import { TbChevronDown, TbChevronUp } from 'react-icons/tb';
import { Board } from '../../core/graphql/types';
import { useBoard } from '../../core/providers/board';
import { MatrPopover } from '../ui/portal';
import { useSpaceStore } from '../../core/store/store';

const BoardHeader = () => {
  const auth = useAuth();
  const { boards } = useSpace();
  const {initId, initType} = useBoard();
  const init: string = `${initType}:${initId}`;
  const store = useSpaceStore()
  const {board} = store.data[init];
  const [groupBoards, setGroupBoards] = useState<Board[]>([])
  const boardsRef = useRef(null)
  const [openOpenBoards, setOpenBoards] = useState(false)
  function closeBoards() { setOpenBoards(false)}

  useEffect(() => {
    setGroupBoards(boards)
    // setGroupBoards(boards.filter(b => b.group?.id === board.group?.id))
  }, [boards])


  return (
    <>
      <header className="relative flex w-full h-14 items-center border-b">
        <div className="flex w-full my-2 items-center h-full">
          <div className="flex-none h-full w-48 px-4">
            <div className='flex h-full justify-start items-center'>
              <Link to="/" className="flex font-medium text-gray-900 lg:w-auto items-center justify-center space-x-2">
                <HiArrowCircleLeft size={22} className="text-slate-400 hover:text-slate-600"/>
                <span className="mx-auto text-base font-bold leading-none text-gray-900 select-none">Mattr<span className="text-indigo-600">.</span></span>
              </Link>
            </div>
          </div>
          <div className="flex h-full w-full items-center justify-center px-4 space-x-2 cursor-default">
            <div ref={boardsRef} className='text-sm font-medium text-slate-600 cursor-default flex items-center space-x-2'>
              <span>{auth.currentUser.space.name} / {board!.group?.name}</span>
              <div className='rounded-full bg-slate-200 items-center w-5 h-5 flex justify-center' onClick={() => setOpenBoards(!openOpenBoards)}>
                {openOpenBoards ? <TbChevronUp size={16} /> : <TbChevronDown size={16}/>}
              </div>
            </div>
          </div>
        </div>
      </header>
      {openOpenBoards && <MatrPopover onClose={closeBoards} reference={boardsRef.current} placement="bottom">
        <div className='w-96 flex p-3 shadow-lg bg-white max-h-[24rem] border mt-6 rounded-md'>
          <ul className='flex flex-col space-y-2 w-full overflow-scroll'>
            {_.sortBy(groupBoards, ['name']).map((item, i) => (
              <Link to={`/board/${item.id}`} className='cursor-default'>
                <li key={i} className={`flex flex-col border hover:bg-indigo-200 w-full rounded p-2 ${item.id === board!.id ? "bg-indigo-600 text-white" : "text-slate-800"}`}>
                  <span className="text-sm font-medium  cursor-default">{item.name}</span>
                </li>
              </Link>
            ))}
          </ul>
        </div>
      </MatrPopover>}
    </>
  )
};

export default BoardHeader;