import { useFormContext } from "react-hook-form";
import { BlockFormTypeEnum } from "../../../../../core/graphql/types";
import BlockFormCreateSettings from "./create/settings";
import BlockFormEditSettings from "./edit/settings";

const BlockFormSettings = () => {
  const {getValues} = useFormContext()

  switch (getValues('options.formType')) {
    case BlockFormTypeEnum.Create: return <BlockFormCreateSettings />
    case BlockFormTypeEnum.Edit: return <BlockFormEditSettings />
    default: return <></>
      
  }
}

export default BlockFormSettings;