import BaseParametersPanel from "./base";
import PathParametersPanel from "./path";

export default function ParametersPanel():JSX.Element {  
  return (
    <>
      <BaseParametersPanel />
      <PathParametersPanel />
    </>
  )
}