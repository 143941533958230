import React, { useEffect, useState, useCallback } from "react";
import { SmallSpinner } from "../../components/loaders";
import { useUserNotificationsQuery, UserNotification, Space, NotificationTypesEnum } from "../../core/graphql/types"
import DeleteFromSpaceNotification from "./context/delete_from_space";
import InvitationNotification from "./context/invitation";
import ConfirmationEmailNotification from './context/confirmation_email';
import NotificationFromFlow from "./context/flow_notification";
import _ from "lodash";
import { useAuth } from "../../core/providers/auth";


export function AccountNotifications() {
  const {currentUser} = useAuth()
  const [list, setList] = useState<UserNotification[] | any>([])
  const [{fetching, error, data}] = useUserNotificationsQuery()

  useEffect(() => {
    if(!data) {
      return
    }
    setList(_.groupBy(data?.userNotifications, 'spaceId'))
  },[data])

  function removeFromList(id: string) {
    let newList = list.filter((i: UserNotification) => i.id !== id)
    setList(_.groupBy(newList, 'spaceId'))
  }

  if (error) return <>`Error! ${error.message}`</>;


  function getNotification(data: UserNotification) {
    switch (data.resource?.type) {
      case NotificationTypesEnum.InviteToSpace: return <InvitationNotification data={data} onRemove={removeFromList}/>
      case NotificationTypesEnum.FlowNotification: return <NotificationFromFlow data={data} onRemove={removeFromList}/>
      case NotificationTypesEnum.DeleteFromSpace: return <DeleteFromSpaceNotification data={data} onRemove={removeFromList}/>
      case NotificationTypesEnum.ConfirmEmail: return <ConfirmationEmailNotification/>
      default:
        return <></>
    }
  }
  return (
    <>
      <div className='flex-none flex w-full h-14 items-center text-base px-4 justify-between border-b'>
        <div className="flex w-full justify-between">
          <div className="flex items-center font-medium text-base text-slate-800">
            <span className="px-1">{currentUser.space.name}</span>
            <span className="px-1">/</span>
            <span className="px-1">Увдомления</span>
          </div>
        </div>
      </div>
      <div className="flex flex-col h-full overflow-hidden">
        { fetching ? <SmallSpinner/> : 
          <>
          <div className="max-w-[900px] w-full mx-auto">
            <div className="text-xl py-3 px-6 font-semibold">Оповещения</div>
          </div>
          <div className="overflow-scroll h-full">
            <div className="max-w-[900px] mx-auto w-full">
              {Object.keys(list).map(space => (
                <div key={space} className="pb-6 px-4">
                  <span className="flex px-2 text-base font-medium h-8 items-center w-full text-slate-400">{space === "null" ? "Системные оповещения" : currentUser.spaces.find((s:Space) => s.spaceId === space)?.name!}</span>
                  <div className="space-y-2">
                  {list[space].map((notification: UserNotification, index: number) => (
                    <div key={index} className="flex flex-col px-2 text-sm hover:bg-slate-100 cursor-default">{getNotification(notification!)}</div>
                  ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
          </>
        }
      </div>
    </>
  )
  
}