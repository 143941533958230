import { Tab } from "@headlessui/react";
import { useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { BiToggleLeft, BiToggleRight } from "react-icons/bi";
import { SingleValue } from "react-select";
import FiltersList from "../../../../../components/Filters/filter";
import HiddenFields from "../../../../../components/Filters/hidden";
import MatrSelect from "../../../../../components/ui/select/base";
import { Field, FieldType, Filter } from "../../../../../core/graphql/types";
import { useSpace } from "../../../../../core/providers/space";
import { BlockSourceSelect } from "../Forms/blockSource";

const BlockCalendarSettings = () => {
  const {boards} = useSpace()
  const [loading, setLoading] = useState(true)
  const {control, getValues, setValue, watch, formState} = useFormContext()
  const [boardFields, setBoardFields] = useState<Field[]>([])

  useEffect(() => {
    if(!boards || boards.length === 0 || !watch('source.boardId')) {return}
    setBoardFields(boards.find(b => b.id === getValues('source.boardId'))?.fields! as Field[])
    setLoading(false)
  }, [boards, watch('source.boardId')])


  function handleChangeFilters(filters: Filter[], onChange: { (...event: any[]): void; (arg0: any): void; }) {
    onChange(filters)
  }

  function handleHideShowFields(state: string[] | any[], onChange: { (...event: any[]): void; (...event: any[]): void; }) {
    onChange(state)
  }

  function handleChangeCheckbox(value: any, onChange: { (...event: any[]): void; (arg0: any): void; }) {
    onChange(!value)
  }

  function handleSelectStackedField(e: SingleValue<Field>, onChange: (...event: any[]) => void) {
    onChange(e?.id)
  }

  function handleSelectStartEventField(e: SingleValue<Field>, onChange: (...event: any[]) => void) {
    onChange(e?.id)
  }

  function handleSelectEndEventField(e: SingleValue<Field>, onChange: (...event: any[]) => void) {
    onChange(e?.id)
  }

  function handleSelectTitleField(e: SingleValue<Field>, onChange: (...event: any[]) => void) {
    onChange(e?.id)
  }

  return (
    <>
      <div className="relative w-full space-y-1.5">
        <div className="flex justify-end items-center space-x-2">
          <span className="text-xs">Показывать заголовок</span>
          <Controller
            name="options.showTitle"
            control={control}
            render={({ field: { onChange, value, ref }}) => (
              <div 
                className={`flex items-center  rounded-md font-normal  cursor-default`} 
                onClick={() => handleChangeCheckbox(value, onChange)}
              >
                {value === false ? <BiToggleLeft className='h-6 w-6 text-slate-400'/> : <BiToggleRight className='h-6 w-6 text-blue-600'/>}
              </div>
            )}
          />
        </div>
        <Controller
          name="options.title"
          control={control}
          rules={{required: true}}
          render={({ field: { onChange, value, ref }}) => (
            <div className="mt-2">
              <input placeholder="Заголовок" className="text-xl w-full font-medium text-slate-800 placeholder:text-slate-300 focus:outline-none" value={value} onChange={onChange} />
            </div>
          )}
        />
      </div>
      <Tab.Group>
        <Tab.List className="text-base border-b">
          <Tab >{({ selected }) => (<div className={`px-3 py-1.5  font-medium ${selected ? "text-indigo-600 border-b-2 border-indigo-600" : "text-slate-600"} `}>Источник</div>)}</Tab>
          <Tab >{({ selected }) => (<div className={`px-3 py-1.5  font-medium ${selected ? "text-indigo-600 border-b-2 border-indigo-600" : "text-slate-600"} `}>Настройки календаря</div>)}</Tab>
          <Tab >{({ selected }) => (<div className={`px-3 py-1.5  font-medium ${selected ? "text-indigo-600 border-b-2 border-indigo-600" : "text-slate-600"} `}>Колонки</div>)}</Tab>
          <Tab >{({ selected }) => (<div className={`px-3 py-1.5  font-medium ${selected ? "text-indigo-600 border-b-2 border-indigo-600" : "text-slate-600"} `}>Фильтры</div>)}</Tab>
          <Tab >{({ selected }) => (<div className={`px-3 py-1.5  font-medium ${selected ? "text-indigo-600 border-b-2 border-indigo-600" : "text-slate-600"} `}>Разрешения</div>)}</Tab>
        </Tab.List>
        <Tab.Panels>
          <Tab.Panel className="py-2 space-y-2">
            <BlockSourceSelect />
          </Tab.Panel>
          <Tab.Panel className="py-2 space-y-2">
          <div className="relative space-y-1.5">
            <label className="matr-label">Базовое поле "Дата и время":</label>
            <p className="text-xs text-slate-400 pt-1">Это поле используется для построения вида. Оно также является временем начала события.</p>
            <Controller
              name="options.stackedFieldId"
              control={control}
              rules={{required: true}}
              render={({ field: { onChange, value, ref }}) => (
                <div className="mt-2">
                  <MatrSelect 
                    classNamePrefix="matr_select_form"
                    placeholder="Базовая колонка"
                    isSearchable={false}
                    getOptionLabel ={(option)=>option?.title!}
                    getOptionValue ={(option)=>option?.id!}
                    value={boardFields?.find((f: any) => f.id === value)}
                    onChange={(e) => handleSelectStackedField(e, onChange)}
                    options={boardFields?.filter((f: any) => f.type === FieldType.Datetime)} 
                  />
                </div>
              )}
            />
          </div>
          { watch('options.stackedFieldId') && <>
            <div className="relative space-y-1.5">
              <label className="matr-label">Выберите поле "Дата и время" начала события:</label>
              <p className="text-xs text-slate-400 pt-1">Это поле нужно чтобы отображать, когда событие началось. Если оно не указано то будет браться значение времени из базового поля.</p>
              <Controller
                name="options.startEventFieldId"
                control={control}
                render={({ field: { onChange, value, ref }}) => (
                  <div className="mt-2">
                    <MatrSelect 
                      classNamePrefix="matr_select_form"
                      placeholder="Выберите поле начала события"
                      isSearchable={false}
                      isClearable={true}
                      getOptionLabel ={(option)=>option?.title!}
                      getOptionValue ={(option)=>option?.id!}
                      defaultValue = {value ? boardFields?.find((f: { id: any; }) => f.id === value) : []}
                      value={boardFields?.find(f => f.id === value)}
                      onChange={(e) => handleSelectStartEventField(e, onChange)}
                      options={boardFields?.filter(f => f.type === FieldType.Datetime)}
                    />
                  </div>
                )}
              />
            </div>
            <div className="relative space-y-1.5">
              <label className="matr-label">Выберите поле "Дата и время" окончания события:</label>
              <p className="text-xs text-slate-400 pt-1">Это поле нужно чтобы отображать, когда событие закончится. Если оно не указано то будет братъся значение времени из базового поля.</p>
              <Controller
                name="options.endEventFieldId"
                control={control}
                render={({ field: { onChange, value, ref }}) => (
                  <div className="mt-2">
                    <MatrSelect 
                      classNamePrefix="matr_select_form"
                      placeholder="Выберите поле окончания события"
                      isSearchable={false}
                      isClearable={true}
                      getOptionLabel ={(option)=>option?.title!}
                      getOptionValue ={(option)=>option?.id!}
                      value={boardFields?.find(f=> f.id === value)}
                      onChange={(e) => handleSelectEndEventField(e, onChange)}
                      options={watch('options.startEventFieldId') ? boardFields?.filter(f => f.type === FieldType.Datetime && f.id !== watch('options.startEventFieldId')) : []}
                    />
                  </div>
                )}
              />
            </div>
            <div className="relative space-y-1.5">
              <label className="matr-label">Выберите поле заголовка для события:</label>
              <p className="text-xs text-slate-400 pt-1">Занчение из этого поля будет отображаться в календаре.</p>
              <Controller
                name="options.titleFieldId"
                control={control}
                render={({ field: { onChange, value, ref }}) => (
                  <div className="mt-2">
                    <MatrSelect 
                      classNamePrefix="matr_select_form"
                      placeholder="Выберите поле окончания события"
                      isSearchable={false}
                      getOptionLabel ={(option)=>option?.title!}
                      getOptionValue ={(option)=>option?.id!}
                      value={boardFields?.find(f => f.id === value)}
                      onChange={(e) => handleSelectTitleField(e, onChange)}
                      options={boardFields}
                    />
                  </div>
                )}
              />
            </div> 
          </>}
          </Tab.Panel>
          <Tab.Panel className="py-2 space-y-2">
            <Controller
              name="source.hiddenFields"
              control={control}
              render={({ field: { onChange, value, ref }}) => (
                !loading ? <HiddenFields fields={boardFields!} hiddenFields={value ? value : []} onChangeFields={(state: string[] | []) => handleHideShowFields(state, onChange)}/> : <></>
              )}
            />
          </Tab.Panel>
          <Tab.Panel className="py-2 space-y-2">
            <Controller
              name={`source.filters`}
              control={control}
              render={({ field: { onChange, value, ref }}) => (
                !loading ? <FiltersList boardFields={boardFields} currentFilters={value ? value : []} users={[]} saveFilters={(state: Filter[] | []) => handleChangeFilters(state, onChange)}/> : <></>
              )}
            />
          </Tab.Panel>
          <Tab.Panel className="py-2 space-y-2">
            <div className="relative">
                <div className="flex justify-between items-center w-full">
                  <label className="matr-label w-full">Может добавлять записи</label>
                  <Controller
                    name="permissions.canAdd"
                    control={control}
                    rules={{required: true}}
                    render={({ field: { onChange, value, ref }}) => (
                      <div 
                        className={`flex items-center  rounded-md font-normal  cursor-default`} 
                        onClick={() => handleChangeCheckbox(value, onChange)}
                      >
                        {value === false ? <BiToggleLeft className='h-6 w-6 text-slate-400'/> : <BiToggleRight className='h-6 w-6 text-blue-600'/>}
                      </div>
                    )}
                  />
                </div>
                <div className="flex justify-between items-center w-full">
                  <label className="matr-label w-full">Может изменять и просматривать записи</label>
                  <Controller
                    name="permissions.canEdit"
                    control={control}
                    rules={{required: true}}
                    render={({ field: { onChange, value, ref }}) => (
                      <div 
                        className={`flex items-center  rounded-md font-normal  cursor-default`} 
                        onClick={() => handleChangeCheckbox(value, onChange)}
                      >
                        {value === false ? <BiToggleLeft className='h-6 w-6 text-slate-400'/> : <BiToggleRight className='h-6 w-6 text-blue-600'/>}
                      </div>
                    )}
                  />
                </div>
                <div className="flex w-full pb-2">
                  {/* {watch("permissions.canEdit") === false ? <div className="text-sm text-slate-500">Не активно</div> : <CanEditForm />} */}
                </div>
                <div className="flex justify-between items-center w-full">
                  <label className="matr-label w-full">Может редактировать в таблице</label>
                  <Controller
                    name="permissions.canEditInline"
                    control={control}
                    rules={{required: true}}
                    render={({ field: { onChange, value, ref }}) => (
                      <div 
                        className={`flex items-center  rounded-md font-normal  cursor-default`} 
                        onClick={() => handleChangeCheckbox(value, onChange)}
                      >
                        {value === false ? <BiToggleLeft className='h-6 w-6 text-slate-400'/> : <BiToggleRight className='h-6 w-6 text-blue-600'/>}
                      </div>
                    )}
                  />                    
                </div>
                <div className="flex w-full pb-2">
                  {watch("permissions.canEditInline") === false ? <div className="text-sm text-slate-500">Не активно</div> : <div className="text-sm text-slate-500">Активно</div>}
                </div>  
                <div className="flex justify-between items-center w-full">
                  <label className="matr-label w-full">Может фильтровать записи</label>
                  <Controller
                    name="permissions.canFilter"
                    control={control}
                    rules={{required: true}}
                    render={({ field: { onChange, value, ref }}) => (
                      <div 
                        className={`flex items-center  rounded-md font-normal  cursor-default`} 
                        onClick={() => handleChangeCheckbox(value, onChange)}
                      >
                        {value === false ? <BiToggleLeft className='h-6 w-6 text-slate-400'/> : <BiToggleRight className='h-6 w-6 text-blue-600'/>}
                      </div>
                    )}
                  />                    
                </div>
                <div className="flex w-full pb-2">
                  {watch("permissions.canFilter") === false ? <div className="text-sm text-slate-500">Не активно</div> : <div className="text-sm text-slate-500">Активно</div>}
                </div>  
                <div className="flex justify-between items-center w-full">
                  <label className="matr-label w-full">Может искать по записям</label>
                  <Controller
                    name="permissions.canSearch"
                    control={control}
                    rules={{required: true}}
                    render={({ field: { onChange, value, ref }}) => (
                      <div 
                        className={`flex items-center  rounded-md font-normal  cursor-default`} 
                        onClick={() => handleChangeCheckbox(value, onChange)}
                      >
                        {value === false ? <BiToggleLeft className='h-6 w-6 text-slate-400'/> : <BiToggleRight className='h-6 w-6 text-blue-600'/>}
                      </div>
                    )}
                  />
                </div>
                <div className="flex justify-between items-center w-full">
                  <label className="matr-label w-full">Может удалять записи</label>
                  <Controller
                    name="permissions.canDelete"
                    control={control}
                    rules={{required: true}}
                    render={({ field: { onChange, value, ref }}) => (
                      <div 
                        className={`flex items-center  rounded-md font-normal  cursor-default`} 
                        onClick={() => handleChangeCheckbox(value, onChange)}
                      >
                        {value === false ? <BiToggleLeft className='h-6 w-6 text-slate-400'/> : <BiToggleRight className='h-6 w-6 text-blue-600'/>}
                      </div>
                    )}
                  />
                </div>
              </div>
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </>
  )
}

export default BlockCalendarSettings;