import { Dialog, Popover } from "@headlessui/react";
import { useRef, useState } from "react";
import ListExternalRelationCards from "../../Cards/Lists/ExternalRelationsList"
import BaseListRelationCards from "../../Cards/Lists/BaseFormRelationsList"
import { LinkItem } from '../../../core/graphql/types';
import { HiPlus } from 'react-icons/hi';
import { usePopper } from 'react-popper';
import BaseRelationList from "../../Cards/Lists/BaseFormRelationsList";


interface ModalRelationProps {
  open: boolean;
  close: () => void;
  boardId: string;
  fieldId: string;
  relations: LinkItem[];
  onChange: any;
  buttonRef: any;
  addNewCardToRelationBoard: any;
}

interface PopoverRelationProps {
  boardId: string;
  fieldId: string;
  foreginFieldId?: string;
  relations: LinkItem[];
  onChange: any;
}

interface PopoverFormRelationsProps {
  boardId: string;
  fieldId: string;
  viewId?: string | null;
  foreginFieldId?: string;
  relations: string[];
  onChange: any;
}

const ModalRelations = ({open, close, boardId, fieldId, relations, onChange, buttonRef, addNewCardToRelationBoard}: ModalRelationProps) => {
  let [popperElement, setPopperElement] = useState<any>(null)
  let { styles, attributes } = usePopper(
    buttonRef, 
    popperElement, 
    {
      placement: "bottom-end",
      modifiers: [
      {
        name: 'eventListeners',
        options: {
          scroll: false, // true by default
        },
      },
      {
        name: 'offset',
        options: {
          offset: [0, 8],
        },
      },
    ]})
  let refDiv = useRef(null)
  return (
    <Dialog 
      as="div"
      initialFocus={refDiv}
      open={open}
      onClose={close}
      ref={setPopperElement}
      style={styles.popper}
      {...attributes.popper}
    >
      {/* <div className="relative h-full flex items-center justify-center" > */}
        <Dialog.Overlay className="fixed inset-0 bg-transparent" ref={refDiv}/>
        <div 
          className="w-[32rem] inline-block h-[32rem] text-left align-middle transition-all transform bg-white shadow-lg rounded-md border"
        >
          <BaseRelationList 
            boardId={boardId}
            links={relations}
            // foreginFieldId={foreginFieldId}
            fieldId={fieldId}
            onChange={onChange}
            close={close}
            addNewCardToRelationBoard={addNewCardToRelationBoard}
            canAdd={true}
          />
        </div>
      {/* </div> */}
    </Dialog>
  )
};

function PopoverRelations({boardId, fieldId, foreginFieldId, relations, onChange}: PopoverRelationProps) {
  return (
    <Popover className="relative">
      <Popover.Button>
        <div className="bg-gray-300 w-5 h-5 flex items-center px-1 rounded-md">
          <HiPlus className="h-4 w-4" ></HiPlus>
        </div>
      </Popover.Button>

      <Popover.Panel className="absolute left" style={{zIndex: 21}}>
        {({ close }) => (
          <BaseRelationList
            boardId={boardId}
            links={relations}
            foreginFieldId={foreginFieldId}
            fieldId={fieldId}
            onChange={onChange}
            close={close}
            canAdd={false}
          />
        )}
      </Popover.Panel>
    </Popover>
  )
}




function PopoverFormRelations({boardId, fieldId, foreginFieldId, viewId, relations, onChange}: PopoverFormRelationsProps) {
  return (
    <Popover className="">
      <Popover.Button>
        <div className="bg-indigo-200 text-sm text-indigo-600 rounded py-1 space-x-2 flex items-center px-2">
          <HiPlus className="h-4 w-4" />
          <span>Добавить</span>
        </div>
      </Popover.Button>

      <Popover.Panel className="absolute left z-30 mt-3 w-full shadow-lg h-screen max-h-96 rounded-md transform px-4 sm:px-0 lg:max-w-3xl bg-white">
        {({ close }) => (
          <ListExternalRelationCards 
            boardId={boardId}
            viewId={viewId}
            relations={relations}
            foreginFieldId={foreginFieldId}
            fieldId={fieldId}
            onChange={onChange}
            close={close}
          />
        )}
      </Popover.Panel>
    </Popover>
  )
}


export {
  PopoverRelations, 
  ModalRelations,
  PopoverFormRelations, 
}; 