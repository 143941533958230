// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { orderBy, groupBy as rowGrouper } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import DataGrid, { CellClickArgs, CellMouseEvent, SortColumn, TreeDataGrid } from 'react-data-grid';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { BoardView, Field, ValuePayload, ViewAppearanceParamEnum, ViewAppearanceTypeEnum, useBlockblockChangeFieldWidthMutation, useChangeFieldWidthMutation } from '../../../core/graphql/types';
import { useBoard } from '../../../core/providers/board';
import { useSpaceStore } from '../../../core/store/store';
import { SmallSpinner } from '../../loaders';
import { MatrPopover } from '../../ui/portal';
import { makeColumns } from './columns';
import BatchContextMenu from './menus/BatchContextMenu';
import SingleContextMenu from './menus/SingleContextMenu';
import { calculateRowHeight, getComparator, makeRows } from './rows';
import { renderRow } from './rows/row';
import { Row, SummaryRow } from './types';

import 'react-data-grid/lib/styles.css';
import useDebouncedCallback from '../../../core/hooks/debounce/useDebouncedCallback';

function rowKeyGetter(row: Row) { return row.id;}

export default function MattrGrid() {
  const {initType, initId, block, inBlock, board, fields, cards, values, rows, columns, editMode, boardIsLoading, currentView, userCurrentView, updateValueInRow, setValueForUpdate, setColumns, setRows, setEditMode, cardModalIsOpened, fieldModalIsOpened, externalModalIsOpened, modal} = useBoard()
  const init: string = `${initType}:${initId}`
  const store = useSpaceStore()
  const {actionUserBlocksSet, actionUserViewsSet} = store.baseActions
  const [selectedRows, setSelectedRows] = useState<ReadonlySet<string>>(() => new Set());
  const [sortColumns, setSortColumns] = useState<readonly SortColumn[]>([]);
  const [groupColumns, setGroupColumns] = useState<readonly string[]>([]);
  const [expandedGroupIds, setExpandedGroupIds] = useState<ReadonlySet<unknown>>(
    () => new Set<unknown>([])
  );

  const [menuRef, setMenuRef] = useState<any>(null);
  const [contextMenuProps, setContextMenuProps] = useState<any>()
  const [isContextMenuOpen, setIsContextMenuOpen] = useState(false);

  const [,changeFieldWidth] = useChangeFieldWidthMutation()
  const [,blockChangeFieldWidth] = useBlockblockChangeFieldWidthMutation()


  useEffect(() => {
    if(!fields) {return}
    let hiddenFields = [...currentView?.hiddenFields!, ...userCurrentView?.hiddenFields!]
    let viewFields = 
      fields.filter((field: Field) => !hiddenFields.includes(field?.id!)).map(vf => {
        let w = userCurrentView.appearance?.find(a => a?.type === ViewAppearanceTypeEnum.Field && a.param === ViewAppearanceParamEnum.Width && a.id === vf.id)?.value
        return {...vf, width: w ? parseInt(w) : vf.width}
      })
    setColumns(makeColumns(viewFields, store.currentSpaceUser!, currentView?.permissions!), init);
  }, [fields, currentView?.hiddenFields, userCurrentView.hiddenFields]);

  useEffect(() => {
    if(!fields) {return}
    setRows(makeRows(cards, values, board!), init);
  }, [cards, values]);

  useEffect(() => {
    if(!userCurrentView || userCurrentView?.sorts?.length === 0) {
      setSortColumns([])
      return
    }

    let sortByFields: SortColumn[] | any = userCurrentView?.sorts!.map(s => ({columnKey: s?.fieldId, direction: s?.direction, type: s?.fieldType}))
    setSortColumns(sortByFields)
  }, [userCurrentView]);

  useEffect(() => {
    if(!userCurrentView || userCurrentView?.groups?.length === 0) {
      setGroupColumns([])
      return
    }

    let groupByFields: string[] = userCurrentView!.groups!.map(g => g?.fieldId!)
    setGroupColumns(groupByFields)
  }, [userCurrentView]);

  // 

  const onColumnResize = useDebouncedCallback(
    (idx, width) => {
      if(inBlock) {
        blockChangeFieldWidth({blockId: block?.id!, id: columns[idx].key, value: width.toString()}).then(r => {
          if(r.error) {return}
          actionUserBlocksSet(r.data?.blockChangeFieldWidth!)
        })
      } else {
        changeFieldWidth({viewId: (currentView as BoardView)?.id!, id: columns[idx].key, value: width.toString()}).then(r => {
          if(r.error) {return}
          actionUserViewsSet(r.data?.changeFieldWidth!)
        })
      }
    },
    1000
  );

  // ОБНОВЛЕНИЕ

  function onRowsChanged(rows: Row[], data: any) {
    setEditMode(false, init)

    setRows(rows, init)
    
    let r: any = rows[data.indexes[0]]
    let id: string = data.column.key
    if(data.column.type !== "RELATION") {
      let val: ValuePayload = r[id]
      updateValueInRow(val, data.indexes[0], init)
      setValueForUpdate(val, init)
    }
  }

  // СОРТИРОВКА

  const sortedRows = useMemo((): readonly Row[] => {
    if (!userCurrentView || !userCurrentView?.sorts || userCurrentView?.sorts?.length === 0) return rows;

    return [...rows].sort((a, b) => {
      for (const sort of userCurrentView?.sorts!) {
        const comparator = getComparator(sort?.fieldId!, sort?.fieldType!);
        const compResult = comparator(a, b);
        if (compResult !== 0) {
          return sort?.direction === 'ASC' ? compResult : -compResult;
        }
      }
      return 0;
    });
  }, [rows, userCurrentView]);

  // ГРУППИРОВКА

  function grouping(data: any) {
    const dirtyData = data.filter((it: Row) => it.id !== 'new')

    const sortByField = userCurrentView?.groups![userCurrentView?.groups!.length - 1];
    const dir = sortByField?.direction === 'ASC' ? 'asc' : 'desc'
    let ordered:any

    ordered = orderBy(dirtyData, item => item[sortByField?.fieldId!]?.value, dir)

    let groupedd = rowGrouper(ordered, item => 
      userCurrentView?.groups!.map(gb => (
        item[gb?.fieldId!]?.value
      ))
    )
    return groupedd
  }

  // СВОДНЫЙ

  const summaryRows = useMemo((): readonly SummaryRow[] => {
    let obj: any = {}
    fields.map((field: { id: string; type: string; }) => {
      obj[field.id] = rows.filter((row: any) => row.id !== 'new' && !!row[field.id]).map((row: any) => row[field.id]!)
    })
    const summaryRow: SummaryRow = obj
    return [summaryRow];
  }, [rows, editMode, fields]);

  // КОНТЕКСТНОЕ МЕНЮ

  function getContextMenu(props: CellClickArgs<Row, SummaryRow>, event: CellMouseEvent) {
    const { clientX, clientY } = event;
    setIsContextMenuOpen(true);
    setContextMenuProps(props)
    const virtualElement = {
      getBoundingClientRect: () => ({
        width: 0,
        height: 0,
        top: clientY,
        right: clientX,
        bottom: clientY,
        left: clientX
      })
    };

    setMenuRef(virtualElement)
  }

  function onCloseContextMenu() {
    setIsContextMenuOpen(false)
  }

  const gridElement = (
    <DataGrid
      columns={columns}
      onColumnResize={onColumnResize}
      rows={sortedRows}
      rowKeyGetter={rowKeyGetter}
      onRowsChange={onRowsChanged}
      renderers={{renderRow}}
      // onFill={handleFill}
      // onCopy={handleCopy}
      // onPaste={handlePaste}
      rowHeight={calculateRowHeight(userCurrentView?.options?.rowHeight!)}
      headerRowHeight={34}
      summaryRowHeight={40}
      selectedRows={selectedRows}
      onSelectedRowsChange={setSelectedRows}
      sortColumns={sortColumns}
      onSortColumnsChange={setSortColumns}
      className="rdg-light h-full fill-grid"
      bottomSummaryRows={summaryRows}
      // direction={direction}
      onCellContextMenu={(props, event) => {
        event.preventGridDefault();
        // Do not show the default context menu
        event.preventDefault();
        getContextMenu(props, event);
      }}
    />
  )

  const treeGridElement = (
    <TreeDataGrid
      className="rdg-light h-full"
      columns={columns}
      rows={sortedRows}
      rowHeight={calculateRowHeight(userCurrentView?.options?.rowHeight!)}
      rowKeyGetter={rowKeyGetter}
      selectedRows={selectedRows}
      onSelectedRowsChange={setSelectedRows}
      groupBy={groupColumns}
      rowGrouper={grouping}
      expandedGroupIds={expandedGroupIds}
      onExpandedGroupIdsChange={setExpandedGroupIds}
      sortColumns={sortColumns}
      onSortColumnsChange={setSortColumns}
    />
  )

  return (
    <>
      <div className="h-full overflow-hidden">
        {boardIsLoading ? <SmallSpinner /> : <DndProvider backend={HTML5Backend}>{groupColumns.length > 0 ? treeGridElement : gridElement}</DndProvider>}
      </div>
      {isContextMenuOpen && 
        <MatrPopover reference={menuRef} placement="bottom-start" onClose={onCloseContextMenu}>
          {selectedRows.size === 0 ? <SingleContextMenu onClose={onCloseContextMenu} {...contextMenuProps}/> : <BatchContextMenu />}
        </MatrPopover>
      }
      {fieldModalIsOpened && modal }
      {cardModalIsOpened && modal }
      {externalModalIsOpened && modal}
    </>
  )
}