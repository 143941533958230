import { BlockTypeEnum } from "../../../../../core/graphql/types";
import { usePanel } from "../../../../../core/providers/panel";
import RecordsListBlock from "../../blocks/BlockRecordsList";

export default function LayoutRecordsList() {
  const { page } = usePanel()

  return (
    <div className={`flex flex-col overflow-hidden w-full h-full`}>
      <div className="flex flex-col overflow-hidden w-full h-full relative">
        <RecordsListBlock block={page.blocks?.find(b => b?.isPage === true && b.type === BlockTypeEnum.BlockRecordsList)!}/>
      </div>
    </div>
  );
}