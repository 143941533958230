
import { useState } from 'react';
import { Column, RenderCellProps, RenderCheckboxProps, RenderGroupCellProps, RenderHeaderCellProps, useRowSelection } from 'react-data-grid';
import { HiOutlineArrowsExpand, HiOutlinePlusCircle } from 'react-icons/hi';
import { MdOutlineDragIndicator } from 'react-icons/md';
import { Card, CardWithValues } from '../../../../core/graphql/types';
import { useBoard } from '../../../../core/providers/board';
import CardModal from '../../../Cards';
import { CheckboxFormatter } from './checkbox';
import { Row } from '../types';

export const SELECT_COLUMN_KEY = 'select-row';

function ExpandFormatter(props: any) {
  const {initId, initType, inBlock, cards, values, currentView, setCardModalIsOpened, setModal, setCurrentCardId, setCurrentCardPrimaryKey, setCurrentCard} = useBoard()
  const init: string = `${initType}:${initId}`
  const [isHover, setIsHover] = useState(false);
  
  function close() {
    setCardModalIsOpened(false, init)
  }
  
  function showCardModal(props: any) {
    if(inBlock) {
      setCurrentCardId(props.row.id, init)
      setCurrentCardPrimaryKey(values.find(v => v.cardId === props.row.id && v.primary === true)?.id!, init)
      setCurrentCard({...(cards.find(c => c.id === props.row.id) as CardWithValues), values: values.filter(v => v.cardId === props.row.id)}, init)
      setCardModalIsOpened(true, init)
    } else {
      setCardModalIsOpened(true, init)
      setModal(<CardModal open={true} close={close} cardId={props.row.id} boardId={props.row.boardId} initiator="ROW"/>, init)
    }
  }

  return (
    <div className='flex items-center justify-center' onMouseEnter={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)}>
    {currentView?.permissions?.canEdit ?
      isHover ? 
        <div className="cursor-pointer flex h-full w-6" onClick={() => showCardModal(props)}>
          <HiOutlineArrowsExpand className="w-4 h-4 hover:text-indigo-600 text-gray-300"/>
        </div>
        :
        <div className='text-xs w-full text-center font-medium text-slate-400'>{(props.row as Row).position + 1}</div> 
    :
      <div className='text-xs w-full text-center font-medium text-slate-400'>{(props.row as Row).position + 1}</div> 
    }
    </div>
  );
}

function AddFormatter() {
  return (
    <>
      <div className="cursor-pointer flex items-center justify-end w-fill h-full mr-1.5">
        <HiOutlinePlusCircle className="w-4 h-4 hover:text-indigo-600 text-slate-600"/>
      </div>
    </>
  );
}

function SelectFormatter<R>(props: RenderCellProps<Row>) {
  const [isRowSelected, onRowSelectionChange] = useRowSelection();

  if((props.row as Row).id === 'new') {
    return <AddFormatter/>
  } else {
    return (
      <div className="flex flex-row items-start py-2 bg-slate-100 h-full">
        <div className='flex flex-row items-center justify-between w-full pr-2'>
          <MdOutlineDragIndicator size={18} className="hover:text-indigo-600 text-slate-300 cursor-move w-6 bg-whites rounded-tr-md rounded-br-md"/>
          <ExpandFormatter {...props}/>
          <SelectCellFormatter
            aria-label="Select"
            tabIndex={props.tabIndex}
            value={isRowSelected}
            onChange={(checked, isShiftClick) => {
              onRowSelectionChange({type: 'ROW', row: props.row, checked, isShiftClick });
            }}
          />
        </div>
      </div>
    );
  }


}

function SelectGroupFormatter(props: RenderGroupCellProps<unknown>) {
  const [isRowSelected, onRowSelectionChange] = useRowSelection();

  return (
    <div className='flex w-full h-full justify-end items-center px-2'>
      <SelectCellFormatter
        aria-label="Select Group"
        tabIndex={props.tabIndex}
        value={isRowSelected}
        onChange={(checked) => {
          onRowSelectionChange({type: 'ROW', row: props.row, checked, isShiftClick: false });
        }}
      />
    </div>
  );
}

function SelectHeaderFormatter(props: RenderHeaderCellProps<unknown>) {
  const [isRowSelected, onRowSelectionChange] = useRowSelection();

  return (
    <div className='flex w-full h-full justify-end items-center px-2'>
      <SelectCellFormatter
        aria-label="Select All"
        tabIndex={props.tabIndex}
        value={isRowSelected}
        onChange={(checked) => {
          onRowSelectionChange({ type: 'HEADER', checked });
        }}
      />
    </div>
  );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const SelectColumn: Column<any, any> = {
  key: SELECT_COLUMN_KEY,
  name: '',
  width: 80,
  minWidth: 80,
  maxWidth: 80,
  resizable: false,
  sortable: false,
  frozen: true,
  cellClass: "rdg-manage-cell",
  renderHeaderCell(props) {
    return (
      <SelectHeaderFormatter {...props} />
    );
  },
  renderCell: SelectFormatter,
  renderGroupCell: SelectGroupFormatter,
};


type SharedInputProps = Pick<
  RenderCheckboxProps,
  'disabled' | 'tabIndex' | 'aria-label' | 'aria-labelledby'
>;

interface SelectCellFormatterProps extends SharedInputProps {
  value: boolean;
  onChange: (value: boolean, isShiftClick: boolean) => void;
}

export function SelectCellFormatter({
  value,
  tabIndex,
  disabled,
  onChange,
  'aria-label': ariaLabel,
  'aria-labelledby': ariaLabelledBy
}: SelectCellFormatterProps) {


  return (
    <CheckboxFormatter
      aria-label={ariaLabel}
      aria-labelledby={ariaLabelledBy}
      tabIndex={tabIndex}
      disabled={disabled}
      checked={value}
      onChange={onChange}
    />
  );
}