import {Fragment, useRef} from "react";
import { Dialog, Transition } from "@headlessui/react";
import { HiOutlineX, HiOutlineXCircle } from "react-icons/hi";
import { BoardForm } from "../../../../core/graphql/types";
import TelegramExternalForm from "../../../Forms/ExternalForm/telegram";
import { useTelegram } from "../../../../core/hooks/useTelegram";

// Приходит запись, она может быть новой или старой, приходит функция для изменения списка связей и внешняя доска

interface TelegramFormModalProps {
  open: boolean;
  close: () => void;
  form: BoardForm;
}


const TelegramFormModal = ({open, close, form}:TelegramFormModalProps) => {
  const {onClose} = useTelegram()
  let refDiv = useRef(null)

  const closeAll = () => {
    close()
    onClose()
  }

  return (
    <Transition show={open} as={Fragment}>
      <Dialog 
        as="div"
        className="fixed inset-0 z-20"
        initialFocus={refDiv}
        open={open}
        onClose={() => close()}
      >
        <div className="h-screen w-screen flex" style={{zIndex: new Date().getTime()}}>
          <Dialog.Overlay className="fixed inset-0 telegram_bg_color" ref={refDiv}/>
          <div className="flex flex-col w-screen h-screen text-left transition-all transform">
            <div className="flex shrink-0 flex-row items-center h-16 telegram_bg_color justify-between px-4">
              <div className="flex flex-row h-full items-center font-medium telegram_text_color">
                {form.title}
              </div>
              <div className="text-sm telegram_text_color hover:text-indigo-600" onClick={() => closeAll()}>
                <HiOutlineX size={24}/>
              </div>
            </div>
            <div className="w-full h-full px-2">
              <TelegramExternalForm form={form} close={close}/>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
};

export default TelegramFormModal;