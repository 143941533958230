import { BsPlusSquareFill } from "react-icons/bs";
import { useFetcherContext } from "../../../core/providers/fetcher";
import RequestPopper from "./poper";



const List = () => {
  const {requests, request, setCurrentRequestId, deleteRequest} = useFetcherContext()

  return (
    <div className="flex h-full overflow-scroll">
      <div className="flex flex-col w-full">
        {requests?.length > 0 && requests.map(item => (
          <div key={item.id} className={`px-4 cursor-pointer items-start justify-between flex relative w-full border-b ${request && request.id === item.id ? "bg-slate-200 text-white" : "text-gray-800"}`}>
            <div className="flex  flex-col w-full" onClick={() => setCurrentRequestId(item.id!)}>
              <div className="flex justify-between items-center w-full ">
                <div className="flex flex-row items-center">
                  <div className="text-xs text-gray-600 uppercase mr-2">{item.method}</div>
                  <span className="text-sm text-gray-800 truncate">{item.name}</span>
                </div>
                <RequestPopper request={item} onRemove={deleteRequest}/>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default List;