import _ from "lodash";
import PageBlock from "../..";
import { Block, BlockFieldOptions, BlockSourceField, BlockTypeEnum, FieldType } from "../../../../../../core/graphql/types";
import { usePanel } from "../../../../../../core/providers/panel";
import { DragDropContext, Draggable, DropResult, Droppable, ResponderProvided } from "react-beautiful-dnd";
import {BlockBaseControls, BlockRelationFieldControls} from "../../Controls";
import { TbDragDrop } from "react-icons/tb";

const reorder = (list: Block[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

export function SimpleFormBlockEdit({formBlocks, setFormBlocks}: {formBlocks: Block[], setFormBlocks: (blocks: Block[]) => void}) {
    const {block, editorMode, changeCurrentBlock, updateBlocksPosition} = usePanel()

    function onDragEnd(result: DropResult, provided: ResponderProvided) {
        // dropped outside the list
        if (!result.destination) { return; }
    
        const items = reorder(
          formBlocks!,
          result.source.index,
          result.destination.index
        );
        updateBlocksPosition(items?.map((item, index) => ({id: item?.id!, x: index!, y: item?.position?.y!, w: item?.position?.w!, h: item?.position?.h!})))
    }

    return (
        editorMode ? 
            <div className={`w-full max-w-[900px] mx-auto space-y-2 flex flex-col`}>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                  {(provided, snapshot) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      className={`flex-1 flex flex-col items-start w-full px-2 ${snapshot.isDraggingOver ? "bg-blue-100" : ""}`}
                    >
                      {(_.orderBy(formBlocks, item => item?.position?.x, "asc")).map((item, index) => (
                        <Draggable key={item?.id} draggableId={item?.id!} index={index}>
                          {(provided, snapshot) => (
                            <div 
                              className={
                                `mx-auto bg-white hover:bg-slate-100 relative p-2 w-full mb-2
                                ${item?.position?.w! < 13 ? 'max-w-[900px]' : ''}
                                ${block?.id === item?.id ? "ring-blue-500 ring-2 ring-inset hover:ring-blue-600" : ""}
                                ${snapshot.isDragging ? "bg-blue-200" : ""}
                                `} 
                              onClick={(event) => changeCurrentBlock(event, item)}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                            >
                              {block?.id === item?.id ? item.type === BlockTypeEnum.BlockField && (item.source as BlockSourceField).field?.type === FieldType.Relation ? <BlockRelationFieldControls block={item}/> : <BlockBaseControls block={item}/> : <></>}
                              <div {...provided.dragHandleProps} className="absolute -left-6 top-1 w-6 h-6 flex items-center justify-center text-slate-400">
                                <TbDragDrop size={18}/>
                              </div>
                              <PageBlock pageBlock={item}/>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
            :
            <div className={`max-w-full w-full mx-auto space-y-2 flex flex-col`}>
              {(_.orderBy(formBlocks, item => item?.position?.x, "asc")).map(item => (
                <div key={item.id} 
                  className={
                  `mx-auto bg-white relative py-2 px-4 w-full mb-2
                  ${item?.position?.w! < 13 ? 'max-w-[900px]' : 'w-full'}
                  `} 
                >
                  <PageBlock pageBlock={item}/>
                </div>
              ))}
            </div>
    )
}