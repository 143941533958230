import React, {useState, useEffect, useCallback} from "react";
import { BoardView, RowHeightType, ViewType, useCreateBoardViewMutation, useDeleteBoardViewMutation, useUpdateBoardViewMutation } from "../../../../core/graphql/types";

import { useForm, FormProvider, useWatch } from "react-hook-form";

import { TbSquaresFilled, TbX } from "react-icons/tb";
import { useBoard } from "../../../../core/providers/board";
import ViewForm from "./form";

import * as Yup from 'yup';
import { debounce } from "debounce";
import useDeepCompareEffect from "use-deep-compare-effect";
import { useSpaceStore } from "../../../../core/store/store";


const viewTypes = [
  {label: 'Таблица', value: ViewType.Grid},
  {label: 'Календарь', value: ViewType.Calendar},
  {label: 'Канбан', value: ViewType.Kanban},
  {label: 'Список', value: ViewType.List}
]

type BoardViewsProps = {
  type: "create" | "edit";
  close: () => void;
}

const validationSchema = Yup.object().shape({
  name: Yup.string().required()
});

const initialViewState: any = {
  id: "",
  name: "",
  type: ViewType.Grid, 
  hiddenFields: [], 
  filters: [], 
  sorts: [],
  groups: [],
  options: {rowHeight: RowHeightType.Short},
  permissions: {canAdd: true, canEdit: true, canEditInline: true, canFilter: true, canSort: true, canGroup: true}
}

const BoardViewSettings: React.FC<BoardViewsProps> = ({close, type}) => { 
  const {initId, initType} = useBoard()
  const init: string = `${initType}:${initId}`
  const store = useSpaceStore()
  const {board, currentView} = store.data[init];
  const methods = useForm({
    defaultValues: type === "create" ? initialViewState : currentView!
  });
  const { control, getValues, formState } = methods;
  const [ error, setError ] = useState(null);
  const [ ,createBoardView ] = useCreateBoardViewMutation();
  const [ ,updateBoardView ] = useUpdateBoardViewMutation();
  const [ ,deleteBoardView ] = useDeleteBoardViewMutation();

  const onSubmit = () => { 
    validationSchema.isValid(getValues()).then(res => {
      if(res) {
        let data = getValues()
        setError(null)
        if(type === "create") {
          createBoardView(
            {
              boardId: board?.id!,
              name: data.name,
              type: data.type,
              filters: data.filters,
              hiddenFields: data.hiddenFields,
              options: data.options,
              permissions: data.permissions
            }
          ).then(res => {
            res.data?.createBoardView?.status === 'ok' && close()
          })
        } else {
          updateBoardView(
            {
              viewId: (currentView as BoardView)?.id!,
              boardId: board?.id!,
              name: data.name,
              type: data.type,
              filters: data.filters,
              hiddenFields: data.hiddenFields,
              options: data.options,
              permissions: data.permissions
            }
          ).then(res => {
            res.data?.updateBoardView?.status === 'ok' && close()
          })
        }
      } else {
        validationSchema.validate(getValues()).catch(res => setError(res.message))
      }
    })
  };

  function onDelete() {
    if((currentView as BoardView)?.primary) {return}
    deleteBoardView({
      viewId: (currentView as BoardView)?.id!,
      boardId: board?.id!
    }).then(res => {
      if(res.data?.deleteBoardView?.status === 'ok') { 
        close()
      }
    })
  }

  return (
    <>
      <div className="flex items-center justify-between">
        <div className="flex items-center space-x-2">
          <div className="py-1 px-2 bg-slate-100 flex space-x-2 rounded">
            <TbSquaresFilled size={20} className="text-indigo-600"/>
            <div className="flex font-medium text-sm">{board?.name}</div>
          </div>
          <div className="flex font-normal text-sm">Создание вида</div>
        </div>
        <button onClick={() => close()}><TbX size={18}/></button>
      </div>
      
      <FormProvider {...methods}>
        <ViewForm types={viewTypes}/>
        <div className="flex w-full h-full justify-end pt-4 space-x-4">
          {!(currentView as BoardView)?.primary && <button type="button" onClick={() => onDelete()} className="rounded text-sm py-2 px-4 bg-slate-200 text-slate-400 hover:bg-slate-300 hover:text-slate-500">Удалить</button>}
          <button type="button" onClick={() => onSubmit()} className="rounded text-sm py-2 px-4 bg-indigo-600 text-white hover:bg-indigo-700">Сохранить</button>
        </div>
      </FormProvider>
    </>
  );

}

export default BoardViewSettings;