import { useEffect, useRef, useState } from "react";
import useDeepCompareEffect from "use-deep-compare-effect";
import CardWithBlockForm from "../../../../../components/Cards/Modals/cardWithBlockForm";
import { Block, BlockFormOptions, BlockFormTypeEnum, BlockGridOptions, BlockTypeEnum, Field, useBlockChangeFiltersMutation, useBlockChangeGroupsMutation, useBlockChangeHiddenFieldsMutation, useBlockChangeRowHeightMutation, useBlockChangeSearchMutation, useBlockChangeSortsMutation } from "../../../../../core/graphql/types";
import { usePanel } from "../../../../../core/providers/panel";
import { useSpaceStore } from "../../../../../core/store/store";
import HiddenFieldsDropdown from "../../../../../components/Views/ViewsMenu/fields";
import BoardFiltersDropdown from "../../../../../components/Views/ViewsMenu/filters";
import RowHeight from "../../../../../components/Views/ViewsMenu/row_height";
import GroupByDropdown from "../../../../../components/Views/ViewsMenu/group";
import SortByDropdown from "../../../../../components/Views/ViewsMenu/sort";
import { TbRowInsertBottom, TbSearch } from "react-icons/tb";

export function BlockKanbanActions({initiator}: { initiator: {type: string, id: string} }) {
  const { id: initId, type: initType } = initiator
  const init: string = `${initType}:${initId}`
  const store = useSpaceStore()
  const {page} = usePanel()
  const { actionUserBlocksSet } = store.baseActions
  const { setCardModalIsOpened, setCurrentCardId, setCurrentCard, setUserCurrentView } = store.boardActions
  const {block, fields, currentView, currentCard, cardModalIsOpened} = store.data[init]

  let ref = useRef(null)
  const [ viewFields, setViewFields ] = useState<Field[] | any>([]);

  const [, changeGroupBy] = useBlockChangeGroupsMutation()
  const [, changeSortBy] = useBlockChangeSortsMutation()
  const [, showHideCustomFields] = useBlockChangeHiddenFieldsMutation()
  const [, changeViewCustomFilters] = useBlockChangeFiltersMutation()
  const [, changeRowHeight] = useBlockChangeRowHeightMutation()
  const [, changeSearch] = useBlockChangeSearchMutation()

  let [createForm, setCreateForm] = useState<Block>()
  let [updateForm, setUpdateForm] = useState<Block>()
  let [addCard, setAddCard] = useState<boolean>(false)

  useDeepCompareEffect(() => {
    if(!block.id) { return }
    setCreateForm(page.blocks?.find(b => b?.parentId === block?.id && b?.type === BlockTypeEnum.BlockForm && (b?.options as BlockFormOptions).formType === BlockFormTypeEnum.Create)!)
    setUpdateForm(page.blocks?.find(b => b?.parentId === block?.id && b?.type === BlockTypeEnum.BlockForm && (b?.options as BlockFormOptions).formType === BlockFormTypeEnum.Edit)!)
  },[page])

  useEffect(() => {
    if(!fields) { return }
    const vf = fields?.filter((f:any) => !currentView?.hiddenFields!.includes(f.id))
    setViewFields(vf)
  }, [fields])


  function closeCardModal() {
    setAddCard(false)
    setCardModalIsOpened(false, init)
    setCurrentCardId(null, init)
    setCurrentCard(null, init)
  }

  function showAddCard() {
    setAddCard(true)
    setCardModalIsOpened(true, init)
  }


  function onChangeFieldsVisible(fields: any) {
    showHideCustomFields({
        blockId: block.id,
        hiddenFields: fields
    }).then(res => {
      actionUserBlocksSet(res.data?.blockChangeHiddenFields!)
      setUserCurrentView(res.data?.blockChangeHiddenFields!, init)
    })
  }
  
  function onChangeViewFilters(state: any) {
    changeViewCustomFilters({
      blockId: block.id,
      filters: state
    }).then(res => {
      actionUserBlocksSet(res.data?.blockChangeFilters!)
      setUserCurrentView(res.data?.blockChangeFilters!, init)
    })
  }

  function onChangeGroupBy(state: any) {
    changeGroupBy({
      blockId: block.id,
      groups: state
    }).then(res => {
      actionUserBlocksSet(res.data?.blockChangeGroups!)
      setUserCurrentView(res.data?.blockChangeGroups!, init)
    })
  }

  function onChangeSortBy(state: any) {
    changeSortBy({
      blockId: initId,
      sorts: state
    }).then(res => {
      actionUserBlocksSet(res.data?.blockChangeSorts!)
      setUserCurrentView(res.data?.blockChangeSorts!, init)
    })
  }

  function onChangeRowHeight(state: any) {
    changeRowHeight({
      blockId: initId,
      rowHeight: state
    }).then(res => {
      actionUserBlocksSet(res.data?.blockChangeRowHeight!)
      setUserCurrentView(res.data?.blockChangeRowHeight!, init)
    })
  }

  function onChangeSearch(state: any) {
    changeSearch({
      blockId: initId,
      searchTerm: state
    }).then(res => {
      actionUserBlocksSet(res.data?.blockChangeSearch!)
      setUserCurrentView(res.data?.blockChangeSearch!, init)
    })
  }

  return (
    <div className={`flex justify-between items-center ${block.isPage ? "px-4" : ""}`}>
      <div className="w-full h-10">
        {(block.options as BlockGridOptions).showTitle ? 
          <div className="w-full h-full">
            <div className="flex-none h-full flex w-full text-lg text-slate-800 font-semibold items-center truncate">{(block.options as BlockGridOptions)?.title!}</div>
          </div> 
          :
          <></>
        }
      </div> 
      <div className="flex-none h-10 flex items-center font-semibold justify-end">
        <div className="flex h-full items-center space-x-4">
          {block.permissions?.canAdd && 
            <div>
              <button type="button" className="h-7 text-sm font-medium flex items-center px-1.5 space-x-2 rounded-sm bg-slate-200 text-slate-700 hover:bg-slate-300 hover:text-slate-800" onClick={() => showAddCard()}><TbRowInsertBottom size={18}/></button>
            </div>
          }
          
          {block.permissions?.canFilter &&
            <div className="space-x-1 flex">
              <BoardFiltersDropdown viewFields={viewFields} onChange={onChangeViewFilters}/>
            </div>
          }

          {(block.permissions?.canSort || block.permissions?.canGroup) &&
            <div className="space-x-1">
              {block.permissions?.canSort && <GroupByDropdown viewFields={viewFields} onChange={onChangeGroupBy}/>}
              {block.permissions?.canGroup && <SortByDropdown viewFields={viewFields} onChange={onChangeSortBy}/>}
            </div>
          }

          {block.permissions?.canSearch &&
            <button className="h-7 text-sm font-medium flex items-center px-1.5 space-x-2 rounded-sm bg-slate-200 text-slate-700 hover:bg-slate-300 hover:text-slate-800"><TbSearch size={18}/></button>
          }
        </div>
        {cardModalIsOpened && addCard && block.permissions?.canAdd && <CardWithBlockForm open={cardModalIsOpened} close={closeCardModal} block={createForm!} />}
        {cardModalIsOpened && !!currentCard && block.permissions?.canEdit && <CardWithBlockForm open={cardModalIsOpened} card={currentCard} close={closeCardModal} block={updateForm!} />}
      </div>
    </div>
  )
}