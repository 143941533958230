import { useEffect, useState } from "react";
import { HiOutlineXCircle } from "react-icons/hi";
import { transformValueToString } from "../../../core/Controllers/Base";
import { makeRow } from "../../../core/Controllers/Grid";
import { Field, useGetCardQuery, useGetFieldsForCardQuery } from "../../../core/graphql/types";
import { useSpace } from "../../../core/providers/space";
import CardEdit from "../../Cards/Form";
import { MatrCardDialog } from "../../ui/portal";
import { Row } from "../../Views/MattrGrid/types";

// Приходит запись, она может быть новой или старой, приходит функция для изменения списка связей и внешняя доска

interface CardOutBoardProps {
  open: boolean;
  close: () => void;
  boardId: string;
  cardId?: string;
  fromFieldId: string; // Это чтобы ограничить по виду
  fromView?: string; // Откуда открываем
}

const CardOutBoard = ({open, close, boardId, cardId, fromFieldId}:CardOutBoardProps) => {
  let {boards} = useSpace();
  let boardName = boards.find(b => b.id === boardId)?.name || ""
  let [cardPrimaryValue, setCardPrimaryValue] = useState<string | null>(null)
  const [ fields, setFields ] = useState<Field[]>([])
  const [ card, setCard ] = useState<Row>()
  const [ loading, setLoading ] = useState(true);
  
  const [{data: dataFields}, getFields] = useGetFieldsForCardQuery({variables: {boardId: boardId, fromFieldId: fromFieldId}, pause: true})
  const [{data: dataCard}, getCard] = useGetCardQuery({variables:{id: cardId!}, pause: true})

  useEffect(()=>{
    if(!boardId || !cardId) {return}
    getFields()
    getCard()
  },[boardId, cardId])



  useEffect(() => {
    if(!dataFields?.getFieldsForCard || !dataFields?.getFieldsForCard.length) {return}
    if(!dataCard?.getCard) {return}

    setFields(dataFields?.getFieldsForCard as Field[])
    let primaryFieldId = dataFields?.getFieldsForCard!.find(f => f?.primary! === true)?.id
    // @ts-ignore
    let name = transformValueToString(dataCard?.getCard?.values.find(v => v?.fieldId === primaryFieldId)!)
    setCardPrimaryValue!(name ? name : null)
    setCard(makeRow({ card: dataCard?.getCard, values: dataCard?.getCard.values,  boardId: boardId}))
    setLoading(false)
  },[dataFields?.getFieldsForCard, dataCard?.getCard])


  return (
    <MatrCardDialog open={open} onClose={close}>
      <div className="relative flex flex-col max-w-4xl w-full h-full text-left overflow-hidden transition-all transform bg-white shadow-lg rounded-lg">
        <div className="flex flex-row items-center h-16 border-b bg-slate-100 justify-between px-4">
          <div className="flex flex-col py-3 pl-16 h-full">
            <span className="text-md font-medium truncate h-8 text-slate-800">{loading ? "..." : cardPrimaryValue}</span>
            <span className="text-xs text-slate-400">Доска: {boardName}</span>
          </div>
          <div className="text-sm text-slate-400 hover:text-indigo-600" onClick={() => close()}>
            <HiOutlineXCircle size={18}/>
          </div>
        </div>
        {!loading && <CardEdit row={card} fields={fields}/>}
      </div>
    </MatrCardDialog>
  )
};

export default CardOutBoard;