import type { FormFieldEditorProps } from './types';
import { UrlIcon } from "../../icons";
import { Controller, useFormContext } from 'react-hook-form';


export default function FormUrlEditor({formField, value}: FormFieldEditorProps) {
  const methods = useFormContext();
  const {control} = methods;

  return (
    <div className="relative flex flex-col">
      <div className="flex items-center h-full space-x-2">
        <div className="text-slate-400"><UrlIcon /></div>
        <label className="font-medium text-indigo-600 text-sm">{formField.name} {formField.required ? <span className="text-red-600">*</span> : ""}</label>
      </div>
      <div className="flex mt-2">
        <Controller
          key={formField.id}
          name={`${formField.fieldId}`}
          defaultValue={value ? value : ""}
          control={control}
          rules={{required: formField.required}}
          render={({ field }) => (
            <input {...field} className="matr-form-input"/>
          )}
        />
      </div>
    </div>
  );
}