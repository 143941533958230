import { useState } from "react";
import List from "./list";

import { useAuth } from "../../core/providers/auth";
import { useFetcherContext } from "../../core/providers/fetcher";
import { useSidebar } from "../../core/providers/sidebar";
import CreateModal from "./modals/create";
import Request from "./request";
import Response from "./response";
import { TbDots } from "react-icons/tb";
import { BsPlusSquareFill } from "react-icons/bs";
import EditFetcher from "./form/edit";

function FetcherContainer(): JSX.Element {
  const auth = useAuth();
  const {fetcher, request, setCurrentRequestId, setRequest} = useFetcherContext();
  const [openCreateModal, setOpenCreateModal] = useState(false);

  function showCreateModal() {setOpenCreateModal(true)}
  function closeCreateModal() {setOpenCreateModal(false)}

  function showSettings() {
    setRequest(null)
    setCurrentRequestId(null)
  }

  return (
    <div className="h-full overflow-hidden">
      <div className='flex-none flex w-full h-14 items-center text-base px-4 justify-between border-b'>
        <div className="flex w-full justify-between">
          <div className="flex items-center font-medium text-base text-slate-800">
            <span className="px-1">{auth.currentUser.space.name}</span>
            <span className="px-1">/</span>
            <span className="px-1">{fetcher?.title}</span>
            <span className="px-1">/</span>
            <span className="px-1">{request?.name}</span>
          </div>
        </div>
      </div>
      <div className="flex h-full">
        <div className="w-72 flex-none flex-col h-full">
          <div className='h-full flex flex-col border-r overflow-hidden'>
            <div className="flex shrink-0 h-[46px] items-center bg-slate-100 border-b">
              <div className="flex px-4 w-full items-center justify-between">
                <div className="flex items-center space-x-2 hover:text-indigo-600 cursor-pointer" onClick={() => showSettings()}>
                  <div className="font-medium text-sm text-slate-600">{fetcher?.title}</div>
                </div>
                <div className="flex h-10 items-center">
                  <BsPlusSquareFill className="w-4 h-4 text-indigo-600"  onClick={() => showCreateModal()}/>
                </div>
              </div>
            </div>
            <List />
          </div>
        </div>
        {request ? 
          <div className="flex-1 w-full h-full">
            <div className="flex flex-col h-full relative">
              <Request />
              <Response /> 
            </div>
          </div>
          : 
          <div className="w-full h-full flex mx-auto max-w-[900px]">
            <EditFetcher />
          </div>
        }

      </div>
      {openCreateModal && <CreateModal open={openCreateModal} close={closeCreateModal}/>}
    </div>
  );
}
export default FetcherContainer;