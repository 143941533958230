import React, { useEffect } from "react";
import { Board, useImportBoardMutation } from "../../../../core/graphql/types";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import toast from 'react-hot-toast';
import Select from "react-select";
import { customStyles } from "../../../../utils";
import { useSpaceStore } from "../../../../core/store/store";
import { TbSquaresFilled, TbX } from "react-icons/tb";

export type IBoardFormCreateProps = {
  onCreate: (board: Board) => void;
  closeModal: () => void;
}


export const BoardFormImport: React.FC<IBoardFormCreateProps> = ({closeModal, onCreate}) => {
  const {groups, space} = useSpaceStore()
  const { handleSubmit, register, control, formState: { errors } } = useForm();
  const navigate = useNavigate();
  const [{fetching: loadingBoard}, importBoard] = useImportBoardMutation();

  const onSubmit = handleSubmit(data => {
    toast.promise(
      importBoard({name: data.name, file: data.file[0], groupId: data.group.id}).then(result => {
        onCreate(result.data?.importBoard!)
        closeModal();
        return result
      }),
      {
        loading: 'Импортирую...',
        success: (data) => {
          if (data.error) throw new Error();
          navigate(`boards/${data.data?.importBoard?.id}`)
          return 'Файл импортирован';
        },
        error: 'Ошибка. При импорте файла',
      }
    );
  });

  return (
    <>
      <div className="flex items-center justify-between">
        <div className="flex items-center space-x-2">
        <div className="py-1 px-2 bg-slate-100 flex space-x-2 rounded">
            <TbSquaresFilled size={20} className="text-indigo-600"/>
            <div className="flex font-medium text-sm">{space?.name}</div>
        </div>
        <div className="flex font-normal text-sm">Импорт таблицы</div>
        </div>
        <button onClick={() => closeModal()}><TbX size={18}/></button>
      </div>
      <form onSubmit={onSubmit} className="flex flex-col relative w-full space-y-4 ">
        <div className="relative space-y-1.5 mt-4">
          <input 
            {...register("name", {required: true})} 
            className="text-xl font-medium text-slate-800 placeholder:text-slate-300 focus:outline-none mb-4 w-full" 
            placeholder="Название таблицы"
          />
          {errors.name && <span className="text-red-600 text-xs font-normal">"Название" обязательное поле.</span>}
        </div>
        <div className="relative">
          <label className="matr-label">Группа:</label>
          <Controller
            name="group"
            defaultValue={groups?.find(g => g?.isRoot === true)}
            control={control}
            render={({ field: { onChange, value }}) => (
              <div className="mt-2">
                <Select
                  styles={customStyles}
                  classNamePrefix="matr_select_form"
                  defaultValue={groups?.find(g => g?.isRoot === true)}
                  getOptionLabel={(option) => option?.name}
                  getOptionValue ={(option)=> option?.id}
                  value={value}
                  onChange={onChange}
                  options={groups ? groups : []}
                />
              </div>
            )}
          />
        </div>
        <div>
          <label className="font-medium text-gray-600 text-sm">Выберите файл:</label>
          <div className="text-xs text-gray-600">Вы можете импортировать файлы: .xlsx</div>
          <input type={"file"} {...register("file", { required: true })}
            className="text-sm form-input mt-2 py-2.5 px-2 block w-full rounded-sm bg-gray-100 border-0 focus:bg-white focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          />
          {errors.file && <span className="text-red-600 text-xs font-normal">"Файл" обязательное поле.</span>}
        </div>
        <div className="flex justify-between w-full pt-6">
          <button
            type="button"
            className="inline-flex justify-end px-3 py-1.5 text-sm font-medium text-indigo-700 bg-indigo-100 border border-transparent rounded-md hover:bg-indigo-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-indigo-500"
            onClick={closeModal}
          >Отменить</button>
          <button
            type="submit"
            disabled={loadingBoard}
            className="inline-flex justify-center px-3 py-1.5 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md hover:bg-indigo-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-indigo-500"
          > 
            {loadingBoard && <span>Импортирую...</span>}
            {!loadingBoard && <span>Импортировать</span>}
          </button>
        </div>
      </form>
    </>
  )
};