import { useEffect, useState } from "react";
import type { FieldPrimitiveEditorProps } from './types';
import { StringValue} from "../../../core/graphql/types"
import { TextareaIcon } from "../../icons";
import PlainTextEditor from "../../ui/TextEditors/PlainTextEditor";
import RichTextEditor from "../../ui/TextEditors/RichTextEditor";
import { transformValueToString } from "../../../core/Controllers/Base";


export default function FieldTextarea({field, card, updatePrimitive}: FieldPrimitiveEditorProps) {
  const [value, setValue] = useState(card[field.id]?.valueAsString &&  JSON.parse(card[field.id].valueAsString!))
  const attr = field.attributes;
  const [tokens, setTokens] = useState<any[] | null>(null)

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    updatePrimitive(
      { ...card, [field.id]: {...card[field.id], valueAsString: JSON.stringify(e)}}, 
      ({...card[field.id], valueAsString: JSON.stringify(e)} as unknown as StringValue), 
      {valueAsString: JSON.stringify(e)}
    )
    setValue(JSON.stringify(e))
  }

  useEffect(() => {
    const tok = Object.values(Object.fromEntries(Object.entries(card as {}).filter(([key]) => !["id", "boardId", "position"].includes(key)))).filter((i: any) => !["TEXTAREA"].includes(i.type)).map((i:any) => ({
      id: i.id,
      value: transformValueToString(i),
      data: {
        type: "editor",
        source: "internal",
        sourceId: i.boardId, 
        valueType: i.type,
        boardId: i.boardId,
        fieldId: i.fieldId,
        cardId: i.cardId,
        function: null,
      }
    }));
    setTokens(tok)
  },[card])

  return (
    <div className="relative flex flex-col space-y-1.5">
      {!attr.editor ? 
        <>
          <div className="flex items-center h-full">
            <div className="text-slate-400 pr-2"><TextareaIcon/></div>
            <label className="text-slate-500 text-sm">{field.title}</label>
          </div>
          <PlainTextEditor value={value} onChange={handleChange}/>
        </>
        : 
        <>
          <div className="flex items-center">
            <div className="text-slate-400 pr-2"><TextareaIcon/></div>
            <label className="text-slate-500 text-sm">{field.title}</label>
          </div>
          {tokens && <RichTextEditor value={value} onChange={handleChange} tokens={tokens}/>}
        </>
      }
    </div>
  );
}