import { DateTime, Duration } from "luxon";
import { Datetime, DatetimeValue, Field, FieldAttributes, FieldType, JsonArrayValue, JsonValue, MattrTypes, NumberValue, StringValue, ValuePayload, Duration as DurationType, Percent, Number, Decimal, Currency, BooleanValue, LinkItem, Maybe, Map } from "../../../../core/graphql/types"
import { hashCode, hexToRGB, intToRGB } from "../../../../utils";
import NumberFormat from "react-number-format";
import { TbSquare, TbSquareCheckFilled } from "react-icons/tb";

interface ValueShowProps {
  type: FieldType | MattrTypes,
  fieldAttributes: FieldAttributes,
  value: ValuePayload | any;
}

function isValuePayload(value: ValuePayload | string | number) {
  if(typeof value === 'object') {
    return 'type' in value ? true : false
  } else {
    return false
  }
}

export default function ValueShow({type, fieldAttributes, value}: ValueShowProps) {
  if(value) {
  switch (type) {
    case MattrTypes.String:
      return (
        isValuePayload(value) ? 
          <ShowString value={(value as StringValue).valueAsString!} />
        : 
          <ShowString value={value} />
      ) 
    case MattrTypes.Url:
      return (
        isValuePayload(value) ? 
          <ShowUrl value={(value as StringValue).valueAsString!} />
        : 
          <ShowUrl value={value} />
      )
    case MattrTypes.Email:
      return (
        isValuePayload(value) ? 
          <ShowEmail value={(value as StringValue).valueAsString!} />
        : 
          <ShowEmail value={value} />
      )
    case MattrTypes.Number:
      return (
        isValuePayload(value) ? 
          <ShowNumber value={(value as NumberValue).valueAsNumber!} fieldAttributes={fieldAttributes}/>
        : 
          <ShowNumber value={value} fieldAttributes={fieldAttributes}/>
      )
    case MattrTypes.Phone:
      return (
        isValuePayload(value) ? 
          <ShowPhone value={(value as NumberValue).valueAsNumber!} fieldAttributes={fieldAttributes}/>
        : 
          <ShowPhone value={value} fieldAttributes={fieldAttributes}/>
      )
    case MattrTypes.Duration:
      return (
        isValuePayload(value) ? 
          <ShowDuration value={(value as NumberValue).valueAsNumber!} fieldAttributes={fieldAttributes}/>
        : 
          <ShowDuration value={value} fieldAttributes={fieldAttributes}/>
      )
    case MattrTypes.Decimal:
      return (
        isValuePayload(value) ? 
          <ShowDecimal value={(value as NumberValue).valueAsNumber!} fieldAttributes={fieldAttributes}/>
        : 
          <ShowDecimal value={value} fieldAttributes={fieldAttributes}/>
      )
    case MattrTypes.Percent:
      return (
        isValuePayload(value) ? 
          <ShowPercent value={(value as NumberValue).valueAsNumber!} fieldAttributes={fieldAttributes}/>
        : 
          <ShowPercent value={value} fieldAttributes={fieldAttributes}/>
      )
    case MattrTypes.Currency:
      return (
        isValuePayload(value) ? 
          <ShowCurrency value={(value as NumberValue).valueAsNumber!} fieldAttributes={fieldAttributes}/>
        : 
          <ShowCurrency value={value} fieldAttributes={fieldAttributes}/>
      )
    case MattrTypes.Checkbox:
      return (
        isValuePayload(value) ? 
          <ShowCheckbox value={(value as BooleanValue).valueAsBool!}/>
        : 
          <ShowCheckbox value={value}/>
      )
    case MattrTypes.Recordid: return  isValuePayload(value) ? <div>{value.value}</div> : <div>{value}</div>
    case MattrTypes.Formula: return  isValuePayload(value) ? <div>{value?.value}</div> : <div>{value}</div>
    
    case MattrTypes.Select:
    case MattrTypes.UpdatedBy:
    case MattrTypes.CreatedBy: return (
      isValuePayload(value) ? 
        <ShowMap value={(value as JsonValue).valueAsJson!} fieldAttributes={fieldAttributes} /> 
      : 
        <ShowMap value={value} fieldAttributes={fieldAttributes} />
    ) 
    case MattrTypes.Collaborator:
    case MattrTypes.Multiselect: return (
      isValuePayload(value) ? 
        <ShowArray value={(value as JsonArrayValue).valueAsJsonArray! as Map[]} fieldAttributes={fieldAttributes} /> 
      : 
        <ShowArray value={value} fieldAttributes={fieldAttributes} />
    ) 
    case MattrTypes.Datetime:
    case MattrTypes.InsertedAt:
    case MattrTypes.UpdatedAt: return  (
      isValuePayload(value) ? 
        <ShowDatetime value={(value as DatetimeValue).valueAsDatetime} fieldAttributes={fieldAttributes} />
      : 
        <ShowDatetime value={value} fieldAttributes={fieldAttributes} />
    )
    case MattrTypes.Lookup: return  (
      isValuePayload(value) ? 
        <ShowLookup value={value.links} />
      : 
        <ShowLookup value={value} />
    )
    case MattrTypes.Relation: return  (
      isValuePayload(value) ? 
        <ShowRelation value={value.links} />
      : 
        <ShowRelation value={value} />
    )
    default: return <></>
  }} else {
    return <></>
  }
}

function ShowString({value}:{value: string | null}) {
  if(!value) {return <></>}
  return (
    <div className="flex">{value}</div>
  );
}

function ShowUrl({value}:{value: string | null}) {
  if(!value) {return <></>}
  return (
    <a href={`${value}`} target="_blank" className="flex px-1 py-0.5 text-blue-600">{value}</a>
  );
}

function ShowEmail({value}:{value: string | null}) {
  if(!value) {return <></>}
  return (
    <a href={`mailto:${value}`} target="_blank" className="flex px-1 py-0.5">{value}</a>
  );
}

function ShowNumber({value, fieldAttributes}:{value: number | null, fieldAttributes: FieldAttributes}) {
  if(!value) {return <></>}
  return (
    <NumberFormat 
      allowNegative={(fieldAttributes as Number)?.negative ? true : false}
      value={value} 
      displayType={'text'} 
      thousandSeparator={false}
    />
  );
}

function ShowPhone({value, fieldAttributes}:{value: number | null, fieldAttributes: FieldAttributes}) {
  if(!value) {return <></>}
  return (
    <NumberFormat 
      format="+# (###) ###-####"
      value={value} 
      displayType={'text'} 
      thousandSeparator={false}
    />
  );
}

function ShowDuration({value, fieldAttributes}:{value: number | null, fieldAttributes: FieldAttributes}) {
  const attr = fieldAttributes as DurationType

  function getDurationValue(value: any, attr: DurationType) {
    let milliseconds = getDurationMills(value, attr.durationInputDiscret)
    let format: string = convertFormat(attr.durationTimeFormat)!
    if(milliseconds) {
      return (Duration.fromMillis(milliseconds!)).toFormat(format).toString()
    } else {
      return ""
    }
  }

  function convertFormat(format: string) {
    switch (format) {
      case 'HH_MM':
        return 'hh:mm'
      case 'HH_MM_SS':
        return 'hh:mm:ss'
      case 'HH_MM_SS_SSS':
        return 'hh:mm:ss.SSS'
    }
  }

  function getDurationMills(value: number, discret: string) : number | undefined {
    switch (discret) {
      case 'MINUTES':
        return value * 60 * 1000
      case 'SECONDS':
        return value * 1000
      case 'MILLISECONDS':
        return value 
    }
  }

  return (
    <div className="flex px-1 py-0.5">{getDurationValue(value, attr)}</div>
  )
}

function ShowDecimal({value, fieldAttributes}:{value: number | null, fieldAttributes: FieldAttributes}) {
  const attr = fieldAttributes as Decimal

  if(!value) {return <></>}
  return (
    <NumberFormat 
      allowNegative={attr.negative ? true : false}
      fixedDecimalScale={true}
      decimalScale={attr.percision ? attr.percision! : 0} 
      value={value} 
      displayType={'text'} 
      thousandSeparator={true}
    />
  );
}

function ShowPercent({value, fieldAttributes}:{value: number | null, fieldAttributes: FieldAttributes}) {
  const attr = fieldAttributes as Percent

  if(!value) {return <></>}
  return (
    <NumberFormat 
      allowEmptyFormatting={false}
      allowNegative={attr.negative ? true : false}
      fixedDecimalScale={true}
      decimalScale={attr.percision ? attr.percision : 0} 
      isNumericString={false}
      value={value ? value * 100 : ''}
      displayType={'text'} 
      suffix={value ? '%' : ''}
      thousandSeparator={true}
    />
  );
}

function ShowCurrency({value, fieldAttributes}:{value: number | null, fieldAttributes: FieldAttributes}) {
  const attr = fieldAttributes as Currency

  if(!value) {return <></>}
  return (
    <NumberFormat 
      allowNegative={attr.negative ? true : false}
      fixedDecimalScale={true}
      decimalScale={attr.percision ? attr.percision : 0} 
      value={value} 
      displayType={'text'} 
      thousandSeparator={true} 
      prefix={` ${attr?.currency! ? attr?.currency! : ''} `} 
    />
  );
}

function ShowCheckbox({value}:{value: boolean | null}) {
  return (
    <div className="flex h-full px-2 py-2 text-sm items-start justify-center text-blue-600">{value ? <TbSquareCheckFilled className="text-blue-600" size={20}/>: <TbSquare className="text-slate-400" size={20}/>}</div>
  )
}

function ShowDatetime({value, fieldAttributes}:{value: string | null, fieldAttributes: FieldAttributes}) {
  const attr = fieldAttributes as Datetime

  if(!value) {return <></>}

  return (
    attr.includeTime ? 
      <div>{DateTime.fromISO(value!).toLocaleString(DateTime.DATETIME_MED)}</div> 
    : 
      <div>{DateTime.fromISO(value!).toLocaleString(DateTime.DATE_FULL)}</div> 
  )
}

function ShowRelation({value}:{value: Maybe<LinkItem[]>}) {
  if(!value || value.length === 0) {return <></>}
  return (
    <div className="flex space-x-1">
      {value?.map((v: LinkItem) => (
        v?.value?.value && <div key={v?.valueId} className="py-0.5 rounded-sm bg-pink-50 px-1 text-pink-600">
          {v?.value?.value}
        </div>
      ))}
    </div>
  )
}

function ShowLookup({value}:{value: Maybe<LinkItem[]>}) {
  if(!value || value.length === 0) {return <></>}
  return (
    <div className="flex space-x-1">
      {value?.map((v: LinkItem) => (
        v?.value?.value && <div key={v?.valueId} className="py-0.5 rounded-sm bg-blue-50 px-1">
          {v?.value?.value}
        </div>
      ))}
    </div>
  )
}

function ShowMap({value, fieldAttributes}:{value: Map, fieldAttributes: FieldAttributes}) {
  if(!value || !value.name) {return <></>}
  return (
    <div className="flex space-x-1">
      <div className="flex px-1.5 py-0.5 rounded-full text-slate-800" style={{backgroundColor: `${hexToRGB(intToRGB(hashCode(value?.name!)), 0.4)}`}}>{value.name}</div>
    </div>
  )
}

function ShowArray({value, fieldAttributes}:{value: Map[], fieldAttributes: FieldAttributes}) {
  if(!value || value?.length === 0) {return <></>}
  return (
    <div className="flex space-x-1">
      {value.map((v: Map) => (
        <div className="flex px-1.5 py-0.5 rounded-full text-slate-800" style={{backgroundColor: `${hexToRGB(intToRGB(hashCode(v?.name!)), 0.4)}`}}>{v.name}</div>
      ))}
    </div>
  )
}