import { FormulaIcon } from "../../icons";

export function FieldFormula({ value, field }: {value: string, field: any}) {

  if (value && field.attributes.expressionType === "DECIMAL") {
    return (
      <div className="relative flex flex-row justify-between items-center pb-1.5 border-b">
        <div className="flex items-center h-full">
          <div className="text-slate-400 pr-2"><FormulaIcon /></div>
          <label className="text-slate-500 text-sm">{field.title}</label>
        </div>
        <div className="text-sm px-2 bg-slate-100 text-slate-800 rounded-sm"
        >{value}</div>
        {/* <div className="flex w-full h-full text-xs font-mono items-center text-gray-400">
          {field.attributes.original} = {parseFloat(value).toFixed(2)}
        </div> */}
      </div>
    )
  } else if(value) {
    return (
      <div className="relative flex flex-row justify-between items-center pb-1.5 border-b">
        <div className="flex items-center h-full">
          <div className="text-slate-400 pr-2"><FormulaIcon /></div>
          <label className="text-slate-500 text-sm">{field.title}</label>
        </div>
        <div className="text-sm px-2 bg-slate-100 text-slate-800 rounded-sm"
        >{value}</div>
        {/* <div className="flex w-full h-full text-xs font-mono items-center text-gray-400">
          {field.attributes.original} = {value}
        </div> */}
      </div>
    )
  } else {
    return (
      <div className="relative flex flex-col gap-3">
        <div className="flex items-center h-full">
          <div className="text-slate-400 pr-2"><FormulaIcon /></div>
          <label className="text-slate-500 text-sm">{field.title}</label>
        </div>
        <div className="text-sm h-8 form-input py-1.5 block w-full rounded-sm bg-gray-100 border-0 focus:bg-white focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
        ></div>
        <div className="flex w-full h-full text-xs font-mono items-center text-gray-400">
          Нет результата выражения
        </div>
      </div>
    )
  }
}